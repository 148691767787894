import { useNavigate } from 'react-router-dom'
import React, { useEffect, useState, useCallback } from 'react'
import { connect, useDispatch } from 'react-redux'
import { Card, Table, Space, Switch, Tooltip, Drawer } from 'antd'
import commonStyles from '../../../../Utils/Common.less'
import AddNewButton from '../../../../Components/AddNewButton'
import SearchInput from '../../../../Components/SearchInput'
import type { ColumnsType, TableProps } from 'antd/es/table'
import type { TableParamsInterface } from '../../../../Types/CommonType'
import {
    commonTableParams,
    setCommonTableParamsData,
} from '../../../../Utils/CommontTable'
import FullWidthModal from '../../../../Components/FullWidthModal'
import { ReactComponent as DeleteIcon } from '../../../../Assest/Svg/DeleteIcon.svg'
import { EyeOutlined, MessageOutlined } from '@ant-design/icons'
import {
    type OnBoardingSoleTraderListInterface,
    type OnBoardingSoleTraderListDataTypeInterface,
    type OnBoardingSoleTraderListStateInterface,
} from '../../../../Types/Client/OnboardingClients/OnboardingSoletrader'
import {
    GetSoleTraderList,
    ActiveInactiveSoleTrader,
    DeleteSoleTrader,
} from '../../../../Services/OnBoardingSoletrader'
import SoleTraderExpandView from './OnboardingSoltraderExapandView'
import calculatePagination from '../../../../Utils/Pagination'
import ActivePremission from '../../../../Utils/premissins'
import { PermissionType } from '../../../../Types/Enum/PermissionType'
import StaffCommunication from '../../../../Components/StaffChat'
import { type DataCHAT } from '../../../../Types/Client/ProspectClients/prospectClient'
import { GetChat } from '../../../../Services/Common'
export const SoleTraderItemDefaultData = {
    soleTraderId: 0,
    clientCategoryId: 0,
    natureOfBusiness: '',
    BusinessTradingName: '',
    clientNumber: '',
    activestatus: '',
    serviceIds: '',
    ContactName: '',
    MobileNumber: '',
    EmailAddress: '',
    AlternativeEmailAddress: '',
    DefaultUser: '',
    DefaultAssigneeUser: '',
}

const SoleTradersList = (
    props: OnBoardingSoleTraderListInterface
): React.ReactElement => {
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const {
        onboardingsoleTraderList,
        onboardingsoleTraderListCount,
        selectedCategoryId,
        selectedServiceId,
    } = props
    const [tableParams, setTableParams] =
        useState<TableParamsInterface>(commonTableParams)
    const [deleteModalStatus, setDeleteModalStatus] = useState(false)
    const [soleTraderId, setsoleTraderId] = useState('')
    const [activeInactiveModalStatus, setActiveInactiveModalStatus] =
        useState(false)
    const [activeInactiveStatusLabel, setActiveInactiveStatusLabel] =
        useState('')
    // const [chatModalOpen, setChatModalOpen] = useState(false)
    const [currentPage, setCurrentPage] = useState(1)
    const [itemsPerPage, setItemsPerPage] = useState(10)

    const [ChatDatas, setChatData] = useState<DataCHAT | null>(null)
    const [open, setOpen] = useState(false)
    useEffect(() => {
        if (Number(soleTraderId) !== 0) {
            void GetChat(
                dispatch,
                6,
                Number(soleTraderId),
                2,
                (chatdatass: DataCHAT) => {
                    setChatData(chatdatass)
                }
            )
        }
    }, [soleTraderId])

    const openChatModal = (soleTraderId: string): void => {
        // setSelectedCorporateId(corporateid)
        // setChatModalOpen(true)
        setsoleTraderId(soleTraderId)
        setOpen(true)
    }
    const onClose = (): void => {
        setOpen(false)
    }

    const getTableData = useCallback(() => {
        const { pageSize, pageNo, sortOrderList, searchCriteriaList } =
            setCommonTableParamsData(tableParams)
        GetSoleTraderList(
            dispatch,
            { pageNo, pageSize, searchCriteriaList, sortOrderList },
            selectedCategoryId,
            selectedServiceId
        )
    }, [
        tableParams?.sorter,
        tableParams.pagination?.current,
        tableParams?.filter,
        selectedCategoryId,
        selectedServiceId,
    ])

    useEffect(() => {
        getTableData()
    }, [getTableData])

    const handleDataCountChange = useCallback(() => {
        setTableParams({
            ...tableParams,
            pagination: {
                ...tableParams.pagination,
                total: onboardingsoleTraderListCount,
            },
        })
    }, [onboardingsoleTraderListCount])

    useEffect(() => {
        handleDataCountChange()
    }, [handleDataCountChange])

    const viewSoleTraderDetails = (id: string): void => {
        const SoleTraderData = onboardingsoleTraderList?.find(
            (item) => item.soleTraderId === id
        )
        const onbordingId = 1
        navigate(`/clients/registered-clients/soletrader-view`, {
            state: { SoleTraderData, onbordingId },
        })
    }

    const expandableData = (
        record: OnBoardingSoleTraderListDataTypeInterface
    ): React.ReactNode => {
        return <SoleTraderExpandView soleTraderData={record} />
    }
    const handlePageChange = (pageNo: number, pageSize: number): void => {
        setCurrentPage(pageNo)
        setItemsPerPage(pageSize)
    }

    const pagination = calculatePagination(
        currentPage,
        onboardingsoleTraderListCount,
        itemsPerPage
    )
    const columns: ColumnsType<OnBoardingSoleTraderListDataTypeInterface> = [
        {
            title: 'Client No',
            dataIndex: 'clientNumber',
        },
        {
            title: ' Business Trading Name ',
            dataIndex: 'businessTradingName',
            sorter: true,
            sortDirections: ['descend', 'ascend'],
        },
        {
            title: ' Nature Of Business ',
            dataIndex: 'natureOfBusiness',
        },
        {
            title: ' Services ',
            dataIndex: 'serviceIds',
        },
        {
            title: 'Action',
            key: 'action',
            render: (_, record: OnBoardingSoleTraderListDataTypeInterface) => {
                return (
                    <Space size="middle">
                        <Tooltip title="Staff Communication">
                            <MessageOutlined
                                onClick={() => {
                                    openChatModal(record?.soleTraderId)
                                }}
                            />
                        </Tooltip>

                        <Tooltip title="Client Details">
                            <EyeOutlined
                                className={commonStyles.viewIcon}
                                onClick={() => {
                                    viewSoleTraderDetails(record?.soleTraderId)
                                }}
                            />
                        </Tooltip>

                        <Tooltip
                            title={
                                record.activestatus === 'Active'
                                    ? 'Active'
                                    : 'Inactive'
                            }
                        >
                            <Switch
                                checked={record.activestatus === 'Active'}
                                onClick={() => {
                                    activeInactiveModalFunction(
                                        record?.soleTraderId,
                                        record.activestatus
                                    )
                                }}
                            />
                        </Tooltip>
                        {ActivePremission('3000', PermissionType.DELETE) && (
                            <Tooltip title="Delete Soletrader">
                                <DeleteIcon
                                    onClick={() => {
                                        deleteModalFunction(
                                            record?.soleTraderId
                                        )
                                    }}
                                />
                            </Tooltip>
                        )}
                    </Space>
                )
            },
        },
    ]

    const onChangeTableParams: TableProps<OnBoardingSoleTraderListDataTypeInterface>['onChange'] =
        (pagination, filters, sorter: any, extra) => {
            handlePageChange(pagination.current ?? 1, pagination.pageSize ?? 10)
            setTableParams({
                ...tableParams,
                pagination,
                sorter: {
                    field: sorter?.field ?? '',
                    order: sorter?.order ?? '',
                },
            })
        }

    const deleteModalFunction = (id: string): void => {
        setDeleteModalStatus(!deleteModalStatus)
        let soleTraderId = ''
        if (!deleteModalStatus) {
            soleTraderId = id
        }
        setsoleTraderId(soleTraderId)
    }

    const deleteSoleTrader = (): void => {
        DeleteSoleTrader(dispatch, soleTraderId, getTableData)
        deleteModalFunction('')
    }

    const activeInactiveModalFunction = (
        id: string,
        activeStatus: string
    ): void => {
        setActiveInactiveModalStatus(!activeInactiveModalStatus)
        let soleTraderId = ''
        if (!activeInactiveModalStatus) {
            soleTraderId = id
        }
        let label = 'Active'
        if (activeStatus === 'Active') {
            label = 'Inactive'
        }
        setActiveInactiveStatusLabel(label)
        setsoleTraderId(soleTraderId)
    }

    const updateActiveInactiveSoleTraders = (): void => {
        let status = 'Active'
        if (activeInactiveStatusLabel === 'Inactive') status = 'Inactive'
        ActiveInactiveSoleTrader(dispatch, soleTraderId, status, getTableData)
        activeInactiveModalFunction('', '')
    }

    const onChangeText = (data: React.ChangeEvent<HTMLInputElement>): void => {
        const searchText = data.target.value.trim().toLowerCase()
        onChangeFilter(searchText)
    }

    const onChangeFilter = (data: string): void => {
        setTableParams({
            ...tableParams,
            filter: {
                fieldName: 'multiplesearch',
                searchOperator: 10,
                fieldValue1: data,
                fieldValue2: '',
            },
            pagination: {
                ...tableParams.pagination,
                current: 1,
            },
        })
    }

    const createSoleTrader = (id: string): void => {
        const soleTraderData = onboardingsoleTraderList?.find(
            (item) => item.soleTraderId === id
        )
        navigate(`/clients/registered-clients/soletrader-new`, {
            state: { soleTraderData },
        })
    }

    return (
        <Card className={commonStyles.card}>
            <SearchInput
                placeHolder={'Search Sole Traders Details by keywords'}
                onChange={onChangeText}
                onSearch={onChangeFilter}
                width="478"
            />
            <div className={commonStyles.AddBtn}>
                <AddNewButton
                    name="Add New"
                    clickAction={() => {
                        createSoleTrader('')
                    }}
                />
            </div>

            <div className={commonStyles.table}>
                <Table
                    columns={columns}
                    dataSource={onboardingsoleTraderList}
                    onChange={onChangeTableParams}
                    pagination={pagination}
                    expandable={{
                        expandedRowRender: expandableData,
                        rowExpandable: () => true,
                    }}
                />
            </div>
            <FullWidthModal
                modalStatus={deleteModalStatus}
                closeModal={deleteModalFunction}
                tittle="You are about to delete this Sole Traders . Click 'Yes' to continue."
                smallTittle="Warning: This action cannot be undone!"
                yesButtonFunction={deleteSoleTrader}
            />
            <FullWidthModal
                modalStatus={activeInactiveModalStatus}
                closeModal={() => {
                    activeInactiveModalFunction('', '')
                }}
                tittle={`You are about to ${activeInactiveStatusLabel} this Sole Traders. Click 'Yes' to continue.`}
                smallTittle=""
                yesButtonFunction={updateActiveInactiveSoleTraders}
            />

            {/* <Modal
                title="SoleTraderChat"
                visible={chatModalOpen}
                onCancel={() => {
                    setChatModalOpen(false)
                }}
                footer={null}
                width={'50%'}
            >
                {selectedSoleTraderId != null && (
                    <SoleTraderChat
                        soleTraderId={parseInt(selectedSoleTraderId, 10)}
                    />
                )}
            </Modal> */}
            <Drawer
                title={ChatDatas?.clientName}
                width={550}
                onClose={onClose}
                open={open}
            >
                <StaffCommunication
                    refernceId={Number(soleTraderId)}
                    messageTypeId={6}
                    businessId={2}
                />
            </Drawer>
        </Card>
    )
}

const mapStateToProps = (
    state: any
): OnBoardingSoleTraderListStateInterface => {
    return {
        onboardingsoleTraderList: state.client.onboardingsoleTraderList,
        onboardingsoleTraderListCount:
            state.client.onboardingsoleTraderListCount,
        serviceId: state.initial.serviceList,
    }
}

export default connect(mapStateToProps)(SoleTradersList)
