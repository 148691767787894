import React, { useEffect, useState, useCallback } from 'react'
import { connect, useDispatch } from 'react-redux'
import { Card, Table, Space, Switch, Col, Row, Tooltip, Drawer } from 'antd'
import commonStyles from '../../../../Utils/Common.less'
import SearchInput from '../../../../Components/SearchInput'
import type { ColumnsType, TableProps } from 'antd/es/table'
import type { TableParamsInterface } from '../../../../Types/CommonType'
import {
    commonTableParams,
    setCommonTableParamsData,
} from '../../../../Utils/CommontTable'
import FullWidthModal from '../../../../Components/FullWidthModal'
import { ReactComponent as DeleteIcon } from '../../../../Assest/Svg/DeleteIcon.svg'
import { MessageOutlined, EyeOutlined } from '@ant-design/icons'
import { GetCategoryDropDownList } from '../../../../Services/GroupAndPartners'
import type {
    PartnershipListStateInterface,
    PartnershiplListDataTypeInterface,
    PartnershipListInterface,
} from '../../../../Types/Client/RegisteredClients/Partnership'
import {
    ActiveInactiveParnership,
    DeleteParnership,
    GetParnershipList,
} from '../../../../Services/Parnership'
import AddNewButton from '../../../../Components/AddNewButton'
import PartnershipExpandView from './ PartnershipExpandView'
import { useNavigate } from 'react-router-dom'
import ActivePremission from '../../../../Utils/premissins'
import { PermissionType } from '../../../../Types/Enum/PermissionType'
import calculatePagination from '../../../../Utils/Pagination'
import StaffCommunication from '../../../../Components/StaffChat'
import { type DataCHAT } from '../../../../Types/Client/ProspectClients/prospectClient'
import { GetChat } from '../../../../Services/Common'
export const PartnershipItemDefaultData = {
    partnershipId: '0',
    name: '',
    tradingName: '',
    typeOfSelfEmployment: '',
    clientNumber: '',
    activestatus: '',
    serviceIds: '',
    mobilenumber: '',
    emailAddress: '',
    alternativeEmailAddress: '',
    defaultUser: '',
    defaultAssigneeUser: '',
}

const PartnershiplList = (
    props: PartnershipListInterface
): React.ReactElement => {
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const {
        partnershipList,
        partnershipListCount,
        selectedCategoryId,
        selectedServiceId,
    } = props
    const [tableParams, setTableParams] =
        useState<TableParamsInterface>(commonTableParams)
    const [deleteModalStatus, setDeleteModalStatus] = useState(false)
    const [partnershipId, setPartnershipId] = useState('')
    const [activeInactiveModalStatus, setActiveInactiveModalStatus] =
        useState(false)
    const [activeInactiveStatusLabel, setActiveInactiveStatusLabel] =
        useState('')

    // const [chatModalOpen, setChatModalOpen] = useState(false)
    const [currentPage, setCurrentPage] = useState(1)
    const [itemsPerPage, setItemsPerPage] = useState(10)

    const [ChatDatas, setChatData] = useState<DataCHAT | null>(null)
    const [open, setOpen] = useState(false)
    useEffect(() => {
        if (Number(partnershipId) !== 0) {
            void GetChat(
                dispatch,
                7,
                Number(partnershipId),
                4,
                (chatdatass: DataCHAT) => {
                    setChatData(chatdatass)
                }
            )
        }
    }, [partnershipId])

    const openChatModal = (partnershipId: string): void => {
        // setSelectedCorporateId(corporateid)
        // setChatModalOpen(true)
        setPartnershipId(partnershipId)
        setOpen(true)
    }
    const onClose = (): void => {
        setOpen(false)
    }

    useEffect(() => {
        GetCategoryDropDownList(dispatch)
    }, [dispatch])

    const getTableData = useCallback(() => {
        const { pageSize, pageNo, sortOrderList, searchCriteriaList } =
            setCommonTableParamsData(tableParams)
        GetParnershipList(
            dispatch,
            { pageNo, pageSize, searchCriteriaList, sortOrderList },
            selectedCategoryId,
            selectedServiceId
        )
    }, [
        tableParams?.sorter,
        tableParams.pagination?.current,
        tableParams?.filter,
        selectedCategoryId,
        selectedServiceId,
    ])

    useEffect(() => {
        getTableData()
    }, [getTableData])

    const handleDataCountChange = useCallback(() => {
        setTableParams({
            ...tableParams,
            pagination: {
                ...tableParams.pagination,
                total: partnershipListCount,
            },
        })
    }, [partnershipListCount])

    useEffect(() => {
        handleDataCountChange()
    }, [handleDataCountChange])

    const ViewParnershipDetails = (id: string): void => {
        const partnershipData = partnershipList.find(
            (item) => item.partnershipId === id
        )
        const onbordingId = 0
        navigate(`/clients/registered-clients/partnership-view`, {
            state: { partnershipData, onbordingId },
        })
    }

    const createPartnership = (id: string): void => {
        const partnershipData = partnershipList.find(
            (item) => item.partnershipId === id
        )
        navigate(`/clients/registered-clients/partnership-new`, {
            state: { partnershipData },
        })
    }

    const onChangeText = (data: React.ChangeEvent<HTMLInputElement>): void => {
        if (data.target.value.length === 0) {
            onChangeFilter(data.target.value)
        }
    }

    const handlePageChange = (pageNo: number, pageSize: number): void => {
        setCurrentPage(pageNo)
        setItemsPerPage(pageSize)
    }
    const pagination = calculatePagination(
        currentPage,
        partnershipListCount,
        itemsPerPage
    )
    const columns: ColumnsType<PartnershiplListDataTypeInterface> = [
        {
            title: 'Client No',
            dataIndex: 'clientNumber',
            sorter: true,
            sortDirections: ['descend', 'ascend'],
        },
        {
            title: 'Trading Name',
            dataIndex: 'tradingName',
        },
        {
            title: ' Partnership UTR',
            dataIndex: 'partnershipUTR',
        },
        {
            title: 'Service',
            dataIndex: 'serviceIds',
        },
        {
            title: 'Action',
            key: 'action1',
            render: (_, record: PartnershiplListDataTypeInterface) => {
                return (
                    <Space size="middle">
                        <Tooltip title="Communication">
                            <MessageOutlined
                                onClick={() => {
                                    openChatModal(record?.partnershipId)
                                }}
                            />
                        </Tooltip>
                        <Tooltip title="View Partner Details">
                            <EyeOutlined
                                className={commonStyles.viewIcon}
                                onClick={() => {
                                    ViewParnershipDetails(record?.partnershipId)
                                }}
                            />
                        </Tooltip>
                        <Tooltip
                            title={
                                record.activestatus === 'Active'
                                    ? 'Active'
                                    : 'Inactive'
                            }
                        >
                            <Switch
                                checked={record.activestatus === 'Active'}
                                onClick={() => {
                                    activeInactiveModalFunction(
                                        record?.partnershipId,
                                        record.activestatus
                                    )
                                }}
                            />
                        </Tooltip>
                        {ActivePremission('3000', PermissionType.SAVE) && (
                            <Tooltip title="View Portfolio Details">
                                <DeleteIcon
                                    onClick={() => {
                                        deleteModalFunction(
                                            record?.partnershipId
                                        )
                                    }}
                                />
                            </Tooltip>
                        )}
                    </Space>
                )
            },
        },
    ]

    const onChangeTableParams: TableProps<PartnershiplListDataTypeInterface>['onChange'] =
        (pagination, filters, sorter: any, extra) => {
            handlePageChange(pagination.current ?? 1, pagination.pageSize ?? 10)
            setTableParams({
                ...tableParams,
                pagination,
                sorter: {
                    field: sorter?.field ?? '',
                    order: sorter?.order ?? '',
                },
            })
        }

    const onChangeFilter = (data: string): void => {
        setTableParams({
            ...tableParams,
            filter: {
                fieldName: 'multiplesearch',
                searchOperator: 10,
                fieldValue1: data,
                fieldValue2: '',
            },
        })
    }

    const deleteModalFunction = (id: string): void => {
        setDeleteModalStatus(!deleteModalStatus)
        let partnershipId = ''
        if (!deleteModalStatus) {
            partnershipId = id
        }
        setPartnershipId(partnershipId)
    }

    const deletePartnership = (): void => {
        DeleteParnership(dispatch, partnershipId, getTableData)
        deleteModalFunction('')
    }

    const activeInactiveModalFunction = (
        id: string,
        activeStatus: string
    ): void => {
        setActiveInactiveModalStatus(!activeInactiveModalStatus)
        let partnershipId = ''
        if (!activeInactiveModalStatus) {
            partnershipId = id
        }
        let label = 'Active'
        if (activeStatus === 'Active') {
            label = 'Inactive'
        }
        setActiveInactiveStatusLabel(label)
        setPartnershipId(partnershipId)
    }

    const updateActiveInactivePartnership = (): void => {
        let status = 'Active'
        if (activeInactiveStatusLabel === 'Inactive') status = 'Inactive'
        ActiveInactiveParnership(dispatch, partnershipId, status, getTableData)
        activeInactiveModalFunction('', '')
    }

    const expandableData = (
        record: PartnershiplListDataTypeInterface
    ): JSX.Element => {
        return <PartnershipExpandView PartnershipData={record} />
    }

    return (
        <Card className={commonStyles.card}>
            <Row gutter={16} align="middle">
                <div className={commonStyles.cardContainer}>
                    <Col span={16}>
                        <SearchInput
                            placeHolder={'Search Name by keywords'}
                            onChange={onChangeText}
                            onSearch={onChangeFilter}
                            width="478"
                        />
                    </Col>
                    <Col span={4}>
                        <AddNewButton
                            name="Add New"
                            clickAction={() => {
                                createPartnership('')
                            }}
                        />
                    </Col>
                </div>
            </Row>
            <div className={commonStyles.table}>
                <Table
                    columns={columns}
                    dataSource={partnershipList}
                    onChange={onChangeTableParams}
                    pagination={pagination}
                    expandable={{
                        expandedRowRender: expandableData,
                        rowExpandable: () => true,
                    }}
                />
            </div>
            <FullWidthModal
                modalStatus={deleteModalStatus}
                closeModal={deleteModalFunction}
                tittle="You are about to delete this Client. Click 'Yes' to continue."
                smallTittle="Warning: This action cannot be undone!"
                yesButtonFunction={deletePartnership}
            />
            <FullWidthModal
                modalStatus={activeInactiveModalStatus}
                closeModal={() => {
                    activeInactiveModalFunction('', '')
                }}
                tittle={`You are about to ${activeInactiveStatusLabel} this Client. Click 'Yes' to continue.`}
                smallTittle=""
                yesButtonFunction={updateActiveInactivePartnership}
            />

            {/* <Modal
                title="Staff Comm"
                open={chatModalOpen}
                onCancel={CloseStaffCommunicatio}
                footer={null}
                width={'50%'}
            >
                {selectedPartnershipId != null && (
                    <PartnershipChat
                        partnershipId={parseInt(selectedPartnershipId, 10)}
                        closeModel={CloseStaffCommunicatio}
                    />
                )}
            </Modal> */}
            <Drawer
                title={ChatDatas?.clientName}
                width={550}
                onClose={onClose}
                open={open}
            >
                <StaffCommunication
                    refernceId={Number(partnershipId)}
                    messageTypeId={7}
                    businessId={4}
                />
            </Drawer>
        </Card>
    )
}

const mapStateToProps = (state: any): PartnershipListStateInterface => {
    return {
        partnershipList: state.client.partnershipList,
        partnershipListCount: state.client.partnershipListCount,
        serviceId: state.initial.serviceList,
    }
}

export default connect(mapStateToProps)(PartnershiplList)
