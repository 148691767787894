import { Card, Col, Modal, Row } from 'antd'
import React, { useState } from 'react'
import style from '../../ThingToDoViewAll/tingtodo.less'
import RegisterOfficeAddress from '../CompanyHouseRelatedThings/regitserOfficeAddress'
interface CompanyDetailsprops {
    companyHousId?: number
}
const CompanyDetails = (props: CompanyDetailsprops): React.ReactElement => {
    const { companyHousId } = props
    const [createEditModalStatus, setCreateEditModalStatus] = useState(false)
    const OpenEditAddressModel = (): void => {
        setCreateEditModalStatus(!createEditModalStatus)
    }
    const changeCreateEditModalStatus = (): void => {
        setCreateEditModalStatus(!createEditModalStatus)
    }
    return (
        <>
            <div>
                <h3>Company Details</h3>
            </div>
            <Card>
                {companyHousId === 1 && (
                    <div className={style.misstingInfo}>
                        If you want to do changes required in registered office
                        address should be filed using the
                        <b>&quot;Edit Registered Address&quot;</b>
                        before you file the confirmation statement.
                    </div>
                )}
                <Row gutter={[24, 8]}>
                    <Col span={8}>Company Type</Col>
                    <Col span={8}>:</Col>
                </Row>
                <Row gutter={[24, 8]}>
                    <Col span={8}>Date Of Incorporation</Col>
                    <Col span={8}>:</Col>
                </Row>
                <Row gutter={[24, 8]}>
                    <Col span={8}>Trading on market in period</Col>
                    <Col span={8}>:</Col>
                </Row>
                <Row gutter={[24, 8]}>
                    <Col span={8}>
                        Registered Office Address{' '}
                        {companyHousId === 1 && (
                            <span>
                                (<a onClick={OpenEditAddressModel}>Edit</a>)
                            </span>
                        )}
                    </Col>
                    <Col span={8}>:</Col>
                </Row>
                <Row gutter={[24, 8]}>
                    <Col span={8}>SIC Code 1</Col>
                    <Col span={8}>:</Col>
                </Row>
                <Row gutter={[24, 8]}>
                    <Col span={8}>SIC Code 2</Col>
                    <Col span={8}>:</Col>
                </Row>
                <Row gutter={[24, 8]}>
                    <Col span={8}>SIC Code 3</Col>
                    <Col span={8}>:</Col>
                </Row>
                <Row gutter={[24, 8]}>
                    <Col span={8}>SIC Code 4</Col>
                    <Col span={8}>:</Col>
                </Row>
            </Card>
            <Modal
                footer={null}
                onCancel={changeCreateEditModalStatus}
                width={'50%'}
                title={'Change of Registered Office Address'}
                open={createEditModalStatus}
            >
                <RegisterOfficeAddress />
            </Modal>
        </>
    )
}

export default CompanyDetails
