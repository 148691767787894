import { Col, Row, Input, Form, Card, Modal } from 'antd'
import commonStyles from '../../../../Utils/Common.less'
import { useLocation } from 'react-router-dom'
import AddNewButton from '../../../../Components/AddNewButton'
import React, { useState, type ReactElement } from 'react'
import PartnershipTabs from './PartnershipTaps'
import { type PartneshipData } from '../../../../Types/Client/RegisteredClients/Partnership'
import CreateTask from '../../../../Components/CreateTask'

const partnershipView = (): ReactElement => {
    const location = useLocation()
    const PartnershipData = location.state?.partnershipData
    const [isEditModalVisible, setEditModalVisible] = useState(false)
    const openEditModal = (): void => {
        setEditModalVisible(true)
    }

    const closeEditModal = (): void => {
        setEditModalVisible(false)
    }

    const handleCloseModal = (): void => {
        setEditModalVisible(false)
    }

    return (
        <>
            <Form>
                <Card
                    title={`${
                        PartnershipData?.tradingName !== null &&
                        PartnershipData?.tradingName !== undefined
                            ? PartnershipData?.tradingName
                            : ''
                    } - ${
                        PartnershipData?.clientNumber !== null &&
                        PartnershipData?.clientNumber !== undefined
                            ? PartnershipData?.clientNumber
                            : ''
                    }`}
                    extra={
                        <AddNewButton
                            name="Create Task"
                            clickAction={() => {
                                openEditModal()
                            }}
                        />
                    }
                    className={commonStyles.card}
                >
                    <Row gutter={16}>
                        <Col span={12}>
                            <Form.Item label="Client Type" />
                        </Col>
                        <Col span={12}>
                            <Form.Item label="Client No" />
                        </Col>
                    </Row>
                    <Row gutter={16}>
                        <Col span={12}>
                            <Form.Item<PartneshipData>>
                                <Input value={'Partnership'} disabled />
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item<PartneshipData>>
                                <Input
                                    value={PartnershipData?.clientNumber}
                                    disabled
                                />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={16}>
                        <Col span={12}>
                            <Form.Item label="Partnership UTR" />
                        </Col>
                        <Col span={12}>
                            <Form.Item label="Trading Name" />
                        </Col>
                    </Row>
                    <Row gutter={16}>
                        <Col span={12}>
                            <Form.Item<PartneshipData>>
                                <Input
                                    value={PartnershipData?.partnershipUTR}
                                    disabled
                                />
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item<PartneshipData>>
                                <Input
                                    value={PartnershipData?.tradingName}
                                    disabled
                                />
                            </Form.Item>
                        </Col>
                        <Row className={commonStyles.width100}>
                            <PartnershipTabs />
                        </Row>
                        <Modal
                            footer={null}
                            onCancel={closeEditModal}
                            width={'50%'}
                            title="Create Task"
                            open={isEditModalVisible}
                        >
                            <CreateTask onClose={handleCloseModal} />
                        </Modal>
                    </Row>
                </Card>
            </Form>
        </>
    )
}

export default partnershipView
