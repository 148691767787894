import React, { useEffect, useState } from 'react'
import { GetEmailPreviewData } from '../../../Services/GlobelSearch'
import { useDispatch } from 'react-redux'
// import ConverCodeToReact from './htmlTOReactCode'
import { type CommunicationEmailAttachmentList } from '../../../Types/GlobelSearchClient/GlobelSearchClient'
import HTMLcodeToReact from './htmltoReact'
import { CloudDownloadOutlined } from '@ant-design/icons'
interface GlobelClientTapsProps {
    ClientEmailId: number
}

const PreviewEmailModel = (
    props: GlobelClientTapsProps
): React.ReactElement => {
    const { ClientEmailId } = props
    const dispatch = useDispatch()
    const [missingFields, setMissingFields] =
        useState<CommunicationEmailAttachmentList | null>(null)

    useEffect(() => {
        const fetchData = async (): Promise<void> => {
            try {
                const data = await GetEmailPreviewData(dispatch, ClientEmailId)
                setMissingFields(data?.data)
            } catch (error) {}
        }
        void fetchData()
    }, [ClientEmailId])

    const downloadAttachment = (attachmentPath: string): void => {
        window.open(attachmentPath, '_blank')
    }
    return (
        <div>
            {missingFields !== null && (
                <>
                    <div>
                        <HTMLcodeToReact html={missingFields?.description} />
                    </div>
                    <hr />
                    {missingFields?.communicationEmailAttachmentList.length >
                        0 && (
                        <div>
                            {missingFields?.communicationEmailAttachmentList?.map(
                                (items, index) => (
                                    <div key={items.clientAttachmentId}>
                                        {index + 1}.{' '}
                                        <CloudDownloadOutlined
                                            onClick={() => {
                                                downloadAttachment(
                                                    items.attachmentPath
                                                )
                                            }}
                                        />
                                    </div>
                                )
                            )}
                        </div>
                    )}
                </>
            )}
        </div>
    )
}

export default PreviewEmailModel
