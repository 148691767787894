import React, { useEffect, useState } from 'react'
import {
    Col,
    Row,
    Input,
    Form,
    Button,
    Space,
    DatePicker,
    type DatePickerProps,
} from 'antd'
import commonStyles from '../../../../Utils/Common.less'
import { SaveOutlined } from '@ant-design/icons'

import { type ProspectClientFormDataInterface } from '../../../../Types/Client/ProspectClients/prospectClient'
import dayjs from 'dayjs'
import { SaveCommuniCationProspectClient } from '../../../../Services/ProspectClient'
import { useDispatch } from 'react-redux'
import {
    type CommunicationModelProps,
    type ContactInfo,
} from '../../../../Types/GlobelSearchClient/GlobelSearchClient'
import { GetContacPersonDetalils } from '../../../../Services/GlobelSearch'

const ClientPhoneCall = (
    props: CommunicationModelProps
): React.ReactElement => {
    const [form] = Form.useForm()

    const [selectedDate, setSelectedDate] = useState<string>('')
    const { businessTypeId, referenceId, changeModalStatus } = props
    const dispatch = useDispatch()
    const [ConatctInfo, setConatctInfo] = useState<ContactInfo | null>(null)

    useEffect(() => {
        const fetchData = (): void => {
            void GetContacPersonDetalils(
                dispatch,
                businessTypeId,
                referenceId,
                (details: ContactInfo) => {
                    setConatctInfo(details)
                }
            )
        }
        fetchData()
    }, [businessTypeId, referenceId])

    useEffect(() => {
        if (ConatctInfo !== null && typeof ConatctInfo === 'object') {
            const newFieldValues = {
                firstName: ConatctInfo?.firstName,
                mobileNumber: ConatctInfo?.mobileNumber,
            }

            form.setFieldsValue(newFieldValues)
        }
    }, [ConatctInfo, form])

    const onSave = (): void => {
        void onSaveSms()
    }
    const onSaveSms = async (): Promise<void> => {
        try {
            await form.validateFields()

            const formData = form.getFieldsValue()

            const dataTosave = {
                otherCommunicationHistoryId: 0,
                communicationModeId: 3,
                communicationTypeId: 3,
                businessTypeId,
                referenceId,
                contactNumber: formData.mobileNumber,
                description: formData.description,
                historyDate: selectedDate,
            }

            await SaveCommuniCationProspectClient(dispatch, dataTosave)

            form.setFieldsValue({
                description: '',
            })

            changeModalStatus()
        } catch (error) {
            console.error('Error:', error)
        }
    }
    useEffect(() => {
        form.setFieldValue('historyDate', selectedDate)
    }, [selectedDate])

    const onChange = (
        dateOfBirth: DatePickerProps['value'],
        dateString: string | string[]
    ): void => {
        if (dateOfBirth != null) {
            const isoDate = dateOfBirth.toISOString()
            setSelectedDate(isoDate)
        }
    }

    const customizeRequiredMark = (
        label: React.ReactNode,
        { required }: { required: boolean }
    ): React.ReactElement => (
        <>
            {label}
            &nbsp;
            {required && <span className={commonStyles.requireIcon}>*</span>}
        </>
    )
    return (
        <div className={commonStyles.formWrapper}>
            <Form
                name="complex-form"
                labelCol={{ span: 24 }}
                wrapperCol={{ span: 24 }}
                initialValues={{ remember: true }}
                layout={'vertical'}
                autoComplete="off"
                form={form}
                size="small"
                requiredMark={customizeRequiredMark}
            >
                <Form.Item<ProspectClientFormDataInterface>
                    name="prospectClientId"
                    hidden={true}
                >
                    <Input />
                </Form.Item>
                <Row gutter={16}>
                    <Col span={24}>
                        <Form.Item<ProspectClientFormDataInterface>
                            label="Name"
                            name="firstName"
                            rules={[
                                {
                                    required: true,
                                    message: 'Please input your firstName!',
                                },
                            ]}
                        >
                            <Input />
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={16}>
                    <Col span={24}>
                        <Form.Item<ProspectClientFormDataInterface>
                            label="Phone Number"
                            name="mobileNumber"
                            rules={[
                                {
                                    pattern: /^\d{10,15}$/,
                                    message:
                                        'Please enter a valid mobile number (10 to 15 digits).',
                                },
                                {
                                    required: true,
                                    message: 'Please input your mobile number!',
                                },
                            ]}
                        >
                            <Input />
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={16}>
                    <Col span={24}>
                        <Form.Item
                            label="Call Date"
                            name="historyDate"
                            rules={[
                                {
                                    required: true,
                                    message: 'Please input your Call Date!',
                                },
                            ]}
                        >
                            <Space direction="vertical" size={12}>
                                <DatePicker
                                    onChange={(date, dateString) => {
                                        onChange(date, dateString)
                                    }}
                                    value={
                                        selectedDate.length > 0
                                            ? dayjs(selectedDate)
                                            : null
                                    }
                                    format="DD/MM/YYYY h:mm:ss A"
                                    showTime
                                    className={commonStyles.dateWidth}
                                />
                            </Space>
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={16}>
                    <Col span={24}>
                        <Form.Item
                            label="Comments"
                            name="description"
                            rules={[
                                {
                                    required: true,
                                    message: 'Please input your Comments',
                                },
                            ]}
                        >
                            <Input.TextArea rows={4} />
                        </Form.Item>
                    </Col>
                </Row>

                <Row gutter={16}>
                    <Col offset={21} span={2}>
                        <Button type="primary" size="middle" onClick={onSave}>
                            <SaveOutlined />
                        </Button>
                    </Col>
                </Row>
            </Form>
        </div>
    )
}

export default ClientPhoneCall
