import React, { useEffect, useState, useCallback } from 'react'
import { connect, useDispatch } from 'react-redux'
import { Table, Space, Tooltip } from 'antd'
import { RightOutlined } from '@ant-design/icons'
import commonStyles from '../../../Utils/Common.less'
import SearchInput from '../../../Components/SearchInput'
import type { ColumnsType, TableProps } from 'antd/es/table'
import type { TableParamsInterface } from '../../../Types/CommonType'
import {
    commonTableParams,
    setCommonTableParamsData,
} from '../../../Utils/CommontTable'
import { ReactComponent as DeleteIcon } from '../../../Assest/Svg/DeleteIcon.svg'
import { ReactComponent as EditIcon } from '../../../Assest/Svg/EditIcon.svg'
import calculatePagination from '../../../Utils/Pagination'
import LeaveExpandView from './LeaveExpandView'
import {
    DeleteLeave,
    GetLeaveList,
    SaveLeave,
    SetApproval,
} from '../../../Services/HR/LeavePermission'
import {
    type LeaveFormStateType,
    type LeaveFormListType,
    type LeaveListType,
    type LeaveListTableIntrface,
} from '../../../Types/Hr/LeavePermission'
import FullWidthModal from '../../../Components/FullWidthModal'
import ActivePremission from '../../../Utils/premissins'
import { PermissionType } from '../../../Types/Enum/PermissionType'

const LeaveFormList = (props: LeaveFormListType): React.ReactElement => {
    const dispatch = useDispatch()
    const {
        LeaveList,
        LeaveListCount,
        LeaveType,
        LeaveTypeId,
        onFinish,
        saveEnable,
    } = props
    const [tableParams, setTableParams] =
        useState<TableParamsInterface>(commonTableParams)
    const [currentPage, setCurrentPage] = useState(1)
    const [itemsPerPage, setItemsPerPage] = useState(10)
    const [deleteModalStatus, setDeleteModalStatus] = useState(false)
    const [leaveID, setLeaveID] = useState(0)
    const [submitModalStatus, setSubmitModalStatus] = useState(false)
    const [submitLeaveId, setSubmitLeaveId] = useState(0)
    const [description, setDescription] = useState('')
    const [status, setStatus] = useState('=NA=')

    const getTableData = useCallback(() => {
        const { pageSize, pageNo, sortOrderList, searchCriteriaList } =
            setCommonTableParamsData(tableParams)
        GetLeaveList(
            dispatch,
            {
                pageNo,
                pageSize,
                searchCriteriaList,
                sortOrderList,
            },
            LeaveType
        )
    }, [
        tableParams?.sorter,
        tableParams.pagination?.current,
        tableParams?.filter,
        LeaveType,
        saveEnable,
    ])
    useEffect(() => {
        getTableData()
    }, [getTableData])

    const openSubmitModal = (record: LeaveListType): void => {
        setSubmitLeaveId(record.leaveId)
        setSubmitModalStatus(true)
        setDescription(record.description)
        setStatus('Pending Approval')
    }

    const deleteModalFunction = (id: number): void => {
        setDeleteModalStatus(!deleteModalStatus)
        setLeaveID(id)
    }

    const deleteLeave = (): void => {
        DeleteLeave(dispatch, leaveID, getTableData)
        setDeleteModalStatus(!deleteModalStatus)
    }

    const closeModal = (): void => {
        setDeleteModalStatus(false)
    }

    const submitLeave = (
        LeaveId: number,
        Description: string,
        Status: string
    ): void => {
        SetApproval(dispatch, LeaveId, Description, Status)
        getTableData()
        setSubmitModalStatus(false)
    }

    useEffect(() => {
        const saveLeaveAndRefresh = async (): Promise<void> => {
            if (saveEnable) {
                try {
                    await SaveLeave(dispatch, onFinish)
                    getTableData()
                } catch (error) {
                    console.error('Error while saving leave:', error)
                }
            }
        }

        saveLeaveAndRefresh().catch((error) => {
            console.error(
                'Error while saving leave and refreshing table:',
                error
            )
        })
    }, [dispatch, onFinish, saveEnable])

    const handleDataCountChange = useCallback(() => {
        setTableParams({
            ...tableParams,
            pagination: {
                ...tableParams.pagination,
                total: LeaveListCount,
            },
        })
    }, [LeaveListCount])

    useEffect(() => {
        handleDataCountChange()
    }, [handleDataCountChange])

    const handlePageChange = (pageNo: number, pageSize: number): void => {
        setCurrentPage(pageNo)
        setItemsPerPage(pageSize)
    }

    const pagination = calculatePagination(
        currentPage,
        LeaveListCount,
        itemsPerPage
    )
    const formatdate = (date: string | undefined): any => {
        if (date != null) {
            const dateObject = new Date(date)
            const year = dateObject.getFullYear()
            const month = dateObject.getMonth() + 1
            const day = dateObject.getDate()
            const formattedDate = `${month}/${day}/${year}`
            return formattedDate
        }
    }
    const columns: ColumnsType<LeaveListTableIntrface> = [
        {
            title: 'Leave From',
            dataIndex: 'leaveFrom',
            key: 'leaveFrom',
            sorter: true,
            sortDirections: ['descend', 'ascend'],
            render: (text: string, record: LeaveListTableIntrface) => {
                return <div>{formatdate(text)}</div>
            },
        },
        {
            title: 'Leave To',
            dataIndex: 'leaveTo',
            key: 'leaveTo',
            render: (text: string, record: LeaveListTableIntrface) => {
                return <div>{formatdate(text)}</div>
            },
        },
        {
            title: 'Leave Mode',
            dataIndex: 'leaveModeName',
            key: 'leaveModeName',
        },
        {
            title: 'Description',
            dataIndex: 'description',
            key: 'description',
        },
        {
            title: 'Status',
            dataIndex: 'leaveApprovalStatus',
            key: 'leaveApprovalStatus',
        },
        {
            title: 'Action',
            dataIndex: 'action',
            key: 'action',
            render: (_, record: LeaveListTableIntrface) => {
                return (
                    <Space size="middle">
                        {record.leaveApprovalStatus !== 'Pending Approval' && (
                            <>
                                <Tooltip title="Submit To Approval">
                                    <RightOutlined
                                        onClick={() => {
                                            openSubmitModal(record)
                                        }}
                                        className={commonStyles.clickableIcon}
                                    />
                                </Tooltip>
                                {ActivePremission(
                                    '8000',
                                    PermissionType.UPDATE
                                ) && (
                                    <Tooltip title="Edit Leave">
                                        <EditIcon
                                            onClick={() => {
                                                openEditModal(record)
                                            }}
                                            className={
                                                commonStyles.clickableIcon
                                            }
                                        />
                                    </Tooltip>
                                )}
                                {ActivePremission(
                                    '8000',
                                    PermissionType.DELETE
                                ) && (
                                    <Tooltip title="Delete">
                                        <DeleteIcon
                                            onClick={() => {
                                                deleteModalFunction(
                                                    record.leaveId
                                                )
                                            }}
                                            className={
                                                commonStyles.clickableIcon
                                            }
                                        />
                                    </Tooltip>
                                )}
                            </>
                        )}
                    </Space>
                )
            },
        },
    ]

    const onChangeTableParams: TableProps<LeaveListTableIntrface>['onChange'] =
        (pagination, filters, sorter: any, extra) => {
            handlePageChange(pagination.current ?? 1, pagination.pageSize ?? 10)
            setTableParams({
                ...tableParams,
                pagination,
                sorter: {
                    field: sorter?.field ?? '',
                    order: sorter?.order ?? '',
                },
            })
        }

    const onChangeText = (data: React.ChangeEvent<HTMLInputElement>): void => {
        onChangeFilter(data.target.value)
    }

    const onChangeFilter = (data: string): void => {
        setTableParams({
            ...tableParams,
            filter: {
                fieldName: 'description',
                searchOperator: 10,
                fieldValue1: data,
                fieldValue2: '',
            },
        })
    }

    const openEditModal = (record: LeaveListType): void => {
        LeaveTypeId(record)
    }

    const expandableData = (
        record: LeaveListTableIntrface
    ): React.ReactElement => {
        return <LeaveExpandView listsData={record} />
    }

    const updatekeyList = LeaveList.map((data) => ({
        ...data,
        key: data.leaveId,
        leaveFrom: formatdate(data.leaveFrom),
    }))
    return (
        <>
            <SearchInput
                placeHolder={'Search Banking Details by keywords'}
                onSearch={onChangeFilter}
                onChange={onChangeText}
                width="478"
            />
            <div className={commonStyles.table}>
                <Table
                    columns={columns}
                    dataSource={updatekeyList}
                    onChange={onChangeTableParams}
                    pagination={pagination}
                    expandable={{
                        expandedRowRender: expandableData,
                        rowExpandable: () => true,
                    }}
                />

                <FullWidthModal
                    modalStatus={deleteModalStatus}
                    closeModal={closeModal}
                    tittle="You are about to delete this Leave. Click 'Yes' to continue."
                    smallTittle="Warning: This action cannot be undone!"
                    yesButtonFunction={deleteLeave}
                />
                <FullWidthModal
                    modalStatus={submitModalStatus}
                    closeModal={() => {
                        setSubmitModalStatus(false)
                    }}
                    tittle="You are about to submit this leave. Click 'Yes' to continue."
                    smallTittle="Warning: This action cannot be undone!"
                    yesButtonFunction={() => {
                        submitLeave(submitLeaveId, description, status)
                    }}
                />
            </div>
        </>
    )
}

const mapStateToProps = (state: any): LeaveFormStateType => {
    return {
        LeaveList: state.hr.LeaveList,
        LeaveListCount: state.hr.LeaveListCount,
    }
}

export default connect(mapStateToProps)(LeaveFormList)
