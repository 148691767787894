import React from 'react'
import type { CorporateServicesVatView } from '../../../../../../Types/Client/RegisteredClients/Corporate'
import { Col, Row } from 'antd'
import { formatdate } from '../../../../../../Utils/dateFormat'
export const vatdefaultdata = {
    serviceReferenceId: 0,
    companyType: 0,
    country: '',
    postCode: '',
    status: '', // Provide the default status here
    town: '',
    vatId: 0,
    vatNumber: '',
    vatScheme: '',
    vatRegistrationDate: '01/01/1990', // Provide the default VAT registration date here
    effectiveDate: '01/01/1990', // Provide the default effective date here
    endingDate: '01/01/1990', // Provide the default ending date here
    dataInput: '',
    hmrcGatewayId: '',
    hmrcPassword: '',
    vatFrequency: 0,
    vatFrequencyName: '',
    addressLineOne: '',
    addressLineTwo: '',
}
const VATEdit = (props: CorporateServicesVatView): React.ReactElement => {
    const { VATServiceData } = props

    return (
        <>
            <Row gutter={[24, 8]}>
                <Col span={6}>
                    {' '}
                    <p>VAT Number</p>
                </Col>
                <Col span={6}>
                    {' '}
                    <p>:{VATServiceData?.vatNumber}</p>
                </Col>
                <Col span={6}>
                    <p>Address 1</p>
                </Col>
                <Col span={6}>
                    {' '}
                    <p>:{VATServiceData?.addressLineOne}</p>
                </Col>
            </Row>
            <Row gutter={[24, 8]}>
                <Col span={6}>
                    {' '}
                    <p>VAT Scheme</p>
                </Col>
                <Col span={6}>
                    <p>:{VATServiceData?.vatScheme}</p>
                </Col>
                <Col span={6}>
                    <p>Address 2</p>
                </Col>
                <Col span={6}>
                    <p>:{VATServiceData?.addressLineTwo}</p>
                </Col>
            </Row>
            <Row gutter={[24, 8]}>
                <Col span={6}>
                    <p>VAT Registration Date</p>
                </Col>
                <Col span={6}>
                    <p>:{formatdate(VATServiceData?.vatRegistrationDate)}</p>
                </Col>
                <Col span={6}>
                    <p>Town</p>
                </Col>
                <Col span={6}>
                    {' '}
                    <p>:{VATServiceData?.town}</p>
                </Col>
            </Row>
            <Row gutter={[24, 8]}>
                <Col span={6}>
                    {' '}
                    <p>VAT Effective Date</p>
                </Col>
                <Col span={6}>
                    <p>:{formatdate(VATServiceData?.effectiveDate)}</p>
                </Col>
                <Col span={6}>
                    {' '}
                    <p>Country</p>
                </Col>
                <Col span={6}>
                    <p>:{VATServiceData?.country}</p>
                </Col>
            </Row>
            <Row gutter={[24, 8]}>
                <Col span={6}>
                    {' '}
                    <p>Period Ending Date</p>
                </Col>
                <Col span={6}>
                    <p>:{formatdate(VATServiceData?.endingDate)}</p>
                </Col>
                <Col span={6}>
                    <p>Post Code</p>
                </Col>
                <Col span={6}>
                    {' '}
                    <p>:{VATServiceData?.postCode}</p>
                </Col>
            </Row>
            <Row gutter={[24, 8]}>
                <Col span={6}>
                    <p>HMRC Gateway ID</p>
                </Col>
                <Col span={6}>
                    <p>:{VATServiceData?.hmrcGatewayId}</p>
                </Col>
                <Col span={6}>
                    <p>Password</p>
                </Col>
                <Col span={6}>
                    {' '}
                    <p>:{VATServiceData?.hmrcPassword}</p>
                </Col>
            </Row>
            <Row gutter={[24, 8]}>
                <Col span={6}>
                    <p>VAT Frequency</p>
                </Col>
                <Col span={6}>
                    {' '}
                    <p>:{VATServiceData?.vatFrequencyName}</p>
                </Col>
                <Col span={6}>
                    <p>Data Input</p>
                </Col>
                <Col span={6}>
                    <p>:{VATServiceData?.dataInput}</p>
                </Col>
            </Row>
        </>
    )
}

export default VATEdit
