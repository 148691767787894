// import { useLocation } from 'react-router-dom'
import { getClientCompanyDetails } from '../../../../Services/Corporate'
import { connect, useDispatch } from 'react-redux'
import React, { useCallback, useEffect, useState } from 'react'
import { Tabs } from 'antd'
import type { TabsProps } from 'antd'
import type { CorporateTap } from '../../../../Types/Client/RegisteredClients/Corporate'
import Compliance from './Buttons/Compliance/Compliance'
import HomeList from './Buttons/Home/HomeList'
import SupplierInfo from './Buttons/SupplierInfo'
import { useLocation } from 'react-router-dom'
import CoporateConfiguration from './corporateConfiguration'
import CoporateTabsLess from '../../../../Less/Clients/RegisteredClients/Corporate/CorporateTabs.less'
import ContactInfoDetails from './Buttons/ContactInfo/ContactInfoDetails'
import Documentmain from './Buttons/Doucument/documentMain'
import Officer from './Buttons/OfficerButton/Officer'
import ServiceMain from './Buttons/ServicesTab/ServicesMain'
import { corporateTaps } from '../../../../Reducer/Client'

const CorporateTabs = (): React.ReactElement => {
    const location = useLocation()
    const { corporateData, onbordingId } = location.state
    const dispatch = useDispatch()
    const businessTypeId = 1
    const [activeTab, setActiveTab] = useState('1')
    const corporateId = corporateData?.corporateid
    const [companyservicedetails, setCompanyServiceDetails] = useState<any>({})
    const clientTypeId = corporateData.clientTypeId
    const contactTap = 5
    const [contactInfo, setCompanycontactInfo] = useState<any>({})
    const onChange = (key: string): void => {
        setActiveTab(key)
    }

    const getTapData = useCallback(() => {
        void getClientCompanyDetails(
            dispatch,
            businessTypeId,
            corporateId,
            parseInt(activeTab, 10)
        )
    }, [activeTab, corporateId])
    useEffect(() => {
        getTapData()
    }, [getTapData])

    const fetchDataContactInfo = async (): Promise<void> => {
        try {
            const data = await getClientCompanyDetails(
                dispatch,
                businessTypeId,
                corporateId,
                contactTap
            )
            setCompanycontactInfo(data)
        } catch (error) {}
    }

    const fetchServiceData = async (): Promise<void> => {
        try {
            const data = await getClientCompanyDetails(
                dispatch,
                businessTypeId,
                corporateId,
                4
            )
            setCompanyServiceDetails(data)
        } catch (error) {}
    }

    useEffect(() => {
        if (activeTab === '3') {
            void fetchServiceData()
            void fetchDataContactInfo()
        }
    }, [activeTab])

    const items: TabsProps['items'] = [
        {
            key: '1',
            label: 'Home',
            children: <HomeList corporateId={corporateId} />,
        },
        {
            key: '2',
            label: 'Officer',
            children: (
                <Officer
                    corporateTaps={corporateTaps}
                    corporateId={corporateId}
                    companyName={corporateData.companyname}
                    companyNumber={corporateData.companyregisternumber}
                    clientTypeId={clientTypeId}
                />
            ),
        },
        {
            key: '4',
            label: 'Services',
            children: <ServiceMain corporateid={corporateData.corporateid} />,
        },
        {
            key: '7',
            label: 'Supplier Info',
            children: <SupplierInfo corporateId={corporateData.corporateid} />,
        },
        {
            key: '5',
            label: 'Contact Info',
            children: (
                <ContactInfoDetails
                    companydetails={contactInfo}
                    corporateid={corporateId}
                />
            ),
        },
        {
            key: '3',
            label: 'Documents',
            children: (
                <Documentmain
                    corporateId={corporateId}
                    companyservicedetails={companyservicedetails}
                />
            ),
        },
        {
            key: '6',
            label: 'Compliance',
            children: (
                <Compliance
                    onbordingId={onbordingId}
                    corporateId={corporateData.corporateid}
                />
            ),
        },
        {
            key: '10',
            label: 'Configuration',
            children: (
                <CoporateConfiguration
                    registeredClientCorporateConfiguration={''}
                />
            ),
        },
    ]

    return (
        <div className={CoporateTabsLess.tabsWidth}>
            <Tabs activeKey={activeTab} onChange={onChange} items={items} />
        </div>
    )
}

const mapStateToProps = (state: any): CorporateTap => {
    return {
        corporateTaps: state.client.corporateTaps,
    }
}

export default connect(mapStateToProps)(CorporateTabs)
