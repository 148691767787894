import React, { useEffect, useState } from 'react'
import { Card, Modal, Space, Table, Tooltip } from 'antd'
import { EyeOutlined, DeleteOutlined } from '@ant-design/icons'
import AWS from 'aws-sdk'
import { ErrorMessage, SuccessMessage } from '../../../Utils/Notification'

const DocumentViewModelForm = (props: { data: string }): React.ReactElement => {
    const { data } = props
    const [imagePath, setImagePath] = useState('')
    const [visible, setVisible] = useState(false)
    const [fileList, setFileList] = useState<string[]>([])
    const s3 = new AWS.S3()
    const bucketName =
        process.env.REACT_APP_AWS_S3_BUCKET_NAME ?? 'iykons-dev-s3-storage'

    const fetchFileList = async (directoryPath: string): Promise<string[]> => {
        const params = {
            Bucket: bucketName,
            Prefix: directoryPath,
        }

        try {
            const response = await s3.listObjectsV2(params).promise()
            const files: string[] = (response.Contents ?? [])
                ?.map((file) => file.Key)
                ?.filter((file): file is string => Boolean(file))

            return files
        } catch (error) {
            ErrorMessage('Error fetching S3 objects:')
            return []
        }
    }

    const loadFileLists = async (): Promise<any> => {
        const urls = data.split(',').map((url) => new URL(url.trim()))
        const directoryPaths = urls.map(
            (url) =>
                url.pathname
                    .substring(0, url.pathname.lastIndexOf('/'))
                    .replace(/^\/|\/$/g, '') + '/'
        )

        const allFileLists = await Promise.all(
            directoryPaths.map(async (path) => await fetchFileList(path))
        )

        const combinedFileList = allFileLists.flat()
        setFileList(combinedFileList)
    }

    useEffect(() => {
        void loadFileLists()
    }, [data])

    const Servicecolumns = [
        {
            title: 'File',
            dataIndex: 'attachmentPath',
            key: 'key',
        },
        {
            title: '',
            key: 'action',
            render: (_: any, record: any) => {
                return (
                    <Space size="middle">
                        <Tooltip title="View">
                            <EyeOutlined
                                onClick={() => {
                                    handleViewFile(record.key)
                                }}
                            />
                        </Tooltip>

                        <Tooltip title="Delete">
                            <DeleteOutlined
                                onClick={() => {
                                    void handleDeleteClick(record.key)
                                }}
                            />
                        </Tooltip>
                    </Space>
                )
            },
        },
    ]

    const getFileExtension = (url: string | undefined): string | undefined => {
        if (url != null) {
            return url.split('.').pop()?.toLowerCase()
        }
        return undefined
    }

    const handleViewFile = (fileUrl: string): void => {
        console.log('Viewing file:', fileUrl) // Debugging line
        const fileExtension = getFileExtension(fileUrl)
        if (fileExtension === 'pdf') {
            viewPDF(fileUrl)
        } else if (
            fileExtension === 'jpg' ||
            fileExtension === 'jpeg' ||
            fileExtension === 'png'
        ) {
            viewImage(fileUrl)
        } else {
            ErrorMessage('Unsupported file format.')
        }
    }

    const viewImage = (imageUrl: string): void => {
        console.log('Viewing image:', imageUrl) // Debugging line
        const newpath = `https://${bucketName}.s3.eu-west-1.amazonaws.com/${imageUrl}`
        setImagePath(newpath)
        setVisible(true)
    }

    const viewPDF = (pdfUrl: string): void => {
        console.log('Viewing PDF:', pdfUrl) // Debugging line
        const viewerUrl = `https://docs.google.com/viewer?url=${encodeURIComponent(
            `https://${bucketName}.s3.eu-west-1.amazonaws.com/${pdfUrl}`
        )}&embedded=true`
        setImagePath(viewerUrl)
        setVisible(true)
    }

    const handleDeleteClick = async (filePath: string): Promise<void> => {
        const params = {
            Bucket: bucketName,
            Key: filePath,
        }

        try {
            await s3.deleteObject(params).promise()
            SuccessMessage('File deleted successfully:')

            // Update fileList state by filtering out the deleted file
            setFileList((prevFileList) =>
                prevFileList.filter((file) => file !== filePath)
            )

            // Optionally, reset imagePath and close modal if the deleted file was being viewed
            if (
                imagePath ===
                `https://${bucketName}.s3.eu-west-1.amazonaws.com/${filePath}`
            ) {
                setImagePath('')
                setVisible(false)
            }
        } catch (error) {
            ErrorMessage('Error deleting S3 object:')
        }
    }

    return (
        <div>
            <Card>
                <Table
                    columns={Servicecolumns}
                    dataSource={fileList?.map((file) => ({
                        key: file,
                        attachmentPath: file.substring(
                            file.lastIndexOf('/') + 1
                        ),
                    }))}
                    pagination={false}
                />
            </Card>
            <Modal
                width={'60%'}
                visible={visible}
                onCancel={() => {
                    setVisible(false)
                }}
                footer={null}
            >
                {imagePath !== '' ? (
                    imagePath.includes('.pdf') ? (
                        <iframe
                            title="PDF Viewer"
                            src={imagePath}
                            style={{
                                width: '100%',
                                height: '80vh',
                                border: 'none',
                            }}
                        />
                    ) : (
                        <img
                            src={imagePath}
                            alt="View"
                            style={{ width: '100%', marginTop: '20px' }}
                        />
                    )
                ) : (
                    <div>Error loading file</div>
                )}
            </Modal>
        </div>
    )
}

export default DocumentViewModelForm
