import { type PayloadAction, createSlice } from '@reduxjs/toolkit'
import {
    type PropertyviewPayloadInterface,
    type propertyFormDataInterface,
} from '../Types/MiisngInformation/PropertyView'
import { type ListFrontEndInterface } from '../Types/CommonType'

interface InitailStateInterface {
    MissinginformationprpertyviewList: propertyFormDataInterface[]
    MissinginformationprpertyviewListCount: number
    missingdroplist: ListFrontEndInterface[]
}

const MiisingInformation = createSlice({
    name: 'missing',
    initialState: {
        MissinginformationprpertyviewList: [],
        MissinginformationprpertyviewListCount: 0,
        missingdroplist: [],
    },
    reducers: {
        MissinginformationprpertyviewList(
            state: InitailStateInterface,
            action: PayloadAction<PropertyviewPayloadInterface>
        ) {
            state.MissinginformationprpertyviewList = action?.payload?.data
            state.MissinginformationprpertyviewListCount =
                action?.payload?.noOfRecords
        },
        missingdroplist(
            state: InitailStateInterface,
            action: PayloadAction<ListFrontEndInterface[]>
        ) {
            state.missingdroplist = action?.payload
        },
    },
})

export const { MissinginformationprpertyviewList, missingdroplist } =
    MiisingInformation.actions
export default MiisingInformation.reducer
