import React, { useEffect, useState } from 'react'
import { connect, useDispatch } from 'react-redux'
import { Card, Flex, Select } from 'antd'
import commonStyles from '../../../Utils/Common.less'
import CommonButton from '../../../Components/Commonbutton'
import CoporateList from './Corporate/corporateList'
import IndividualList from './Individual/IndividualList'
import { GetCategoryDropDownList } from '../../../Services/GroupAndPartners'
import { GetServiceDropDownList } from '../../../Services/Corporate'
import SoleTraderList from './SoleTrader/soleTraderList'
import type { RegisterClientInterface } from '../../../Types/Client/RegisteredClients/registerClient'
import PartnershipList from './Partnership/ PartnershipList'
import { GetRegisteClientDetails } from '../../../Services/Common'
import AdhocClientList from '../AdhocClient/AdhocClientList'
import { useNavigate } from 'react-router-dom'

const RegisterClient = (props: RegisterClientInterface): React.ReactElement => {
    const dispatch = useDispatch()
    const { categoryDropDownList, serviceDropDownList, regiterClientDetails } =
        props
    const [activeButton, setActiveButton] = useState(1)
    const [showCoporateList, setShowCoporateList] = useState(true)
    const [showSoleTraderList, setShowSoleTrader] = useState(true)
    const [showIndividualList, setShowIndividualList] = useState(true)
    const [showPatnershipList, setShowPatnershipList] = useState(true)
    const [showourclientList, setShowourclientList] = useState(true)
    const [selectedId, setSelectedId] = useState(0)
    const [selectedServiceId, setSelectedServiceId] = useState(0)
    const navigate = useNavigate()
    useEffect(() => {
        setShowSoleTrader(true)
    }, [])
    useEffect(() => {
        GetCategoryDropDownList(dispatch)
        GetRegisteClientDetails(dispatch)
    }, [dispatch])

    useEffect(() => {
        GetServiceDropDownList(dispatch)
    }, [dispatch])

    const handleSelectChange = (value: string | number): void => {
        setSelectedId(value === 'all' ? 0 : Number(value))
    }

    const handleSelectChange1 = (value: string | number): void => {
        setSelectedServiceId(value === 'all' ? 0 : Number(value))
    }

    const handleButtonClick = (buttonName: number): void => {
        setActiveButton(buttonName)

        if (buttonName === 1) {
            setShowCoporateList(true)
            setShowSoleTrader(false)
        } else if (buttonName === 2) {
            setShowSoleTrader(true)
            setShowCoporateList(false)
        } else if (buttonName === 3) {
            setShowIndividualList(true)
            setShowCoporateList(false)
            setShowSoleTrader(false)
        } else if (buttonName === 4) {
            setShowPatnershipList(true)
            setShowIndividualList(false)
            setShowCoporateList(false)
            setShowSoleTrader(false)
        } else if (buttonName === 5) {
            setShowourclientList(true)
            setShowPatnershipList(false)
            setShowIndividualList(false)
            setShowCoporateList(false)
            setShowSoleTrader(false)
        }
    }

    const handleOtherFunction = (): React.ReactElement => {
        if (activeButton === 1) {
            return (
                <CoporateList
                    selectedCategoryId={selectedId}
                    selectedServiceId={selectedServiceId}
                />
            )
        } else if (activeButton === 2) {
            return (
                <SoleTraderList
                    selectedCategoryId={selectedId}
                    selectedServiceId={selectedServiceId}
                />
            )
        }
        if (activeButton === 3) {
            return (
                <IndividualList
                    selectedCategoryId={selectedId}
                    selectedServiceId={selectedServiceId}
                />
            )
        }
        if (activeButton === 4) {
            return (
                <PartnershipList
                    selectedCategoryId={selectedId}
                    selectedServiceId={selectedServiceId}
                />
            )
        }
        if (activeButton === 5) {
            return <AdhocClientList />
        }
        return <></>
    }

    return (
        <>
            <Card
                title="Registered"
                className={commonStyles.card}
                extra={
                    <Flex justify="space-evenly" gap={20}>
                        <div
                            style={{
                                cursor: 'pointer',
                                fontSize: '14px',
                                fontWeight: 600,
                            }}
                            onClick={() => {
                                navigate(`/clients/onboarding-clients`)
                            }}
                        >
                            Onboarding
                        </div>
                        <div
                            style={{
                                cursor: 'pointer',
                                fontSize: '14px',
                                fontWeight: 600,
                            }}
                            onClick={() => {
                                navigate(`/clients/prospect-clients`)
                            }}
                        >
                            Prospect
                        </div>
                    </Flex>
                }
            >
                <Select
                    className={commonStyles.topdropdown2}
                    placeholder="Please select"
                    onChange={handleSelectChange}
                    value={selectedId === 0 ? 'all' : selectedId}
                    options={[
                        { value: 'all', label: 'All' },
                        ...categoryDropDownList,
                    ]}
                />
                <Select
                    className={commonStyles.topdropdown}
                    placeholder="Please select"
                    onChange={handleSelectChange1}
                    value={selectedServiceId === 0 ? 'all' : selectedServiceId}
                    options={[
                        { value: 'all', label: 'All' },
                        ...serviceDropDownList,
                    ]}
                />
                {regiterClientDetails.map((client, index) => (
                    <CommonButton
                        key={index}
                        name={client.label} // Assuming `buttonName` is a property in regiterClientDetails
                        isActive={activeButton === index + 1}
                        clickAction={() => handleOtherFunction()}
                        additionalOnClick={() => {
                            handleButtonClick(index + 1)
                        }}
                    />
                ))}
                {showCoporateList && activeButton === 1 && (
                    <CoporateList
                        selectedCategoryId={selectedId}
                        selectedServiceId={selectedServiceId}
                    />
                )}
                {showIndividualList && activeButton === 3 && (
                    <IndividualList
                        selectedCategoryId={selectedId}
                        selectedServiceId={selectedServiceId}
                    />
                )}
                {showSoleTraderList && activeButton === 2 && (
                    <SoleTraderList
                        selectedCategoryId={selectedId}
                        selectedServiceId={selectedServiceId}
                    />
                )}
                {showPatnershipList && activeButton === 4 && (
                    <PartnershipList
                        selectedCategoryId={selectedId}
                        selectedServiceId={selectedServiceId}
                    />
                )}
                {showourclientList && activeButton === 5 && <AdhocClientList />}
            </Card>
        </>
    )
}

const mapStateToProps = (state: any): RegisterClientInterface => {
    return {
        parentCategoryList: state.initial.parentCategoryList,
        categoryDropDownList: state.initial.categoryDropDownList,
        serviceDropDownList: state.initial.serviceDropDownList,
        regiterClientDetails: state.common.regiterClientDetails,
    }
}

export default connect(mapStateToProps)(RegisterClient)
