import { Card, Table } from 'antd'
import React from 'react'
import style from '../../ThingToDoViewAll/tingtodo.less'
interface CompanyDetailsprops {
    companyHousId?: number
}
const Shareholders = (props: CompanyDetailsprops): React.ReactElement => {
    const { companyHousId } = props
    const dataSource = [
        {
            key: '1',
            name: 'Mike',
            age: 32,
            address: '10 Downing Street',
        },
        {
            key: '2',
            name: 'John',
            age: 42,
            address: '10 Downing Street',
        },
    ]

    const columns = [
        {
            title: 'Name',
            dataIndex: 'name',
            key: 'name',
        },
        {
            title: 'Share Class',
            dataIndex: 'age',
            key: 'age',
        },
        {
            title: 'No of Shares',
            dataIndex: 'address',
            key: 'address',
        },
        {
            title: 'Transfers',
            dataIndex: 'address',
            key: 'address',
        },
        {
            title: 'Transfers Date',
            dataIndex: 'address',
            key: 'address',
        },
    ]

    return (
        <>
            <div>
                <h3>Shareholders</h3>
            </div>
            <Card>
                {companyHousId === 1 && (
                    <div className={style.misstingInfo}>
                        If you want to do changes required in shareholder
                        details, should be filed using respectively the{' '}
                        <b>&quot;Add New Shareholders&quot;</b>,{' '}
                        <b>&quot;Edit Personal Details&quot;</b>,
                        <b>&quot;Transfer Shares&quot;</b> pages before you file
                        the confirmation statement.
                    </div>
                )}
                <Table dataSource={dataSource} columns={columns} />
            </Card>
        </>
    )
}

export default Shareholders
