import { Avatar, Col, Row, Space } from 'antd'
import React from 'react'
import styles from './profile.less'
import { useNavigate } from 'react-router-dom'
import { pageReloadAuth } from '../../Reducer/AuthReducer'
import { useDispatch } from 'react-redux'

interface UserProfileProps {
    oncancelModel: () => void
}

const UserProfile = (props: UserProfileProps): React.ReactElement => {
    const { oncancelModel } = props
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const tokenDetailsString = localStorage.getItem('user')
    const tokenDetails =
        tokenDetailsString != null ? JSON.parse(tokenDetailsString) : null

    const NaviagetToChangePin = (): void => {
        navigate(`/change-Pin`)
        oncancelModel()
    }
    const NaviagetToChangePassword = (): void => {
        navigate(`/change-Password`)
        oncancelModel()
    }
    const NaviagetToListofHistory = (): void => {
        navigate(`/change-holidayList`)
        oncancelModel()
    }
    const NaviagetManageProfile = (): void => {
        navigate(`/manage-Profile`)
        oncancelModel()
    }

    const enterFullScreen = (): void => {
        if (document.fullscreenElement == null) {
            document.documentElement.requestFullscreen().catch((err) => {
                console.error('Failed to enter fullscreen mode:', err)
            })
        } else {
            if (document.exitFullscreen == null) {
                document.exitFullscreen().catch((err) => {
                    console.error('Failed to exit fullscreen mode:', err)
                })
            }
        }
    }

    const handlePrint = (): void => {
        window.print()
    }

    const handleLogout = (): void => {
        localStorage.clear()
        dispatch(pageReloadAuth())
        setTimeout(() => {
            location.reload()
        }, 2000)
    }
    return (
        <>
            <div className={styles.container}>
                <Row>
                    <Col span={8}>
                        <Avatar
                            src={tokenDetails?.imageUrl}
                            size={54}
                            className={styles.avatarImage}
                        />
                    </Col>
                    <Col span={16}>
                        <Space.Compact direction="vertical" size="small">
                            <div className={styles.firstName}>
                                {tokenDetails?.userName}
                            </div>
                            <div className={styles.lastName}>
                                {tokenDetails?.emailAddress}
                            </div>
                        </Space.Compact>
                    </Col>
                </Row>
            </div>
            <div
                style={{
                    display: 'flex',
                    flexDirection: 'column',
                    gap: '1rem',
                }}
            >
                <div className={styles.div} onClick={enterFullScreen}>
                    <div
                        style={{
                            display: 'flex',
                            justifyContent: 'space-between',
                        }}
                    >
                        <div>Full Screen</div>
                        <i>F11</i>
                    </div>
                </div>
                <div className={styles.div} onClick={handlePrint}>
                    <div
                        style={{
                            display: 'flex',
                            justifyContent: 'space-between',
                        }}
                    >
                        <div>Print</div>
                        <i>Ctrl + P</i>
                    </div>
                </div>
            </div>
            <hr />
            <Space
                direction="vertical"
                size="large"
                className={styles.spaceStyle}
            >
                <div className={styles.div} onClick={NaviagetToChangePassword}>
                    Change Password
                </div>
                <div className={styles.div} onClick={NaviagetToChangePin}>
                    Change Pin
                </div>
                <div className={styles.div} onClick={NaviagetToListofHistory}>
                    List of Holiday
                </div>
                <div className={styles.div} onClick={NaviagetManageProfile}>
                    Manage Profile
                </div>
                <div className={styles.divLog} onClick={handleLogout}>
                    Log Out
                </div>
            </Space>
        </>
    )
}

export default UserProfile
