import React, { useEffect, useState, useCallback } from 'react'
import { connect, useDispatch } from 'react-redux'
import { Table, Tooltip, Space, Button } from 'antd'
import type { ColumnsType, TableProps } from 'antd/es/table'
import {
    GetBusinessTagList,
    SaveBusinesstag,
    getClientCompanyDetails,
} from '../../../../../../Services/Individual'
import type { TableParamsInterface } from '../../../../../../Types/CommonType'
import {
    commonTableParams,
    setCommonTableParamsData,
} from '../../../../../../Utils/CommontTable'
import { DeleteOutlined, PlusOutlined } from '@ant-design/icons'
import type {
    InduvidualDetailsStateListInterface,
    IndividualDetailsListformInterface,
    IndividualListDataTypeInterface,
} from '../../../../../../Types/Client/RegisteredClients/Individual'
import commonStyles from '../../../../../../Utils/Common.less'
import calculatePagination from '../../../../../../Utils/Pagination'
import FullWidthModal from '../../../../../../Components/FullWidthModal'
import SearchInput from '../../../../../../Components/SearchInput'
import ActivePremission from '../../../../../../Utils/premissins'
import { PermissionType } from '../../../../../../Types/Enum/PermissionType'

const IndividualList = (
    props: IndividualDetailsListformInterface
): React.ReactElement => {
    const dispatch = useDispatch()
    const {
        indiviualbusinessTagList,
        indiviualbusinessTagListCount,
        individualId,
    } = props

    const [tableParams, setTableParams] =
        useState<TableParamsInterface>(commonTableParams)
    const [mode, setMode] = useState<boolean>(false)
    const [currentPage, setCurrentPage] = useState(1)
    const [itemsPerPage, setItemsPerPage] = useState(10)
    const [referenceId, setReferenceId] = useState<number | null>(null)
    const [confirmedDeletions, setConfirmedDeletions] = useState<number[]>([])
    const [customIndiviualbusinessTagList, setCustomIndiviualbusinessTagList] =
        useState<IndividualListDataTypeInterface[]>(indiviualbusinessTagList)
    const [deleteModalStatus, setDeleteModalStatus] = useState(false)
    const [selectedIndividualId, setSelectedIndividualId] = useState<
        number | null
    >(null)
    const [selectedsearchString, setSelectedsearchString] = useState('=NA=')

    useEffect(() => {
        setCustomIndiviualbusinessTagList(indiviualbusinessTagList)
    }, [indiviualbusinessTagList])

    const getTableData = useCallback(() => {
        const { pageSize, pageNo, sortOrderList, searchCriteriaList } =
            setCommonTableParamsData(tableParams)
        GetBusinessTagList(
            dispatch,
            { pageNo, pageSize, searchCriteriaList, sortOrderList },
            individualId,
            selectedsearchString
        )
    }, [
        tableParams?.sorter,
        tableParams.pagination?.current,
        tableParams?.filter,
        individualId,
        selectedsearchString,
    ])

    useEffect(() => {
        getTableData()
    }, [getTableData])

    const handleDataCountChange = useCallback(() => {
        setTableParams({
            ...tableParams,
            pagination: {
                ...tableParams.pagination,
                total: indiviualbusinessTagListCount,
            },
        })
    }, [indiviualbusinessTagListCount])

    useEffect(() => {
        handleDataCountChange()
    }, [handleDataCountChange])

    const handlePageChange = (pageNo: number, pageSize: number): void => {
        setCurrentPage(pageNo)
        setItemsPerPage(pageSize)
    }

    const pagination = calculatePagination(
        currentPage,
        indiviualbusinessTagListCount,
        itemsPerPage
    )

    const deleteModalFunction = (individualId: number): void => {
        if (individualId <= 0) {
            setMode(false)
        }
        if (individualId > 0) {
            setMode(true)
        }

        setDeleteModalStatus(!deleteModalStatus)
        if (!deleteModalStatus) {
            setSelectedIndividualId(individualId)
        }
    }
    const handleIndividual = (status: number): void => {
        if (referenceId !== null && selectedIndividualId !== null) {
            void SaveBusinesstag(
                dispatch,
                individualId,
                referenceId,
                1,
                status
            ).then((val: boolean) => {
                if (val) {
                    setCustomIndiviualbusinessTagList(
                        customIndiviualbusinessTagList?.map((item) =>
                            item.referenceId === referenceId
                                ? {
                                      ...item,
                                      individualId:
                                          status === 0 ? individualId : 0,
                                  }
                                : item
                        )
                    )
                }
            })
            void getClientCompanyDetails(dispatch, 3, individualId, 9)
            setConfirmedDeletions([...confirmedDeletions, selectedIndividualId])
        }
        setDeleteModalStatus(false)
    }

    const columns: ColumnsType<IndividualListDataTypeInterface> = [
        {
            title: 'Company Name',
            dataIndex: 'companyName',
        },
        {
            title: 'Type',
            dataIndex: 'businessType',
        },
        {
            title: 'Client No',
            dataIndex: 'clientNumber',
        },
        {
            title: 'Name',
            render: (text, record) =>
                `${record.title} ${record.firstName} ${record.surName}`,
        },
        {
            title: 'Action',
            key: 'action',
            render: (_, record: IndividualListDataTypeInterface) => (
                <Space size="middle">
                    <Tooltip
                        title={record.individualId === 0 ? 'Add' : 'Delete'}
                    >
                        <Button
                            type="default"
                            onClick={() => {
                                setReferenceId(record.referenceId)
                                deleteModalFunction(record.individualId)
                            }}
                        >
                            <Space>
                                {record.individualId === 0 ? (
                                    <PlusOutlined />
                                ) : (
                                    ActivePremission(
                                        '3000',
                                        PermissionType.DELETE
                                    ) && <DeleteOutlined />
                                )}
                            </Space>
                        </Button>
                    </Tooltip>
                </Space>
            ),
        },
    ]
    const onChangeTableParams: TableProps<IndividualListDataTypeInterface>['onChange'] =
        (pagination, filters, sorter: any) => {
            handlePageChange(pagination.current ?? 1, pagination.pageSize ?? 10)
            setTableParams({
                ...tableParams,
                pagination,
                sorter: {
                    field: sorter?.field ?? '',
                    order: sorter?.order ?? '',
                },
            })
        }
    const onChangeText = (data: React.ChangeEvent<HTMLInputElement>): void => {
        onChangeFilter(data.target.value)
    }

    const onChangeFilter = (data: string): void => {
        setSelectedsearchString(data)
    }
    return (
        <>
            <div>
                <SearchInput
                    placeHolder={'Search by keywords'}
                    onSearch={onChangeFilter}
                    onChange={onChangeText}
                    width="478"
                />
            </div>
            <div className={commonStyles.table}>
                <Table
                    columns={columns}
                    dataSource={customIndiviualbusinessTagList}
                    onChange={onChangeTableParams}
                    pagination={pagination}
                />
            </div>
            <FullWidthModal
                modalStatus={deleteModalStatus}
                closeModal={() => {
                    deleteModalFunction(-1)
                }}
                tittle={`You are about to ${
                    !mode ? 'add' : 'delete'
                } this business tag. Click 'Yes' to continue.`}
                yesButtonFunction={
                    !mode
                        ? () => {
                              handleIndividual(0)
                          }
                        : () => {
                              handleIndividual(1)
                          }
                }
                smallTittle={''}
            />
        </>
    )
}

const mapStateToProps = (state: any): InduvidualDetailsStateListInterface => {
    return {
        indiviualbusinessTagList: state.client.indiviualbusinessTagList,
        indiviualbusinessTagListCount:
            state.client.indiviualbusinessTagListCount,

        individualTaps: state.client.individualTaps,
    }
}

export default connect(mapStateToProps)(IndividualList)
