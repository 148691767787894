import React, { useEffect, useState } from 'react'
import {
    Col,
    Row,
    Input,
    Form,
    Button,
    Space,
    DatePicker,
    type DatePickerProps,
    Switch,
    Select,
} from 'antd'
import commonStyles from '../../../Utils/Common.less'
import { SaveOutlined } from '@ant-design/icons'
import { connect, useDispatch } from 'react-redux'
import moment from 'moment'
import dayjs from 'dayjs'
import { type PhoneCallProps } from '../../../Types/ToDoViewAllList'
import { SaveSwitchUser } from '../../../Services/ToDoViewAllList'
import { type ProspectChatStateInterface } from '../../../Types/Client/ProspectClients/prospectClient'
import { GetUserList } from '../../../Services/User'
import FullWidthModal from '../../../Components/FullWidthModal'

const ServiceProcessSwitchUserModel = (
    props: PhoneCallProps
): React.ReactElement => {
    const {
        serviceProcessId,
        changePhoneCallModalStatus,
        userList,
        serviceActionId,
        businessTypeId,
    } = props
    const dispatch = useDispatch()
    const [form] = Form.useForm()
    const [selectedDate, setSelectedDate] = useState<string>('')
    const [isApplyCircle, setIsApplyCircle] = useState<boolean>(false)
    const [checked, setChecked] = useState<boolean>(false)
    const [activeInactiveModalStatus, setActiveInactiveModalStatus] =
        useState(false)

    useEffect(() => {
        form.setFieldValue('dueDate', selectedDate)
    }, [selectedDate])

    useEffect(() => {
        GetUserList(dispatch)
    }, [])

    const onChange = (
        dateOfBirth: DatePickerProps['value'],
        dateString: string | string[]
    ): void => {
        if (dateOfBirth != null) {
            const isoDate = dateOfBirth.toISOString()
            setSelectedDate(isoDate)
            form.setFieldsValue({ dueDate: dateOfBirth }) // Update form value
            void form.validateFields(['dueDate']) // Revalidate the dueDate field
        }
    }
    const onSwitActive = (checked: boolean): void => {
        setActiveInactiveModalStatus(!activeInactiveModalStatus)
        setChecked(checked)
    }
    const activeInactiveModalFunction = (): void => {
        setActiveInactiveModalStatus(!activeInactiveModalStatus)
    }

    const updateActiveInactive = (): void => {
        setIsApplyCircle(checked)
        setActiveInactiveModalStatus(!activeInactiveModalStatus)
    }
    const onSave = (): void => {
        void onSavePhone()
    }
    const onSavePhone = async (): Promise<void> => {
        try {
            await form.validateFields()

            const formData = form.getFieldsValue()

            const dataToSave = {
                serviceprocessid: serviceProcessId,
                type: businessTypeId,
                referenceid: serviceActionId ?? 0,
                workeduserid: formData.assiginUser,
                duedate: moment(formData.dueDate).format('DD/MM/YYYY'),
                assigneeremarks: formData.description ?? '',
                isapplycircle: isApplyCircle ? 1 : 0,
            }

            await SaveSwitchUser(dispatch, dataToSave)

            form.resetFields()
            form.setFieldsValue({
                description: '',
                historyDate: '',
            })

            changePhoneCallModalStatus()
            setSelectedDate('')
        } catch (error) {}
    }

    const customizeRequiredMark = (
        label: React.ReactNode,
        { required }: { required: boolean }
    ): React.ReactElement => (
        <>
            {label}
            &nbsp;
            {required && <span className={commonStyles.requireIcon}>*</span>}
        </>
    )
    return (
        <div className={commonStyles.formWrapper}>
            <Form
                name="complex-form"
                labelCol={{ span: 24 }}
                wrapperCol={{ span: 24 }}
                initialValues={{ remember: true }}
                layout={'vertical'}
                autoComplete="off"
                form={form}
                size="small"
                requiredMark={customizeRequiredMark}
            >
                <Row gutter={16}>
                    <Col span={12}>
                        <Form.Item
                            label="Assign User"
                            name="assiginUser"
                            rules={[
                                {
                                    required: true,
                                    message: 'Please select your Assign User!',
                                },
                            ]}
                        >
                            <Select
                                allowClear
                                placeholder="Please select"
                                options={userList}
                                showSearch
                                filterOption={(input, option) =>
                                    option?.label
                                        ?.toLowerCase()
                                        .includes(input.toLowerCase()) ?? false
                                }
                                onChange={() => {}}
                            />
                        </Form.Item>
                    </Col>

                    <Col span={12}>
                        <Form.Item
                            label="Due Date"
                            name="dueDate"
                            rules={[
                                {
                                    required: true,
                                    message: 'Please input your Due Date!',
                                },
                                {
                                    validator: async (_, value) => {
                                        if (value !== 0) {
                                            await Promise.resolve()
                                            return
                                        }
                                        return await Promise.reject(
                                            new Error('Please select a user!')
                                        )
                                    },
                                },
                            ]}
                        >
                            <Space direction="vertical" size={12}>
                                <DatePicker
                                    onChange={(date, dateString) => {
                                        onChange(date, dateString)
                                    }}
                                    value={
                                        selectedDate.length > 0
                                            ? dayjs(selectedDate)
                                            : null
                                    }
                                    format="DD/MM/YYYY"
                                    className={commonStyles.dateWidth}
                                />
                            </Space>
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={16}>
                    <Col span={24}>
                        <Form.Item label="Comments" name="description">
                            <Input.TextArea rows={4} />
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={16}>
                    <Col span={24}>
                        <Switch
                            checked={isApplyCircle}
                            onChange={(checked) => {
                                onSwitActive(checked)
                            }}
                        />
                        <p>Apply this change to the current circle.</p>
                    </Col>
                </Row>
                <Row gutter={16}>
                    <Col offset={21} span={2}>
                        <Button type="primary" onClick={onSave} size="middle">
                            <SaveOutlined />
                        </Button>
                    </Col>
                </Row>
            </Form>
            <FullWidthModal
                modalStatus={activeInactiveModalStatus}
                closeModal={() => {
                    activeInactiveModalFunction()
                }}
                tittle={`All pending company processes will assign to this user for this circle. Click 'Yes' to continue.`}
                smallTittle=""
                yesButtonFunction={updateActiveInactive}
            />
        </div>
    )
}

const mapStateToProps = (state: any): ProspectChatStateInterface => {
    return {
        userList: state.user.userList,
    }
}

export default connect(mapStateToProps)(ServiceProcessSwitchUserModel)
