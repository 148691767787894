import React, { useState } from 'react'
import CommonButton from '../../../Components/Commonbutton'
import { Button, Card, Col, Flex, Row } from 'antd'
import CompanyDetails from '../../CompanyhouseandCSFillingButtons/ButtonFiles/companyDetails'
import PSCPage from '../../CompanyhouseandCSFillingButtons/ButtonFiles/pscsPage'
import ShareCapital from '../../CompanyhouseandCSFillingButtons/ButtonFiles/shareCapital'
import SAILAddersss from '../../CompanyhouseandCSFillingButtons/ButtonFiles/sailAddress'
import Officers from '../../CompanyhouseandCSFillingButtons/ButtonFiles/ofiicer'
import Shareholders from '../../CompanyhouseandCSFillingButtons/ButtonFiles/shareHolders'
import { DeleteOutlined, CheckOutlined } from '@ant-design/icons'
import CusttomBackgroundButton from '../../../Components/CustomBagraoundButton'
const CSFillingList = (): React.ReactElement => {
    const [activeButton, setActiveButton] = useState(1)
    const [CompanyDetailsBtn, setCompanyDetailsBtn] = useState(true)
    const [OfficerBtn, setOfficerBtn] = useState(false)
    const [SAILAddressBtn, setSAILAddressBtn] = useState(false)
    const [ShareCapitalBtn, setShareCapitalBtn] = useState(false)
    const [ShareholdersBtn, setShareholdersBtn] = useState(false)
    const [PscBtn, setPscBtn] = useState(false)

    const handleButtonClick = (buttonName: number): void => {
        setActiveButton(buttonName)
        switch (buttonName) {
            case 1:
                setCompanyDetailsBtn(true)
                break
            case 2:
                setOfficerBtn(true)
                break
            case 3:
                setSAILAddressBtn(true)
                break
            case 4:
                setShareCapitalBtn(true)
                break
            case 5:
                setShareholdersBtn(true)
                break
            case 6:
                setPscBtn(true)
                break
            default:
                break
        }
    }

    const handleOtherFunction = (): React.ReactElement => {
        if (activeButton === 1) {
            return <></>
        } else if (activeButton === 9) {
            return <></>
        }
        if (activeButton === 10) {
            return <></>
        }
        if (activeButton === 3) {
            return <></>
        }
        if (activeButton === 4) {
            return <></>
        }
        if (activeButton === 100) {
            return <></>
        }
        return <></>
    }
    return (
        <>
            <div>
                <h1>Summary of Confirmation Statement Filing</h1>
            </div>
            <Card title={'ATHERAY ORGANISATION LIMITED - 04977584'}>
                <div style={{ marginBottom: '15px' }}>
                    <Row gutter={[24, 8]}>
                        <Col span={10}>Next Statement Date </Col>
                        <Col span={8}>:</Col>
                    </Row>
                    <Row gutter={[24, 8]}>
                        <Col span={10}>
                            If you file a confirmation today, it will show the
                            date as
                        </Col>
                        <Col span={8}>:</Col>
                    </Row>
                </div>
                <CommonButton
                    name="Company Details"
                    isActive={activeButton === 1}
                    clickAction={() => handleOtherFunction()}
                    additionalOnClick={() => {
                        handleButtonClick(1)
                    }}
                />
                <CommonButton
                    name="Officer"
                    isActive={activeButton === 2}
                    clickAction={() => handleOtherFunction()}
                    additionalOnClick={() => {
                        handleButtonClick(2)
                    }}
                />
                <CommonButton
                    name="SAIL Address"
                    isActive={activeButton === 3}
                    clickAction={() => handleOtherFunction()}
                    additionalOnClick={() => {
                        handleButtonClick(3)
                    }}
                />
                <CommonButton
                    name="Share Capital"
                    isActive={activeButton === 4}
                    clickAction={() => handleOtherFunction()}
                    additionalOnClick={() => {
                        handleButtonClick(4)
                    }}
                />
                <CommonButton
                    name="Shareholders"
                    isActive={activeButton === 5}
                    clickAction={() => handleOtherFunction()}
                    additionalOnClick={() => {
                        handleButtonClick(5)
                    }}
                />
                <CommonButton
                    name="PSC's"
                    isActive={activeButton === 6}
                    clickAction={() => handleOtherFunction()}
                    additionalOnClick={() => {
                        handleButtonClick(6)
                    }}
                />

                {CompanyDetailsBtn && activeButton === 1 && (
                    <div>
                        <CompanyDetails />
                    </div>
                )}
                {OfficerBtn && activeButton === 2 && (
                    <div>
                        <Officers />
                    </div>
                )}
                {SAILAddressBtn && activeButton === 3 && (
                    <div>
                        <SAILAddersss />
                    </div>
                )}
                {ShareCapitalBtn && activeButton === 4 && (
                    <div>
                        <ShareCapital />
                    </div>
                )}
                {ShareholdersBtn && activeButton === 5 && (
                    <div>
                        <Shareholders />
                    </div>
                )}
                {PscBtn && activeButton === 6 && (
                    <div>
                        <PSCPage />
                    </div>
                )}
                <div style={{ marginTop: '15px' }}>
                    <Flex justify="center" align="flex-end" gap={5}>
                        <Button
                            type="primary"
                            size="large"
                            danger
                            icon={<DeleteOutlined />}
                        >
                            Revet All
                        </Button>

                        <CusttomBackgroundButton
                            color={'#1dc9b7'}
                            name={'Submit all & CS Filling'}
                            icon={<CheckOutlined />}
                        />
                    </Flex>
                </div>
            </Card>
        </>
    )
}

export default CSFillingList
