import { type PayloadAction, createSlice } from '@reduxjs/toolkit'
import {
    type CorporateTaps,
    type CorporatePayloadInteface,
    type TapIndexPayloadInterface,
    type CorporateFormDataInterface,
} from '../Types/Client/RegisteredClients/Corporate'
import {
    type OnBoardingCorporateFormDataInterface,
    type OnBoardingCorporatePayloadInteface,
} from '../Types/Client/OnboardingClients/OnBoardingCoporate'
import type {
    BusineeTagPayloadInteface,
    IndividualFormDataInterface,
    IndividualPayloadInteface,
} from '../Types/Client/RegisteredClients/Individual'
import type {
    SoleTraderPayloadInterface,
    SoleTraderFormDataInterface,
} from '../Types/Client/RegisteredClients/SoleTrader'
import { type ListFrontEndInterface } from '../Types/CommonType'
import {
    type onboardingPartnershipPayloadInteface,
    type onboardingPartnershipFormDataInterface,
} from '../Types/Client/OnboardingClients/OnBoardingPartnership'
import {
    type OnBoardingIndividualPayloadInteface,
    type OnBoardingIndividualFormDataInterface,
} from '../Types/Client/OnboardingClients/OnboardingIndividual'
import {
    type OnBoardingSoleTraderFormDataInterface,
    type OnBoardingSoleTraderPayloadInterface,
} from '../Types/Client/OnboardingClients/OnboardingSoletrader'
import {
    type PartnershipPayloadInteface,
    type PartnershipFormDataInterface,
} from '../Types/Client/RegisteredClients/Partnership'
import {
    type CommunicationHistory,
    type ProspectClientDetailsPayloadInterface,
    type ProspectClientHistoryPayloadInterface,
    type ProspectClientFormDataInterface,
} from '../Types/Client/ProspectClients/prospectClient'

interface ClientStateInterface {
    corporateList: CorporateFormDataInterface[]
    corporateListCount: number
    onboardingcorporateList: OnBoardingCorporateFormDataInterface[]
    onboardingcorporateListCount: number
    onboardingindividualList: OnBoardingIndividualFormDataInterface[]
    onboardingindividualListCount: number
    onboardingsoleTraderList: OnBoardingSoleTraderFormDataInterface[]
    onboardingsoleTraderListCount: number
    onboardingpartnerList: onboardingPartnershipFormDataInterface[]
    onboardingpartnerListCount: number
    individualList: IndividualFormDataInterface[]
    individualListCount: number
    individualTaps: any
    corporateTaps: CorporateTaps
    onBoardingCorporateTaps: any
    soleTraderList: SoleTraderFormDataInterface[]
    soleTraderListCount: number
    soleTraderTaps: any
    priorityDropDownList: ListFrontEndInterface[]
    paymentOptionDropDownList: ListFrontEndInterface[]
    registeredClientCorporateConfiguration: any
    registeredClientIndividualConfiguration: any
    soleTraderHomeData: any
    partnershipList: PartnershipFormDataInterface[]
    partnershipListCount: number
    partnershipTaps: any
    registeredClientPartnershipConfiguration: any
    prospectClientDetailsList: ProspectClientFormDataInterface[]
    prospectClientDetailsListCount: number
    prospectClientHistory: CommunicationHistory[]
    prospectClientHistoryCount: number
    individualHomeData: any
    indiviualbusinessTagList: any
    indiviualbusinessTagListCount: number
    registeredClientSoleTraderConfiguration: any
    clientTypeDropDownList: ListFrontEndInterface[]
    contactInfoPersonDropDownList: ListFrontEndInterface[]
}

const ClientSlice = createSlice({
    name: 'client',
    initialState: {
        corporateList: [],
        corporateListCount: 0,
        onboardingcorporateList: [],
        onboardingcorporateListCount: 0,
        onboardingindividualList: [],
        onboardingindividualListCount: 0,
        onboardingsoleTraderList: [],
        onboardingsoleTraderListCount: 0,
        onboardingpartnerList: [],
        onboardingpartnerListCount: 0,
        soleTraderList: [],
        soleTraderListCount: 0,
        corporateTaps: {},
        onBoardingCorporateTaps: {},
        individualList: [],
        individualListCount: 0,
        individualTaps: {},
        soleTraderTaps: {},
        priorityDropDownList: [],
        paymentOptionDropDownList: [],
        registeredClientCorporateConfiguration: {},
        registeredClientIndividualConfiguration: {},
        soleTraderHomeData: {},
        partnershipList: [],
        partnershipListCount: 0,
        partnershipTaps: {},
        registeredClientPartnershipConfiguration: {},
        prospectClientDetailsList: [],
        prospectClientDetailsListCount: 0,
        prospectClientHistory: [],
        prospectClientHistoryCount: 0,
        individualHomeData: {},
        indiviualbusinessTagList: [],
        indiviualbusinessTagListCount: 0,
        registeredClientSoleTraderConfiguration: {},
        clientTypeDropDownList: [],
        contactInfoPersonDropDownList: [],
    },
    reducers: {
        corporateList(
            state: ClientStateInterface,
            action: PayloadAction<CorporatePayloadInteface>
        ) {
            state.corporateList = action?.payload?.data
            state.corporateListCount = action?.payload?.noOfRecords
        },
        onboardingcorporateList(
            state: ClientStateInterface,
            action: PayloadAction<OnBoardingCorporatePayloadInteface>
        ) {
            state.onboardingcorporateList = action?.payload?.data
            state.onboardingcorporateListCount = action?.payload?.noOfRecords
        },
        onboardingindividualList(
            state: ClientStateInterface,
            action: PayloadAction<OnBoardingIndividualPayloadInteface>
        ) {
            state.onboardingindividualList = action?.payload?.data
            state.onboardingindividualListCount = action?.payload?.noOfRecords
        },
        onboardingsoleTraderList(
            state: ClientStateInterface,
            action: PayloadAction<OnBoardingSoleTraderPayloadInterface>
        ) {
            state.onboardingsoleTraderList = action?.payload?.data
            state.onboardingsoleTraderListCount = action?.payload?.noOfRecords
        },
        onboardingpartnerList(
            state: ClientStateInterface,
            action: PayloadAction<onboardingPartnershipPayloadInteface>
        ) {
            state.onboardingpartnerList = action?.payload?.data
            state.onboardingpartnerListCount = action?.payload?.noOfRecords
        },

        soleTraderList(
            state: ClientStateInterface,
            action: PayloadAction<SoleTraderPayloadInterface>
        ) {
            state.soleTraderList = action?.payload?.data
            state.soleTraderListCount = action?.payload?.noOfRecords
        },
        corporateTaps(
            state: ClientStateInterface,
            action: PayloadAction<TapIndexPayloadInterface>
        ) {
            state.corporateTaps = action?.payload?.data
        },
        onBoardingCorporateTaps(
            state: ClientStateInterface,
            action: PayloadAction<TapIndexPayloadInterface>
        ) {
            state.onBoardingCorporateTaps = action?.payload?.data
        },

        individualList(
            state: ClientStateInterface,
            action: PayloadAction<IndividualPayloadInteface>
        ) {
            state.individualList = action?.payload?.data
            state.individualListCount = action?.payload?.noOfRecords
        },
        individualTaps(
            state: ClientStateInterface,
            action: PayloadAction<TapIndexPayloadInterface>
        ) {
            state.individualTaps = action?.payload?.data
        },
        soleTraderTaps(
            state: ClientStateInterface,
            action: PayloadAction<TapIndexPayloadInterface>
        ) {
            state.soleTraderTaps = action?.payload?.data
        },
        priorityDropDownList(
            state: ClientStateInterface,
            action: PayloadAction<ListFrontEndInterface[]>
        ) {
            state.priorityDropDownList = action?.payload
        },
        paymentOptionDropDownList(
            state: ClientStateInterface,
            action: PayloadAction<ListFrontEndInterface[]>
        ) {
            state.paymentOptionDropDownList = action?.payload
        },
        registeredClientCorporateConfiguration(
            state: ClientStateInterface,
            action: PayloadAction<any>
        ) {
            state.registeredClientCorporateConfiguration = action?.payload
        },
        registeredClientIndividualConfiguration(
            state: ClientStateInterface,
            action: PayloadAction<any>
        ) {
            state.registeredClientIndividualConfiguration = action?.payload
        },
        soleTraderHomeData(
            state: ClientStateInterface,
            action: PayloadAction<TapIndexPayloadInterface>
        ) {
            state.soleTraderHomeData = action?.payload?.data
        },
        partnershiplList(
            state: ClientStateInterface,
            action: PayloadAction<PartnershipPayloadInteface>
        ) {
            state.partnershipList = action?.payload?.data
            state.partnershipListCount = action?.payload?.noOfRecords
        },
        partnershipTaps(
            state: ClientStateInterface,
            action: PayloadAction<TapIndexPayloadInterface>
        ) {
            state.partnershipTaps = action?.payload?.data
        },
        registeredClientPartnershipConfiguration(
            state: ClientStateInterface,
            action: PayloadAction<any>
        ) {
            state.registeredClientPartnershipConfiguration = action?.payload
        },

        prospectClientDetailsList(
            state: ClientStateInterface,
            action: PayloadAction<ProspectClientDetailsPayloadInterface>
        ) {
            state.prospectClientDetailsList = action?.payload?.data
            state.prospectClientDetailsListCount = action?.payload?.noOfRecords
        },
        prospectHistoryDetailsList(
            state: ClientStateInterface,
            action: PayloadAction<ProspectClientHistoryPayloadInterface>
        ) {
            state.prospectClientHistory = action?.payload?.data
            state.prospectClientHistoryCount = action?.payload?.noOfRecords
        },
        individualHomeData(
            state: ClientStateInterface,
            action: PayloadAction<TapIndexPayloadInterface>
        ) {
            state.individualHomeData = action?.payload?.data
        },
        registeredClientSoleTraderConfiguration(
            state: ClientStateInterface,
            action: PayloadAction<any>
        ) {
            state.registeredClientSoleTraderConfiguration = action?.payload
        },
        individualBusinessTag(
            state: ClientStateInterface,
            action: PayloadAction<BusineeTagPayloadInteface>
        ) {
            state.indiviualbusinessTagList = action?.payload?.data
            state.indiviualbusinessTagListCount = action?.payload?.noOfRecords
        },
        clientTypeDropDownList(
            state: ClientStateInterface,
            action: PayloadAction<ListFrontEndInterface[]>
        ) {
            state.clientTypeDropDownList = action?.payload
        },
        contactInfoPersonDropDownList(
            state: ClientStateInterface,
            action: PayloadAction<ListFrontEndInterface[]>
        ) {
            state.contactInfoPersonDropDownList = action?.payload
        },
    },
})

export const {
    corporateList,
    onboardingcorporateList,
    onboardingpartnerList,
    onboardingindividualList,
    onboardingsoleTraderList,
    soleTraderList,
    soleTraderTaps,
    corporateTaps,
    onBoardingCorporateTaps,
    individualList,
    individualTaps,
    priorityDropDownList,
    paymentOptionDropDownList,
    registeredClientCorporateConfiguration,
    registeredClientIndividualConfiguration,
    registeredClientSoleTraderConfiguration,
    soleTraderHomeData,
    partnershiplList,
    partnershipTaps,
    registeredClientPartnershipConfiguration,
    prospectClientDetailsList,
    prospectHistoryDetailsList,
    individualHomeData,
    individualBusinessTag,
    clientTypeDropDownList,
    contactInfoPersonDropDownList,
} = ClientSlice.actions

export default ClientSlice.reducer
