import {
    Col,
    Row,
    Input,
    Form,
    Card,
    Collapse,
    Select,
    Button,
    DatePicker,
    Space,
    type DatePickerProps,
} from 'antd'
import commonStyles from '../../../../Utils/Common.less'
import soleTraderNewStyles from '../../../../Less/Clients/RegisteredClients/SoleTrader/SoleTraderNew.less'
import { connect, useDispatch } from 'react-redux'
import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { GetCategoryDropDownList } from '../../../../Services/GroupAndPartners'
import type { SoleTraderNewStateInterface } from '../../../../Types/Client/RegisteredClients/SoleTrader'
import { GetCountryList } from '../../../../Services/Common'
import { SaveOutlined } from '@ant-design/icons'
import { type IndividualCreateFormDataInterface } from '../../../../Types/Client/RegisteredClients/Individual'
import { saveInduvidual } from '../../../../Services/Individual'
import dayjs from 'dayjs'

const InduvidualNew = (
    props: SoleTraderNewStateInterface
): React.ReactElement => {
    const { categoryDropDownList, countryList } = props
    const [form] = Form.useForm()
    const [collapseOne, SetCollapseOne] = useState<string[] | string>(['1'])
    const [collapseTwo, SetCollapseTwo] = useState<string[] | string>([])
    const navigate = useNavigate()
    const [selectedDate, setSelectedDate] = useState<string>('')
    const [selecteddueDate, setSelecteddueDate] = useState<string>('')
    const dispatch = useDispatch()

    useEffect(() => {
        GetCategoryDropDownList(dispatch)
        GetCountryList(dispatch)
    }, [dispatch, Form])

    const customizeRequiredMark = (
        label: React.ReactNode,
        { required }: { required: boolean }
    ): React.ReactElement => (
        <>
            {label}
            &nbsp;
            {required && <span className={commonStyles.requireIcon}>*</span>}
        </>
    )

    const saveCallBack = (data: IndividualCreateFormDataInterface): void => {
        navigate('/clients/registered-clients/individual-view', {
            state: { individualData: data },
        })
    }

    const onsaveInduvidual = (
        data: IndividualCreateFormDataInterface
    ): void => {
        void onSaveSoleTrader(data)
    }
    const onSaveSoleTrader = async (
        data: IndividualCreateFormDataInterface
    ): Promise<void> => {
        await form.validateFields()
        if (collapseOne?.length === 1) {
            SetCollapseOne(['1', '2'])
            SetCollapseTwo(['1', '2', '3'])
        } else {
            void saveInduvidual(dispatch, data, saveCallBack)
        }
    }

    const collapseOneOnChange = (data: string | string[]): void => {
        SetCollapseOne(data)
    }

    const collapseTwoOnChange = (data: string | string[]): void => {
        SetCollapseTwo(data)
    }

    const onChange = (
        dateOfBirth: DatePickerProps['value'],
        dateString: string | string[]
    ): void => {
        if (dateOfBirth != null) {
            const isoDate = dateOfBirth.toISOString()
            setSelectedDate(isoDate)
        }
    }
    const onChangedueDate = (
        dateOfBirth: DatePickerProps['value'],
        dateString: string | string[]
    ): void => {
        if (dateOfBirth != null) {
            const isoDate = dateOfBirth.toISOString()
            setSelecteddueDate(isoDate)
        }
    }
    useEffect(() => {
        form.setFieldValue('dateOfBirth', selectedDate)
        form.setFieldValue('dueDate', selecteddueDate)
    }, [selectedDate, selecteddueDate])
    const initialDetails = (): React.ReactElement => {
        return (
            <div>
                <Form.Item label="Client Category" name="clientCategoryId">
                    <Select
                        placeholder="Please select"
                        options={[...categoryDropDownList]}
                    />
                </Form.Item>
                <Form.Item label="Tittle" name="tittle">
                    <Select
                        allowClear
                        placeholder="Please select tittle"
                        onChange={() => {}}
                        defaultValue={'UNKNOWN'}
                    >
                        <Select.Option value="UNKNOWN">Unknown</Select.Option>
                        <Select.Option value="MR.">MR.</Select.Option>
                        <Select.Option value="MISS.">MISS.</Select.Option>
                        <Select.Option value="MRS.">MRS.</Select.Option>
                        <Select.Option value="MS.">MS.</Select.Option>
                        <Select.Option value="DR.">DR.</Select.Option>
                    </Select>
                </Form.Item>
                <Form.Item
                    label="First Name"
                    name="firstName"
                    rules={[
                        {
                            required: true,
                            message: 'Please Enter First Name',
                        },
                    ]}
                >
                    <Input />
                </Form.Item>
                <Form.Item label="Middle Name" name="middleName">
                    <Input />
                </Form.Item>
                <Form.Item
                    label="Surename"
                    name="surName"
                    rules={[
                        {
                            required: true,
                            message: 'Please Enter Surename',
                        },
                    ]}
                >
                    <Input />
                </Form.Item>
                <Form.Item
                    label="Type Of SelfEmployment"
                    name="typeOfSelfEmployment"
                >
                    <Input />
                </Form.Item>
                <Form.Item
                    label="Trading Name"
                    name="tradingName"
                    rules={[
                        {
                            required: true,
                            message: 'Please Enter Business Trading Name',
                        },
                    ]}
                >
                    <Input />
                </Form.Item>
                <Form.Item label="Individual UTR" name="individualUTR">
                    <Input />
                </Form.Item>
            </div>
        )
    }

    const personalDetails = (): React.ReactElement => {
        return (
            <div>
                <Form.Item label="Date Of Birth" name="dateOfBirth">
                    <Space direction="vertical" size={12}>
                        <DatePicker
                            onChange={(date, dateString) => {
                                onChange(date, dateString)
                            }}
                            value={
                                selectedDate?.length > 0
                                    ? dayjs(selectedDate)
                                    : null
                            }
                            format="DD/MM/YYYY"
                            className={commonStyles.dateWidth}
                        />
                    </Space>
                </Form.Item>
                <Form.Item label="NI Number" name="niNumber">
                    <Input />
                </Form.Item>
                <Form.Item label="Due Date" name="dueDate">
                    <Space direction="vertical" size={12}>
                        <DatePicker
                            onChange={(date, dateString) => {
                                onChangedueDate(date, dateString)
                            }}
                            value={
                                selecteddueDate?.length > 0
                                    ? dayjs(selecteddueDate)
                                    : null
                            }
                            format="DD/MM/YYYY"
                            className={commonStyles.dateWidth}
                        />
                    </Space>
                </Form.Item>
                <Form.Item
                    label="Mobile Number"
                    name="mobileNumber"
                    rules={[
                        {
                            required: true,
                            message: 'Please Enter Mobile Number',
                        },
                        {
                            pattern: /^\d{10,15}$/,
                            message: 'Mobile Number must be 10-15 digits!',
                        },
                    ]}
                >
                    <Input />
                </Form.Item>
                <Form.Item label="Home Number" name="homeNumber">
                    <Input />
                </Form.Item>
                <Form.Item label="Work Number" name="workNumber">
                    <Input />
                </Form.Item>
                <Form.Item
                    label="Email Address"
                    name="emailAddress"
                    rules={[
                        {
                            required: true,
                            message: 'Please Enter Email Address',
                        },
                        {
                            pattern:
                                /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/,
                            message: 'Please enter a valid Email Address!',
                        },
                    ]}
                >
                    <Input />
                </Form.Item>
                <Form.Item
                    label="Alternative Email Address"
                    name="alternativeEmailAddress"
                >
                    <Input />
                </Form.Item>
            </div>
        )
    }

    const homeAddress = (): React.ReactElement => {
        return (
            <div>
                <Form.Item
                    label="Address 1"
                    name="homeAddressOne"
                    rules={[
                        {
                            required: true,
                            message: 'Please Enter Address1',
                        },
                    ]}
                >
                    <Input />
                </Form.Item>
                <Form.Item label="Address 2" name="homeAddressTwo">
                    <Input />
                </Form.Item>
                <Form.Item
                    label="Town"
                    name="homeTown"
                    rules={[
                        {
                            required: true,
                            message: 'Please Enter Town',
                        },
                    ]}
                >
                    <Input />
                </Form.Item>
                <Form.Item label="Country" name="homeCountry">
                    <Select
                        allowClear
                        showSearch
                        placeholder="Please select"
                        onChange={() => {}}
                        optionFilterProp="children"
                        options={countryList?.map((data: { label: string }) => {
                            return {
                                id: data.label,
                                value: data.label,
                                label: data.label,
                            }
                        })}
                    />
                </Form.Item>
                <Form.Item
                    label="Post Code"
                    name="postCode"
                    rules={[
                        {
                            required: true,
                            message: 'Please Enter Post Code',
                        },
                    ]}
                >
                    <Input />
                </Form.Item>
            </div>
        )
    }

    return (
        <Card className={commonStyles.card}>
            <Form
                name="complex-form"
                layout={'vertical'}
                autoComplete="off"
                form={form}
                requiredMark={customizeRequiredMark}
                onFinish={onsaveInduvidual}
            >
                <Row gutter={16}>
                    <Col span={12}>
                        <Form.Item label="Client Type">
                            <Input disabled value={'Individual'} />
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item label="Client No" name="clientNumber">
                            <Input disabled />
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={16}>
                    <Col span={12}>
                        <Form.Item label="Client Name">
                            <Input disabled />
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item
                            label="Business Trading Name"
                            name="businessTradingName"
                        >
                            <Input disabled />
                        </Form.Item>
                    </Col>
                </Row>
                <Row>
                    <h3>Basic Information</h3>
                </Row>
                <div className={commonStyles.flexDirectionRow}>
                    <div className={soleTraderNewStyles.collapseFirst}>
                        <Collapse
                            activeKey={collapseOne}
                            onChange={collapseOneOnChange}
                            bordered={true}
                            items={[
                                {
                                    key: '1',
                                    label: 'Initial Details',
                                    children: initialDetails(),
                                },
                                {
                                    key: '2',
                                    label: 'Personal Details',
                                    children: personalDetails(),
                                },
                            ]}
                        />
                    </div>
                    <div className={soleTraderNewStyles.collapseSecond}>
                        <Collapse
                            activeKey={collapseTwo}
                            onChange={collapseTwoOnChange}
                            bordered={true}
                            items={[
                                {
                                    key: '1',
                                    label: 'Home Address',
                                    children: homeAddress(),
                                },
                            ]}
                        />
                    </div>
                </div>
                &nbsp;
                <Row gutter={16}>
                    <Col offset={23} span={1}>
                        <Button type="primary" htmlType="submit">
                            <SaveOutlined />
                        </Button>
                    </Col>
                </Row>
            </Form>
        </Card>
    )
}

const mapStateToProps = (state: any): SoleTraderNewStateInterface => {
    return {
        categoryDropDownList: state.initial.categoryDropDownList,
        countryList: state.common.countryList,
    }
}

export default connect(mapStateToProps)(InduvidualNew)
