import React, { useEffect, useState } from 'react'
import { Card, Flex, Modal } from 'antd'
import { connect, useDispatch } from 'react-redux'
import { GetClientOverview } from '../../Services/Overview'
import { type InitialOverviewStateInterface } from '../../Types/Overview'
import style from './overviewless.less'
import OverViewClientList from './overviewCientMode'

const OverViewClient = (
    props: InitialOverviewStateInterface
): React.ReactElement => {
    const { overview } = props
    const dispatch = useDispatch()
    const [isModalVisible, setIsModalVisible] = useState(false)
    const [selectedSquence, setSelectedSeqence] = useState(0)
    const [searchtext, setSerachtext] = useState('=NA=')
    useEffect(() => {
        void GetClientOverview(dispatch)
    }, [dispatch])

    const handleRecordCountClick = (value: number): void => {
        setIsModalVisible(true)
        setSelectedSeqence(value)
    }
    const changeCreateEditModalStatus = (): void => {
        setIsModalVisible(!isModalVisible)
        setSerachtext('=NA=')
    }
    return (
        <>
            <Card title="Overview Client and Service Status">
                <Flex gap="middle" align="start" justify={'space-between'}>
                    <div>
                        <table className={style.tablestyle}>
                            <thead>
                                <tr>
                                    <th className={style.thstyle}>
                                        Active Client Total -{' '}
                                        {overview?.totalActiveCount}
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                {overview?.clientInfoList?.map((clientInfo) => (
                                    <tr
                                        key={clientInfo.sequenceNo}
                                        className={style.trstyle}
                                    >
                                        <td className={style.tdlabel}>
                                            {clientInfo.description}
                                        </td>
                                        <td className={style.tdCount}>
                                            <div
                                                onClick={() => {
                                                    handleRecordCountClick(
                                                        clientInfo.sequenceNo
                                                    )
                                                }}
                                            >
                                                {clientInfo.recordCount}
                                            </div>
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                    <div style={{ top: 0 }}>
                        <table className={style.tablestyle}>
                            <thead>
                                <tr>
                                    <th className={style.thstyle}>
                                        Business Type
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                {overview?.businessTypeInfoList?.map(
                                    (businessType) => (
                                        <tr
                                            key={businessType.sequenceNo}
                                            className={style.trstyle}
                                        >
                                            <td className={style.tdlabel}>
                                                {businessType.description}
                                            </td>
                                            <td className={style.tdCount}>
                                                <div
                                                    onClick={() => {
                                                        handleRecordCountClick(
                                                            businessType.sequenceNo
                                                        )
                                                    }}
                                                >
                                                    {businessType.recordCount}
                                                </div>
                                            </td>
                                        </tr>
                                    )
                                )}
                            </tbody>
                        </table>
                    </div>
                    <div>
                        <table className={style.tablestyle}>
                            <thead>
                                <tr>
                                    <th className={style.thstyle}>
                                        Prospect Client
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                {overview?.prospectiveClientInfoList?.map(
                                    (ppclientInfo) => (
                                        <tr
                                            key={ppclientInfo.sequenceNo}
                                            className={style.trstyle}
                                        >
                                            <td className={style.tdlabel}>
                                                {ppclientInfo.description}
                                            </td>
                                            <td className={style.tdCount}>
                                                <div
                                                    onClick={() => {
                                                        handleRecordCountClick(
                                                            ppclientInfo.sequenceNo
                                                        )
                                                    }}
                                                >
                                                    {ppclientInfo.recordCount}
                                                </div>
                                            </td>
                                        </tr>
                                    )
                                )}
                            </tbody>
                        </table>
                    </div>
                    <div>
                        <table className={style.tablestyle}>
                            <thead>
                                <tr>
                                    <th className={style.thstyle}>Services</th>
                                </tr>
                            </thead>
                            <tbody>
                                {overview?.serviceActiveInfoList?.map(
                                    (service) => (
                                        <tr
                                            key={service.sequenceNo}
                                            className={style.trstyle}
                                        >
                                            <td className={style.tdlabel}>
                                                {service.description}
                                            </td>
                                            <td className={style.tdCount}>
                                                <div
                                                    onClick={() => {
                                                        handleRecordCountClick(
                                                            service.sequenceNo
                                                        )
                                                    }}
                                                >
                                                    {service.recordCount}
                                                </div>
                                            </td>
                                        </tr>
                                    )
                                )}
                            </tbody>
                        </table>
                    </div>
                </Flex>
                <div style={{ marginTop: '20px' }}>
                    <Flex gap="middle" align="start" justify={'space-between'}>
                        <div>
                            <table className={style.tablestyle}>
                                <thead>
                                    <tr>
                                        <th className={style.thstyle}>
                                            Services
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {overview?.serviceSeparationInfoList?.map(
                                        (SSclientInfo) => (
                                            <tr
                                                key={SSclientInfo.sequenceNo}
                                                className={style.trstyle}
                                            >
                                                <td className={style.tdlabel}>
                                                    {SSclientInfo.description}
                                                </td>
                                                <td className={style.tdCount}>
                                                    <div
                                                        onClick={() => {
                                                            handleRecordCountClick(
                                                                SSclientInfo.sequenceNo
                                                            )
                                                        }}
                                                    >
                                                        {
                                                            SSclientInfo.recordCount
                                                        }
                                                    </div>
                                                </td>
                                            </tr>
                                        )
                                    )}
                                </tbody>
                            </table>
                        </div>
                        <div>
                            <table className={style.tablestyle}>
                                <thead>
                                    <tr>
                                        <th className={style.thstyle}>
                                            VAT Action(s)
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {overview?.overviewServiceStatusList?.map(
                                        (Overservice) => (
                                            <tr
                                                key={Overservice.actionId}
                                                className={style.trstyle}
                                            >
                                                <td className={style.tdlabel}>
                                                    {Overservice.actionName}
                                                </td>
                                                <td className={style.tdCount}>
                                                    <div
                                                        onClick={() => {
                                                            handleRecordCountClick(
                                                                Overservice.actionId
                                                            )
                                                        }}
                                                    >
                                                        {
                                                            Overservice.statusCount
                                                        }
                                                    </div>
                                                </td>
                                            </tr>
                                        )
                                    )}
                                </tbody>
                            </table>
                        </div>
                        <div className={style.tablestyleE}></div>
                        <div className={style.tablestyleE}></div>
                    </Flex>
                </div>
            </Card>
            <Modal
                footer={null}
                onCancel={changeCreateEditModalStatus}
                width={'50%'}
                title={'Overview'}
                open={isModalVisible}
            >
                <OverViewClientList
                    selectedSequenceNo={selectedSquence}
                    searchtextss={searchtext}
                />
            </Modal>
        </>
    )
}

const mapStateToProps = (state: any): InitialOverviewStateInterface => ({
    overview: state.dashboardInfo.overview,
})

export default connect(mapStateToProps)(OverViewClient)
