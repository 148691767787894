import axios from '../Config/AxiosConfig'
import {
    ClientserviceUserList,
    clientServicecorporateList,
    clientserviceIndividualList,
    clientservicePartnershipList,
    clientserviceSoltraderList,
    serviceUserList,
} from '../Reducer/ServiceSetup'
import { setLoadingStatus } from '../Reducer/Common'
import {
    type ServiceUserPostData,
    type ServiceListViewType,
    type SwitchUserFormData,
} from '../Types/ServiceSetup/ServiveUser'
import { ErrorMessage, SuccessMessage } from '../Utils/Notification'

import { type Dispatch } from '@reduxjs/toolkit'
import {
    type SoleTraderData,
    type ClientServiceCorporateRegistrationViewDefaultUser,
    type PartnershipData,
    type IndividualData,
    type ServiceDetails,
} from '../Types/ServiceSetup/ClientServiceUser'
import {
    type ListBackendInterface,
    type ListFrontEndInterface,
    type TableListInterface,
} from '../Types/CommonType'

export const GetServiceUserList = (dispatch: Dispatch): void => {
    dispatch(setLoadingStatus({ status: true }))

    axios
        .get(`/api/ServiceConfiguration/GetServiceList`)
        .then((response) => {
            if (response.status === 200) {
                const newData = response?.data?.data?.map(
                    (value: ServiceListViewType) => {
                        return {
                            ...value,
                            key: value.serviceId,
                        }
                    }
                )
                response.data.data = newData
                dispatch(serviceUserList({ ...response.data }))
            } else {
                ErrorMessage(response.data.operationMsg)
            }
            dispatch(setLoadingStatus({ status: false }))
        })
        .catch((error) => {
            ErrorMessage(error.response.data.operationMsg)
            console.error('Error fetching posts:', error)
            dispatch(setLoadingStatus({ status: false }))
        })
}

export const GetDefaultAssignee = async (
    dispatch: Dispatch,
    serviceId: number,
    getAddress: CallableFunction
): Promise<void> => {
    dispatch(setLoadingStatus({ status: true }))
    axios
        .get(
            `/api/ServiceConfiguration/GetDefaultAssigneeByServiceId/${serviceId}`
        )
        .then((response) => {
            if (response.status === 200) {
                const addressData = response.data
                getAddress(addressData)
            } else {
                ErrorMessage(response.data.operationMsg)
            }
            dispatch(setLoadingStatus({ status: false }))
        })
        .catch((error) => {
            ErrorMessage(error.response.data.operationMsg)
            console.error('Error fetching data:', error)
            dispatch(setLoadingStatus({ status: false }))
        })
}

export const GetDefaultWorkingUser = async (
    dispatch: Dispatch,
    serviceId: number,
    getAddress: CallableFunction
): Promise<void> => {
    dispatch(setLoadingStatus({ status: true }))
    axios
        .get(`/api/ServiceConfiguration/GetDefaultUserByServiceId/${serviceId}`)
        .then((response) => {
            if (response.status === 200) {
                const addressData = response.data
                getAddress(addressData)
            } else {
                ErrorMessage(response.data.operationMsg)
            }
            dispatch(setLoadingStatus({ status: false }))
        })
        .catch((error) => {
            ErrorMessage(error.response.data.operationMsg)
            console.error('Error fetching data:', error)
            dispatch(setLoadingStatus({ status: false }))
        })
}

export const SetAssigneeUser = async (
    dispatch: Dispatch,
    data: ServiceUserPostData
): Promise<any> => {
    dispatch(setLoadingStatus({ status: true }))
    return await axios
        .post(`/api/ServiceConfiguration/SetDefaultAssigneeUser/`, data)
        .then((response) => {
            dispatch(setLoadingStatus({ status: false }))
            if (response.status === 200) {
                SuccessMessage(response.data.operationMsg)
                return true
            } else {
                ErrorMessage(response.data.operationMsg)
                return false
            }
        })
        .catch((error) => {
            ErrorMessage(error.response.data.operationMsg)
            console.error('Error fetching posts:', error)
            dispatch(setLoadingStatus({ status: false }))
            return false
        })
}

export const SetWorkingUser = async (
    dispatch: Dispatch,
    data: ServiceUserPostData
): Promise<any> => {
    dispatch(setLoadingStatus({ status: true }))
    return await axios
        .post(`/api/ServiceConfiguration/SetDefaultUser/`, data)
        .then((response) => {
            dispatch(setLoadingStatus({ status: false }))
            if (response.status === 200) {
                SuccessMessage(response.data.operationMsg)
                return true
            } else {
                ErrorMessage(response.data.operationMsg)
                return false
            }
        })
        .catch((error) => {
            ErrorMessage(error.response.data.operationMsg)
            console.error('Error fetching posts:', error)
            dispatch(setLoadingStatus({ status: false }))
            return false
        })
}

export const SetProcessLevelSwitchUser = async (
    dispatch: Dispatch,
    data: SwitchUserFormData
): Promise<any> => {
    dispatch(setLoadingStatus({ status: true }))
    return await axios
        .post(`/api/ServiceConfiguration/SetProcessLevelSwitchUser/`, data)
        .then((response) => {
            dispatch(setLoadingStatus({ status: false }))
            if (response.status === 200) {
                SuccessMessage(response.data.operationMsg)
                return true
            } else {
                ErrorMessage(response.data.operationMsg)
                return false
            }
        })
        .catch((error) => {
            ErrorMessage(error.response.data.operationMsg)
            console.error('Error fetching posts:', error)
            dispatch(setLoadingStatus({ status: false }))
            return false
        })
}

export const GetClientServiceCorporateList = (
    dispatch: Dispatch,
    data: TableListInterface,
    clientCategoryId: number,
    serviceId: number
): void => {
    dispatch(setLoadingStatus({ status: true }))
    const { pageNo, pageSize, searchCriteriaList, sortOrderList } = data
    const bodyData = {
        searchCriteriaList,
        sortOrderList,
    }

    axios
        .post(
            `api/ServiceConfiguration/GetCorporateCompanyList/${clientCategoryId}/${serviceId}/${pageNo}/${pageSize}`,
            bodyData
        )
        .then((response) => {
            if (response.status === 200) {
                const newData = response?.data?.data?.map(
                    (
                        value: ClientServiceCorporateRegistrationViewDefaultUser
                    ) => {
                        return {
                            ...value,
                            key: value.corporateid,
                        }
                    }
                )
                response.data.data = newData
                dispatch(clientServicecorporateList({ ...response.data }))
            } else {
                ErrorMessage(response.data.operationMsg)
            }
            dispatch(setLoadingStatus({ status: false }))
        })
        .catch((error) => {
            ErrorMessage(error.response.data.operationMsg)
            console.error('Error fetching posts:', error)
            dispatch(setLoadingStatus({ status: false }))
        })
}

export const GetClientServiceSoletraderList = (
    dispatch: Dispatch,
    data: TableListInterface,
    clientCategoryId: number,
    serviceId: number
): void => {
    dispatch(setLoadingStatus({ status: true }))
    const { pageNo, pageSize, searchCriteriaList, sortOrderList } = data
    const bodyData = {
        searchCriteriaList,
        sortOrderList,
    }

    axios
        .post(
            `/api/SoleTrader/GetSoleTraderList/${clientCategoryId}/${serviceId}/${pageNo}/${pageSize}`,
            bodyData
        )
        .then((response) => {
            if (response.status === 200) {
                const newData = response?.data?.data?.map(
                    (value: SoleTraderData) => {
                        return {
                            ...value,
                            key: value.soleTraderId,
                        }
                    }
                )
                response.data.data = newData
                dispatch(clientserviceSoltraderList({ ...response.data }))
            } else {
                ErrorMessage(response.data.operationMsg)
            }
            dispatch(setLoadingStatus({ status: false }))
        })
        .catch((error) => {
            ErrorMessage(error.response.data.operationMsg)
            console.error('Error fetching posts:', error)
            dispatch(setLoadingStatus({ status: false }))
        })
}

export const GetClientServiceInduvidualList = (
    dispatch: Dispatch,
    data: TableListInterface,
    clientCategoryId: number,
    serviceId: number
): void => {
    dispatch(setLoadingStatus({ status: true }))
    const { pageNo, pageSize, searchCriteriaList, sortOrderList } = data
    const bodyData = {
        searchCriteriaList,
        sortOrderList,
    }

    axios
        .post(
            `/api/Individual/GetIndividualList/${clientCategoryId}/${serviceId}/${pageNo}/${pageSize}`,
            bodyData
        )
        .then((response) => {
            if (response.status === 200) {
                const newData = response?.data?.data?.map(
                    (value: IndividualData) => {
                        return {
                            ...value,
                            key: value.individualId,
                        }
                    }
                )
                response.data.data = newData
                dispatch(clientserviceIndividualList({ ...response.data }))
            } else {
                ErrorMessage(response.data.operationMsg)
            }
            dispatch(setLoadingStatus({ status: false }))
        })
        .catch((error) => {
            ErrorMessage(error.response.data.operationMsg)
            console.error('Error fetching posts:', error)
            dispatch(setLoadingStatus({ status: false }))
        })
}

export const GetClientServicePartnershipList = (
    dispatch: Dispatch,
    data: TableListInterface,
    clientCategoryId: number,
    serviceId: number
): void => {
    dispatch(setLoadingStatus({ status: true }))
    const { pageNo, pageSize, searchCriteriaList, sortOrderList } = data
    const bodyData = {
        searchCriteriaList,
        sortOrderList,
    }

    axios
        .post(
            `/api/Partnership/GetPartnershipList/${clientCategoryId}/${serviceId}/${pageNo}/${pageSize}`,
            bodyData
        )
        .then((response) => {
            if (response.status === 200) {
                const newData = response?.data?.data?.map(
                    (value: PartnershipData) => {
                        return {
                            ...value,
                            key: value.no,
                        }
                    }
                )
                response.data.data = newData
                dispatch(clientservicePartnershipList({ ...response.data }))
            } else {
                ErrorMessage(response.data.operationMsg)
            }
            dispatch(setLoadingStatus({ status: false }))
        })
        .catch((error) => {
            ErrorMessage(error.response.data.operationMsg)
            console.error('Error fetching posts:', error)
            dispatch(setLoadingStatus({ status: false }))
        })
}

export const DeleteClientServiceUser = (
    dispatch: Dispatch,
    flegId: number,
    businessId: number,
    referenceId: number,
    getTableData: CallableFunction
): void => {
    dispatch(setLoadingStatus({ status: true }))
    axios
        .get(
            `api/SetDeleteRecordWithTwoParameter/26/${flegId}/${businessId}/${referenceId}`
        )
        .then((response) => {
            if (response.status === 200) {
                getTableData()
                SuccessMessage(response.data.operationMsg)
            } else {
                ErrorMessage(response.data.operationMsg)
            }
            dispatch(setLoadingStatus({ status: false }))
        })
        .catch((error) => {
            ErrorMessage(error.response.data.operationMsg)
            console.error('Error fetching get:', error)
            dispatch(setLoadingStatus({ status: false }))
        })
}

export const GetClientServiceUserList = (
    dispatch: Dispatch,
    flegId: number,
    serviceId: number
): void => {
    dispatch(setLoadingStatus({ status: true }))
    axios
        .get(
            `/api/GetStandardListingWithTwoParameter/49/${flegId}/${serviceId}`
        )
        .then((response) => {
            if (response.status === 200) {
                const uList: ListFrontEndInterface[] =
                    response?.data?.data?.map((data: ListBackendInterface) => {
                        return {
                            value: data.stdId,
                            label: data.stdName,
                        }
                    })
                dispatch(ClientserviceUserList(uList))
            } else {
                ErrorMessage(response.data.operationMsg)
            }
            dispatch(setLoadingStatus({ status: false }))
        })
        .catch((error) => {
            ErrorMessage(error.response.data.operationMsg)
            console.error('Error fetching get:', error)
            dispatch(setLoadingStatus({ status: false }))
        })
}

export const SaveClientServiceUser = async (
    dispatch: Dispatch,
    data: ServiceDetails
): Promise<any> => {
    dispatch(setLoadingStatus({ status: true }))
    return await axios
        .post(`/api/ServiceConfiguration/SetServiceClientDefaultUser/`, data)
        .then((response) => {
            dispatch(setLoadingStatus({ status: false }))
            if (response.status === 200) {
                SuccessMessage(response.data.operationMsg)
                return true
            } else {
                ErrorMessage(response.data.operationMsg)
                return false
            }
        })
        .catch((error) => {
            dispatch(setLoadingStatus({ status: false }))
            ErrorMessage(error.response.data.operationMsg)
            console.error('Error fetching posts:', error)
            return false
        })
}
