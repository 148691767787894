import axios from '../Config/AxiosConfig'
import type { Dispatch } from '@reduxjs/toolkit'
import { ErrorMessage } from '../Utils/Notification'
import { setLoadingStatus } from '../Reducer/Common'
import { eventListByUser } from '../Reducer/DashboardReducer'

export const GetEventListByUserId = async (
    dispatch: Dispatch
): Promise<void> => {
    dispatch(setLoadingStatus({ status: true }))
    await axios
        .get(`api/Management/GetEventListByUserId`)
        .then((response) => {
            if (response.status === 200) {
                dispatch(eventListByUser(response.data.data))
                dispatch(setLoadingStatus({ status: false }))
            } else {
                dispatch(setLoadingStatus({ status: false }))
                ErrorMessage(response.data.error.message)
            }
        })
        .catch((error) => {
            dispatch(setLoadingStatus({ status: false }))
            ErrorMessage(error)
        })
        .finally(() => {
            dispatch(setLoadingStatus({ status: false }))
        })
}
