import { Card, Col, Row, Button } from 'antd'
import React from 'react'
import customless from './custom.less'
import hederimage from '../../../../../../../Assest/Svg/pattern-2.svg'
import {
    PDFDocument,
    type PDFFont,
    type PDFPage,
    type RGB,
    rgb,
    StandardFonts,
} from 'pdf-lib'
import { useLocation } from 'react-router-dom'
import { convertImageToBase64 } from '../../../../../../../Components/base64ImageCovert'

const LetterOfEngagement = (): React.ReactElement => {
    const location = useLocation()
    const previewData = location.state?.previewqutation
    const businessDocumentTemplate = previewData?.businessDocumentTemplate
    const quotationHeader = previewData?.quotationHeader
    const quotationCompany = previewData?.quotationCompany
    const quotationDetails = previewData?.quotationDetails
    // const quotationFooter = previewData?.quotationFooter

    const handleDownloadPDFDoc = async (): Promise<void> => {
        const pdfDoc = await PDFDocument.create()
        const page1 = pdfDoc.addPage([650, 842]) // A4 size in points

        // Load and embed the fonts
        const timesRomanFont = await pdfDoc.embedFont(StandardFonts.TimesRoman)
        // const timesItalicFont = await pdfDoc.embedFont('Times-Italic')
        const timesRomanBoldFont = await pdfDoc.embedFont(
            StandardFonts.TimesRomanBold
        )
        const convertSvgToPng = async (svgUrl: string): Promise<string> => {
            return await new Promise((resolve, reject) => {
                const canvas = document.createElement('canvas')
                const ctx = canvas.getContext('2d')
                const img = new Image()

                img.onload = () => {
                    // Set canvas dimensions to match SVG
                    canvas.width = img.width
                    canvas.height = img.height

                    // Draw SVG on canvas
                    ctx?.drawImage(img, 0, 0)
                    resolve(canvas.toDataURL('image/png'))
                }

                img.onerror = reject
                img.src = svgUrl
            })
        }

        try {
            // Convert the SVG to PNG data URL
            const headerPngDataUrl = await convertSvgToPng(hederimage)
            const headerImageBytes = await fetch(headerPngDataUrl).then(
                async (res) => await res.arrayBuffer()
            )
            const headerImage = await pdfDoc.embedPng(headerImageBytes)

            // Draw the header image
            page1.drawImage(headerImage, {
                x: 25,
                y: 810,
                width: 600,
                height: 10,
            })
        } catch (error) {
            console.error('Error converting SVG to PNG:', error)
        }
        // Set the font size and color
        const fontColor = rgb(0, 0, 0)

        // Draw the header image
        // Draw company name and details
        page1.drawText(businessDocumentTemplate?.companyName ?? '', {
            x: 65,
            y: 780,
            size: 14,
            font: timesRomanBoldFont,
            color: fontColor,
        })

        // Draw additional details
        page1.drawText(
            `${businessDocumentTemplate?.addressOne ?? ''}, ${
                businessDocumentTemplate?.addressTwo ?? ''
            }, ${businessDocumentTemplate?.town ?? ''}, ${
                businessDocumentTemplate?.postCode ?? ''
            }, ${businessDocumentTemplate?.country ?? ''}`,
            {
                x: 65,
                y: 765,
                size: 7,
                font: timesRomanFont,
                color: fontColor,
            }
        )
        page1.drawText(businessDocumentTemplate?.website ?? '', {
            x: 65,
            y: 755,
            size: 7,
            font: timesRomanFont,
            color: fontColor,
        })

        const proxyUrl = 'https://cors-anywhere.herokuapp.com/'
        try {
            const imageUrl = `https://api.allorigins.win/raw?url=${businessDocumentTemplate?.companyLogo}`
            const base64String = await convertImageToBase64(imageUrl)

            const imageBytes = await fetch(base64String).then(
                async (res) => await res.arrayBuffer()
            )
            const image = await pdfDoc.embedPng(imageBytes)
            page1.drawImage(image, {
                x: 450,
                y: 750,
                width: 120,
                height: 50,
            })
        } catch (error) {
            console.error('Error embedding image:', error)
        }
        // Draw the Quotation title
        page1.drawText('LETTER OF ENGAGEMENT', {
            x: 65,
            y: 720,
            size: 24,
            font: timesRomanBoldFont,
            color: fontColor,
        })

        // Draw quotation details

        page1.drawText(
            [
                `${quotationCompany?.companyName}`,
                `${quotationCompany?.addressLine1},`,
                `${quotationCompany?.addressLine2}`,
                `${quotationCompany?.town}`,
                `${quotationCompany?.postCode}`,
                `${quotationCompany?.country}`,
            ].join('\n'),
            {
                x: 65,
                y: 705,
                size: 9,
                font: timesRomanFont,
                color: fontColor,
                lineHeight: 10,
            }
        )
        page1.drawText(`Dear  ${quotationCompany?.attentionName}`, {
            x: 65,
            y: 640,
            size: 8,
            font: timesRomanFont,
            color: fontColor,
        })
        const drawLeftAlignedText = (
            page1: PDFPage,
            text: string,
            x: number,
            y: number,
            width: number,
            fontSize: number,
            font: PDFFont,
            fontColor: RGB
        ): void => {
            const words = text.split(' ')
            let line = ''
            const lines = []
            const spaceWidth = font.widthOfTextAtSize(' ', 12)
            let currentWidth = 0

            // Calculate the lines of text
            words.forEach((word: string) => {
                const wordWidth = font.widthOfTextAtSize(word, fontSize)
                if (currentWidth + wordWidth + spaceWidth <= width) {
                    line += (line?.length > 0 ? ' ' : '') + word
                    currentWidth += wordWidth + spaceWidth
                } else {
                    lines.push(line)
                    line = word
                    currentWidth = wordWidth + spaceWidth
                }
            })
            lines.push(line)

            // Draw each line with left alignment
            lines.forEach((line, index) => {
                page1.drawText(line, {
                    x,
                    y: y - index * (fontSize + 2), // Adjust line height as needed
                    size: fontSize,
                    font,
                    color: fontColor,
                })
            })
        }

        drawLeftAlignedText(
            page1,
            `We are pleased to accept the instruction to act as accountants for your company, and we are writing to con.rm the terms of our appointment. This letter sets out the basis on which we are to act as accountants to the company and the respective areas of responsibilities of the directors and ourselves.`,
            65, // x position
            620, // y position
            520, // Width for wrapping text
            10,
            timesRomanFont,
            fontColor
        )
        page1.drawText(`1) PERIOD OF ENGAGEMENT`, {
            x: 65,
            y: 570,
            size: 13,
            font: timesRomanFont,
            color: fontColor,
        })
        page1.drawText(
            `Once agreed, this letter will remain effective from ${quotationHeader?.DateOfTermSigned}`,
            {
                x: 65,
                y: 555,
                size: 10,
                font: timesRomanFont,
                color: fontColor,
            }
        )
        page1.drawText(
            `We will also deal with matters arising in earlieryears as appropriate under a separate agreement.`,
            {
                x: 65,
                y: 540,
                size: 10,
                font: timesRomanFont,
                color: fontColor,
            }
        )
        page1.drawText(`2) YOUR RESPONSIBILITIES`, {
            x: 65,
            y: 510,
            size: 13,
            font: timesRomanFont,
            color: fontColor,
        })
        drawLeftAlignedText(
            page1,
            `As a business, you are required by statute to prepare accounts (financial statements) for each .nancial year, giving a true and fair view of the company’s state of affairs and its pro.t or loss for that period. In preparing those accounts, you must:`,
            65, // x position
            490, // y position
            520, // Width for wrapping text
            10,
            timesRomanFont,
            fontColor
        )
        page1.drawText(
            [
                `• Select suitable accounting policies and then apply them consistently;`,
                `• Make judgements and estimates that are reasonable and prudent; and`,
            ].join('\n'),
            {
                x: 85,
                y: 460,
                size: 9,
                font: timesRomanFont,
                color: fontColor,
                lineHeight: 10,
            }
        )
        drawLeftAlignedText(
            page1,
            `• Prepare the accounts on the going concern basis unless it is not appropriate to presume that the company will continue in business`,
            85, // x position
            440, // y position
            520, // Width for wrapping text
            10,
            timesRomanFont,
            fontColor
        )
        drawLeftAlignedText(
            page1,
            ` It is your responsibility to keep proper accounting records, which disclose with reasonable accuracy at any particular time of the financial position of the business. It is also your responsibility to safeguard the assets of the business and take reasonable steps to prevent and detect fraud and other irregularities with an appropriate system of internal controls.`,
            65, // x position
            410, // y position
            520, // Width for wrapping text
            10,
            timesRomanFont,
            fontColor
        )

        drawLeftAlignedText(
            page1,
            ` You are responsible for determining whether the company meets the conditions for exemption from an audit in respect of the year concerned.`,
            65, // x position
            360, // y position
            520, // Width for wrapping text
            10,
            timesRomanFont,
            fontColor
        )
        drawLeftAlignedText(
            page1,
            ` You are also responsible for making available to us, as and when required, all the company’s accounting records and all other relevant records and related information, including minutes of management and shareholders’ meetings.`,
            65, // x position
            330, // y position
            520, // Width for wrapping text
            10,
            timesRomanFont,
            fontColor
        )
        page1.drawText(`You will also be responsible for:`, {
            x: 65,
            y: 295,
            size: 10,
            font: timesRomanFont,
            color: fontColor,
        })

        page1.drawText(
            [
                `• Maintaining records of all receipts and payments of cash;`,
                `• Keeping records of invoices issued and received;`,
                `• Reconciling balances monthly/annually with the bank statements;`,
                `• Preparing details of the following at the year-end:`,
            ].join('\n'),
            {
                x: 85,
                y: 275,
                size: 10,
                font: timesRomanFont,
                color: fontColor,
                lineHeight: 10,
            }
        )
        page1.drawText(
            [
                `• Stocks and work in progress;`,
                `• Fixed assets;`,
                `• Amounts owing to suppliers;`,
                `• Amounts owing by customers; and`,
                `• Accruals and prepayments.`,
            ].join('\n'),
            {
                x: 105,
                y: 230,
                size: 10,
                font: timesRomanFont,
                color: fontColor,
                lineHeight: 10,
            }
        )

        drawLeftAlignedText(
            page1,
            ` You will keep us informed of material changes in circumstances that could affect your tax liabilities. If you are unsure whether the change is material or not, please let us know so that we can assess its significance.`,
            65, // x position
            170, // y position
            520, // Width for wrapping text
            10,
            timesRomanFont,
            fontColor
        )

        drawLeftAlignedText(
            page1,
            ` As part of our standard procedures, we may request you to provide written con.rmation of any oral information and explanations given to us during the course of our work.`,
            65, // x position
            130, // y position
            520, // Width for wrapping text
            10,
            timesRomanFont,
            fontColor
        )
        page1.drawLine({
            start: { x: 25, y: 15 },
            end: { x: 600, y: 15 },
            thickness: 1,
            color: rgb(0, 0, 0),
        })
        page1.drawText(
            `Registered in England 06509841 | ACCA Registra on number 2819986`,
            {
                x: 200,
                y: 7,
                size: 8,
                font: timesRomanFont,
                color: fontColor,
            }
        )
        const page2 = pdfDoc.addPage([650, 842]) // Second page (A4 size)

        // Set the font size and color for the second page

        // Draw content on the second page

        page2.drawText(`3) OUR RESPONSIBILITIES`, {
            x: 65,
            y: 780,
            size: 13,
            font: timesRomanFont,
            color: fontColor,
        })

        const drawLeftAlignedTextP2 = (
            page1: PDFPage,
            text: string,
            x: number,
            y: number,
            width: number,
            fontSize: number,
            font: PDFFont,
            fontColor: RGB
        ): void => {
            const words = text.split(' ')
            let line = ''
            const lines = []
            const spaceWidth = font.widthOfTextAtSize(' ', 12)
            let currentWidth = 0

            // Calculate the lines of text
            words.forEach((word: string) => {
                const wordWidth = font.widthOfTextAtSize(word, fontSize)
                if (currentWidth + wordWidth + spaceWidth <= width) {
                    line += (line?.length > 0 ? ' ' : '') + word
                    currentWidth += wordWidth + spaceWidth
                } else {
                    lines.push(line)
                    line = word
                    currentWidth = wordWidth + spaceWidth
                }
            })
            lines.push(line)

            // Draw each line with left alignment
            lines.forEach((line, index) => {
                page2.drawText(line, {
                    x,
                    y: y - index * (fontSize + 2), // Adjust line height as needed
                    size: fontSize,
                    font,
                    color: fontColor,
                })
            })
        }

        drawLeftAlignedTextP2(
            page2,
            ` We shall compile the financial statements (profit and loss account and balance sheet and relevant notes) for your approval based on the accounting records maintained by you and the information and explanations given to us by you.`,
            65, // x position
            760, // y position
            520, // Width for wrapping text
            10,
            timesRomanFont,
            fontColor
        )
        drawLeftAlignedTextP2(
            page2,
            ` We shall plan our work on the basis that no report is required by statute or regulation for the year unless you inform us in writing to the contrary.`,
            65, // x position
            730, // y position
            520, // Width for wrapping text
            10,
            timesRomanFont,
            fontColor
        )
        drawLeftAlignedTextP2(
            page2,
            ` Our work will not be an audit of the accounts in accordance with Auditing Standards. Accordingly, we shall not seek any independent evidence to support the accounting records entries or prove the existence, ownership or valuation of assets or completeness of income, liabilities or disclosure in the accounts. Nor shall we assess the reasonableness of any estimates or judgements made in the preparation of the accounts. Consequently, our work will not provide any assurance that the accounting records are free from material misstatement, irregularities or error.`,
            65, // x position
            700, // y position
            520, // Width for wrapping text
            10,
            timesRomanFont,
            fontColor
        )
        drawLeftAlignedTextP2(
            page2,
            ` We have a professional duty to compile Accounts, which conform to generally accepted accounting principles. Where we identify that the Accounts do not conform to accepted accounting principles, or if the accounting policies adopted are not immediately apparent, this will be made clear in our report if it is not clear in the Accounts.`,
            65, // x position
            630, // y position
            520, // Width for wrapping text
            10,
            timesRomanFont,
            fontColor
        )
        drawLeftAlignedTextP2(
            page2,
            ` We also have a professional responsibility not to allow our name to be associated with financial statements, which we believe may be misleading. Therefore, although we are not required to search for such matters, should we become aware, for any reason, that the financial statements may be misleading, we will discuss the matter with you with the view to agreeing on appropriate adjustments and/or disclosures in the financial statements. In extreme cases where this matter cannot be resolved, we will withdraw from the engagement and notify you in writing of the reasons.`,
            65, // x position
            580, // y position
            520, // Width for wrapping text
            10,
            timesRomanFont,
            fontColor
        )
        page2.drawText(`4) GENERAL SERVICES`, {
            x: 65,
            y: 500,
            size: 13,
            font: timesRomanFont,
            color: fontColor,
        })
        drawLeftAlignedTextP2(
            page2,
            `We agree to provide you the following services if applicable when necessary. `,
            65, // x position
            480, // y position
            520, // Width for wrapping text
            10,
            timesRomanFont,
            fontColor
        )

        function drawWrappedText(
            page: PDFPage,
            text: string,
            x: number,
            y: number,
            font: PDFFont,
            fontSize: number,
            color: RGB,
            maxWidth: number
        ): number {
            const words = text.split(' ')
            let line = ''
            let yOffset = 0

            for (let i = 0; i < words?.length; i++) {
                const testLine = line + words[i] + ' '
                const testLineWidth = font.widthOfTextAtSize(testLine, fontSize)

                if (testLineWidth > maxWidth && i > 0) {
                    // Draw the current line
                    page.drawText(line.trim(), {
                        x,
                        y: y - yOffset,
                        size: fontSize,
                        font,
                        color,
                    })

                    // Start a new line with the current word
                    line = words[i] + ' '
                    yOffset += fontSize + 2 // Adjust the line height as needed
                } else {
                    line = testLine
                }
            }

            // Draw the last line
            if (line.trim()?.length > 0) {
                page.drawText(line.trim(), {
                    x,
                    y: y - yOffset,
                    size: fontSize,
                    font,
                    color,
                })
            }

            return yOffset
        }

        // Usage inside your loop
        let yPos = 460
        quotationDetails?.forEach(async (detail: any) => {
            page2.drawText(
                `${detail.sequenceNo ?? ''}. ${
                    detail.quotationDescription ?? ''
                }`,
                {
                    x: 85,
                    y: yPos,
                    size: 11,
                    font: timesRomanFont,
                    color: fontColor,
                }
            )

            const maxWidth = 550 - 70
            const yOffset = drawWrappedText(
                page2,
                detail.description ?? '',
                105,
                yPos - 20,
                timesRomanFont,
                9,
                fontColor,
                maxWidth
            )
            yPos -= 40 + yOffset
        })

        page2.drawText(`AD HOC AND ADVISORY WORK`, {
            x: 65,
            y: 170,
            size: 10,
            font: timesRomanFont,
            color: fontColor,
        })
        drawLeftAlignedTextP2(
            page2,
            ` Where you have instructed us to do so, we will also provide other additional services from time to time. These may be subject to a separate engagement letter/or an agreement, in our opinion. Where appropriate, we will discuss and agree to an additional fee for such work when you commissioned it.`,
            65, // x position
            150, // y position
            520, // Width for wrapping text
            10,
            timesRomanFont,
            fontColor
        )
        page2.drawText(`5) FEES`, {
            x: 65,
            y: 100,
            size: 13,
            font: timesRomanFont,
            color: fontColor,
        })
        page2.drawText(`General Service Charges`, {
            x: 65,
            y: 80,
            size: 10,
            font: timesRomanFont,
            color: fontColor,
        })
        drawLeftAlignedTextP2(
            page2,
            ` Our fees may depend not only upon the time spent on your affairs but also on the level of skill, responsibility, importance and value of the advice that we provide. Unless otherwise agreed, our fees will be billed at appropriate intervals during the course of the year.`,
            65, // x position
            50, // y position
            520, // Width for wrapping text
            10,
            timesRomanFont,
            fontColor
        )
        page2.drawLine({
            start: { x: 25, y: 15 },
            end: { x: 600, y: 15 },
            thickness: 1,
            color: rgb(0, 0, 0),
        })
        page2.drawText(
            `Registered in England 06509841 | ACCA Registra on number 2819986`,
            {
                x: 200,
                y: 7,
                size: 8,
                font: timesRomanFont,
                color: fontColor,
            }
        )

        const page3 = pdfDoc.addPage([650, 842])
        const drawLeftAlignedTextP3 = (
            page1: PDFPage,
            text: string,
            x: number,
            y: number,
            width: number,
            fontSize: number,
            font: PDFFont,
            fontColor: RGB
        ): void => {
            const words = text.split(' ')
            let line = ''
            const lines = []
            const spaceWidth = font.widthOfTextAtSize(' ', 12)
            let currentWidth = 0

            // Calculate the lines of text
            words.forEach((word: string) => {
                const wordWidth = font.widthOfTextAtSize(word, fontSize)
                if (currentWidth + wordWidth + spaceWidth <= width) {
                    line += (line?.length > 0 ? ' ' : '') + word
                    currentWidth += wordWidth + spaceWidth
                } else {
                    lines.push(line)
                    line = word
                    currentWidth = wordWidth + spaceWidth
                }
            })
            lines.push(line)

            // Draw each line with left alignment
            lines.forEach((line, index) => {
                page3.drawText(line, {
                    x,
                    y: y - index * (fontSize + 2), // Adjust line height as needed
                    size: fontSize,
                    font,
                    color: fontColor,
                })
            })
        }
        drawLeftAlignedTextP3(
            page3,
            ` Where requested, we may indicate a fixed fee for the provision of specific services or an indicative range of fees for a particular assignment. It is not our practice to identify fixed fees for more than a year ahead as such fee quotes need to be reviewed in the light of events. If it becomes apparent to us, due to unforeseen ircumstances, that a fee quote is inadequate, we reserve the right to notify you of a revised figure or range and to seek your agreement thereto.`,
            65, // x position
            780, // y position
            520, // Width for wrapping text
            10,
            timesRomanFont,
            fontColor
        )
        drawLeftAlignedTextP3(
            page3,
            ` Charges for additional services`,
            65, // x position
            720, // y position
            520, // Width for wrapping text
            10,
            timesRomanFont,
            fontColor
        )
        drawLeftAlignedTextP3(
            page3,
            `Where you have instructed us to do so, we will also provide additional services. Examples of such work include:`,
            65, // x position
            700, // y position
            520, // Width for wrapping text
            10,
            timesRomanFont,
            fontColor
        )
        page3.drawText(
            [
                `• Dealing with any enquiry opened into the self-assessment tax return by HMRC;`,
                `• Preparing any amended returns, which may be required and corresponding with HMRC as necessary;`,
                `• Business plans;`,
            ].join('\n'),
            {
                x: 105,
                y: 680,
                size: 10,
                font: timesRomanFont,
                color: fontColor,
                lineHeight: 13,
            }
        )
        drawLeftAlignedTextP3(
            page3,
            `We will advise you of the estimated cost in advance before we undertake any such services.`,
            65, // x position
            630, // y position
            520, // Width for wrapping text
            10,
            timesRomanFont,
            fontColor
        )
        drawLeftAlignedTextP3(
            page3,
            `On occasions, where appropriate, we advise our clients to obtain personal liability insurance to protect themselves against the professional cost of dealing with specific cases. In such circumstances, you may be entitled to assistance with your professional fees, particularly in relation to any investigation into your tax affairs by HMRC. We may provide assistance through insurance policies you hold; however, you will remain liable for our fees regardless of whether all or part are liable to be paid by your insurers.`,
            65, // x position
            610, // y position
            520, // Width for wrapping text
            10,
            timesRomanFont,
            fontColor
        )
        drawLeftAlignedTextP3(
            page3,
            ` If you do not accept that an invoiced fee is fair and reasonable, you must notify us immediately.`,
            65, // x position
            540, // y position
            520, // Width for wrapping text
            10,
            timesRomanFont,
            fontColor
        )

        page3.drawText(`Lien`, {
            x: 65,
            y: 520,
            size: 10,
            font: timesRomanFont,
            color: fontColor,
        })
        drawLeftAlignedTextP3(
            page3,
            ` In so far as we are permitted to do so by law or professional guidelines, we reserve the right to exercise a lien over all funds, documents and records in our possession relating to all engagements for you until all outstanding fees and disbursements are paid in full.`,
            65, // x position
            500, // y position
            520, // Width for wrapping text
            10,
            timesRomanFont,
            fontColor
        )
        page3.drawText(`6) LIMITATION OF LIABILITY`, {
            x: 65,
            y: 450,
            size: 13,
            font: timesRomanFont,
            color: fontColor,
        })
        drawLeftAlignedTextP3(
            page3,
            `  We will provide our services with reasonable care and skill. Our liability to you is limited to losses, damages, costs and expenses directly caused by our negligence or wilful default.`,
            65, // x position
            430, // y position
            520, // Width for wrapping text
            10,
            timesRomanFont,
            fontColor
        )
        drawLeftAlignedTextP3(
            page3,
            `  Exclusion of liability for loss caused by others`,
            65, // x position
            400, // y position
            520, // Width for wrapping text
            10,
            timesRomanFont,
            fontColor
        )
        drawLeftAlignedTextP3(
            page3,
            ` We will not be liable if such losses, penalties, interest or additional tax liabilities are caused by the acts or omissions of any other person or due to the provision to us of incomplete, misleading or false information or if they are caused by a failure to act on our advice or a failure to provide us with relevant information.`,
            65, // x position
            380, // y position
            520, // Width for wrapping text
            10,
            timesRomanFont,
            fontColor
        )
        drawLeftAlignedTextP3(
            page3,
            ` Exclusion of liability in relation to circumstances beyond our control.`,
            65, // x position
            330, // y position
            520, // Width for wrapping text
            10,
            timesRomanFont,
            fontColor
        )
        drawLeftAlignedTextP3(
            page3,
            `  We will not be liable to you for any delay or failure to perform our obligations under this engagement letter if circumstances outside our reasonable control cause the delay or failure.`,
            65, // x position
            310, // y position
            520, // Width for wrapping text
            10,
            timesRomanFont,
            fontColor
        )
        drawLeftAlignedTextP3(
            page3,
            `Exclusion of liability relating to the discovery of fraud etc`,
            65, // x position
            280, // y position
            520, // Width for wrapping text
            10,
            timesRomanFont,
            fontColor
        )
        drawLeftAlignedTextP3(
            page3,
            `We will not be responsible or liable for any loss, damage or expense incurred or sustained if information material to the service we provide is withheld or concealed from us or misrepresented to us. This applies equally to fraudulent acts, misrepresentation or wilful default on the part of any party to the transaction and their directors, officers, employees, agents or advisers.`,
            65, // x position
            260, // y position
            520, // Width for wrapping text
            10,
            timesRomanFont,
            fontColor
        )
        drawLeftAlignedTextP3(
            page3,
            `This exclusion shall not apply where such  misrepresentation, withholding or concealment is or should (in carrying out the procedures which we have agreed to perform with reasonable care and skill) have been evident to us without further enquiry beyond that which it would have been reasonable for us to have carried out in the circumstances.`,
            65, // x position
            200, // y position
            520, // Width for wrapping text
            10,
            timesRomanFont,
            fontColor
        )
        drawLeftAlignedTextP3(
            page3,
            ` Indemnity for unauthorised disclosure`,
            65, // x position
            140, // y position
            520, // Width for wrapping text
            10,
            timesRomanFont,
            fontColor
        )
        drawLeftAlignedTextP3(
            page3,
            `  You agree to indemnify our agents and us in respect of any claim (including any claim for negligence) arising out of any unauthorised disclosure by you or by any person for whom you are responsible for our advice and opinions, whether in writing or otherwise. This indemnity will extend to the cost of defending any such claim, including payment at our usual rates for the time that we spend in defending it.`,
            65, // x position
            120, // y position
            520, // Width for wrapping text
            10,
            timesRomanFont,
            fontColor
        )
        page3.drawLine({
            start: { x: 25, y: 15 },
            end: { x: 600, y: 15 },
            thickness: 1,
            color: rgb(0, 0, 0),
        })
        page3.drawText(
            `Registered in England 06509841 | ACCA Registra on number 2819986`,
            {
                x: 200,
                y: 7,
                size: 8,
                font: timesRomanFont,
                color: fontColor,
            }
        )
        // Save the PDF and create a download link

        const page4 = pdfDoc.addPage([650, 842])
        const drawLeftAlignedTextP4 = (
            page1: PDFPage,
            text: string,
            x: number,
            y: number,
            width: number,
            fontSize: number,
            font: PDFFont,
            fontColor: RGB
        ): void => {
            const words = text.split(' ')
            let line = ''
            const lines = []
            const spaceWidth = font.widthOfTextAtSize(' ', 12)
            let currentWidth = 0

            // Calculate the lines of text
            words.forEach((word: string) => {
                const wordWidth = font.widthOfTextAtSize(word, fontSize)
                if (currentWidth + wordWidth + spaceWidth <= width) {
                    line += (line?.length > 0 ? ' ' : '') + word
                    currentWidth += wordWidth + spaceWidth
                } else {
                    lines.push(line)
                    line = word
                    currentWidth = wordWidth + spaceWidth
                }
            })
            lines.push(line)

            // Draw each line with left alignment
            lines.forEach((line, index) => {
                page4.drawText(line, {
                    x,
                    y: y - index * (fontSize + 2), // Adjust line height as needed
                    size: fontSize,
                    font,
                    color: fontColor,
                })
            })
        }
        page4.drawText(`7) LIMITATION OF THIRD-PARTY RIGHTS`, {
            x: 65,
            y: 780,
            size: 13,
            font: timesRomanFont,
            color: fontColor,
        })
        drawLeftAlignedTextP4(
            page4,
            ` The advice and information we provide to you as part of our service are for your sole use and not for any third party to whom you may communicate unless we have expressly agreed in the engagement letter that a specified third party may rely on our work. We accept no responsibility to third parties, including any group company to whom the engagement letter is not addressed, for any advice, information or material produced as part of our work for you that you make available to them. A party to this agreement is the only person who has the right to enforce any of its terms, and no rights or benefits are conferred on any third party under the Contracts (Rights of Third Parties) Act 1999.`,
            65, // x position
            760, // y position
            520, // Width for wrapping text
            10,
            timesRomanFont,
            fontColor
        )

        page4.drawText(`8) RELIANCE ON ADVICE`, {
            x: 65,
            y: 675,
            size: 13,
            font: timesRomanFont,
            color: fontColor,
        })
        drawLeftAlignedTextP4(
            page4,
            ` We will endeavour to record all advice on important matters in writing. Advice given orally is not intended to be relied upon unless confirmed in writing. Therefore, if we provide oral advice (for example, during the course of a meeting or a telephone conversation) and you wish to be able to rely on that advice, you must ask for the advice to be confirmed by us in writing.`,
            65, // x position
            655, // y position
            520, // Width for wrapping text
            10,
            timesRomanFont,
            fontColor
        )

        page4.drawText(`9) CHANGES IN THE LAW OR PUBLIC POLICY AND PRACTICE`, {
            x: 65,
            y: 610,
            size: 13,
            font: timesRomanFont,
            color: fontColor,
        })
        drawLeftAlignedTextP4(
            page4,
            ` We will not accept responsibility if you act on advice given by us on an earlier occasion without first confirming with us that the advice is still valid in the light of any change in the law or public policy and practice or your circumstances.`,
            65, // x position
            590, // y position
            520, // Width for wrapping text
            10,
            timesRomanFont,
            fontColor
        )
        drawLeftAlignedTextP4(
            page4,
            `We will accept no liability for losses arising from changes in the law (or the interpretation thereof) or public policy and practice that are first published after the date on which the advice is given.`,
            65, // x position
            560, // y position
            520, // Width for wrapping text
            10,
            timesRomanFont,
            fontColor
        )

        page4.drawText(`10) CONFLICTS OF INTEREST`, {
            x: 65,
            y: 520,
            size: 13,
            font: timesRomanFont,
            color: fontColor,
        })
        drawLeftAlignedTextP4(
            page4,
            ` We will inform you if we become aware of any conflict of interest in our relationship with you or in our relationship with you and another client unless we are unable to do so because of our confidentiality obligations.  We have safeguards that can be implemented to protect the interests of different clients if a conflict arises. Where conflicts are identified that cannot be managed in a way that protects your interests, and we regret that we will not provide further services. If this arises, we will inform you promptly.`,
            65, // x position
            500, // y position
            520, // Width for wrapping text
            10,
            timesRomanFont,
            fontColor
        )
        drawLeftAlignedTextP4(
            page4,
            ` If there is a conflict of interest that is capable of being addressed successfully by the adoption of suitable safeguards to protect your interests, then we will adopt those safeguards. Where possible, this will be done on the basis of your informed consent. We reserve the right to act for other clients whose interests are not the same as or are adverse to yours, subject to the obligations of confidentiality referred to above.`,
            65, // x position
            430, // y position
            520, // Width for wrapping text
            10,
            timesRomanFont,
            fontColor
        )
        page4.drawText(`11) CONFIDENTIALITY`, {
            x: 65,
            y: 370,
            size: 13,
            font: timesRomanFont,
            color: fontColor,
        })
        drawLeftAlignedTextP4(
            page4,
            `We confirm that we will comply with the provisions of the Data Protection Act 2018 when processing personal data about you. In order to carry out the services of this engagement, and for related purposes such as updating and enhancing our client records, analysis for management purposes and statutory returns, legal and regulatory compliance and crime prevention, we may obtain, process, use and disclose personal data about you.`,
            65, // x position
            350, // y position
            520, // Width for wrapping text
            10,
            timesRomanFont,
            fontColor
        )
        drawLeftAlignedTextP4(
            page4,
            `As an accounting practitioner, we are obliged to keep our client information confidential; therefore, we take all reasonable steps to preserve confidentiality and will never share information relating to your business with any other third party without prior consent unless required to do so by law, by regulatory bodies or by insurers to disclose information about our clients. Likewise, you agree not to use or copy or allow the use of the output of the work we do for with a third party without our permission.`,
            65, // x position
            290, // y position
            520, // Width for wrapping text
            10,
            timesRomanFont,
            fontColor
        )
        page4.drawText(`12) RETENTION OF RECORDS`, {
            x: 65,
            y: 220,
            size: 13,
            font: timesRomanFont,
            color: fontColor,
        })
        drawLeftAlignedTextP4(
            page4,
            `You have a legal responsibility to retain documents and records relevant to your tax  affairs. During the course of our work, we may collect information from you and others relevant to your tax affairs. We will return any original documents to you. We are obliged to keep documents and records pertinent to your tax affairs for a period of no less than six years from the end of the tax year in question.`,
            65, // x position
            200, // y position
            520, // Width for wrapping text
            10,
            timesRomanFont,
            fontColor
        )
        drawLeftAlignedTextP4(
            page4,
            ` While certain documents may legally belong to you, we may destroy correspondence and other papers that we store, electronically or otherwise, which are more than seven years old. You must tell us if you require the return of any specific document or their retention for a more extended period.`,
            65, // x position
            140, // y position
            520, // Width for wrapping text
            10,
            timesRomanFont,
            fontColor
        )

        page4.drawText(`13) ELECTRONIC COMMUNICATION`, {
            x: 65,
            y: 90,
            size: 13,
            font: timesRomanFont,
            color: fontColor,
        })
        drawLeftAlignedTextP4(
            page4,
            `Unless you instruct us otherwise, we may communicate with you via email or by other electronic means. The recipient is responsible for virus checking emails and any attachments.`,
            65, // x position
            75, // y position
            520, // Width for wrapping text
            10,
            timesRomanFont,
            fontColor
        )
        page4.drawLine({
            start: { x: 25, y: 15 },
            end: { x: 600, y: 15 },
            thickness: 1,
            color: rgb(0, 0, 0),
        })
        page4.drawText(
            `Registered in England 06509841 | ACCA Registra on number 2819986`,
            {
                x: 200,
                y: 7,
                size: 8,
                font: timesRomanFont,
                color: fontColor,
            }
        )
        const page5 = pdfDoc.addPage([650, 842])
        const drawLeftAlignedTextP5 = (
            page1: PDFPage,
            text: string,
            x: number,
            y: number,
            width: number,
            fontSize: number,
            font: PDFFont,
            fontColor: RGB
        ): void => {
            const words = text.split(' ')
            let line = ''
            const lines = []
            const spaceWidth = font.widthOfTextAtSize(' ', 12)
            let currentWidth = 0

            // Calculate the lines of text
            words.forEach((word: string) => {
                const wordWidth = font.widthOfTextAtSize(word, fontSize)
                if (currentWidth + wordWidth + spaceWidth <= width) {
                    line += (line?.length > 0 ? ' ' : '') + word
                    currentWidth += wordWidth + spaceWidth
                } else {
                    lines.push(line)
                    line = word
                    currentWidth = wordWidth + spaceWidth
                }
            })
            lines.push(line)

            // Draw each line with left alignment
            lines.forEach((line, index) => {
                page5.drawText(line, {
                    x,
                    y: y - index * (fontSize + 2), // Adjust line height as needed
                    size: fontSize,
                    font,
                    color: fontColor,
                })
            })
        }
        drawLeftAlignedTextP5(
            page5,
            `There is a risk of non-receipt, delayed receipt, inadvertent misdirection or interception by third parties with electronic communication. We use virus-scanning software to reduce the risk of viruses and similar damaging items being transmitted through emails or electronic storage devices. However, electronic communication is not totally secure. We cannot be held responsible for damage or loss caused by viruses or communications that are corrupted or altered after despatch. Nor can we accept any liability for problems or accidental errors relating to this means of communication, especially in relation to commercially sensitive material. These are risks you must bear in return for greater efficiency and lower costs. If you do not wish to accept these risks, please let us know, and we will communicate by hard copy, other than where electronic submission is mandatory.`,
            65, // x position
            780, // y position
            520, // Width for wrapping text
            10,
            timesRomanFont,
            fontColor
        )

        page5.drawText(`14) QUALITY OF SERVICE`, {
            x: 65,
            y: 670,
            size: 13,
            font: timesRomanFont,
            color: fontColor,
        })
        drawLeftAlignedTextP5(
            page5,
            ` We aim to provide a high quality of service at all times. If you would like to discuss how our service could be improved or if you are dissatisfied with the service you are receiving, please let us know. We undertake to look into any complaint carefully and promptly and do all we can to explain the position to you.`,
            65, // x position
            650, // y position
            520, // Width for wrapping text
            10,
            timesRomanFont,
            fontColor
        )
        page5.drawText(`15) APPLICABLE LAW`, {
            x: 65,
            y: 600,
            size: 13,
            font: timesRomanFont,
            color: fontColor,
        })
        drawLeftAlignedTextP5(
            page5,
            ` This engagement letter shall be governed by and construed in accordance with English law. The English courts shall have exclusive jurisdiction in relation to any claim, dispute of differences concerning the engagement letter and any matter arising from it. Each party irrevocably waives any right it may have to object to an action being brought in those Courts, to claim that the action has been brought in an inconvenient forum, or to claim that those Courts do not have jurisdiction.`,
            65, // x position
            580, // y position
            520, // Width for wrapping text
            10,
            timesRomanFont,
            fontColor
        )
        page5.drawText(`16) AGREEMENT OF TERMS`, {
            x: 65,
            y: 520,
            size: 13,
            font: timesRomanFont,
            color: fontColor,
        })
        drawLeftAlignedTextP5(
            page5,
            ` This letter supersedes any previous engagement letter. Once it has been agreed, this letter will remain effective until it is replaced. You or we may vary or terminate  our authority to act on your behalf at any time without penalty. Notice of variation or termination must be given in writing.`,
            65, // x position
            500, // y position
            520, // Width for wrapping text
            10,
            timesRomanFont,
            fontColor
        )
        page5.drawText(`Yours faithfully,`, {
            x: 70,
            y: 450,
            size: 10,
            font: timesRomanFont,
            color: fontColor,
        })

        const imageUrl3 = `https://api.allorigins.win/raw?url=${proxyUrl}${businessDocumentTemplate?.signatureLogo}`

        try {
            const base64String = await convertImageToBase64(imageUrl3)

            const imageBytes = await fetch(base64String).then(
                async (res) => await res.arrayBuffer()
            )
            const image = await pdfDoc.embedPng(imageBytes)
            page5.drawImage(image, {
                x: 90,
                y: 380,
                width: 120,
                height: 50,
            })
        } catch (error) {
            console.error('Error embedding image:', error)
        }

        const imageUrl2 = `https://api.allorigins.win/raw?url=${businessDocumentTemplate?.rubberStampLogo}`
        try {
            const base64String = await convertImageToBase64(imageUrl2)

            const imageBytes = await fetch(base64String).then(
                async (res) => await res.arrayBuffer()
            )
            const image = await pdfDoc.embedPng(imageBytes)
            page5.drawImage(image, {
                x: 400,
                y: 380,
                width: 120,
                height: 50,
            })
        } catch (error) {
            console.error('Error embedding image:', error)
        }

        page5.drawText(`IYKONS LIMITED`, {
            x: 75,
            y: 350,
            size: 10,
            font: timesRomanFont,
            color: fontColor,
        })
        page5.drawText(
            `I /WE ACKNOWLEDGE THE RECEIPT OF THIS LETTER, AND I/WE AGREE TO THE TERMS OF THIS LETTER.`,
            {
                x: 75,
                y: 330,
                size: 10,
                font: timesRomanBoldFont,
                color: fontColor,
            }
        )
        page5.drawLine({
            start: { x: 400, y: 280 },
            end: { x: 550, y: 280 },
            thickness: 1,
            color: rgb(0, 0, 0),
        })

        page5.drawText(`Signature`, {
            x: 455,
            y: 260,
            size: 10,
            font: timesRomanBoldFont,
            color: fontColor,
        })
        page5.drawLine({
            start: { x: 400, y: 220 },
            end: { x: 550, y: 220 },
            thickness: 1,
            color: rgb(0, 0, 0),
        })
        page5.drawText(`Date`, {
            x: 470,
            y: 200,
            size: 10,
            font: timesRomanBoldFont,
            color: fontColor,
        })

        // page5.drawText(`MR. KULASEKARAM JEYARAJ `, {
        //     x: 130,
        //     y: 300,
        //     size: 10,
        //     font: timesRomanFont,
        //     color: fontColor,
        // })
        // page5.drawText(
        //     `Capacity in which signed: Company Director (eg. Company `,
        //     {
        //         x: 90,
        //         y: 280,
        //         size: 10,
        //         font: timesRomanFont,
        //         color: fontColor,
        //     }
        // )
        // page5.drawText(`Director / Secretary) `, {
        //     x: 150,
        //     y: 260,
        //     size: 10,
        //     font: timesRomanFont,
        //     color: fontColor,
        // })
        page5.drawLine({
            start: { x: 25, y: 15 },
            end: { x: 600, y: 15 },
            thickness: 1,
            color: rgb(0, 0, 0),
        })
        page5.drawText(
            `Registered in England 06509841 | ACCA Registra on number 2819986`,
            {
                x: 200,
                y: 7,
                size: 8,
                font: timesRomanFont,
                color: fontColor,
            }
        )
        const pdfBytes = await pdfDoc.save()
        const blob = new Blob([pdfBytes], { type: 'application/pdf' })
        const link = document.createElement('a')
        link.href = URL.createObjectURL(blob)
        link.download = `Document_${quotationHeader?.referenceId}-${quotationHeader?.companyType}_LEO.pdf`
        link.click()
    }

    const handleDownloadPDF = (): void => {
        void handleDownloadPDFDoc()
    }

    return (
        <Card>
            <Row>
                <div
                    className={`${customless.body} avoid-page1-break`}
                    id="pdf-content"
                >
                    <div data-size="A4" className={customless.container}>
                        <div className={customless.div}>
                            <div className={customless.col_sm_12}>
                                <img
                                    src={hederimage}
                                    className={customless.col_sm}
                                />
                                <br />
                                <br />
                            </div>
                        </div>
                        <div className={customless.page1}>
                            <div>
                                <div className={customless.company_info}>
                                    <div className={customless.keep_print_font}>
                                        <h4 className={customless.h4}>
                                            {
                                                businessDocumentTemplate?.companyName
                                            }
                                            <small className={customless.small}>
                                                <br />
                                                {
                                                    businessDocumentTemplate?.addressOne
                                                }
                                                ,
                                                {
                                                    businessDocumentTemplate?.addressTwo
                                                }
                                                ,
                                                {businessDocumentTemplate?.town}
                                                ,
                                                {
                                                    businessDocumentTemplate?.postCode
                                                }
                                                ,
                                                {
                                                    businessDocumentTemplate?.country
                                                }
                                                ,
                                                <br />
                                                {
                                                    businessDocumentTemplate?.website
                                                }
                                            </small>
                                        </h4>
                                    </div>
                                    <img
                                        id="barcode"
                                        alt="companyLogo"
                                        src={
                                            businessDocumentTemplate?.companyLogo
                                        }
                                        className={customless.barcode}
                                    />
                                </div>

                                <h4 className={customless.h3_span}>
                                    <span>LETTER OF ENGAGEMENT</span>
                                </h4>
                            </div>

                            <div className={customless.table}>
                                <Row>
                                    <Col>
                                        <Row>
                                            {quotationCompany?.companyName}
                                        </Row>
                                        <Row>
                                            {quotationCompany?.addressLine1},
                                        </Row>
                                        <Row>
                                            {quotationCompany?.addressLine2},
                                        </Row>
                                        <Row>{quotationCompany?.town},</Row>
                                        <Row>{quotationCompany?.postCode},</Row>
                                        <Row>{quotationCompany?.country}</Row>
                                    </Col>
                                </Row>
                            </div>

                            <br />

                            <div>Dear {quotationCompany?.attentionName}</div>

                            <br />

                            <p className={customless.p}>
                                We are pleased to accept the instruction to act
                                as accountants for you, and we are writing to
                                confirm the terms of our appointment. This
                                letter sets out the basis on which we are to act
                                as accountants, our duties and the respective
                                areas of your responsibilities.
                            </p>

                            <div className={customless.p}>
                                <h3>1) PERIOD OF ENGAGEMENT</h3>
                                <p>
                                    Once agreed, this letter will remain
                                    effective from{' '}
                                    <b>{quotationHeader?.DateOfTermSigned}</b>.
                                </p>
                                <p>
                                    We will also deal with matters arising in
                                    earlier years as appropriate under a
                                    separate agreement.
                                </p>
                            </div>

                            <div className={customless.p}>
                                <h3>2) YOUR RESPONSIBILITIES</h3>
                                <p>
                                    You are required by statute to prepare
                                    accounts (financial statements) for each
                                    financial year, giving a true and fair view
                                    of the business activity and of its profit
                                    or loss for that period. In preparing those
                                    accounts, you must:
                                </p>
                                <ul>
                                    <li>
                                        Select suitable accounting policies and
                                        then apply them consistently;
                                    </li>
                                    <li>
                                        Make judgements and estimates that are
                                        reasonable and prudent; and
                                    </li>
                                </ul>
                                <p>
                                    It is your responsibility to keep proper
                                    accounting records, which disclose with
                                    reasonable accuracy at any particular time
                                    of the financial position of the business.
                                    It is also your responsibility to safeguard
                                    the assets of the business and take
                                    reasonable steps to prevent and detect fraud
                                    and other irregularities with an appropriate
                                    system of internal controls.
                                </p>
                                <p>
                                    It is your responsibility to provide full
                                    information necessary for dealing with your
                                    affairs; we will rely on the information and
                                    documents being true, correct, and complete
                                    and will not audit the information or those
                                    documents.
                                </p>
                                <p>
                                    All returns are to be made on the basis of
                                    full disclosure of all sources of income,
                                    charges, allowances and capital transactions
                                    and therefore, you are also responsible for
                                    making available to us, as and when
                                    required, all the business’s accounting
                                    records and all other relevant records and
                                    related information.
                                </p>
                                <p>You will also be responsible for:</p>
                                <ul>
                                    <li>
                                        Maintaining records of all receipts and
                                        payments of cash;
                                    </li>
                                    <li>
                                        Keeping records of invoices issued and
                                        received;
                                    </li>
                                    <li>
                                        Reconciling balances monthly/annually
                                        with the bank statements;
                                    </li>
                                    <li>
                                        Preparing details of the following at
                                        the year-end:
                                        <ul>
                                            <li>
                                                Stocks and work in progress;
                                            </li>
                                            <li>Fixed assets;</li>
                                            <li>Amounts owing to suppliers;</li>
                                            <li
                                                className={customless.breakhere}
                                            >
                                                Amounts owing by customers; and
                                            </li>
                                            <li>Accruals and prepayments.</li>
                                        </ul>
                                    </li>
                                </ul>
                                <p>
                                    You will keep us informed of material
                                    changes in circumstances that could affect
                                    your tax liabilities. If you are unsure
                                    whether the change is material or not,
                                    please let us know so that we can assess its
                                    significance.
                                </p>
                                <p>
                                    You should provide us with information in
                                    sufficient time for your tax return to be
                                    completed and submitted by the due date
                                    following the end of the tax year.
                                </p>
                                <p>
                                    Taxpayers who sign their returns cannot
                                    delegate this legal responsibility to
                                    others. You agree to check that the returns
                                    that we have prepared for you are complete
                                    before you approve and sign them.
                                </p>
                                <p>
                                    As part of our standard procedures, we may
                                    request you to provide written confirmation
                                    of any oral information and explanations
                                    given to us during the course of our work.
                                </p>
                            </div>

                            <div className={customless.p}>
                                <h3>3) OUR RESPONSIBILITIES</h3>
                                <p>
                                    We will compile the financial statements
                                    (profit and loss account and balance sheet
                                    and relevant notes) for your approval based
                                    on the accounting records maintained by you
                                    and the information and explanations given
                                    to us by you.
                                </p>
                                <p>
                                    We shall plan our work on the basis that no
                                    report is required by statute or regulation
                                    for the year unless you inform us in writing
                                    to the contrary.
                                </p>
                                <p>
                                    Our work will not be an audit of the
                                    accounts in accordance with Auditing
                                    Standards. Accordingly, we shall not seek
                                    any independent evidence to support the
                                    accounting records entries or prove the
                                    existence, ownership or valuation of assets
                                    or completeness of income, liabilities or
                                    disclosure in the accounts. Nor shall we
                                    assess the reasonableness of any estimates
                                    or judgements made in the preparation of the
                                    accounts. Consequently, our work will not
                                    provide any assurance that the accounting
                                    records are free from material misstatement,
                                    irregularities or error.
                                </p>
                                <p>
                                    We have a professional duty to compile
                                    Accounts, which conform to generally
                                    accepted accounting principles. Where we
                                    identify that the Accounts do not conform to
                                    accepted accounting principles, or if the
                                    accounting policies adopted are not
                                    immediately apparent, this will be made
                                    clear in our report if it is not clear in
                                    the Accounts.
                                </p>
                                <p>
                                    We also have a professional responsibility
                                    not to allow our name to be associated with
                                    financial statements, which we believe may
                                    be misleading. Therefore, although we are
                                    not required to search for such matters,
                                    should we become aware, for any reason, that
                                    the financial statements may be misleading,
                                    we will discuss the matter with you with the
                                    view to agreeing on appropriate adjustments
                                    and/or disclosures in the financial
                                    statements. In extreme cases where this
                                    matter cannot be resolved, we will withdraw
                                    from the engagement and notify you in
                                    writing of the reasons.
                                </p>
                            </div>
                            <div className={customless.p}>
                                <h3>4) GENERAL SERVICES</h3>
                                <p>
                                    If applicable, we agree to provide you with
                                    the following services:
                                </p>

                                <div>
                                    {quotationDetails?.map((item: any) => (
                                        <div key={item.sequenceNo}>
                                            {' '}
                                            {/* Add a unique key to each rendered item */}
                                            <h5>{item.quotationDescription}</h5>
                                            {item.Description !== '' ? (
                                                <ul>
                                                    <li>{item.description}</li>
                                                </ul>
                                            ) : null}
                                        </div>
                                    ))}
                                </div>

                                <p>AD HOC AND ADVISORY WORK</p>
                                <p>
                                    Where you have instructed us to do so, we
                                    will also provide other additional services
                                    from time to time. These may be subject to a
                                    separate engagement letter/or an agreement,
                                    in our opinion. Where appropriate, we will
                                    discuss and agree to an additional fee for
                                    such work when you commissioned it.
                                </p>
                            </div>

                            <div className={customless.p}>
                                <h3>5) FEES</h3>
                                <p>General Service Charges</p>
                                <p>
                                    Our fees may depend not only upon the time
                                    spent on your affairs but also on the level
                                    of skill, responsibility, importance and
                                    value of the advice that we provide. Unless
                                    otherwise agreed, our fees will be billed at
                                    appropriate intervals during the course of
                                    the year.
                                </p>
                                <p>
                                    Where requested, we may indicate a fixed fee
                                    for the provision of specific services or an
                                    indicative range of fees for a particular
                                    assignment. It is not our practice to
                                    identify fixed fees for more than a year
                                    ahead as such fee quotes need to be reviewed
                                    in the light of events. If it becomes
                                    apparent to us, due to unforeseen
                                    circumstances, that a fee quote is
                                    inadequate, we reserve the right to notify
                                    you of a revised figure or range and to seek
                                    your agreement thereto.
                                </p>
                                <p>Charges for additional services</p>
                                <p>
                                    Where you have instructed us to do so, we
                                    will also provide additional services.
                                    Examples of such work include:
                                </p>
                                <ul>
                                    <li>
                                        Dealing with any enquiry opened into the
                                        self-assessment tax return by HMRC;{' '}
                                    </li>
                                    <li>
                                        Preparing any amended returns, which may
                                        be required and corresponding with HMRC
                                        as necessary;{' '}
                                    </li>
                                    <li>Business plans; </li>
                                </ul>
                                <p>
                                    We will advise you of the estimated cost in
                                    advance before we undertake any such
                                    services.{' '}
                                </p>
                                <p>
                                    On occasions, where appropriate, we advise
                                    our clients to obtain personal liability
                                    insurance to protect themselves against the
                                    professional cost of dealing with specific
                                    cases. In such circumstances, you may be
                                    entitled to assistance with your
                                    professional fees, particularly in relation
                                    to any investigation into your tax affairs
                                    by HMRC. We may provide assistance through
                                    insurance policies you hold; however, you
                                    will remain liable for our fees regardless
                                    of whether all or part are liable to be paid
                                    by your insurers.
                                </p>
                                <p>
                                    If you do not accept that an invoiced fee is
                                    fair and reasonable, you must notify us
                                    immediately.
                                </p>

                                <p className={customless.breakhere}>Lien</p>
                                <p>
                                    In so far as we are permitted to do so by
                                    law or professional guidelines, we reserve
                                    the right to exercise a lien over all funds,
                                    documents and records in our possession
                                    relating to all engagements for you until
                                    all outstanding fees and disbursements are
                                    paid in full.
                                </p>
                            </div>

                            <div className={customless.p}>
                                <h3>6) LIMITATION OF LIABILITY</h3>
                                <p>
                                    We will provide our services with reasonable
                                    care and skill. Our liability to you is
                                    limited to losses, damages, costs and
                                    expenses directly caused by our negligence
                                    or wilful default.
                                </p>
                                <p>
                                    Exclusion of liability for loss caused by
                                    others
                                </p>
                                <p>
                                    We will not be liable if such losses,
                                    penalties, interest or additional tax
                                    liabilities are caused by the acts or
                                    omissions of any other person or due to the
                                    provision to us of incomplete, misleading or
                                    false information or if they are caused by a
                                    failure to act on our advice or a failure to
                                    provide us with relevant information.
                                </p>
                                <p>
                                    Exclusion of liability in relation to
                                    circumstances beyond our control
                                </p>
                                <p>
                                    We will not be liable to you for any delay
                                    or failure to perform our obligations under
                                    this engagement letter if circumstances
                                    outside our reasonable control cause the
                                    delay or failure.
                                </p>
                                <p>
                                    Exclusion of liability relating to the
                                    discovery of fraud etc
                                </p>
                                <p>
                                    We will not be responsible or liable for any
                                    loss, damage or expense incurred or
                                    sustained if information material to the
                                    service we provide is withheld or concealed
                                    from us or misrepresented to us. This
                                    applies equally to fraudulent acts,
                                    misrepresentation or wilful default on the
                                    part of any party to the transaction and
                                    their directors, officers, employees, agents
                                    or advisers.
                                </p>
                                <p>
                                    This exclusion shall not apply where such
                                    misrepresentation, withholding or
                                    concealment is or should (in carrying out
                                    the procedures which we have agreed to
                                    perform with reasonable care and skill) have
                                    been evident to us without further enquiry
                                    beyond that which it would have been
                                    reasonable for us to have carried out in the
                                    circumstances.
                                </p>
                                <p>Indemnity for unauthorised disclosure</p>
                                <p>
                                    You agree to indemnify our agents and us in
                                    respect of any claim (including any claim
                                    for negligence) arising out of any
                                    unauthorised disclosure by you or by any
                                    person for whom you are responsible for our
                                    advice and opinions, whether in writing or
                                    otherwise. This indemnity will extend to the
                                    cost of defending any such claim, including
                                    payment at our usual rates for the time that
                                    we spend in defending it.
                                </p>
                            </div>

                            <div className={customless.p}>
                                <h3>7) LIMITATION OF THIRD-PARTY RIGHTS</h3>
                                <p>
                                    The advice and information we provide to you
                                    as part of our service are for your sole use
                                    and not for any third party to whom you may
                                    communicate unless we have expressly agreed
                                    in the engagement letter that a specified
                                    third party may rely on our work. We accept
                                    no responsibility to third parties,
                                    including any group company to whom the
                                    engagement letter is not addressed, for any
                                    advice, information or material produced as
                                    part of our work for you that you make
                                    available to them. A party to this agreement
                                    is the only person who has the right to
                                    enforce any of its terms, and no rights or
                                    benefits are conferred on any third party
                                    under the Contracts (Rights of Third
                                    Parties) Act 1999.
                                </p>
                            </div>

                            <div className={customless.p}>
                                <h3>8) RELIANCE ON ADVICE</h3>
                                <p>
                                    We will endeavour to record all advice on
                                    important matters in writing. Advice given
                                    orally is not intended to be relied upon
                                    unless confirmed in writing. Therefore, if
                                    we provide oral advice (for example, during
                                    the course of a meeting or a telephone
                                    conversation) and you wish to be able to
                                    rely on that advice, you must ask for the
                                    advice to be confirmed by us in writing.
                                </p>
                            </div>

                            <div className={customless.p}>
                                <h3>
                                    9) CHANGES IN THE LAW OR PUBLIC POLICY AND
                                    PRACTICE{' '}
                                </h3>
                                <p>
                                    We will not accept responsibility if you act
                                    on advice given by us on an earlier occasion
                                    without first confirming with us that the
                                    advice is still valid in the light of any
                                    change in the law or public policy and
                                    practice or your circumstances.
                                </p>
                                <p className={customless.breakhere}>
                                    We will accept no liability for losses
                                    arising from changes in the law (or the
                                    interpretation thereof) or public policy and
                                    practice that are first published after the
                                    date on which the advice is given.
                                </p>
                                <h3>10) CONFLICTS OF INTEREST</h3>
                                <p>
                                    We will inform you if we become aware of any
                                    conflict of interest in our relationship
                                    with you or in our relationship with you and
                                    another client unless we are unable to do so
                                    because of our confidentiality obligations.
                                    We have safeguards that can be implemented
                                    to protect the interests of different
                                    clients if a conflict arises. Where
                                    conflicts are identified that cannot be
                                    managed in a way that protects your
                                    interests, and we regret that we will not
                                    provide further services. If this arises, we
                                    will inform you promptly.{' '}
                                </p>
                                <p>
                                    If there is a conflict of interest that is
                                    capable of being addressed successfully by
                                    the adoption of suitable safeguards to
                                    protect your interests, then we will adopt
                                    those safeguards. Where possible, this will
                                    be done on the basis of your informed
                                    consent. We reserve the right to act for
                                    other clients whose interests are not the
                                    same as or are adverse to yours, subject to
                                    the obligations of confidentiality referred
                                    to above.
                                </p>
                            </div>

                            <div className={customless.p}>
                                <h3>11) CONFIDENTIALITY </h3>
                                <p>
                                    We confirm that we will comply with the
                                    provisions of the Data Protection Act 2018
                                    when processing personal data about you. In
                                    order to carry out the services of this
                                    engagement, and for related purposes such as
                                    updating and enhancing our client records,
                                    analysis for management purposes and
                                    statutory returns, legal and regulatory
                                    compliance and crime prevention, we may
                                    obtain, process, use and disclose personal
                                    data about you.
                                </p>
                                <p>
                                    As an accounting practitioner, we are
                                    obliged to keep our client information
                                    confidential; therefore, we take all
                                    reasonable steps to preserve confidentiality
                                    and will never share information relating to
                                    your business with any other third party
                                    without prior consent unless required to do
                                    so by law, by regulatory bodies or by
                                    insurers to disclose information about our
                                    clients. Likewise, you agree not to use or
                                    copy or allow the use of the output of the
                                    work we do for with a third party without
                                    our permission.
                                </p>
                            </div>

                            <div className={customless.p}>
                                <h3>12) RETENTION OF RECORDS</h3>
                                <p>
                                    You have a legal responsibility to retain
                                    documents and records relevant to your tax
                                    affairs. During the course of our work, we
                                    may collect information from you and others
                                    relevant to your tax affairs. We will return
                                    any original documents to you. We are
                                    obliged to keep documents and records
                                    pertinent to your tax affairs for a period
                                    of no less than six years from the end of
                                    the tax year in question.
                                </p>
                                <p>
                                    While certain documents may legally belong
                                    to you, we may destroy correspondence and
                                    other papers that we store, electronically
                                    or otherwise, which are more than seven
                                    years old. You must tell us if you require
                                    the return of any specific document or their
                                    retention for a more extended period.{' '}
                                </p>
                            </div>

                            <div className={customless.p}>
                                <h3>13) ELECTRONIC COMMUNICATION</h3>
                                <p>
                                    Unless you instruct us otherwise, we may
                                    communicate with you via email or by other
                                    electronic means. The recipient is
                                    responsible for virus checking emails and
                                    any attachments.
                                </p>
                                <p>
                                    There is a risk of non-receipt, delayed
                                    receipt, inadvertent misdirection or
                                    interception by third parties with
                                    electronic communication. We use
                                    virus-scanning software to reduce the risk
                                    of viruses and similar damaging items being
                                    transmitted through emails or electronic
                                    storage devices. However, electronic
                                    communication is not totally secure. We
                                    cannot be held responsible for damage or
                                    loss caused by viruses or communications
                                    that are corrupted or altered after
                                    despatch. Nor can we accept any liability
                                    for problems or accidental errors relating
                                    to this means of communication, especially
                                    in relation to commercially sensitive
                                    material. These are risks you must bear in
                                    return for greater efficiency and lower
                                    costs. If you do not wish to accept these
                                    risks, please let us know, and we will
                                    communicate by hard copy, other than where
                                    electronic submission is mandatory.{' '}
                                </p>
                            </div>

                            <div className={customless.p}>
                                <h3>14) QUALITY OF SERVICE</h3>
                                <p>
                                    We aim to provide a high quality of service
                                    at all times. If you would like to discuss
                                    how our service could be improved or if you
                                    are dissatisfied with the service you are
                                    receiving, please let us know. We undertake
                                    to look into any complaint carefully and
                                    promptly and do all we can to explain the
                                    position to you.
                                </p>
                            </div>

                            <div className={customless.p}>
                                <h3>15) APPLICABLE LAW</h3>
                                <p>
                                    This engagement letter shall be governed by
                                    and construed in accordance with English
                                    law. The English courts shall have exclusive
                                    jurisdiction in relation to any claim,
                                    dispute of differences concerning the
                                    engagement letter and any matter arising
                                    from it. Each party irrevocably waives any
                                    right it may have to object to an action
                                    being brought in those Courts, to claim that
                                    the action has been brought in an
                                    inconvenient forum, or to claim that those
                                    Courts do not have jurisdiction.
                                </p>
                            </div>

                            <div className={customless.p}>
                                <h3>16) AGREEMENT OF TERMS</h3>
                                <p>
                                    This letter supersedes any previous
                                    engagement letter. Once it has been agreed,
                                    this letter will remain effective until it
                                    is replaced. You or we may vary or terminate
                                    our authority to act on your behalf at any
                                    time without penalty. Notice of variation or
                                    termination must be given in writing.
                                </p>
                            </div>

                            <div>
                                Yours faithfully,
                                <div>
                                    <img
                                        src={
                                            businessDocumentTemplate?.signatureLogo
                                        }
                                        alt=".CompanyName-Sign"
                                        style={{
                                            float: 'left',
                                            marginRight: '10px', // Add some spacing between the images
                                        }}
                                    />
                                    <img
                                        src={
                                            businessDocumentTemplate?.rubberStampLogo
                                        }
                                        alt=".CompanyName-Stamp"
                                        style={{
                                            float: 'right',
                                            width: '44%',
                                        }}
                                    />
                                    <div style={{ clear: 'both' }}></div>
                                </div>
                                <div className={customless.CompanyName}>
                                    {businessDocumentTemplate?.companyName}
                                </div>
                            </div>

                            <div>
                                <b>
                                    I /WE ACKNOWLEDGE THE RECEIPT OF THIS
                                    LETTER, AND I/WE AGREE TO THE TERMS OF THIS
                                    LETTER.
                                </b>
                            </div>

                            <div className={customless.signatureContainer}>
                                <Row className={customless.signature}>
                                    <Col>
                                        <Row>
                                            ................................................
                                        </Row>
                                        <Row className={customless.custom_row}>
                                            Signature
                                        </Row>

                                        <Row>
                                            ...............................................
                                        </Row>
                                        <Row>Date</Row>
                                    </Col>
                                </Row>
                            </div>
                        </div>
                    </div>
                </div>
            </Row>
            <Row gutter={16}>
                <Col offset={18} span={2}>
                    <Button type="primary" onClick={handleDownloadPDF}>
                        Download
                    </Button>
                </Col>
            </Row>
        </Card>
    )
}

export default LetterOfEngagement
