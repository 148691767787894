import {
    Button,
    Col,
    Collapse,
    Form,
    Modal,
    Row,
    Select,
    Space,
    Table,
    Tooltip,
} from 'antd'
import React, { useEffect, useState } from 'react'
import { connect, useDispatch } from 'react-redux'
import {
    type CompanyAttachment,
    type CorporateDocumentView,
} from '../../../../../../Types/Client/RegisteredClients/SoleTrader'
import {
    EyeOutlined,
    CloudDownloadOutlined,
    SaveOutlined,
} from '@ant-design/icons'
import commontStyles from './document.less'
import commoncss from '../../../../../../Utils/Common.less'
import DocumentViewModelForm from './documentViewModel'
import ServiceDocumentUpload from './serviceDocumentUpload'
import {
    SaveOfficerDocument,
    getClientCompanyDetails,
    GetDocumenttypeList,
} from '../../../../../../Services/SoleTrader'
import ActivePremission from '../../../../../../Utils/premissins'
import { PermissionType } from '../../../../../../Types/Enum/PermissionType'
import S3UploadMultiple from '../../../../../../Components/S3UploadMultiple'

const DocumentMain = (
    props: CorporateDocumentView & {
        companydetails: any
        soleTraderId: number
        companyservicedetails: any
    }
): React.ReactElement => {
    const {
        soleTraderTaps,
        documenttypeList,
        soleTraderId,
        companyservicedetails,
    } = props
    const [form] = Form.useForm()
    const dispatch = useDispatch()
    const [viewModalVisible, setViewModalVisible] = useState(false)
    const [selectedDocumentType, setSelectedDocumentType] = useState<number>(1)
    const [companyAttachmentId, setCompanyAttachmentId] = useState<number>(0)
    const [selectedDocument, setSelectedDocument] = useState<string>('')
    useEffect(() => {
        GetDocumenttypeList(dispatch)
    }, [])

    const OpenModel = (attachmentPath: string): void => {
        setSelectedDocument(attachmentPath)
        setViewModalVisible(true)
    }

    const columns = [
        {
            title: 'Name',
            dataIndex: 'title',
            key: 'title',
        },
        {
            title: 'Document',
            key: 'document',
            render: (
                text: any,
                record: {
                    documentBaseIcon: string | undefined
                    attachmentPath: string
                }
            ) => (
                <Space size="middle">
                    <Tooltip title="View Document">
                        <EyeOutlined
                            onClick={() => {
                                OpenModel(record.attachmentPath)
                            }}
                        />
                    </Tooltip>
                    <Tooltip title="Download Document">
                        <a
                            href={record.attachmentPath}
                            target="_blank"
                            rel="noopener noreferrer"
                            download={record.attachmentPath}
                        >
                            <CloudDownloadOutlined />
                        </a>
                    </Tooltip>
                </Space>
            ),
        },
    ]

    const Servicecolumns = [
        {
            title: 'Name',
            dataIndex: 'title',
            key: 'title',
        },
        {
            title: 'Document',
            key: 'document',
            render: (
                text: any,
                record: {
                    attachmentPath: string
                }
            ) => (
                <Space size="middle">
                    <Tooltip title="View Document">
                        <EyeOutlined
                            onClick={() => {
                                OpenModel(record.attachmentPath)
                            }}
                        />
                    </Tooltip>
                    <Tooltip title="Download Document">
                        <a
                            href={record.attachmentPath}
                            target="_blank"
                            rel="noopener noreferrer"
                            download={record.attachmentPath}
                        >
                            <CloudDownloadOutlined />
                        </a>
                    </Tooltip>
                </Space>
            ),
        },
    ]

    const filterServicecolumns = [
        {
            title: 'Name',
            dataIndex: 'title',
            key: 'title',
        },
        {
            title: 'Document',
            key: 'document',
            render: (
                text: any,
                record: {
                    documentBaseIcon: string | undefined
                    attachmentPath: string
                }
            ) => <Space size="middle"></Space>,
        },
    ]

    const uniqueServiceDocuments = Array.from(
        new Set(
            soleTraderTaps?.serviceDocumentList?.map(
                (document) => document.companyAttachmentId
            )
        )
    )?.map(
        (companyAttachmentId) =>
            soleTraderTaps?.serviceDocumentList.find(
                (document) =>
                    document.companyAttachmentId === companyAttachmentId
            )
    )

    const handleDocumentTitleChange = (value: number): void => {
        setSelectedDocumentType(value)
        form.resetFields(['attachmentPath'])
    }

    useEffect(() => {
        const matchingDocument = soleTraderTaps?.generalDocumentList?.find(
            (document) => document.documentBaseId === selectedDocumentType
        )
        if (matchingDocument != null) {
            const companyAttachmentIds = matchingDocument.companyAttachmentId
            setCompanyAttachmentId(companyAttachmentIds)
        } else {
            setCompanyAttachmentId(0)
        }
    }, [selectedDocumentType])

    const activeServices = companyservicedetails?.data?.filter(
        (service: { status: string }) => service.status === 'Active'
    )

    const servicedocumentName = uniqueServiceDocuments?.map(
        (service) => service?.serviceName.toUpperCase()
    )

    // Filter out items from 'a' that have a service name present in 'activeServices'
    const filteredServiceData = activeServices?.filter(
        (item: { serviceName: string }) =>
            !servicedocumentName.includes(item.serviceName)
    )

    const pensionDocuments = uniqueServiceDocuments
        ?.filter((document) => document?.serviceName === 'Pension')
        .map((document) => ({
            ...document,
            key: document?.companyAttachmentId,
        }))

    const pensionServiceNames = pensionDocuments.map((doc) => doc.serviceName)

    // Filter out services from filteredServiceData
    const updatedServiceData = filteredServiceData?.filter(
        (service: { serviceName: string }) =>
            !pensionServiceNames.includes(service.serviceName)
    )
    const businessTypeId = 2
    const tabIndex = 3
    const fetchData = (): void => {
        void getClientCompanyDetails(
            dispatch,
            businessTypeId,
            soleTraderId,
            tabIndex
        )
    }
    const [uploadFileList, setUploadFileList] = useState<string[]>([])

    const handleSetFormData = (name: string, locations: string[]): void => {
        setUploadFileList(locations)
    }

    const convertedString = uploadFileList
        .map((item) => item.slice())
        .join(', ')

    const handeleCompanDocumentSave = (): void => {
        let documentBaseNames = ''

        switch (selectedDocumentType) {
            case 13:
                documentBaseNames = 'Letter of Engagement'
                break
            case 14:
                documentBaseNames = 'AML Document'
                break
            case 15:
                documentBaseNames = 'ID Proof'
                break
            case 16:
                documentBaseNames = 'Address Proof'
                break
            default:
                documentBaseNames = ''
                break
        }

        const savedata = {
            companyAttachmentId: companyAttachmentId ?? 0,
            companyTypeId: 2,
            referenceId: soleTraderId,
            referenceId2: 0,
            documentBaseId: selectedDocumentType ?? 0,
            documentBaseName: documentBaseNames,
            attachmentPath: convertedString ?? '',
            soleTraderId: 0,
            type: 'GENERAL',
            ppExpiryDate: '',
            dlExpiryDate: '',
            prExpiryDate: '',
        }
        void SaveOfficerDocument(dispatch, savedata)
        fetchData()
        form.resetFields(['attachmentPath'])
    }
    return (
        <>
            <Row>
                <Col span={24}>
                    <Collapse
                        expandIconPosition="right"
                        accordion
                        defaultActiveKey={['1']}
                    >
                        <Collapse.Panel
                            header="General Company Documents"
                            key="1"
                        >
                            <Row gutter={[24, 16]}>
                                <Col span={12}>
                                    <Table
                                        dataSource={
                                            soleTraderTaps?.generalDocumentList
                                        }
                                        columns={columns}
                                        pagination={false}
                                    />
                                </Col>

                                <Col span={12}>
                                    <div className={commoncss.formWrapper}>
                                        <Form
                                            name="complex-form"
                                            labelCol={{ span: 24 }}
                                            wrapperCol={{ span: 24 }}
                                            initialValues={{
                                                remember: true,
                                            }}
                                            layout={'vertical'}
                                            autoComplete="off"
                                            form={form}
                                        >
                                            <Row gutter={[8, 8]}>
                                                <Col span={24}>
                                                    <Form.Item<CompanyAttachment>
                                                        label="Document Type"
                                                        name="title"
                                                        className={
                                                            commontStyles.CorporteDocumentGeenreal
                                                        }
                                                    >
                                                        <Select
                                                            allowClear
                                                            placeholder="Select a Document Title"
                                                            onChange={(
                                                                value: number
                                                            ) => {
                                                                handleDocumentTitleChange(
                                                                    value
                                                                )
                                                            }}
                                                            options={
                                                                documenttypeList
                                                            }
                                                            showSearch
                                                            filterOption={(
                                                                input,
                                                                option
                                                            ) =>
                                                                option?.label
                                                                    ?.toLowerCase()
                                                                    .includes(
                                                                        input.toLowerCase()
                                                                    ) ?? false
                                                            }
                                                        />
                                                    </Form.Item>
                                                </Col>
                                            </Row>
                                            <Row gutter={[8, 8]}>
                                                <Col span={24}>
                                                    <S3UploadMultiple
                                                        name={'attachmentPath'}
                                                        value={uploadFileList}
                                                        setFormData={
                                                            handleSetFormData
                                                        }
                                                        corporate={'SoleTrader'}
                                                        corporateid={
                                                            soleTraderId
                                                        }
                                                        documenttype={'General'}
                                                        documentbaseId={
                                                            selectedDocumentType
                                                        }
                                                        memberpersonId={0}
                                                    />
                                                </Col>
                                            </Row>
                                            {ActivePremission(
                                                '3000',
                                                PermissionType.SAVE
                                            ) && (
                                                <Row gutter={16}>
                                                    <Col offset={20} span={2}>
                                                        <Button
                                                            type="primary"
                                                            onClick={
                                                                handeleCompanDocumentSave
                                                            }
                                                        >
                                                            <SaveOutlined />
                                                        </Button>
                                                    </Col>
                                                </Row>
                                            )}
                                        </Form>
                                    </div>
                                </Col>
                            </Row>
                        </Collapse.Panel>
                        {uniqueServiceDocuments?.map((document) => (
                            <Collapse.Panel
                                header={
                                    <div>
                                        <span>{document?.title}</span>
                                    </div>
                                }
                                key={document?.companyAttachmentId ?? '1'}
                            >
                                <Row gutter={[24, 16]}>
                                    <Col span={12}>
                                        <Table
                                            dataSource={
                                                document != null
                                                    ? [document]
                                                    : []
                                            }
                                            columns={Servicecolumns}
                                            pagination={false}
                                        />
                                    </Col>
                                    <Col span={12}>
                                        <ServiceDocumentUpload
                                            soleTraderId={soleTraderId}
                                            companyAttachmentId={
                                                document?.companyAttachmentId
                                            }
                                            serviceName={document?.serviceName}
                                            documentBaseId={
                                                document?.documentBaseId
                                            }
                                            documentType={document?.documentType.toString()}
                                            fetchData={fetchData}
                                        />
                                    </Col>
                                </Row>
                            </Collapse.Panel>
                        ))}
                        {pensionDocuments.length > 0 && (
                            <Collapse.Panel
                                header={
                                    <div>
                                        <span>Pension Document</span>
                                    </div>
                                }
                                key={'4'}
                            >
                                <Row gutter={[24, 16]}>
                                    <Col span={12}>
                                        <Table
                                            dataSource={
                                                pensionDocuments !== undefined
                                                    ? pensionDocuments.map(
                                                          (doc) => ({
                                                              ...doc,
                                                              attachmentPath:
                                                                  doc.attachmentPath ??
                                                                  '', // Add a default value or handle accordingly
                                                          })
                                                      )
                                                    : []
                                            }
                                            columns={Servicecolumns}
                                            pagination={false}
                                        />
                                    </Col>
                                    <Col span={12}>
                                        <ServiceDocumentUpload
                                            soleTraderId={soleTraderId}
                                            companyAttachmentId={0}
                                            serviceName={'Pension'}
                                            documentBaseId={0}
                                            documentType={''}
                                            pensionData={pensionDocuments}
                                            fetchData={fetchData}
                                        />
                                    </Col>
                                </Row>
                            </Collapse.Panel>
                        )}
                        {updatedServiceData?.map((document: any) => (
                            <Collapse.Panel
                                header={
                                    <div>
                                        <span>
                                            {document?.serviceName} File
                                            Document
                                        </span>
                                    </div>
                                }
                                key={document?.serviceReferenceId}
                            >
                                <Row gutter={[24, 16]}>
                                    <Col span={12}>
                                        <Table
                                            dataSource={
                                                document != null
                                                    ? [document]
                                                    : []
                                            }
                                            columns={filterServicecolumns}
                                            pagination={false}
                                        />
                                    </Col>
                                    <Col span={12}>
                                        <ServiceDocumentUpload
                                            soleTraderId={soleTraderId}
                                            companyAttachmentId={0}
                                            serviceName={document?.serviceName}
                                            documentBaseId={
                                                document?.serviceName === 'VAT'
                                                    ? 8
                                                    : document?.serviceName ===
                                                        'PAYE'
                                                      ? 10
                                                      : document?.serviceName ===
                                                          'CT600'
                                                        ? 9
                                                        : document?.serviceName ===
                                                            'AA'
                                                          ? 16
                                                          : 0
                                            }
                                            documentType={'0'}
                                            fetchData={fetchData}
                                        />
                                    </Col>
                                </Row>
                            </Collapse.Panel>
                        ))}
                    </Collapse>
                </Col>
            </Row>
            <Modal
                title="Documents"
                onCancel={() => {
                    setViewModalVisible(false)
                }}
                visible={viewModalVisible}
                footer={null}
                width={'50%'}
            >
                <DocumentViewModelForm data={selectedDocument} itshow={0} />
            </Modal>
        </>
    )
}
const mapStateToProps = (state: any): CorporateDocumentView => {
    return {
        soleTraderTaps: state.client.soleTraderTaps,
        documenttypeList: state.common.documenttypeList,
    }
}

export default connect(mapStateToProps)(DocumentMain)
