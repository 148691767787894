import React, { useEffect, useState } from 'react'

const ImageToDataURL = (props: { inputURL: string }): React.ReactElement => {
    const { inputURL } = props
    const [outputDataURL, setOutputDataURL] = useState<string | null>(null)
    const [loading, setLoading] = useState(true)

    useEffect(() => {
        const fetchAndConvertToDataURL = (url: string, callback: any): void => {
            const image = new Image()
            image.crossOrigin = 'Anonymous'
            image.onload = function () {
                const canvas = document.createElement('canvas')
                const context = canvas.getContext('2d')

                // Ensure that context is not null
                if (context != null) {
                    // Explicitly cast image to HTMLImageElement
                    const img = this as HTMLImageElement

                    canvas.height = img.naturalHeight
                    canvas.width = img.naturalWidth
                    context.drawImage(img, 0, 0)
                    const dataURL = canvas.toDataURL('image/jpeg')
                    callback(dataURL)
                }
            }
            image.onload = () => {
                setLoading(false) // Set loading to false once the image has loaded
            }
            image.src = url
        }

        setLoading(true) // Set loading to true when a new URL is provided
        fetchAndConvertToDataURL(inputURL, (dataURL: string) => {
            setOutputDataURL(dataURL)
        })
    }, [inputURL])

    return (
        <div>
            {loading ? (
                <p>Loading...</p>
            ) : (
                outputDataURL != null && (
                    <img src={outputDataURL} alt="Converted to Base64" />
                )
            )}
        </div>
    )
}
export default ImageToDataURL
