import React, { useEffect, useState, useCallback } from 'react'
import { connect, useDispatch } from 'react-redux'
import { Card, Table, Space, Modal, Tooltip } from 'antd'
import commonStyles from '../../../Utils/Common.less'
import AddNewButton from '../../../Components/AddNewButton'
import SearchInput from '../../../Components/SearchInput'
import type { ColumnsType, TableProps } from 'antd/es/table'
import {
    AddSupplierDetails,
    DeleteSupplierDetails,
    GetSupplierDetailsList,
} from '../../../Services/SupplierService'
import type { TableParamsInterface } from '../../../Types/CommonType'
import {
    commonTableParams,
    setCommonTableParamsData,
} from '../../../Utils/CommontTable'
import FullWidthModal from '../../../Components/FullWidthModal'
import { ReactComponent as DeleteIcon } from '../../../Assest/Svg/DeleteIcon.svg'
import { ReactComponent as EditIcon } from '../../../Assest/Svg/EditIcon.svg'
import AddEditSupplierDetailsForm from './supplierDetailsForm'
import type {
    SupplierDetailsListInterface,
    SupplierDetailsListDataTypeInterface,
    SupplierFormDataInterface,
} from '../../../Types/Supplier'
import calculatePagination from '../../../Utils/Pagination'

export const SupplierItemDefaultData = {
    supplierId: '0',
    supplierName: '',
    imgPath: '',
}

const SupplierDetailsList = (
    props: SupplierDetailsListInterface
): React.ReactElement => {
    const dispatch = useDispatch()
    const { supplierDetailsList, supplierDetailsListCount } = props
    const [tableParams, setTableParams] =
        useState<TableParamsInterface>(commonTableParams)
    const [deleteModalStatus, setDeleteModalStatus] = useState(false)
    const [createEditModalStatus, setCreateEditModalStatus] = useState(false)
    const [supplierDetailsId, setSupplierDetailsId] = useState('')
    const [editModalTittle, setEditModalTittle] = useState(
        'Create Supplier Details'
    )
    const [editData, setEditData] = useState<SupplierFormDataInterface>(
        SupplierItemDefaultData
    )
    const [currentPage, setCurrentPage] = useState(1)
    const [itemsPerPage, setItemsPerPage] = useState(10)

    const getTableData = useCallback(() => {
        const { pageSize, pageNo, sortOrderList, searchCriteriaList } =
            setCommonTableParamsData(tableParams)
        GetSupplierDetailsList(dispatch, {
            pageNo,
            pageSize,
            searchCriteriaList,
            sortOrderList,
        })
    }, [
        tableParams?.sorter,
        tableParams.pagination?.current,
        tableParams?.filter,
    ])

    useEffect(() => {
        getTableData()
    }, [getTableData])

    const handleDataCountChange = useCallback(() => {
        setTableParams({
            ...tableParams,
            pagination: {
                ...tableParams.pagination,
                total: supplierDetailsListCount,
            },
        })
    }, [supplierDetailsListCount])

    useEffect(() => {
        handleDataCountChange()
    }, [handleDataCountChange])

    const handlePageChange = (pageNo: number, pageSize: number): void => {
        setCurrentPage(pageNo)
        setItemsPerPage(pageSize)
    }

    const pagination = calculatePagination(
        currentPage,
        supplierDetailsListCount,
        itemsPerPage
    )

    const columns: ColumnsType<SupplierDetailsListDataTypeInterface> = [
        {
            title: 'Supplier Name',
            dataIndex: 'supplierName',
            sorter: true,
            sortDirections: ['descend', 'ascend'],
            width: 1200,
        },
        {
            title: 'Action',
            key: 'action',
            render: (_, record: SupplierDetailsListDataTypeInterface) => {
                return (
                    <Space size="middle">
                        <Tooltip title="Edit Supplier">
                            <EditIcon
                                onClick={() => {
                                    openEditModal(record?.supplierId)
                                }}
                                className={commonStyles.clickableIcon}
                            />
                        </Tooltip>
                        <Tooltip title="Delete">
                            <DeleteIcon
                                onClick={() => {
                                    deleteModalFunction(record?.supplierId)
                                }}
                                className={commonStyles.clickableIcon}
                            />
                        </Tooltip>
                    </Space>
                )
            },
        },
    ]

    const onChangeTableParams: TableProps<SupplierDetailsListDataTypeInterface>['onChange'] =
        (pagination, filters, sorter: any, extra) => {
            handlePageChange(pagination.current ?? 1, pagination.pageSize ?? 10)
            setTableParams({
                ...tableParams,
                pagination,
                sorter: {
                    field: sorter?.field ?? '',
                    order: sorter?.order ?? '',
                },
            })
        }

    const onChangeText = (data: React.ChangeEvent<HTMLInputElement>): void => {
        if (data.target.value.length === 0) {
            onChangeFilter(data.target.value)
        }
    }

    const onChangeFilter = (data: string): void => {
        setTableParams({
            ...tableParams,
            filter: {
                fieldName: 'supplierName',
                searchOperator: 10,
                fieldValue1: data,
                fieldValue2: '',
            },
        })
    }

    const deleteModalFunction = (id: string): void => {
        setDeleteModalStatus(!deleteModalStatus)
        let supplierDetailsId = ''
        if (!deleteModalStatus) {
            supplierDetailsId = id
        }
        setSupplierDetailsId(supplierDetailsId)
    }

    const deleteSupplier = (): void => {
        DeleteSupplierDetails(dispatch, supplierDetailsId, getTableData)
        deleteModalFunction('')
    }

    const openEditModal = (id: string): void => {
        const data = supplierDetailsList.find(
            (data: SupplierFormDataInterface) => {
                return data.supplierId === id
            }
        )

        let tittle = 'Create Supplier'
        if (id !== null && id !== undefined) {
            tittle = 'Edit Supplier'
        }

        setEditModalTittle(tittle)
        setEditData(data ?? SupplierItemDefaultData)
        changeCreateEditModalStatus()
    }

    const changeCreateEditModalStatus = (): void => {
        setCreateEditModalStatus(!createEditModalStatus)
    }

    const onSaveSupplierDetails = (
        data: SupplierFormDataInterface,
        callBack: CallableFunction
    ): void => {
        void saveSupplierDetailsData(data, callBack)
    }

    const saveSupplierDetailsData = async (
        data: SupplierFormDataInterface,
        callBack: CallableFunction
    ): Promise<void> => {
        await AddSupplierDetails(dispatch, data)
            .then((result) => {
                if (result !== null && result !== undefined) {
                    changeCreateEditModalStatus()
                    getTableData()
                    callBack()
                }
            })
            .catch(() => {})
    }

    return (
        <>
            <Card
                title="Manage Supplier"
                extra={
                    <AddNewButton
                        name="Add New"
                        clickAction={() => {
                            openEditModal('')
                        }}
                    />
                }
                className={commonStyles.card}
            >
                <SearchInput
                    placeHolder={'Search Supplier Details by keywords'}
                    onSearch={onChangeFilter}
                    onChange={onChangeText}
                    width="478"
                />
                <div className={commonStyles.table}>
                    <Table
                        columns={columns}
                        dataSource={supplierDetailsList}
                        onChange={onChangeTableParams}
                        pagination={pagination}
                    />
                </div>
                <FullWidthModal
                    modalStatus={deleteModalStatus}
                    closeModal={deleteModalFunction}
                    tittle="You are about to delete this Supplier Details. Click 'Yes' to continue."
                    smallTittle="Warning: This action cannot be undone!"
                    yesButtonFunction={deleteSupplier}
                />
                <Modal
                    footer={null}
                    onCancel={changeCreateEditModalStatus}
                    width={'30%'}
                    title={editModalTittle}
                    open={createEditModalStatus}
                >
                    <AddEditSupplierDetailsForm
                        onSave={onSaveSupplierDetails}
                        editData={editData}
                    />
                </Modal>
            </Card>
        </>
    )
}

const mapStateToProps = (state: any): SupplierDetailsListInterface => {
    return {
        supplierDetailsList: state.initial.supplierDetailsList,
        supplierDetailsListCount: state.initial.supplierDetailsListCount,
    }
}

export default connect(mapStateToProps)(SupplierDetailsList)
