import React from 'react'
import { Button, ConfigProvider } from 'antd'

interface DefaultButtonProps {
    name: string
    clickAction?: () => void
    color: string
    icon: React.ReactNode
}

const CusttomBackgroundButton = (
    props: DefaultButtonProps
): React.ReactElement => {
    const { name = 'Add New', clickAction, color, icon } = props

    return (
        <ConfigProvider
            theme={{
                token: {
                    colorPrimary: color,
                },
            }}
        >
            <Button
                type="primary"
                size="middle"
                icon={icon}
                onClick={clickAction}
            >
                {name}
            </Button>
        </ConfigProvider>
    )
}

export default CusttomBackgroundButton
