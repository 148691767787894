import React, { useState } from 'react'
import CommonButton from '../../../Components/Commonbutton'
import CompanyDetails from '../../CompanyhouseandCSFillingButtons/ButtonFiles/companyDetails'
import Officers from '../../CompanyhouseandCSFillingButtons/ButtonFiles/ofiicer'
import SAILAddersss from '../../CompanyhouseandCSFillingButtons/ButtonFiles/sailAddress'
import ShareCapital from '../../CompanyhouseandCSFillingButtons/ButtonFiles/shareCapital'
import Shareholders from '../../CompanyhouseandCSFillingButtons/ButtonFiles/shareHolders'
import PSCPage from '../../CompanyhouseandCSFillingButtons/ButtonFiles/pscsPage'
import { Card, Modal } from 'antd'
import NameChangePage from './nameEditPage'
const CompanyOverViewButtons = (): React.ReactElement => {
    const [activeButton, setActiveButton] = useState(1)
    const [CompanyDetailsBtn, setCompanyDetailsBtn] = useState(true)
    const [OfficerBtn, setOfficerBtn] = useState(false)
    const [SAILAddressBtn, setSAILAddressBtn] = useState(false)
    const [ShareCapitalBtn, setShareCapitalBtn] = useState(false)
    const [ShareholdersBtn, setShareholdersBtn] = useState(false)
    const [PscBtn, setPscBtn] = useState(false)
    const [NameChangeModel, setNameChangeModel] = useState(false)
    const OpenNameChangeModel = (): void => {
        setNameChangeModel(!NameChangeModel)
    }
    const changeNameChangeModel = (): void => {
        setNameChangeModel(!NameChangeModel)
    }
    const handleButtonClick = (buttonName: number): void => {
        setActiveButton(buttonName)
        switch (buttonName) {
            case 1:
                setCompanyDetailsBtn(true)
                break
            case 2:
                setOfficerBtn(true)
                break
            case 3:
                setSAILAddressBtn(true)
                break
            case 4:
                setShareCapitalBtn(true)
                break
            case 5:
                setShareholdersBtn(true)
                break
            case 6:
                setPscBtn(true)
                break
            default:
                break
        }
    }

    const handleOtherFunction = (): React.ReactElement => {
        if (activeButton === 1) {
            return <></>
        } else if (activeButton === 2) {
            return <></>
        }
        if (activeButton === 3) {
            return <></>
        }
        if (activeButton === 4) {
            return <></>
        }
        if (activeButton === 5) {
            return <></>
        }
        if (activeButton === 6) {
            return <></>
        }
        return <></>
    }

    return (
        <>
            {' '}
            <div>
                <h2>Company overview</h2>
            </div>
            <Card
                title={
                    <>
                        N & S VANI LIMITED - 11869090
                        <span style={{ marginLeft: '10px' }}>
                            (<a onClick={OpenNameChangeModel}>Edit</a>)
                        </span>
                    </>
                }
            >
                <CommonButton
                    name="Company Details"
                    isActive={activeButton === 1}
                    clickAction={() => handleOtherFunction()}
                    additionalOnClick={() => {
                        handleButtonClick(1)
                    }}
                />
                <CommonButton
                    name="Officers"
                    isActive={activeButton === 2}
                    clickAction={() => handleOtherFunction()}
                    additionalOnClick={() => {
                        handleButtonClick(2)
                    }}
                />
                <CommonButton
                    name="SAIL Address"
                    isActive={activeButton === 3}
                    clickAction={() => handleOtherFunction()}
                    additionalOnClick={() => {
                        handleButtonClick(3)
                    }}
                />
                <CommonButton
                    name="Share Capital"
                    isActive={activeButton === 4}
                    clickAction={() => handleOtherFunction()}
                    additionalOnClick={() => {
                        handleButtonClick(4)
                    }}
                />
                <CommonButton
                    name="Shareholders"
                    isActive={activeButton === 5}
                    clickAction={() => handleOtherFunction()}
                    additionalOnClick={() => {
                        handleButtonClick(5)
                    }}
                />
                <CommonButton
                    name="PSC's"
                    isActive={activeButton === 6}
                    clickAction={() => handleOtherFunction()}
                    additionalOnClick={() => {
                        handleButtonClick(6)
                    }}
                />
                {CompanyDetailsBtn && activeButton === 1 && (
                    <div>
                        <CompanyDetails companyHousId={1} />
                    </div>
                )}
                {OfficerBtn && activeButton === 2 && (
                    <div>
                        <Officers companyHousId={1} />
                    </div>
                )}
                {SAILAddressBtn && activeButton === 3 && (
                    <div>
                        <SAILAddersss companyHousId={1} />
                    </div>
                )}
                {ShareCapitalBtn && activeButton === 4 && (
                    <div>
                        <ShareCapital companyHousId={1} />
                    </div>
                )}
                {ShareholdersBtn && activeButton === 5 && (
                    <div>
                        <Shareholders companyHousId={1} />
                    </div>
                )}
                {PscBtn && activeButton === 6 && (
                    <div>
                        <PSCPage companyHousId={1} />
                    </div>
                )}
            </Card>
            <Modal
                footer={null}
                onCancel={changeNameChangeModel}
                width={'50%'}
                title={'Name Change'}
                open={NameChangeModel}
            >
                <NameChangePage />
            </Modal>
        </>
    )
}

export default CompanyOverViewButtons
