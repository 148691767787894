import { Card, Col, Row, Button } from 'antd'
import React, { useEffect } from 'react'
import customcss from './customcss.less'
import hederimage from '../../../../../../../Assest/Svg/pattern-2.svg'
import { useLocation } from 'react-router-dom'
import {
    PDFDocument,
    type PDFFont,
    type PDFPage,
    rgb,
    type RGB,
    StandardFonts,
} from 'pdf-lib'
import { convertImageToBase64 } from '../../../../../../../Components/base64ImageCovert'

const PartneshipQuotatioPreview = (): React.ReactElement => {
    const location = useLocation()
    const previewData = location.state?.previewqutation
    const businessDocumentTemplate = previewData?.businessDocumentTemplate
    const quotationHeader = previewData?.quotationHeader
    const quotationCompany = previewData?.quotationCompany
    const quotationDetails = previewData?.quotationDetails
    const quotationFooter = previewData?.quotationFooter

    useEffect(() => {
        // Check if the image is loaded and visible
        const img = document.getElementById('barcode')
        if (img != null) {
            img.onload = () => {
                console.log('Image loaded successfully')
            }
        }
    }, [])

    const handleDownloadPDF = (): void => {
        void handleDownloadPDFDoc()
    }

    const handleDownloadPDFDoc = async (): Promise<void> => {
        const pdfDoc = await PDFDocument.create()
        const page = pdfDoc.addPage([650, 842]) // A4 size in points

        // Load and embed the fonts
        const timesRomanFont = await pdfDoc.embedFont(StandardFonts.TimesRoman)
        const timesItalicFont = await pdfDoc.embedFont('Times-Italic')
        const timesRomanBoldFont = await pdfDoc.embedFont(
            StandardFonts.TimesRomanBold
        )
        const convertSvgToPng = async (svgUrl: string): Promise<string> => {
            return await new Promise((resolve, reject) => {
                const canvas = document.createElement('canvas')
                const ctx = canvas.getContext('2d')
                const img = new Image()

                img.onload = () => {
                    // Set canvas dimensions to match SVG
                    canvas.width = img.width
                    canvas.height = img.height

                    // Draw SVG on canvas
                    ctx?.drawImage(img, 0, 0)
                    resolve(canvas.toDataURL('image/png'))
                }

                img.onerror = reject
                img.src = svgUrl
            })
        }

        try {
            // Convert the SVG to PNG data URL
            const headerPngDataUrl = await convertSvgToPng(hederimage)
            const headerImageBytes = await fetch(headerPngDataUrl).then(
                async (res) => await res.arrayBuffer()
            )
            const headerImage = await pdfDoc.embedPng(headerImageBytes)

            // Draw the header image
            page.drawImage(headerImage, {
                x: 25,
                y: 810,
                width: 600,
                height: 10,
            })
        } catch (error) {
            console.error('Error converting SVG to PNG:', error)
        }
        // Set the font size and color
        const fontColor = rgb(0, 0, 0)

        // Draw the header image

        // Draw company name and details
        page.drawText(businessDocumentTemplate?.companyName ?? '', {
            x: 65,
            y: 780,
            size: 14,
            font: timesRomanBoldFont,
            color: fontColor,
        })

        // Draw additional details
        page.drawText(
            `${businessDocumentTemplate?.addressOne ?? ''}, ${
                businessDocumentTemplate?.addressTwo ?? ''
            }, ${businessDocumentTemplate?.town ?? ''}, ${
                businessDocumentTemplate?.postCode ?? ''
            }, ${businessDocumentTemplate?.country ?? ''}`,
            {
                x: 65,
                y: 765,
                size: 7,
                font: timesRomanFont,
                color: fontColor,
            }
        )
        page.drawText(businessDocumentTemplate?.website ?? '', {
            x: 65,
            y: 755,
            size: 7,
            font: timesRomanFont,
            color: fontColor,
        })

        try {
            const imageUrl = `https://api.allorigins.win/raw?url=${businessDocumentTemplate?.companyLogo}`
            const base64String = await convertImageToBase64(imageUrl)

            const imageBytes = await fetch(base64String).then(
                async (res) => await res.arrayBuffer()
            )
            const image = await pdfDoc.embedPng(imageBytes)
            page.drawImage(image, {
                x: 450,
                y: 750,
                width: 120,
                height: 50,
            })
        } catch (error) {
            console.error('Error embedding image:', error)
        }

        // Draw the Quotation title
        page.drawText('QUOTATION', {
            x: 65,
            y: 720,
            size: 24,
            font: timesRomanBoldFont,
            color: fontColor,
        })

        // Draw quotation details
        page.drawText(`${quotationHeader?.quotationNo ?? ''}`, {
            x: 65,
            y: 700,
            size: 10,
            font: timesRomanFont,
            color: fontColor,
        })
        page.drawText(
            [
                `Issue Date  : ${quotationHeader?.quotationDate}`,
                `Due Date    :${quotationHeader?.dueDate}`,
                `Net Total   :${quotationHeader?.netTotal.toFixed(2)}`,
                `Currency    :${quotationHeader?.currencyName}`,
            ].join('\n'),
            {
                x: 65,
                y: 680,
                size: 9,
                font: timesRomanFont,
                color: fontColor,
                lineHeight: 10,
            }
        )
        page.drawText(
            [
                `Bill to: `,
                `${quotationCompany?.companyName}`,
                `${quotationCompany?.addressLine1},`,
                `${quotationCompany?.addressLine2},${quotationCompany?.town} ,${quotationCompany?.postCode}, ${quotationCompany?.country}`,
                `${quotationCompany?.attentionMobileNo}`,
                ` Attn: ${quotationCompany?.attentionName}`,
            ].join('\n'),
            {
                x: 400,
                y: 680,
                size: 9,
                font: timesRomanFont,
                color: fontColor,
                lineHeight: 10,
            }
        )

        // Draw the line items (services)
        let yPos = 560
        page.drawText(`Servises`, {
            x: 65,
            y: 585,
            size: 12,
            font: timesRomanFont,
            color: fontColor,
        })
        page.drawLine({
            start: { x: 65, y: 580 },
            end: { x: 570, y: 580 },
            thickness: 1.5,
            color: rgb(0, 0, 0),
        })

        function drawWrappedText(
            page: PDFPage,
            text: string,
            x: number,
            y: number,
            font: PDFFont,
            fontSize: number,
            color: RGB,
            maxWidth: number
        ): number {
            const words = text.split(' ')
            let line = ''
            let yOffset = 0

            for (let i = 0; i < words.length; i++) {
                const testLine = line + words[i] + ' '
                const testLineWidth = font.widthOfTextAtSize(testLine, fontSize)

                if (testLineWidth > maxWidth && i > 0) {
                    // Draw the current line
                    page.drawText(line.trim(), {
                        x,
                        y: y - yOffset,
                        size: fontSize,
                        font,
                        color,
                    })

                    // Start a new line with the current word
                    line = words[i] + ' '
                    yOffset += fontSize + 2 // Adjust the line height as needed
                } else {
                    line = testLine
                }
            }

            // Draw the last line
            if (line.trim().length > 0) {
                page.drawText(line.trim(), {
                    x,
                    y: y - yOffset,
                    size: fontSize,
                    font,
                    color,
                })
            }

            return yOffset
        }

        // Usage inside your loop
        quotationDetails?.forEach(async (detail: any) => {
            page.drawText(
                `${detail.sequenceNo ?? ''}. ${
                    detail.quotationDescription ?? ''
                }`,
                {
                    x: 65,
                    y: yPos,
                    size: 10,
                    font: timesRomanFont,
                    color: fontColor,
                }
            )

            // Calculate the max width for the wrapped text
            const maxWidth = 550 - 70 // Adjust this value according to your page width and margins

            // Draw wrapped description text
            const yOffset = drawWrappedText(
                page,
                detail.description ?? '',
                85,
                yPos - 20,
                timesItalicFont,
                10,
                fontColor,
                maxWidth
            )

            // Draw the horizontal line after the description text
            page.drawLine({
                start: { x: 65, y: yPos - 30 - yOffset }, // Adjust y based on yOffset
                end: { x: 570, y: yPos - 30 - yOffset }, // Adjust x as needed
                thickness: 1,
                color: rgb(233 / 255, 233 / 255, 233 / 255),
            })

            yPos -= 40 + yOffset // Adjust yPos down for the next item, including the text height
            // Adjust yPos down for the next item, including the text height
        })

        // Draw the totals
        page.drawText(`Subtotal: `, {
            x: 65,
            y: yPos - 20,
            size: 10,
            font: timesRomanFont,
            color: fontColor,
        })
        page.drawText(` ${quotationFooter?.netTotal.toFixed(2) ?? ''}`, {
            x: 540,
            y: yPos - 20,
            size: 10,
            font: timesRomanFont,
            color: fontColor,
        })
        if (quotationFooter?.taxIncluded > 0) {
            page.drawText(`VAT (20%)`, {
                x: 65,
                y: yPos - 40,
                size: 10,
                font: timesRomanFont,
                color: fontColor,
            })
            page.drawText(` £${quotationFooter?.taxVAT.toFixed(2) ?? ''}`, {
                x: 540,
                y: yPos - 40,
                size: 10,
                font: timesRomanFont,
                color: fontColor,
            })
        }
        page.drawLine({
            start: { x: 65, y: yPos - 50 },
            end: { x: 570, y: yPos - 50 },
            thickness: 1,
            color: rgb(0, 0, 0),
        })
        page.drawText(`Total`, {
            x: 65,
            y: yPos - 75,
            size: 13,
            font: timesRomanBoldFont,
            color: fontColor,
        })
        page.drawText(`£${quotationFooter?.grossTotal.toFixed(2) ?? ''}`, {
            x: 540,
            y: yPos - 75,
            size: 13,
            font: timesRomanBoldFont,
            color: fontColor,
        })
        page.drawText(
            `${
                quotationFooter?.subscriptionType === 0
                    ? '(Annually)'
                    : '(Monthly)'
            }`,
            {
                x: 525,
                y: yPos - 87,
                size: 10,
                font: timesRomanBoldFont,
                color: fontColor,
            }
        )

        // Draw footer note
        const drawLeftAlignedText = (
            page: PDFPage,
            text: string,
            x: number,
            y: number,
            width: number,
            fontSize: number,
            font: PDFFont,
            fontColor: RGB
        ): void => {
            const words = text.split(' ')
            let line = ''
            const lines = []
            const spaceWidth = font.widthOfTextAtSize(' ', fontSize)
            let currentWidth = 0

            // Calculate the lines of text
            words.forEach((word: string) => {
                const wordWidth = font.widthOfTextAtSize(word, fontSize)
                if (currentWidth + wordWidth + spaceWidth <= width) {
                    line += (line.length > 0 ? ' ' : '') + word
                    currentWidth += wordWidth + spaceWidth
                } else {
                    lines.push(line)
                    line = word
                    currentWidth = wordWidth + spaceWidth
                }
            })
            lines.push(line)

            // Draw each line with left alignment
            lines.forEach((line, index) => {
                page.drawText(line, {
                    x,
                    y: y - index * (fontSize + 5), // Adjust line height as needed
                    size: fontSize,
                    font,
                    color: fontColor,
                })
            })
        }

        // Usage
        drawLeftAlignedText(
            page,
            `In order for us to carry out work in accordance with the required timetable, it will be important that we can be assured of the availability and co-operation of you and the firm management and staff members in providing the information we may require. If you wish to discuss the contents of this quotation, please contact us.`,
            65, // x position
            yPos - 130, // y position
            520, // Width for wrapping text
            10,
            timesRomanFont,
            fontColor
        )

        page.drawLine({
            start: { x: 25, y: 15 },
            end: { x: 600, y: 15 },
            thickness: 1,
            color: rgb(0, 0, 0),
        })
        page.drawText(
            `Registered in England 06509841 | ACCA Registra on number 2819986`,
            {
                x: 200,
                y: 7,
                size: 8,
                font: timesRomanFont,
                color: fontColor,
            }
        )
        // Save the PDF and create a download link
        const pdfBytes = await pdfDoc.save()
        const blob = new Blob([pdfBytes], { type: 'application/pdf' })
        const link = document.createElement('a')
        link.href = URL.createObjectURL(blob)
        link.download = `Document_${quotationHeader?.referenceId}-${quotationHeader?.companyType}_QUO.pdf`
        link.click()
    }
    return (
        <Card>
            <div className={customcss.body}>
                <div
                    data-size="A4"
                    id="pdf-content"
                    className={customcss.printableContent}
                >
                    <div>
                        <div>
                            <img
                                src={hederimage}
                                className={customcss.col_sm}
                            />
                        </div>
                    </div>
                    <div className={customcss.hedertext}>
                        <div>
                            <div className={customcss.headerContainer}>
                                <div>
                                    <h2
                                        className={
                                            customcss.hedertextCompanyName
                                        }
                                    >
                                        {businessDocumentTemplate?.companyName}
                                        <small
                                            className={
                                                customcss.headersmalltext
                                            }
                                        >
                                            <br />
                                            {
                                                businessDocumentTemplate?.addressOne
                                            }
                                            ,
                                            {
                                                businessDocumentTemplate?.addressTwo
                                            }
                                            ,{businessDocumentTemplate?.town},
                                            {businessDocumentTemplate?.postCode}
                                            ,{businessDocumentTemplate?.country}
                                            ,
                                            <br />
                                            {businessDocumentTemplate?.website}
                                        </small>
                                    </h2>
                                </div>
                                <div className={customcss.headerImage}>
                                    <img
                                        id="barcode"
                                        alt="companyLogo"
                                        className={customcss.headerlogo}
                                        src={
                                            businessDocumentTemplate?.companyLogo
                                        }
                                    />
                                </div>
                            </div>

                            <h2 className={customcss.title}>
                                <b> QUOTATION</b>
                            </h2>
                            <div>
                                <p>{quotationHeader?.quotationNo}</p>
                            </div>
                        </div>
                        <div>
                            <Row gutter={[40, 8]}>
                                <Col span={13}>
                                    <Row gutter={[8, 8]}>
                                        <Col span={6}>
                                            <Row>Issue Date</Row>
                                            <Row>
                                                <strong>Due Date</strong>
                                            </Row>
                                            <Row>Net Total</Row>
                                            <Row>Currency </Row>
                                        </Col>
                                        <Col span={7}>
                                            <Row>
                                                :
                                                {quotationHeader?.quotationDate}
                                            </Row>
                                            <Row>
                                                :{quotationHeader?.dueDate}
                                            </Row>
                                            <Row>
                                                :
                                                {quotationHeader?.netTotal.toFixed(
                                                    2
                                                )}
                                            </Row>
                                            <Row>
                                                :{quotationHeader?.currencyName}
                                            </Row>
                                        </Col>
                                    </Row>
                                </Col>
                                <Col span={11}>
                                    <Row>
                                        <Col>
                                            <Row>
                                                <strong>Bill to:</strong>
                                            </Row>
                                            <Row>
                                                {quotationCompany?.companyName}
                                            </Row>
                                            <Row>
                                                {quotationCompany?.addressLine1}
                                                ,
                                            </Row>
                                            <Row>
                                                {quotationCompany?.addressLine2}
                                                ,{quotationCompany?.town},
                                                {quotationCompany?.postCode},
                                                {quotationCompany?.country}
                                            </Row>
                                            <Row>
                                                {
                                                    quotationCompany?.attentionMobileNo
                                                }
                                            </Row>
                                            <Row>
                                                {
                                                    quotationCompany?.attentionEmail
                                                }
                                            </Row>
                                            <Row>
                                                Attn:
                                                {
                                                    quotationCompany?.attentionName
                                                }
                                            </Row>
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                        </div>
                        <span>Service</span>
                        <hr className={customcss.hrstyle} />
                        <div>
                            {quotationDetails?.map(
                                (detail: any, index: any) => (
                                    <>
                                        <Row key={index} gutter={[8, 8]}>
                                            <Col span={2}>
                                                <Row>{detail.sequenceNo}</Row>
                                            </Col>
                                            <Col span={20}>
                                                <Row>
                                                    {
                                                        detail.quotationDescription
                                                    }
                                                </Row>
                                                <Row>
                                                    <i>{detail.description}</i>
                                                </Row>
                                            </Col>
                                        </Row>
                                        <hr className={customcss.hrstyle2} />
                                    </>
                                )
                            )}
                        </div>
                        <div className={customcss.col_sm_4}>
                            <div className={customcss.subtotal_Vat}>
                                <strong className={customcss.strongText}>
                                    Subtotal
                                </strong>
                                <p className={customcss.ptext}>
                                    {quotationFooter?.netTotal.toFixed(2)}
                                </p>
                            </div>
                            {quotationFooter?.taxIncluded > 0 && (
                                <div className={customcss.subtotal_Vat}>
                                    <strong className={customcss.strongText}>
                                        VAT (20%)
                                    </strong>
                                    <p className={customcss.ptext}>
                                        {quotationFooter?.taxVAT.toFixed(2)}
                                    </p>
                                </div>
                            )}
                            <hr className={customcss.hrstyle} />
                            <div
                                className={`${customcss.subtotal_Vat} ${customcss.keep_print_font}`}
                            >
                                <h4 className={customcss.strongText}>Total</h4>
                                <div className={customcss.ptext}>
                                    <strong>
                                        &#163;{' '}
                                        {quotationFooter?.grossTotal.toFixed(2)}
                                    </strong>
                                    <p className={customcss.annually_montly}>
                                        {quotationFooter?.subscriptionType === 0
                                            ? '(Annually)'
                                            : '(Monthly)'}
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div>
                            <div className={customcss.col_sm}>
                                <h4>
                                    In order for us to carryout work in
                                    accordance with the required timetable, it
                                    will be important that we can be assured of
                                    the availability and co-operation of you and
                                    the firm management and staff members in
                                    providing the information we may require.If
                                    you wish to discuss the contents of this
                                    quotation, please contact us.
                                    {businessDocumentTemplate?.workNo} or{' '}
                                    {businessDocumentTemplate?.mobileNo}
                                </h4>
                            </div>
                        </div>
                    </div>
                    <hr className={customcss.hrstyle} />
                </div>
            </div>
            <br />
            <Row gutter={16} className={customcss.button}>
                <Col offset={18} span={2}>
                    <Button type="primary" onClick={handleDownloadPDF}>
                        Download
                    </Button>
                </Col>
            </Row>
        </Card>
    )
}

export default PartneshipQuotatioPreview
