import React from 'react'
import { Input, Form } from 'antd'
import styles from './Less/input.less'

const InputFieldPassword = (props: {
    name: string
    label: string
    reuireMessage: string
}): React.ReactElement => {
    const { name, label, reuireMessage } = props

    return (
        <Form.Item
            label={<label className={styles.inputLabel}>{label}</label>}
            name={name}
            rules={[{ required: true, message: reuireMessage }]}
        >
            <Input.Password />
        </Form.Item>
    )
}

export default InputFieldPassword
