import React, { useState } from 'react'
import { Button, Upload, Spin, Card } from 'antd'
import { uploadFileToS3 } from '../Config/AwsConfig'
import styles from './Less/s3Upload.less'
import layoutStyles from '../Layouts/Less/style.less'
import { ReactComponent as ExcelImage } from '../Assest/Svg/excel.svg'
import { DeleteOutlined } from '@ant-design/icons'
import { ErrorMessage } from '../Utils/Notification'
interface S3UploadInterface {
    name: string
    value: string
    setFormData: (name: string, location: string) => void
    setBase64?: (base64: string | undefined) => void
    corporate: string
    corporateid: number
    documenttype: string
    documentbaseId: number
    memberpersonId: number
}
// const handleDownload = (url: string): void => {
//     // Create a temporary anchor element
//     const link = document.createElement('a')
//     link.href = url
//     link.download = url.split('/').pop() ?? 'document.pdf' // Set the default file name if not available in URL
//     link.target = '_blank'

//     // Append the link to the document body and trigger the download
//     document.body.appendChild(link)
//     link.click()

//     // Remove the link from the document body
//     document.body.removeChild(link)
// }

const S3UploadExcel = (props: S3UploadInterface): React.ReactElement => {
    const {
        name,
        value,
        setFormData,
        setBase64,
        corporate,
        corporateid,
        documenttype,
        memberpersonId,
        documentbaseId,
    } = props
    const [isImageUploaded, setIsImageUploaded] = useState<boolean>(false)
    const [isLoading, setIsLoading] = useState<boolean>(false)

    const handleBeforeUpload = (): boolean => {
        return false
    }
    const dynamicPath = `Document${corporate !== '' ? `/${corporate}` : ''}${
        corporateid !== 0 ? `/${corporateid}` : ''
    }${documenttype !== '' ? `/${documenttype}` : ''}${
        memberpersonId !== 0 ? `/${memberpersonId}` : ''
    }${documentbaseId !== 0 ? `/${documentbaseId}` : ''}`

    // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
    const convertBase64 = async (file: File) => {
        return await new Promise((resolve, reject) => {
            const fileReader = new FileReader()
            fileReader.readAsDataURL(file)
            fileReader.onload = () => {
                resolve(fileReader.result)
            }
            fileReader.onerror = (error) => {
                reject(error)
            }
        })
    }

    const fileOnChange = async (
        file: any,
        name: string,
        fileList: any
    ): Promise<void> => {
        setIsLoading(true)

        if (setBase64 != null) {
            const base64 = await convertBase64(fileList[0]?.originFileObj)
            setBase64(base64 as string)
        }

        uploadFileToS3(file, dynamicPath)
            .then((response) => {
                if (response?.Location !== null) {
                    setFormData(name, response?.Location)
                    setIsImageUploaded(true)
                    setIsLoading(false)
                }
            })
            .catch(() => {
                setIsLoading(false)
            })
    }
    const handleRemove = (): void => {
        setFormData(name, '')
        setIsImageUploaded(false)
    }

    const getFileExtension = (url: string | undefined): string | undefined => {
        // Check if url is defined and not null
        if (url != null) {
            return url.split('.').pop()?.toLowerCase()
        }
        return undefined
    }
    const fileExtension = getFileExtension(value)
    return (
        <Upload
            accept=".xlsx,.csv"
            beforeUpload={handleBeforeUpload}
            onChange={(info) => {
                const isPdfOrImage =
                    info.file.type ===
                        'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' ||
                    info.file.type === 'application/vnd.ms-excel'

                if (!isPdfOrImage) {
                    ErrorMessage('You can only upload Excel File!')
                    return Upload.LIST_IGNORE
                }
                try {
                    void fileOnChange(info.file, name, info.fileList)
                } catch (error) {
                    ErrorMessage(`file upload failed.`)
                }
                return false
            }}
            className={styles.uploadComponent}
            maxCount={1}
            showUploadList={{
                showRemoveIcon: false,
                showPreviewIcon: isImageUploaded,
            }}
            defaultFileList={[
                {
                    uid: '1',
                    name: '',
                    status: 'uploading',
                    url: value,
                },
            ]}
            itemRender={(): React.ReactElement =>
                !(value === null || value === '' || value === undefined) ? (
                    fileExtension === 'xlsx' || fileExtension === 'csv' ? (
                        <Card
                            style={{ width: 200, marginTop: 16 }}
                            cover={
                                <ExcelImage
                                    width={200}
                                    height={200}
                                    style={{ cursor: 'pointer' }}
                                />
                            }
                            actions={[
                                <DeleteOutlined
                                    key="delete"
                                    onClick={() => {
                                        handleRemove()
                                    }}
                                />,
                            ]}
                        />
                    ) : (
                        <span>No Image Selected</span>
                    )
                ) : (
                    <span>No Image Selected</span>
                )
            }
        >
            <Button className={styles.fileUploadButton}>Choose File</Button>
            {isLoading && (
                <div className={layoutStyles.overlay}>
                    <Spin />
                </div>
            )}
        </Upload>
    )
}

export default S3UploadExcel
