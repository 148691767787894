import { Input, Form } from 'antd'
import { connect } from 'react-redux'
import commonStyles from '../../../../../../Utils/Common.less'
import React, { useEffect } from 'react'
import { type SoleTraderConfigurationInterface } from '../../../../../../Types/Client/RegisteredClients/SoleTrader'
const { TextArea } = Input

const GoCardlessForm = (
    props: SoleTraderConfigurationInterface
): React.ReactElement => {
    const { registeredClientSoleTraderConfiguration } = props
    const [form] = Form.useForm()

    useEffect(() => {
        form.setFieldsValue(registeredClientSoleTraderConfiguration?.data)
    }, [registeredClientSoleTraderConfiguration])

    const handleFinish = (): void => {
        const formData = form.getFieldsValue()
        console.log(formData)
    }

    const customizeRequiredMark = (
        label: React.ReactNode,
        { required }: { required: boolean }
    ): React.ReactElement => (
        <>
            {label}
            &nbsp;
            {required && <span className={commonStyles.requireIcon}>*</span>}
        </>
    )

    return (
        <div className={commonStyles.formWrapper}>
            <Form
                name="complex-form"
                autoComplete="off"
                form={form}
                labelCol={{ span: 24 }}
                wrapperCol={{ span: 24 }}
                onFinish={handleFinish}
                requiredMark={customizeRequiredMark}
            >
                <Form.Item
                    label="Company Name"
                    name="companyName"
                    rules={[
                        {
                            required: true,
                            message: 'Please Enter Company Name',
                        },
                        {
                            pattern: /^[a-zA-Z\s]*$/,
                            message: 'Please enter a valid Company Name!',
                        },
                    ]}
                >
                    <Input />
                </Form.Item>
                <Form.Item
                    label="Given Name"
                    name="givenName"
                    rules={[
                        {
                            required: true,
                            message: 'Please Enter Given Name',
                        },
                        {
                            pattern: /^[a-zA-Z\s]*$/,
                            message: 'Please enter a valid Given Name!',
                        },
                    ]}
                >
                    <Input />
                </Form.Item>
                <Form.Item
                    label="Family Name"
                    name="familyName"
                    rules={[
                        {
                            required: true,
                            message: 'Please Enter Family Name',
                        },
                        {
                            pattern: /^[a-zA-Z\s]*$/,
                            message: 'Please enter a valid Family Name!',
                        },
                    ]}
                >
                    <Input />
                </Form.Item>
                <Form.Item
                    label="Email"
                    name="email"
                    rules={[
                        {
                            type: 'email',
                            required: true,
                            message: 'Please Enter Email',
                        },
                    ]}
                >
                    <Input />
                </Form.Item>
                <Form.Item
                    label="Address Line1"
                    name="addressLine1"
                    rules={[
                        {
                            required: true,
                            message: 'Please Enter Address Line1',
                        },
                    ]}
                >
                    <Input />
                </Form.Item>
                <Form.Item label="Address Line2" name="addressLine2">
                    <Input />
                </Form.Item>
                <Form.Item label="Address Line3" name="addressLine3">
                    <Input />
                </Form.Item>
                <Form.Item
                    label="City"
                    name="city"
                    rules={[
                        {
                            required: true,
                            message: 'Please Enter City',
                        },
                        {
                            pattern: /^[a-zA-Z\s]*$/,
                            message: 'Please enter a valid City!',
                        },
                    ]}
                >
                    <Input />
                </Form.Item>
                <Form.Item
                    label="Postal Code"
                    name="postalCode"
                    rules={[
                        {
                            required: true,
                            message: 'Please input your PostCode!',
                        },
                    ]}
                >
                    <Input />
                </Form.Item>
                <Form.Item
                    label="Country Code"
                    name="countryCode"
                    rules={[
                        {
                            required: true,
                            message: 'Please Enter Country Code',
                        },
                    ]}
                >
                    <Input />
                </Form.Item>
                <Form.Item
                    label="Region"
                    name="region"
                    rules={[
                        {
                            required: true,
                            message: 'Please Enter Region',
                        },
                    ]}
                >
                    <Input />
                </Form.Item>
                <Form.Item
                    label="Language"
                    name="language"
                    rules={[
                        {
                            required: true,
                            message: 'Please Enter Language',
                        },
                        {
                            pattern: /^[a-zA-Z\s]*$/,
                            message: 'Please enter a valid Language!',
                        },
                    ]}
                >
                    <Input />
                </Form.Item>
                <Form.Item
                    label="Phone Number"
                    name="phoneNumber"
                    rules={[
                        {
                            pattern: /^\(?(\d{2})\)?[- ]?(\d{4})[- ]?(\d{6})$/,
                            message: 'please enter valid mobile number',
                        },
                        {
                            required: true,
                            message: 'Please input your mobile number!',
                        },
                    ]}
                >
                    <Input />
                </Form.Item>
                <Form.Item
                    label="Description"
                    name="description"
                    rules={[
                        {
                            required: true,
                            message: 'Please Enter Description',
                        },
                    ]}
                >
                    <TextArea rows={4} />
                </Form.Item>
            </Form>
        </div>
    )
}

const mapStateToProps = (state: any): any => {
    return {
        registeredClientSoleTraderConfiguration:
            state.client.registeredClientSoleTraderConfiguration,
    }
}

export default connect(mapStateToProps)(GoCardlessForm)
