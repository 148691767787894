import React from 'react'
import commonStyle from './Common.less'
import type { TablePaginationConfig } from 'antd/es/table'

function calculatePagination(
    currentPage: number,
    totalItems: number,
    itemsPerPage: number
): TablePaginationConfig {
    const totalPages: number = Math.ceil(totalItems / itemsPerPage)
    const showtoal = (): React.ReactElement => {
        return (
            <div className={commonStyle.custom_pagination}>
                <div className={commonStyle.left_pagination}>
                    Page {currentPage} of {totalPages}{' '}
                </div>
            </div>
        )
    }
    const paginationConfig = {
        current: currentPage,
        total: totalItems,
        pageSize: itemsPerPage,
        showSizeChanger: true,
        pageSizeOptions: ['10', '20', '50'],
        showPrevNextJumpers: true,
        showTotal: showtoal,
        itemRender: (page: number, type: string, originalElement: any) => {
            if (type === 'prev') {
                return <a>Previous</a>
            }
            if (type === 'next') {
                return <a>Next</a>
            }

            return originalElement
        },
    }

    return paginationConfig
}

export default calculatePagination
