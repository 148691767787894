import { useLocation } from 'react-router-dom'
import { getClientCompanyDetails } from '../../../../Services/Individual'
import { connect, useDispatch } from 'react-redux'
import React, { useEffect, useState } from 'react'
import { Tabs } from 'antd'
import type { TabsProps } from 'antd'
import commonStyles from '../../../../Utils/Common.less'
import Documentmain from './Buttons/Documents/documentMain'
import HomeList from './Buttons/Home/HomeList'
import BusinessTag from '../Individual/Buttons/BusinessTag/BusinessTag'
// import ContactInfoEditForm from './Buttons/ContactInfo/ContactInfoEditForm'
import ServiceList from './Buttons/servicesTab/serviceView'
import ConfigurationForm from './IndividualConfiguration'
import Compliance from './Buttons/Compliance/compliance'
const IndividualTabs = (): React.ReactElement => {
    const location = useLocation()
    const { individualData, onbordingId } = location.state
    const IndividualData = individualData
    const dispatch = useDispatch()
    const businessTypeId = 3
    const [activeTab, setActiveTab] = useState('1')
    const individualId = IndividualData?.individualId

    const [companyservicedetails, setCompanyServiceDetails] = useState<any>({})
    const onChange = (key: string): void => {
        setActiveTab(key)
    }

    useEffect(() => {
        void getClientCompanyDetails(
            dispatch,
            businessTypeId,
            individualId,
            parseInt(activeTab, 10)
        )
    }, [activeTab, businessTypeId, dispatch])

    const fetchServiceData = async (): Promise<void> => {
        try {
            const data = await getClientCompanyDetails(
                dispatch,
                businessTypeId,
                individualId,
                4
            )
            setCompanyServiceDetails(data)
        } catch (error) {}
    }

    useEffect(() => {
        if (activeTab === '3') {
            void fetchServiceData()
        }
    }, [activeTab])
    const items: TabsProps['items'] = [
        {
            key: '1',
            label: 'Home',
            children: <HomeList individualId={individualId} />,
        },
        {
            key: '4',
            label: 'Services',
            children: <ServiceList individualId={individualId} />,
        },
        {
            key: '3',
            label: 'Documents',
            children: (
                <Documentmain
                    individualId={individualId}
                    companyservicedetails={companyservicedetails}
                />
            ),
        },

        {
            key: '9',
            label: 'Business Tag',
            children: <BusinessTag individualId={individualId} />,
        },

        {
            key: '6',
            label: 'Compliance',
            children: (
                <Compliance
                    onbordingId={onbordingId}
                    individualId={individualId}
                    tapId={20}
                />
            ),
        },
        {
            key: '8',
            label: 'Configuration',
            children: (
                <ConfigurationForm
                    registeredClientIndividualConfiguration={''}
                />
            ),
        },
    ]

    return (
        <div className={commonStyles.width100}>
            <Tabs activeKey={activeTab} onChange={onChange} items={items} />
        </div>
    )
}

const mapStateToProps = (state: any): any => {
    return {
        individualTaps: state.client.individualTaps,
    }
}

export default connect(mapStateToProps)(IndividualTabs)
