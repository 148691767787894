import React, { useEffect, useState, useCallback } from 'react'
import { connect, useDispatch } from 'react-redux'
import { Table, Space, Tooltip } from 'antd'
import { RightOutlined } from '@ant-design/icons'
import commonStyles from '../../../Utils/Common.less'
import SearchInput from '../../../Components/SearchInput'
import type { ColumnsType, TableProps } from 'antd/es/table'
import type { TableParamsInterface } from '../../../Types/CommonType'
import {
    commonTableParams,
    setCommonTableParamsData,
} from '../../../Utils/CommontTable'
import { ReactComponent as DeleteIcon } from '../../../Assest/Svg/DeleteIcon.svg'
import { ReactComponent as EditIcon } from '../../../Assest/Svg/EditIcon.svg'
import calculatePagination from '../../../Utils/Pagination'
import ApplyExpandView from './ApplyExpandView'
import {
    DeletePermission,
    GetPermissionList,
    SetPermissionApproval,
} from '../../../Services/HR/LeavePermission'
import {
    type PermissionListType,
    type ApplyFormListType,
    type ApplyFormStateType,
} from '../../../Types/Hr/LeavePermission'
import FullWidthModal from '../../../Components/FullWidthModal'
import ActivePremission from '../../../Utils/premissins'
import { PermissionType } from '../../../Types/Enum/PermissionType'

const ApplyPermissionList = (props: ApplyFormListType): React.ReactElement => {
    const dispatch = useDispatch()
    const {
        PermissionListCount,
        PermissionList,
        editedData,
        savaStatus,
        onFinish,
        statusHandlingFunction,
    } = props
    const [tableParams, setTableParams] =
        useState<TableParamsInterface>(commonTableParams)
    const [currentPage, setCurrentPage] = useState(1)
    const [itemsPerPage, setItemsPerPage] = useState(10)
    const [deleteModalStatus, setDeleteModalStatus] = useState(false)
    const [leaveID, setLeaveID] = useState(0)
    const [submitModalStatus, setSubmitModalStatus] = useState(false)
    const [submitLeaveId, setSubmitLeaveId] = useState(0)
    const [description, setDescription] = useState('')
    const [status, setStatus] = useState('')
    const getTableData = useCallback(() => {
        const { pageSize, pageNo, sortOrderList, searchCriteriaList } =
            setCommonTableParamsData(tableParams)
        GetPermissionList(dispatch, {
            pageNo,
            pageSize,
            searchCriteriaList,
            sortOrderList,
        })
    }, [
        tableParams?.sorter,
        tableParams.pagination?.current,
        tableParams?.filter,
    ])
    useEffect(() => {
        getTableData()
        statusHandlingFunction(false)
    }, [getTableData, savaStatus])

    useEffect(() => {
        const saveLeaveAndRefresh = async (): Promise<void> => {
            if (savaStatus) {
                try {
                    getTableData()
                } catch (error) {
                    console.error('Error while saving leave:', error)
                }
            }
        }

        saveLeaveAndRefresh().catch((error) => {
            console.error(
                'Error while saving leave and refreshing table:',
                error
            )
        })
    }, [dispatch, onFinish, savaStatus])

    const openSubmitModal = (record: PermissionListType): void => {
        setSubmitLeaveId(record.permissionId)
        setSubmitModalStatus(true)
        setDescription(record.description)
        setStatus('Pending Approval')
    }

    const deleteModalFunction = (id: number): void => {
        setDeleteModalStatus(!deleteModalStatus)
        setLeaveID(id)
    }

    const deleteLeave = (): void => {
        DeletePermission(dispatch, leaveID, getTableData)
        setDeleteModalStatus(!deleteModalStatus)
    }

    const closeModal = (): void => {
        setDeleteModalStatus(false)
    }

    const submitLeave = (
        LeaveId: number,
        Description: string,
        Status: string
    ): void => {
        SetPermissionApproval(dispatch, LeaveId, Description, Status)
        getTableData()
        setSubmitModalStatus(false)
    }

    const handleDataCountChange = useCallback(() => {
        setTableParams({
            ...tableParams,
            pagination: {
                ...tableParams.pagination,
                total: PermissionListCount,
            },
        })
    }, [PermissionListCount])

    useEffect(() => {
        handleDataCountChange()
    }, [handleDataCountChange])

    const handlePageChange = (pageNo: number, pageSize: number): void => {
        setCurrentPage(pageNo)
        setItemsPerPage(pageSize)
    }

    const pagination = calculatePagination(
        currentPage,
        PermissionListCount,
        itemsPerPage
    )

    const formatdate = (date: string | undefined): any => {
        if (date != null) {
            const dateObject = new Date(date)
            const year = dateObject.getFullYear()
            const month = dateObject.getMonth() + 1
            const day = dateObject.getDate()
            const formattedDate = `${month}/${day}/${year}`
            return formattedDate
        }
    }
    const columns: ColumnsType<PermissionListType> = [
        {
            title: 'Date',
            dataIndex: 'endTo',
            sorter: true,
            sortDirections: ['descend', 'ascend'],
            render: (text: string, record: PermissionListType) => {
                return <div>{formatdate(text)}</div>
            },
        },
        {
            title: 'Start Time',
            dataIndex: 'startTime',
        },
        {
            title: 'End Time',
            dataIndex: 'endTime',
        },
        {
            title: 'Description',
            dataIndex: 'description',
        },
        {
            title: 'Status',
            dataIndex: 'permissionApprovalStatus',
        },
        {
            title: 'Action',
            key: 'action',
            render: (_, record: PermissionListType) => {
                return (
                    <Space size="middle">
                        {record.permissionApprovalStatus !==
                            'Pending Approval' && (
                            <>
                                <Tooltip title="Submit To Approval">
                                    <RightOutlined
                                        onClick={() => {
                                            openSubmitModal(record)
                                        }}
                                        className={commonStyles.clickableIcon}
                                    />
                                </Tooltip>
                                {ActivePremission(
                                    '8000',
                                    PermissionType.UPDATE
                                ) && (
                                    <Tooltip title="Edit Permission">
                                        <EditIcon
                                            onClick={() => {
                                                openEditModal(record)
                                            }}
                                            className={
                                                commonStyles.clickableIcon
                                            }
                                        />
                                    </Tooltip>
                                )}
                                {ActivePremission(
                                    '8000',
                                    PermissionType.DELETE
                                ) && (
                                    <Tooltip title="Delete">
                                        <DeleteIcon
                                            onClick={() => {
                                                deleteModalFunction(
                                                    record.permissionId
                                                )
                                            }}
                                            className={
                                                commonStyles.clickableIcon
                                            }
                                        />
                                    </Tooltip>
                                )}
                            </>
                        )}
                    </Space>
                )
            },
        },
    ]

    const onChangeTableParams: TableProps<PermissionListType>['onChange'] = (
        pagination,
        filters,
        sorter: any
    ) => {
        handlePageChange(pagination.current ?? 1, pagination.pageSize ?? 10)
        setTableParams({
            ...tableParams,
            pagination,
            sorter: {
                field: sorter?.field ?? '',
                order: sorter?.order ?? '',
            },
        })
    }

    const onChangeText = (data: React.ChangeEvent<HTMLInputElement>): void => {
        onChangeFilter(data.target.value)
    }

    const onChangeFilter = (data: string): void => {
        setTableParams({
            ...tableParams,
            filter: {
                fieldName: 'description',
                searchOperator: 10,
                fieldValue1: data,
                fieldValue2: '',
            },
        })
    }

    const openEditModal = (record: PermissionListType): void => {
        editedData(record)
    }

    const expandableData = (record: PermissionListType): React.ReactElement => {
        return (
            <div>
                <ApplyExpandView listsData={record} />
            </div>
        )
    }

    return (
        <>
            <>
                <SearchInput
                    placeHolder={'Search Details by Description keywords'}
                    onSearch={onChangeFilter}
                    onChange={onChangeText}
                    width="478"
                />
                <div className={commonStyles.table}>
                    <Table
                        columns={columns}
                        dataSource={PermissionList}
                        onChange={onChangeTableParams}
                        pagination={pagination}
                        expandable={{
                            expandedRowRender: (record: PermissionListType) =>
                                expandableData(record),
                            rowExpandable: () => true,
                        }}
                    />
                    <FullWidthModal
                        modalStatus={deleteModalStatus}
                        closeModal={closeModal}
                        tittle="You are about to delete this Permission. Click 'Yes' to continue."
                        smallTittle="Warning: This action cannot be undone!"
                        yesButtonFunction={deleteLeave}
                    />
                    <FullWidthModal
                        modalStatus={submitModalStatus}
                        closeModal={() => {
                            setSubmitModalStatus(false)
                        }}
                        tittle="You are about to submit this Permission. Click 'Yes' to continue."
                        smallTittle="Warning: This action cannot be undone!"
                        yesButtonFunction={() => {
                            submitLeave(submitLeaveId, description, status)
                        }}
                    />
                </div>
            </>
        </>
    )
}

const mapStateToProps = (state: any): ApplyFormStateType => {
    return {
        PermissionList: state.hr.PermissionList,
        PermissionListCount: state.hr.PermissionListCount,
    }
}

export default connect(mapStateToProps)(ApplyPermissionList)
