import React from 'react'
import commonStyles from './Common.less'

function CopyrightTexts(): React.ReactElement {
    const currentYear = new Date().getFullYear()
    return (
        <div className={commonStyles.copyrightText}>
            &copy; {currentYear} All Rights Reserved. IYCONNECT&#174; is a
            registered trademark of{' '}
            <a href="https://www.iykons.com/" target="_blank" rel="noreferrer">
                IYKONS LIMITED.
            </a>
        </div>
    )
}

export default CopyrightTexts
