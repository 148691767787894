import { type Dispatch } from '@reduxjs/toolkit'
import {
    type ListBackendInterface,
    type ListFrontEndInterface,
    type TableListInterface,
} from '../../Types/CommonType'
import { ErrorMessage, SuccessMessage } from '../../Utils/Notification'
import { setLoadingStatus } from '../../Reducer/Common'
import axios from '../../Config/AxiosConfig'
import {
    TaskAttachmentList,
    allDepartmentList,
    follwersList,
    hoursList,
    projectDropDownList,
    satusList,
    subtaskDetailsList,
    taskDetailsList,
    treeListOut,
    userimageList,
    ProjectManagmentStausListInprogress,
    SubViewDetail,
    createadocclientList,
} from '../../Reducer/HelpDesk'
import {
    type setTasks,
    type TaskAttachment,
    type TaskData,
    type TaskFollowerRequestBody,
    type TaskHours,
    type ceteteclient,
    type subTaskData,
} from '../../Types/HelpDesk/Cretetask'
import { type ProjectManagmentSatusFormDataInterface } from '../../Types/ProjectMangement'
import { ProjectManagmentStausList } from '../../Reducer/InitialReducer'

export const GetTaskList = (
    dispatch: Dispatch,

    flagId: string | undefined,
    searchString: string,
    pageNo: number,
    pageSize: number,
    assignedBy: number,
    assignedTo: number
): void => {
    dispatch(setLoadingStatus({ status: true }))

    axios
        .get(
            `/api/Task/GetTaskList/${flagId}/${searchString}/${pageNo}/${pageSize}/${assignedBy}/${assignedTo}`
        )
        .then((response) => {
            if (response.status === 200) {
                const newData = response?.data?.data?.map((value: TaskData) => {
                    return {
                        ...value,
                        key: value.taskId,
                    }
                })
                response.data.data = newData
                dispatch(taskDetailsList({ ...response.data }))
            } else {
                ErrorMessage(response.data.operationMsg)
            }
            dispatch(setLoadingStatus({ status: false }))
        })
        .catch((error) => {
            ErrorMessage(error.response.data.operationMsg)
            console.error('Error fetching posts:', error)
            dispatch(setLoadingStatus({ status: false }))
        })
}

export const SubViewDetails = (
    dispatch: Dispatch,
    flagId: string | undefined,
    searchString: string,
    pageNo: number,
    pageSize: number,
    assignedBy: number,
    assignedTo: number
): void => {
    dispatch(setLoadingStatus({ status: true }))

    axios
        .get(
            `/api/Task/GetTaskList/${flagId}/${searchString}/${pageNo}/${pageSize}/${assignedBy}/${assignedTo}`
        )
        .then((response) => {
            if (response.status === 200) {
                const newData = response?.data?.data?.map((value: TaskData) => {
                    return {
                        ...value,
                        key: value.taskId,
                    }
                })
                response.data.data = newData
                dispatch(SubViewDetail({ ...response.data }))
            } else {
                ErrorMessage(response.data.operationMsg)
            }
            dispatch(setLoadingStatus({ status: false }))
        })
        .catch((error) => {
            ErrorMessage(error.response.data.operationMsg)
            console.error('Error fetching posts:', error)
            dispatch(setLoadingStatus({ status: false }))
        })
}

export const GetInprogressTaskList = (
    dispatch: Dispatch,
    flagId: string | undefined,
    searchString: string,
    pageNo: number,
    pageSize: number,
    assignedBy: number,
    assignedTo: number
): void => {
    dispatch(setLoadingStatus({ status: true }))

    axios
        .get(
            `/api/Task/GetTaskList/${flagId}/${searchString}/${pageNo}/${pageSize}/${assignedBy}/${assignedTo}`
        )
        .then((response) => {
            if (response.status === 200) {
                const newData = response?.data?.data?.map((value: TaskData) => {
                    return {
                        ...value,
                        key: value.taskId,
                    }
                })
                response.data.data = newData
                dispatch(
                    ProjectManagmentStausListInprogress({ ...response.data })
                )
            } else {
                ErrorMessage(response.data.operationMsg)
            }
            dispatch(setLoadingStatus({ status: false }))
        })
        .catch((error) => {
            ErrorMessage(error.response.data.operationMsg)
            console.error('Error fetching posts:', error)
            dispatch(setLoadingStatus({ status: false }))
        })
}

export const GetsubTaskList = async (
    dispatch: Dispatch,

    taskId: string
): Promise<any> => {
    dispatch(setLoadingStatus({ status: true }))

    return await axios
        .get(`/api/Task/GetSubTaskDetail/${taskId}`)

        .then((response) => {
            if (response.status === 200) {
                dispatch(subtaskDetailsList({ ...response.data }))
                return response.data
            }
        })
        .catch((error) => {
            console.error('Error fetching get:', error)
        })
        .finally(() => {
            dispatch(setLoadingStatus({ status: false }))
        })
}

export const SaveTask = async (
    dispatch: Dispatch,
    data: setTasks
): Promise<any> => {
    dispatch(setLoadingStatus({ status: true }))
    return await axios
        .post(`/api/Task/SetTask`, data)
        .then((response) => {
            dispatch(setLoadingStatus({ status: false }))
            if (response.status === 200) {
                SuccessMessage(response.data.operationMsg)
                return true
            } else {
                ErrorMessage(response.data.operationMsg)
                return false
            }
        })
        .catch((error) => {
            ErrorMessage(error.response.data.operationMsg)
            console.error('Error fetching posts:', error)
            dispatch(setLoadingStatus({ status: false }))
            return false
        })
}

export const getfollwersdroplist = async (
    dispatch: Dispatch,
    taskId: string
): Promise<any> => {
    dispatch(setLoadingStatus({ status: true }))
    return await axios
        .get(`/api/Task/GetTaskFollowerDetailsByTaskId/${taskId}`)
        .then((response) => {
            if (response.status === 200) {
                if (response.data.data !== null) {
                    dispatch(follwersList({ ...response.data }))
                    return response.data
                } else {
                    dispatch(
                        follwersList({
                            data: [],
                            noOfRecords: 0,
                        })
                    )
                }
            }
        })
        .catch((error) => {
            console.error('Error fetching get:', error)
        })
        .finally(() => {
            dispatch(setLoadingStatus({ status: false }))
        })
}
export const gethourssdroplist = async (
    dispatch: Dispatch,
    taskId: string,
    pageNo: number,
    pageSize: number
): Promise<any> => {
    dispatch(setLoadingStatus({ status: true }))
    return await axios
        .get(
            `/api/Task/GetTaskHoursListByTaskId/${taskId}/${pageNo}/${pageSize}`
        )
        .then((response) => {
            if (response.status === 200) {
                dispatch(hoursList({ ...response.data }))
                return response.data
            }
        })
        .catch((error) => {
            console.error('Error fetching get:', error)
        })
        .finally(() => {
            dispatch(setLoadingStatus({ status: false }))
        })
}
export const SaveHours = async (
    dispatch: Dispatch,
    data: TaskHours
): Promise<any> => {
    dispatch(setLoadingStatus({ status: true }))
    return await axios
        .post(`/api/Task/SetTaskHours`, data)
        .then((response) => {
            dispatch(setLoadingStatus({ status: false }))
            if (response.status === 200) {
                SuccessMessage(response.data.operationMsg)
                return true
            } else {
                ErrorMessage(response.data.operationMsg)
                return false
            }
        })
        .catch((error) => {
            ErrorMessage(error.response.data.operationMsg)
            console.error('Error fetching posts:', error)
            dispatch(setLoadingStatus({ status: false }))
            return false
        })
}
export const getusersdroplist = async (dispatch: Dispatch): Promise<any> => {
    dispatch(setLoadingStatus({ status: true }))
    return await axios
        .get(`/api/GetUserDetails`)
        .then((response) => {
            if (response.status === 200) {
                dispatch(userimageList({ ...response.data }))
                return response.data
            }
        })
        .catch((error) => {
            console.error('Error fetching get:', error)
        })
        .finally(() => {
            dispatch(setLoadingStatus({ status: false }))
        })
}
export const getattachmentdroplist = async (
    dispatch: Dispatch,
    taskId: string
): Promise<any> => {
    dispatch(setLoadingStatus({ status: true }))
    return await axios
        .get(`/api/Task/GetTaskAttachmentListByTaskId/${taskId}`)
        .then((response) => {
            if (response.status === 200) {
                dispatch(TaskAttachmentList({ ...response.data }))
                return response.data
            }
        })
        .catch((error) => {
            console.error('Error fetching get:', error)
        })
        .finally(() => {
            dispatch(setLoadingStatus({ status: false }))
        })
}
export const SaveAttachment = async (
    dispatch: Dispatch,
    data: TaskAttachment
): Promise<any> => {
    dispatch(setLoadingStatus({ status: true }))
    return await axios
        .post(`/api/Task/SetTaskAttachment `, data)
        .then((response) => {
            dispatch(setLoadingStatus({ status: false }))
            if (response.status === 200) {
                SuccessMessage(response.data.operationMsg)
                return true
            } else {
                ErrorMessage(response.data.operationMsg)
                return false
            }
        })
        .catch((error) => {
            ErrorMessage(error.response.data.operationMsg)
            console.error('Error fetching posts:', error)
            dispatch(setLoadingStatus({ status: false }))
            return false
        })
}

export const deleteAttachment = async (
    dispatch: Dispatch,
    id: string,
    getTableData: CallableFunction
): Promise<any> => {
    dispatch(setLoadingStatus({ status: true }))
    axios
        .get(`/api/SetDeleteRecord/11/${id}`)
        .then((response) => {
            if (response.status === 200) {
                getTableData()
                SuccessMessage(response.data.operationMsg)
            } else {
                ErrorMessage(response.data.operationMsg)
            }
            dispatch(setLoadingStatus({ status: false }))
        })
        .catch((error) => {
            ErrorMessage(error.response.data.operationMsg)
            console.error('Error fetching get:', error)
            dispatch(setLoadingStatus({ status: false }))
        })
}
export const Savefollowers = async (
    dispatch: Dispatch,
    data: TaskFollowerRequestBody
): Promise<any> => {
    dispatch(setLoadingStatus({ status: true }))
    return await axios
        .post(`/api/Task/SetTaskFollower `, data)
        .then((response) => {
            dispatch(setLoadingStatus({ status: false }))
            if (response.status === 200) {
                SuccessMessage(response.data.operationMsg)
                return true
            } else {
                ErrorMessage(response.data.operationMsg)
                return false
            }
        })
        .catch((error) => {
            ErrorMessage(error.response.data.operationMsg)
            console.error('Error fetching posts:', error)
            dispatch(setLoadingStatus({ status: false }))
            return false
        })
}
export const SaveClient = async (
    dispatch: Dispatch,
    data: ceteteclient
): Promise<any> => {
    dispatch(setLoadingStatus({ status: true }))
    return await axios
        .post(`/api/ClientOtherCompany/SetClientOtherCompany`, data)
        .then((response) => {
            dispatch(setLoadingStatus({ status: false }))
            if (response.status === 200) {
                SuccessMessage(response.data.operationMsg)
                return true
            } else {
                ErrorMessage(response.data.operationMsg)
                return false
            }
        })
        .catch((error) => {
            ErrorMessage(error.response.data.operationMsg)
            console.error('Error fetching posts:', error)
            dispatch(setLoadingStatus({ status: false }))
            return false
        })
}
export const GetsatusList = (dispatch: Dispatch): void => {
    dispatch(setLoadingStatus({ status: true }))
    axios
        .get('/api/GetStandardListing/76')
        .then((response) => {
            if (response.status === 200) {
                const newParentCategoryList: ListFrontEndInterface[] =
                    response?.data?.data?.map((data: ListBackendInterface) => {
                        return {
                            value: data.stdId,
                            label: data.stdName,
                        }
                    })
                dispatch(satusList(newParentCategoryList))
            } else {
                ErrorMessage(response.data.operationMsg)
            }
            dispatch(setLoadingStatus({ status: false }))
        })
        .catch((error) => {
            ErrorMessage(error.response.data.operationMsg)
            console.error('Error fetching posts:', error)
            dispatch(setLoadingStatus({ status: false }))
        })
}
export const SavesubTask = async (
    dispatch: Dispatch,
    data: subTaskData
): Promise<any> => {
    dispatch(setLoadingStatus({ status: true }))
    return await axios
        .post(`/api/Task/SetSubTask`, data)
        .then((response) => {
            dispatch(setLoadingStatus({ status: false }))
            if (response.status === 200) {
                SuccessMessage(response.data.operationMsg)
                return true
            } else {
                ErrorMessage(response.data.operationMsg)
                return false
            }
        })
        .catch((error) => {
            ErrorMessage(error.response.data.operationMsg)
            console.error('Error fetching posts:', error)
            dispatch(setLoadingStatus({ status: false }))
            return false
        })
}
export const GetSatusList = (
    dispatch: Dispatch,
    projectId: string | null,
    data: TableListInterface
): void => {
    dispatch(setLoadingStatus({ status: true }))
    const { pageNo, pageSize, searchCriteriaList, sortOrderList } = data
    const bodyData = {
        searchCriteriaList,
        sortOrderList,
    }

    axios
        .post(
            `/api/Project/GetProjectStatusList/${projectId}/${pageNo}/${pageSize}`,
            bodyData
        )
        .then((response) => {
            if (response.status === 200) {
                const newData = response?.data?.data?.map(
                    (value: ProjectManagmentSatusFormDataInterface) => {
                        return {
                            ...value,
                            key: value.projectStatusId,
                        }
                    }
                )
                response.data.data = newData
                dispatch(ProjectManagmentStausList({ ...response.data }))
            } else {
                ErrorMessage(response.data.operationMsg)
            }
            dispatch(setLoadingStatus({ status: false }))
        })
        .catch((error) => {
            ErrorMessage(error.response.data.operationMsg)
            console.error('Error fetching posts:', error)
            dispatch(setLoadingStatus({ status: false }))
        })
}

export const getProjectlist = (dispatch: Dispatch, Id: number): void => {
    dispatch(setLoadingStatus({ status: true }))
    axios
        .get(`/api/GetStandardListingWithOneParameter/69/${Id}`)
        .then((response) => {
            if (response.status === 200) {
                const newProject: ListFrontEndInterface[] =
                    response?.data?.data.map((data: ListBackendInterface) => {
                        return {
                            value: data.stdId,
                            label: data.stdName,
                        }
                    })
                dispatch(projectDropDownList(newProject))
            } else {
                ErrorMessage(response.data.operationMsg)
            }
            dispatch(setLoadingStatus({ status: false }))
        })
        .catch((error) => {
            ErrorMessage(error.response.data.operationMsg)
            console.error('Error fetching posts:', error)
            dispatch(setLoadingStatus({ status: false }))
        })
}

export const getAllDepartment = (dispatch: Dispatch): void => {
    dispatch(setLoadingStatus({ status: true }))
    axios
        .get(`/api/GetStandardListingWithOneParameter/26/1`)
        .then((response) => {
            if (response.status === 200) {
                const newProject: ListFrontEndInterface[] =
                    response?.data?.data.map((data: ListBackendInterface) => {
                        return {
                            value: data.stdId,
                            label: data.stdName,
                        }
                    })
                dispatch(allDepartmentList(newProject))
            } else {
                ErrorMessage(response.data.operationMsg)
            }
            dispatch(setLoadingStatus({ status: false }))
        })
        .catch((error) => {
            ErrorMessage(error.response.data.operationMsg)
            console.error('Error fetching posts:', error)
            dispatch(setLoadingStatus({ status: false }))
        })
}

export const Saveuserstaus = async (
    dispatch: Dispatch,
    taskId: string,
    projectStausId: string
): Promise<any> => {
    dispatch(setLoadingStatus({ status: true }))
    return await axios
        .get(
            `/api/Task/SetTaskUserStatus/${taskId}/${projectStausId}
        `
        )
        .then((response) => {
            dispatch(setLoadingStatus({ status: false }))
            if (response.status === 200) {
                SuccessMessage(response.data.operationMsg)
                return true
            } else {
                // ErrorMessage(response.data.operationMsg)
                return false
            }
        })
        .catch((error) => {
            // ErrorMessage(error.response.data.operationMsg)
            console.error('Error fetching posts:', error)
            dispatch(setLoadingStatus({ status: false }))
            return false
        })
}

export const Saveprojectstaus = async (
    dispatch: Dispatch,
    taskId: string,
    projectStausId: number
): Promise<any> => {
    dispatch(setLoadingStatus({ status: true }))
    return await axios
        .get(
            `/api/Task/SetTaskProjectStatus/${taskId}/${projectStausId}
        `
        )
        .then((response) => {
            dispatch(setLoadingStatus({ status: false }))
            if (response.status === 200) {
                SuccessMessage(response.data.operationMsg)
                return true
            } else {
                // ErrorMessage(response.data.operationMsg)
                return false
            }
        })
        .catch((error) => {
            // ErrorMessage(error.response.data.operationMsg)
            console.error('Error fetching posts:', error)
            dispatch(setLoadingStatus({ status: false }))
            return false
        })
}

export const getTreeList = (dispatch: Dispatch, Id: number): void => {
    dispatch(setLoadingStatus({ status: true }))
    axios
        .get(`/api/Project/GetProjectTreeView/${Id}`)
        .then((response) => {
            if (response.status === 200) {
                const newProject: ListFrontEndInterface[] =
                    response?.data?.data.map((data: ListBackendInterface) => {
                        return {
                            value: data.stdId,
                            label: data.stdName,
                        }
                    })
                dispatch(treeListOut(newProject))
            } else {
                ErrorMessage(response.data.operationMsg)
            }
            dispatch(setLoadingStatus({ status: false }))
        })
        .catch((error) => {
            ErrorMessage(error.response.data.operationMsg)
            console.error('Error fetching posts:', error)
            dispatch(setLoadingStatus({ status: false }))
        })
}

export const GetadhocclientList = (
    dispatch: Dispatch,
    data: TableListInterface
): void => {
    dispatch(setLoadingStatus({ status: true }))
    const { pageNo, pageSize, searchCriteriaList, sortOrderList } = data
    const bodyData = {
        searchCriteriaList,
        sortOrderList,
    }

    axios
        .post(
            `/api/ClientOtherCompany/GetClientOtherCompanyList/${pageNo}/${pageSize}`,
            bodyData
        )
        .then((response) => {
            if (response.status === 200) {
                const newData = response?.data?.data?.map(
                    (value: ceteteclient) => {
                        return {
                            ...value,
                            key: value.otherCompanyId,
                        }
                    }
                )
                response.data.data = newData
                dispatch(createadocclientList({ ...response.data }))
            } else {
                ErrorMessage(response.data.operationMsg)
            }
            dispatch(setLoadingStatus({ status: false }))
        })
        .catch((error) => {
            ErrorMessage(error.response.data.operationMsg)
            console.error('Error fetching posts:', error)
            dispatch(setLoadingStatus({ status: false }))
        })
}
export const DeleteAdhocclientDetails = (
    dispatch: Dispatch,
    id: string,
    getTableData: CallableFunction
): void => {
    dispatch(setLoadingStatus({ status: true }))
    axios
        .get(`/api/SetDeleteRecord/27/${id}`)
        .then((response) => {
            if (response.status === 200) {
                getTableData()
                SuccessMessage(response.data.operationMsg)
            } else {
                ErrorMessage(response.data.operationMsg)
            }
            dispatch(setLoadingStatus({ status: false }))
        })
        .catch((error) => {
            ErrorMessage(error.response.data.operationMsg)
            console.error('Error fetching posts:', error)
            dispatch(setLoadingStatus({ status: false }))
        })
}
export const ActiveInactiveAdhoc = (
    dispatch: Dispatch,
    id: string,
    status: string,
    getTableData: CallableFunction
): void => {
    dispatch(setLoadingStatus({ status: true }))
    axios
        .get(`/api/SetRecordActiveStatus/17/${id}/${status}`)
        .then((response) => {
            if (response.status === 200) {
                getTableData()
                SuccessMessage(response.data.operationMsg)
            } else {
                ErrorMessage(response.data.operationMsg)
            }
            dispatch(setLoadingStatus({ status: false }))
        })
        .catch((error) => {
            ErrorMessage(error.response.data.operationMsg)
            console.error('Error fetching get:', error)
            dispatch(setLoadingStatus({ status: false }))
        })
}

export const GetTaskData = async (
    dispatch: Dispatch,
    taskId: string
): Promise<any> => {
    dispatch(setLoadingStatus({ status: true }))

    try {
        const response = await axios.get(`/api/Task/GetTaskDetail/${taskId}`)

        if (response.status === 200) {
            return response?.data?.data
        } else {
            ErrorMessage('Failed to fetch data')
        }
    } catch (error) {
        console.error('Error fetching client company details:', error)
        ErrorMessage('An error occurred while fetching data')
    } finally {
        dispatch(setLoadingStatus({ status: false }))
    }
}
