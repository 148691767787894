import React from 'react'
import { Row, Table, Image } from 'antd'
import { connect } from 'react-redux'
import commonStyles from '../../../Utils/Common.less'
import type {
    GroupAndPartnerExpandViewInterface,
    GroupAndPartnerExpandViewStateInterface,
} from '../../../Types/GroupAndPartners'
import type { ListFrontEndInterface } from '../../../Types/CommonType'

const GroupAndPartnerExpandView = (
    props: GroupAndPartnerExpandViewInterface
): React.ReactElement => {
    const { parentCategoryList, groupAndPartnerData } = props
    const paretnCategoryData = parentCategoryList.find(
        (data: ListFrontEndInterface) =>
            data.value === groupAndPartnerData.parentCategoryId
    )

    const data = [
        {
            businessLogoPath: groupAndPartnerData.businessLogoPath,
            signaturePath: groupAndPartnerData.signaturePath,
            rubberStampLogo: groupAndPartnerData.rubberStampLogo,
        },
    ]

    return (
        <div className={commonStyles.expandCard}>
            <Row className={commonStyles.expandRow}>
                Alternative Email -:{' '}
                <div className={commonStyles.expandRowValue}>
                    {groupAndPartnerData.alternativeEmailAddress}
                </div>
            </Row>
            <Row className={commonStyles.expandRow}>
                Work No -:{' '}
                <div className={commonStyles.expandRowValue}>
                    {groupAndPartnerData.workNumber}
                </div>
            </Row>
            <Row className={commonStyles.expandRow}>
                Home No -:{' '}
                <div className={commonStyles.expandRowValue}>
                    {groupAndPartnerData.homeNumber}
                </div>
            </Row>
            <Row className={commonStyles.expandRow}>
                Website -:{' '}
                <div className={commonStyles.expandRowValue}>
                    {groupAndPartnerData.website}
                </div>
            </Row>
            <Row className={commonStyles.expandRow}>
                Parent Company -:{' '}
                <div className={commonStyles.expandRowValue}>
                    {paretnCategoryData?.label ?? ''}
                </div>
            </Row>
            <Row className={commonStyles.expandRow}>
                <div>Document Footer Text -:</div> {'  '}{' '}
                <div className={commonStyles.expandRowValue}>
                    {groupAndPartnerData.documentFooterText}
                </div>
            </Row>
            <Row className={commonStyles.expandTable}>
                Company Attachment
                <Table
                    columns={[
                        {
                            title: 'Business Log',
                            render: (_, tableData: any) => {
                                if (
                                    tableData?.businessLogoPath !== null &&
                                    tableData?.businessLogoPath !== undefined
                                ) {
                                    return (
                                        <Image
                                            width={200}
                                            height={200}
                                            src={tableData.businessLogoPath}
                                        />
                                    )
                                }
                                return '-'
                            },
                        },
                        {
                            title: 'E-Signature',
                            render: (_, tableData: any) => {
                                if (
                                    tableData.signaturePath !== null &&
                                    tableData.signaturePath !== undefined
                                ) {
                                    return (
                                        <Image
                                            width={200}
                                            height={200}
                                            src={tableData.signaturePath}
                                        />
                                    )
                                }
                                return '-'
                            },
                        },
                        {
                            title: 'Rubber Stamp',
                            render: (_, tableData: any) => {
                                if (
                                    tableData.rubberStampLogo !== null &&
                                    tableData.rubberStampLogo !== undefined
                                ) {
                                    return (
                                        <Image
                                            width={200}
                                            height={200}
                                            src={tableData.rubberStampLogo}
                                        />
                                    )
                                }
                                return '-'
                            },
                        },
                    ]}
                    dataSource={data}
                    pagination={false}
                />
            </Row>
        </div>
    )
}

const mapStateToProps = (
    state: any
): GroupAndPartnerExpandViewStateInterface => {
    return {
        parentCategoryList: state.initial.parentCategoryList,
    }
}

export default connect(mapStateToProps)(GroupAndPartnerExpandView)
