import React, { useEffect, useState } from 'react'
import { Card, Table, Tooltip } from 'antd'
import { GetInduvidualMobileRequest } from '../../../Services/MobileRequest'
import { type ClientRequestDetail } from '../../../Types/MobileRequest'
import { useDispatch } from 'react-redux'
interface TableDatas {
    key: number
    attachmentPath: string
    filepath: string
}
const DocumentViewModel = (props: {
    clientRequestId: number
}): React.ReactElement => {
    const { clientRequestId } = props
    const dispatch = useDispatch()
    const [induvidualMobileData, setInduvidualMobileData] =
        useState<ClientRequestDetail | null>(null)
    useEffect(() => {
        const fetchData = async (): Promise<void> => {
            try {
                const data = await GetInduvidualMobileRequest(
                    dispatch,
                    clientRequestId
                )
                setInduvidualMobileData(data)
            } catch (error) {
                console.error('Error fetching audit data:', error)
            }
        }

        void fetchData()
    }, [clientRequestId])

    const Servicecolumns = [
        {
            title: 'File',
            key: 'index',
            render: (record: TableDatas) => {
                return (
                    <div>
                        <Tooltip
                            title={'Click The Link File Will be Downloded'}
                        >
                            <a href={record.filepath} download>
                                {record.attachmentPath}
                            </a>
                        </Tooltip>
                    </div>
                )
            },
        },
    ]

    const datatasourec = induvidualMobileData?.attachements?.map(
        (file, index) => ({
            key: index,
            attachmentPath: file.substring(file.lastIndexOf('/') + 1),
            filepath: file,
        })
    )
    return (
        <div>
            <Card>
                <Table
                    columns={Servicecolumns}
                    dataSource={datatasourec}
                    pagination={false}
                />
            </Card>
        </div>
    )
}

export default DocumentViewModel
