import React from 'react'
import { Table } from 'antd'
import commontStyles from '../../../Utils/Common.less'
import {
    type TimesheetApprovelExpandView,
    type ApprovalHistory,
} from '../../../Types/ToApproveViewAll/Timesheet'

const TimesheetExpandView = (
    props: TimesheetApprovelExpandView
): React.ReactElement => {
    const { expandData } = props

    const columns = [
        {
            title: 'Level',
            dataIndex: 'levelName',
        },
        {
            title: 'Approver',
            dataIndex: 'approverName',
        },
        {
            title: 'Approval Date',
            dataIndex: 'approvedDate',
        },
        {
            title: 'Status',
            dataIndex: 'approvalStatus',
        },
        {
            title: 'Remarks',
            dataIndex: 'remarks',
        },
    ]

    const dataSource: ApprovalHistory[] = expandData.approvalHistoryList.map(
        (data) => ({
            ...data,
            key: data.workFlowId.toString(), // Provide a unique key for each item
        })
    )

    return (
        <div className={commontStyles.expandCard}>
            <Table
                columns={columns}
                dataSource={dataSource}
                pagination={false}
            />
        </div>
    )
}

export default TimesheetExpandView
