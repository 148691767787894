import { Button, Card, Flex } from 'antd'
import React from 'react'
import { SearchOutlined } from '@ant-design/icons'
import SearchInput from '../../../Components/SearchInput'
import { useNavigate } from 'react-router-dom'
const CompanyFormation = (): React.ReactElement => {
    const navigate = useNavigate()
    const onChangeText = (data: React.ChangeEvent<HTMLInputElement>): void => {
        if (data.target.value.length !== 0) {
            onChangeFilter(data.target.value)
        }
    }

    const onChangeFilter = (data: string): void => {}

    return (
        <>
            <div>
                <h2>Company List</h2>
            </div>
            <Card title="Company List">
                <Flex justify="space-between">
                    <SearchInput
                        placeHolder={'Search your keywords and entre'}
                        onSearch={onChangeFilter}
                        onChange={onChangeText}
                        width="478"
                    />
                    <Button
                        type="primary"
                        onClick={() => {
                            navigate(`/company-house/company-eligibility`)
                        }}
                    >
                        <SearchOutlined />
                    </Button>
                </Flex>
                <span>No Records Found</span>
            </Card>
        </>
    )
}

export default CompanyFormation
