import React, { useEffect, useState } from 'react'
import { connect, useDispatch } from 'react-redux'
import { Layout, Breadcrumb, Spin, Flex } from 'antd'
import SiderMenu from './siderMenu'
import LayoutBanner from './layoutBanner'
import styles from './Less/style.less'
import Login from '../Pages/User/login'
import LoginPin from '../Pages/User/loginPin'
import { authenticateWithJwt } from '../Services/Auth'
import { useLocation, Link } from 'react-router-dom'
import RoutingList, { breadCrumbNameRouteMapping } from '../Routes/routingList'
import type { MainLayoutProps } from '../Types/CommonType'
import CopyrightTexts from '../Utils/CopyrightText'
import { LeftOutlined, RightOutlined } from '@ant-design/icons'
const { Content } = Layout

interface ItemInterface {
    key: string
    title: string | JSX.Element
}

function MainLayout(props: MainLayoutProps): React.ReactElement {
    const { isLoading, isAuthenticate, isLogin } = { ...props }
    const dispatch = useDispatch()
    const [collapsed, setCollapsed] = useState(false)
    const [items, setItems] = useState<ItemInterface[]>([])

    const location = useLocation()

    const handleOnCollapse = (): void => {
        setCollapsed((prevState) => !prevState)
    }

    useEffect(() => {
        const token = localStorage.getItem('jwtToken')
        if (token !== null) {
            authenticateWithJwt(dispatch)
        }
    }, [])

    useEffect(() => {
        const pathSnippets = location.pathname.split('/')?.filter((i) => i)
        const items = pathSnippets?.map((data: any, index) => {
            let url = `/${pathSnippets.slice(0, index + 1).join('/')}`
            const key = url

            switch (url) {
                case '/system-configuration':
                    url = '/system-configuration/company-group'
                    break
                case '/service-setup':
                    url = '/service-setup/service-user'
                    break
                case '/clients':
                    url = '/clients/registered-clients'
                    break
                case '/missing-information':
                    url = '/missing-information/awaiting-approval'
                    break
                case '/invoicing':
                    url = '/invoicing/invoice-preparation'
                    break
                case '/invoicing/credit-control':
                    url = '/invoicing/credit-control/status-report'
                    break
                case '/help-desk':
                    url = '/help-desk/create-task'
                    break
                case '/hr':
                    url = '/hr/leave-permission'
                    break
                case '/hr/reports':
                    url = '/hr/reports/time-sheet'
                    break
                case '/user-management':
                    url = '/user-management/manage-user'
                    break
                case '/report':
                    url = '/report/service-status'
                    break
                case '/client-comunication':
                    url = '/client-comunication/bulk-mail'
                    break
                case '/wiki':
                    url = '/wiki/article-group'
                    break
                case '/company-house':
                    url = '/company-house/company-eligibility'
                    break
                case '/hrmc':
                    url = '/hrmc/hrmc-64-8'
                    break
                default:
                    break
            }

            const name = breadCrumbNameRouteMapping[data]
            return {
                key,
                title:
                    pathSnippets?.length - 1 === index ? (
                        name
                    ) : (
                        <Link to={url}>{name}</Link>
                    ),
            }
        })
        setItems(items)
    }, [location.pathname])

    if (!isLogin) {
        return <Login />
    }

    if (!isAuthenticate) {
        return <LoginPin />
    }

    return (
        <Layout className={styles.main}>
            <LayoutBanner
                collapsed={collapsed}
                handleOnCollapse={handleOnCollapse}
            />
            <Layout className={styles.layout} hasSider>
                <div style={{ display: 'flex', flexDirection: 'row' }}>
                    <SiderMenu
                        collapsed={collapsed}
                        handleOnCollapse={handleOnCollapse}
                    />
                    <div className={styles.divider}>
                        <div
                            className={styles.iconButton}
                            onClick={handleOnCollapse}
                        >
                            {collapsed ? <RightOutlined /> : <LeftOutlined />}
                        </div>
                    </div>
                </div>

                <Content className={styles.contentLayout}>
                    <Breadcrumb items={items} />
                    {isLoading && (
                        <div className={styles.overlay}>
                            <Spin />
                        </div>
                    )}
                    <RoutingList />
                    <Flex align="flex-end">
                        {items?.length > 0 && <CopyrightTexts />}
                    </Flex>
                </Content>
            </Layout>
        </Layout>
    )
}

const mapStateToProps = (state: any): MainLayoutProps => {
    return {
        isLogin: state.auth.isLogin,
        isAuthenticate: state.auth.isAuthenticate,
        isLoading: state.common.isLoading,
    }
}

export default connect(mapStateToProps)(MainLayout)
