import { useLocation } from 'react-router-dom'
import { getClientCompanyDetails } from '../../../../Services/SoleTrader'
import { connect, useDispatch } from 'react-redux'
import React, { useEffect, useState } from 'react'
import { Tabs } from 'antd'
import type { TabsProps } from 'antd'
import ServiceMain from './Buttons/ServicesTab/ServicesMain'
import HomeList from './Buttons/Home/HomeList'
import ContactInfoEditForm from './Buttons/ContactInfo/ContactInfoEditForm'
import Compliance from './Buttons/Compliance/compliance'
import SupplierInfo from './Buttons/SupplierInfo'
import Documentmain from './Buttons/Doucument/documentMain'
import SoleTraderConfiguration from './Buttons/soleTraderConfiguration'
const SoleTraderTabs = (): React.ReactElement => {
    const location = useLocation()
    const { SoleTraderData, onbordingId } = location.state
    const dispatch = useDispatch()
    const businessTypeId = 2
    const [activeTab, setActiveTab] = useState('1')
    const soleTraderId = SoleTraderData?.soleTraderId
    const contactTap = 5
    const [contactInfo, setCompanycontactInfo] = useState<any>({})
    const onChange = (key: string): void => {
        setActiveTab(key)
    }
    const [companyservicedetails, setCompanyServiceDetails] = useState<any>({})
    useEffect(() => {
        void getClientCompanyDetails(
            dispatch,
            businessTypeId,
            soleTraderId,
            parseInt(activeTab, 10)
        )
    }, [activeTab, businessTypeId, dispatch])

    const fetchDataContactInfo = async (): Promise<void> => {
        try {
            const data = await getClientCompanyDetails(
                dispatch,
                businessTypeId,
                soleTraderId,
                contactTap
            )
            setCompanycontactInfo(data)
        } catch (error) {}
    }

    const fetchServiceData = async (): Promise<void> => {
        try {
            const data = await getClientCompanyDetails(
                dispatch,
                businessTypeId,
                soleTraderId,
                4
            )
            setCompanyServiceDetails(data)
        } catch (error) {}
    }

    useEffect(() => {
        if (activeTab === '3') {
            void fetchDataContactInfo()
            void fetchServiceData()
        }
    }, [activeTab])

    const items: TabsProps['items'] = [
        {
            key: '1',
            label: 'Home',
            children: <HomeList soleTraderId={soleTraderId} />,
        },
        {
            key: '4',
            label: 'Services',
            children: <ServiceMain soletrderId={SoleTraderData.soleTraderId} />,
        },
        {
            key: '7',
            label: 'Supplier Info',
            children: (
                <SupplierInfo soleTraderId={SoleTraderData.soleTraderId} />
            ),
        },
        {
            key: '5',
            label: 'Contact Info',
            children: <ContactInfoEditForm />,
        },
        {
            key: '3',
            label: 'Documents',
            children: (
                <Documentmain
                    soleTraderId={soleTraderId}
                    companydetails={companyservicedetails}
                    companyservicedetails={companyservicedetails}
                />
            ),
        },
        {
            key: '6',
            label: 'Compliance',
            children: (
                <Compliance
                    soleTraderId={soleTraderId}
                    contactInfo={contactInfo}
                    onbordingId={onbordingId}
                />
            ),
        },
        {
            key: '8',
            label: 'Configuration',
            children: (
                <SoleTraderConfiguration
                    registeredClientSoleTraderConfiguration={''}
                />
            ),
        },
    ]

    return (
        <div style={{ width: '100%' }}>
            <Tabs activeKey={activeTab} onChange={onChange} items={items} />
        </div>
    )
}

const mapStateToProps = (state: any): any => {
    return {
        soleTraderTaps: state.client.soleTraderTaps,
    }
}

export default connect(mapStateToProps)(SoleTraderTabs)
