import React, { useState, useEffect } from 'react'
import commontStyles from '../../../../../Utils/Common.less'
import SoleTraderConfigurationLess from '../../../../../Less/Clients/RegisteredClients/Corporate/CorporateConfiguration.less'
import { Collapse, Switch, Row, Col, Modal } from 'antd'
import FullWidthModal from '../../../../../Components/FullWidthModal'
import GoCardlessForm from './GoCardless/GoCardlessForm'
import GoCardlessInviteClient from './GoCardless/GoCardlessInviteClient'
import {
    getClientPortalInvitation,
    GetDirectDebitCustomer,
} from '../../../../../Services/SoleTrader'
import { useDispatch, connect } from 'react-redux'
import { useLocation } from 'react-router-dom'
import { type SoleTraderConfigurationInterface } from '../../../../../Types/Client/RegisteredClients/SoleTrader'

const SoleTraderConfiguration = (
    props: SoleTraderConfigurationInterface
): React.ReactElement => {
    const { registeredClientSoleTraderConfiguration } = props
    const referenceId =
        registeredClientSoleTraderConfiguration?.data?.referenceId
    const enableGoCardlessFormStatus =
        referenceId !== null && referenceId !== undefined
    const [enableCustomerPortal, SetEnableCustomerPortal] = useState(false)
    const [enableGoCardlessForm, SetEnableGoCardlessForm] = useState(
        enableGoCardlessFormStatus
    )
    const [customerPortalModal, SetCustomerPortalModal] = useState(false)
    const [goCardlessModal, setGoCardLessModal] = useState(false)
    const dispatch = useDispatch()
    const location = useLocation()
    const soleTraderData = location.state?.SoleTraderData
    const soleTraderId = soleTraderData?.soleTraderId
    const businessTypeId = 2
    const [PortalStatusMessage, setCustomerPortalStatusMessage] = useState('')

    useEffect(() => {
        if (soleTraderId !== undefined) {
            GetDirectDebitCustomer(dispatch, 2, soleTraderId)
        }
    }, [dispatch, soleTraderId])

    useEffect(() => {
        setCustomerPortalStatusMessage(
            enableCustomerPortal ? 'Active' : 'Inactive'
        )
    }, [enableCustomerPortal])

    const onChangeEnableCustomerPortal = (): void => {
        SetEnableCustomerPortal(!enableCustomerPortal)
        SetCustomerPortalModal(true)
    }

    const closeCustomerPortalModal = (): void => {
        SetCustomerPortalModal(false)
        SetEnableCustomerPortal(false)
    }

    const acceptCustomerPortalModalEnable = (): void => {
        if (soleTraderId !== undefined) {
            void getClientPortalInvitation(
                dispatch,
                businessTypeId,
                soleTraderId
            )
            SetCustomerPortalModal(false)
        }
    }

    const onChangeGoCardlessForm = (): void => {
        SetEnableGoCardlessForm(!enableGoCardlessForm)
    }

    const cancelGoCardlessModal = (): void => {
        setGoCardLessModal(false)
    }

    const customerPortal = (): React.ReactElement => {
        return (
            <div>
                <Switch
                    onChange={onChangeEnableCustomerPortal}
                    checked={enableCustomerPortal}
                />
                &nbsp; Enable Customer Portal
            </div>
        )
    }

    const goCardless = (): React.ReactElement => {
        return (
            <div className={commontStyles.flexDirectionColumn}>
                <Row>
                    <Col span="12">
                        <Switch
                            onChange={onChangeGoCardlessForm}
                            checked={enableGoCardlessForm}
                        />
                        &nbsp; Enable GoCardless
                    </Col>
                    {(referenceId === null || referenceId === undefined) && (
                        <Col
                            span="12"
                            className={SoleTraderConfigurationLess.inviteClient}
                            onClick={() => {
                                setGoCardLessModal(true)
                            }}
                        >
                            Invite Client
                        </Col>
                    )}
                </Row>
                {enableGoCardlessForm && (
                    <GoCardlessForm
                        registeredClientSoleTraderConfiguration={
                            registeredClientSoleTraderConfiguration
                        } // Adjusted this prop
                    />
                )}
            </div>
        )
    }

    return (
        <>
            <div
                className={commontStyles.flexDirectionRow}
                style={{ width: '100%' }}
            >
                <div
                    className={
                        SoleTraderConfigurationLess.configurationRowWithMargin
                    }
                >
                    <Collapse
                        bordered={true}
                        items={[
                            {
                                key: '1',
                                label: 'Company House',
                                children: <div>Company House content</div>, // Placeholder content
                            },
                        ]}
                    />
                    <Collapse
                        bordered={true}
                        items={[
                            {
                                key: '1',
                                label: 'Customer Portal',
                                children: customerPortal(),
                            },
                        ]}
                    />
                </div>
                <div className={SoleTraderConfigurationLess.configurationRow}>
                    <Collapse
                        bordered={true}
                        items={[
                            {
                                key: '1',
                                label: 'HMRC',
                                children: <div>HMRC content</div>, // Placeholder content
                            },
                        ]}
                    />
                    <Collapse
                        bordered={true}
                        items={[
                            {
                                key: '1',
                                label: 'GoCardless',
                                children: goCardless(),
                            },
                        ]}
                    />
                </div>

                <FullWidthModal
                    modalStatus={customerPortalModal}
                    closeModal={closeCustomerPortalModal}
                    tittle={`You are about to ${PortalStatusMessage} this client account. Click 'Yes' to continue.`}
                    smallTittle="Warning: This action cannot be undone!"
                    yesButtonFunction={acceptCustomerPortalModalEnable}
                />

                <Modal
                    footer={null}
                    onCancel={cancelGoCardlessModal}
                    width={'30%'}
                    title={'Gocardless Invite Client'}
                    open={goCardlessModal}
                >
                    <GoCardlessInviteClient
                        paymentOptionDropDownList={[]}
                        cancelGoCardlessModal={cancelGoCardlessModal}
                    />
                </Modal>
            </div>
        </>
    )
}

const mapStateToProps = (state: any): any => {
    return {
        registeredClientSoleTraderConfiguration:
            state.client.registeredClientSoleTraderConfiguration,
    }
}

export default connect(mapStateToProps)(SoleTraderConfiguration)
