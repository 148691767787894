import { type Dispatch } from '@reduxjs/toolkit'
import axios from '../Config/AxiosConfig'
import { ErrorMessage, SuccessMessage } from '../Utils/Notification'
import { setLoadingStatus } from '../Reducer/Common'
import {
    type ServiceProcessCheckListResponse,
    type GetServicesDetals,
    type MyRequest,
    type GetServiceProcessHistoryPayload,
    type ServiceProcessIdData,
    type GetServiceProcessDataPayload,
    type SwitchUserSaveData,
    type GetServiceProcessDetilsPayload,
    type ServiceActionGroup,
    type changeProgressStatusSaveData,
    type SaveSkipActionData,
    type SaveProcessLavelSitchuser,
    type ClientCommunicationHistory,
    type InvoicePaymentStatusData,
    type PinnedMessageType,
    type ProcessCheckListItem,
    type ChangeDusDateSetData,
} from '../Types/ToDoViewAllList'
import {
    serviceProcessHistory,
    thingsTodoList,
    communicationHistoryList,
    serviceProcessInduvidualData,
    serviceProcessDetailsList,
} from '../Reducer/DashboardReducer'
import { type TableListInterface } from '../Types/CommonType'
import { type CommunicationHistory } from '../Types/Invoice/Invoicing'
import { type ChatMessage } from '../Types/Invoicing/Invoice'

export const GetToDoListClientWise = (
    dispatch: Dispatch,
    data: MyRequest
): void => {
    dispatch(setLoadingStatus({ status: true }))

    axios
        .post(`api/Dashboard/GetServiceFollowUpThings`, data)
        .then((response) => {
            if (response.status === 200) {
                if (response?.data?.noOfRecords !== 0) {
                    const newData = response?.data?.data?.map(
                        (value: GetServicesDetals) => {
                            return {
                                ...value,
                                key: value.serviceProcessId,
                            }
                        }
                    )

                    response.data.data = newData
                    dispatch(thingsTodoList({ ...response.data }))
                } else {
                    dispatch(
                        thingsTodoList({
                            data: [],
                            noOfRecords: 0,
                        })
                    )
                }
            } else {
                ErrorMessage(response.data.operationMsg)
            }
            dispatch(setLoadingStatus({ status: false }))
        })
        .catch((error) => {
            ErrorMessage(error.response.data.operationMsg)
            console.error('Error fetching posts:', error)
            dispatch(setLoadingStatus({ status: false }))
        })
}

export const GetToDoListTaskwise = (
    dispatch: Dispatch,
    data: MyRequest
): void => {
    dispatch(setLoadingStatus({ status: true }))

    axios
        .post(`/api/Dashboard/GetServiceFollowUpThingsTaskWise`, data)
        .then((response) => {
            if (response.status === 200) {
                if (response?.data?.noOfRecords !== 0) {
                    const newData = response?.data?.data?.map(
                        (value: GetServicesDetals) => {
                            return {
                                ...value,
                                key: value.serviceProcessId,
                            }
                        }
                    )

                    response.data.data = newData
                    dispatch(thingsTodoList({ ...response.data }))
                } else {
                    dispatch(
                        thingsTodoList({
                            data: [],
                            noOfRecords: 0,
                        })
                    )
                }
            } else {
                ErrorMessage(response.data.operationMsg)
            }
            dispatch(setLoadingStatus({ status: false }))
        })
        .catch((error) => {
            ErrorMessage(error.response.data.operationMsg)
            console.error('Error fetching posts:', error)
            dispatch(setLoadingStatus({ status: false }))
        })
}
export const GetServiceProcessHistory = (
    dispatch: Dispatch,
    serviceProcessId: number
): void => {
    dispatch(setLoadingStatus({ status: true }))
    axios
        .get(`/api/ServiceProcess/GetServiceProcessHistory/${serviceProcessId}`)
        .then((response) => {
            if (response.status === 200) {
                const serviceprocessList: ServiceProcessCheckListResponse =
                    response?.data?.data

                // Wrap the serviceprocessList in a payload object with a data property
                const payload: GetServiceProcessHistoryPayload = {
                    data: serviceprocessList,
                }

                // Dispatch the action with the wrapped payload
                dispatch(serviceProcessHistory(payload))
            } else {
                ErrorMessage(response.data.operationMsg)
            }
            dispatch(setLoadingStatus({ status: false }))
        })
        .catch((error) => {
            ErrorMessage(error.response.data.operationMsg)
            console.error('Error fetching posts:', error)
            dispatch(setLoadingStatus({ status: false }))
        })
}

export const GetToDoHistoryDetailsList = (
    dispatch: Dispatch,
    referenceId: number,
    selectedButtonIdd: number,
    data: TableListInterface
): void => {
    dispatch(setLoadingStatus({ status: true }))
    const { pageNo, pageSize, searchCriteriaList, sortOrderList } = data
    const bodyData = {
        searchCriteriaList,
        sortOrderList,
    }

    axios
        .post(
            `/api/Communication/GetCommunicationHistoryList/${referenceId}/${selectedButtonIdd}/${pageNo}/${pageSize}`,
            bodyData
        )
        .then((response) => {
            if (response.status === 200) {
                const newData = response?.data?.data?.map(
                    (value: CommunicationHistory) => {
                        return {
                            ...value,
                            key: value.communicationHistoryId,
                        }
                    }
                )
                response.data.data = newData
                dispatch(communicationHistoryList({ ...response.data }))
            } else {
                ErrorMessage(response.data.operationMsg)
            }
            dispatch(setLoadingStatus({ status: false }))
        })
        .catch((error) => {
            ErrorMessage(error.response.data.operationMsg)
            console.error('Error fetching posts:', error)
            dispatch(setLoadingStatus({ status: false }))
        })
}

export const GetServiceProcessInduvidual = (
    dispatch: Dispatch,
    serviceProcessId: number
): void => {
    dispatch(setLoadingStatus({ status: true }))
    axios
        .get(`/api/ServiceProcess/GetProcessDetail/${serviceProcessId}`)
        .then((response) => {
            if (response.status === 200) {
                const serviceprocessInduvidualList: ServiceProcessIdData =
                    response?.data?.data
                const payload: GetServiceProcessDataPayload = {
                    data: serviceprocessInduvidualList,
                }
                dispatch(serviceProcessInduvidualData(payload))
            } else {
                ErrorMessage(response.data.operationMsg)
            }
            dispatch(setLoadingStatus({ status: false }))
        })
        .catch((error) => {
            ErrorMessage(error.response.data.operationMsg)
            console.error('Error fetching posts:', error)
            dispatch(setLoadingStatus({ status: false }))
        })
}

export const GetMissingInfrmation = async (
    dispatch: Dispatch,
    serviceProcessId: number
): Promise<any> => {
    dispatch(setLoadingStatus({ status: true }))

    try {
        const response = await axios.get(
            `/api/MissedInfo/GetMissedFieldByServiceProcessId/${serviceProcessId}`
        )

        if (response.status === 200) {
            return response?.data?.data
        } else {
            ErrorMessage('Failed to fetch data')
        }
    } catch (error) {
        console.error('Error fetching client company details:', error)
        ErrorMessage('An error occurred while fetching data')
    } finally {
        dispatch(setLoadingStatus({ status: false }))
    }
}
export const SaveSwitchUser = async (
    dispatch: Dispatch,
    data: SwitchUserSaveData
): Promise<any> => {
    dispatch(setLoadingStatus({ status: true }))
    return await axios
        .post(`/api/ServiceProcess/SetSwitchUser`, data)
        .then((response) => {
            dispatch(setLoadingStatus({ status: false }))
            if (response.status === 200) {
                SuccessMessage(response.data.operationMsg)
                return true
            } else {
                ErrorMessage(response.data.operationMsg)
                return false
            }
        })
        .catch((error) => {
            ErrorMessage(error.response.data.operationMsg)
            console.error('Error fetching posts:', error)
            dispatch(setLoadingStatus({ status: false }))
            return false
        })
}

export const GetChat = async (
    dispatch: Dispatch,
    referenceId: number,
    businessId: number,
    getAddress: CallableFunction
): Promise<void> => {
    dispatch(setLoadingStatus({ status: true }))
    axios
        .get(`/api/ServiceProcess/GetChatList/1/${referenceId}/${businessId}`)
        .then((response) => {
            if (response.status === 200) {
                const chatdata = response.data.data
                getAddress(chatdata)
                SuccessMessage(response.data.operationMsg)
            } else {
                ErrorMessage(response.data.operationMsg)
            }

            dispatch(setLoadingStatus({ status: false }))
        })
        .catch((error) => {
            ErrorMessage(error.response.data.operationMsg)
            console.error('Error fetching data:', error)
            dispatch(setLoadingStatus({ status: false }))
        })
}

export const SaveChat = async (
    dispatch: Dispatch,
    data: ChatMessage
): Promise<any> => {
    dispatch(setLoadingStatus({ status: true }))
    return await axios
        .post(`/api/ServiceProcess/SetProcessChat`, data)
        .then((response) => {
            dispatch(setLoadingStatus({ status: false }))
            if (response.status === 200) {
                SuccessMessage(response.data.operationMsg)
                return true
            } else {
                ErrorMessage(response.data.operationMsg)
                return false
            }
        })
        .catch((error) => {
            ErrorMessage(error.response.data.operationMsg)
            console.error('Error fetching posts:', error)
            dispatch(setLoadingStatus({ status: false }))
            return false
        })
}

export const GetPinnedChat = async (
    dispatch: Dispatch,
    serviceProcessId: number
): Promise<any> => {
    dispatch(setLoadingStatus({ status: true }))

    try {
        const response = await axios.get(
            `/api/ServiceProcess/GetPinnedChat/${serviceProcessId}`
        )

        if (response.status === 200) {
            return response?.data?.data
        } else {
            ErrorMessage('Failed to fetch data')
        }
    } catch (error) {
        console.error('Error fetching client company details:', error)
        ErrorMessage('An error occurred while fetching data')
    } finally {
        dispatch(setLoadingStatus({ status: false }))
    }
}

export const GetServiceProcessAtionGroup = (
    dispatch: Dispatch,
    serviceProcessId: number
): void => {
    dispatch(setLoadingStatus({ status: true }))
    axios
        .get(`api/ServiceProcess/GetProcessActionGroup/${serviceProcessId}`)
        .then((response) => {
            if (response.status === 200) {
                const serviceprocessList: ServiceActionGroup[] =
                    response?.data?.data

                // Wrap the serviceprocessList in a payload object with a data property
                const payload: GetServiceProcessDetilsPayload = {
                    data: serviceprocessList,
                }

                // Dispatch the action with the wrapped payload
                dispatch(serviceProcessDetailsList(payload))
            } else {
                ErrorMessage(response.data.operationMsg)
            }
            dispatch(setLoadingStatus({ status: false }))
        })
        .catch((error) => {
            ErrorMessage(error.response.data.operationMsg)
            console.error('Error fetching posts:', error)
            dispatch(setLoadingStatus({ status: false }))
        })
}

export const SaveChangePrgress = async (
    dispatch: Dispatch,
    data: changeProgressStatusSaveData
): Promise<any> => {
    dispatch(setLoadingStatus({ status: true }))
    return await axios
        .post(`/api/ServiceProcess/SetActionStatus/`, data)
        .then((response) => {
            dispatch(setLoadingStatus({ status: false }))
            if (response.status === 200) {
                SuccessMessage(response.data.operationMsg)
                return true
            } else {
                ErrorMessage(response.data.operationMsg)
                return false
            }
        })
        .catch((error) => {
            ErrorMessage(error.response.data.operationMsg)
            console.error('Error fetching posts:', error)
            dispatch(setLoadingStatus({ status: false }))
            return false
        })
}

export const GetCheckListData = async (
    dispatch: Dispatch,
    serviceProcessId: number
): Promise<any> => {
    dispatch(setLoadingStatus({ status: true }))

    try {
        const response = await axios.get(
            `/api/ServiceProcess/GetProcessCheckList/${serviceProcessId}`
        )

        if (response.status === 200) {
            return response?.data?.data
        } else {
            ErrorMessage('Failed to fetch data')
        }
    } catch (error) {
        console.error('Error fetching client company details:', error)
        ErrorMessage('An error occurred while fetching data')
    } finally {
        dispatch(setLoadingStatus({ status: false }))
    }
}

export const SaveSkipAction = async (
    dispatch: Dispatch,
    data: SaveSkipActionData
): Promise<any> => {
    dispatch(setLoadingStatus({ status: true }))
    return await axios
        .post(`/api/ServiceProcess/SetSkip/`, data)
        .then((response) => {
            dispatch(setLoadingStatus({ status: false }))
            if (response.status === 200) {
                SuccessMessage(response.data.operationMsg)
                return true
            } else {
                ErrorMessage(response.data.operationMsg)
                return false
            }
        })
        .catch((error) => {
            ErrorMessage(error.response.data.operationMsg)
            console.error('Error fetching posts:', error)
            dispatch(setLoadingStatus({ status: false }))
            return false
        })
}
export const SetProcessLevelswitchUser = async (
    dispatch: Dispatch,
    data: SaveProcessLavelSitchuser
): Promise<any> => {
    dispatch(setLoadingStatus({ status: true }))
    return await axios
        .post(`/api/ServiceConfiguration/SetProcessLevelSwitchUser`, data)
        .then((response) => {
            dispatch(setLoadingStatus({ status: false }))
            if (response.status === 200) {
                SuccessMessage(response.data.operationMsg)
                return true
            } else {
                ErrorMessage(response.data.operationMsg)
                return false
            }
        })
        .catch((error) => {
            ErrorMessage(error.response.data.operationMsg)
            console.error('Error fetching posts:', error)
            dispatch(setLoadingStatus({ status: false }))
            return false
        })
}

export const SaveCommuniCationNormal = async (
    dispatch: Dispatch,
    data: ClientCommunicationHistory
): Promise<any> => {
    dispatch(setLoadingStatus({ status: true }))
    return await axios
        .post(`/api/Communication/SetCommunication/`, data)
        .then((response) => {
            dispatch(setLoadingStatus({ status: false }))
            if (response.status === 200) {
                SuccessMessage(response.data.operationMsg)
                return true
            } else {
                ErrorMessage(response.data.operationMsg)
                return false
            }
        })
        .catch((error) => {
            ErrorMessage(error.response.data.operationMsg)
            console.error('Error fetching posts:', error)
            dispatch(setLoadingStatus({ status: false }))
            return false
        })
}

export const SaveVoidThisModel = async (
    dispatch: Dispatch,
    serviceProcessId: number,
    comments: string
): Promise<any> => {
    dispatch(setLoadingStatus({ status: true }))
    return await axios
        .post(`/api/ServiceProcess/SetVoid/${serviceProcessId}/${comments}`)
        .then((response) => {
            dispatch(setLoadingStatus({ status: false }))
            if (response.status === 200) {
                SuccessMessage(response.data.operationMsg)
                return true
            } else {
                ErrorMessage(response.data.operationMsg)
                return false
            }
        })
        .catch((error) => {
            ErrorMessage(error.response.data.operationMsg)
            console.error('Error fetching posts:', error)
            dispatch(setLoadingStatus({ status: false }))
            return false
        })
}

export const SaveHoldthisThisProcess = async (
    dispatch: Dispatch,
    serviceProcessId: number,
    flagId: string,
    comments: string
): Promise<any> => {
    dispatch(setLoadingStatus({ status: true }))
    return await axios
        .post(
            `/api/ServiceProcess/SetHold/${serviceProcessId}/${flagId}/${comments}`
        )
        .then((response) => {
            dispatch(setLoadingStatus({ status: false }))
            if (response.status === 200) {
                SuccessMessage(response.data.operationMsg)
                return true
            } else {
                ErrorMessage(response.data.operationMsg)
                return false
            }
        })
        .catch((error) => {
            ErrorMessage(error.response.data.operationMsg)
            console.error('Error fetching posts:', error)
            dispatch(setLoadingStatus({ status: false }))
            return false
        })
}

export const SaveInvoicePymentDetails = async (
    dispatch: Dispatch,
    data: InvoicePaymentStatusData
): Promise<any> => {
    dispatch(setLoadingStatus({ status: true }))
    return await axios
        .post(`/api/AccountInvoice/SetInvoiceComplete`, data)
        .then((response) => {
            dispatch(setLoadingStatus({ status: false }))
            if (response.status === 200) {
                SuccessMessage(response.data.operationMsg)
                return true
            } else {
                ErrorMessage(response.data.operationMsg)
                return false
            }
        })
        .catch((error) => {
            ErrorMessage(error.response.data.operationMsg)
            console.error('Error fetching posts:', error)
            dispatch(setLoadingStatus({ status: false }))
            return false
        })
}

export const SavePinnedChat = async (
    dispatch: Dispatch,
    data: PinnedMessageType
): Promise<any> => {
    dispatch(setLoadingStatus({ status: true }))
    return await axios
        .post(`/api/ServiceProcess/SetPinnedChat`, data)
        .then((response) => {
            dispatch(setLoadingStatus({ status: false }))
            if (response.status === 200) {
                SuccessMessage(response.data.operationMsg)
                return true
            } else {
                ErrorMessage(response.data.operationMsg)
                return false
            }
        })
        .catch((error) => {
            ErrorMessage(error.response.data.operationMsg)
            console.error('Error fetching posts:', error)
            dispatch(setLoadingStatus({ status: false }))
            return false
        })
}

export const SetProcessCheckList = async (
    dispatch: Dispatch,
    data: ProcessCheckListItem
): Promise<any> => {
    dispatch(setLoadingStatus({ status: true }))
    return await axios
        .post(`/api/ServiceProcess/SetProcessCheckList`, data)
        .then((response) => {
            dispatch(setLoadingStatus({ status: false }))
            if (response.status === 200) {
                SuccessMessage(response.data.operationMsg)
                return true
            } else {
                ErrorMessage(response.data.operationMsg)
                return false
            }
        })
        .catch((error) => {
            ErrorMessage(error.response.data.operationMsg)
            console.error('Error fetching posts:', error)
            dispatch(setLoadingStatus({ status: false }))
            return false
        })
}

export const SetProcessCheckListFlag = async (
    dispatch: Dispatch,
    serviceProcessId: number,
    flagId: number
): Promise<any> => {
    dispatch(setLoadingStatus({ status: true }))
    return await axios
        .post(
            `/api/ServiceProcess/SetProcessCheckListFlag/${serviceProcessId}/${flagId}/`
        )
        .then((response) => {
            dispatch(setLoadingStatus({ status: false }))
            if (response.status === 200) {
                SuccessMessage(response.data.operationMsg)
                return true
            } else {
                ErrorMessage(response.data.operationMsg)
                return false
            }
        })
        .catch((error) => {
            ErrorMessage(error.response.data.operationMsg)
            console.error('Error fetching posts:', error)
            dispatch(setLoadingStatus({ status: false }))
            return false
        })
}

export const RevertProcess = async (
    dispatch: Dispatch,
    serviceProcessId: number,
    flagId: number,
    referenceId: number
): Promise<any> => {
    dispatch(setLoadingStatus({ status: true }))
    return await axios
        .post(
            `/api/ServiceProcess/SetRevertProcess/${serviceProcessId}/${flagId}/${referenceId}`
        )
        .then((response) => {
            dispatch(setLoadingStatus({ status: false }))
            if (response.status === 200) {
                SuccessMessage(response.data.operationMsg)
                return true
            } else {
                ErrorMessage(response.data.operationMsg)
                return false
            }
        })
        .catch((error) => {
            ErrorMessage(error.response.data.operationMsg)
            console.error('Error fetching posts:', error)
            dispatch(setLoadingStatus({ status: false }))
            return false
        })
}

export const GetSendEmailSDetailsByServiceProcessId = async (
    dispatch: Dispatch,
    serviceProcessId: number,
    tempId: number
): Promise<any> => {
    dispatch(setLoadingStatus({ status: true }))

    try {
        const response = await axios.get(
            `/api/Email/GetEmailDetail/${serviceProcessId}/${tempId}`
        )

        if (response.status === 200) {
            // SuccessMessage(response.data.operationMsg)
            return response.data.data
        } else {
            ErrorMessage(response.data.operationMsg)
        }
    } catch (error) {
        console.error('Error fetching client company details:', error)
        ErrorMessage('An error occurred while fetching data')
    } finally {
        dispatch(setLoadingStatus({ status: false }))
    }
}

export const ChangeDueDate = async (
    dispatch: Dispatch,
    data: ChangeDusDateSetData
): Promise<any> => {
    dispatch(setLoadingStatus({ status: true }))
    return await axios
        .post(`/api/ServiceProcess/SetDueDate`, data)
        .then((response) => {
            dispatch(setLoadingStatus({ status: false }))
            if (response.status === 200) {
                SuccessMessage(response.data.operationMsg)
                return true
            } else {
                ErrorMessage(response.data.operationMsg)
                return false
            }
        })
        .catch((error) => {
            ErrorMessage(error.response.data.operationMsg)
            console.error('Error fetching posts:', error)
            dispatch(setLoadingStatus({ status: false }))
            return false
        })
}

export const GetSupplierInfrmation = async (
    dispatch: Dispatch,
    serviceProcessId: number,
    serviceActionId: number
): Promise<any> => {
    dispatch(setLoadingStatus({ status: true }))

    try {
        const response = await axios.get(
            `/api/ServiceProcess/GetSupplierInfo/${serviceProcessId}/${serviceActionId}`
        )

        if (response.status === 200) {
            return response?.data?.data
        } else {
            ErrorMessage('Failed to fetch data')
        }
    } catch (error) {
        console.error('Error fetching client company details:', error)
        ErrorMessage('An error occurred while fetching data')
    } finally {
        dispatch(setLoadingStatus({ status: false }))
    }
}
