import React from 'react'
import { Table } from 'antd'
import commontStyles from '../../../Utils/Common.less'
import { type LeaveListExpadTable } from '../../../Types/ToApproveViewAll/LeaveApproval'

const LeaveListExpandView = (
    props: LeaveListExpadTable
): React.ReactElement => {
    const { expandData } = props

    const columns = [
        {
            title: 'Level',
            dataIndex: 'levelName',
        },
        {
            title: 'Approver',
            dataIndex: 'approverName',
        },
        {
            title: 'Approval Date',
            dataIndex: 'approvedDate',
        },
        {
            title: 'Status',
            dataIndex: 'approvalStatus',
        },
        {
            title: 'Remarks',
            dataIndex: 'remarks',
        },
    ]
    const newData = [expandData]
    return (
        <div className={commontStyles.expandCard}>
            <Table columns={columns} dataSource={newData} pagination={false} />
        </div>
    )
}

export default LeaveListExpandView
