import React, { useCallback, useEffect, useState } from 'react'
import { Col, Row, Button, Table, type TableProps } from 'antd'
import commonStyles from '../Utils/Common.less'
import { PhoneOutlined, MessageOutlined, MailOutlined } from '@ant-design/icons'
import { connect, useDispatch } from 'react-redux'
import SearchInput from './SearchInput'
import { type ColumnsType } from 'antd/es/table'
import {
    type InvoiceListCommunicationHistory,
    type InvoiceHistoryProps,
    type InvoiceHistorystateProps,
} from '../Types/Invoice/Invoicing'
import { type TableParamsInterface } from '../Types/CommonType'
import {
    commonTableParams,
    setCommonTableParamsData,
} from '../Utils/CommontTable'
import { GetInvoicetHistoryDetailsList } from '../Services/Invoice'
import calculatePagination from '../Utils/Pagination'

const InvoiceHistory = (props: InvoiceHistoryProps): React.ReactElement => {
    const {
        invoiceIds,
        invoiceHistoryList,
        invoiceHistoryListCount,
        seletedButton,
        setButtonID,
    } = props
    const dispatch = useDispatch()
    const [currentPage, setCurrentPage] = useState(1)
    const [itemsPerPage, setItemsPerPage] = useState(10)
    const [tableParams, setTableParams] =
        useState<TableParamsInterface>(commonTableParams)

    const [, setCommunicationId] = useState(0)

    const handleButtonClick = (buttonId: number): void => {
        setCommunicationId(buttonId)
        setButtonID(buttonId === seletedButton ? null : buttonId)
    }

    const getTableData = useCallback(() => {
        const { pageSize, pageNo, sortOrderList, searchCriteriaList } =
            setCommonTableParamsData(tableParams)
        if (seletedButton !== null && invoiceIds !== null) {
            GetInvoicetHistoryDetailsList(
                dispatch,
                invoiceIds,
                seletedButton ?? 0,
                {
                    pageNo,
                    pageSize,
                    searchCriteriaList,
                    sortOrderList,
                }
            )
        }
    }, [
        dispatch,
        seletedButton,
        invoiceIds,
        tableParams?.sorter,
        tableParams.pagination?.current,
        tableParams?.filter,
    ])

    useEffect(() => {
        getTableData()
    }, [getTableData])

    const handleDataCountChange = useCallback(() => {
        setTableParams({
            ...tableParams,
            pagination: {
                ...tableParams.pagination,
                total: invoiceHistoryListCount,
            },
        })
    }, [invoiceHistoryListCount])

    useEffect(() => {
        handleDataCountChange()
    }, [handleDataCountChange])

    const handlePageChange = (pageNo: number, pageSize: number): void => {
        setCurrentPage(pageNo)
        setItemsPerPage(pageSize)
    }

    const pagination = calculatePagination(
        currentPage,
        invoiceHistoryListCount,
        itemsPerPage
    )

    const columns: ColumnsType<InvoiceListCommunicationHistory> = [
        {
            title: 'Description',
            dataIndex: 'description',
            sorter: true,
            sortDirections: ['descend', 'ascend'],
        },
        {
            title: 'Date',
            dataIndex: 'historyDate',
        },
    ]

    const onChangeTableParams: TableProps<InvoiceListCommunicationHistory>['onChange'] =
        (pagination, filters, sorter: any) => {
            handlePageChange(pagination.current ?? 1, pagination.pageSize ?? 10)
            setTableParams({
                ...tableParams,
                pagination,
                sorter: {
                    field: sorter?.field ?? '',
                    order: sorter?.order ?? '',
                },
            })
        }

    const onChangeText = (data: React.ChangeEvent<HTMLInputElement>): void => {
        onChangeFilter(data.target.value)
    }

    const onChangeFilter = (data: string): void => {
        setTableParams({
            ...tableParams,
            filter: {
                fieldName: 'description',
                searchOperator: 10,
                fieldValue1: data,
                fieldValue2: '',
            },
        })
    }

    return (
        <div className={commonStyles.formWrapper}>
            <Row gutter={25} justify="start">
                <Col>
                    <Button
                        type="primary"
                        size="large"
                        className={commonStyles.historyButton}
                        onClick={() => {
                            handleButtonClick(1)
                        }}
                    >
                        <span className={commonStyles.buttontext}>Email</span>
                        <span>
                            <MailOutlined />
                        </span>
                    </Button>
                </Col>
                <Col>
                    <Button
                        type="primary"
                        size="large"
                        className={commonStyles.historyButton}
                        onClick={() => {
                            handleButtonClick(2)
                        }}
                    >
                        <span className={commonStyles.buttontext}>SMS</span>
                        <span>
                            <MessageOutlined />
                        </span>
                    </Button>
                </Col>
                <Col>
                    <Button
                        type="primary"
                        size="large"
                        className={commonStyles.historyButton}
                        onClick={() => {
                            handleButtonClick(3)
                        }}
                    >
                        <span className={commonStyles.buttontext}>Phone </span>
                        <span>
                            <PhoneOutlined />
                        </span>
                    </Button>
                </Col>
            </Row>

            {seletedButton != null && invoiceHistoryList != null && (
                <>
                    <Row>
                        <SearchInput
                            placeHolder={'Search your keywords'}
                            onSearch={onChangeFilter}
                            onChange={onChangeText}
                            width="478"
                        />
                    </Row>
                    {invoiceHistoryList != null ? (
                        <div className={commonStyles.table}>
                            <Table
                                columns={columns}
                                dataSource={invoiceHistoryList}
                                onChange={onChangeTableParams}
                                pagination={pagination}
                            />
                        </div>
                    ) : (
                        <div>No data found</div>
                    )}
                </>
            )}
        </div>
    )
}

const mapStateToProps = (state: any): InvoiceHistorystateProps => {
    return {
        invoiceHistoryList: state.invoice.invoiceHistoryList,
        invoiceHistoryListCount: state.invoice.invoiceHistoryListCount,
    }
}

export default connect(mapStateToProps)(InvoiceHistory)
