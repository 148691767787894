import { Col, Row, Input, Form, Card, Modal } from 'antd'
import commonStyles from '../../../../Utils/Common.less'
import { useLocation } from 'react-router-dom'
import AddNewButton from '../../../../Components/AddNewButton'
import React, { useState, type ReactElement } from 'react'
import SoleTraderTabs from './soleTraderTabs'
import { type SoleTraderData } from '../../../../Types/Client/RegisteredClients/SoleTrader'
import CreateTask from '../../../../Components/CreateTask'

const soleTraderView = (): ReactElement => {
    const location = useLocation()
    const SoleTraderData = location.state?.SoleTraderData
    const [isEditModalVisible, setEditModalVisible] = useState(false)
    const openEditModal = (): void => {
        setEditModalVisible(true)
    }

    const closeEditModal = (): void => {
        setEditModalVisible(false)
    }

    const handleCloseModal = (): void => {
        setEditModalVisible(false)
    }

    return (
        <>
            <div>
                <h2>SoleTrader Registration</h2>
            </div>

            <Card
                title={`${
                    SoleTraderData?.businessTradingName !== null &&
                    SoleTraderData?.businessTradingName !== undefined
                        ? SoleTraderData?.businessTradingName
                        : ''
                } - ${
                    SoleTraderData?.clientNumber !== null &&
                    SoleTraderData?.clientNumber !== undefined
                        ? SoleTraderData?.clientNumber
                        : ''
                }`}
                extra={
                    <AddNewButton
                        name="Create Task"
                        clickAction={() => {
                            openEditModal()
                        }}
                    />
                }
                className={commonStyles.card}
            >
                {' '}
                <Form>
                    <Row gutter={16}>
                        <Col span={12}>
                            <Form.Item label="Client Type" />
                        </Col>
                        <Col span={12}>
                            <Form.Item label="Client No" />
                        </Col>
                    </Row>
                    <Row gutter={16}>
                        <Col span={12}>
                            <Form.Item<SoleTraderData>>
                                <Input
                                    value={SoleTraderData?.clienttype}
                                    disabled
                                />
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item<SoleTraderData>>
                                <Input
                                    value={SoleTraderData?.clientNumber}
                                    disabled
                                />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={16}>
                        <Col span={12}>
                            <Form.Item label="client Name" />
                        </Col>
                        <Col span={12}>
                            <Form.Item label="Bussine Trading Name" />
                        </Col>
                    </Row>
                    <Row gutter={16}>
                        <Col span={12}>
                            <Form.Item<SoleTraderData>>
                                <Input
                                    value={SoleTraderData?.contactName}
                                    disabled
                                />
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item<SoleTraderData>>
                                <Input
                                    value={SoleTraderData?.businessTradingName}
                                    disabled
                                />
                            </Form.Item>
                        </Col>
                    </Row>
                </Form>
                <Row className={commonStyles.width100}>
                    <SoleTraderTabs />
                </Row>
                <Modal
                    footer={null}
                    onCancel={closeEditModal}
                    width={'50%'}
                    title="Create Task"
                    open={isEditModalVisible}
                >
                    <CreateTask onClose={handleCloseModal} />
                </Modal>
            </Card>
        </>
    )
}

export default soleTraderView
