import { type PayloadAction, createSlice } from '@reduxjs/toolkit'
import type { ListFrontEndInterface } from '../Types/CommonType'

interface InitailStateInterface {
    userList: ListFrontEndInterface[]
}

const UserReducer = createSlice({
    name: 'user',
    initialState: {
        userList: [],
    },
    reducers: {
        userList(
            state: InitailStateInterface,
            action: PayloadAction<ListFrontEndInterface[]>
        ) {
            state.userList = action?.payload
        },
    },
})

export const { userList } = UserReducer.actions
export default UserReducer.reducer
