import React, { useEffect, useState } from 'react'
import {
    Form,
    Input,
    Button,
    Switch,
    Row,
    Col,
    Space,
    DatePicker,
    Select,
    type DatePickerProps,
    Tooltip,
} from 'antd'
import { SaveOutlined } from '@ant-design/icons'
import commonStyles from '../../../../../../Utils/Common.less'
import {
    type TogilleAddress,
    type CtStateInterface,
    type ServiceCorporatepaye,
} from '../../../../../../Types/Client/RegisteredClients/Corporate'

import { connect, useDispatch } from 'react-redux'
import {
    GetAddress,
    SavepayeServices,
} from '../../../../../../Services/Corporate'
import { type ListFrontEndInterface } from '../../../../../../Types/CommonType'
import { GetCountryList } from '../../../../../../Services/Common'
import Servicesless from '../../../../../../Layouts/Less/style.less'
import moment from 'moment'
import dayjs from 'dayjs'
import ActivePremission from '../../../../../../Utils/premissins'
import { PermissionType } from '../../../../../../Types/Enum/PermissionType'
const { Option } = Select
const PayeForm = ({
    PAYEServiceData,
    isEditMode,
    countryList,
    corporateId,
    onSave,
}: {
    PAYEServiceData: ServiceCorporatepaye | null
    isEditMode: boolean
    countryList: ListFrontEndInterface[]
    corporateId: number

    onSave: () => void
}): React.ReactElement => {
    const dispatch = useDispatch()
    const [form] = Form.useForm()
    const [addressDatas] = useState<TogilleAddress | null>(null)
    const [SchemeStartDate, setSelectedDate] = useState<string>('')

    const [servicesEnabled, setServicesEnabled] = useState(
        PAYEServiceData?.status === 'Active'
    )
    const [CSIEnabled, setServicescs1Enabled] = useState(false)

    const [switchStates, setSwitchStates] = useState<{
        1: boolean
        2: boolean
        3: boolean
    }>({
        1: false,
        2: false,
        3: false,
    })

    const handleSwitchChange = (switchId: 1 | 2 | 3): void => {
        const newSwitchStates = {
            1: false,
            2: false,
            3: false,
        }
        if (!switchStates[switchId]) {
            newSwitchStates[switchId] = true
            void GetAddress(
                dispatch,
                corporateId,
                switchId,
                (addressData: TogilleAddress) => {
                    form.setFieldsValue({
                        addressLineOne: addressData.addressLineOne ?? '',
                    })
                }
            )
        }
        setSwitchStates(newSwitchStates)
    }

    useEffect(() => {
        GetCountryList(dispatch)
    }, [dispatch])

    useEffect(() => {
        if ((PAYEServiceData ?? null) !== null && isEditMode) {
            const countryData = countryList?.find(
                (data: ListFrontEndInterface) =>
                    data.label === PAYEServiceData?.country
            )
            const newData = {
                ...PAYEServiceData,
                country: countryData != null ? countryData.value : '',
            }
            form.setFieldsValue(newData)
        }
    }, [PAYEServiceData, isEditMode])

    useEffect(() => {
        if (PAYEServiceData != null && isEditMode) {
            form.setFieldsValue({
                accountOfficeReference: PAYEServiceData?.accountOfficeReference,
                csiFiling: PAYEServiceData?.csiFiling,
                filingMethodName: PAYEServiceData?.filingMethodName,
                gateWayId: PAYEServiceData?.gateWayId,
                hmrcPaymentFrequency: PAYEServiceData?.hmrcPaymentFrequency,
                hmrcPaymentFrequencyName:
                    PAYEServiceData?.hmrcPaymentFrequencyName,
                password: PAYEServiceData?.password,
                payeId: PAYEServiceData?.payeId,
                payeProcess: PAYEServiceData?.payeProcess,
                payeProcessName: PAYEServiceData?.payeProcessName,
                payrollFrequency: PAYEServiceData?.payrollFrequency,
                postCode: PAYEServiceData?.postCode,
                schemeStartDate: PAYEServiceData?.schemeStartDate,

                town: PAYEServiceData?.town,
                filingMethod: PAYEServiceData?.filingMethod,
                addressLineTwo: PAYEServiceData?.addressLineTwo,
                addressLineOne: PAYEServiceData?.addressLineOne,
                country: PAYEServiceData?.country,
                status: PAYEServiceData?.status,
                payeReference: PAYEServiceData?.payeReference,
                companyType: PAYEServiceData?.companyType,
            })
        }
    }, [PAYEServiceData, isEditMode, form])

    useEffect(() => {
        if (addressDatas?.addressLineOne != null) {
            const newFieldValues = {
                addressLineOne: addressDatas.addressLineOne,
                addressLineTwo: addressDatas.addressLineTwo,
                town: addressDatas.town,
                country: addressDatas.country,
                postCode: addressDatas.postCode,
            }
            form.setFieldsValue(newFieldValues)
        }
    }, [addressDatas])

    useEffect(() => {
        if (PAYEServiceData !== null) {
            setSelectedDate(PAYEServiceData.schemeStartDate)
        }
    }, [PAYEServiceData])
    useEffect(() => {
        form.setFieldValue('schemeStartDate', SchemeStartDate)
    }, [SchemeStartDate])

    const onChangeStartDate = (
        schemeStartDate: DatePickerProps['value'],
        dateString: string | string[]
    ): void => {
        if (schemeStartDate != null) {
            const isoDate = schemeStartDate.toISOString()
            setSelectedDate(isoDate)
        }
    }
    const handleClick = async (): Promise<void> => {
        try {
            await form.validateFields()
            const formData = form.getFieldsValue()
            const hmrcPaymentFrequencyName = formData.hmrcPaymentFrequencyName
            let hmrcPaymentFrequency: number
            if (hmrcPaymentFrequencyName === 'MONTHLY') {
                hmrcPaymentFrequency = 1
            } else {
                hmrcPaymentFrequency = 2
            }

            const filingMethodName = formData.filingMethodName
            let filingMethod: number
            if (filingMethodName === 'Nil Return') {
                filingMethod = 1
            } else if (filingMethodName === 'Standard Filing') {
                filingMethod = 2
            } else if (
                filingMethodName === 'Hours to be obtained WEEKLY Filing'
            ) {
                filingMethod = 3
            } else {
                filingMethod = 0
            }

            const dataToSave = {
                payeId: PAYEServiceData?.payeId ?? 0,
                companyType: PAYEServiceData?.companyType ?? 1,
                referenceId: corporateId,
                payeReference: formData?.payeReference ?? '',
                accountOfficeReference: formData?.accountOfficeReference ?? '',
                payrollFrequency: formData?.payrollFrequency ?? '',
                schemeStartDate: moment(formData.schemeStartDate).format(
                    'DD/MM/YYYY'
                ),
                addressLineTwo: formData?.addressLineTwo ?? '',
                addressLineOne: formData?.addressLineOne ?? '',
                town: formData?.town ?? '',
                country: formData?.country ?? '',
                postCode: formData?.postCode ?? '',
                gateWayId: formData?.gateWayId ?? '',
                password: formData?.password ?? '',
                status: servicesEnabled ? 'Active' : 'Inactive',
                csiFiling: CSIEnabled ? 1 : 0,
                payeProcess: PAYEServiceData?.payeProcess ?? 0,
                filingMethod: filingMethod ?? 0,
                payeProcessName: PAYEServiceData?.payeProcessName ?? '',
                filingMethodName: formData?.filingMethodName ?? '',
                hmrcPaymentFrequency: hmrcPaymentFrequency ?? 0,
                hmrcPaymentFrequencyName:
                    formData?.hmrcPaymentFrequencyName ?? '',
                payeProcessDate: formData?.payeProcessDate ?? '01/01/1900',
            }
            void SavepayeServices(dispatch, dataToSave)
            onSave()
        } catch (error) {}
    }

    const handleServiceSwitchChange1 = (checked: boolean): void => {
        setServicesEnabled(checked)
    }
    const handleServiceSwitchChange2 = (checked: boolean): void => {
        setServicescs1Enabled(checked)
    }

    const customizeRequiredMark = (
        label: React.ReactNode,
        { required }: { required: boolean }
    ): React.ReactElement => (
        <>
            {label}
            &nbsp;
            {required && <span className={commonStyles.requireIcon}>*</span>}
        </>
    )
    return (
        <div className={commonStyles.formWrapper}>
            <Form
                form={form}
                name="ct-form"
                layout={'vertical'}
                autoComplete="off"
                labelCol={{ span: 24 }}
                wrapperCol={{ span: 24 }}
                requiredMark={customizeRequiredMark}
            >
                <Row gutter={24} className={Servicesless.services}>
                    <Col span={8}>
                        <Form.Item
                            label="Same as Office"
                            name="officeSwitch"
                            labelCol={{ span: 16 }}
                            wrapperCol={{ span: 8 }}
                        >
                            <Switch
                                id="1"
                                checked={switchStates[1]}
                                onChange={() => {
                                    handleSwitchChange(1)
                                }}
                            />
                        </Form.Item>
                    </Col>
                    <Col span={8}>
                        <Form.Item
                            label="Same as Business"
                            name="businessSwitch"
                            labelCol={{ span: 16 }}
                            wrapperCol={{ span: 8 }}
                        >
                            <Switch
                                id="2"
                                checked={switchStates[2]}
                                onChange={() => {
                                    handleSwitchChange(2)
                                }}
                            />
                        </Form.Item>
                    </Col>
                    <Col span={8}>
                        <Form.Item
                            label="Same as Correspondence"
                            name="correspondenceSwitch"
                            labelCol={{ span: 16 }}
                            wrapperCol={{ span: 8 }}
                        >
                            <Switch
                                id="3"
                                checked={switchStates[3]}
                                onChange={() => {
                                    handleSwitchChange(3)
                                }}
                            />
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={16} className={Servicesless.services}>
                    <Col span={12}>
                        <Form.Item
                            label="PAYE Reference"
                            rules={[
                                {
                                    required: true,
                                    message:
                                        'Please input your PAYE Reference!',
                                },
                            ]}
                            name="payeReference"
                        >
                            <Input />
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item name="addressLineTwo" label="Address 2">
                            <Input />
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={16} className={Servicesless.services}>
                    <Col span={12}>
                        <Form.Item
                            label="Account Office Reference"
                            name="accountOfficeReference"
                            rules={[
                                {
                                    required: true,
                                    message:
                                        'Please input your Account Office Reference!',
                                },
                            ]}
                        >
                            <Input />
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item
                            label="Town "
                            name="town"
                            rules={[
                                {
                                    required: true,
                                    message: 'Please input your Town!',
                                },
                            ]}
                        >
                            <Input />
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={16} className={Servicesless.services}>
                    <Col span={12}>
                        <Form.Item
                            label="Payroll Frequency"
                            name="payrollFrequency"
                        >
                            <Select
                                showSearch
                                optionFilterProp="children"
                                filterOption={(input, option) =>
                                    typeof option?.children === 'string' && // Ensure option.children is a string
                                    (option.children as string)
                                        .toLowerCase()
                                        .includes(input.toLowerCase())
                                }
                            >
                                <Option value="WEEKLY">WEEKLY</Option>
                                <Option value="BI WEEKLY">BI WEEKLY</Option>
                                <Option value="FOUR WEEKLY">FOUR WEEKLY</Option>
                                <Option value="MONTHLY">MONTHLY</Option>
                            </Select>
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item label="Country " name="country">
                            <Select
                                allowClear
                                placeholder="Please select"
                                showSearch
                                onChange={() => {}}
                                options={countryList?.map((country) => ({
                                    value: country.label,
                                    label: country.label,
                                }))}
                            />
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={16} className={Servicesless.services}>
                    <Col span={12}>
                        <Form.Item
                            label="Scheme Start Date"
                            name="schemeStartDate"
                            rules={[
                                {
                                    required: true,
                                    message: 'Please input Start Date!',
                                },
                            ]}
                        >
                            <Space direction="vertical" size={12}>
                                <DatePicker
                                    format="DD/MM/YYYY"
                                    className={commonStyles.dateWidth}
                                    onChange={(date, dateString) => {
                                        onChangeStartDate(date, dateString)
                                    }}
                                    value={
                                        SchemeStartDate != null
                                            ? dayjs(SchemeStartDate)
                                            : null
                                    }
                                />
                            </Space>
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item
                            label="Post Code"
                            name="postCode"
                            rules={[
                                {
                                    required: true,
                                    message: 'Please input your Post Code!',
                                },
                            ]}
                        >
                            <Input />
                        </Form.Item>
                    </Col>
                </Row>

                <Row gutter={16} className={Servicesless.services}>
                    <Col span={12}>
                        <Form.Item label="Address 1" name="addressLineOne">
                            <Input />
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item label="HMRC Gateway ID" name="gateWayId">
                            <Input />
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={24} className={Servicesless.services}>
                    <Col span={12}>
                        <Form.Item label="CIS Filing" name="csiFiling">
                            {' '}
                            <Switch
                                onChange={handleServiceSwitchChange2}
                                checked={CSIEnabled}
                            />
                        </Form.Item>
                    </Col>

                    <Col span={12}>
                        <Form.Item label="Password" name="password">
                            <Input />
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={16} className={Servicesless.services}>
                    <Col span={12}>
                        <Form.Item label="Enable/Disable Services">
                            <Switch
                                onChange={handleServiceSwitchChange1}
                                checked={servicesEnabled}
                            />
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item
                            label="Filing Method"
                            name="filingMethodName"
                        >
                            <Select
                                placeholder="Please select"
                                showSearch
                                optionFilterProp="children"
                                filterOption={(input, option) =>
                                    typeof option?.children === 'string' && // Ensure option.children is a string
                                    (option.children as string)
                                        .toLowerCase()
                                        .includes(input.toLowerCase())
                                }
                            >
                                <Option value="Nil Return">Nil Return</Option>
                                <Option value="Standard Filing">
                                    Standard Filing
                                </Option>
                                <Option value="Hours to be obtained WEEKLY">
                                    Hours to be obtained WEEKLY
                                </Option>
                            </Select>
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={24} className={Servicesless.services}>
                    <Col span={12}></Col>
                    <Col span={12}>
                        <Form.Item
                            label="HMRC payment frequency"
                            name="hmrcPaymentFrequencyName"
                        >
                            <Select
                                showSearch
                                optionFilterProp="children"
                                filterOption={(input, option) =>
                                    typeof option?.children === 'string' && // Ensure option.children is a string
                                    (option.children as string)
                                        .toLowerCase()
                                        .includes(input.toLowerCase())
                                }
                            >
                                <Option value="MONTHLY">MONTHLY</Option>
                                <Option value="QUATERLY">QUATERLY</Option>
                            </Select>
                        </Form.Item>
                    </Col>
                </Row>
                {ActivePremission('3000', PermissionType.SAVE) && (
                    <Row gutter={16}>
                        <Col offset={22} span={2}>
                            <Tooltip title="Save PAYE Service">
                                <Button
                                    type="primary"
                                    onClick={() => {
                                        handleClick()
                                            .then(() => {
                                                console.log(
                                                    'Form submitted successfully'
                                                )
                                            })
                                            .catch((error: any) => {
                                                console.error(
                                                    'Error submitting form:',
                                                    error
                                                )
                                            })
                                    }}
                                >
                                    <SaveOutlined />
                                </Button>
                            </Tooltip>
                        </Col>
                    </Row>
                )}
            </Form>
        </div>
    )
}

const mapStateToProps = (state: any): CtStateInterface => {
    return {
        countryList: state.common.countryList,
    }
}
export default connect(mapStateToProps)(PayeForm)
