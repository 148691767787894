import { setLoadingStatus } from '../Reducer/Common'
import { ErrorMessage, SuccessMessage } from '../Utils/Notification'
import { type Dispatch } from '@reduxjs/toolkit'
import axios from '../Config/AxiosConfig'
import { type TableListInterface } from '../Types/CommonType'
import {
    type ProjectManagmentSatusFormDataInterface,
    type ProjectManagmentFormDataInterface,
    type AttachmentProjectManagmentFormDataInterface,
    type ProjectManagmentNotificationFormDataInterface,
    type ProjectManagmentTierFormDataInterface,
    type ProjectTier,
    type SaveProject,
} from '../Types/ProjectMangement'
import {
    AttachmentDropDownList,
    ProjectManagmentList,
    ProjectManagmentStausList,
    ProjectManagmentTierList,
} from '../Reducer/InitialReducer'

export const GetProjectList = (
    dispatch: Dispatch,
    data: TableListInterface
): void => {
    dispatch(setLoadingStatus({ status: true }))
    const { pageNo, pageSize, searchCriteriaList, sortOrderList } = data
    const bodyData = {
        searchCriteriaList,
        sortOrderList,
    }

    axios
        .post(`/api/Project/GetProjectList/${pageNo}/${pageSize}`, bodyData)
        .then((response) => {
            if (response.status === 200) {
                const newData = response?.data?.data?.map(
                    (value: ProjectManagmentFormDataInterface) => {
                        return {
                            ...value,
                            key: value.projectId,
                        }
                    }
                )
                response.data.data = newData
                dispatch(ProjectManagmentList({ ...response.data }))
            } else {
                ErrorMessage(response.data.operationMsg)
            }
            dispatch(setLoadingStatus({ status: false }))
        })
        .catch((error) => {
            ErrorMessage(error.response.data.operationMsg)
            console.error('Error fetching posts:', error)
            dispatch(setLoadingStatus({ status: false }))
        })
}

export const GetServiceSDropDownList = async (
    projectId: string,
    dispatch: Dispatch
): Promise<any> => {
    dispatch(setLoadingStatus({ status: true }))

    axios
        .get(`api/Project/GetProjectAttachmentList/${projectId}`)
        .then((response) => {
            if (response.status === 200) {
                const newData = response?.data?.data?.map((value: any) => ({
                    ...value,
                    key: value.projectId,
                }))
                response.data.data = newData

                dispatch(AttachmentDropDownList({ ...response.data }))
            } else {
                ErrorMessage(response.data.operationMsg)
            }
        })
        .catch((error) => {
            ErrorMessage(error.response.data.operationMsg)
            console.error('Error fetching posts:', error)
            dispatch(setLoadingStatus({ status: false }))
        })
        .finally(() => {
            dispatch(setLoadingStatus({ status: false }))
        })
}

export const saveProjectManagenent = async (
    dispatch: Dispatch,
    data: SaveProject
): Promise<any> => {
    dispatch(setLoadingStatus({ status: true }))

    return await axios
        .post(`/api/Project/SetProject`, data)
        .then((response) => {
            dispatch(setLoadingStatus({ status: false }))
            if (response.status === 200) {
                SuccessMessage(response.data.operationMsg)
                return true
            } else {
                ErrorMessage(response.data.operationMsg)
                return false
            }
        })
        .catch((error) => {
            ErrorMessage(error.response.data.operationMsg)
            console.error('Error fetching posts:', error)
            dispatch(setLoadingStatus({ status: false }))
            return false
        })
}
export const GetSatusList = (
    dispatch: Dispatch,
    projectId: string,
    data: TableListInterface
): void => {
    dispatch(setLoadingStatus({ status: true }))
    const { pageNo, pageSize, searchCriteriaList, sortOrderList } = data
    const bodyData = {
        searchCriteriaList,
        sortOrderList,
    }

    axios
        .post(
            `/api/Project/GetProjectStatusList/${projectId}/${pageNo}/${pageSize}`,
            bodyData
        )
        .then((response) => {
            if (response.status === 200) {
                const newData = response?.data?.data?.map(
                    (value: ProjectManagmentSatusFormDataInterface) => {
                        return {
                            ...value,
                            key: value.projectStatusId,
                        }
                    }
                )
                response.data.data = newData
                dispatch(ProjectManagmentStausList({ ...response.data }))
            } else {
                ErrorMessage(response.data.operationMsg)
            }
            dispatch(setLoadingStatus({ status: false }))
        })
        .catch((error) => {
            ErrorMessage(error.response.data.operationMsg)
            console.error('Error fetching posts:', error)
            dispatch(setLoadingStatus({ status: false }))
        })
}
export const GetTierList = (dispatch: Dispatch, projectId: string): void => {
    dispatch(setLoadingStatus({ status: true }))

    axios
        .post(`/api/Project/GetProjectTreeView/${projectId}`)
        .then((response) => {
            if (response.status === 200) {
                const newData = response?.data?.data?.map(
                    (value: ProjectManagmentTierFormDataInterface) => {
                        return {
                            ...value,
                            key: value.projectId,
                        }
                    }
                )
                response.data.data = newData
                dispatch(ProjectManagmentTierList({ ...response.data }))
            } else {
                ErrorMessage(response.data.operationMsg)
            }
            dispatch(setLoadingStatus({ status: false }))
        })
        .catch((error) => {
            ErrorMessage(error.response.data.operationMsg)
            console.error('Error fetching posts:', error)
            dispatch(setLoadingStatus({ status: false }))
        })
}
export const AddStausDetails = async (
    dispatch: Dispatch,
    data: ProjectManagmentSatusFormDataInterface
): Promise<any> => {
    dispatch(setLoadingStatus({ status: true }))
    return await axios
        .post(`/api/Project/SetProjectStatus`, data)
        .then((response) => {
            dispatch(setLoadingStatus({ status: false }))
            if (response.status === 200) {
                SuccessMessage(response.data.operationMsg)
                return true
            } else {
                ErrorMessage(response.data.operationMsg)
                return false
            }
        })
        .catch((error) => {
            ErrorMessage(error.response.data.operationMsg)
            console.error('Error fetching posts:', error)
            dispatch(setLoadingStatus({ status: false }))
            return false
        })
}
export const AddTierDetails = async (
    dispatch: Dispatch,
    data: ProjectTier
): Promise<any> => {
    dispatch(setLoadingStatus({ status: true }))
    return await axios
        .post(`/api/Project/SetProjectTier`, data)
        .then((response) => {
            dispatch(setLoadingStatus({ status: false }))
            if (response.status === 200) {
                SuccessMessage(response.data.operationMsg)
                return true
            } else {
                ErrorMessage(response.data.operationMsg)
                return false
            }
        })
        .catch((error) => {
            ErrorMessage(error.response.data.operationMsg)
            console.error('Error fetching posts:', error)
            dispatch(setLoadingStatus({ status: false }))
            return false
        })
}
export const saveProjectAttachment = async (
    dispatch: Dispatch,
    attachmentData: AttachmentProjectManagmentFormDataInterface
): Promise<boolean> => {
    dispatch(setLoadingStatus({ status: true }))

    const bodyData = {
        projectAttachmentId: attachmentData.projectAttachmentId ?? 0,
        projectId: attachmentData.projectId,
        attachmentFile: attachmentData.attachmentFile ?? '',
        description: attachmentData.description ?? '',
        canAccessByTaskLevel: attachmentData.canAccessByTaskLevel ?? 0,
        canAccessBySubTaskLevel: attachmentData.canAccessBySubTaskLevel ?? 0,
    }

    return await axios
        .post(`/Project/SetProjectAttachment`, bodyData)
        .then((response) => {
            dispatch(setLoadingStatus({ status: false }))
            if (response.status === 200) {
                SuccessMessage(response.data.operationMsg)
                return true
            } else {
                ErrorMessage(response.data.operationMsg)
                return false
            }
        })
        .catch((error) => {
            dispatch(setLoadingStatus({ status: false }))
            ErrorMessage(error.response.data.operationMsg)
            console.error('Error uploading attachment:', error)
            return false
        })
}
export const DeleteProject = (
    dispatch: Dispatch,
    id: string,
    getTableData: CallableFunction
): void => {
    dispatch(setLoadingStatus({ status: true }))
    axios
        .get(`api/SetDeleteRecord/35/${id}`)
        .then((response) => {
            if (response.status === 200) {
                getTableData()
                SuccessMessage(response.data.operationMsg)
            } else {
                ErrorMessage(response.data.operationMsg)
            }
            dispatch(setLoadingStatus({ status: false }))
        })
        .catch((error) => {
            ErrorMessage(error.response.data.operationMsg)
            console.error('Error fetching get:', error)
            dispatch(setLoadingStatus({ status: false }))
        })
}
export const DeleteTier = (
    dispatch: Dispatch,
    id: string,
    getTableData: CallableFunction
): void => {
    dispatch(setLoadingStatus({ status: true }))
    axios
        .get(`api/SetDeleteRecord/36/${id}`)
        .then((response) => {
            if (response.status === 200) {
                getTableData()
                SuccessMessage(response.data.operationMsg)
            } else {
                ErrorMessage(response.data.operationMsg)
            }
            dispatch(setLoadingStatus({ status: false }))
        })
        .catch((error) => {
            ErrorMessage(error.response.data.operationMsg)
            console.error('Error fetching get:', error)
            dispatch(setLoadingStatus({ status: false }))
        })
}
export const Addattach = async (
    dispatch: Dispatch,
    data: AttachmentProjectManagmentFormDataInterface
): Promise<any> => {
    dispatch(setLoadingStatus({ status: true }))
    return await axios
        .post(`/api/Project/SetProjectAttachment`, data)
        .then((response) => {
            dispatch(setLoadingStatus({ status: false }))
            if (response.status === 200) {
                SuccessMessage(response.data.operationMsg)
                return true
            } else {
                ErrorMessage(response.data.operationMsg)
                return false
            }
        })
        .catch((error) => {
            ErrorMessage(error.response.data.operationMsg)
            console.error('Error fetching posts:', error)
            dispatch(setLoadingStatus({ status: false }))
            return false
        })
}
export const CheckProjectNameExist = (
    dispatch: Dispatch,
    projectid: string,
    projectname: string
): void => {
    dispatch(setLoadingStatus({ status: true }))
    const bodyData = [
        {
            fieldName: 'projectid',
            compareOperator: 1,
            fieldValue1: projectid.toString(),
            fieldValue2: '',
        },
        {
            fieldName: 'projectname',
            compareOperator: 1,
            fieldValue1: projectname,
            fieldValue2: '',
        },
    ]
    axios
        .post(`/api/ExistObjectData/11`, bodyData)
        .then((response) => {
            if (response.status === 200 && Boolean(response?.data?.isExist)) {
                ErrorMessage(response.data.error.message)
            }
            dispatch(setLoadingStatus({ status: false }))
        })
        .catch((error) => {
            ErrorMessage(error.response.data.operationMsg)
            console.error('Error fetching post:', error)
            dispatch(setLoadingStatus({ status: false }))
        })
}
export const CheckProjectSatusExist = (
    dispatch: Dispatch,
    projectid: string,
    projectstatusid: string,
    projectStatusName: string
): void => {
    dispatch(setLoadingStatus({ status: true }))
    const bodyData = [
        {
            fieldName: 'projectid',
            compareOperator: 1,
            fieldValue1: projectid,
            fieldValue2: '',
        },
        {
            fieldName: 'projectstatusid',
            compareOperator: 1,
            fieldValue1: projectstatusid,
            fieldValue2: '',
        },
        {
            fieldName: 'projectStatusName',
            compareOperator: 1,
            fieldValue1: projectStatusName,
            fieldValue2: '',
        },
    ]
    axios
        .post(`/api/ExistObjectData/13`, bodyData)
        .then((response) => {
            if (response.status === 200 && Boolean(response?.data?.isExist)) {
                ErrorMessage(response.data.error.message)
            }
            dispatch(setLoadingStatus({ status: false }))
        })
        .catch((error) => {
            ErrorMessage(error.response.data.operationMsg)
            console.error('Error fetching post:', error)
            dispatch(setLoadingStatus({ status: false }))
        })
}
export const DeletesatusProject = (
    dispatch: Dispatch,
    id: string,
    getTableData: CallableFunction
): void => {
    dispatch(setLoadingStatus({ status: true }))
    axios
        .get(`api/SetDeleteRecord/37/${id}`)
        .then((response) => {
            if (response.status === 200) {
                getTableData()
                SuccessMessage(response.data.operationMsg)
            } else {
                ErrorMessage(response.data.operationMsg)
            }
            dispatch(setLoadingStatus({ status: false }))
        })
        .catch((error) => {
            ErrorMessage(error.response.data.operationMsg)
            console.error('Error fetching get:', error)
            dispatch(setLoadingStatus({ status: false }))
        })
}
export const AddNotificationDetails = async (
    dispatch: Dispatch,
    data: ProjectManagmentNotificationFormDataInterface
): Promise<any> => {
    dispatch(setLoadingStatus({ status: true }))
    return await axios
        .post(
            `/api/Project/SetProjectStatusNotification
        `,
            data
        )
        .then((response) => {
            dispatch(setLoadingStatus({ status: false }))
            if (response.status === 200) {
                // SuccessMessage(response.data.operationMsg)
                return true
            } else {
                ErrorMessage(response.data.operationMsg)
                return false
            }
        })
        .catch((error) => {
            ErrorMessage(error.response.data.operationMsg)
            console.error('Error fetching posts:', error)
            dispatch(setLoadingStatus({ status: false }))
            return false
        })
}
