import { type Dispatch } from '@reduxjs/toolkit'
import axios from '../Config/AxiosConfig'
import {
    workflowDetailsList,
    approveByDropDownList,
} from '../Reducer/InitialReducer'
import { ErrorMessage, SuccessMessage } from '../Utils/Notification'
import { setLoadingStatus } from '../Reducer/Common'
import type { WorkflowDetailsFormDataInterface } from '../Types/WorkflowDetails'
import type {
    ListFrontEndInterface,
    ListBackendInterface,
    TableListInterface,
} from '../Types/CommonType'

export const GetWorkflowDetailsList = (
    dispatch: Dispatch,
    data: TableListInterface,
    workflowId: string
): void => {
    dispatch(setLoadingStatus({ status: true }))
    const { pageNo, pageSize, searchCriteriaList, sortOrderList } = data
    const bodyData = {
        searchCriteriaList,
        sortOrderList,
    }

    axios
        .post(
            `/api/General/WorkflowDetailList/${workflowId}/${pageNo}/${pageSize}`,
            bodyData
        )
        .then((response) => {
            if (response.status === 200) {
                const newData = response?.data?.data?.map(
                    (value: WorkflowDetailsFormDataInterface) => {
                        return {
                            ...value,
                            key: value.workflowLevelId,
                        }
                    }
                )
                response.data.data = newData
                dispatch(workflowDetailsList({ ...response.data }))
            } else {
                ErrorMessage(response.data.operationMsg)
            }
            dispatch(setLoadingStatus({ status: false }))
        })
        .catch((error) => {
            ErrorMessage(error.response.data.operationMsg)
            console.error('Error fetching posts:', error)
            dispatch(setLoadingStatus({ status: false }))
        })
}

export const GetApproveByList = (
    dispatch: Dispatch,
    levelTypeId: number,
    departmentId: number
): void => {
    dispatch(setLoadingStatus({ status: true }))
    axios
        .get(
            `/api/GetStandardListingWithTwoParameter/31/${departmentId}/${levelTypeId}`
        )
        .then((response) => {
            if (response.status === 200) {
                const approvedByList: ListFrontEndInterface[] =
                    response?.data?.data?.map((data: ListBackendInterface) => {
                        return {
                            value: data.stdId,
                            label: data.stdName,
                        }
                    })
                dispatch(approveByDropDownList(approvedByList))
            } else {
                ErrorMessage(response.data.operationMsg)
            }
            dispatch(setLoadingStatus({ status: false }))
        })
        .catch((error) => {
            ErrorMessage(error.response.data.operationMsg)
            console.error('Error fetching get:', error)
            dispatch(setLoadingStatus({ status: false }))
        })
}

export const SaveWorkflowDetails = async (
    dispatch: Dispatch,
    data: WorkflowDetailsFormDataInterface
): Promise<any> => {
    const newData = {
        ...data,
        canSkip: data?.canSkip !== null && data?.canSkip !== undefined ? 1 : 0,
    }
    dispatch(setLoadingStatus({ status: true }))
    return await axios
        .post(`/api/General/SetWorkflowDetail`, newData)
        .then((response) => {
            dispatch(setLoadingStatus({ status: false }))
            if (response.status === 200) {
                SuccessMessage(response.data.operationMsg)
                return true
            } else {
                ErrorMessage(response.data.operationMsg)
                return false
            }
        })
        .catch((error) => {
            ErrorMessage(error.response.data.operationMsg)
            console.error('Error fetching posts:', error)
            dispatch(setLoadingStatus({ status: false }))
            return false
        })
}

export const DeleteWorkflowDetail = (
    dispatch: Dispatch,
    id: string,
    getTableData: CallableFunction
): void => {
    dispatch(setLoadingStatus({ status: true }))
    axios
        .get(`/api/SetDeleteRecord/17/${id}`)
        .then((response) => {
            if (response.status === 200) {
                getTableData()
                SuccessMessage(response.data.operationMsg)
            } else {
                ErrorMessage(response.data.operationMsg)
            }
            dispatch(setLoadingStatus({ status: false }))
        })
        .catch((error) => {
            ErrorMessage(error.response.data.operationMsg)
            console.error('Error fetching get:', error)
            dispatch(setLoadingStatus({ status: false }))
        })
}
