import {
    Col,
    Row,
    Input,
    Form,
    Collapse,
    Select,
    Switch,
    DatePicker,
    Space,
    type DatePickerProps,
    Flex,
} from 'antd'
import commonStyles from '../../../Utils/Common.less'
import soleTraderNewStyles from '../../../Less/Clients/RegisteredClients/SoleTrader/SoleTraderNew.less'
import { connect, useDispatch } from 'react-redux'
import React, { useEffect, useState } from 'react'
import type { SoleTraderCreateFormDataInterface } from '../../../Types/Client/RegisteredClients/SoleTrader'
import { GetCountryList } from '../../../Services/Common'
import { CheckOutlined, CloseSquareOutlined } from '@ant-design/icons'
import dayjs from 'dayjs'
import { type ListFrontEndInterface } from '../../../Types/CommonType'
import CusttomBackgroundButton from '../../../Components/CustomBagraoundButton'

export interface SoleTraderNewStateInterface {
    countryList: ListFrontEndInterface[]
}
const AddNewOfficerForm = (
    props: SoleTraderNewStateInterface
): React.ReactElement => {
    const { countryList } = props
    const [form] = Form.useForm()
    const [collapseOne, SetCollapseOne] = useState<string[] | string>(['1'])
    const [collapseTwo, SetCollapseTwo] = useState<string[] | string>([])
    const [selectedDateAp, setSelectedDateAp] = useState<string>('')
    const dispatch = useDispatch()

    useEffect(() => {
        GetCountryList(dispatch)
    }, [dispatch, Form])

    const customizeRequiredMark = (
        label: React.ReactNode,
        { required }: { required: boolean }
    ): React.ReactElement => (
        <>
            {label}
            &nbsp;
            {required && <span className={commonStyles.requireIcon}>*</span>}
        </>
    )

    const onSaveSoleTrader = (
        data: SoleTraderCreateFormDataInterface
    ): void => {
        if (collapseOne.length === 1) {
            SetCollapseOne(['1', '2'])
            SetCollapseTwo(['1', '2'])
        } else {
            // void saveSoleTrader(dispatch, data, saveCallBack)
        }
    }

    const collapseOneOnChange = (data: string | string[]): void => {
        SetCollapseOne(data)
    }

    const collapseTwoOnChange = (data: string | string[]): void => {
        SetCollapseTwo(data)
    }

    useEffect(() => {
        form.setFieldValue('dateOfBirth', selectedDateAp)
    }, [selectedDateAp])

    const onChangeDateAppoint = (
        dateofAppointment: DatePickerProps['value'],
        dateString: string | string[]
    ): void => {
        if (dateofAppointment != null) {
            const isoDate = dateofAppointment.toISOString()
            setSelectedDateAp(isoDate)
        }
    }

    const initialDetails = (): React.ReactElement => {
        return (
            <div>
                <Form.Item label="Member Person" name="clientCategoryId">
                    <Select
                        placeholder="Please select"
                        // options={[...categoryDropDownList]}
                        // filterOption={(input, option) =>
                        //     option?.label
                        //         ?.toLowerCase()
                        //         .includes(input.toLowerCase()) ?? false
                        // }
                        allowClear
                        showSearch
                    />
                </Form.Item>
                <Form.Item label="Tittle" name="tittle">
                    <Select
                        allowClear
                        placeholder="Please select title"
                        onChange={() => {}}
                        defaultValue={'UNKNOWN'}
                        filterOption={(input, option) =>
                            typeof option?.children === 'string' && // Ensure option.children is a string
                            (option.children as string)
                                .toLowerCase()
                                .includes(input.toLowerCase())
                        }
                        showSearch
                    >
                        <Select.Option value="UNKNOWN">Unknown</Select.Option>
                        <Select.Option value="MR.">MR.</Select.Option>
                        <Select.Option value="MISS.">MISS.</Select.Option>
                        <Select.Option value="MRS.">MRS.</Select.Option>
                        <Select.Option value="MS.">MS.</Select.Option>
                        <Select.Option value="DR.">DR.</Select.Option>
                    </Select>
                </Form.Item>
                <Form.Item
                    label="First Name"
                    name="firstName"
                    rules={[
                        {
                            required: true,
                            message: 'Please Enter First Name',
                        },
                    ]}
                >
                    <Input />
                </Form.Item>
                <Form.Item label="Middle Name" name="middleName">
                    <Input />
                </Form.Item>
                <Form.Item
                    label="Surename"
                    name="surName"
                    rules={[
                        {
                            required: true,
                            message: 'Please Enter Surename',
                        },
                    ]}
                >
                    <Input />
                </Form.Item>
            </div>
        )
    }

    const personalDetails = (): React.ReactElement => {
        return (
            <div>
                <Form.Item label="Date Of Birth" name="dateOfBirth">
                    <Space direction="vertical" size={12}>
                        <DatePicker
                            className={commonStyles.dateWidth}
                            onChange={(date, dateString) => {
                                onChangeDateAppoint(date, dateString)
                            }}
                            value={
                                selectedDateAp.length > 0
                                    ? dayjs(selectedDateAp)
                                    : null
                            }
                            format="M/D/YYYY"
                        />
                    </Space>
                </Form.Item>
                <Form.Item
                    label="Nationality"
                    name="nationality"
                    rules={[
                        {
                            required: true,
                            message: 'Please select Nationality',
                        },
                    ]}
                >
                    <Select
                        allowClear
                        showSearch
                        placeholder="Please select"
                        onChange={() => {}}
                        optionFilterProp="children"
                        options={countryList?.map((data: { label: string }) => {
                            return {
                                id: data.label,
                                value: data.label,
                                label: data.label,
                            }
                        })}
                        filterOption={(input, option) =>
                            option?.label
                                ?.toLowerCase()
                                .includes(input.toLowerCase()) ?? false
                        }
                    />
                </Form.Item>
                <Form.Item label="Occupation" name="Occupation">
                    <Input />
                </Form.Item>

                <Form.Item label="Country" name="homeCountry">
                    <Select
                        allowClear
                        showSearch
                        placeholder="Please select"
                        onChange={() => {}}
                        optionFilterProp="children"
                        options={countryList?.map((data: { label: string }) => {
                            return {
                                id: data.label,
                                value: data.label,
                                label: data.label,
                            }
                        })}
                        filterOption={(input, option) =>
                            option?.label
                                ?.toLowerCase()
                                .includes(input.toLowerCase()) ?? false
                        }
                    />
                </Form.Item>
                <Form.Item label="Date of Appointment" name="dateOfBirth">
                    <Space direction="vertical" size={12}>
                        <DatePicker
                            className={commonStyles.dateWidth}
                            onChange={(date, dateString) => {
                                onChangeDateAppoint(date, dateString)
                            }}
                            value={
                                selectedDateAp.length > 0
                                    ? dayjs(selectedDateAp)
                                    : null
                            }
                            format="M/D/YYYY"
                        />
                    </Space>
                </Form.Item>
            </div>
        )
    }

    const homeAddress = (): React.ReactElement => {
        return (
            <div>
                <Form.Item
                    label="Address 1"
                    name="homeAddressOne"
                    rules={[
                        {
                            required: true,
                            message: 'Please Enter Address1',
                        },
                    ]}
                >
                    <Input />
                </Form.Item>
                <Form.Item label="Address 2" name="homeAddressTwo">
                    <Input />
                </Form.Item>
                <Form.Item
                    label="Town"
                    name="homeTown"
                    rules={[
                        {
                            required: true,
                            message: 'Please Enter Town',
                        },
                    ]}
                >
                    <Input />
                </Form.Item>
                <Form.Item label="Country" name="homeCountry">
                    <Select
                        allowClear
                        showSearch
                        placeholder="Please select"
                        onChange={() => {}}
                        optionFilterProp="children"
                        options={countryList?.map((data: { label: string }) => {
                            return {
                                id: data.label,
                                value: data.label,
                                label: data.label,
                            }
                        })}
                        filterOption={(input, option) =>
                            option?.label
                                ?.toLowerCase()
                                .includes(input.toLowerCase()) ?? false
                        }
                    />
                </Form.Item>
                <Form.Item
                    label="Post Code"
                    name="homePostCode"
                    rules={[
                        {
                            required: true,
                            message: 'Please Enter Post Code',
                        },
                    ]}
                >
                    <Input />
                </Form.Item>
            </div>
        )
    }

    const onChangeCAHome = (checked: boolean): void => {
        let corrAddress1 = ''
        let corrAddress2 = ''
        let corrTown = ''
        let corrCountry = ''
        let corrPostCode = ''
        if (checked) {
            corrAddress1 = form.getFieldValue('homeAddressOne')
            corrAddress2 = form.getFieldValue('homeAddressTwo')
            corrTown = form.getFieldValue('homeTown')
            corrCountry = form.getFieldValue('homeCountry')
            corrPostCode = form.getFieldValue('homePostCode')
        }
        form.setFieldValue('corrAddress1', corrAddress1)
        form.setFieldValue('corrAddress2', corrAddress2)
        form.setFieldValue('corrTown', corrTown)
        form.setFieldValue('corrCountry', corrCountry)
        form.setFieldValue('corrPostCode', corrPostCode)
    }

    const onChangeCABusiness = (checked: boolean): void => {
        let corrAddress1 = ''
        let corrAddress2 = ''
        let corrTown = ''
        let corrCountry = ''
        let corrPostCode = ''
        if (checked) {
            corrAddress1 = form.getFieldValue('busAddress1')
            corrAddress2 = form.getFieldValue('busAddress2')
            corrTown = form.getFieldValue('busTown')
            corrCountry = form.getFieldValue('busCountry')
            corrPostCode = form.getFieldValue('busPostCode')
        }
        form.setFieldValue('corrAddress1', corrAddress1)
        form.setFieldValue('corrAddress2', corrAddress2)
        form.setFieldValue('corrTown', corrTown)
        form.setFieldValue('corrCountry', corrCountry)
        form.setFieldValue('corrPostCode', corrPostCode)
    }

    const correspondAddress = (): React.ReactElement => {
        return (
            <div>
                <div className={soleTraderNewStyles.cpAddressMain}>
                    <div className={commonStyles.margintRight100}>
                        <Switch size="small" onChange={onChangeCAHome} />
                        &nbsp;
                        <label className={commonStyles.colorGray}>
                            Same as Home
                        </label>
                    </div>
                    <div>
                        <Switch size="small" onChange={onChangeCABusiness} />
                        &nbsp;
                        <label className={commonStyles.colorGray}>
                            Same as Business
                        </label>
                    </div>
                </div>
                <Form.Item
                    label="Address 1"
                    name="corrAddress1"
                    rules={[
                        {
                            required: true,
                            message: 'Please Enter Address1',
                        },
                    ]}
                >
                    <Input />
                </Form.Item>
                <Form.Item label="Address 2" name="corrAddress2">
                    <Input />
                </Form.Item>
                <Form.Item
                    label="Town"
                    name="corrTown"
                    rules={[
                        {
                            required: true,
                            message: 'Please Enter Town',
                        },
                    ]}
                >
                    <Input />
                </Form.Item>
                <Form.Item label="Country" name="corrCountry">
                    <Select
                        allowClear
                        showSearch
                        placeholder="Please select"
                        onChange={() => {}}
                        optionFilterProp="children"
                        options={countryList?.map((data: { label: string }) => {
                            return {
                                id: data.label,
                                value: data.label,
                                label: data.label,
                            }
                        })}
                        filterOption={(input, option) =>
                            option?.label
                                ?.toLowerCase()
                                .includes(input.toLowerCase()) ?? false
                        }
                    />
                </Form.Item>
                <Form.Item
                    label="Post Code"
                    name="corrPostCode"
                    rules={[
                        {
                            required: true,
                            message: 'Please Enter Post Code',
                        },
                    ]}
                >
                    <Input />
                </Form.Item>
            </div>
        )
    }

    return (
        <>
            <div>
                <h5>Company Name & Reg Number N & S VANI LIMITED - 11869090</h5>
            </div>
            <Form
                name="complex-form"
                layout={'vertical'}
                autoComplete="off"
                form={form}
                requiredMark={customizeRequiredMark}
                onFinish={onSaveSoleTrader}
            >
                <Row gutter={16}>
                    <Col span={12}>
                        <Form.Item label="Member Type">
                            <Select
                                showSearch
                                allowClear
                                placeholder="Please select"
                                optionFilterProp="children"
                                filterOption={(input, option) =>
                                    typeof option?.children === 'string' && // Ensure option.children is a string
                                    (option.children as string)
                                        .toLowerCase()
                                        .includes(input.toLowerCase())
                                }
                            >
                                <Select.Option
                                    key="CORPORATE"
                                    value="CORPORATE"
                                >
                                    Corporate
                                </Select.Option>
                                <Select.Option key="PERSON" value="PERSON">
                                    Person
                                </Select.Option>
                            </Select>
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item label="Appoint For" name="clientNumber">
                            <Select
                                showSearch
                                allowClear
                                placeholder="Please select"
                                optionFilterProp="children"
                                filterOption={(input, option) =>
                                    typeof option?.children === 'string' && // Ensure option.children is a string
                                    (option.children as string)
                                        .toLowerCase()
                                        .includes(input.toLowerCase())
                                }
                            >
                                <Select.Option key="Director" value="Director">
                                    Director
                                </Select.Option>
                                <Select.Option key="Secorety" value="Secorety">
                                    Secorety
                                </Select.Option>
                            </Select>
                        </Form.Item>
                    </Col>
                </Row>
                <div className={commonStyles.flexDirectionRow}>
                    <div className={soleTraderNewStyles.collapseFirst}>
                        <Collapse
                            activeKey={collapseOne}
                            onChange={collapseOneOnChange}
                            bordered={true}
                            items={[
                                {
                                    key: '1',
                                    label: 'Initial Details',
                                    children: initialDetails(),
                                },
                                {
                                    key: '2',
                                    label: 'Personal Details',
                                    children: personalDetails(),
                                },
                            ]}
                        />
                    </div>
                    <div className={soleTraderNewStyles.collapseSecond}>
                        <Collapse
                            activeKey={collapseTwo}
                            onChange={collapseTwoOnChange}
                            bordered={true}
                            items={[
                                {
                                    key: '1',
                                    label: 'Correspondance Address',
                                    children: correspondAddress(),
                                },
                                {
                                    key: '2',
                                    label: 'Home Address',
                                    children: homeAddress(),
                                },
                            ]}
                        />
                    </div>
                </div>
                &nbsp;
                <Row>
                    <Col span={24}>
                        <Collapse
                            items={[
                                {
                                    key: '1',
                                    label: 'Consent to Act',
                                    children: (
                                        <p>
                                            <Switch />{' '}
                                            <span style={{ marginLeft: 10 }}>
                                                The Company confirms that the
                                                person named has consented to
                                                act as a director / secretary
                                            </span>
                                        </p>
                                    ),
                                },
                            ]}
                        />
                    </Col>
                </Row>
                &nbsp;
                <Row>
                    <Col span={24}>
                        <Switch />{' '}
                        <span style={{ marginLeft: 10 }}>
                            Select this option, If you are need to apply this
                            changes to companies house.
                        </span>
                    </Col>
                </Row>
                <div style={{ marginTop: '20px' }}>
                    <Flex justify="flex-end" gap={5}>
                        <CusttomBackgroundButton
                            color={'#868e96'}
                            name={'Cancel'}
                            icon={<CloseSquareOutlined />}
                        />
                        <CusttomBackgroundButton
                            color={'#1dc9b7'}
                            name={'Submit Changes'}
                            icon={<CheckOutlined />}
                        />
                    </Flex>
                </div>
            </Form>
        </>
    )
}

const mapStateToProps = (state: any): SoleTraderNewStateInterface => {
    return {
        countryList: state.common.countryList,
    }
}

export default connect(mapStateToProps)(AddNewOfficerForm)
