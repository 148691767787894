import type { Dispatch } from '@reduxjs/toolkit'
import axios from '../Config/AxiosConfig'
import { ErrorMessage, SuccessMessage } from '../Utils/Notification'
import type { DepartmentFormDataInterface } from '../Types/Department'
import type {
    TableListInterface,
    ListFrontEndInterface,
    ListBackendInterface,
} from '../Types/CommonType'
import { setLoadingStatus, setSuccess } from '../Reducer/Common'
import {
    DesignationDropDownLists,
    UserInfoList,
    departmentDropDownLists,
} from '../Reducer/UserRightsReducer'
import { type ManageUserDetailsFormDataInterface } from '../Types/ManageUser'
import { categoryDropDownList } from '../Reducer/InitialReducer'

export const GetUsertList = (
    dispatch: Dispatch,
    data: TableListInterface
): void => {
    dispatch(setLoadingStatus({ status: true }))
    const { pageNo, pageSize, searchCriteriaList, sortOrderList } = data
    const bodyData = {
        searchCriteriaList,
        sortOrderList,
    }

    axios
        .post(
            `/api/UserManagement/GetUserInformationList/${pageNo}/${pageSize}`,
            bodyData
        )
        .then((response) => {
            if (response.status === 200) {
                const newData = response?.data?.data?.map(
                    (value: DepartmentFormDataInterface) => {
                        return {
                            ...value,
                            key: value.departmentId,
                        }
                    }
                )
                response.data.data = newData
                dispatch(UserInfoList({ ...response.data }))
            } else {
                ErrorMessage(response.data.operationMsg)
            }
            dispatch(setLoadingStatus({ status: false }))
        })
        .catch((error) => {
            ErrorMessage(error.response.data.operationMsg)
            console.error('Error fetching posts:', error)
            dispatch(setLoadingStatus({ status: false }))
        })
}

export const SaveUserInfo = async (
    dispatch: Dispatch,
    data: ManageUserDetailsFormDataInterface
): Promise<any> => {
    const dataSave = {
        ...data,
        userId: parseInt(data.userId, 10),
    }
    dispatch(setLoadingStatus({ status: true }))
    return await axios
        .post(`/api/UserManagement/SetUserInformation`, dataSave)
        .then((response) => {
            dispatch(setLoadingStatus({ status: false }))
            if (response.status === 200) {
                SuccessMessage(response.data.operationMsg)
                return true
            } else {
                ErrorMessage(response.data.operationMsg)
                return false
            }
        })
        .catch((error) => {
            ErrorMessage(error.response.data.operationMsg)
            console.error('Error fetching posts:', error)
            dispatch(setLoadingStatus({ status: false }))
            return false
        })
}

export const ActiveInactiveUser = (
    dispatch: Dispatch,
    id: string,
    status: string,
    getTableData: CallableFunction
): void => {
    dispatch(setLoadingStatus({ status: true }))
    axios
        .get(`/api/SetRecordActiveStatus/6/${id}/${status}`)
        .then((response) => {
            if (response.status === 200) {
                getTableData()
                SuccessMessage(response.data.operationMsg)
            } else {
                ErrorMessage(response.data.operationMsg)
            }
            dispatch(setLoadingStatus({ status: false }))
        })
        .catch((error) => {
            ErrorMessage(error.response.data.operationMsg)
            console.error('Error fetching get:', error)
            dispatch(setLoadingStatus({ status: false }))
        })
}

export const DeleteUser = (
    dispatch: Dispatch,
    id: string,
    getTableData: CallableFunction
): void => {
    dispatch(setLoadingStatus({ status: true }))
    axios
        .get(`/api/SetDeleteRecord/8/${id}`)
        .then((response) => {
            if (response.status === 200) {
                getTableData()
                SuccessMessage(response.data.operationMsg)
            } else {
                ErrorMessage(response.data.operationMsg)
            }
            dispatch(setLoadingStatus({ status: false }))
        })
        .catch((error) => {
            ErrorMessage(error.response.data.operationMsg)
            console.error('Error fetching get:', error)
            dispatch(setLoadingStatus({ status: false }))
        })
}

export const GetDepartmentDropDownList = (
    dispatch: Dispatch,
    clientCategoryId: number
): void => {
    dispatch(setLoadingStatus({ status: true }))
    axios
        .get(`/api/GetStandardListingWithOneParameter/26/${clientCategoryId}`)
        .then((response) => {
            if (response.status === 200) {
                const newDepartmentList: ListFrontEndInterface[] =
                    response?.data?.data.map((data: ListBackendInterface) => {
                        return {
                            value: data.stdId,
                            label: data.stdName,
                        }
                    })
                dispatch(departmentDropDownLists(newDepartmentList))
            } else {
                ErrorMessage(response.data.operationMsg)
            }
            dispatch(setLoadingStatus({ status: false }))
        })
        .catch((error) => {
            ErrorMessage(error.response.data.operationMsg)
            console.error('Error fetching posts:', error)
            dispatch(setLoadingStatus({ status: false }))
        })
}

export const GetDesignationDropDownList = (
    dispatch: Dispatch,
    clientCategoryId: number
): void => {
    dispatch(setLoadingStatus({ status: true }))
    axios
        .get(`/api/GetStandardListingWithOneParameter/27/${clientCategoryId}`)
        .then((response) => {
            if (response.status === 200) {
                const newDepartmentList: ListFrontEndInterface[] =
                    response?.data?.data.map((data: ListBackendInterface) => {
                        return {
                            value: data.stdId,
                            label: data.stdName,
                        }
                    })
                dispatch(DesignationDropDownLists(newDepartmentList))
            } else {
                ErrorMessage(response.data.operationMsg)
            }
            dispatch(setLoadingStatus({ status: false }))
        })
        .catch((error) => {
            ErrorMessage(error.response.data.operationMsg)
            console.error('Error fetching posts:', error)
            dispatch(setLoadingStatus({ status: false }))
        })
}

export const GetCategoryDropDownListMU = (dispatch: Dispatch): void => {
    dispatch(setLoadingStatus({ status: true }))
    axios
        .get('/api/GetStandardListing/1')
        .then((response) => {
            if (response.status === 200) {
                const newCategoryList: ListFrontEndInterface[] =
                    response?.data?.data.map((data: ListBackendInterface) => {
                        return {
                            value: data.stdId,
                            label: data.stdName,
                        }
                    })
                dispatch(categoryDropDownList(newCategoryList))
                dispatch(setSuccess({ status: true }))
            } else {
                ErrorMessage(response.data.operationMsg)
            }
            dispatch(setLoadingStatus({ status: false }))
        })
        .catch((error) => {
            ErrorMessage(error.response.data.operationMsg)
            console.error('Error fetching posts:', error)
            dispatch(setLoadingStatus({ status: false }))
        })
}
