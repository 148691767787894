import axios from '../Config/AxiosConfig'
import { setLoadingStatus } from '../Reducer/Common'
import { type TableListInterface } from '../Types/CommonType'
import { ErrorMessage, SuccessMessage } from '../Utils/Notification'
import { type Dispatch } from '@reduxjs/toolkit'
import {
    type TimeSheetFormDataInterface,
    type TimesheetListDataTypeInterface,
} from '../Types/Timesheet'
import { TimeSheetList } from '../Reducer/Hr'

export const GetTimesheetList = (
    dispatch: Dispatch,
    data: TableListInterface
): void => {
    dispatch(setLoadingStatus({ status: true }))
    const { pageNo, pageSize, searchCriteriaList, sortOrderList } = data
    const bodyData = {
        searchCriteriaList,
        sortOrderList,
    }

    axios
        .post(
            `/api/Timesheet/GetTimesheetListbyUserId/${pageNo}/${pageSize}`,
            bodyData
        )
        .then((response) => {
            if (response.status === 200) {
                const newData = response?.data?.data?.map(
                    (value: TimesheetListDataTypeInterface) => {
                        return {
                            ...value,
                            key: value.timeSheetId,
                        }
                    }
                )
                response.data.data = newData
                dispatch(TimeSheetList({ ...response.data }))
            } else {
                ErrorMessage(response.data.operationMsg)
            }
            dispatch(setLoadingStatus({ status: false }))
        })
        .catch((error) => {
            ErrorMessage(error.response.data.operationMsg)
            // console.error('Error fetching posts:', error)
            dispatch(setLoadingStatus({ status: false }))
        })
}

export const GetTimesheetListExpand = (
    dispatch: Dispatch,
    data: TableListInterface
): void => {
    dispatch(setLoadingStatus({ status: true }))
    const { pageNo, pageSize, searchCriteriaList, sortOrderList } = data
    const bodyData = {
        searchCriteriaList,
        sortOrderList,
    }

    axios
        .post(`/api/Timesheet/GetTimesheetList/${pageNo}/${pageSize}`, bodyData)
        .then((response) => {
            if (response.status === 200) {
                const newData = response?.data?.data?.map(
                    (value: TimesheetListDataTypeInterface) => {
                        return {
                            ...value,
                            key: value.timeSheetId,
                        }
                    }
                )
                dispatch(TimeSheetList({ ...response.data }))
                return newData
            } else {
                ErrorMessage(response.data.operationMsg)
                dispatch(setLoadingStatus({ status: false }))
                return []
            }
        })
        .catch((error) => {
            ErrorMessage(error.response.data.operationMsg)
            console.error('Error fetching posts:', error)
            dispatch(setLoadingStatus({ status: false }))
            return []
        })
}

export const SaveTimesheet = async (
    dispatch: Dispatch,
    data: TimeSheetFormDataInterface
): Promise<any> => {
    dispatch(setLoadingStatus({ status: true }))
    return await axios
        .post(`/api/Timesheet/SetTimesheet`, data)
        .then((response) => {
            dispatch(setLoadingStatus({ status: false }))
            if (response.status === 200) {
                SuccessMessage(response.data.operationMsg)
                return true
            } else {
                ErrorMessage(response.data.operationMsg)
                return false
            }
        })
        .catch((error) => {
            dispatch(setLoadingStatus({ status: false }))
            ErrorMessage(error.response.data.operationMsg)
            console.error('Error fetching posts:', error)
            return false
        })
}
const _appSettings = {
    TimesheetwithWorkflow: 0, // Set the value according to your actual settings
}
export const ActiveInactiveTimesheet = (
    dispatch: Dispatch,
    id: string,
    status: string,
    description: string,
    getTableData: CallableFunction
): void => {
    dispatch(setLoadingStatus({ status: true }))

    // Determine the optionId based on _appSettings.TimesheetwithWorkflow
    const optionId = _appSettings.TimesheetwithWorkflow === 1 ? 3 : 4

    axios
        .get(
            `/api/Employee/SetApprovalStatusRecord/${optionId}/${id}/${status}/${description}`
        )
        .then((response) => {
            if (response.status === 200) {
                getTableData()
                SuccessMessage(response.data.operationMsg)
            } else {
                ErrorMessage(response.data.operationMsg)
            }
            dispatch(setLoadingStatus({ status: false }))
        })
        .catch((error) => {
            ErrorMessage(error.response.data.operationMsg)
            console.error('Error fetching get:', error)
            dispatch(setLoadingStatus({ status: false }))
        })
}

export const GetTimeSheetById = async (
    dispatch: Dispatch,
    TimesheetId: string
): Promise<any> => {
    dispatch(setLoadingStatus({ status: true }))

    try {
        const response = await axios.get(
            `/api/Timesheet/GetTimesheetDetail/${TimesheetId}`
        )

        if (response.status === 200) {
            return response?.data?.data
        } else {
            ErrorMessage('Failed to fetch data')
        }
    } catch (error) {
        console.error('Error fetching client company details:', error)
        ErrorMessage('An error occurred while fetching data')
    } finally {
        dispatch(setLoadingStatus({ status: false }))
    }
}
