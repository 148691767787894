import { setLoadingStatus } from '../../Reducer/Common'
import { ErrorMessage } from '../../Utils/Notification'
import { type Dispatch } from '@reduxjs/toolkit'
import axios from '../../Config/AxiosConfig'
import {
    type ListBackendInterface,
    type ListFrontEndInterface,
} from '../../Types/CommonType'
import { businessstypedropdownlist } from '../../Reducer/InitialReducer'
import { type propertyFormDataInterface } from '../../Types/MiisngInformation/PropertyView'
import {
    MissinginformationprpertyviewList,
    missingdroplist,
} from '../../Reducer/MiisingInformation'

export const businesstypecatergoryList = (dispatch: Dispatch): void => {
    dispatch(setLoadingStatus({ status: true }))
    axios
        .get('/api/GetStandardListing/68')
        .then((response) => {
            if (response.status === 200) {
                const newPriority1: ListFrontEndInterface[] =
                    response.data.data.map((data: ListBackendInterface) => ({
                        value: data.stdId,
                        label: data.stdName,
                    }))

                dispatch(businessstypedropdownlist(newPriority1))
            } else {
                ErrorMessage(response.data.operationMsg)
            }
            dispatch(setLoadingStatus({ status: false }))
        })
        .catch((error) => {
            ErrorMessage(error.response?.data?.operationMsg)
            console.error('Error fetching posts:', error)
            dispatch(setLoadingStatus({ status: false }))
        })
}

export const missinglist = (dispatch: Dispatch): void => {
    dispatch(setLoadingStatus({ status: true }))
    axios
        .get(`/api/GetStandardListing/29`)
        .then((response) => {
            if (response.status === 200) {
                const uList: ListFrontEndInterface[] = response?.data?.data.map(
                    (data: ListBackendInterface) => {
                        return {
                            value: data.stdId,
                            label: data.stdName,
                        }
                    }
                )
                dispatch(missingdroplist(uList))
            } else {
                ErrorMessage(response.data.operationMsg)
            }
            dispatch(setLoadingStatus({ status: false }))
        })
        .catch((error) => {
            ErrorMessage(error.response.data.operationMsg)
            console.error('Error fetching get:', error)
            dispatch(setLoadingStatus({ status: false }))
        })
}
export const GetMissedField = (
    dispatch: Dispatch,
    searchContent: string,
    MissedFieldId: number,
    clientCategoryId: number,
    businessTypeId: number,
    pageNo: number,
    pageSize: number
): void => {
    dispatch(setLoadingStatus({ status: true }))
    if (searchContent?.length === 0 || searchContent.trim() === '') {
        searchContent = '=NA='
    }
    axios
        .post(
            `/api/MissedInfo/GetMissedField/${searchContent}/${MissedFieldId}/${pageNo}/${pageSize}/${clientCategoryId}/${businessTypeId}`
        )
        .then((response) => {
            if (response.status === 200) {
                const newData = response?.data?.data?.map(
                    (value: propertyFormDataInterface) => {
                        return {
                            ...value,
                            key: value.referenceId,
                        }
                    }
                )
                response.data.data = newData
                dispatch(
                    MissinginformationprpertyviewList({ ...response.data })
                )
            } else {
                ErrorMessage(response.data.operationMsg)
            }
            dispatch(setLoadingStatus({ status: false }))
        })
        .catch((error) => {
            ErrorMessage(error.response.data.operationMsg)
            console.error('Error fetching posts:', error)
            dispatch(setLoadingStatus({ status: false }))
        })
}
