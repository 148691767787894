import React, { useEffect, useRef } from 'react'
import ReactQuill from 'react-quill'
import 'react-quill/dist/quill.snow.css'
import { Tooltip } from 'react-tooltip'
import 'react-tooltip/dist/react-tooltip.css'
interface CustomQuillProps {
    onDescriptionChange: (value: string) => void
    emailText: string
}
/*
Params 1: onDescriptionChange: CallBack Function for the Parent Component
Params 2: emailText: string value for the Quill Editor
Component Description: CustomQuill, adds tooltips feature to the Quill Editor toolbar buttons by setting custom attribures to the buttons. Which is then used by the React Tooltip component to show the tooltip on hover.
Return: React.ReactElement
*/
const CustomQuill: React.FC<CustomQuillProps> = (props): React.ReactElement => {
    const { onDescriptionChange, emailText } = props
    const toolbarRef = useRef<HTMLDivElement>(null)
    const onChange = (value: string): void => {
        // CallBack Function for the Parent Component (wikiArticalForm.tsx, wikiArticalGroupForm.tsx)
        onDescriptionChange(value)
    }
    useEffect(() => {
        const toolbarButtons =
            toolbarRef.current?.querySelectorAll<HTMLElement>(
                '.ql-toolbar button, .ql-toolbar .ql-picker'
            )
        toolbarButtons?.forEach((element) => {
            if (element instanceof HTMLButtonElement) {
                const className = element.className
                if (className.includes('ql-bold')) {
                    element.setAttribute('data-tooltip-id', `tooltip-bold`)
                    element.setAttribute(
                        'data-tooltip-content',
                        'Bold (Ctrl+B)'
                    )
                } else if (className.includes('ql-italic')) {
                    element.setAttribute('data-tooltip-id', `tooltip-italic`)
                    element.setAttribute(
                        'data-tooltip-content',
                        'Italic (Ctrl+I)'
                    )
                } else if (className.includes('ql-underline')) {
                    element.setAttribute('data-tooltip-id', `tooltip-underline`)
                    element.setAttribute(
                        'data-tooltip-content',
                        'Underline (Ctrl+U)'
                    )
                } else if (className.includes('ql-link')) {
                    element.setAttribute('data-tooltip-id', `tooltip-link`)
                    element.setAttribute(
                        'data-tooltip-content',
                        'Insert Link (Ctrl+K)'
                    )
                } else if (
                    className.includes('ql-list') &&
                    element.value === 'ordered'
                ) {
                    element.setAttribute(
                        'data-tooltip-id',
                        `tooltip-ordered-list`
                    )
                    element.setAttribute(
                        'data-tooltip-content',
                        'Ordered List (Ctrl+Shift+7)'
                    )
                } else if (
                    className.includes('ql-list') &&
                    element.value === 'bullet'
                ) {
                    element.setAttribute(
                        'data-tooltip-id',
                        `tooltip-bullet-list`
                    )
                    element.setAttribute(
                        'data-tooltip-content',
                        'Bullet List (Ctrl+Shift+8)'
                    )
                } else if (className.includes('ql-clean')) {
                    element.setAttribute('data-tooltip-id', `tooltip-clean`)
                    element.setAttribute(
                        'data-tooltip-content',
                        'Clear Formatting (Ctrl+\\)'
                    )
                }
            } else if (element.classList.contains('ql-header')) {
                const pickerLabel = element.querySelector('.ql-picker-label')
                if (pickerLabel !== null) {
                    pickerLabel.setAttribute(
                        'data-tooltip-id',
                        `tooltip-header`
                    )
                    pickerLabel.setAttribute(
                        'data-tooltip-content',
                        'Heading (Ctrl+Alt+H)'
                    )
                }
            }
        })
    }, [])

    return (
        <div ref={toolbarRef}>
            <ReactQuill
                theme="snow"
                value={emailText}
                onChange={(value: string) => {
                    onChange(value)
                }}
            />
            <Tooltip id="tooltip-bold" />
            <Tooltip id="tooltip-italic" />
            <Tooltip id="tooltip-underline" />
            <Tooltip id="tooltip-link" />
            <Tooltip id="tooltip-ordered-list" />
            <Tooltip id="tooltip-bullet-list" />
            <Tooltip id="tooltip-clean" />
            <Tooltip id="tooltip-header" />
        </div>
        // Format to add custom Styles <Tooltip id="tooltip-bold" className={styles['react-tooltip']}/>
    )
}

export default CustomQuill
