import React, { useEffect, useState } from 'react'
import {
    Col,
    Row,
    Input,
    Form,
    Button,
    Space,
    DatePicker,
    type DatePickerProps,
    Tooltip,
} from 'antd'
import commonStyles from '../../../Utils/Common.less'
import { SaveOutlined } from '@ant-design/icons'

import { useDispatch } from 'react-redux'
import moment from 'moment'
import dayjs from 'dayjs'
import { type ContactInfo } from '../../../Types/Invoice/Invoicing'
import { GetInvoiceDetails } from '../../../Services/Invoice'
import { type PhoneCallProps } from '../../../Types/ToDoViewAllList'
import { SaveCommuniCationNormal } from '../../../Services/ToDoViewAllList'

const ToDoPhoneCallModel = (props: PhoneCallProps): React.ReactElement => {
    const { serviceProcessId, changePhoneCallModalStatus } = props
    const dispatch = useDispatch()
    const [form] = Form.useForm()
    const [UnpaidPhoneCallDetails, setUnpaidPhoneCallDetails] =
        useState<ContactInfo | null>(null)
    const [selectedDate, setSelectedDate] = useState<string>('')

    useEffect(() => {
        const fetchData = (): void => {
            void GetInvoiceDetails(
                dispatch,
                serviceProcessId,
                (details: ContactInfo) => {
                    setUnpaidPhoneCallDetails(details)
                }
            )
        }
        fetchData()
    }, [serviceProcessId])

    useEffect(() => {
        if (
            UnpaidPhoneCallDetails !== null &&
            typeof UnpaidPhoneCallDetails === 'object'
        ) {
            const newFieldValues = {
                contactName: UnpaidPhoneCallDetails?.contactName,
                contactNumber: UnpaidPhoneCallDetails?.contactNumber,
            }

            form.setFieldsValue(newFieldValues)
        }
    }, [UnpaidPhoneCallDetails, form])

    useEffect(() => {
        form.setFieldValue('historyDate', selectedDate)
    }, [serviceProcessId, selectedDate])

    const onChange = (
        dateOfBirth: DatePickerProps['value'],
        dateString: string | string[]
    ): void => {
        if (dateOfBirth != null) {
            const isoDate = dateOfBirth.toISOString()
            setSelectedDate(isoDate)
        }
    }

    const onSave = (): void => {
        void onSavePhone()
    }
    const onSavePhone = async (): Promise<void> => {
        try {
            await form.validateFields()

            const formData = form.getFieldsValue()

            const dataToSave = {
                communicationHistoryId: 0,
                communicationTypeId: 2,
                serviceProcessId,
                description: formData.description,
                historyDate: moment(formData.historyDate).format(
                    'DD/MM/YYYY h:mm:ss A'
                ),
                contactNumber: formData.contactNumber,
            }

            await SaveCommuniCationNormal(dispatch, dataToSave)

            form.setFieldsValue({
                description: '',
                historyDate: '',
            })

            changePhoneCallModalStatus()
            setSelectedDate('')
        } catch (error) {}
    }

    const customizeRequiredMark = (
        label: React.ReactNode,
        { required }: { required: boolean }
    ): React.ReactElement => (
        <>
            {label}
            &nbsp;
            {required && <span className={commonStyles.requireIcon}>*</span>}
        </>
    )
    return (
        <div className={commonStyles.formWrapper}>
            <Form
                name="complex-form"
                labelCol={{ span: 24 }}
                wrapperCol={{ span: 24 }}
                initialValues={{ remember: true }}
                layout={'vertical'}
                autoComplete="off"
                form={form}
                size="small"
                requiredMark={customizeRequiredMark}
            >
                <Form.Item
                    name="prospectClientId"
                    hidden={true}
                    initialValue={serviceProcessId}
                >
                    <Input />
                </Form.Item>
                <Row gutter={16}>
                    <Col span={24}>
                        <Form.Item
                            label="Name"
                            name="contactName"
                            rules={[
                                {
                                    required: true,
                                    message: 'Please input your firstName!',
                                },
                            ]}
                        >
                            <Input disabled />
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={16}>
                    <Col span={24}>
                        <Form.Item
                            label="Phone Number"
                            name="contactNumber"
                            rules={[
                                {
                                    pattern: /^\d{10,15}$/,
                                    message:
                                        'Please enter a valid mobile number (10 to 15 digits).',
                                },
                                {
                                    required: true,
                                    message: 'Please input your mobile number!',
                                },
                            ]}
                        >
                            <Input />
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={16}>
                    <Col span={24}>
                        <Form.Item
                            label="Call Date"
                            name="historyDate"
                            rules={[
                                {
                                    required: true,
                                    message: 'Please input your Call Date!',
                                },
                            ]}
                        >
                            <Space direction="vertical" size={12}>
                                <DatePicker
                                    onChange={(data, dataString) => {
                                        onChange(data, dataString)
                                    }}
                                    value={
                                        selectedDate.length > 0
                                            ? dayjs(selectedDate)
                                            : null
                                    }
                                    format="DD/MM/YYYY h:mm:ss A"
                                    showTime
                                    className={commonStyles.dateWidth}
                                />
                            </Space>
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={16}>
                    <Col span={24}>
                        <Form.Item
                            label="Comments"
                            name="description"
                            rules={[
                                {
                                    required: true,
                                    message: 'Please input your Comments',
                                },
                            ]}
                        >
                            <Input.TextArea rows={4} />
                        </Form.Item>
                    </Col>
                </Row>

                <Row gutter={16}>
                    <Col offset={21} span={2}>
                        <Tooltip title="Save Phone Call">
                            <Button
                                type="primary"
                                onClick={onSave}
                                size="middle"
                            >
                                <SaveOutlined />
                            </Button>
                        </Tooltip>
                    </Col>
                </Row>
            </Form>
        </div>
    )
}

export default ToDoPhoneCallModel
