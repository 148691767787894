import React, { useEffect, useState } from 'react'
import {
    Col,
    Row,
    Input,
    Form,
    Button,
    DatePicker,
    Select,
    Tooltip,
} from 'antd'
import { connect, useDispatch } from 'react-redux'
import commonStyles from '../../../Utils/Common.less'
import { SaveOutlined } from '@ant-design/icons'
import { GetWorkFlow, GetLeaveMode } from '../../../Services/HR/LeavePermission'
import { GetUserList } from '../../../Services/User'
import {
    type FormStaeInterface,
    type FormComponentProps,
    type LeaveListType,
} from '../../../Types/Hr/LeavePermission'
import dayjs from 'dayjs'
import 'dayjs/locale/en'
import ActivePremission from '../../../Utils/premissins'
import { PermissionType } from '../../../Types/Enum/PermissionType'

dayjs.locale('en')
const { TextArea } = Input

const FormComponent = (props: FormComponentProps): React.ReactElement => {
    const {
        LeaveType,
        workflowDropDownList,
        LeaveModeDropDownList,
        userList,
        LeaveEditRecord,
        onFinish,
    } = props
    const dispatch = useDispatch()
    const [form] = Form.useForm()
    const [formHeight, setFormHeight] = useState('auto')
    const [saveEnable, setSaveEnable] = useState(false)
    const LeaveTypeId = LeaveEditRecord.leaveId

    useEffect(() => {
        if (LeaveEditRecord.leaveId > 0) {
            setSaveEnable(true)
        }
    }, [LeaveEditRecord])

    useEffect(() => {
        GetWorkFlow(dispatch)
        GetLeaveMode(dispatch)
        GetUserList(dispatch)
    }, [dispatch, LeaveTypeId])

    const handleFinish = async (): Promise<void> => {
        try {
            await form.validateFields()
            const formdata = await form.getFieldsValue()
            const formatleaveFrom = dayjs(
                formdata.leaveFrom,
                'YYYY-MM-DDTHH:mm:ss'
            ).format('DD/MM/YYYY')
            const formatleaveTo = dayjs(
                formdata.leaveTo,
                'YYYY-MM-DDTHH:mm:ss'
            ).format('DD/MM/YYYY')

            const leaveApprovalDescription = ''
            const leaveApprovalStatus = 'Saved'
            const formDataWithLeaveType = {
                ...formdata,
                LeaveType,
                leaveApprovalDescription,
                leaveApprovalStatus,
            }
            form.setFieldsValue(formDataWithLeaveType)
            setSaveEnable(true)
            const api = {
                ...formDataWithLeaveType,
                leaveFrom: formatleaveFrom,
                leaveTo: formatleaveTo,
            }
            onFinish(api, saveEnable)
            form.resetFields()
        } catch (err) {
            console.error('Validation errors:', err)
        }
    }

    useEffect(() => {
        if (LeaveTypeId > 0) {
            const parsedLeaveFrom = dayjs(
                LeaveEditRecord.leaveFrom,
                'YYYY/MM/DD'
            )
            const parsedLeaveTo = dayjs(LeaveEditRecord.leaveTo, 'YYYY/MM/DD')
            form.setFieldsValue({
                leaveId: LeaveEditRecord.leaveId,
                leaveFrom: parsedLeaveFrom,
                leaveTo: parsedLeaveTo,
                leaveMode: LeaveEditRecord.leaveMode,
                description: LeaveEditRecord.description,
                coveringPersonId:
                    LeaveEditRecord.coveringPersonId !== 0
                        ? LeaveEditRecord.coveringPersonId
                        : null,
                workflowId:
                    LeaveEditRecord.workflowId !== 0
                        ? LeaveEditRecord.workflowId
                        : null,
            })
        }
    }, [LeaveTypeId, LeaveEditRecord, form])

    const customizeRequiredMark = (
        label: React.ReactNode,
        { required }: { required: boolean }
    ): React.ReactElement => (
        <>
            {label}
            &nbsp;
            {required && <span className={commonStyles.requireIcon}>*</span>}
        </>
    )
    return (
        <div
            className={commonStyles.formWrapper}
            style={{ minHeight: formHeight }}
        >
            <Form
                name="complex-form"
                initialValues={{ remember: true }}
                labelCol={{ span: 24 }}
                wrapperCol={{ span: 24 }}
                autoComplete="off"
                form={form}
                onFieldsChange={() => {
                    setFormHeight('auto')
                }}
                onValuesChange={() => {
                    setTimeout(() => {
                        setFormHeight(`${form.getFieldsValue().length * 50}px`)
                    }, 0)
                }}
                requiredMark={customizeRequiredMark}
            >
                <Row gutter={16}>
                    <Form.Item<LeaveListType>
                        label="leaveId"
                        name="leaveId"
                        hidden={true}
                    />

                    <Col span={12}>
                        <Form.Item<LeaveListType>
                            label="From Date"
                            name="leaveFrom"
                            rules={[
                                {
                                    required: true,
                                    message: 'Please select the From Date!',
                                },
                            ]}
                        >
                            <DatePicker
                                format="DD/MM/YYYY"
                                style={{ width: '100%' }}
                                defaultValue={
                                    LeaveEditRecord.leaveFrom !== undefined
                                        ? dayjs(
                                              LeaveEditRecord.leaveFrom,
                                              'DD/MM/YYYY'
                                          )
                                        : null
                                }
                            />
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item<LeaveListType>
                            label="To Date"
                            name="leaveTo"
                            rules={[
                                {
                                    required: true,
                                    message: 'Please select the ToDate!',
                                },
                            ]}
                        >
                            <DatePicker
                                format="DD/MM/YYYY"
                                style={{ width: '100%' }}
                            />
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={16}>
                    <Col span={12}>
                        <Form.Item<LeaveListType>
                            label="Leave Mode"
                            name="leaveMode"
                            rules={[
                                {
                                    required: true,
                                    message: 'Please select the Leave Mode!',
                                },
                            ]}
                        >
                            <Select
                                showSearch
                                allowClear
                                placeholder="Please select"
                                onChange={() => {}}
                                filterOption={(input, option) =>
                                    (option?.label as string)
                                        .toLowerCase()
                                        .includes(input.toLowerCase())
                                }
                                options={[
                                    { label: 'Please select', value: null },
                                    ...LeaveModeDropDownList,
                                ]}
                            />
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item<LeaveListType>
                            label="Purpose"
                            name="description"
                            rules={[
                                {
                                    required: true,
                                    message: 'Please select the Purpose!',
                                },
                            ]}
                        >
                            <TextArea rows={3} />
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={16}>
                    <Col span={12}>
                        <Form.Item<LeaveListType>
                            label="Covering Person"
                            name="coveringPersonId"
                        >
                            <Select
                                showSearch
                                allowClear
                                placeholder="Please select"
                                onChange={() => {}}
                                filterOption={(input, option) =>
                                    (option?.label as string)
                                        .toLowerCase()
                                        .includes(input.toLowerCase())
                                }
                                options={[
                                    { label: 'Please select', value: null },
                                    ...userList,
                                ]}
                            />
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item<LeaveListType>
                            label="Work Flow"
                            name="workflowId"
                            rules={[
                                {
                                    required: true,
                                    message: 'Please select the Leave Mode!',
                                },
                            ]}
                        >
                            <Select
                                showSearch
                                allowClear
                                placeholder="Please select"
                                onChange={() => {}}
                                filterOption={(input, option) =>
                                    (option?.label as string)
                                        .toLowerCase()
                                        .includes(input.toLowerCase())
                                }
                                options={[
                                    { label: 'Please select', value: null },
                                    ...workflowDropDownList,
                                ]}
                            />
                        </Form.Item>
                    </Col>
                </Row>
                {ActivePremission('8000', PermissionType.SAVE) && (
                    <Row gutter={16}>
                        <Col offset={22} span={2}>
                            <Button
                                type="primary"
                                onClick={() => {
                                    handleFinish()
                                        .then(() => {})
                                        .catch(() => {})
                                }}
                            >
                                <Tooltip title="Save Leave">
                                    <SaveOutlined />
                                </Tooltip>
                            </Button>
                        </Col>
                    </Row>
                )}
            </Form>
        </div>
    )
}

const mapStateToProps = (state: any): FormStaeInterface => {
    return {
        workflowDropDownList: state.hr.workflowDropDownList,
        LeaveModeDropDownList: state.hr.LeaveModeDropDownList,
        userList: state.user.userList,
    }
}

export default connect(mapStateToProps)(FormComponent)
