import { Button, Col, Form, Row, Select } from 'antd'
import React, { useState } from 'react'
import commontStyles from './document.less'
import { SaveOutlined } from '@ant-design/icons'
import { SaveOfficerDocument } from '../../../../../../Services/SoleTrader'
import { connect, useDispatch } from 'react-redux'
import S3UploadMultiple from '../../../../../../Components/S3UploadMultiple'
import { type ListFrontEndInterface } from '../../../../../../Types/CommonType'
import { type CorporateDocumentView } from '../../../../../../Types/Client/RegisteredClients/Corporate'

const ServiceDocumentUpload = (props: {
    soleTraderId: number
    companyAttachmentId: number | undefined
    serviceName: string | undefined
    documentBaseId: number | undefined
    documentType: string | undefined
    fetchData: () => void
    pensionData?: any
    documenttypeList: ListFrontEndInterface[]
}): React.ReactElement => {
    const {
        soleTraderId,
        companyAttachmentId,
        serviceName,
        documentBaseId,
        documentType,
        fetchData,
        documenttypeList,
        pensionData,
    } = props
    const dispatch = useDispatch()
    const [form] = Form.useForm()
    const [uploadFileList, setUploadFileList] = useState<string[]>([])
    const [selectedDocumentType, setSelectedDocumentType] = useState(11)

    const handleSetFormData = (name: string, locations: string[]): void => {
        setUploadFileList(locations)
    }

    const handeleCompanDocumentSave = (): void => {
        const formdata = form.getFieldsValue()
        const documentBaseIds =
            documentBaseId === 0 ? selectedDocumentType : documentBaseId

        const convertedString = uploadFileList
            .map((item) => item.slice())
            .join(', ')

        if (documentType === '') {
            const datasss = pensionData?.filter(
                (d: any) => d.documentBaseId === documentBaseIds
            )
            const savedata = {
                companyAttachmentId: datasss.companyAttachmentId ?? 0,
                companyTypeId: 1,
                referenceId: soleTraderId,
                referenceId2: 0,
                documentBaseId: documentBaseIds ?? 0,
                documentBaseName: serviceName ?? '',
                attachmentPath:
                    serviceName === 'Pension'
                        ? convertedString
                        : formdata.attachmentPath ?? '',
                soleTraderId: 0,
                type: datasss.documentType ?? '',
                ppExpiryDate: '',
                dlExpiryDate: '',
                prExpiryDate: '',
            }
            void SaveOfficerDocument(dispatch, savedata)
            fetchData()
        } else {
            const savedata = {
                companyAttachmentId: companyAttachmentId ?? 0,
                companyTypeId: 2,
                referenceId: soleTraderId,
                referenceId2: 0,
                documentBaseId: documentBaseId ?? 0,
                documentBaseName: serviceName ?? '',
                attachmentPath: convertedString ?? '',
                soleTraderId: 0,
                type: documentType ?? '',
                ppExpiryDate: '',
                dlExpiryDate: '',
                prExpiryDate: '',
            }
            void SaveOfficerDocument(dispatch, savedata)
            fetchData()
        }
        form.resetFields(['attachmentPath'])
    }

    const handleDocumentTypeChange = (value: number): void => {
        setSelectedDocumentType(value)
        form.resetFields(['attachmentPath'])
    }
    return (
        <div className={commontStyles.formWrapper}>
            <Form
                name="complex-form"
                labelCol={{ span: 24 }}
                wrapperCol={{ span: 24 }}
                initialValues={{
                    remember: true,
                }}
                layout={'vertical'}
                autoComplete="off"
                form={form}
            >
                {serviceName === 'Pension' ? (
                    <>
                        <Row gutter={16}>
                            <Col span={24}>
                                <Form.Item
                                    label="Document Type"
                                    name="title"
                                    className={
                                        commontStyles.CorporteDocumentGeenreal
                                    }
                                >
                                    <Select
                                        allowClear
                                        placeholder="Please select"
                                        onChange={handleDocumentTypeChange}
                                        value={selectedDocumentType}
                                        showSearch
                                        optionFilterProp="children"
                                        filterOption={(input, option) =>
                                            option?.label
                                                ?.toLowerCase()
                                                .includes(
                                                    input.toLowerCase()
                                                ) ?? false
                                        }
                                        options={documenttypeList}
                                    />
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row gutter={16}>
                            <Col span={24}>
                                <Form.Item name="attachmentPath">
                                    <S3UploadMultiple
                                        name={'attachmentPath'}
                                        value={uploadFileList}
                                        setFormData={handleSetFormData}
                                        corporate={'Corporate'}
                                        corporateid={soleTraderId}
                                        documenttype={serviceName ?? ''}
                                        documentbaseId={documentBaseId ?? 0}
                                        memberpersonId={0}
                                    />
                                </Form.Item>
                            </Col>
                        </Row>
                    </>
                ) : (
                    <Row gutter={16}>
                        <Col span={24}>
                            <S3UploadMultiple
                                name={'attachmentPath'}
                                value={uploadFileList}
                                setFormData={handleSetFormData}
                                corporate={'Corporate'}
                                corporateid={soleTraderId}
                                documenttype={serviceName ?? ''}
                                documentbaseId={documentBaseId ?? 0}
                                memberpersonId={0}
                            />
                        </Col>
                    </Row>
                )}

                <Row gutter={16}>
                    <Col offset={20} span={2}>
                        <Button
                            type="primary"
                            onClick={handeleCompanDocumentSave}
                            className={`${
                                serviceName !== 'Pension'
                                    ? commontStyles.savebuttonless
                                    : ''
                            }`}
                        >
                            <SaveOutlined />
                        </Button>
                    </Col>
                </Row>
            </Form>
        </div>
    )
}

const mapStateToProps = (state: any): CorporateDocumentView => {
    return {
        corporateTabs: state.client.corporateTaps,
        documenttypeList: state.common.documenttypeList,
    }
}

export default connect(mapStateToProps)(ServiceDocumentUpload)
