import React from 'react'
import type { CorporateServicesVatView } from '../../../../../../Types/Client/RegisteredClients/Corporate'
import { Col, Row } from 'antd'

const CtServiceViewPage = (
    props: CorporateServicesVatView
): React.ReactElement => {
    const { ctServiceData } = props

    return (
        <>
            <Row gutter={[24, 8]}>
                <Col span={4}>
                    {' '}
                    <p>Company UTR</p>
                </Col>
                <Col span={8}>
                    {' '}
                    <p>:{ctServiceData?.companyUTR}</p>
                </Col>
                <Col span={4}>
                    <p>Town</p>
                </Col>
                <Col span={8}>
                    {' '}
                    <p>:{ctServiceData?.town}</p>
                </Col>
            </Row>
            <Row gutter={[24, 8]}>
                <Col span={4}>
                    {' '}
                    <p>Address 1</p>
                </Col>
                <Col span={8}>
                    <p>:{ctServiceData?.addressLineOne}</p>
                </Col>
                <Col span={4}>
                    <p>Country</p>
                </Col>
                <Col span={8}>
                    <p>:{ctServiceData?.country}</p>
                </Col>
            </Row>
            <Row gutter={[24, 8]}>
                <Col span={4}>
                    <p>Address 2</p>
                </Col>
                <Col span={8}>
                    <p>:{ctServiceData?.addressLineTwo}</p>
                </Col>
                <Col span={4}>
                    <p>Post Code</p>
                </Col>
                <Col span={8}>
                    {' '}
                    <p>:{ctServiceData?.postCode}</p>
                </Col>
            </Row>
        </>
    )
}

export default CtServiceViewPage
