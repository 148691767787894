import React, { useEffect, useState } from 'react'
import { Col, Row, Input, Form, Button, Select } from 'antd'
import commonStyles from '../../../Utils/Common.less'
import { SaveOutlined } from '@ant-design/icons'
import type { CorporateChatStateInterface } from '../../../Types/Client/RegisteredClients/Corporate'
import { useDispatch, connect } from 'react-redux'
import { GetUserList } from '../../../Services/User'
import { SaveStaffCommData } from '../../../Services/GlobelSearch'
import { type StaffCommunicationSet } from '../../../Types/GlobelSearchClient/GlobelSearchClient'

const StaffComm = (props: StaffCommunicationSet): React.ReactElement => {
    const { userList, businessTypeId, referenceId, closeModal } = props
    const dispatch = useDispatch()
    const [form] = Form.useForm()
    const [FilteredOptions, setFilteredOptions] = useState(userList)
    const [selectedUserIds, setSelectedUserIds] = useState<string[]>(
        form.getFieldValue('userIds') ?? []
    )

    useEffect(() => {
        GetUserList(dispatch)
    }, [dispatch, form])

    useEffect(() => {
        setFilteredOptions(userList)
    }, [userList])

    const handleSearch = (value: string): void => {
        const currentFilter = form.getFieldValue('userIds') ?? []
        const filteredOptions = userList.filter(
            (user: { label: string }) =>
                user.label.toLowerCase().includes(value.toLowerCase()) ||
                currentFilter.includes(user.label)
        )
        setFilteredOptions(filteredOptions)
    }
    const onSave = (): void => {
        void onSaveEmail()
    }
    const onSaveEmail = async (): Promise<void> => {
        try {
            await form.validateFields()

            const formData = form.getFieldsValue()

            const dataTosave = {
                chatId: 0,
                businessTypeId,
                referenceId,
                isClientCommunication: 0,
                description: formData.description,
                userIds: formData.userIds,
            }

            await SaveStaffCommData(dispatch, dataTosave)
            closeModal()
            form.resetFields()
        } catch (error) {
            console.error('Error:', error)
        }
    }
    const handleChange = (selectedUserIds: any): void => {
        setSelectedUserIds(selectedUserIds)
        form.setFieldsValue({ userIds: selectedUserIds })
    }

    const customizeRequiredMark = (
        label: React.ReactNode,
        { required }: { required: boolean }
    ): React.ReactElement => (
        <>
            {label}
            &nbsp;
            {required && <span className={commonStyles.requireIcon}>*</span>}
        </>
    )
    return (
        <div className={commonStyles.formWrapper}>
            <Form
                name="complex-form"
                labelCol={{ span: 24 }}
                wrapperCol={{ span: 24 }}
                initialValues={{ remember: true }}
                layout={'vertical'}
                autoComplete="off"
                form={form}
                size="small"
                requiredMark={customizeRequiredMark}
            >
                <Form.Item name="corporateid" hidden={true}>
                    <Input />
                </Form.Item>
                <Row gutter={16}>
                    <Col span={24}>
                        <Form.Item
                            label="Message"
                            name="description"
                            rules={[
                                {
                                    required: true,
                                    message: 'Please input your Message',
                                },
                            ]}
                        >
                            <Input.TextArea rows={4} />
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={16}>
                    <Col span={24}>
                        <Form.Item
                            label="Message To"
                            name="userIds"
                            rules={[
                                {
                                    required: true,
                                    message: 'Please select at least one User!',
                                },
                            ]}
                        >
                            <Select
                                mode="multiple"
                                allowClear
                                placeholder="Please select"
                                options={FilteredOptions}
                                size="large"
                                optionLabelProp="label"
                                showSearch
                                onSearch={handleSearch}
                                defaultActiveFirstOption
                                filterOption={false}
                                onChange={handleChange}
                                value={selectedUserIds}
                            />
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={16}>
                    <Col offset={22} span={2}>
                        <Button type="primary" size="middle" onClick={onSave}>
                            <SaveOutlined />
                        </Button>
                    </Col>
                </Row>
            </Form>
        </div>
    )
}

const mapStateToProps = (state: any): CorporateChatStateInterface => {
    return {
        userList: state.user.userList,
    }
}

export default connect(mapStateToProps)(StaffComm)
