import React, { useEffect, useState } from 'react'
import { Row } from 'antd'
import commonStyles from '../../../Utils/Common.less'
import { type ManageUserDetailsExpandViewInterface } from '../../../Types/ManageUser'

const UserExpanview = (
    props: ManageUserDetailsExpandViewInterface
): React.ReactElement => {
    const { userDetailsData } = props
    const [dob, setFormatDate] = useState('')
    useEffect(() => {
        const dateObject = new Date(userDetailsData.dateOfBirth)

        const year = dateObject.getFullYear()
        const month = dateObject.getMonth() + 1
        const day = dateObject.getDate()

        const formattedDate = `${month}/${day}/${year}`
        setFormatDate(formattedDate)
    }, [userDetailsData])
    return (
        <div className={commonStyles.expandCard}>
            <Row className={commonStyles.expandRow}>
                Date Of Birth -:{' '}
                <div className={commonStyles.expandRowValue}>{dob}</div>
            </Row>
            <Row className={commonStyles.expandRow}>
                Nick Name -:{' '}
                <div className={commonStyles.expandRowValue}>
                    {userDetailsData.userName}
                </div>
            </Row>
            <Row className={commonStyles.expandRow}>
                Department -:{' '}
                <div className={commonStyles.expandRowValue}>
                    {' '}
                    {userDetailsData.departmentName}
                </div>
            </Row>
            <Row className={commonStyles.expandRow}>
                Address -:{' '}
                <div className={commonStyles.expandRowValue}>
                    {userDetailsData.addressOne},{userDetailsData.addressTwo},
                    {userDetailsData.town},{userDetailsData.postCode},
                    {userDetailsData.country}
                </div>
            </Row>
            <Row className={commonStyles.expandRow}>
                Reporting Person -:{' '}
                <div className={commonStyles.expandRowValue}>
                    {userDetailsData.managerName}
                </div>
            </Row>
            <Row className={commonStyles.expandRow}>
                <div>User Group -:</div> {'  '}{' '}
                <div className={commonStyles.expandRowValue}>
                    {userDetailsData.userGroupName}
                </div>
            </Row>
            <Row className={commonStyles.expandRow}>
                <div>Image -:</div> {'  '}{' '}
                <div className={commonStyles.expandRowValue}>
                    <img
                        src={userDetailsData.imageurl}
                        className={commonStyles.usermangementimage}
                    />
                </div>
            </Row>
        </div>
    )
}

export default UserExpanview
