import React, { useEffect, useState } from 'react'
import { Row, Col, Card, Table, Avatar, Flex, Spin, Empty, Select } from 'antd'
import styles from './index.less'
import { Chart, ArcElement } from 'chart.js'
import type {
    DashboardThingsToDoUserWise,
    StaffProgressProps,
} from '../../Types/Dashboard'
import axios from '../../Config/AxiosConfig'
import { ErrorMessage } from '../../Utils/Notification'
import { useNavigate } from 'react-router-dom'
import { connect, useDispatch } from 'react-redux'
import { type ListFrontEndInterface } from '../../Types/CommonType'
import { GetUserList } from '../../Services/User'
import { type ColumnsType } from 'antd/es/table'

Chart.register(ArcElement)

interface StaffProgressStateIntrface {
    userList: ListFrontEndInterface[]
}
const StaffProgress = (props: StaffProgressProps): React.ReactElement => {
    const { dashboardThingsToDoUserWiseList, userList } = props
    const navigate = useNavigate()
    const [isLoading, setLoading] = useState<boolean>(false)
    const [staffList, setStaffList] = useState<DashboardThingsToDoUserWise[]>(
        []
    )
    const [searchStaff, setSearchStaff] = useState<string>('')
    const dispatch = useDispatch()
    useEffect(() => {
        GetUserList(dispatch)
    }, [dispatch])

    const columns: ColumnsType<any> = [
        {
            title: (
                <span className={styles.staffProgress__table__tittle}>
                    Staff
                </span>
            ),
            dataIndex: 'staffName',
            key: 'staffName',
            width: 140,
            render: (text: string) => (
                <span
                    style={{
                        display: 'flex',
                        justifyItems: 'center',
                        alignItems: 'center',
                    }}
                >
                    <Avatar
                        src={
                            'https://zos.alipayobjects.com/rmsportal/ODTLcjxAfvqbxHnVXCYX.png'
                        }
                        size={16}
                    />
                    &nbsp;
                    {text}
                </span>
            ),
            onHeaderCell: () => ({
                style: {
                    backgroundColor: '#f0f0f0',
                },
            }),
        },
        {
            title: (
                <span className={styles.staffProgress__table__tittle}>VAT</span>
            ),
            dataIndex: 'vat',
            key: 'vat',
            width: 45,
            align: 'center',
            onHeaderCell: () => ({
                style: {
                    backgroundColor: '#f0f0f0',
                },
            }),
        },
        {
            title: (
                <span className={styles.staffProgress__table__tittle}>
                    PAYE
                </span>
            ),
            dataIndex: 'paye',
            key: 'paye',
            width: 50,
            align: 'center',
            onHeaderCell: () => ({
                style: {
                    backgroundColor: '#f0f0f0',
                },
            }),
        },
        {
            title: (
                <span className={styles.staffProgress__table__tittle}>CT</span>
            ),
            dataIndex: 'ct',
            key: 'ct',
            width: 40,
            align: 'center',
            onHeaderCell: () => ({
                style: {
                    backgroundColor: '#f0f0f0',
                },
            }),
        },
        {
            title: (
                <span className={styles.staffProgress__table__tittle}>
                    Pension
                </span>
            ),
            dataIndex: 'pension',
            key: 'pension',
            width: 70,
            align: 'center',
            onHeaderCell: () => ({
                style: {
                    backgroundColor: '#f0f0f0',
                },
            }),
        },
        {
            title: (
                <span className={styles.staffProgress__table__tittle}>AA</span>
            ),
            dataIndex: 'aa',
            key: 'aa',
            width: 40,
            align: 'center',
            onHeaderCell: () => ({
                style: {
                    backgroundColor: '#f0f0f0',
                },
            }),
        },
        {
            title: (
                <span className={styles.staffProgress__table__tittle}>CS</span>
            ),
            dataIndex: 'cs',
            key: 'cs',
            width: 40,
            align: 'center',
            onHeaderCell: () => ({
                style: {
                    backgroundColor: '#f0f0f0',
                },
            }),
        },
        {
            title: (
                <span className={styles.staffProgress__table__tittle}>
                    Adhoc
                </span>
            ),
            dataIndex: 'adhoc',
            key: 'adhoc',
            width: 60,
            align: 'center',
            onHeaderCell: () => ({
                style: {
                    backgroundColor: '#f0f0f0',
                },
            }),
        },
        {
            title: (
                <span className={styles.staffProgress__table__tittle}>
                    My Task
                </span>
            ),
            dataIndex: 'myTask',
            key: 'myTask',
            width: 80,
            align: 'center',
            onHeaderCell: () => ({
                style: {
                    backgroundColor: '#f0f0f0',
                },
            }),
        },
    ]

    const user = localStorage.getItem('user')
    const userId = user !== null ? JSON.parse(user)?.userId : ''

    const GetDashboardThingsToDoUserWise = async (
        search: string
    ): Promise<void> => {
        setLoading(true)
        await axios
            .get(
                `api/Dashboard/GetDashboardThingsToDoUserWise/${userId}/${search}/1`
            )
            .then((response: any) => {
                if (response.status === 200) {
                    const newData = response?.data?.data
                    setStaffList(newData)
                    setLoading(false)
                } else {
                    setLoading(false)
                    ErrorMessage(response.data.error.message)
                }
            })
            .catch((error: any) => {
                setLoading(false)
                ErrorMessage(error)
            })
    }

    useEffect(() => {
        if (dashboardThingsToDoUserWiseList.length > 0) {
            setStaffList(dashboardThingsToDoUserWiseList)
        } else {
            void GetDashboardThingsToDoUserWise('=NA=')
        }
    }, [])

    useEffect(() => {
        if (
            searchStaff !== null &&
            searchStaff !== undefined &&
            searchStaff !== ''
        ) {
            void GetDashboardThingsToDoUserWise(searchStaff)
        } else {
            if (dashboardThingsToDoUserWiseList.length > 0) {
                setStaffList(dashboardThingsToDoUserWiseList)
            } else {
                setStaffList([])
            }
        }
    }, [searchStaff])

    const TableComponent = (): React.ReactElement => {
        return staffList?.length > 0 ? (
            <Table
                rowKey="userId"
                dataSource={staffList}
                columns={columns}
                pagination={false}
                size="small"
                scroll={{ x: 100 }}
                className={styles.custom_table}
            />
        ) : (
            <Flex align="center" justify="center">
                <Empty
                    style={{ padding: '40px 0px' }}
                    image={Empty.PRESENTED_IMAGE_SIMPLE}
                    description={<span>No Staff Found</span>}
                />
            </Flex>
        )
    }

    const handleViewAllClick = (): void => {
        navigate('/dashboard-staff-overview')
    }

    const handelUserOnchange = (value: number): void => {
        const user = userList?.find((user) => user.value === value)
        if (user !== undefined && user !== null) {
            setSearchStaff(user?.label)
        } else {
            setSearchStaff('=NA=')
        }
    }
    return (
        <Card
            className={`${styles.card} ${styles.staffProgress__main__height}`}
        >
            <div className={styles.cardContent}>
                <Row>
                    <Col span={12} className={styles.staffProgress__tittle}>
                        Staff Progress
                    </Col>
                    <Col
                        span={12}
                        className={styles.staffProgress__viewall}
                        onClick={handleViewAllClick}
                    >
                        View All
                    </Col>
                </Row>
                <Row>
                    {/* <Search
                        placeholder="input search text"
                        size="small"
                        className={styles.staffProgress__search}
                        value={searchStaff}
                        onChange={(e) => {
                            setSearchStaff(e.target.value)
                        }}
                    /> */}
                    <Select
                        allowClear
                        placeholder="Select & search staff"
                        onChange={handelUserOnchange}
                        showSearch
                        filterOption={(input, option) =>
                            option?.label
                                ?.toLowerCase()
                                .includes(input.toLowerCase()) ?? false
                        }
                        options={[
                            { label: 'Please select', value: null },
                            ...userList,
                        ]}
                        size="small"
                        className={styles.staffProgress__search}
                    />
                </Row>
                <div className={styles.staffProgress__table}>
                    {isLoading ? (
                        <Flex
                            align="center"
                            justify="center"
                            style={{ padding: '20px' }}
                        >
                            <Spin />
                        </Flex>
                    ) : (
                        <TableComponent />
                    )}
                </div>
            </div>
        </Card>
    )
}

const mapStateToProps = (state: any): StaffProgressStateIntrface => {
    return {
        userList: state.user.userList,
    }
}

export default connect(mapStateToProps)(StaffProgress)
