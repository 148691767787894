import React, { useEffect, useState, useCallback } from 'react'
import { connect, useDispatch } from 'react-redux'
import { Card, Table, Space, Modal, Switch, Tooltip } from 'antd'
import commonStyles from '../../../Utils/Common.less'
import AddNewButton from '../../../Components/AddNewButton'
import SearchInput from '../../../Components/SearchInput'
import type { ColumnsType, TableProps } from 'antd/es/table'
import {
    GetDepartmentList,
    SaveDepartment,
    ActiveInactiveDepartment,
    DeleteDepartment,
} from '../../../Services/Department'
import type { TableParamsInterface } from '../../../Types/CommonType'
import {
    commonTableParams,
    setCommonTableParamsData,
} from '../../../Utils/CommontTable'
import FullWidthModal from '../../../Components/FullWidthModal'
import { ReactComponent as DeleteIcon } from '../../../Assest/Svg/DeleteIcon.svg'
import { ReactComponent as EditIcon } from '../../../Assest/Svg/EditIcon.svg'
import DepartmentForm from './departmentForm'
import type {
    DepartmentListInterface,
    DepartmentListDataTypeInterface,
    DepartmentFormDataInterface,
} from '../../../Types/Department'
import { GetCategoryDropDownList } from '../../../Services/GroupAndPartners'
import calculatePagination from '../../../Utils/Pagination'
import ActivePremission from '../../../Utils/premissins'
import { PermissionType } from '../../../Types/Enum/PermissionType'

export const DepartmentItemDefaultData = {
    clientCategoryName: '',
    clientCategoryId: 0,
    departmentName: '',
    departmentId: '0',
    description: '',
    activeStatus: '',
}

const DepartmentList = (props: DepartmentListInterface): React.ReactElement => {
    const dispatch = useDispatch()
    const { DepartmentList, DepartmentListCount } = props
    const [tableParams, setTableParams] =
        useState<TableParamsInterface>(commonTableParams)
    const [deleteModalStatus, setDeleteModalStatus] = useState(false)
    const [createEditModalStatus, setCreateEditModalStatus] = useState(false)
    const [departmentId, setDepartmentId] = useState('')
    const [editModalTittle, setEditModalTittle] = useState('Create Department')
    const [editData, setEditData] = useState<DepartmentFormDataInterface>(
        DepartmentItemDefaultData
    )
    const [activeInactiveModalStatus, setActiveInactiveModalStatus] =
        useState(false)
    const [activeInactiveStatusLabel, setActiveInactiveStatusLabel] =
        useState('')
    const [currentPage, setCurrentPage] = useState(1)
    const [itemsPerPage, setItemsPerPage] = useState(10)

    useEffect(() => {
        GetCategoryDropDownList(dispatch)
    }, [])

    const getTableData = useCallback(() => {
        const { pageSize, pageNo, sortOrderList, searchCriteriaList } =
            setCommonTableParamsData(tableParams)
        GetDepartmentList(dispatch, {
            pageNo,
            pageSize,
            searchCriteriaList,
            sortOrderList,
        })
    }, [
        tableParams?.sorter,
        tableParams.pagination?.current,
        tableParams?.filter,
    ])

    useEffect(() => {
        getTableData()
    }, [getTableData])

    const handleDataCountChange = useCallback(() => {
        setTableParams({
            ...tableParams,
            pagination: {
                ...tableParams.pagination,
                total: DepartmentListCount,
            },
        })
    }, [DepartmentListCount])

    useEffect(() => {
        handleDataCountChange()
    }, [handleDataCountChange])

    const handlePageChange = (pageNo: number, pageSize: number): void => {
        setCurrentPage(pageNo)
        setItemsPerPage(pageSize)
    }

    const pagination = calculatePagination(
        currentPage,
        DepartmentListCount,
        itemsPerPage
    )

    const columns: ColumnsType<DepartmentListDataTypeInterface> = [
        {
            title: 'Departments',
            dataIndex: 'departmentName',
        },
        {
            title: 'Company Group',
            dataIndex: 'clientCategoryName',
            sorter: true,
            sortDirections: ['descend', 'ascend'],
        },
        {
            title: 'Description',
            dataIndex: 'description',
        },
        {
            title: 'Action',
            key: 'action',
            render: (_, record: DepartmentListDataTypeInterface) => {
                return (
                    <Space size="middle">
                        {ActivePremission('1002', PermissionType.UPDATE) && (
                            <Tooltip title="Edit Department">
                                <EditIcon
                                    onClick={() => {
                                        openEditModal(record?.departmentId)
                                    }}
                                    className={commonStyles.clickableIcon}
                                />
                            </Tooltip>
                        )}

                        <Tooltip
                            title={
                                record.activeStatus === 'Active'
                                    ? 'Active'
                                    : 'Inactive'
                            }
                        >
                            <Switch
                                checked={record.activeStatus === 'Active'}
                                onClick={() => {
                                    activeInactiveModalFunction(
                                        record?.departmentId,
                                        record.activeStatus
                                    )
                                }}
                            />
                        </Tooltip>
                        {ActivePremission('1002', PermissionType.DELETE) && (
                            <Tooltip title="Delete">
                                <DeleteIcon
                                    onClick={() => {
                                        deleteModalFunction(
                                            record?.departmentId
                                        )
                                    }}
                                    className={commonStyles.clickableIcon}
                                />
                            </Tooltip>
                        )}
                    </Space>
                )
            },
        },
    ]

    const onChangeTableParams: TableProps<DepartmentListDataTypeInterface>['onChange'] =
        (pagination, filters, sorter: any, extra) => {
            handlePageChange(pagination.current ?? 1, pagination.pageSize ?? 10)
            setTableParams({
                ...tableParams,
                pagination,
                sorter: {
                    field: sorter?.field ?? '',
                    order: sorter?.order ?? '',
                },
            })
        }

    const onChangeText = (data: React.ChangeEvent<HTMLInputElement>): void => {
        const searchText = data.target.value.trim().toLowerCase()
        onChangeFilter(searchText)
    }

    const onChangeFilter = (data: string): void => {
        setTableParams({
            ...tableParams,
            filter: {
                fieldName: 'departmentName',
                searchOperator: 10,
                fieldValue1: data,
                fieldValue2: '',
            },
        })
    }

    const deleteModalFunction = (id: string): void => {
        setDeleteModalStatus(!deleteModalStatus)
        let departmentId = ''
        if (!deleteModalStatus) {
            departmentId = id
        }
        setDepartmentId(departmentId)
    }

    const deleteDepartment = (): void => {
        DeleteDepartment(dispatch, departmentId, getTableData)
        deleteModalFunction('')
    }

    const openEditModal = (id: string): void => {
        const data = DepartmentList.find(
            (data: DepartmentFormDataInterface) => {
                return data.departmentId === id
            }
        )

        let tittle = 'Create Department'
        if (id !== null && id !== undefined) {
            tittle = 'Edit Designation'
        }

        setEditModalTittle(tittle)
        setEditData(data ?? DepartmentItemDefaultData)
        changeCreateEditModalStatus()
    }

    const changeCreateEditModalStatus = (): void => {
        setCreateEditModalStatus(!createEditModalStatus)
    }

    const activeInactiveModalFunction = (
        id: string,
        activeStatus: string
    ): void => {
        setActiveInactiveModalStatus(!activeInactiveModalStatus)
        let departmentId = ''
        if (!activeInactiveModalStatus) {
            departmentId = id
        }
        let label = 'Active'
        if (activeStatus === 'Active') {
            label = 'Inactive'
        }
        setActiveInactiveStatusLabel(label)
        setDepartmentId(departmentId)
    }

    const updateActiveInactiveDepartement = (): void => {
        let status = 'Active'
        if (activeInactiveStatusLabel === 'Inactive') status = 'Inactive'
        setDepartmentId(departmentId)
        ActiveInactiveDepartment(dispatch, departmentId, status, getTableData)
        activeInactiveModalFunction('', '')
    }

    const onSaveDepartment = (
        data: DepartmentFormDataInterface,
        callBack: CallableFunction
    ): void => {
        void saveDepartmentData(data, callBack)
    }

    const saveDepartmentData = async (
        data: DepartmentFormDataInterface,
        callBack: CallableFunction
    ): Promise<void> => {
        await SaveDepartment(dispatch, data)
            .then((result) => {
                if (result !== null && result !== undefined) {
                    changeCreateEditModalStatus()
                    getTableData()
                    callBack()
                }
            })
            .catch(() => {})
    }

    return (
        <>
            <Card
                title="Departments"
                extra={
                    <AddNewButton
                        name="Add New"
                        clickAction={() => {
                            openEditModal('')
                        }}
                    />
                }
                className={commonStyles.card}
            >
                <SearchInput
                    placeHolder={'Search Departments by keywords'}
                    onSearch={onChangeFilter}
                    onChange={onChangeText}
                    width="478"
                />
                <div className={commonStyles.table}>
                    <Table
                        columns={columns}
                        dataSource={DepartmentList}
                        onChange={onChangeTableParams}
                        pagination={pagination}
                    />
                </div>
                <FullWidthModal
                    modalStatus={deleteModalStatus}
                    closeModal={deleteModalFunction}
                    tittle="You are about to delete this department. Click 'Yes' to continue."
                    smallTittle="Warning: This action cannot be undone!"
                    yesButtonFunction={deleteDepartment}
                />
                <FullWidthModal
                    modalStatus={activeInactiveModalStatus}
                    closeModal={() => {
                        activeInactiveModalFunction('', '')
                    }}
                    tittle={`You are about to ${activeInactiveStatusLabel} this department. Click 'Yes' to continue.`}
                    smallTittle=""
                    yesButtonFunction={updateActiveInactiveDepartement}
                />
                <Modal
                    footer={null}
                    onCancel={changeCreateEditModalStatus}
                    width={'40%'}
                    title={editModalTittle}
                    open={createEditModalStatus}
                >
                    <DepartmentForm
                        onSave={onSaveDepartment}
                        editData={editData}
                    />
                </Modal>
            </Card>
        </>
    )
}

const mapStateToProps = (state: any): DepartmentListInterface => {
    return {
        DepartmentList: state.initial.departmentList,
        DepartmentListCount: state.initial.departmentListCount,
    }
}

export default connect(mapStateToProps)(DepartmentList)
