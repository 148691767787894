import { Button, Card, Flex, Modal, Space, Switch, Table, Tooltip } from 'antd'
import React, { useCallback, useEffect, useState } from 'react'
import commonStyles from '../../../Utils/Common.less'
import type { ColumnsType, TableProps } from 'antd/es/table'
import { connect, useDispatch } from 'react-redux'
import {
    type ArticleData,
    type ArticaltableList,
    type ListofArticalStateInteerface,
} from '../../../Types/wikiArtical'
import SearchInput from '../../../Components/SearchInput'
import { type TableParamsInterface } from '../../../Types/CommonType'
import {
    commonTableParams,
    setCommonTableParamsData,
} from '../../../Utils/CommontTable'
import calculatePagination from '../../../Utils/Pagination'
import {
    ActiveInactiveArtical,
    DeleteArtical,
    GetArticalList,
    SaveArtical,
} from '../../../Services/wikiArtical'
import { ReactComponent as DeleteIcon } from '../../../Assest/Svg/DeleteIcon.svg'
import { EyeOutlined } from '@ant-design/icons'
import { ReactComponent as PlusIcon } from '../../../Assest/Svg/Plus.svg'
import { ReactComponent as EditIcon } from '../../../Assest/Svg/EditIcon.svg'
import FullWidthModal from '../../../Components/FullWidthModal'
import ArticalDetailsExpandView from './wikiArticalExpandView'
import ArticalDetailsForm from './wikiArticalForm'
import DocumentViewModelForm from './documentViewModel'

export const ArticleDefaultData = {
    articleId: 0,
    articleGroupId: 0,
    articleGroupName: '',
    articleName: '',
    articleDescription: '',
    icon: '',
    iconType: 0,
    isPublic: 0,
    isSharedInSocialMedia: 0,
    isActive: '',
    attachmentURL: '',
    isPublishInNews: 0,
    date: '',
}
const WikiArticalList = (
    props: ListofArticalStateInteerface
): React.ReactElement => {
    const { articalList, articalListCount } = props
    const [currentPage, setCurrentPage] = useState(1)
    const [itemsPerPage, setItemsPerPage] = useState(10)
    const dispatch = useDispatch()
    const [tableParams, setTableParams] =
        useState<TableParamsInterface>(commonTableParams)
    const [editData, setEditData] = useState<ArticleData>(ArticleDefaultData)
    const [articalId, setArticalId] = useState(0)
    const [editModalTittle, setEditModalTittle] = useState('Create Artical ')
    const [deleteModalStatus, setDeleteModalStatus] = useState(false)
    const [createEditModalStatus, setCreateEditModalStatus] = useState(false)
    const [activeInactiveModalStatus, setActiveInactiveModalStatus] =
        useState(false)
    const [activeInactiveStatusLabel, setActiveInactiveStatusLabel] =
        useState('')
    const [documentModalStatus, setDocumentModalStatus] = useState(false)
    const [doucmentUrl, setDocumentUrl] = useState('')
    const getTableData = useCallback(() => {
        const { pageSize, pageNo, sortOrderList, searchCriteriaList } =
            setCommonTableParamsData(tableParams)
        GetArticalList(dispatch, {
            pageNo,
            pageSize,
            searchCriteriaList,
            sortOrderList,
        })
    }, [
        tableParams?.sorter,
        tableParams.pagination?.current,
        tableParams?.filter,
    ])

    useEffect(() => {
        getTableData()
    }, [getTableData])

    const handleDataCountChange = useCallback(() => {
        setTableParams({
            ...tableParams,
            pagination: {
                ...tableParams.pagination,
                total: articalListCount,
            },
        })
    }, [articalListCount])

    useEffect(() => {
        handleDataCountChange()
    }, [handleDataCountChange])

    const handlePageChange = (pageNo: number, pageSize: number): void => {
        setCurrentPage(pageNo)
        setItemsPerPage(pageSize)
    }

    const pagination = calculatePagination(
        currentPage,
        articalListCount,
        itemsPerPage
    )

    const columns: ColumnsType<ArticaltableList> = [
        {
            title: 'Artical',
            key: 'articleName',
            render: (record: ArticaltableList) => {
                return (
                    <div>
                        <h3>
                            {record.articleName}({record.articleGroupName})
                        </h3>
                    </div>
                )
            },
        },
        {
            title: '',
            key: 'action1',
            render: (record: ArticaltableList) => {
                return (
                    <Space size="middle">
                        <Tooltip title="Edit Artical">
                            <EditIcon
                                onClick={() => {
                                    openEditModal(record?.articleId)
                                }}
                                className={commonStyles.clickableIcon}
                            />
                        </Tooltip>
                        <Tooltip title="Preview Doument">
                            <EyeOutlined
                                onClick={() => {
                                    OpenDocimentModel(
                                        record.attachmentURL,
                                        record.articleId
                                    )
                                }}
                                className={commonStyles.viewIcon}
                            />
                        </Tooltip>
                        <Tooltip
                            title={`Click ${
                                record.isActive === 'Active'
                                    ? 'Active'
                                    : 'Inactive'
                            }
                                    `}
                        >
                            <Switch
                                checked={record.isActive === 'Active'}
                                onClick={() => {
                                    activeInactiveModalFunction(
                                        record?.articleId,
                                        record.isActive
                                    )
                                }}
                            />
                        </Tooltip>

                        <Tooltip title="Delete Artical">
                            <DeleteIcon
                                onClick={() => {
                                    deleteModalFunction(record?.articleId)
                                }}
                                className={commonStyles.clickableIcon}
                            />
                        </Tooltip>
                    </Space>
                )
            },
        },
    ]

    const onChangeTableParams: TableProps<ArticaltableList>['onChange'] = (
        pagination,
        filters,
        sorter: any,
        extra
    ) => {
        handlePageChange(pagination.current ?? 1, pagination.pageSize ?? 10)
        setTableParams({
            ...tableParams,
            pagination,
            sorter: {
                field: sorter?.field ?? '',
                order: sorter?.order ?? '',
            },
        })
    }

    const onChangeText = (data: React.ChangeEvent<HTMLInputElement>): void => {
        if (data.target.value?.length !== 0) {
            onChangeFilter(data.target.value)
        }
    }

    const onChangeFilter = (data: string): void => {
        setTableParams({
            ...tableParams,
            filter: {
                fieldName: 'multiplesearch',
                searchOperator: 10,
                fieldValue1: data,
                fieldValue2: 'string',
            },
            pagination: {
                ...tableParams.pagination,
                current: 1,
            },
        })
    }

    const deleteModalFunction = (id: number): void => {
        setDeleteModalStatus(!deleteModalStatus)
        let articalid = 0
        if (!deleteModalStatus) {
            articalid = id
        }
        setArticalId(articalid)
    }

    const deleteArticalDetails = (): void => {
        DeleteArtical(dispatch, articalId, getTableData)
        deleteModalFunction(0)
    }

    const openEditModal = (id: number): void => {
        const data = articalLists.find((data: ArticleData) => {
            return data.articleId === id
        })

        let tittle = 'Create Artical'
        if (id > 0) {
            tittle = 'Edit Artical'
        }

        setEditModalTittle(tittle)
        setEditData(data ?? ArticleDefaultData)
        changeCreateEditModalStatus()
    }
    const changeCreateEditModalStatus = (): void => {
        setCreateEditModalStatus(!createEditModalStatus)
    }

    const activeInactiveModalFunction = (
        id: number,
        activeStatus: string
    ): void => {
        setActiveInactiveModalStatus(!activeInactiveModalStatus)
        let articalid = 0
        if (!activeInactiveModalStatus) {
            articalid = id
        }
        let label = 'Active'
        if (activeStatus === 'Active') {
            label = 'Inactive'
        }
        setActiveInactiveStatusLabel(label)
        setArticalId(articalid)
    }

    const updateActiveInactiveCorporate = (): void => {
        let status = 'Active'
        if (activeInactiveStatusLabel === 'Inactive') status = 'Inactive'
        ActiveInactiveArtical(dispatch, articalId, status, getTableData)
        activeInactiveModalFunction(0, '')
    }

    const onSaveArticalDetails = (
        data: ArticleData,
        callBack: CallableFunction
    ): void => {
        void saveArticalDetailsData(data, callBack)
    }

    const saveArticalDetailsData = async (
        data: ArticleData,
        callBack: CallableFunction
    ): Promise<void> => {
        await SaveArtical(dispatch, data)
            .then((result) => {
                if (result !== null && result !== undefined) {
                    changeCreateEditModalStatus()
                    getTableData()
                    callBack()
                }
            })
            .catch(() => {})
    }

    const OpenDocimentModel = (value: string, articalID: number): void => {
        let newPath = ''
        if (value.includes('/0/')) {
            newPath = value.replace('/0/', `/${articalID}/`)
        } else {
            newPath = value.replace('/0', `/${articalID}/`)
        }
        setDocumentUrl(newPath)
        setDocumentModalStatus(!documentModalStatus)
    }
    const changeDocumentModalStatus = (): void => {
        setDocumentModalStatus(!documentModalStatus)
    }

    const articalLists = articalList?.map((item) => ({
        ...item,
        key: item.articleId,
    }))

    const expandableData = (record: ArticaltableList): React.ReactElement => {
        return <ArticalDetailsExpandView articalExpandData={record} />
    }
    return (
        <>
            <div>
                <h2>Article</h2>
            </div>
            <Card title={'Article'}>
                <Flex justify="space-between">
                    <SearchInput
                        placeHolder={'Search by keywords & press enter'}
                        onSearch={onChangeFilter}
                        onChange={onChangeText}
                        width="478"
                    />
                    <Tooltip title="Create Artical">
                        <Button
                            type="primary"
                            onClick={() => {
                                openEditModal(0)
                            }}
                        >
                            <PlusIcon />
                        </Button>
                    </Tooltip>
                </Flex>
                <div className={commonStyles.table}>
                    <Table
                        columns={columns}
                        dataSource={articalLists}
                        onChange={onChangeTableParams}
                        pagination={pagination}
                        expandable={{
                            expandedRowRender: expandableData,
                            rowExpandable: () => true,
                        }}
                    />
                </div>
                <FullWidthModal
                    modalStatus={deleteModalStatus}
                    closeModal={() => {
                        deleteModalFunction(0)
                    }}
                    tittle="You are about to delete this Article. Click 'Yes' to continue."
                    smallTittle="Warning: This action cannot be undone!"
                    yesButtonFunction={deleteArticalDetails}
                />
                <Modal
                    footer={null}
                    onCancel={changeCreateEditModalStatus}
                    width={'50%'}
                    title={editModalTittle}
                    open={createEditModalStatus}
                >
                    <ArticalDetailsForm
                        onSave={onSaveArticalDetails}
                        editData={editData}
                    />
                </Modal>
                <FullWidthModal
                    modalStatus={activeInactiveModalStatus}
                    closeModal={() => {
                        activeInactiveModalFunction(0, '')
                    }}
                    tittle={`You are about to ${activeInactiveStatusLabel} this Article. Click 'Yes' to continue.`}
                    smallTittle=""
                    yesButtonFunction={updateActiveInactiveCorporate}
                />
                <Modal
                    footer={null}
                    onCancel={changeDocumentModalStatus}
                    width={'50%'}
                    title={
                        <div>
                            <h3>Documents</h3>
                            <span>You can view & download your documents</span>
                        </div>
                    }
                    open={documentModalStatus}
                >
                    <DocumentViewModelForm data={doucmentUrl} />
                </Modal>
            </Card>
        </>
    )
}

const mapStateToProps = (state: any): ListofArticalStateInteerface => {
    return {
        articalList: state.wiki.articalList,
        articalListCount: state.wiki.articalListCount,
    }
}

export default connect(mapStateToProps)(WikiArticalList)
