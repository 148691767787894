import React, { useEffect, type ReactElement, useState } from 'react'
import {
    Col,
    DatePicker,
    type DatePickerProps,
    Form,
    Input,
    Row,
    Space,
} from 'antd'
import { connect } from 'react-redux'
import {
    type RegisterInterface,
    type PersonalDetails,
} from '../../../../../../Types/Client/RegisteredClients/SoleTrader'
import dayjs from 'dayjs'
import moment from 'moment'

const PersonalDetailsForm = (props: PersonalDetails): ReactElement => {
    const { soleTraderTaps } = props
    const [selectedDate, setSelectedDate] = useState<string>('')

    useEffect(() => {
        const initialDate = moment(
            soleTraderTaps?.soleTraderPersonalDetails?.dateOfBirth,
            'M/D/YYYY'
        )
        const formattedDate = initialDate.format('M/D/YYYY')
        setSelectedDate(formattedDate)
    }, [soleTraderTaps?.soleTraderPersonalDetails?.dateOfBirth])

    const handleDateChange = (
        date: DatePickerProps['value'],
        dateString: string | string[]
    ): void => {
        if (date != null) {
            const isoDate = date.toISOString()
            setSelectedDate(isoDate)
            if (props.onDateChange !== undefined) {
                props.onDateChange(isoDate)
            }
        }
    }
    return (
        <>
            <Row gutter={[16, 8]}>
                <Col span={24}>
                    <Form.Item label="Date Of Birth" name="dateOfBirth">
                        <Space direction="vertical" size={12}>
                            <DatePicker
                                value={
                                    selectedDate?.length > 0
                                        ? dayjs(selectedDate)
                                        : null
                                }
                                format="M/D/YYYY"
                                style={{ width: '500px' }}
                                onChange={(date, dateString) => {
                                    handleDateChange(date, dateString)
                                }}
                            />
                        </Space>
                    </Form.Item>
                </Col>
            </Row>
            <Row gutter={[16, 8]}>
                <Col span={24}>
                    {' '}
                    <Form.Item label="NI Number" name="niNumber">
                        <Input />
                    </Form.Item>
                </Col>
            </Row>
            <Row gutter={[16, 8]}>
                <Col span={24}>
                    <Form.Item label="Nationality" name="nationality">
                        <Input />
                    </Form.Item>
                </Col>
            </Row>
            <Row gutter={[16, 8]}>
                <Col span={24}>
                    {' '}
                    <Form.Item
                        label="Mobile Number"
                        name="mobileNumber"
                        rules={[
                            {
                                pattern: /^\d{10,15}$/,
                                message: 'please enter valid mobile number',
                            },
                            {
                                required: true,
                                message: 'Please input your mobile number!',
                            },
                        ]}
                    >
                        <Input />
                    </Form.Item>
                </Col>
            </Row>
            <Row gutter={[16, 8]}>
                <Col span={24}>
                    {' '}
                    <Form.Item
                        label="Home Number"
                        name="homeNumber"
                        rules={[
                            {
                                pattern:
                                    /^\(?(\d{2})\)?[- ]?(\d{4})[- ]?(\d{6})$/,
                                message: 'please enter valid mobile number',
                            },
                        ]}
                    >
                        <Input />
                    </Form.Item>
                </Col>
            </Row>
            <Row gutter={[16, 8]}>
                <Col span={24}>
                    {' '}
                    <Form.Item
                        label="Work Number"
                        name="workNumber"
                        rules={[
                            {
                                pattern:
                                    /^\(?(\d{2})\)?[- ]?(\d{4})[- ]?(\d{6})$/,
                                message: 'please enter valid mobile number',
                            },
                        ]}
                    >
                        <Input />
                    </Form.Item>
                </Col>
            </Row>
            <Row gutter={[16, 8]}>
                <Col span={24}>
                    {' '}
                    <Form.Item
                        label="Email Address"
                        name="emailAddress"
                        rules={[
                            {
                                type: 'email',
                                required: true,
                                message: 'Please input your email!',
                            },
                        ]}
                    >
                        <Input />
                    </Form.Item>
                </Col>
            </Row>
            <Row gutter={[16, 8]}>
                <Col span={24}>
                    <Form.Item
                        label="Alternative Email Address"
                        name="alternativeEmailAddress"
                        rules={[
                            {
                                type: 'email',
                                message: 'Please input valid email!',
                            },
                        ]}
                    >
                        <Input />
                    </Form.Item>
                </Col>
            </Row>
        </>
    )
}

const mapStateToProps = (state: any): RegisterInterface => {
    return {
        categoryDropDownList: state.initial.categoryDropDownList,
        countryList: state.common.countryList,
        soleTraderTaps: state.client.soleTraderTaps,
    }
}

export default connect(mapStateToProps)(PersonalDetailsForm)
