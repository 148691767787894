import { Input, Form, Select, Button, Row, Col } from 'antd'
import { connect, useDispatch } from 'react-redux'
import commonStyles from '../../../../../Utils/Common.less'
import React, { useEffect } from 'react'
import { SaveOutlined } from '@ant-design/icons'
import {
    GetPaymentOptionDropDownList,
    SetDirectDebitCustomer,
} from '../../../../../Services/Corporate'
import { type CorporateConfigurationInviteClientInterface } from '../../../../../Types/Client/RegisteredClients/Corporate'
import { useLocation } from 'react-router-dom'
import ActivePremission from '../../../../../Utils/premissins'
import { PermissionType } from '../../../../../Types/Enum/PermissionType'

const { TextArea } = Input

const GoCardlessInviteClient = (
    props: CorporateConfigurationInviteClientInterface
): React.ReactElement => {
    const { paymentOptionDropDownList, cancelGoCardlessModal } = props
    const dispatch = useDispatch()

    const location = useLocation()
    const corporateData = location.state?.corporateData
    const [form] = Form.useForm()

    useEffect(() => {
        GetPaymentOptionDropDownList(dispatch)
    }, [])
    const handleFinish = (): void => {
        void handleFinishData()
    }
    const handleFinishData = async (): Promise<void> => {
        try {
            await form.validateFields()
            const formData = form.getFieldsValue()
            const data = {
                CustomerId: 0,
                ApplicationId: 0,
                ReferenceId: corporateData.corporateid,
                ClientCategoryId: corporateData.clientcategoryid,
                BusinessTypeId: 1,
                CompanyName: '',
                GivenName: '',
                FamilyName: '',
                Email: formData.email,
                AddressLine1: '',
                AddressLine2: '',
                AddressLine3: '',
                City: '',
                PostalCode: '',
                CountryCode: '',
                Region: '',
                Language: '',
                PhoneNumber: '',
                DanishIdentityNumber: '',
                SwedishIdentityNumber: '',
                Description: formData.description ?? '',
                RegisterToken: '',
                PaymentOptionId: formData.goCardlessAccount,
            }

            void SetDirectDebitCustomer(dispatch, data, cancelGoCardlessModal)
        } catch (error) {}
    }

    const customizeRequiredMark = (
        label: React.ReactNode,
        { required }: { required: boolean }
    ): React.ReactElement => (
        <>
            {label}
            &nbsp;
            {required && <span className={commonStyles.requireIcon}>*</span>}
        </>
    )

    return (
        <div className={commonStyles.formWrapper}>
            <Form
                name="complex-form"
                layout={'vertical'}
                autoComplete="off"
                form={form}
                onFinish={handleFinish}
                requiredMark={customizeRequiredMark}
                className={commonStyles.flexDirectionColumn}
            >
                <Form.Item
                    label="Email"
                    name="email"
                    rules={[
                        {
                            type: 'email',
                            required: true,
                            message: 'Please Enter Valid Email',
                        },
                    ]}
                >
                    <Input />
                </Form.Item>
                <Form.Item
                    label="Gocardless Account"
                    name="goCardlessAccount"
                    rules={[
                        {
                            required: true,
                            message: 'Please Enter Gocardless Account',
                        },
                    ]}
                >
                    <Select
                        allowClear
                        showSearch
                        placeholder="Select your payment option"
                        onChange={() => {}}
                        filterOption={(input, option) =>
                            !(option == null) &&
                            option.label
                                .toLowerCase()
                                .includes(input.toLowerCase())
                        }
                        options={[
                            { label: 'Please select', value: null },
                            ...paymentOptionDropDownList,
                        ]}
                    />
                </Form.Item>
                <Form.Item label="Description" name="description">
                    <TextArea rows={4} />
                </Form.Item>
                {ActivePremission('3000', PermissionType.SAVE) && (
                    <Row gutter={16}>
                        <Col offset={21} span={3}>
                            <Button type="primary" htmlType="submit">
                                <SaveOutlined />
                            </Button>
                        </Col>
                    </Row>
                )}
            </Form>
        </div>
    )
}

const mapStateToProps = (state: any): any => {
    return {
        paymentOptionDropDownList: state.client.paymentOptionDropDownList,
    }
}

export default connect(mapStateToProps)(GoCardlessInviteClient)
