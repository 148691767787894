import { Col, Row, Space, Tooltip } from 'antd'
import React, { useEffect, useState } from 'react'
import { CheckOutlined } from '@ant-design/icons'
import notification from './notification.less'
import { useDispatch, connect } from 'react-redux'
import {
    GetNotificationDetials,
    SetReadMessage,
} from '../../Services/Notificcations'
import {
    type Message,
    type MessageListInterface,
} from '../../Types/Notifications'

const DashboarNotificationPopup = (
    props: MessageListInterface
): React.ReactElement => {
    const { messageList } = props
    const dispatch = useDispatch()
    const [clickedIndex, setClickedIndex] = useState(1)
    const [hoveredIndex, setHoveredIndex] = useState<number | null>(null)

    useEffect(() => {
        GetNotificationDetials(dispatch)
    }, [])

    const handleItemClick = (index: number): void => {
        setClickedIndex(index === clickedIndex ? 1 : index)
    }
    const handleMouseEnter = (index: number): void => {
        setHoveredIndex(index)
    }

    const handleMouseLeave = (): void => {
        setHoveredIndex(null)
    }

    const handleCheckClick = (notificationId: number, status: number): void => {
        SetReadMessage(dispatch, clickedIndex, notificationId, status)
        GetNotificationDetials(dispatch)
    }

    const renderMessages = (messages: Message[]): JSX.Element[] => {
        return messages?.map((message, index) => (
            <div
                key={message.messageId}
                onMouseEnter={() => {
                    handleMouseEnter(index)
                }}
                onMouseLeave={handleMouseLeave}
                className={notification.contentContainer}
            >
                {message.markAsRead === 0 && (
                    <>
                        <img
                            src={message.fromUserImageUrl}
                            className={notification.imageStyle}
                        />
                        <div className={notification.textContent}>
                            <p className={notification.messageHeading}>
                                {message.messageTitle.slice(0, 30)}
                            </p>
                            <p className={notification.messageHeading}>
                                {message.messageTitle.slice(30)}
                            </p>
                            <p className={notification.content}>
                                {message.messgeContent.slice(0, 40)}
                            </p>
                            <p className={notification.content}>
                                {message.messgeContent.slice(40)}
                            </p>
                            <p className={notification.hours}>
                                {message.dateInfo}
                                {hoveredIndex === index && (
                                    <span className={notification.actinbutton}>
                                        <Tooltip title={'Mark As Read'}>
                                            <CheckOutlined
                                                onClick={() => {
                                                    handleCheckClick(
                                                        message.messageId,
                                                        1
                                                    )
                                                }}
                                            />
                                        </Tooltip>
                                    </span>
                                )}
                            </p>
                        </div>
                    </>
                )}
            </div>
        ))
    }

    return (
        <>
            <div className={notification.contrinert}>
                <p className={notification.notificationCount}>
                    {messageList?.totalUnreadNotificationCount} New
                </p>
                <p>User Notification</p>
            </div>
            <hr />
            <div className={notification.contentDisplay}>
                <Row>
                    <Col span={12}>
                        <div
                            className={
                                clickedIndex === 1
                                    ? `${notification.messagestyle}`
                                    : `${notification.messagestyle2}`
                            }
                            onClick={() => {
                                handleItemClick(1)
                            }}
                        >
                            Message({messageList?.unreadMessageCount})
                        </div>
                    </Col>
                    <Col span={12}>
                        <div
                            className={
                                clickedIndex === 2
                                    ? `${notification.messagestyle}`
                                    : `${notification.messagestyle2}`
                            }
                            onClick={() => {
                                handleItemClick(2)
                            }}
                        >
                            Feeds({messageList?.unreadFeedCount})
                        </div>
                    </Col>
                </Row>
            </div>
            {clickedIndex === 1 && (
                <div className={notification.notificationcontent}>
                    <Space direction="vertical" size="large">
                        {renderMessages(messageList?.messageList)}
                    </Space>
                </div>
            )}
            {clickedIndex === 2 && (
                <div>
                    <h5> No record Found</h5>
                </div>
            )}
        </>
    )
}

const mapStateToProps = (state: any): MessageListInterface => {
    return {
        messageList: state.notification.messageList,
    }
}

export default connect(mapStateToProps)(DashboarNotificationPopup)
