import { type PayloadAction, createSlice } from '@reduxjs/toolkit'
import {
    type PortfolioPartnersPayloadInterface,
    type PortfolioManagerDetail,
    type PortfolioClientData,
    type PortfoliounAssigneePayloadInterface,
    type PortfolioRemarksList,
    type PortfolioRemarksListPayload,
} from '../Types/Client/RegisteredClients/Portfolio'

interface InitailStateInterface {
    portfolioList: PortfolioManagerDetail[]
    portfolioListCount: number
    portfolioUnassignUserList: PortfolioClientData[]
    portfolioUnassignUserListCount: number
    portfolioRemarksListData: PortfolioRemarksList | null
}

const PortfolioReducer = createSlice({
    name: 'portfolio',
    initialState: {
        portfolioList: [],
        portfolioListCount: 0,
        portfolioUnassignUserList: [],
        portfolioUnassignUserListCount: 0,
        portfolioRemarksListData: null,
    },
    reducers: {
        portfolioList(
            state: InitailStateInterface,
            action: PayloadAction<PortfolioPartnersPayloadInterface>
        ) {
            state.portfolioList = action?.payload?.data
            state.portfolioListCount = action?.payload?.noOfRecords
        },
        portfolioUnassignUserList(
            state: InitailStateInterface,
            action: PayloadAction<PortfoliounAssigneePayloadInterface>
        ) {
            state.portfolioUnassignUserList = action?.payload?.data
            state.portfolioUnassignUserListCount = action?.payload?.noOfRecords
        },
        portfolioRemarksListData(
            state: InitailStateInterface,
            action: PayloadAction<PortfolioRemarksListPayload>
        ) {
            state.portfolioRemarksListData = action?.payload?.data
        },
    },
})

export const {
    portfolioList,
    portfolioUnassignUserList,
    portfolioRemarksListData,
} = PortfolioReducer.actions
export default PortfolioReducer.reducer
