import { Col, Row, type TableProps, Table, Tooltip, Modal, Space } from 'antd'
import React, { useCallback, useEffect, useState } from 'react'
import commonStyles from '../Utils/Common.less'
import {
    type PortfolioClientviewTableData,
    type PortfolioClientviewData,
    type ClientCompanyDetails,
} from '../Types/Client/RegisteredClients/Portfolio'
import { type TableParamsInterface } from '../Types/CommonType'
import { commonTableParams } from '../Utils/CommontTable'
import { PortfolioDetailView } from '../Services/Portfolio'
import { useDispatch } from 'react-redux'
import { type ColumnsType } from 'antd/es/table'
import calculatePagination from '../Utils/Pagination'
import { ReactComponent as Infoicon } from '../Assest/ServiceprocessSVG/InfoI.svg'
import RemarksHistory from './remarksHistoryPage'
import { ProfileOutlined } from '@ant-design/icons'
const PortfolioView = (props: {
    businessId: number
    referenceId: number
    portfolioId: number
}): React.ReactElement => {
    const dispatch = useDispatch()
    const { businessId, referenceId, portfolioId } = props
    const [clientViewData, setClientViewData] =
        useState<PortfolioClientviewData>()
    const [currentPage, setCurrentPage] = useState(1)
    const [itemsPerPage, setItemsPerPage] = useState(10)
    const [tableParams, setTableParams] =
        useState<TableParamsInterface>(commonTableParams)
    const [remarksModel, setremarksModelStatus] = useState(false)
    const [ReferenceId, setReferenceId] = useState(0)
    const [BusinessId, setBusinessId] = useState(0)
    useEffect(() => {
        fetchclientData()
    }, [businessId, referenceId, portfolioId])

    const fetchclientData = (): void => {
        void PortfolioDetailView(
            dispatch,
            portfolioId,
            businessId,
            referenceId,
            (portfoliodata: PortfolioClientviewData) => {
                setClientViewData(portfoliodata)
            }
        )
    }

    const clientCompanyDetailsList = (
        clientViewData?.clientCompanyDetailsList != null
            ? clientViewData?.clientCompanyDetailsList?.map(
                  (item: ClientCompanyDetails) => ({
                      clientNumber: item.clientNumber,
                      name: item.name,
                      businessType: item.businessType,
                      businessTypeId: item.businessTypeId,
                      referenceId: item.referenceId,
                      key: item.referenceId,
                  })
              )
            : []
    ) as PortfolioClientviewTableData[]

    const handleDataCountChange = useCallback(() => {
        setTableParams({
            ...tableParams,
            pagination: {
                ...tableParams.pagination,
                total: clientCompanyDetailsList?.length,
            },
        })
    }, [clientCompanyDetailsList?.length])

    useEffect(() => {
        handleDataCountChange()
    }, [handleDataCountChange])

    const handlePageChange = (pageNo: number, pageSize: number): void => {
        setCurrentPage(pageNo)
        setItemsPerPage(pageSize)
    }

    const pagination = calculatePagination(
        currentPage,
        clientCompanyDetailsList?.length,
        itemsPerPage
    )

    const columns: ColumnsType<PortfolioClientviewTableData> = [
        {
            title: 'ClientNumber',
            dataIndex: 'clientNumber',
        },
        {
            title: 'Name',
            dataIndex: 'name',
        },
        {
            width: 300,
            title: 'BusinessType',
            dataIndex: 'businessType',
        },
        {
            title: '',
            key: 'action',
            render: (_, record: PortfolioClientviewTableData) => {
                return (
                    <Space size="middle">
                        <Tooltip title="Remarks History">
                            <ProfileOutlined
                                onClick={() => {
                                    RemarksModelOpen(
                                        record.referenceId,
                                        record.businessTypeId
                                    )
                                }}
                            />
                        </Tooltip>
                    </Space>
                )
            },
        },
    ]

    const onChangeTableParams: TableProps<PortfolioClientviewTableData>['onChange'] =
        (pagination, filters, sorter: any, extra) => {
            handlePageChange(pagination.current ?? 1, pagination.pageSize ?? 10)
            setTableParams({
                ...tableParams,
                pagination,
                sorter: {
                    field: sorter?.field ?? '',
                    order: sorter?.order ?? '',
                },
            })
        }
    const RemarksModelOpen = (
        referenceId: number,
        businessId: number
    ): void => {
        setReferenceId(referenceId)
        setBusinessId(businessId)
        setremarksModelStatus(!remarksModel)
    }
    const changeRemarksModalStatus = (): void => {
        setremarksModelStatus(!remarksModel)
    }

    return (
        <>
            <Row gutter={[12, 8]}>
                <Col span={6}>
                    <p>PortfolioNumber </p>
                    <p>PortfolioName</p>

                    <p>Accounts Manager</p>
                    <p>Portfolio Managet</p>
                    <p>Remarks</p>
                </Col>
                <Col span={5}>
                    <p>: {clientViewData?.number} </p>
                    <p>: {clientViewData?.name}</p>

                    <p>: {clientViewData?.accountsManager}</p>
                    <p>: {clientViewData?.manager}</p>
                    <p>
                        : {clientViewData?.remarks}{' '}
                        <Tooltip title="Remark History">
                            <Infoicon
                                style={{
                                    marginTop: 8,
                                    cursor: 'pointer',
                                }}
                                onClick={() => {
                                    RemarksModelOpen(0, 0)
                                }}
                            />
                        </Tooltip>
                    </p>
                </Col>
            </Row>
            <div className={commonStyles.table}>
                <Table
                    columns={columns}
                    dataSource={clientCompanyDetailsList}
                    onChange={onChangeTableParams}
                    pagination={pagination}
                />
            </div>
            <Modal
                footer={null}
                onCancel={changeRemarksModalStatus}
                width={'40%'}
                title={'Remarks History'}
                open={remarksModel}
            >
                <RemarksHistory
                    portfolioId={
                        clientViewData?.portfolioId !== undefined
                            ? clientViewData?.portfolioId
                            : 0
                    }
                    referenceId={ReferenceId}
                    businessId={BusinessId}
                />
            </Modal>
        </>
    )
}

export default PortfolioView
