import React from 'react'
import { Row } from 'antd'
import commontStyles from '../../../Utils/Common.less'
import type { BankDetailsExpandViewInterface } from '../../../Types/BankDetails'

const BankDetailsExpandView = (
    props: BankDetailsExpandViewInterface
): React.ReactElement => {
    const { bankDetailsData } = props

    return (
        <div className={commontStyles.expandCard}>
            <Row className={commontStyles.expandRow}>
                IBAN -:{' '}
                <div className={commontStyles.expandRowValue}>
                    {bankDetailsData.iban}
                </div>
            </Row>
            <Row className={commontStyles.expandRow}>
                Description -:{' '}
                <div className={commontStyles.expandRowValue}>
                    {bankDetailsData.description}
                </div>
            </Row>
        </div>
    )
}

export default BankDetailsExpandView
