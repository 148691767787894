import React, { useEffect, useState, useCallback } from 'react'
import { connect, useDispatch } from 'react-redux'
import { Card, Table, Space, Modal, Switch, Tooltip } from 'antd'
import commonStyles from '../../../Utils/Common.less'
import AddNewButton from '../../../Components/AddNewButton'
import SearchInput from '../../../Components/SearchInput'
import type { ColumnsType, TableProps } from 'antd/es/table'
import {
    GetleavetypeList,
    ActiveInactiveleave,
    Deleteleave,
    SaveLeave,
} from '../../../Services/LeaveType'
import type { TableParamsInterface } from '../../../Types/CommonType'
import {
    commonTableParams,
    setCommonTableParamsData,
} from '../../../Utils/CommontTable'
import FullWidthModal from '../../../Components/FullWidthModal'
import { ReactComponent as DeleteIcon } from '../../../Assest/Svg/DeleteIcon.svg'
import { ReactComponent as EditIcon } from '../../../Assest/Svg/EditIcon.svg'
import LeaveForm from './leaveForm'
import type {
    LeaveListInterface,
    LeaveListDataTypeInterface,
    LeaveFormDataInterface,
} from '../../../Types/LeaveType'
import calculatePagination from '../../../Utils/Pagination'
import { ErrorMessage } from '../../../Utils/Notification'
import ActivePremission from '../../../Utils/premissins'
import { PermissionType } from '../../../Types/Enum/PermissionType'

export const LeaveItemDefaultData = {
    leaveTypeId: '0',
    leaveType: '',
    activeStatus: '',
}

const LeaveList = (props: LeaveListInterface): React.ReactElement => {
    const dispatch = useDispatch()
    const { LeaveList, LeaveListCount } = props
    const [leaveTypeId, setLeaveTypeId] = useState('')
    const [tableParams, setTableParams] =
        useState<TableParamsInterface>(commonTableParams)
    const [deleteModalStatus, setDeleteModalStatus] = useState(false)
    const [createEditModalStatus, setCreateEditModalStatus] = useState(false)
    const [editModalTittle, setEditModalTittle] = useState('Create Holiday')
    const [editData, setEditData] =
        useState<LeaveFormDataInterface>(LeaveItemDefaultData)
    const [activeInactiveModalStatus, setActiveInactiveModalStatus] =
        useState(false)
    const [activeInactiveStatusLabel, setActiveInactiveStatusLabel] =
        useState('')
    const [currentPage, setCurrentPage] = useState(1)
    const [itemsPerPage, setItemsPerPage] = useState(10)

    const getTableData = useCallback(() => {
        const { pageSize, pageNo, sortOrderList, searchCriteriaList } =
            setCommonTableParamsData(tableParams)
        GetleavetypeList(dispatch, {
            pageNo,
            pageSize,
            searchCriteriaList,
            sortOrderList,
        })
    }, [
        tableParams?.sorter,
        tableParams.pagination?.current,
        tableParams?.filter,
    ])

    useEffect(() => {
        getTableData()
    }, [getTableData])

    const handleDataCountChange = useCallback(() => {
        setTableParams({
            ...tableParams,
            pagination: {
                ...tableParams.pagination,
                total: LeaveListCount,
            },
        })
    }, [LeaveListCount])

    useEffect(() => {
        handleDataCountChange()
    }, [handleDataCountChange])

    const handlePageChange = (pageNo: number, pageSize: number): void => {
        setCurrentPage(pageNo)
        setItemsPerPage(pageSize)
    }

    const pagination = calculatePagination(
        currentPage,
        LeaveListCount,
        itemsPerPage
    )

    const columns: ColumnsType<LeaveListDataTypeInterface> = [
        {
            title: 'LeaveType',
            dataIndex: 'leaveType',
            sorter: true,
            sortDirections: ['descend', 'ascend'],
        },

        {
            title: 'Action',
            key: 'action',
            render: (_, record: LeaveListDataTypeInterface) => {
                return (
                    <Space size="middle">
                        {ActivePremission('1005', PermissionType.UPDATE) && (
                            <Tooltip title="Edit Leave">
                                <EditIcon
                                    onClick={() => {
                                        openEditModal(record?.leaveTypeId)
                                    }}
                                    className={commonStyles.clickableIcon}
                                />
                            </Tooltip>
                        )}
                        <Tooltip
                            title={
                                record.activeStatus === 'Active'
                                    ? 'Active'
                                    : 'Inactive'
                            }
                        >
                            <Switch
                                checked={record.activeStatus === 'Active'}
                                onClick={() => {
                                    activeInactiveModalFunction(
                                        record?.leaveTypeId,
                                        record.activeStatus
                                    )
                                }}
                            />
                        </Tooltip>
                        {ActivePremission('1005', PermissionType.DELETE) && (
                            <Tooltip title="Delete">
                                <DeleteIcon
                                    onClick={() => {
                                        deleteModalFunction(record?.leaveTypeId)
                                    }}
                                    className={commonStyles.clickableIcon}
                                />
                            </Tooltip>
                        )}
                    </Space>
                )
            },
        },
    ]

    const onChangeTableParams: TableProps<LeaveListDataTypeInterface>['onChange'] =
        (pagination, filters, sorter: any, extra) => {
            handlePageChange(pagination.current ?? 1, pagination.pageSize ?? 10)
            setTableParams({
                ...tableParams,
                pagination,
                sorter: {
                    field: sorter?.field ?? '',
                    order: sorter?.order ?? '',
                },
            })
        }

    const onChangeText = (data: React.ChangeEvent<HTMLInputElement>): void => {
        const searchText = data.target.value.trim().toLowerCase()
        onChangeFilter(searchText)
    }

    const onChangeFilter = (data: string): void => {
        setTableParams({
            ...tableParams,
            filter: {
                fieldName: 'leaveType',
                searchOperator: 10,
                fieldValue1: data,
                fieldValue2: '',
            },
        })
    }

    const deleteModalFunction = (id: string): void => {
        setDeleteModalStatus(!deleteModalStatus)
        let leaveTypeId = ''
        if (!deleteModalStatus) {
            leaveTypeId = id
        }
        setLeaveTypeId(leaveTypeId)
    }

    const deleteleave = (): void => {
        Deleteleave(dispatch, leaveTypeId, getTableData)
        deleteModalFunction('')
    }

    const openEditModal = (id: string): void => {
        const data = LeaveList.find((data: LeaveFormDataInterface) => {
            return data.leaveTypeId === id
        })
        let title = 'Create Leave'

        if (id !== null && id !== undefined) {
            title = 'Edit Leave'
        }

        setEditModalTittle(title)
        setEditData(data ?? LeaveItemDefaultData)
        changeCreateEditModalStatus()
    }

    const changeCreateEditModalStatus = (): void => {
        setCreateEditModalStatus(!createEditModalStatus)
    }

    const activeInactiveModalFunction = (
        id: string,
        activeStatus: string
    ): void => {
        setActiveInactiveModalStatus(!activeInactiveModalStatus)
        let leaveTypeId = ''
        if (!activeInactiveModalStatus) {
            leaveTypeId = id
        }
        let label = 'Active'
        if (activeStatus === 'Active') {
            label = 'Inactive'
        }
        setActiveInactiveStatusLabel(label)
        setLeaveTypeId(leaveTypeId)
    }

    const updateActiveInactiveleave = (): void => {
        let status = 'Active'
        if (activeInactiveStatusLabel === 'Inactive') status = 'Inactive'
        ActiveInactiveleave(dispatch, leaveTypeId, status, getTableData)
        activeInactiveModalFunction('', '')
    }

    const onSaveleave = (
        data: LeaveFormDataInterface,
        callBack: CallableFunction
    ): void => {
        void saveLeaveData(data, callBack)
    }
    const saveLeaveData = async (
        data: LeaveFormDataInterface,
        callBack: CallableFunction
    ): Promise<void> => {
        const isDataAlreadyExists = LeaveList.some(
            (leave) =>
                leave.leaveTypeId !== data.leaveTypeId &&
                leave.leaveType === data.leaveType
        )

        if (isDataAlreadyExists) {
            ErrorMessage('This data already exists in the leave type.')
            return
        }

        SaveLeave(dispatch, data)
            .then((result) => {
                if (result !== null && result !== undefined) {
                    changeCreateEditModalStatus()
                    getTableData()
                    callBack()
                }
            })
            .catch(() => {})
    }

    return (
        <>
            <Card
                title="Leave Type"
                extra={
                    <AddNewButton
                        name="Add New"
                        clickAction={() => {
                            openEditModal('')
                        }}
                    />
                }
                className={commonStyles.card}
            >
                <SearchInput
                    placeHolder={'Search Leaves by keywords'}
                    onSearch={onChangeFilter}
                    onChange={onChangeText}
                    width="478"
                />
                <div className={commonStyles.table}>
                    <Table
                        columns={columns}
                        dataSource={LeaveList}
                        onChange={onChangeTableParams}
                        pagination={pagination}
                    />
                </div>
                <FullWidthModal
                    modalStatus={deleteModalStatus}
                    closeModal={deleteModalFunction}
                    tittle="You are about to delete this leave. Click 'Yes' to continue."
                    smallTittle="Warning: This action cannot be undone!"
                    yesButtonFunction={deleteleave}
                />
                <FullWidthModal
                    modalStatus={activeInactiveModalStatus}
                    closeModal={() => {
                        activeInactiveModalFunction('', '')
                    }}
                    tittle={`You are about to ${activeInactiveStatusLabel} this leave. Click 'Yes' to continue.`}
                    smallTittle=""
                    yesButtonFunction={updateActiveInactiveleave}
                />
                <Modal
                    footer={null}
                    onCancel={changeCreateEditModalStatus}
                    width={'40%'}
                    title={editModalTittle}
                    open={createEditModalStatus}
                >
                    <LeaveForm onSave={onSaveleave} editData={editData} />
                </Modal>
            </Card>
        </>
    )
}

const mapStateToProps = (state: any): LeaveListInterface => {
    return {
        LeaveList: state.initial.leaveList,
        LeaveListCount: state.initial.leaveListCount,
    }
}

export default connect(mapStateToProps)(LeaveList)
