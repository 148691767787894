import React, { useState } from 'react'
import PreviewButton from '../../../Components/PreviewButton'
import ApplyLeaveModule from './ApplyLeaveModule.less'
import FormComponent from './FormComponent'
import commonStyles from '../../../Utils/Common.less'
import { Row } from 'antd'
import LeaveFormList from './LeaveFormList'
import { type LeaveListType } from '../../../Types/Hr/LeavePermission'

const ApplyLeave = (): React.ReactElement => {
    const [showForm, setShowForm] = useState(false)
    const [leaveType, setLeaveType] = useState(1)
    const [activeButton, setActiveButton] = useState(1)
    const [leaveTypeId, setLeaveTypeId] = useState({})
    const [sendFinish, setSendFinish] = useState({})
    const [saveEnable, setSaveEnable] = useState(false)

    const handleButtonClick = (type: number): void => {
        setShowForm(true)
        setLeaveType(type)
        setActiveButton(type)
    }

    const handleDataFromChild = (data: LeaveListType): void => {
        setLeaveTypeId(data)
    }

    const hanldeFinish = (data: LeaveListType, saveEnable: boolean): void => {
        setSendFinish(data)
        setSaveEnable(saveEnable)
    }

    return (
        <div className={commonStyles.formWrapper}>
            <Row>
                <PreviewButton
                    name="Medical"
                    clickAction={() => {
                        handleButtonClick(1)
                    }}
                    className={`${ApplyLeaveModule.previewButton} ${
                        activeButton === 1 ? ApplyLeaveModule.activeButton : ''
                    }`}
                />
                <PreviewButton
                    name="Casual"
                    clickAction={() => {
                        handleButtonClick(2)
                    }}
                    className={`${ApplyLeaveModule.previewButton} ${
                        activeButton === 2 ? ApplyLeaveModule.activeButton : ''
                    }`}
                />
                <PreviewButton
                    name="Annual"
                    clickAction={() => {
                        handleButtonClick(3)
                    }}
                    className={`${ApplyLeaveModule.previewButton} ${
                        activeButton === 3 ? ApplyLeaveModule.activeButton : ''
                    }`}
                />
                <PreviewButton
                    name="Special"
                    clickAction={() => {
                        handleButtonClick(4)
                    }}
                    className={`${ApplyLeaveModule.previewButton} ${
                        activeButton === 4 ? ApplyLeaveModule.activeButton : ''
                    }`}
                />
            </Row>
            <Row>
                <PreviewButton
                    name="Other"
                    clickAction={() => {
                        handleButtonClick(5)
                    }}
                    className={`${ApplyLeaveModule.previewButton} ${
                        activeButton === 5 ? ApplyLeaveModule.activeButton : ''
                    }`}
                />
                <PreviewButton
                    name="test1"
                    clickAction={() => {
                        handleButtonClick(6)
                    }}
                    className={`${ApplyLeaveModule.previewButton} ${
                        activeButton === 6 ? ApplyLeaveModule.activeButton : ''
                    }`}
                />
            </Row>
            {
                <div>
                    {showForm && (
                        <FormComponent
                            LeaveType={leaveType}
                            LeaveEditRecord={leaveTypeId}
                            onFinish={hanldeFinish}
                        />
                    )}
                    {showForm && (
                        <LeaveFormList
                            LeaveType={leaveType}
                            LeaveTypeId={handleDataFromChild}
                            onFinish={sendFinish}
                            saveEnable={saveEnable}
                        />
                    )}
                </div>
            }
        </div>
    )
}

export default ApplyLeave
