import React, { useEffect, useState } from 'react'
import { Col, Row, Input, Form, Select, Switch, Flex } from 'antd'
import { connect, useDispatch } from 'react-redux'
import commonStyles from '../../../Utils/Common.less'
import { CheckOutlined, CloseSquareOutlined } from '@ant-design/icons'
import { GetCountryList } from '../../../Services/Common'
import type { ListFrontEndInterface } from '../../../Types/CommonType'
import FullWidthModal from '../../../Components/FullWidthModal'
import CusttomBackgroundButton from '../../../Components/CustomBagraoundButton'
interface AddressProps {
    countryList: ListFrontEndInterface[]
}
const AddSAILAddress = (props: AddressProps): React.ReactElement => {
    const { countryList } = props
    const [form] = Form.useForm()
    const dispatch = useDispatch()
    const [swithModalStatus, setSwithModelStatus] = useState(false)
    useEffect(() => {
        GetCountryList(dispatch)
    }, [])

    const ChangeModalFunction = (): void => {
        setSwithModelStatus(!swithModalStatus)
    }

    const changeCompanyHouse = (): void => {
        ChangeModalFunction()
    }

    const customizeRequiredMark = (
        label: React.ReactNode,
        { required }: { required: boolean }
    ): React.ReactElement => (
        <>
            {label}
            &nbsp;
            {required && <span className={commonStyles.requireIcon}>*</span>}
        </>
    )

    return (
        <>
            <div className={commonStyles.formWrapper}>
                <Form
                    name="complex-form"
                    labelCol={{ span: 24 }}
                    wrapperCol={{ span: 24 }}
                    initialValues={{ remember: true }}
                    layout={'vertical'}
                    autoComplete="off"
                    form={form}
                    requiredMark={customizeRequiredMark}
                >
                    <div>
                        <Row gutter={16}>
                            <Col span={8}>Company & Reg Number</Col>
                            <Col span={2}>:</Col>
                            <Col span={14}>N & S VANI LIMITED - 11869090</Col>
                        </Row>
                    </div>
                    <Row gutter={16}>
                        <Col span={12}>
                            <Form.Item
                                label="House Name or Number"
                                name="houseNo"
                                rules={[
                                    {
                                        required: true,
                                        message:
                                            'Please input House Name or Number!',
                                    },
                                ]}
                            >
                                <Input />
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item
                                label="PostCode"
                                name="postCode"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Please input your PostCode!',
                                    },
                                ]}
                            >
                                <Input />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={16}>
                        <Col span={12}>
                            <Form.Item
                                label="Address One"
                                name="addressOne"
                                rules={[
                                    {
                                        required: true,
                                        message:
                                            'Please input your address one!',
                                    },
                                ]}
                            >
                                <Input />
                            </Form.Item>
                        </Col>

                        <Col span={12}>
                            <Form.Item label="Address Two" name="addressTwo">
                                <Input />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={16}>
                        <Col span={12}>
                            <Form.Item
                                label="Town"
                                name="town"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Please input your town!',
                                    },
                                ]}
                            >
                                <Input />
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item label="Country / Region" name="region">
                                <Input />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={16}>
                        <Col span={12}>
                            <Form.Item
                                label="Country"
                                name="country"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Please select Country !',
                                    },
                                ]}
                            >
                                <Select
                                    allowClear
                                    showSearch
                                    placeholder="Please select"
                                    onChange={() => {}}
                                    optionFilterProp="children"
                                    filterOption={(input, option) =>
                                        (option?.label ?? '')
                                            .toLowerCase()
                                            .includes(input.toLowerCase())
                                    }
                                    options={countryList}
                                />
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item label="PO Box Number" name="PoBNo">
                                <Input />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row>
                        <Col span={24}>
                            <Switch />{' '}
                            <span style={{ marginLeft: 5 }}>
                                Select this option, If you are need to apply
                                this changes to companies house.
                            </span>
                        </Col>
                    </Row>
                    <div style={{ marginTop: '20px' }}>
                        <Flex justify="flex-end" gap={5}>
                            <CusttomBackgroundButton
                                color={'#868e96'}
                                name={'Cancel'}
                                icon={<CloseSquareOutlined />}
                            />
                            <CusttomBackgroundButton
                                color={'#1dc9b7'}
                                name={'Submit Changes'}
                                icon={<CheckOutlined />}
                            />
                        </Flex>
                    </div>
                </Form>
            </div>
            <FullWidthModal
                modalStatus={swithModalStatus}
                closeModal={ChangeModalFunction}
                tittle="You are about to submit this changes to Company House. Click 'Yes' to continue."
                smallTittle="Warning: This action cannot be undone!"
                yesButtonFunction={changeCompanyHouse}
            />
        </>
    )
}

const mapStateToProps = (state: any): AddressProps => {
    return {
        countryList: state.common.countryList,
    }
}

export default connect(mapStateToProps)(AddSAILAddress)
