import React from 'react'
import { ConfigProvider, Layout, Menu, type MenuProps } from 'antd'
import { useNavigate } from 'react-router-dom'
import styles from './Less/style.less'
import { ReactComponent as Dashboard } from '../Assest/Svg/Dashboard.svg'
import { ReactComponent as InitialSetup } from '../Assest/Svg/InitialSetup.svg'
import { ReactComponent as ServiceSetup } from '../Assest/Svg/ServiceSetup.svg'
import { ReactComponent as ClientIcon } from '../Assest/Svg/ClientIcon.svg'
import { ReactComponent as MissingInformationIcon } from '../Assest/Svg/MissingInformationIcon.svg'
import { ReactComponent as InvoiceIcon } from '../Assest/Svg/InvoiceIcon.svg'
// import { ReactComponent as AdHocServiceIcon } from '../Assest/Svg/AdHocServiceIcon.svg'
import { ReactComponent as HelpDeskIcon } from '../Assest/Svg/HelpDeskIcon.svg'
import { ReactComponent as HrIcon } from '../Assest/Svg/HrIcon.svg'
import { ReactComponent as UserIcon } from '../Assest/Svg/UserIcon.svg'
// import { ReactComponent as ReportIcon } from '../Assest/Svg/ReportIcon.svg'
import { ReactComponent as ClientCommunicationIcon } from '../Assest/Svg/ClientCommunicationIcon.svg'
import { ReactComponent as WikiIcon } from '../Assest/Svg/WikiIcon.svg'
// import { ReactComponent as CompanyHouseIcon } from '../Assest/Svg/CompanyHouseIcon.svg'
// import { ReactComponent as HmrcIcon } from '../Assest/Svg/HmrcIcon.svg'
import { type CollapseType } from 'antd/es/layout/Sider'
import ActivePremission from '../Utils/premissins'
import { PermissionType } from '../Types/Enum/PermissionType'

type MenuItem = Required<MenuProps>['items'][number]

const getItem = (
    label: React.ReactNode,
    key: React.Key,
    icon?: React.ReactNode,
    children?: MenuItem[]
): MenuItem => {
    return {
        key,
        icon,
        children,
        label,
    }
}

type handleOnCollapseInterface = (a: boolean, b: CollapseType) => void

const { Sider } = Layout
const items: MenuItem[] = [
    getItem(
        <span className={styles.navText}>Dashboard</span>,
        'dashboard',
        <Dashboard />
    ),
    getItem(
        <span className={styles.navText}>System Configuration</span>,
        'systemConfiguration',
        <InitialSetup />,
        [
            ActivePremission('1000', PermissionType.VIEW)
                ? getItem(
                      <span className={styles.navText}>
                          - Group & Partners
                      </span>,
                      'groupAndPartners'
                  )
                : null,
            ActivePremission('1001', PermissionType.VIEW)
                ? getItem(
                      <span className={styles.navText}>- Bank Details</span>,
                      'bankDetails'
                  )
                : null,
            ActivePremission('1002', PermissionType.VIEW)
                ? getItem(
                      <span className={styles.navText}>- Departments</span>,
                      'departments'
                  )
                : null,
            ActivePremission('1003', PermissionType.VIEW)
                ? getItem(
                      <span className={styles.navText}>
                          - Staff Designations
                      </span>,
                      'staffDesignations'
                  )
                : null,
            ActivePremission('1004', PermissionType.VIEW)
                ? getItem(
                      <span className={styles.navText}>- Template Type</span>,
                      'templateType'
                  )
                : null,
            ActivePremission('1005', PermissionType.VIEW)
                ? getItem(
                      <span className={styles.navText}>- Leave Type</span>,
                      'leaveType'
                  )
                : null,
            ActivePremission('1006', PermissionType.VIEW)
                ? getItem(
                      <span className={styles.navText}>- Holiday</span>,
                      'holiday'
                  )
                : null,
            ActivePremission('1007', PermissionType.VIEW)
                ? getItem(
                      <span className={styles.navText}>- Work Flow</span>,
                      'workFlow'
                  )
                : null,
            ActivePremission('1008', PermissionType.VIEW)
                ? getItem(
                      <span className={styles.navText}>- Email Template</span>,
                      'emailTemplate'
                  )
                : null,
            getItem(
                <span className={styles.navText}>- Dashboard News</span>,
                'dashboardNews'
            ),
            getItem(
                <span className={styles.navText}>- Project Management</span>,
                'projectManagement'
            ),
        ]
    ),
    getItem(
        <span className={styles.navText}>Service Setup</span>,
        'serviceSetup',
        <ServiceSetup />,
        [
            ActivePremission('2000', PermissionType.VIEW)
                ? getItem(
                      <span className={styles.navText}>- Service User</span>,
                      'serviceUser'
                  )
                : null,
            ActivePremission('2001', PermissionType.VIEW)
                ? getItem(
                      <span className={styles.navText}>
                          - Client Service User
                      </span>,
                      'clientServiceUser'
                  )
                : null,
            getItem(
                <span className={styles.navText}>- Supplier</span>,
                'supplier'
            ),
        ]
    ),
    ActivePremission('3000', PermissionType.VIEW)
        ? getItem(
              <span className={styles.navText}>Clients</span>,
              'clients',
              <ClientIcon />,
              [
                  getItem(
                      <span className={styles.navText}>- Portfolio</span>,
                      'portfolioManager'
                  ),
                  getItem(
                      <span className={styles.navText}>
                          - Registered Clients
                      </span>,
                      'registeredClients'
                  ),
                  getItem(
                      <span className={styles.navText}>
                          - Onboarding Clients
                      </span>,
                      'onboardingClients'
                  ),
                  getItem(
                      <span className={styles.navText}>
                          - Prospect Clients
                      </span>,
                      'prospectClients'
                  ),
              ]
          )
        : null,
    getItem(
        <span className={styles.navText}>Missing Information</span>,
        'missingInformation',
        <MissingInformationIcon />,
        [
            // getItem(
            //     <span className={styles.navText}>- Awaiting Approval</span>,
            //     'awaitingApproval'
            // ),
            ActivePremission('5000', PermissionType.VIEW)
                ? getItem(
                      <span className={styles.navText}>- Property View</span>,
                      'propertyView'
                  )
                : null,
            // ActivePremission(22, PermissionType.VIEW)
            //     ? getItem(
            //           <span className={styles.navText}>- Client View</span>,
            //           'clientView'
            //       )
            //     : null,
        ]
    ),
    getItem(
        <span className={styles.navText}>Invoicing</span>,
        'invoicingMenu',
        <InvoiceIcon />,
        [
            ActivePremission('6000', PermissionType.VIEW)
                ? getItem(
                      <span className={styles.navText}>- Preparation</span>,
                      'invoicePreparation'
                  )
                : null,
            ActivePremission('6001', PermissionType.VIEW)
                ? getItem(
                      <span className={styles.navText}>- Status</span>,
                      'invoicing'
                  )
                : null,
            /* getItem(
                <span className={styles.navText}>- CC Report</span>,
                'creditControl',
                <></>,
                [
                    ActivePremission(31, PermissionType.VIEW)
                        ? getItem(
                              <span className={styles.navText}>
                                  - Status Report
                              </span>,
                              'statusReport'
                          )
                        : null,
                    ActivePremission(32, PermissionType.VIEW)
                        ? getItem(
                              <span className={styles.navText}>
                                  - Client Report
                              </span>,
                              'clientReport'
                          )
                        : null,
                    getItem(
                        <span className={styles.navText}>
                            - Invoice Type Report
                        </span>,
                        'invoiceTypeReport'
                    ),
                    getItem(
                        <span className={styles.navText}>- Unsend Report</span>,
                        'unsendReport'
                    ),
                    getItem(
                        <span className={styles.navText}>- Unpaid Report</span>,
                        'unpaidReport'
                    ),
                    getItem(
                        <span className={styles.navText}>- Paid Report</span>,
                        'paidReport'
                    ),
                ]
            ), */
        ]
    ),
    // getItem(
    //     <span className={styles.navText}>Ad Hoc Services</span>,
    //     'adHocServices',
    //     <AdHocServiceIcon />
    // ),
    getItem(
        <span className={styles.navText}>Help Desk</span>,
        'helpDesk',
        <HelpDeskIcon />,
        [
            ActivePremission('7000', PermissionType.VIEW)
                ? getItem(
                      <span className={styles.navText}>- Task</span>,
                      'createTask'
                  )
                : null,
            // ActivePremission(26, PermissionType.VIEW)
            //   ? getItem(
            //         <span className={styles.navText}>- My Task</span>,
            //          'myTask'
            //     )
            //    : null,
            getItem(
                <span className={styles.navText}>- Mobile Request</span>,
                'mobileRequest'
            ),
            getItem(
                <span className={styles.navText}>- Forms To Clients</span>,
                'formToClients'
            ),
            getItem(
                <span className={styles.navText}>- Manage Diary</span>,
                'manageDiary'
            ),

            // getItem(
            //     <span className={styles.navText}>- Backlog</span>,
            //     'backLog'
            // ),
        ]
    ),
    getItem(<span className={styles.navText}>HR</span>, 'hr', <HrIcon />, [
        getItem(
            <span className={styles.navText}>- Leave-Permission</span>,
            'leavePermission'
        ),
        getItem(
            <span className={styles.navText}>- Timesheet</span>,
            'timesheet'
        ),
        /* ActivePremission(33, PermissionType.VIEW)
            ? getItem(
                  <span className={styles.navText}>- Reports</span>,
                  'hrReports',
                  <></>,
                  [
                      getItem(
                          <span className={styles.navText}>- Timesheet</span>,
                          'reportTimesheet'
                      ),
                      getItem(
                          <span className={styles.navText}>- Leave</span>,
                          'reportLeave'
                      ),
                      getItem(
                          <span className={styles.navText}>- Permission</span>,
                          'reportPermission'
                      ),
                  ]
              )
            : null, */
    ]),
    ActivePremission('9000', PermissionType.VIEW)
        ? getItem(
              <span className={styles.navText}>User Management</span>,
              'userManagement',
              <UserIcon />,
              [
                  getItem(
                      <span className={styles.navText}>- Manage Users</span>,
                      'manageUsers'
                  ),
                  getItem(
                      <span className={styles.navText}>- User Rights</span>,
                      'userRights'
                  ),
              ]
          )
        : null,
    /* getItem(
        <span className={styles.navText}>Reports</span>,
        'reports',
        <ReportIcon />,
        [
            ActivePremission(29, PermissionType.VIEW)
                ? getItem(
                      <span className={styles.navText}>- Service-Status</span>,
                      'serviceStatus'
                  )
                : null,
            ActivePremission(30, PermissionType.VIEW)
                ? getItem(
                      <span className={styles.navText}>- Service-Client</span>,
                      'serviceClient'
                  )
                : null,
            getItem(
                <span className={styles.navText}>- Month Wise</span>,
                'monthWise'
            ),
            getItem(
                <span className={styles.navText}>- Missing Info</span>,
                'missingInfo'
            ),
            getItem(
                <span className={styles.navText}>- Client-Status</span>,
                'clientStatus'
            ),
            getItem(
                <span className={styles.navText}>- Onboarding</span>,
                'onboarding'
            ),
        ]
    ), */
    getItem(
        <span className={styles.navText}>Client Communication</span>,
        'clientCommunication',
        <ClientCommunicationIcon />,
        [
            ActivePremission('12000', PermissionType.VIEW)
                ? getItem(
                      <span className={styles.navText}>- Bulk Mail</span>,
                      'bulkMail'
                  )
                : null,
            ActivePremission('12001', PermissionType.VIEW)
                ? getItem(
                      <span className={styles.navText}>- Bulk SMS</span>,
                      'bulkSms'
                  )
                : null,
            ActivePremission('4006', PermissionType.VIEW)
                ? getItem(
                      <span className={styles.navText}>
                          - Other Business Associates
                      </span>,
                      'otherBusinessAssociates'
                  )
                : null,
        ]
    ),
    getItem(
        <span className={styles.navText}>WIKI</span>,
        'wiki',
        <WikiIcon />,
        [
            getItem(
                <span className={styles.navText}>- Article Group</span>,
                'articalGroup'
            ),
            getItem(
                <span className={styles.navText}>- Article</span>,
                'artical'
            ),
        ]
    ),
    // getItem(
    //     <span className={styles.navText}>Company House</span>,
    //     'companyHouse',
    //     <CompanyHouseIcon />,
    //     [
    //         getItem(
    //             <span className={styles.navText}>- Company Eligibility</span>,
    //             'companyEligibility'
    //         ),
    //         getItem(
    //             <span className={styles.navText}>- Company Formation</span>,
    //             'companyFormation'
    //         ),
    //         getItem(
    //             <span className={styles.navText}>- Company Overview</span>,
    //             'companyOverview'
    //         ),
    //         getItem(
    //             <span className={styles.navText}>
    //                 - Confirmation Statement Filing
    //             </span>,
    //             'confirmationStatementFiling'
    //         ),
    //         getItem(
    //             <span className={styles.navText}>- CH Filing History</span>,
    //             'chFilingHistory'
    //         ),
    //     ]
    // ),
    /* getItem(
        <span className={styles.navText}>HMRC</span>,
        'hmrc',
        <HmrcIcon />,
        [
            getItem(
                <span className={styles.navText}>- HMRC 64-8</span>,
                'hmrc648'
            ),
            getItem(
                <span className={styles.navText}>- VAT Validator</span>,
                'vatValidator'
            ),
        ]
    ), */
]

const SiderMenu = (props: {
    handleOnCollapse: handleOnCollapseInterface
    collapsed: boolean
}): React.ReactElement => {
    const theme = 'light'
    const navigate = useNavigate()

    const handleSiderMenuClick = (action: any): void => {
        switch (action.key) {
            case 'dashboard':
                navigate('/')
                break
            case 'groupAndPartners':
                navigate('/system-configuration/company-group')
                break
            case 'bankDetails':
                navigate('/system-configuration/payment-option')
                break
            case 'departments':
                navigate('/system-configuration/department')
                break
            case 'staffDesignations':
                navigate('/system-configuration/designation')
                break
            case 'templateType':
                navigate('/system-configuration/template-type')
                break
            case 'leaveType':
                navigate('/system-configuration/leave-type')
                break
            case 'holiday':
                navigate('/system-configuration/holiday')
                break
            case 'workFlow':
                navigate('/system-configuration/work-flow')
                break
            case 'emailTemplate':
                navigate('/system-configuration/email-template')
                break
            case 'dashboardNews':
                navigate('/system-configuration/news')
                break
            case 'supplierInitial':
                navigate('/system-configuration/supplier')
                break
            case 'otherPerson':
                navigate('/system-configuration/other-person')
                break
            case 'projectManagement':
                navigate('/system-configuration/project-management')
                break
            case 'serviceUser':
                // navigate('/ServiceConfiguration/ServiceSetup')
                navigate('/service-setup/service-user')
                break
            case 'clientServiceUser':
                // navigate('/ServiceConfiguration/ServiceClient')
                navigate('/service-setup/client-service-user')
                break
            case 'supplier':
                // navigate('/InitialSetup/Supplier')
                navigate('/service-setup/supplier')
                break
            case 'portfolioManager':
                // navigate('/ClientCompany/Index')
                navigate('/clients/portfolio-Manager')
                break
            case 'registeredClients':
                // navigate('/ClientCompany/Index')
                navigate('/clients/registered-clients')
                break
            case 'onboardingClients':
                // navigate('/ClientCompany/Onboard')
                navigate('/clients/onboarding-clients')
                break
            case 'prospectClients':
                // navigate('/Prospect/Index')
                navigate('/clients/prospect-clients')
                break
            case 'awaitingApproval':
                // navigate('/')
                navigate('/missing-information/awaiting-approval')
                break
            case 'propertyView':
                // navigate('/MissedInfo/index')
                navigate('/missing-information/property-view')
                break
            case 'clientView':
                // navigate('/MissedInfo/ClientView')
                navigate('/missing-information/client-view')
                break
            case 'invoicePreparation':
                // navigate('/Invoice/InvoicePreparation')
                navigate('/invoicing/invoice-preparation')
                break
            case 'invoicing':
                // navigate('/Invoice/Index')
                navigate('/invoicing/invoic-status')
                break
            case 'statusReport':
                // navigate('/Report/ReportParam?rt=3')
                navigate('/invoicing/credit-control/status-report')
                break
            case 'clientReport':
                // navigate('Report/ReportParam?rt=4')
                navigate('/invoicing/credit-control/client-report')
                break
            case 'invoiceTypeReport':
                // navigate('/Report/InvoiceServiceList')
                navigate('/invoicing/credit-control/invoice-type-report')
                break
            case 'unsendReport':
                // navigate('/Report/InvoiceUnsend')
                navigate('/invoicing/credit-control/unsend-report')
                break
            case 'unpaidReport':
                // navigate('/Report/InvoiceUnpaid')
                navigate('/invoicing/credit-control/unpaid-report')
                break
            case 'paidReport':
                // navigate('/Report/InvoicePaid')
                navigate('/invoicing/credit-control/paid-report')
                break
            case 'adHocServices':
                // navigate('/ServiceProcess/Other')
                navigate('/ad-hoc-services')
                break
            case 'createTask':
                // navigate('/Task/Index')
                navigate('/help-desk/create-task')
                break
            case 'myTask':
                // navigate('/Task/MyTask')
                navigate('/help-desk/my-task')
                break
            case 'mobileRequest':
                // navigate('/')
                navigate('/help-desk/mobile-request')
                break
            case 'formToClients':
                // navigate('/Management/FormAmendment')
                navigate('/help-desk/forms-to-clients')
                break
            case 'manageDiary':
                // navigate('/Management/ManageDiary')
                navigate('/help-desk/manage-diary')
                break
            case 'backLog':
                // navigate('/help-desk/back-log')
                navigate('/help-desk/back-log')
                break
            case 'leavePermission':
                // navigate('/Leave/index')
                navigate('/hr/leave-permission')
                break
            case 'timesheet':
                // navigate('/Timesheet/index')
                navigate('/hr/time-sheet')
                break
            case 'reportTimesheet':
                // navigate('/Report/ReportParam')
                navigate('/hr/reports/time-sheet')
                break
            case 'reportLeave':
                // navigate('/Report/Leave')
                navigate('/hr/reports/leave')
                break
            case 'reportPermission':
                // navigate('/Report/Permission')
                navigate('/hr/reports/permission')
                break
            case 'manageUsers':
                // navigate('/UserManagement/UserInformation')
                navigate('/user-management/manage-user')
                break
            case 'userRights':
                navigate('/user-management/user-rights')
                break
            case 'serviceStatus':
                // navigate('/Report/ReportParam?rt=1')
                navigate('/report/service-status')
                break
            case 'serviceClient':
                // navigate('/Report/ReportParam?rt=2')
                navigate('/report/service-client')
                break
            case 'monthWise':
                // navigate('/Home/MonthwiseSearch')
                navigate('/report/month-wise')
                break
            case 'missingInfo':
                navigate('/report/missing-info')
                break
            case 'clientStatus':
                navigate('/report/client-status')
                break
            case 'onboarding':
                navigate('/report/onboarding')
                break
            case 'bulkMail':
                // navigate('/Management/BulkMail')
                navigate('/client-comunication/bulk-mail')
                break
            case 'bulkSms':
                // navigate('/Management/BulkSMS')
                navigate('/client-comunication/bulk-sms')
                break
            case 'otherBusinessAssociates':
                // navigate('/InitialSetup/OtherPerson')
                navigate('/client-comunication/other-business-associatiates')
                break
            case 'articalGroup':
                navigate('/wiki/article-group')
                break
            case 'artical':
                navigate('/wiki/article')
                break
            case 'companyEligibility':
                // navigate('/CompanyHouse/Availability')
                navigate('/company-house/company-eligibility')
                break
            case 'companyFormation':
                // navigate('/CompanyHouse/CompanyList')
                navigate('/company-house/company-formation')
                break
            case 'companyOverview':
                // navigate('/ConfirmationStatement/ClientwiseIndividual')
                navigate('/company-house/company-overview')
                break
            case 'confirmationStatementFiling':
                // navigate('/ConfirmationStatement/Clientwise')
                navigate('/company-house/confimation-statement-filing')
                break
            case 'chFilingHistory':
                // navigate('/ConfirmationStatement/FilingHistory')
                navigate('/company-house/ch-filing-history')
                break
            case 'hmrc648':
                // navigate('/')
                navigate('/hrmc/hrmc-64-8')
                break
            case 'vatValidator':
                navigate('/hrmc/vat-validator')
                break
            default:
                navigate('/')
        }
    }

    const theme1 = {
        token: {
            colorPrimary: '#1890ff', // Primary color
            colorBgBase: '#ffffff', // Base background color
            colorTextBase: '#000000', // Base text color
            colorMenuItemSelected: '#98dcfd', // Selected menu item color
            colorMenuItemSelectedBg: '#6aa84f', // Background color of selected menu item
            colorMenuItemHover: '#e6f7ff', // Hover color for menu items
            colorMenuItemHoverBg: '#1890ff', // Hover background color for menu items
        },
    }
    return (
        <ConfigProvider theme={theme1}>
            <Sider
                breakpoint="lg"
                collapsedWidth="70"
                onCollapse={props.handleOnCollapse}
                collapsed={props.collapsed}
                width="320"
                theme={theme}
            >
                <Menu
                    onClick={handleSiderMenuClick}
                    defaultSelectedKeys={['1']}
                    defaultOpenKeys={['1']}
                    mode="inline"
                    items={items}
                />
            </Sider>
        </ConfigProvider>
    )
}

export default SiderMenu
