import React from 'react'
import { Col, Row } from 'antd'
import type { CorporateServicesVatView } from '../../../../../../Types/Client/RegisteredClients/Corporate'
import { formatdate } from '../../../../../../Utils/dateFormat'
export const PayeeDefaultData = {
    payeId: 0,
    companyType: 1,
    referenceId: 0,
    payeReference: '',
    accountOfficeReference: '',
    payrollFrequency: '',
    schemeStartDate: '01/01/1999', // Provide the default scheme start date here
    addressLineOne: '',
    addressLineTwo: '',
    town: '',
    country: '',
    postCode: '',
    gateWayId: '',
    password: '',
    status: '', // Provide the default status here
    csiFiling: 0,
    payeProcess: 0,
    filingMethod: 0,
    payeProcessName: '',
    filingMethodName: '',
    hmrcPaymentFrequency: 0,
    hmrcPaymentFrequencyName: '',
    payeProcessDate: '',
}
const PAYEEdit = (props: CorporateServicesVatView): React.ReactElement => {
    const { PAYEServiceData } = props
    return (
        <>
            <Row gutter={[24, 8]}>
                <Col span={6}>
                    <p>PAYE Reference</p>
                </Col>
                <Col span={8}>
                    <p>:{PAYEServiceData?.payeReference ?? ''}</p>
                </Col>
                <Col span={6}>
                    {' '}
                    <p>Address 2</p>
                </Col>
                <Col span={4}>
                    <p>:{PAYEServiceData?.addressLineTwo ?? ''}</p>
                </Col>
            </Row>
            <Row gutter={[24, 8]}>
                <Col span={6}>
                    {' '}
                    <p>Account Office Reference</p>
                </Col>
                <Col span={8}>
                    <p>:{PAYEServiceData?.accountOfficeReference ?? ''}</p>
                </Col>
                <Col span={6}>
                    <p>Town</p>
                </Col>
                <Col span={4}>
                    {' '}
                    <p>:{PAYEServiceData?.town ?? ''}</p>
                </Col>
            </Row>
            <Row gutter={[24, 8]}>
                <Col span={6}>
                    {' '}
                    <p>Payroll Frequency</p>
                </Col>
                <Col span={8}>
                    <p>:{PAYEServiceData?.payrollFrequency ?? ''}</p>
                </Col>
                <Col span={6}>
                    <p>Country</p>
                </Col>
                <Col span={4}>
                    <p>:{PAYEServiceData?.country ?? ''}</p>
                </Col>
            </Row>
            <Row gutter={[24, 8]}>
                <Col span={6}>
                    <p>Scheme Start Date</p>
                </Col>
                <Col span={8}>
                    {' '}
                    <p>:{formatdate(PAYEServiceData?.schemeStartDate) ?? ''}</p>
                </Col>
                <Col span={6}>
                    {' '}
                    <p>Post Code</p>
                </Col>
                <Col span={4}>
                    {' '}
                    <p>:{PAYEServiceData?.postCode ?? ''}</p>
                </Col>
            </Row>
            <Row gutter={[24, 8]}>
                <Col span={6}>
                    {' '}
                    <p>Address 1 </p>
                </Col>
                <Col span={8}>
                    <p>:{PAYEServiceData?.addressLineOne ?? ''}</p>
                </Col>
                <Col span={6}>
                    {' '}
                    <p>HMRC Gateway ID</p>
                </Col>
                <Col span={4}>
                    {' '}
                    <p>:{PAYEServiceData?.gateWayId ?? ''}</p>
                </Col>
            </Row>
            <Row gutter={[24, 8]}>
                <Col span={6}>
                    <p>CIS Filing</p>
                </Col>
                <Col span={8}>
                    <p>
                        :
                        {PAYEServiceData?.csiFiling === 0
                            ? 'Not Applicable'
                            : 'Applicable'}
                    </p>
                </Col>
                <Col span={6}>
                    <p>Password</p>
                </Col>
                <Col span={4}>
                    <p>:{PAYEServiceData?.password ?? ''}</p>
                </Col>
            </Row>
            <Row gutter={[24, 8]}>
                <Col span={6}>
                    <p>Paye Process Date</p>
                </Col>
                <Col span={8}>
                    <p>:{formatdate(PAYEServiceData?.payeProcessDate) ?? ''}</p>
                </Col>
                <Col span={6}>
                    <p>Filing Method</p>
                </Col>
                <Col span={4}>
                    {' '}
                    <p>:{PAYEServiceData?.filingMethodName ?? ''}</p>
                </Col>
            </Row>
            <Row gutter={[24, 8]}>
                <Col span={6}>
                    <p>HMRC Payment Frequency</p>
                </Col>
                <Col span={8}>
                    <p>:{PAYEServiceData?.hmrcPaymentFrequencyName ?? ''}</p>
                </Col>
                <Col span={6}></Col>
                <Col span={4}></Col>
            </Row>
        </>
    )
}

export default PAYEEdit
