import { type PayloadAction, createSlice } from '@reduxjs/toolkit'
import {
    type viewallPayloadInterface,
    type Data,
    type MessagePayloadInterface,
    type NotificationTableList,
} from '../Types/Notifications'

interface InitailStateInterface {
    messageList: Data | null
    notificationlist: NotificationTableList[]
    notificationListCount: number
}
const NotificationReducer = createSlice({
    name: 'notification',
    initialState: {
        messageList: null,
        notificationlist: [],
        notificationListCount: 0,
    },
    reducers: {
        messageList(
            state: InitailStateInterface,
            action: PayloadAction<MessagePayloadInterface>
        ) {
            state.messageList = action?.payload?.data
        },
        notificationlist(
            state: InitailStateInterface,
            action: PayloadAction<viewallPayloadInterface>
        ) {
            state.notificationlist = action?.payload?.data
            state.notificationListCount = action?.payload?.noOfRecords
        },
    },
})

export const { messageList, notificationlist } = NotificationReducer.actions
export default NotificationReducer.reducer
