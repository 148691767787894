import { type PayloadAction, createSlice } from '@reduxjs/toolkit'
import { type CorporateEntity } from '../Types/Globelserch'
import type {
    ListFrontEndInterface,
    LoadingStatus,
    SuccessStatus,
} from '../Types/CommonType'
import {
    type GetEnvelopePayLoad,
    type GetEnvelopeData,
} from '../Types/Client/RegisteredClients/Corporate'

interface InitailStateInterface {
    languageList: ListFrontEndInterface[]
    countryList: ListFrontEndInterface[]
    emailTemplateTypeList: ListFrontEndInterface[]
    contentPropertiesList: ListFrontEndInterface[]
    accessLevelList: ListFrontEndInterface[]
    documenttypeList: ListFrontEndInterface[]
    addressproofList: ListFrontEndInterface[]
    isLoading: boolean
    isSuccess: boolean
    InduvidualMemberList: ListFrontEndInterface[]
    servicePrcessEmailTemplate: ListFrontEndInterface[]
    regiterClientDetails: ListFrontEndInterface[]
    clientDropDownList: CorporateEntity[]
    formtoCLientEmailList: ListFrontEndInterface[]
    appendFormList: ListFrontEndInterface[]
    getEnvelopData: GetEnvelopeData[]
}

const CommonReducer = createSlice({
    name: 'common',
    initialState: {
        countryList: [],
        emailTemplateTypeList: [],
        contentPropertiesList: [],
        accessLevelList: [],
        languageList: [],
        isLoading: false,
        documenttypeList: [],
        isSuccess: false,
        addressproofList: [],
        InduvidualMemberList: [],
        servicePrcessEmailTemplate: [],
        regiterClientDetails: [],
        clientDropDownList: [],
        formtoCLientEmailList: [],
        appendFormList: [],
        getEnvelopData: [],
    },
    reducers: {
        emailTemplateTypeList(
            state: InitailStateInterface,
            action: PayloadAction<ListFrontEndInterface[]>
        ) {
            state.emailTemplateTypeList = action?.payload
        },

        contentPropertiesList(
            state: InitailStateInterface,
            action: PayloadAction<ListFrontEndInterface[]>
        ) {
            state.contentPropertiesList = action?.payload
        },
        countryList(
            state: InitailStateInterface,
            action: PayloadAction<ListFrontEndInterface[]>
        ) {
            state.countryList = action?.payload
        },
        acessLevelList(
            state: InitailStateInterface,
            action: PayloadAction<ListFrontEndInterface[]>
        ) {
            state.accessLevelList = action?.payload
        },
        setLoadingStatus(
            state: InitailStateInterface,
            action: PayloadAction<LoadingStatus>
        ) {
            state.isLoading = action?.payload?.status
        },
        documenttypeList(
            state: InitailStateInterface,
            action: PayloadAction<ListFrontEndInterface[]>
        ) {
            state.documenttypeList = action?.payload
        },
        addressproofList(
            state: InitailStateInterface,
            action: PayloadAction<ListFrontEndInterface[]>
        ) {
            state.addressproofList = action?.payload
        },
        languageList(
            state: InitailStateInterface,
            action: PayloadAction<ListFrontEndInterface[]>
        ) {
            state.languageList = action?.payload
        },
        setSuccess(
            state: InitailStateInterface,
            action: PayloadAction<SuccessStatus>
        ) {
            state.isSuccess = action?.payload?.status
        },
        InduvidualMemberList(
            state: InitailStateInterface,
            action: PayloadAction<ListFrontEndInterface[]>
        ) {
            state.InduvidualMemberList = action?.payload
        },
        servicePrcessEmailTemplate(
            state: InitailStateInterface,
            action: PayloadAction<ListFrontEndInterface[]>
        ) {
            state.servicePrcessEmailTemplate = action?.payload
        },
        regiterClientDetails(
            state: InitailStateInterface,
            action: PayloadAction<ListFrontEndInterface[]>
        ) {
            state.regiterClientDetails = action?.payload
        },
        clientDropDownList(
            state: InitailStateInterface,
            action: PayloadAction<CorporateEntity[]>
        ) {
            state.clientDropDownList = action?.payload
        },
        formtoCLientEmailList(
            state: InitailStateInterface,
            action: PayloadAction<ListFrontEndInterface[]>
        ) {
            state.formtoCLientEmailList = action?.payload
        },
        appendFormList(
            state: InitailStateInterface,
            action: PayloadAction<ListFrontEndInterface[]>
        ) {
            state.appendFormList = action?.payload
        },
        getEnvelopData(
            state: InitailStateInterface,
            action: PayloadAction<GetEnvelopePayLoad>
        ) {
            state.getEnvelopData = action?.payload?.data
        },
    },
})

export const {
    countryList,
    setLoadingStatus,
    emailTemplateTypeList,
    contentPropertiesList,
    acessLevelList,
    documenttypeList,
    addressproofList,
    languageList,
    setSuccess,
    InduvidualMemberList,
    servicePrcessEmailTemplate,
    regiterClientDetails,
    formtoCLientEmailList,
    appendFormList,
    getEnvelopData,
} = CommonReducer.actions
export default CommonReducer.reducer
