import React from 'react'

interface PDFPreviewProps {
    pdfUrl: string
}

const PDFPreview = (props: PDFPreviewProps): React.ReactElement => {
    const { pdfUrl } = props

    return (
        <iframe
            src={pdfUrl}
            width="90%"
            height="600px"
            style={{ border: 'none' }}
        />
    )
}

export default PDFPreview
