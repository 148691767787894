import React, { useEffect } from 'react'
import { Col, Row, Input, Form, Button } from 'antd'
import commontStyles from '../../../Utils/Common.less'
import { SaveOutlined } from '@ant-design/icons'
import type {
    SupplierFormDataInterface,
    SupplierFormInterface,
} from '../../../Types/Supplier'
import { SupplierItemDefaultData } from './supplierDetailsList'

const AddEditSupplierDetailsForm = (
    props: SupplierFormInterface
): React.ReactElement => {
    const { onSave, editData } = props
    const [form] = Form.useForm()

    useEffect(() => {
        form.setFieldsValue(editData)
    }, [editData])

    const resetForm = (): void => {
        form.setFieldsValue(SupplierItemDefaultData)
    }

    const customizeRequiredMark = (
        label: React.ReactNode,
        { required }: { required: boolean }
    ): React.ReactElement => (
        <>
            {label}
            &nbsp;
            {required && <span className={commontStyles.requireIcon}>*</span>}
        </>
    )

    return (
        <div className={commontStyles.formWrapper}>
            <Form
                name="complex-form"
                labelCol={{ span: 16 }}
                wrapperCol={{ span: 16 }}
                initialValues={{ remember: true }}
                layout={'vertical'}
                autoComplete="off"
                onFinish={(data) => {
                    onSave(data, resetForm)
                }}
                form={form}
                requiredMark={customizeRequiredMark}
            >
                <Form.Item<SupplierFormDataInterface>
                    name="imgPath"
                    hidden={true}
                >
                    <Input />
                </Form.Item>
                <Form.Item<SupplierFormDataInterface>
                    name="supplierId"
                    hidden={true}
                >
                    <Input />
                </Form.Item>
                <Row gutter={16}>
                    <Col span={18}>
                        <Form.Item<SupplierFormDataInterface>
                            label="Supplier Name"
                            name="supplierName"
                            rules={[
                                { required: true, message: 'Supplier Name!' },
                            ]}
                        >
                            <Input />
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={16}>
                    <Col offset={20} span={2}>
                        <Button type="primary" htmlType="submit">
                            <SaveOutlined />
                        </Button>
                    </Col>
                </Row>
            </Form>
        </div>
    )
}

export default AddEditSupplierDetailsForm
