import { type Dispatch } from '@reduxjs/toolkit'
import axios from '../Config/AxiosConfig'
import { userRightsList } from '../Reducer/UserRightsReducer'
import { ErrorMessage, SuccessMessage } from '../Utils/Notification'
import {
    type ApiResponse,
    // UserRightsFormDataInterface,
    // UserRightsPayloadInterface,
} from '../Types/UserRights'
import { setLoadingStatus } from '../Reducer/Common'

export const SaveUserRights = async (
    dispatch: Dispatch,
    data: ApiResponse,
    userId: number
): Promise<boolean> => {
    dispatch(setLoadingStatus({ status: true }))
    return await axios
        .post(`/api/UserManagement/SetUserRights/${userId}`, data)
        .then((response) => {
            dispatch(setLoadingStatus({ status: false }))
            if (response.status === 200) {
                SuccessMessage(response.data.operationMsg)
                return true
            } else {
                ErrorMessage(response.data.operationMsg)
                return false
            }
        })
        .catch((error) => {
            dispatch(setLoadingStatus({ status: false }))
            ErrorMessage(error.response.data.operationMsg)
            console.error('Error fetching posts:', error)
            return false
        })
}

export const GetUserRights = async (
    dispatch: Dispatch,
    userId: number
): Promise<any> => {
    dispatch(setLoadingStatus({ status: true }))
    return await axios
        .get(`/api/UserManagement/GetUserRights/${userId}`)
        .then((response) => {
            if (response.status === 200) {
                dispatch(userRightsList({ ...response.data }))
                return response.data
            }
        })
        .catch((error) => {
            console.error('Error fetching get:', error)
            throw error
        })
        .finally(() => {
            dispatch(setLoadingStatus({ status: false }))
        })
}
