import React, { useEffect } from 'react'
import { Col, Row, Input, Form, Button, Select } from 'antd'
import { connect, useDispatch } from 'react-redux'
import commonStyles from '../../../Utils/Common.less'
import { SaveOutlined } from '@ant-design/icons'
import type {
    DepartmentFormDataInterface,
    DepartmentFormInterface,
    DepartmentFormStateInterface,
} from '../../../Types/Department'
import type { ListFrontEndInterface } from '../../../Types/CommonType'
import { CheckDepartmentNameExist } from '../../../Services/Department'
import { DepartmentItemDefaultData } from './departmentList'
import ActivePremission from '../../../Utils/premissins'
import { PermissionType } from '../../../Types/Enum/PermissionType'
const { TextArea } = Input

const DepartmentForm = (props: DepartmentFormInterface): React.ReactElement => {
    const { onSave, editData, categoryDropDownList } = props
    const dispatch = useDispatch()
    const [form] = Form.useForm()
    let timeout: any

    useEffect(() => {
        form.setFieldValue('departmentId', '0')
    }, [])

    useEffect(() => {
        const clientCategoryData = categoryDropDownList.find(
            (data: ListFrontEndInterface) =>
                data.label === editData.clientCategoryName
        )
        const newData = {
            ...editData,
            clientCategoryId: clientCategoryData?.value ?? null,
        }
        form.setFieldsValue(newData)
    }, [editData])

    const resetForm = (): void => {
        form.setFieldsValue({
            DepartmentItemDefaultData,
            clientCategoryId: null,
            departmentName: undefined,
        })
    }

    const onNamechange = (event: any): void => {
        const clientCategoryId = form.getFieldValue('clientCategoryId') ?? null

        clearTimeout(timeout)
        timeout = setTimeout(() => {
            CheckDepartmentNameExist(
                dispatch,
                editData?.departmentId,
                clientCategoryId,
                event?.target?.value
            )
        }, 1000)
    }

    const customizeRequiredMark = (
        label: React.ReactNode,
        { required }: { required: boolean }
    ): React.ReactElement => (
        <>
            {label}
            &nbsp;
            {required && <span className={commonStyles.requireIcon}>*</span>}
        </>
    )

    return (
        <div className={commonStyles.formWrapper}>
            <Form
                name="complex-form"
                labelCol={{ span: 24 }}
                wrapperCol={{ span: 24 }}
                initialValues={{ remember: true }}
                layout={'vertical'}
                autoComplete="off"
                onFinish={(data) => {
                    onSave(data, resetForm)
                }}
                form={form}
                requiredMark={customizeRequiredMark}
            >
                <Form.Item<DepartmentFormDataInterface>
                    name="departmentId"
                    hidden={true}
                >
                    <Input />
                </Form.Item>
                <Row gutter={16}>
                    <Col span={24}>
                        <Form.Item<DepartmentFormDataInterface>
                            label="Company Group"
                            name="clientCategoryId"
                            rules={[
                                {
                                    required: true,
                                    message: 'Please select the company group!',
                                },
                            ]}
                        >
                            <Select
                                showSearch
                                allowClear
                                placeholder="Please select"
                                onChange={() => {}}
                                filterOption={(input, option) =>
                                    !(option == null) &&
                                    option.label
                                        .toLowerCase()
                                        .includes(input.toLowerCase())
                                }
                                options={[
                                    { label: 'Please select', value: null },
                                    ...categoryDropDownList,
                                ]}
                            />
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={16}>
                    <Col span={24}>
                        <Form.Item<DepartmentFormDataInterface>
                            label="Department"
                            name="departmentName"
                            rules={[
                                {
                                    required: true,
                                    message:
                                        'Please input your department name!',
                                },
                            ]}
                        >
                            <Input onChange={onNamechange} />
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={16}>
                    <Col span={24}>
                        <Form.Item<DepartmentFormDataInterface>
                            label="Description"
                            name="description"
                        >
                            <TextArea rows={4} />
                        </Form.Item>
                    </Col>
                </Row>
                {ActivePremission('1002', PermissionType.SAVE) && (
                    <Row gutter={16}>
                        <Col offset={22} span={2}>
                            <Button type="primary" htmlType="submit">
                                <SaveOutlined />
                            </Button>
                        </Col>
                    </Row>
                )}
            </Form>
        </div>
    )
}

const mapStateToProps = (state: any): DepartmentFormStateInterface => {
    return {
        categoryDropDownList: state.initial.categoryDropDownList,
    }
}

export default connect(mapStateToProps)(DepartmentForm)
