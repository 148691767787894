import React from 'react'
import { Row, Col, Table } from 'antd'
import commonStyles from '../../../Utils/Common.less'
import { type ApplyExpandViewType } from '../../../Types/Hr/LeavePermission'

const ApplyExpandView = (props: ApplyExpandViewType): React.ReactElement => {
    const { listsData } = props
    const formatdate = (date: string | undefined): any => {
        if (date != null) {
            const dateObject = new Date(date)
            const year = dateObject.getFullYear()
            const month = dateObject.getMonth() + 1
            const day = dateObject.getDate()
            const formattedDate = `${month}/${day}/${year}`
            return formattedDate
        }
    }
    const columns = [
        {
            title: 'Level Name',
            dataIndex: 'levelName',
            key: 'levelName',
        },
        {
            title: 'Approver Name',
            dataIndex: 'approverName',
            key: 'approverName',
        },
        {
            title: 'Date',
            dataIndex: 'approvedDate',
            key: 'approvedDate',
        },
        {
            title: 'Approval Status',
            dataIndex: 'approvalStatus',
            key: 'approvalStatus',
        },
        {
            title: 'Remarks',
            dataIndex: 'remarks',
            key: 'remarks',
        },
    ]

    return (
        <div className={commonStyles.expandCard}>
            <Row>
                <Col span={24}>
                    <div>
                        Covering Date: {formatdate(listsData.coveringFrom)}
                    </div>
                </Col>
                <Col span={24}>
                    <div>
                        Covering Start Time: {listsData.coveringStartTime}
                    </div>
                </Col>
                <Col span={24}>
                    <div>Covering End Time: {listsData.coveringEndTime}</div>
                </Col>
                <Col span={24}>
                    <div>
                        Approved History:
                        <div style={{ marginTop: '20px', marginLeft: '10px' }}>
                            <Table
                                columns={columns}
                                dataSource={listsData.approvalHistoryList}
                                pagination={false}
                                rowKey={(record) =>
                                    record.workflowName + record.approverName
                                }
                                bordered
                            />
                        </div>
                    </div>
                </Col>
            </Row>
        </div>
    )
}

export default ApplyExpandView
