import React, { useState } from 'react'
import {
    Col,
    Row,
    Form,
    Switch,
    Flex,
    Space,
    DatePicker,
    type DatePickerProps,
} from 'antd'
import commonStyles from '../../../Utils/Common.less'
import { CheckOutlined, CloseSquareOutlined } from '@ant-design/icons'
import FullWidthModal from '../../../Components/FullWidthModal'
import CusttomBackgroundButton from '../../../Components/CustomBagraoundButton'
import dayjs from 'dayjs'

const RemovePscOfficer = (): React.ReactElement => {
    const [form] = Form.useForm()
    const [swithModalStatus, setSwithModelStatus] = useState(false)
    const [selectedDateAp, setSelectedDateAp] = useState<string>('')
    const ChangeModalFunction = (): void => {
        setSwithModelStatus(!swithModalStatus)
    }

    const changeCompanyHouse = (): void => {
        ChangeModalFunction()
    }
    const onChangeDateAppoint = (
        dateofAppointment: DatePickerProps['value'],
        dateString: string | string[]
    ): void => {
        if (dateofAppointment != null) {
            const isoDate = dateofAppointment.toISOString()
            setSelectedDateAp(isoDate)
        }
    }

    const customizeRequiredMark = (
        label: React.ReactNode,
        { required }: { required: boolean }
    ): React.ReactElement => (
        <>
            {label}
            &nbsp;
            {required && <span className={commonStyles.requireIcon}>*</span>}
        </>
    )

    return (
        <>
            <div className={commonStyles.formWrapper}>
                <Form
                    name="complex-form"
                    labelCol={{ span: 24 }}
                    wrapperCol={{ span: 24 }}
                    initialValues={{ remember: true }}
                    layout={'vertical'}
                    autoComplete="off"
                    form={form}
                    requiredMark={customizeRequiredMark}
                >
                    <Row gutter={16}>
                        <Col span={10}>Company Name</Col>
                        <Col span={2}>:</Col>
                        <Col span={8}></Col>
                    </Row>
                    <Row gutter={16}>
                        <Col span={10}>Reg. Number</Col>
                        <Col span={2}>:</Col>
                        <Col span={8}></Col>
                    </Row>
                    <Row gutter={16}>
                        <Col span={10}>Type Of PSC</Col>
                        <Col span={2}>:</Col>
                        <Col span={8}></Col>
                    </Row>
                    <Row gutter={16}>
                        <Col span={10}>PSC&apos;s Name</Col>
                        <Col span={2}>:</Col>
                        <Col span={8}></Col>
                    </Row>
                    <Row gutter={16}>
                        <Col span={10}>Date of Birth</Col>
                        <Col span={2}>:</Col>
                        <Col span={8}></Col>
                    </Row>
                    <Row gutter={16}>
                        <Col span={12}>
                            <Form.Item
                                label="Date the person ceased to be a PSC"
                                name="dateOfBirth"
                            >
                                <Space direction="vertical" size={12}>
                                    <DatePicker
                                        className={commonStyles.dateWidth}
                                        onChange={(date, dateString) => {
                                            onChangeDateAppoint(
                                                date,
                                                dateString
                                            )
                                        }}
                                        value={
                                            selectedDateAp.length > 0
                                                ? dayjs(selectedDateAp)
                                                : null
                                        }
                                        format="M/D/YYYY"
                                    />
                                </Space>
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item
                                label="Register entry date"
                                name="dateOfBirth"
                            >
                                <Space direction="vertical" size={12}>
                                    <DatePicker
                                        className={commonStyles.dateWidth}
                                        onChange={(date, dateString) => {
                                            onChangeDateAppoint(
                                                date,
                                                dateString
                                            )
                                        }}
                                        value={
                                            selectedDateAp.length > 0
                                                ? dayjs(selectedDateAp)
                                                : null
                                        }
                                        format="M/D/YYYY"
                                    />
                                </Space>
                            </Form.Item>
                        </Col>
                    </Row>

                    <Row>
                        <Col span={24}>
                            <Switch />{' '}
                            <span style={{ marginLeft: 5 }}>
                                Select this option, If you are need to apply
                                this changes to companies house.
                            </span>
                        </Col>
                    </Row>
                    <div style={{ marginTop: '20px' }}>
                        <Flex justify="flex-end" gap={5}>
                            <CusttomBackgroundButton
                                color={'#868e96'}
                                name={'Cancel'}
                                icon={<CloseSquareOutlined />}
                            />
                            <CusttomBackgroundButton
                                color={'#1dc9b7'}
                                name={'Submit Changes'}
                                icon={<CheckOutlined />}
                            />
                        </Flex>
                    </div>
                </Form>
            </div>
            <FullWidthModal
                modalStatus={swithModalStatus}
                closeModal={ChangeModalFunction}
                tittle="You are about to submit this changes to Company House. Click 'Yes' to continue."
                smallTittle="Warning: This action cannot be undone!"
                yesButtonFunction={changeCompanyHouse}
            />
        </>
    )
}

export default RemovePscOfficer
