import { Button, Card, Col, Collapse, Flex, Modal, Row } from 'antd'
import React, { useState } from 'react'
import roleStyle from '../../Clients/RegisteredClients/Corporate/Buttons/OfficerButton/ofiicer.less'
import style from '../../ThingToDoViewAll/tingtodo.less'
import CusttomBackgroundButton from '../../../Components/CustomBagraoundButton'
import { DeleteOutlined, PlusOutlined, EditOutlined } from '@ant-design/icons'
import RemovePscOfficer from '../CompanyHouseRelatedThings/removePscofficer'
interface CompanyDetailsprops {
    companyHousId?: number
}
const PSCPage = (props: CompanyDetailsprops): React.ReactElement => {
    const { companyHousId } = props
    const [TerminateModalStatus, setTerminateModalStatus] = useState(false)
    const [createEditModalStatus, setCreateEditModalStatus] = useState(false)
    const [editModalTittle, setEditModalTittle] = useState(
        'Create Officer Details'
    )
    const getRoleBackgroundColor = (role: string): React.ReactNode => {
        if (role !== '' && typeof role === 'string') {
            const roles = role.split(',')?.map((r) => r.trim())

            return (
                <div className={roleStyle.roleBadge}>
                    {roles?.map((r, index) => (
                        <span
                            key={index}
                            className={`${roleStyle.roleSpan} ${
                                roleStyle[r.toLowerCase()] ??
                                roleStyle.defaultColor
                            }`}
                        >
                            {r.substring(0, 3)}
                        </span>
                    ))}
                </div>
            )
        }
    }

    const openEditModal = (id: string): void => {
        // const data = bankingDetailsList.find(
        //     (data: BankDetailsFormDataInterface) => {
        //         return data.paymentOptionId === id
        //     }
        // )

        let tittle = 'Create Officer Details'
        if (id.length > 0) {
            tittle = 'Edit Officer Details'
        }

        setEditModalTittle(tittle)
        // setEditData(data ?? BankDetailsItemDefaultData)
        changeCreateEditModalStatus()
    }

    const changeCreateEditModalStatus = (): void => {
        setCreateEditModalStatus(!createEditModalStatus)
    }

    const OpenTerminateOfficer = (): void => {
        setTerminateModalStatus(!TerminateModalStatus)
    }

    const changeTerminateModalStatus = (): void => {
        setTerminateModalStatus(!TerminateModalStatus)
    }
    return (
        <>
            <div>
                <h3>PSC &apos; s</h3>
            </div>
            <Card>
                {companyHousId === 1 && (
                    <>
                        <div className={style.misstingInfo}>
                            If you want to do changes required in
                            PSC&apos;s&apos; should be filed using respectively
                            the{' '}
                            <b>
                                &quot;Edit / Remove / Add PSC&apos;s&apos;&quot;
                            </b>
                            pages before you file the confirmation statement.
                        </div>
                        <div
                            style={{ marginTop: '15px', marginBottom: '15px' }}
                        >
                            <Flex justify="flex-end" gap={5}>
                                <CusttomBackgroundButton
                                    color={'#175ca9'}
                                    name={'Add New PSC'}
                                    icon={<PlusOutlined />}
                                />
                            </Flex>
                        </div>
                    </>
                )}
                <Collapse defaultActiveKey={['']}>
                    <Collapse.Panel
                        header={
                            <div
                                style={{
                                    display: 'flex',
                                    justifyContent: 'space-between',
                                }}
                            >
                                <span>{`Deveslk`}</span>
                                <span>{getRoleBackgroundColor('Dev')}</span>
                            </div>
                        }
                        key={1}
                    >
                        {companyHousId === 1 && (
                            <>
                                <div
                                    style={{
                                        marginTop: '15px',
                                        marginBottom: '15px',
                                    }}
                                >
                                    <Flex justify="flex-end" gap={5}>
                                        <Button
                                            type="primary"
                                            size="middle"
                                            danger
                                            icon={<DeleteOutlined />}
                                            onClick={OpenTerminateOfficer}
                                        >
                                            Remove PSC Officer
                                        </Button>

                                        <CusttomBackgroundButton
                                            color={'#175ca9'}
                                            name={'Edit PSC Details'}
                                            icon={<EditOutlined />}
                                            clickAction={() => {
                                                openEditModal('1')
                                            }}
                                        />
                                    </Flex>
                                </div>
                                <Row>
                                    <Col>
                                        <h3>Initial Details</h3>
                                    </Col>
                                </Row>
                                <Row gutter={[16, 16]}>
                                    <Col span={5}>Residence Country</Col>
                                    <Col span={8}>:</Col>
                                    <Col span={5}>Date of Birth</Col>
                                    <Col span={6}>:</Col>
                                </Row>
                                <Row gutter={[16, 16]}>
                                    <Col span={5}>Nationality</Col>
                                    <Col span={8}>:</Col>
                                    <Col span={5}>Corr.Address</Col>
                                    <Col span={6}>:</Col>
                                </Row>
                                <Row gutter={[16, 16]}>
                                    <Col span={5}>Home Address</Col>
                                    <Col span={8}>:</Col>
                                    <Col span={5}>Notification Date</Col>
                                    <Col span={6}>:</Col>
                                </Row>
                                <Row gutter={[16, 16]}>
                                    <Col span={5}>Register Entry Date</Col>
                                    <Col span={8}>:</Col>
                                </Row>
                                <Row>
                                    <Col>
                                        <div>
                                            <h2>Ownership of shares</h2>
                                        </div>
                                        <div>
                                            The Person Holds, directly or
                                            indirectly, 75% or more of shares
                                        </div>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col>
                                        <div>
                                            <h2>Ownership of voting rights</h2>
                                        </div>
                                        <div>
                                            The person holds, directly or
                                            indirectly, 75% or more of voting
                                            rights
                                        </div>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col>
                                        <div>
                                            <h2>
                                                Right to appoint or remove the
                                                majority of the board of
                                                directors
                                            </h2>
                                        </div>
                                        <div>
                                            The person has the right to appoint
                                            or remove the majority of the board
                                            of directors of the company
                                        </div>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col>
                                        <div>
                                            <h2>
                                                Has significant influence or
                                                control
                                            </h2>
                                        </div>
                                        <div></div>
                                    </Col>
                                </Row>
                            </>
                        )}
                    </Collapse.Panel>
                </Collapse>
            </Card>
            <Modal
                footer={null}
                onCancel={changeTerminateModalStatus}
                width={'50%'}
                title={'Termination PSC'}
                open={TerminateModalStatus}
            >
                <RemovePscOfficer />
            </Modal>
            <Modal
                footer={null}
                onCancel={changeCreateEditModalStatus}
                width={'50%'}
                title={editModalTittle}
                open={createEditModalStatus}
            ></Modal>
        </>
    )
}

export default PSCPage
