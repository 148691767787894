import React, { useEffect, useState } from 'react'
import { Card, Col, Row, Form, Input, Button, Table } from 'antd'
import commonStyles from '../../../../Utils/Common.less'
import 'react-quill/dist/quill.snow.css'
import { Quill } from 'react-quill'
import { SaveOutlined, CloudDownloadOutlined } from '@ant-design/icons'
import { useLocation, useNavigate } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import {
    GetSendEmailInvoiceDetails,
    SaveEmail,
} from '../../../../Services/Invoice'
import { type InvoiceEmailData } from '../../../../Types/Invoice/Invoicing'
import S3UploadMultiple from '../../../../Components/S3UploadMultiple'
import ReactQuillComponet from '../../../../Components/ReactQillComponet'

const extractTextFromHtml = (htmlString: any): string => {
    const tempDivElement = document.createElement('div')
    tempDivElement.innerHTML = htmlString
    return tempDivElement.innerHTML
}

const Inline = Quill.import('blots/inline')

class BoldBlot extends Inline {}
BoldBlot.blotName = 'bold'
BoldBlot.tagName = 'strong'
Quill.register(BoldBlot, true)

class ItalicBlot extends Inline {}
ItalicBlot.blotName = 'italic'
ItalicBlot.tagName = 'em'
Quill.register(ItalicBlot, true)

const SendEmailInvoice = (): React.ReactElement => {
    const location = useLocation()
    const invoiceIds = location.state.invoiceIds
    const [form] = Form.useForm()
    const dispatch = useDispatch()
    const [emailText, setEmailText] = useState('')
    const [data, setData] = useState<InvoiceEmailData>()
    const [uploadFileList, setUploadFileList] = useState<string[]>([])
    const navigate = useNavigate()
    useEffect(() => {
        const GetEmailDeatils = async (): Promise<void> => {
            const emaildata = await GetSendEmailInvoiceDetails(
                dispatch,
                invoiceIds
            )
            setData(emaildata)
            form.setFieldsValue(emaildata)
            const description = extractTextFromHtml(emaildata?.description)
            const task = convertHtmlToText(description)
            setEmailText(task)
        }
        void GetEmailDeatils()
    }, [invoiceIds])
    const customizeRequiredMark = (
        label: React.ReactNode,
        { required }: { required: boolean }
    ): React.ReactElement => (
        <>
            {label}
            &nbsp;
            {required && <span className={commonStyles.requireIcon}>*</span>}
        </>
    )

    const onSave = (): void => {
        void onSaveEmail()
    }
    const onSaveEmail = async (): Promise<void> => {
        try {
            await form.validateFields()

            const formData = form.getFieldsValue()

            // const dataTosave = {
            //     clientEmailId: 0,
            //     templateId: data?.templateId ?? 0,
            //     serviceProcessId: 0,
            //     companyTypeId: 6,
            //     referenceId: 0,
            //     serviceActionId: 0,
            //     serviceActionStepId: 0,
            //     fromAddress: 'info@iykons.com',
            //     toAddress: formData.toAddress,
            //     ccAddress: formData.ccAddress,
            //     bccAddress: formData.bccAddress,
            //     subject: formData.subject,
            //     description: emailText,
            //     emailStatus: 'Sucsss',
            //     serviceId: 0,
            //     contactName: data?.contactName ?? '',
            //     invoiceId: invoiceIds,
            // }
            const dataTosave = {
                clientEmailId: 0,
                templateId: data?.templateId ?? 0,
                invoiceId: invoiceIds,
                companyTypeId: 6,
                referenceId: 0,
                serviceActionId: 0,
                serviceActionStepId: 0,
                fromAddress: 'info@iykons.com',
                toAddress: formData.toAddress,
                ccAddress: formData.ccAddress,
                bccAddress: formData.bccAddress,
                subject: formData.subject,
                description: emailText,
                emailStatus: 'Sucsss',
                serviceId: 0,
                fromName: data?.contactName ?? '',
                clientEmailTypeId: 16,
                uniqueCode: '',
            }

            const htmlMessage = 'Invoice'
            await SaveEmail(dispatch, dataTosave, uploadFileList, htmlMessage)

            form.resetFields()
            navigate(`/invoicing/invoic-status`)
        } catch (error) {
            console.error('Error:', error)
        }
    }
    const convertHtmlToText = (html: string): string => {
        const doc = new DOMParser().parseFromString(html, 'text/html')
        return doc.body.textContent ?? ''
    }
    const dataSource = [
        {
            key: '1',
            name: 'Invoice',
            imagepath: data?.invoicePath,
        },
    ]
    const columns = [
        {
            title: 'Documnet',
            dataIndex: 'name',
            key: 'name',
        },
        {
            title: '',
            key: 'imagepath',
            render: (record: { imagepath: string }) => {
                return (
                    <div>
                        <a
                            href={record.imagepath}
                            download={`Invoice_${invoiceIds}_INV.pdf`}
                            target="_blank"
                            rel="noreferrer"
                        >
                            <CloudDownloadOutlined />
                        </a>
                    </div>
                )
            },
        },
    ]

    const handleSetFormData = (name: string, locations: string[]): void => {
        setUploadFileList(locations)
    }

    const onDescriptinChange = (value: string): void => {
        setEmailText(value)
    }

    return (
        <Form
            name="complex-form"
            labelCol={{ span: 24 }}
            wrapperCol={{ span: 24 }}
            initialValues={{ remember: true }}
            layout={'vertical'}
            autoComplete="off"
            form={form}
            requiredMark={customizeRequiredMark}
        >
            <Card className={commonStyles.card}>
                <Form.Item name="contactName" hidden={true}>
                    <Input />
                </Form.Item>
                <Row gutter={16}>
                    <Col span={24}>
                        <Form.Item
                            label="Email To"
                            name="toAddress"
                            rules={[
                                {
                                    required: true,
                                    message: 'Please input your email!',
                                },
                            ]}
                        >
                            <Input />
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={16}>
                    <Col span={24}>
                        <Form.Item label="Email CC" name="ccAddress">
                            <Input />
                        </Form.Item>
                        <span>
                            <i>
                                Note:-This format only accepted
                                (example@gmail.com,example1@gmail.com,example2@gmail.com)
                            </i>
                        </span>
                    </Col>
                </Row>
                <Row gutter={16}>
                    <Col span={24}>
                        <Form.Item
                            label="Email BCC "
                            name="bccAddress"
                            rules={
                                [
                                    // {
                                    //     type: 'email',
                                    //     message: 'Please input Valid email!',
                                    // },
                                ]
                            }
                        >
                            <Input />
                        </Form.Item>
                        <span>
                            <i>
                                Note:-This format only accepted
                                (example@gmail.com,example1@gmail.com,example2@gmail.com)
                            </i>
                        </span>
                    </Col>
                </Row>

                <Row gutter={16}>
                    <Col span={24}>
                        <Form.Item
                            label="Subject"
                            name="subject"
                            rules={[
                                {
                                    required: true,
                                    message: 'Please input your Subject',
                                },
                            ]}
                        >
                            <Input />
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={16}>
                    <Col span={24}>
                        <Form.Item label="Description">
                            <ReactQuillComponet
                                value={emailText}
                                onChange={(value: string) => {
                                    onDescriptinChange(value)
                                }}
                            />
                        </Form.Item>
                    </Col>
                </Row>

                <Row gutter={24}>
                    <Col span={10}>
                        <Table
                            dataSource={dataSource}
                            columns={columns}
                            pagination={false}
                        />
                    </Col>
                </Row>
                <Row>
                    <div
                        style={{
                            marginTop: 20,
                            width: '100%',
                            marginBottom: 20,
                        }}
                    >
                        <Col span={24}>
                            <S3UploadMultiple
                                name={'imageUpload1'}
                                value={uploadFileList}
                                setFormData={handleSetFormData}
                                corporate={''}
                                corporateid={0}
                                documenttype={''}
                                documentbaseId={0}
                                memberpersonId={0}
                            />
                        </Col>
                    </div>
                </Row>
                <Row>
                    <Col offset={22} span={2}>
                        <Form.Item>
                            <Button
                                type="primary"
                                size="middle"
                                onClick={onSave}
                            >
                                <SaveOutlined />
                            </Button>
                        </Form.Item>
                    </Col>
                </Row>
            </Card>
        </Form>
    )
}

export default SendEmailInvoice
