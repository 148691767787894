import { Button, Col, Form, Row, message } from 'antd'
import TextArea from 'antd/es/input/TextArea'
import React, { useEffect } from 'react'
import { SaveOutlined } from '@ant-design/icons'
import { useDispatch } from 'react-redux'
import {
    getBankInfo,
    SetInvoiceStatus,
} from '../../../../Services/Invoicing/Invoice'
import ActivePremission from '../../../../Utils/premissins'
import { PermissionType } from '../../../../Types/Enum/PermissionType'

const NoNeed = (props: {
    record: any
    onTableChange: () => void
}): React.ReactElement => {
    const [form] = Form.useForm()
    const { record, onTableChange } = props
    const dispatch = useDispatch()

    useEffect(() => {
        getBankInfo(dispatch)
    }, [dispatch])

    const onFinish = (values: any): void => {
        try {
            const data = {
                invoicePaymentStatusId: 0,
                serviceProcessId: record.serviceProcessId ?? 0,
                currentStatus: '3',
                Description: values.description ?? '',
                PaymentStatus: 'Paid',
                PaymentOptionId: 0,
            }
            void SetInvoiceStatus(dispatch, data)
            onTableChange()
            form.resetFields()
        } catch (error) {
            void message.error('Failed to submit form: ')
        }
    }

    return (
        <div>
            <Form
                name="complex-form"
                labelCol={{ span: 24 }}
                wrapperCol={{ span: 24 }}
                initialValues={{ remember: true }}
                layout={'vertical'}
                autoComplete="off"
                form={form}
                onFinish={onFinish}
            >
                <Row gutter={16}>
                    <Col span={24}>
                        <Form.Item label="Description" name="description">
                            <TextArea rows={4} />
                        </Form.Item>
                    </Col>
                </Row>

                {ActivePremission('6000', PermissionType.SAVE) && (
                    <Row gutter={16}>
                        <Col offset={20} span={2}>
                            <Button type="primary" htmlType="submit">
                                <SaveOutlined />
                            </Button>
                        </Col>
                    </Row>
                )}
            </Form>
        </div>
    )
}

export default NoNeed
