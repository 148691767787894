import React from 'react'
import { Row } from 'antd'
import commontStyles from '../../../Utils/Common.less'

import type { StaffDesignationExpandViewInterface } from '../../../Types/StaffDesignations'

const StaffDesignationExpandView = (
    props: StaffDesignationExpandViewInterface
): React.ReactElement => {
    const { staffDesignationData } = props

    return (
        <div className={commontStyles.expandCard}>
            <Row className={commontStyles.expandRow}>
                Description -:{' '}
                <div className={commontStyles.expandRowValue}>
                    {staffDesignationData.description}
                </div>
            </Row>
        </div>
    )
}

export default StaffDesignationExpandView
