import { type Dispatch } from '@reduxjs/toolkit'
import axios from '../Config/AxiosConfig'
import {
    workflowList,
    workflowTypeDropDownList,
    workflowDetail,
} from '../Reducer/InitialReducer'
import { ErrorMessage, SuccessMessage } from '../Utils/Notification'
import { setLoadingStatus } from '../Reducer/Common'
import type { WorkflowFormDataInterface } from '../Types/Workflow'
import type {
    ListFrontEndInterface,
    ListBackendInterface,
    TableListInterface,
} from '../Types/CommonType'

export const GetWorkflowList = (
    dispatch: Dispatch,
    data: TableListInterface
): void => {
    dispatch(setLoadingStatus({ status: true }))
    const { pageNo, pageSize, searchCriteriaList, sortOrderList } = data
    const bodyData = {
        searchCriteriaList,
        sortOrderList,
    }

    axios
        .post(`/api/General/GetWorkflowList/${pageNo}/${pageSize}`, bodyData)
        .then((response) => {
            if (response.status === 200) {
                const newData = response?.data?.data?.map(
                    (value: WorkflowFormDataInterface) => {
                        return {
                            ...value,
                            key: value.workflowId,
                        }
                    }
                )
                response.data.data = newData
                dispatch(workflowList({ ...response.data }))
            } else {
                ErrorMessage(response.data.operationMsg)
            }
            dispatch(setLoadingStatus({ status: false }))
        })
        .catch((error) => {
            ErrorMessage(error.response.data.operationMsg)
            console.error('Error fetching posts:', error)
            dispatch(setLoadingStatus({ status: false }))
        })
}

export const GetWorkflowTypeList = (dispatch: Dispatch): void => {
    dispatch(setLoadingStatus({ status: true }))
    axios
        .get('/api/GetStandardListing/28')
        .then((response) => {
            if (response.status === 200) {
                const newWorkflowTypeList: ListFrontEndInterface[] =
                    response?.data?.data.map((data: ListBackendInterface) => {
                        return {
                            value: data.stdId,
                            label: data.stdName,
                        }
                    })
                dispatch(workflowTypeDropDownList(newWorkflowTypeList))
            } else {
                ErrorMessage(response.data.operationMsg)
            }
            dispatch(setLoadingStatus({ status: false }))
        })
        .catch((error) => {
            ErrorMessage(error.response.data.operationMsg)
            console.error('Error fetching get:', error)
            dispatch(setLoadingStatus({ status: false }))
        })
}

export const CheckWorkflowNameExist = (
    dispatch: Dispatch,
    id: string,
    workflowForId: string,
    name: string
): void => {
    dispatch(setLoadingStatus({ status: true }))
    const bodyData = [
        {
            fieldName: 'Workflowname',
            compareOperator: 1,
            fieldValue1: name,
            fieldValue2: '',
        },
        {
            fieldName: 'workflowid',
            compareOperator: 1,
            fieldValue1: String(id),
            fieldValue2: '',
        },
        {
            fieldName: 'workflowfor',
            compareOperator: 1,
            fieldValue1: String(workflowForId),
            fieldValue2: '',
        },
    ]
    axios
        .post(`/api/ExistObjectData/6`, bodyData)
        .then((response) => {
            if (response?.status === 200 && Boolean(response?.data?.isExist)) {
                ErrorMessage(response.data.error.message)
            }
            dispatch(setLoadingStatus({ status: false }))
        })
        .catch((error) => {
            ErrorMessage(error.response.data.operationMsg)
            console.error('Error fetching post:', error)
            dispatch(setLoadingStatus({ status: false }))
        })
}

export const SaveWorkflow = async (
    dispatch: Dispatch,
    data: WorkflowFormDataInterface
): Promise<any> => {
    dispatch(setLoadingStatus({ status: true }))
    return await axios
        .post(`/api/General/SetWorkflow`, data)
        .then((response) => {
            dispatch(setLoadingStatus({ status: false }))
            if (response.status === 200) {
                SuccessMessage(response.data.operationMsg)
                return true
            } else {
                ErrorMessage(response.data.operationMsg)
                return false
            }
        })
        .catch((error) => {
            ErrorMessage(error.response.data.operationMsg)
            console.error('Error fetching posts:', error)
            dispatch(setLoadingStatus({ status: false }))
            return false
        })
}

export const ActiveInactiveWorkflow = (
    dispatch: Dispatch,
    id: string,
    status: string,
    getTableData: CallableFunction
): void => {
    dispatch(setLoadingStatus({ status: true }))
    axios
        .get(`/api/SetRecordActiveStatus/11/${id}/${status}`)
        .then((response) => {
            if (response.status === 200) {
                getTableData()
                SuccessMessage(response.data.operationMsg)
            } else {
                ErrorMessage(response.data.operationMsg)
            }
            dispatch(setLoadingStatus({ status: false }))
        })
        .catch((error) => {
            ErrorMessage(error.response.data.operationMsg)
            console.error('Error fetching get:', error)
            dispatch(setLoadingStatus({ status: false }))
        })
}

export const DeleteWorkflow = (
    dispatch: Dispatch,
    id: string,
    getTableData: CallableFunction
): void => {
    dispatch(setLoadingStatus({ status: true }))
    axios
        .get(`/api/SetDeleteRecord/16/${id}`)
        .then((response) => {
            if (response.status === 200) {
                getTableData()
                SuccessMessage(response.data.operationMsg)
            } else {
                ErrorMessage(response.data.operationMsg)
            }
            dispatch(setLoadingStatus({ status: false }))
        })
        .catch((error) => {
            ErrorMessage(error.response.data.operationMsg)
            console.error('Error fetching get:', error)
            dispatch(setLoadingStatus({ status: false }))
        })
}

export const GetWorkflowDetailById = (
    dispatch: Dispatch,
    workflowId: string
): void => {
    dispatch(setLoadingStatus({ status: true }))
    axios
        .get(`/api/General/GetWorkflowDetail/${workflowId}`)
        .then((response) => {
            if (response.status === 200) {
                dispatch(workflowDetail({ ...response.data }))
            } else {
                ErrorMessage(response.data.operationMsg)
            }
            dispatch(setLoadingStatus({ status: false }))
        })
        .catch((error) => {
            ErrorMessage(error.response.data.operationMsg)
            console.error('Error fetching get:', error)
            dispatch(setLoadingStatus({ status: false }))
        })
}
