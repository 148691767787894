import React, { useCallback, useState } from 'react'
import { Row, Col, Button, Card } from 'antd'
import CoporateTabsless from './ContactInfo.less'
import { connect, useDispatch } from 'react-redux'
import type {
    ContactInfoData,
    ContactInfoDataTabs,
    ContactInfoOfficerDatas,
} from '../../../../../../Types/Client/RegisteredClients/Partnership'
import { EyeOutlined, EditOutlined } from '@ant-design/icons'
import ContactInfoEditForm from './ContactEditForm'
import {
    getClientCompanyDetails,
    setPartnershipContactInfo,
} from '../../../../../../Services/Parnership'
import ActivePremission from '../../../../../../Utils/premissins'
import { PermissionType } from '../../../../../../Types/Enum/PermissionType'

export const ContactInfoDataInterFace = {
    contactpersonid: 0,
    registerid: 0,
    registertype: '',
    title: '',
    firstname: '',
    middlename: '',
    surname: '',
    preferredname: '',
    mobilenumber: '',
    homenumber: '',
    worknumber: '',
    emailaddress: '',
    altemailaddress: '',
    preferredlanguage: 0,
    languageName: '',
}

const ContactInfoViewPage = (
    props: ContactInfoDataTabs
): React.ReactElement => {
    const { partnershipTaps, partnershipId } = props
    const dispatch = useDispatch()
    const [editData, setEditData] = useState<ContactInfoData>(
        ContactInfoDataInterFace
    )
    const [isEditMode, setIsEditMode] = useState(false)
    const handleEditClick = useCallback((): void => {
        setIsEditMode((prevIsEditMode) => !prevIsEditMode)
        setEditData(partnershipTaps)
    }, [partnershipTaps])

    const handleSaveData = (newData: ContactInfoData): void => {
        setEditData(newData)
        void setPartnershipContactInfo(dispatch, newData)
        void getClientCompanyDetails(dispatch, 4, partnershipId, 5)
        setIsEditMode(false)
    }
    return (
        <>
            <Card>
                <div>
                    <Row gutter={16} justify="space-between">
                        <Col>
                            <p className={CoporateTabsless.titleofpage}>
                                Contact Details
                            </p>
                        </Col>
                        <Col>
                            <Button type="primary" onClick={handleEditClick}>
                                {isEditMode ? (
                                    <EyeOutlined />
                                ) : (
                                    ActivePremission(
                                        '3000',
                                        PermissionType.UPDATE
                                    ) && <EditOutlined />
                                )}
                            </Button>
                        </Col>
                    </Row>
                    {isEditMode ? (
                        <div>
                            <ContactInfoEditForm
                                editData={editData}
                                partnershipId={partnershipId}
                                onSave={handleSaveData}
                            />
                        </div>
                    ) : (
                        <Row gutter={[16, 32]}>
                            <Col span={6}>
                                <p>Title</p>
                                <p>First Name</p>
                                <p>Middle Name</p>
                                <p>Surname</p>
                                <p>Preferred Name</p>
                                <p>Prefered Language</p>
                            </Col>
                            <Col span={6}>
                                <p>: {partnershipTaps?.title} </p>
                                <p>: {partnershipTaps?.firstname}</p>
                                <p>: {partnershipTaps?.middlename}</p>
                                <p>: {partnershipTaps?.surname}</p>
                                <p>: {partnershipTaps?.preferredname} </p>
                                <p>: {partnershipTaps?.languageName} </p>
                            </Col>
                            <Col span={6}>
                                <p>Mobile Number</p>
                                <p>Home Number</p>
                                <p>Work Number</p>
                                <p>Email</p>
                                <p>Alternative Email</p>
                            </Col>

                            <Col span={6}>
                                <p>: {partnershipTaps?.mobilenumber} </p>
                                <p>: {partnershipTaps?.homenumber}</p>
                                <p>: {partnershipTaps?.worknumber}</p>
                                <p>: {partnershipTaps?.emailaddress}</p>
                                <p>: {partnershipTaps?.altemailaddress}</p>
                            </Col>
                        </Row>
                    )}
                </div>
            </Card>
        </>
    )
}
const mapStateToProps = (state: any): ContactInfoOfficerDatas => {
    return {
        partnershipTaps: state.client.partnershipTaps,
    }
}

export default connect(mapStateToProps)(ContactInfoViewPage)
