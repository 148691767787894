import React, { useEffect, useState } from 'react'
import { Col, Row, Input, Form, Button, Select } from 'antd'
import { connect, useDispatch } from 'react-redux'
import commonStyles from '../../../Utils/Common.less'
import { SaveOutlined } from '@ant-design/icons'
import { WorkflowItemDefaultData } from './WorkflowList'
import type { ListFrontEndInterface } from '../../../Types/CommonType'
import { GetDepartmentDropDownList } from '../../../Services/Department'
import type {
    WorkflowFormInterface,
    WorkflowFormDataInterface,
    WorkflowFormStateInterface,
} from '../../../Types/Workflow'
import { CheckWorkflowNameExist } from '../../../Services/Workflow'
import ActivePremission from '../../../Utils/premissins'
import { PermissionType } from '../../../Types/Enum/PermissionType'

const WorkflowForm = (props: WorkflowFormInterface): React.ReactElement => {
    const {
        onSave,
        editData,
        workflowTypeDropDownList,
        categoryDropDownList,
        departmentDropDownList,
    } = props
    const [clientCategoryId, setClientCategoryId] = useState(0)
    const dispatch = useDispatch()
    const [form] = Form.useForm()
    let timeout: any

    useEffect(() => {
        form.setFieldValue('workflowId', '0')
    }, [])

    useEffect(() => {
        const workflowTypeData = workflowTypeDropDownList.find(
            (data: ListFrontEndInterface) =>
                data.label === editData.workflowForName
        )
        const clientCategoryData = categoryDropDownList.find(
            (data: ListFrontEndInterface) =>
                data.label === editData.clientCategoryName
        )
        const departmentData = departmentDropDownList.find(
            (data: ListFrontEndInterface) =>
                data.label === editData.departmentName
        )
        const newData = {
            ...editData,
            workflowFor: workflowTypeData?.value ?? '',
            clientCategoryId: clientCategoryData?.value ?? '',
            departmentId: departmentData?.value ?? '',
        }
        form.setFieldsValue(newData)
        setClientCategoryId(clientCategoryData?.value ?? 0)
    }, [editData])

    useEffect(() => {
        const departmentData = departmentDropDownList.find(
            (data: ListFrontEndInterface) =>
                data.label === editData.departmentName
        )
        const data = form.getFieldsValue()
        const newData = {
            ...data,
            departmentId: departmentData?.value ?? '',
        }
        form.setFieldsValue(newData)
    }, [departmentDropDownList])

    useEffect(() => {
        if (clientCategoryId !== 0) {
            GetDepartmentDropDownList(dispatch, clientCategoryId)
        }
    }, [clientCategoryId])

    const resetForm = (): void => {
        form.setFieldsValue({
            WorkflowItemDefaultData,
            workflowFor: undefined,
            workflowName: undefined,
            clientCategoryId: undefined,
            departmentId: undefined,
        })
    }

    const onNamechange = (event: any): void => {
        const workflowForId = form.getFieldValue('workflowFor') ?? '0'

        clearTimeout(timeout)
        timeout = setTimeout(() => {
            CheckWorkflowNameExist(
                dispatch,
                editData?.workflowId,
                workflowForId,
                event?.target?.value
            )
        }, 1000)
    }

    const onChangeClientCategory = (value: number): void => {
        setClientCategoryId(value)
    }

    const customizeRequiredMark = (
        label: React.ReactNode,
        { required }: { required: boolean }
    ): React.ReactElement => (
        <>
            {label}
            &nbsp;
            {required && <span className={commonStyles.requireIcon}>*</span>}
        </>
    )

    return (
        <div className={commonStyles.formWrapper}>
            <Form
                name="complex-form"
                labelCol={{ span: 24 }}
                wrapperCol={{ span: 24 }}
                initialValues={{ remember: true }}
                layout={'vertical'}
                autoComplete="off"
                onFinish={(data) => {
                    onSave(data, resetForm)
                }}
                form={form}
                requiredMark={customizeRequiredMark}
            >
                <Form.Item<WorkflowFormDataInterface>
                    name="workflowId"
                    hidden={true}
                >
                    <Input />
                </Form.Item>
                <Row gutter={16}>
                    <Col span={24}>
                        <Form.Item<WorkflowFormDataInterface>
                            label="Workflow Type"
                            name="workflowFor"
                            rules={[
                                {
                                    required: true,
                                    message: 'Please select the workflow type!',
                                },
                            ]}
                        >
                            <Select
                                showSearch
                                allowClear
                                placeholder="Please select"
                                onChange={() => {}}
                                filterOption={(input, option) =>
                                    !(option == null) &&
                                    option.label
                                        .toLowerCase()
                                        .includes(input.toLowerCase())
                                }
                                options={workflowTypeDropDownList}
                            />
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={16}>
                    <Col span={24}>
                        <Form.Item<WorkflowFormDataInterface>
                            label="Workflow"
                            name="workflowName"
                            rules={[
                                {
                                    required: true,
                                    message: 'Please select the workflow name!',
                                },
                            ]}
                        >
                            <Input onChange={onNamechange} />
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={16}>
                    <Col span={24}>
                        <Form.Item<WorkflowFormDataInterface>
                            label="Company Group"
                            name="clientCategoryId"
                            rules={[
                                {
                                    required: true,
                                    message: 'Please select the company group!',
                                },
                            ]}
                        >
                            <Select
                                showSearch
                                allowClear
                                placeholder="Please select"
                                onChange={onChangeClientCategory}
                                filterOption={(input, option) =>
                                    !(option == null) &&
                                    option.label
                                        .toLowerCase()
                                        .includes(input.toLowerCase())
                                }
                                options={categoryDropDownList}
                            />
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={16}>
                    <Col span={24}>
                        <Form.Item<WorkflowFormDataInterface>
                            label="Department"
                            name="departmentId"
                            rules={[
                                {
                                    required: true,
                                    message: 'Please select the department!',
                                },
                            ]}
                        >
                            <Select
                                showSearch
                                allowClear
                                placeholder="Please select"
                                filterOption={(input, option) =>
                                    !(option == null) &&
                                    option.label
                                        .toLowerCase()
                                        .includes(input.toLowerCase())
                                }
                                options={departmentDropDownList}
                            />
                        </Form.Item>
                    </Col>
                </Row>
                {ActivePremission('1007', PermissionType.SAVE) && (
                    <Row gutter={16}>
                        <Col offset={22} span={2}>
                            <Button type="primary" htmlType="submit">
                                <SaveOutlined />
                            </Button>
                        </Col>
                    </Row>
                )}
            </Form>
        </div>
    )
}

const mapStateToProps = (state: any): WorkflowFormStateInterface => {
    return {
        workflowTypeDropDownList: state.initial.workflowTypeDropDownList,
        categoryDropDownList: state.initial.categoryDropDownList,
        departmentDropDownList: state.initial.departmentDropDownList,
    }
}

export default connect(mapStateToProps)(WorkflowForm)
