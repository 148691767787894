import React from 'react'
import { Table } from 'antd'
import commontStyles from './document.less'
import {
    type officerDocumentList,
    type CorporateDoucmentOfficerExpandView,
} from '../../../../../../Types/Client/RegisteredClients/Corporate'

const OfficerDocumentExpanView = (
    props: CorporateDoucmentOfficerExpandView
): React.ReactElement => {
    const { expandData } = props
    const columns = [
        {
            title: 'Expiry Dates',
            dataIndex: 'expiryDates',
            render: (text: any, record: any) => (
                <div>
                    {record.expiryDates?.map((date: string, index: number) => (
                        <p key={index}>{date}</p>
                    ))}
                </div>
            ),
        },
    ]

    const dataSource = expandData?.map(
        (record: officerDocumentList, index: number) => {
            return {
                key: index.toString(),
                expiryDates: [
                    record.ppExpiryDate,
                    record.dlExpiryDate,
                    record.prExpiryDate,
                ],
            }
        }
    )

    return (
        <div className={commontStyles.expandCard}>
            <Table
                columns={columns}
                dataSource={dataSource}
                pagination={false}
            />
        </div>
    )
}

export default OfficerDocumentExpanView
