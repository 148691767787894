import { type PayloadAction, createSlice } from '@reduxjs/toolkit'

interface InitailStateInterface {
    username: string
    isLogin: boolean
    isAuthenticate: boolean
}

const AuthReducer = createSlice({
    name: 'auth',
    initialState: {
        username: '',
        isLogin: false,
        isAuthenticate: false,
    },
    reducers: {
        setLoginState(
            state: InitailStateInterface,
            action: PayloadAction<any>
        ) {
            state.isLogin = action.payload.status
            state.username = action.payload.username
        },
        setAuthState(state: InitailStateInterface, action: PayloadAction<any>) {
            state.isAuthenticate = action.payload.status
        },
        pageReloadAuth(state: InitailStateInterface) {
            state.isAuthenticate = true
            state.isLogin = true
        },
        pageLoginPinAuth(state: InitailStateInterface) {
            state.isAuthenticate = false
            state.isLogin = true
        },
    },
})

export const { setLoginState, setAuthState, pageReloadAuth, pageLoginPinAuth } =
    AuthReducer.actions
export default AuthReducer.reducer
