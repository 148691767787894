import { Card, Col, Collapse, Row, Table } from 'antd'
import React from 'react'
import style from '../../ThingToDoViewAll/tingtodo.less'
interface CompanyDetailsprops {
    companyHousId?: number
}
const ShareCapital = (props: CompanyDetailsprops): React.ReactElement => {
    const { companyHousId } = props
    const dataSource = [
        {
            key: '1',
            name: 'Mike',
            age: 32,
            address: '10 Downing Street',
        },
        {
            key: '2',
            name: 'John',
            age: 42,
            address: '10 Downing Street',
        },
    ]

    const columns = [
        {
            title: 'Share Class',
            dataIndex: 'age',
            key: 'age',
        },
        {
            title: 'No of Shares',
            dataIndex: 'address',
            key: 'address',
        },
        {
            title: 'Aggregate Nominal Value',
            dataIndex: 'address',
            key: 'address',
        },
        {
            title: 'Prescribed Particulars',
            dataIndex: 'address',
            key: 'address',
        },
    ]
    return (
        <>
            <div>
                <h3>Share Capital</h3>
            </div>
            <Card>
                {companyHousId === 1 && (
                    <div className={style.misstingInfo}>
                        If you want to do changes required in registered office
                        address should be filed using the{' '}
                        <b>&quot;Add New Currency and Share Class&quot;</b>,{' '}
                        <b>&quot;Add New Share Class&quot;</b> before you file
                        the confirmation statement.
                    </div>
                )}
                <Collapse defaultActiveKey={['']}>
                    <Collapse.Panel
                        header={
                            <div
                                style={{
                                    display: 'flex',
                                    justifyContent: 'space-between',
                                }}
                            >
                                <span>Currently : GPB</span>
                            </div>
                        }
                        key={1}
                    >
                        <Row gutter={[24, 8]}>
                            <Table
                                dataSource={dataSource}
                                columns={columns}
                                pagination={false}
                            />
                        </Row>

                        <Row gutter={[24, 8]}>
                            <Col span={8}>Total number of shares issued</Col>
                            <Col span={8}>:</Col>
                        </Row>
                        <Row gutter={[24, 8]}>
                            <Col span={8}>
                                Total aggregate value of shares issued
                            </Col>
                            <Col span={8}>:</Col>
                        </Row>
                        <Row gutter={[24, 8]}>
                            <Col span={8}>Total aggregate amount unpaid</Col>
                            <Col span={8}>:</Col>
                        </Row>
                    </Collapse.Panel>
                </Collapse>
            </Card>
        </>
    )
}

export default ShareCapital
