import type { TableParamsInterface } from '../Types/CommonType'

export const commonPageSize = 10

export const commonTableParams = {
    pagination: {
        current: 1,
        pageSize: commonPageSize,
    },
    sorter: {
        field: '',
        order: '',
    },
    filter: {
        fieldName: '',
        searchOperator: 10,
        fieldValue1: '',
        fieldValue2: '',
    },
}

export const setCommonTableParamsData = (
    tableParams: TableParamsInterface
): Record<string, any> => {
    const pageNo = tableParams.pagination?.current
    const pageSize = tableParams.pagination?.pageSize
    const sortOrderList: object[] = []
    const searchCriteriaList: object[] = []

    if (tableParams?.sorter.field !== '') {
        sortOrderList.push({
            fieldName: tableParams?.sorter.field,
            orderDirection:
                tableParams?.sorter.order === 'descend'
                    ? 2
                    : tableParams?.sorter.order === 'ascend'
                      ? 1
                      : 0,
        })
    }

    if (tableParams?.filter.fieldValue1 !== '') {
        searchCriteriaList.push(tableParams?.filter)
    }

    return { pageSize, pageNo, sortOrderList, searchCriteriaList }
}
