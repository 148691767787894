import React, { useEffect, useState } from 'react'
import { type PropsApp } from '../../../Types/HelpDesk/ManageDiary'
import { Button, Col, Modal, Row, Tooltip } from 'antd'
import { EditOutlined, DeleteOutlined } from '@ant-design/icons'
import {
    DeleteEvent,
    GetEventList,
} from '../../../Services/Helpdesk/ManageDiary'
import { useDispatch } from 'react-redux'
import FullWidthModal from '../../../Components/FullWidthModal'
import ManageDiaryForm from './ManagrDiaryForm'
const ManageDiaryList = (props: PropsApp): React.ReactElement => {
    const { selectedEvent, onDeleteEvent, selectedDate, setModaleventVisible } =
        props
    const [deleteModalVisible, setDeleteModalVisible] = useState(false)
    const [modalVisible, setModalVisible] = useState(false)
    const dispatch = useDispatch()
    const [eventlist, setEventList] = useState(true)
    useEffect(() => {
        setModaleventVisible(eventlist)
    }, [eventlist])

    const handleDeleteClick = (): void => {
        setDeleteModalVisible(true)
    }
    const habndleeditclick = (): void => {
        setModalVisible(true)
        setEventList(false)
    }
    const handleDeleteConfirm = (): void => {
        DeleteEvent(dispatch, selectedEvent.eventId)
        onDeleteEvent(selectedEvent.eventId)
        setDeleteModalVisible(false)
        GetEventList(dispatch)
    }

    const handleDeleteCancel = (): void => {
        setDeleteModalVisible(false)
    }
    const closeModal = (): void => {
        setTimeout(() => {
            GetEventList(dispatch)
            setModalVisible(false)
        }, 3000)
    }

    const userNames = selectedEvent?.generalEventFollowerList
        ?.map((follower: { userName: string }) => follower.userName)
        .join(', ')
    return (
        <div>
            <div>
                <p>
                    Start:{' '}
                    {new Date(selectedEvent.startFrom).toLocaleString('en-US', {
                        day: '2-digit',
                        month: 'short',
                        year: 'numeric',
                    })}
                </p>
                <p>
                    End:{' '}
                    {new Date(selectedEvent.endTo).toLocaleString('en-US', {
                        day: '2-digit',
                        month: 'short',
                        year: 'numeric',
                    })}
                </p>
                <p>Participant: {userNames}</p>
                <p>Description: {selectedEvent.description}</p>
                <Row gutter={16} justify="end">
                    <Col>
                        <Tooltip title="Delete Event">
                            <Button
                                type="primary"
                                onClick={() => {
                                    handleDeleteClick()
                                }}
                            >
                                <DeleteOutlined />
                            </Button>
                        </Tooltip>
                    </Col>
                    <Col>
                        <Tooltip title="Edit Event">
                            <Button type="primary" onClick={habndleeditclick}>
                                <EditOutlined />
                            </Button>
                        </Tooltip>
                    </Col>
                </Row>
            </div>

            <Modal
                title="Save Event"
                open={modalVisible}
                onCancel={closeModal}
                footer={null}
                width={'50%'}
            >
                {selectedDate !== null && (
                    <ManageDiaryForm
                        date={selectedDate}
                        initialFormData={selectedEvent}
                        oncloseEventModal={closeModal}
                    />
                )}
            </Modal>
            <FullWidthModal
                modalStatus={deleteModalVisible}
                closeModal={handleDeleteCancel}
                tittle="You are about to delete all events. Click 'Yes' to continue."
                smallTittle="Warning: This action cannot be undone!"
                yesButtonFunction={handleDeleteConfirm}
            />
        </div>
    )
}

export default ManageDiaryList
