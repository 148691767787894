import React from 'react'
import { Col, Row } from 'antd'
import { type VatViewPagePropData } from '../../../../../../Types/Client/RegisteredClients/SoleTrader'
import { formatdate } from '../../../../../../Utils/dateFormat'

export const VatDefultData = {
    vatId: 0,
    companyType: 0,
    referenceId: 0,
    vatNumber: '',
    vatScheme: '',
    vatRegistrationDate: '01/01/1900',

    addressLineOne: '',
    addressLineTwo: '',
    town: '',
    country: '',
    postCode: '',
    effectiveDate: '01/01/1900',
    endingDate: '01/01/1900',
    dataInput: '',
    status: '',
    hmrcGatewayId: '',
    hmrcPassword: '',
    vatFrequency: 0,
    vatFrequencyName: '',
}
const VATViewPage = (props: VatViewPagePropData): React.ReactElement => {
    const { VatServiceData } = props
    return (
        <>
            <Row gutter={[24, 8]}>
                <Col span={6}>
                    {' '}
                    <p>VAT Number</p>
                </Col>
                <Col span={6}>
                    <p>: {VatServiceData?.vatNumber}</p>
                </Col>
                <Col span={5}>
                    <p>Address 1</p>
                </Col>
                <Col span={7}>
                    <p>: {VatServiceData?.addressLineOne}</p>
                </Col>
            </Row>
            <Row gutter={[24, 8]}>
                <Col span={6}>
                    {' '}
                    <p>VAT Scheme</p>
                </Col>
                <Col span={6}>
                    <p>: {VatServiceData?.vatScheme}</p>
                </Col>
                <Col span={5}>
                    <p>Address 2</p>
                </Col>
                <Col span={7}>
                    <p>: {VatServiceData?.addressLineTwo}</p>
                </Col>
            </Row>
            <Row gutter={[24, 8]}>
                <Col span={6}>
                    {' '}
                    <p>VAT Registration Date</p>
                </Col>
                <Col span={6}>
                    <p>: {formatdate(VatServiceData?.vatRegistrationDate)}</p>
                </Col>
                <Col span={5}>
                    {' '}
                    <p>Town</p>
                </Col>
                <Col span={7}>
                    {' '}
                    <p>: {VatServiceData?.town}</p>
                </Col>
            </Row>
            <Row gutter={[24, 8]}>
                <Col span={6}>
                    <p>VAT Effective Date</p>
                </Col>
                <Col span={6}>
                    {' '}
                    <p>: {formatdate(VatServiceData?.effectiveDate)}</p>
                </Col>
                <Col span={5}>
                    <p>Country</p>
                </Col>
                <Col span={7}>
                    <p>: {VatServiceData?.country}</p>
                </Col>
            </Row>
            <Row gutter={[24, 8]}>
                <Col span={6}>
                    {' '}
                    <p>Period Ending Date</p>
                </Col>
                <Col span={6}>
                    <p>: {formatdate(VatServiceData?.endingDate)}</p>
                </Col>
                <Col span={5}>
                    <p>Post Code</p>
                </Col>
                <Col span={7}>
                    <p>: {VatServiceData?.postCode}</p>
                </Col>
            </Row>
            <Row gutter={[24, 8]}>
                <Col span={6}>
                    <p>HMRC Gateway ID</p>
                </Col>
                <Col span={6}>
                    <p>: {VatServiceData?.hmrcGatewayId}</p>
                </Col>
                <Col span={5}>
                    <p>Password</p>
                </Col>
                <Col span={7}>
                    <p>: {VatServiceData?.hmrcPassword}</p>
                </Col>
            </Row>
            <Row gutter={[24, 8]}>
                <Col span={6}>
                    <p>VAT Frequency</p>
                </Col>
                <Col span={6}>
                    {' '}
                    <p>
                        :{' '}
                        {VatServiceData?.vatFrequency !== undefined
                            ? VatServiceData?.vatFrequency === 1
                                ? 'QUATARY'
                                : 'ANNUALY'
                            : ''}
                    </p>
                </Col>
                <Col span={5}>
                    <p>Data Input</p>
                </Col>
                <Col span={7}>
                    <p>: {VatServiceData?.dataInput}</p>
                </Col>
            </Row>
        </>
    )
}

export default VATViewPage
