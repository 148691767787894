import React from 'react'
import { Row } from 'antd'
import commontStyles from '../../../Utils/Common.less'

import { type MessageDetailsExpandViewInterface } from '../../../Types/Notifications'

const MessageListExpandView = (
    props: MessageDetailsExpandViewInterface
): React.ReactElement => {
    const { messageDetailsData } = props

    return (
        <div className={commontStyles.expandCard}>
            <Row className={commontStyles.expandRow}>
                Description :-{' '}
                <div className={commontStyles.expandRowValue}>
                    {messageDetailsData.description}
                </div>
            </Row>
        </div>
    )
}

export default MessageListExpandView
