import React, { type ReactElement, useEffect, useState } from 'react'
import { Col, Row, Form, Collapse, Button, Card, Tooltip } from 'antd'
import type {
    PartneshipHomeApi,
    PartneshipHomeProps,
    HomeStateProps,
} from '../../../../../../Types/Client/RegisteredClients/Partnership'
import { EditOutlined, EyeFilled, SaveOutlined } from '@ant-design/icons'
import { connect, useDispatch } from 'react-redux'
import { GetCategoryDropDownList } from '../../../../../../Services/GroupAndPartners'
import { GetCountryList } from '../../../../../../Services/Common'
import commonStyles from '../../../../../../Utils/Common.less'
import InitialDetailsForm from './InitialDetailsForm'
import BussinessAddressForm from './BussinessAddressForm'
import CorresponseAddressForm from './CorresponseAddressForm'

import moment from 'moment'
import {
    setPartnershipHomeData,
    getClientCompanyDetails,
} from '../../../../../../Services/Parnership'
import ActivePremission from '../../../../../../Utils/premissins'
import { PermissionType } from '../../../../../../Types/Enum/PermissionType'

const HomeList = (props: PartneshipHomeProps): ReactElement => {
    const { partnershipTaps, partnershipId } = props
    const [form] = Form.useForm()
    const { Panel } = Collapse
    const [showHomeForm] = useState(false)
    const [editMode, setEditMode] = useState(false)
    const dispatch = useDispatch()
    const [openPanel, setActivePanel] = useState<string | string[]>(['1'])
    const [isEditing, setIsEditing] = useState(false)
    const [isCorrEditing, setIsCorrEditing] = useState(false)
    const [isCorrBussEditing, setIsCorrBussEditing] = useState(false)
    const [termSignedDateformat, setFormatDate] = useState('')
    const [selectCountry, setSelectedCountry] = useState<string>('')
    const [selectedCategoryId, setSelectedCategoryId] = useState<number>()

    const handleCategorySelect = (id: number): void => {
        setSelectedCategoryId(id)
    }

    const handleDateChangeCountry = (country: string): void => {
        setSelectedCountry(country)
    }

    const togglePanel = (key: string | string[]): void => {
        if (typeof key === 'string') {
            setActivePanel([key])
        } else {
            setActivePanel(key)
        }
    }

    const toggleEditing = (): void => {
        setIsEditing(!isEditing)
    }

    const coresToggleEditing = (): void => {
        setIsCorrEditing(!isCorrEditing)
        setIsCorrBussEditing(false)
    }

    const coresBussToggleEditing = (): void => {
        setIsCorrBussEditing(!isCorrBussEditing)
        setIsCorrEditing(false)
    }

    useEffect(() => {
        GetCategoryDropDownList(dispatch)
        GetCountryList(dispatch)
    }, [dispatch])

    const updatePage = (): void => {
        setEditMode(true)
        form.submit()
    }
    const viewPage = (): void => {
        setEditMode(false)
    }

    useEffect(() => {
        form.setFieldsValue({
            corraddress1: isCorrBussEditing
                ? partnershipTaps?.businessAddress?.busaddress1
                : partnershipTaps?.correspondanceAddress?.corraddress1,
            corraddress2: isCorrBussEditing
                ? partnershipTaps?.businessAddress?.busaddress2
                : partnershipTaps?.correspondanceAddress?.corraddress2,
            corrtown: isCorrBussEditing
                ? partnershipTaps?.businessAddress?.bustown
                : partnershipTaps?.correspondanceAddress?.corrtown,
            corrcountry: isCorrBussEditing
                ? partnershipTaps?.businessAddress?.buscountry
                : partnershipTaps?.correspondanceAddress?.corrcountry,
            corrpostcode: isCorrBussEditing
                ? partnershipTaps?.businessAddress?.buspostcode
                : partnershipTaps?.correspondanceAddress?.corrpostcode,
        })
    }, [isCorrBussEditing, form, partnershipTaps])

    useEffect(() => {
        form.setFieldsValue({
            partnershipId:
                partnershipTaps?.partnershipInitialDetails?.partnershipId ?? 0,
            clientCategoryId:
                partnershipTaps?.partnershipInitialDetails?.clientCategoryId ??
                0,
            partnershipUTR:
                partnershipTaps?.partnershipInitialDetails?.partnershipUTR,
            tradingName:
                partnershipTaps?.partnershipInitialDetails?.tradingName,
            dateOfTermSigned:
                partnershipTaps?.partnershipInitialDetails?.dateOfTermSigned !=
                null
                    ? moment(
                          partnershipTaps?.partnershipInitialDetails
                              ?.dateOfTermSigned
                      )
                    : null,

            busaddress1: partnershipTaps?.businessAddress?.busaddress1,
            busaddress2: partnershipTaps?.businessAddress?.busaddress2,
            bustown: partnershipTaps?.businessAddress?.bustown,
            buscountry: partnershipTaps?.businessAddress?.buscountry,
            buspostcode: partnershipTaps?.businessAddress?.buspostcode,
            corraddress1: partnershipTaps?.correspondanceAddress?.corraddress1,
            corraddress2: partnershipTaps?.correspondanceAddress?.corraddress2,
            corrtown: partnershipTaps?.correspondanceAddress?.corrtown,
            corrcountry: partnershipTaps?.correspondanceAddress?.corrcountry,
            corrpostcode: partnershipTaps?.correspondanceAddress?.corrpostcode,
        })
        const termSignedDate =
            partnershipTaps?.partnershipInitialDetails?.dateOfTermSigned

        if (termSignedDate != null) {
            const dateObject = new Date(termSignedDate)

            // Extracting date components
            const year = dateObject.getFullYear()
            const month = dateObject.getMonth() + 1 // Month is zero-based
            const day = dateObject.getDate()

            // Formatting the date
            const formattedDate = `${month}/${day}/${year}`
            setFormatDate(formattedDate)
        }
    }, [partnershipTaps])

    const handleFormSubmit = async (): Promise<void> => {
        await form.validateFields()
        const values = await form.getFieldsValue()

        const apiPayload = {
            partnershipId: values.partnershipId ?? 0,
            clientCategoryId: selectedCategoryId ?? 0,
            clientNumber:
                values.clientNumber ??
                partnershipTaps?.partnershipInitialDetails?.clientNumber ??
                '',
            tradingName: values.tradingName ?? '',
            partnershipUTR: values.partnershipUTR ?? '',
            busAddressOne: values.busaddress1 ?? '',
            busAddressTwo: values.busaddress2 ?? '',
            busTown: values.bustown ?? '',
            busCountry: selectCountry ?? '',
            busPostCode: values.buspostcode ?? '',
            corrAddressOne: values.corraddress1 ?? '',
            corrAddressTwo: values.corraddress2 ?? '',
            corrTown: values.corrtown ?? '',
            corrCountry: values.corrcountry ?? '',
            corrPostCode: values.corrpostcode ?? '',
            isActive: 'active',
            isDelete: 'inactive',
        }
        void dataToSend(apiPayload)
    }

    const dataToSend = async (apiPayload: PartneshipHomeApi): Promise<void> => {
        try {
            await setPartnershipHomeData(dispatch, apiPayload)
            await getClientCompanyDetails(dispatch, 4, partnershipId, 1)
            setEditMode(false)
        } catch (error) {
            console.error('Error in dataToSend:', error)
        }
    }

    const customizeRequiredMark = (
        label: React.ReactNode,
        { required }: { required: boolean }
    ): React.ReactElement => (
        <>
            {label}
            &nbsp;
            {required && <span className={commonStyles.requireIcon}>*</span>}
        </>
    )

    return (
        <Card className={commonStyles.card}>
            <Form form={form} requiredMark={customizeRequiredMark}>
                <div>
                    <div className={commonStyles.homeListBasic}>
                        <div>
                            <h3>Basic Information</h3>
                        </div>
                        <div>
                            {!showHomeForm &&
                                !editMode &&
                                ActivePremission(
                                    '3000',
                                    PermissionType.UPDATE
                                ) && (
                                    <Tooltip title={'Edit Basic Information'}>
                                        <Button
                                            type="primary"
                                            onClick={updatePage}
                                        >
                                            <EditOutlined />
                                        </Button>
                                    </Tooltip>
                                )}
                            {editMode && (
                                <Tooltip title={'View Basic Information'}>
                                    <Button type="primary" onClick={viewPage}>
                                        <EyeFilled />
                                    </Button>
                                </Tooltip>
                            )}
                        </div>
                    </div>

                    <div>
                        <h4>
                            Term signed is activated on {termSignedDateformat}{' '}
                            this company
                        </h4>
                    </div>
                </div>

                <Row gutter={16}>
                    <Col span={12} className={commonStyles.corporateInitial}>
                        <Collapse
                            accordion={true}
                            activeKey={openPanel}
                            onChange={togglePanel}
                        >
                            <Panel key={'1'} header={'Initial Details'}>
                                {editMode ? (
                                    <InitialDetailsForm
                                        partneshipTaps={partnershipTaps}
                                        onCategorySelect={handleCategorySelect}
                                    />
                                ) : (
                                    <div
                                        className={commonStyles.corporateInform}
                                    >
                                        <div
                                            className={
                                                commonStyles.corporateBasicDetails
                                            }
                                        >
                                            <p>Client Category:</p>
                                            <p>Trading Name:</p>
                                            <p>Partnership UTR:</p>
                                        </div>
                                        <div
                                            className={
                                                commonStyles.corporateBasicDetails
                                            }
                                        >
                                            <p>
                                                :{' '}
                                                {
                                                    partnershipTaps
                                                        ?.partnershipInitialDetails
                                                        ?.clientCategoryName
                                                }
                                            </p>
                                            <p>
                                                :{' '}
                                                {
                                                    partnershipTaps
                                                        ?.partnershipInitialDetails
                                                        ?.tradingName
                                                }
                                            </p>
                                            <p>
                                                :{' '}
                                                {
                                                    partnershipTaps
                                                        ?.partnershipInitialDetails
                                                        ?.partnershipUTR
                                                }
                                            </p>
                                        </div>
                                    </div>
                                )}
                            </Panel>
                        </Collapse>
                    </Col>
                    <Col span={12} className={commonStyles.corporateInitial}>
                        <Collapse
                            accordion
                            activeKey={openPanel}
                            onChange={togglePanel}
                        >
                            <Panel key={'4'} header={'Bussiness Address'}>
                                {editMode ? (
                                    <>
                                        <BussinessAddressForm
                                            isEditing={isEditing}
                                            toggleEditing={toggleEditing}
                                            onDateChange={
                                                handleDateChangeCountry
                                            }
                                        />
                                    </>
                                ) : (
                                    <>
                                        <div
                                            className={
                                                commonStyles.corporateInform
                                            }
                                        >
                                            <div
                                                className={
                                                    commonStyles.corporateBasicDetails
                                                }
                                            >
                                                <p>Address 1</p>
                                                <p>Address 2</p>
                                                <p>Town</p>
                                                <p>Country</p>
                                                <p>Post Code</p>
                                            </div>
                                            <div
                                                className={
                                                    commonStyles.corporateBasicDetails
                                                }
                                            >
                                                <p>
                                                    <p>
                                                        :{' '}
                                                        {
                                                            partnershipTaps
                                                                ?.businessAddress
                                                                ?.busaddress1
                                                        }
                                                    </p>
                                                    <p>
                                                        :{' '}
                                                        {
                                                            partnershipTaps
                                                                ?.businessAddress
                                                                ?.busaddress2
                                                        }
                                                    </p>
                                                    <p>
                                                        :{' '}
                                                        {
                                                            partnershipTaps
                                                                ?.businessAddress
                                                                ?.bustown
                                                        }
                                                    </p>
                                                    <p>
                                                        :{' '}
                                                        {
                                                            partnershipTaps
                                                                ?.businessAddress
                                                                ?.buscountry
                                                        }
                                                    </p>
                                                    <p>
                                                        :{' '}
                                                        {
                                                            partnershipTaps
                                                                ?.businessAddress
                                                                ?.buspostcode
                                                        }
                                                    </p>
                                                </p>
                                            </div>
                                        </div>
                                    </>
                                )}
                            </Panel>
                        </Collapse>
                    </Col>
                </Row>
                <Row gutter={16}>
                    <Col span={12}></Col>
                    <Col span={12} className={commonStyles.corporateInitial}>
                        <Collapse
                            accordion
                            activeKey={openPanel}
                            onChange={togglePanel}
                        >
                            <Panel key={'5'} header={'Correspondence Address'}>
                                {editMode ? (
                                    <>
                                        <CorresponseAddressForm
                                            isEditing={isCorrEditing}
                                            toggleEditing={coresToggleEditing}
                                            toggleCorrBussEditing={
                                                coresBussToggleEditing
                                            }
                                            onDateChange={
                                                handleDateChangeCountry
                                            }
                                            edit={isCorrBussEditing}
                                        />
                                    </>
                                ) : (
                                    <>
                                        <div
                                            className={
                                                commonStyles.corporateInform
                                            }
                                        >
                                            <div
                                                className={
                                                    commonStyles.corporateBasicDetails
                                                }
                                            >
                                                <p>Address 1</p>
                                                <p>Address 2</p>
                                                <p>Town</p>
                                                <p>Country</p>
                                                <p>Post Code</p>
                                            </div>
                                            <div
                                                className={
                                                    commonStyles.corporateBasicDetails
                                                }
                                            >
                                                <p>
                                                    <p>
                                                        :{' '}
                                                        {
                                                            partnershipTaps
                                                                ?.correspondanceAddress
                                                                ?.corraddress1
                                                        }
                                                    </p>
                                                    <p>
                                                        :{' '}
                                                        {
                                                            partnershipTaps
                                                                ?.correspondanceAddress
                                                                ?.corraddress2
                                                        }
                                                    </p>
                                                    <p>
                                                        :{' '}
                                                        {
                                                            partnershipTaps
                                                                ?.correspondanceAddress
                                                                ?.corrtown
                                                        }
                                                    </p>
                                                    <p>
                                                        :{' '}
                                                        {
                                                            partnershipTaps
                                                                ?.correspondanceAddress
                                                                ?.corrcountry
                                                        }
                                                    </p>
                                                    <p>
                                                        :{' '}
                                                        {
                                                            partnershipTaps
                                                                ?.correspondanceAddress
                                                                ?.corrpostcode
                                                        }
                                                    </p>
                                                </p>
                                            </div>
                                        </div>
                                    </>
                                )}
                            </Panel>
                        </Collapse>
                    </Col>
                </Row>

                {editMode && (
                    <div className={commonStyles.homeListBasic}>
                        <div></div>
                        {ActivePremission('3000', PermissionType.SAVE) && (
                            <div>
                                <Button
                                    type="primary"
                                    onClick={() => {
                                        handleFormSubmit()
                                            .then(() => {
                                                console.log(
                                                    'Form submitted successfully'
                                                )
                                            })
                                            .catch((error: any) => {
                                                console.error(
                                                    'Error submitting form:',
                                                    error
                                                )
                                            })
                                    }}
                                >
                                    <SaveOutlined />
                                </Button>
                            </div>
                        )}
                    </div>
                )}
            </Form>
        </Card>
    )
}

const mapStateToProps = (state: any): HomeStateProps => {
    return {
        categoryDropDownList: state.initial.categoryDropDownList,
        countryList: state.common.countryList,
        partnershipTaps: state.client.partnershipTaps,
    }
}

export default connect(mapStateToProps)(HomeList)
