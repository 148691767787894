import React from 'react'

interface PreviewButtonProps {
    name: string
    clickAction: () => void
    className?: string
}

const PreviewButton: React.FC<PreviewButtonProps> = ({
    name,
    clickAction,
    className,
}) => {
    return (
        <button className={`previewButton ${className}`} onClick={clickAction}>
            {name}
        </button>
    )
}

export default PreviewButton
