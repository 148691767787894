import React, { useEffect, useState, useCallback } from 'react'
import { connect, useDispatch } from 'react-redux'
import { Card, Table, Space, Modal, Tooltip } from 'antd'
import commonStyles from '../../../Utils/Common.less'

import SearchInput from '../../../Components/SearchInput'
import type { ColumnsType, TableProps } from 'antd/es/table'
import {
    GetTimesheetList,
    SaveTimesheet,
    ActiveInactiveTimesheet,
    GetTimeSheetById,
} from '../../../Services/Timesheet'
import type { TableParamsInterface } from '../../../Types/CommonType'
import {
    commonTableParams,
    setCommonTableParamsData,
} from '../../../Utils/CommontTable'
import { ReactComponent as EditIcon } from '../../../Assest/Svg/EditIcon.svg'
import type {
    TimeSheetFormDataInterface,
    TimesheetDataTypeInterface,
    TimesheetListDataTypeInterface,
    TimesheetListstateInterface,
} from '../../../Types/Timesheet'
import calculatePagination from '../../../Utils/Pagination'
import AddNewButton from '../../../Components/AddNewButton'
import TimesheetForm from './TimeSheetEdit'
import { RightOutlined } from '@ant-design/icons'
import FullWidthModal from '../../../Components/FullWidthModal'
import TimesheetExpandView from './TimeSheetExpandView'
export const TimesheetDefaultData: TimeSheetFormDataInterface = {
    timeSheetId: '0',
    dateTime: '',
    duration: '00:00',
    description: '',
    submissionStatus: 0,
    approvalStatus: 0,
    createdDate: '',
    submitedDate: '',
    approvalHistoryList: [],
    isWorkflowRequired: 0,
    firstLevelReportingTo: 0,
}

const TimeSheetList = (
    props: TimesheetListstateInterface
): React.ReactElement => {
    const dispatch = useDispatch()
    const { TimeSheetList, TimeSheetListCount } = props
    const [tableParams, setTableParams] =
        useState<TableParamsInterface>(commonTableParams)
    const [createEditModalStatus, setCreateEditModalStatus] = useState(false)
    const [timeSheetId, setCompanyHolidayId] = useState('')
    const [editModalTittle, setEditModalTittle] = useState('Create Timesheet')
    const [editData, setEditData] =
        useState<TimeSheetFormDataInterface>(TimesheetDefaultData)
    const [activeInactiveModalStatus, setActiveInactiveModalStatus] =
        useState(false)
    const [activeInactiveStatusLabel, setActiveInactiveStatusLabel] =
        useState('')
    const [currentPage, setCurrentPage] = useState(1)
    const [itemsPerPage, setItemsPerPage] = useState(10)

    const getTableData = useCallback(() => {
        const { pageSize, pageNo, sortOrderList, searchCriteriaList } =
            setCommonTableParamsData(tableParams)
        GetTimesheetList(dispatch, {
            pageNo,
            pageSize,
            searchCriteriaList,
            sortOrderList,
        })
    }, [
        tableParams?.sorter,
        tableParams.pagination?.current,
        tableParams?.filter,
    ])

    useEffect(() => {
        getTableData()
    }, [getTableData])

    const handleDataCountChange = useCallback(() => {
        setTableParams({
            ...tableParams,
            pagination: {
                ...tableParams.pagination,
                total: TimeSheetListCount,
            },
        })
    }, [TimeSheetListCount])

    useEffect(() => {
        handleDataCountChange()
    }, [handleDataCountChange])

    const handlePageChange = (pageNo: number, pageSize: number): void => {
        setCurrentPage(pageNo)
        setItemsPerPage(pageSize)
    }

    const pagination = calculatePagination(
        currentPage,
        TimeSheetListCount,
        itemsPerPage
    )
    const activeInactiveModalFunction = (
        id: string,
        activeStatus: string
    ): void => {
        setActiveInactiveModalStatus(!activeInactiveModalStatus)
        let timeSheetId = ''
        if (!activeInactiveModalStatus) {
            timeSheetId = id
        }
        let label = 'Active'
        if (activeStatus === 'Active') {
            label = 'Inactive'
        }
        setActiveInactiveStatusLabel(label)
        setCompanyHolidayId(timeSheetId)
    }

    const updateActiveInactiveHoliday = (): void => {
        let status = '0'
        let submissionStatus = 0
        if (activeInactiveStatusLabel === 'Inactive') {
            status = '0'
            submissionStatus = 1
        }

        const updatedEditData = { ...editData, submissionStatus }
        setEditData(updatedEditData)

        ActiveInactiveTimesheet(
            dispatch,
            timeSheetId,
            status,
            'nl',
            getTableData
        )

        activeInactiveModalFunction('', '')
    }
    const changedate = (date: string): string => {
        if (date != null) {
            const dateObject = new Date(date)

            const year = dateObject.getFullYear()
            const month = dateObject.getMonth() + 1
            const day = dateObject.getDate()
            return `${day}/${month}/${year}`
        }
        return ''
    }
    const convertDuration = (minutes: any): string => {
        const hours = Math.floor(minutes / 60)
        const remainingMinutes = minutes % 60
        return `${hours} hours ${remainingMinutes} minutes`
    }

    const columns: ColumnsType<TimesheetListDataTypeInterface> = [
        {
            width: 120,
            title: 'Date',
            dataIndex: 'dateTime',
            render: (_, record: TimesheetListDataTypeInterface) => {
                return changedate(record.dateTime)
            },
        },
        {
            title: 'Task',
            dataIndex: 'description',
        },
        {
            width: 120,
            title: 'Duration',
            dataIndex: 'duration',
            render: (_, record: TimesheetListDataTypeInterface) => {
                return convertDuration(record.duration)
            },
        },
        {
            width: 100,
            title: 'Status',
            dataIndex: 'submissionStatus',
            render: (submissionStatus) => {
                return submissionStatus === 0 ? 'Saved' : 'Pending'
            },
        },
        {
            width: 100,
            title: '',
            key: 'action',
            render: (_, record: TimesheetListDataTypeInterface) => {
                if (record.submissionStatus === 1) {
                    return null
                } else {
                    return (
                        <Space size="middle">
                            <Tooltip title="submit">
                                <RightOutlined
                                    onClick={() => {
                                        activeInactiveModalFunction(
                                            record?.timeSheetId,
                                            record.submissionStatus.toString()
                                        )
                                    }}
                                    className={commonStyles.clickableIcon}
                                />
                            </Tooltip>
                            <Tooltip title="Edit Timesheet">
                                <EditIcon
                                    onClick={() => {
                                        openEditModal(record?.timeSheetId)
                                    }}
                                    className={commonStyles.clickableIcon}
                                />
                            </Tooltip>
                        </Space>
                    )
                }
            },
        },
    ]

    const onChangeTableParams: TableProps<TimesheetListDataTypeInterface>['onChange'] =
        (pagination, filters, sorter: any, extra) => {
            handlePageChange(pagination.current ?? 1, pagination.pageSize ?? 10)
            setTableParams({
                ...tableParams,
                pagination,
                sorter: {
                    field: sorter?.field ?? '',
                    order: sorter?.order ?? '',
                },
            })
        }

    const onChangeText = (data: React.ChangeEvent<HTMLInputElement>): void => {
        const searchText = data.target.value.trim().toLowerCase()
        onChangeFilter(searchText)
    }

    const onChangeFilter = (data: string): void => {
        setTableParams({
            ...tableParams,
            filter: {
                fieldName: 'multiplesearch',
                searchOperator: 10,
                fieldValue1: data,
                fieldValue2: '',
            },
            pagination: {
                ...tableParams.pagination,
                current: 1,
            },
        })
    }

    const openEditModal = (id?: string): void => {
        // const datas = TimeSheetList.find((data: TimeSheetFormDataInterface) => {
        //     return data.timeSheetId === id
        // })

        let title = 'Create Timesheet'
        if (id !== null && id !== undefined) {
            title = 'Edit Timesheet'
            const fetchData = async (id: string): Promise<void> => {
                try {
                    const data = await GetTimeSheetById(dispatch, id)

                    if (data?.duration != null) {
                        const durationInMinutes = parseInt(data.duration)
                        const hours = Math.floor(durationInMinutes / 60)
                        const minutes = durationInMinutes % 60
                        const formattedDuration: string = `${hours}:${minutes}`

                        setEditData({
                            ...data,
                            duration: formattedDuration,
                        })
                    }
                } catch (error) {}
            }
            void fetchData(id)
        } else {
            setEditData(TimesheetDefaultData)
        }

        setEditModalTittle(title)
        changeCreateEditModalStatus()
    }

    const changeCreateEditModalStatus = (): void => {
        setCreateEditModalStatus(!createEditModalStatus)
    }

    const onSaveHoliday = (
        data: TimeSheetFormDataInterface,
        callBack: CallableFunction
    ): void => {
        void saveHolidayData(data, callBack)
    }
    const saveHolidayData = async (
        data: TimeSheetFormDataInterface,
        callBack: CallableFunction
    ): Promise<void> => {
        await SaveTimesheet(dispatch, data)
            .then((result) => {
                if (result !== null && result !== undefined) {
                    changeCreateEditModalStatus()
                    getTableData()
                    callBack()
                }
            })
            .catch(() => {})
    }
    const expandableData = (
        record: TimesheetDataTypeInterface
    ): React.ReactElement => {
        return <TimesheetExpandView TimesheetData={record} />
    }

    return (
        <>
            <Card
                title="Timesheet"
                extra={
                    <AddNewButton
                        name="Create Timesheet"
                        clickAction={() => {
                            openEditModal()
                        }}
                    />
                }
                className={commonStyles.card}
            >
                <SearchInput
                    placeHolder={'Search Timesheet by keywords'}
                    onSearch={onChangeFilter}
                    onChange={onChangeText}
                    width="478"
                />
                <div className={commonStyles.table}>
                    <Table
                        columns={columns}
                        dataSource={TimeSheetList}
                        onChange={onChangeTableParams}
                        pagination={pagination}
                        expandable={{
                            expandedRowRender: expandableData,
                            rowExpandable: () => true,
                        }}
                    />
                </div>
                <FullWidthModal
                    modalStatus={activeInactiveModalStatus}
                    closeModal={() => {
                        activeInactiveModalFunction('', '')
                    }}
                    tittle={`You are about to ${activeInactiveStatusLabel} this Timesheet. Click 'Yes' to continue.`}
                    smallTittle=""
                    yesButtonFunction={updateActiveInactiveHoliday}
                />
                <Modal
                    footer={null}
                    onCancel={changeCreateEditModalStatus}
                    width={'40%'}
                    title={editModalTittle}
                    open={createEditModalStatus}
                >
                    <TimesheetForm onSave={onSaveHoliday} editData={editData} />
                </Modal>
            </Card>
        </>
    )
}

const mapStateToProps = (state: any): TimesheetListstateInterface => {
    return {
        TimeSheetList: state.hr.TimeSheetList,
        TimeSheetListCount: state.hr.TimeSheetListCount,
        userList: state.user.userList,
    }
}

export default connect(mapStateToProps)(TimeSheetList)
