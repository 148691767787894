import React, { type ReactNode } from 'react'
import { Form, Checkbox } from 'antd'
import styles from './Less/input.less'

const InputFieldCheckBox = (props: {
    name: string
    label: string
    children: ReactNode
}): React.ReactElement => {
    const { name, label, children } = props

    return (
        <Form.Item name={name} valuePropName="checked">
            <>
                <Checkbox className={styles.rememberMeText}>{label}</Checkbox>
                {children}
            </>
        </Form.Item>
    )
}

export default InputFieldCheckBox
