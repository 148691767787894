import React, { useEffect, useState } from 'react'
import {
    Col,
    Row,
    Input,
    Form,
    Button,
    DatePicker,
    Select,
    Switch,
    TimePicker,
} from 'antd'
import { connect, useDispatch } from 'react-redux'
import commonStyles from '../../../Utils/Common.less'
import { SaveOutlined } from '@ant-design/icons'
import {
    GetWorkFlow,
    GetLeaveMode,
    SavePermission,
} from '../../../Services/HR/LeavePermission'
import { GetUserList } from '../../../Services/User'
import {
    type PermissionListType,
    type ApplyFormType,
    type ApplyFormPermissionStateType,
} from '../../../Types/Hr/LeavePermission'
import ApplyPermissionList from './ApplyPermissionList'
import dayjs from 'dayjs'
import moment from 'moment'
dayjs.locale('en')

const { TextArea } = Input

const ApplyPermission = (props: ApplyFormType): React.ReactElement => {
    const { workflowDropDownList } = props
    const dispatch = useDispatch()
    const [form] = Form.useForm()
    const [formHeight, setFormHeight] = useState('auto')
    const [showAdditionalFields, setShowAdditionalFields] = useState(false)
    const [editedData, setEditedData] = useState<PermissionListType>()
    const [savaStatus, setSaveStatus] = useState(false)
    const permissionId = editedData?.permissionId

    useEffect(() => {
        GetWorkFlow(dispatch)
        GetLeaveMode(dispatch)
        GetUserList(dispatch)
    }, [dispatch])

    const onFinish = async (): Promise<void> => {
        try {
            await form.validateFields()
            const formdata = form.getFieldsValue()
            const formattedStartTime = dayjs(formdata.startTime).format(
                'HH:mm A'
            )
            const formattedEndTime = dayjs(formdata.endTime).format('HH:mm A')
            const formattedcoveringStartTime = dayjs(
                formdata.coveringStartTime
            ).format('HH:mm A')
            const formattedcoveringEndTime = dayjs(
                formdata.coveringEndTime
            ).format('HH:mm A')
            const formatDateEndTo = dayjs(
                formdata.endTo,
                'YYYY-MM-DDTHH:mm:ss'
            ).format('DD/MM/YYYY')

            const permissionApprovalStatus = 'Saved'
            const coveringRequired = 1
            const permissionApprovalDescription = ''
            const formDataWithLeaveType = {
                ...formdata,
                permissionId,
                permissionApprovalStatus,
                startTime: formdata.startTime ?? '',
                endTime: formdata.endTime ?? '',
                coveringStartTime: formdata.coveringStartTime ?? '',
                coveringEndTime: formdata.coveringEndTime ?? '',
                endTo: formdata.endTo ?? '',
                coveringFrom: '',
                coveringRequired,
                permissionApprovalDescription,
                startFrom: formdata.startFrom ?? '',
                coveringTo: formdata.coveringTo ?? '',
            }
            form.setFieldsValue(formDataWithLeaveType)
            const apiVAlue = {
                ...formDataWithLeaveType,
                startTime: formattedStartTime,
                endTime: formattedEndTime,
                coveringStartTime: formattedcoveringStartTime,
                coveringEndTime: formattedcoveringEndTime,
                endTo: formatDateEndTo,
            }
            await SavePermission(dispatch, apiVAlue)
            form.resetFields()
            setSaveStatus(true)
        } catch (err) {
            console.error('Validation errors:', err)
        }
    }

    const statusHandlingFunction = (status: boolean): void => {
        setSaveStatus(status)
    }

    useEffect(() => {
        if (editedData != null) {
            form.setFieldsValue({
                permissionId: editedData.permissionId,
                endTo: dayjs(editedData.endTo, 'YYYY-MM-DDTHH:mm:ss'),
                startTime: moment(editedData.startTime, 'hh:mm A'),
                endTime: moment(editedData.endTime, 'hh:mm A'),
                description: editedData.description,
                workflowId: editedData.workflowId,
                coveringFrom:
                    editedData.coveringFrom !== '' &&
                    dayjs(editedData.coveringFrom, 'YYYY-MM-DD'),
                coveringStartTime:
                    editedData.coveringStartTime !== '' &&
                    moment(editedData.coveringStartTime, 'hh:mm A'),
                coveringEndTime:
                    editedData.coveringEndTime !== '' &&
                    moment(editedData.coveringEndTime, 'hh:mm A'),
            })
        }
    }, [editedData, form])

    const handleEditedData = (editedData: PermissionListType): void => {
        setEditedData(editedData)
    }

    const customizeRequiredMark = (
        label: React.ReactNode,
        { required }: { required: boolean }
    ): React.ReactElement => (
        <>
            {label}
            &nbsp;
            {required && <span className={commonStyles.requireIcon}>*</span>}
        </>
    )
    return (
        <div
            className={commonStyles.formWrapper}
            style={{ minHeight: formHeight }}
        >
            <Form
                name="complex-form"
                initialValues={{ remember: true }}
                labelCol={{ span: 24 }}
                wrapperCol={{ span: 24 }}
                autoComplete="off"
                form={form}
                onFieldsChange={() => {
                    setFormHeight('auto')
                }}
                onValuesChange={() => {
                    setTimeout(() => {
                        setFormHeight(`${form.getFieldsValue().length * 50}px`)
                    }, 0)
                }}
                requiredMark={customizeRequiredMark}
            >
                <Row gutter={16}>
                    <Col span={12}>
                        <Form.Item<PermissionListType>
                            label="Date"
                            name="endTo"
                            rules={[
                                {
                                    required: true,
                                    message: 'Please select the Date!',
                                },
                            ]}
                        >
                            <DatePicker
                                format="DD/MM/YYYY"
                                style={{ width: '100%' }}
                            />
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item<PermissionListType>
                            label="Start Time"
                            name="startTime"
                            rules={[
                                {
                                    required: true,
                                    message: 'Please select the startTime!',
                                },
                            ]}
                        >
                            <TimePicker
                                style={{ width: '100%' }}
                                defaultOpenValue={dayjs()
                                    .set('hour', 0)
                                    .set('minute', 0)}
                                format="HH:mm"
                            />
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={16}>
                    <Col span={12}>
                        <Form.Item<PermissionListType>
                            label="End Time"
                            name="endTime"
                            rules={[
                                {
                                    required: true,
                                    message: 'Please select the EndTime!',
                                },
                            ]}
                        >
                            <TimePicker
                                style={{ width: '100%' }}
                                format="HH:mm"
                            />
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item<PermissionListType>
                            label="Purpose"
                            name="description"
                            rules={[
                                {
                                    required: true,
                                    message: 'Please enter the Purpose!',
                                },
                            ]}
                        >
                            <TextArea rows={2} />
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={16}>
                    <Col span={12}>
                        <Form.Item<PermissionListType>
                            label="Work Flow"
                            name="workflowId"
                            rules={[
                                {
                                    required: true,
                                    message: 'Please select the WorkFlow!',
                                },
                            ]}
                        >
                            <Select
                                showSearch
                                allowClear
                                placeholder="Please select"
                                onChange={() => {}}
                                filterOption={(input, option) =>
                                    (option?.label as string)
                                        .toLowerCase()
                                        .includes(input.toLowerCase())
                                }
                                options={[
                                    { label: 'Please select', value: null },
                                    ...workflowDropDownList,
                                ]}
                            />
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item<PermissionListType>
                            label="Toggle Additional Fields"
                            valuePropName="checked"
                        >
                            <Switch
                                onChange={(checked) => {
                                    setShowAdditionalFields(checked)
                                }}
                            />
                        </Form.Item>
                    </Col>
                </Row>
                {showAdditionalFields && (
                    <Row gutter={16}>
                        <Col span={12}>
                            <Form.Item<PermissionListType>
                                label="Covering Date"
                                name="coveringFrom"
                                rules={[
                                    {
                                        required: true,
                                        message:
                                            'Please select the Covering Date!',
                                    },
                                ]}
                            >
                                <DatePicker
                                    format="DD/MM/YYYY"
                                    style={{ width: '100%' }}
                                />
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item<PermissionListType>
                                label="Covering Start Time"
                                name="coveringStartTime"
                                rules={[
                                    {
                                        required: true,
                                        message:
                                            'Please select the Covering StartTime!',
                                    },
                                ]}
                            >
                                <TimePicker
                                    style={{ width: '100%' }}
                                    format="HH:mm"
                                />
                            </Form.Item>
                        </Col>
                    </Row>
                )}
                {showAdditionalFields && (
                    <Row gutter={16}>
                        <Col span={12}>
                            <Form.Item<PermissionListType>
                                label="Covering End Time"
                                name="coveringEndTime"
                                rules={[
                                    {
                                        required: true,
                                        message:
                                            'Please select the Covering EndTime!',
                                    },
                                ]}
                            >
                                <TimePicker
                                    style={{ width: '100%' }}
                                    format="HH:mm"
                                />
                            </Form.Item>
                        </Col>
                    </Row>
                )}
                <Row gutter={16}>
                    <Col offset={22} span={2}>
                        <Button
                            type="primary"
                            onClick={() => {
                                onFinish()
                                    .then(() => {
                                        console.log(
                                            'Form submitted successfully'
                                        )
                                    })
                                    .catch((error: PermissionListType) => {
                                        console.error(
                                            'Error submitting form:',
                                            error
                                        )
                                    })
                            }}
                        >
                            <SaveOutlined />
                        </Button>
                    </Col>
                </Row>
            </Form>
            <ApplyPermissionList
                onFinish={onFinish}
                editedData={handleEditedData}
                savaStatus={savaStatus}
                statusHandlingFunction={statusHandlingFunction}
            />
        </div>
    )
}

const mapStateToProps = (state: any): ApplyFormPermissionStateType => {
    return {
        workflowDropDownList: state.hr.workflowDropDownList,
    }
}

export default connect(mapStateToProps)(ApplyPermission)
