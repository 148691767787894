import { type Dispatch } from '@reduxjs/toolkit'
import axios from '../Config/AxiosConfig'
import { ErrorMessage, SuccessMessage } from '../Utils/Notification'
import {
    type EmailContentTypeListBackendInterface,
    type EmailContentTypeListFrontEndInterface,
    type EmailTemplateFormDataInterface,
} from '../Types/EmailTemplate'
import {
    emailTemplateDetailsList,
    emailContentTypeList,
    emailTemplateDetails,
} from '../Reducer/InitialReducer'
import { setLoadingStatus, emailTemplateTypeList } from '../Reducer/Common'
import {
    type ListBackendInterface,
    type ListFrontEndInterface,
    type TableListInterface,
} from '../Types/CommonType'

export const GetEmailTemplateDetailsList = (
    dispatch: Dispatch,
    data: TableListInterface
): void => {
    dispatch(setLoadingStatus({ status: true }))
    const { pageNo, pageSize, searchCriteriaList, sortOrderList } = data
    const bodyData = {
        searchCriteriaList,
        sortOrderList,
    }
    axios
        .post(
            `/api/General/GetEmailTemplateList/${pageNo}/${pageSize}`,
            bodyData
        )
        .then((response) => {
            if (response.status === 200) {
                const newData = response?.data?.data?.map(
                    (value: EmailTemplateFormDataInterface) => {
                        return {
                            ...value,
                            key: value.templateId,
                        }
                    }
                )
                response.data.data = newData
                dispatch(emailTemplateDetailsList({ ...response.data }))
            } else {
                ErrorMessage(response.data.operationMsg)
            }
            dispatch(setLoadingStatus({ status: false }))
        })
        .catch((error) => {
            ErrorMessage(error.response.data.operationMsg)
            console.error('Error fetching posts:', error)
            dispatch(setLoadingStatus({ status: false }))
        })
}

export const GetEmailTemplateDetailsById = (
    dispatch: Dispatch,
    templateId: string
): void => {
    dispatch(setLoadingStatus({ status: true }))
    axios
        .get(`/api/General/GetEmailTemplateDetail/${templateId}`)
        .then((response) => {
            if (response.status === 200) {
                dispatch(emailTemplateDetails({ ...response.data }))
            } else {
                ErrorMessage(response.data.operationMsg)
            }
            dispatch(setLoadingStatus({ status: false }))
        })
        .catch((error) => {
            ErrorMessage(error.response.data.operationMsg)
            console.error('Error fetching posts:', error)
            dispatch(setLoadingStatus({ status: false }))
        })
}

export const GetContentProperties = (
    dispatch: Dispatch,
    templateTypeId: number
): void => {
    dispatch(setLoadingStatus({ status: true }))
    const url = '/api/GetStandardListingWithOneParameter/37/' + templateTypeId
    axios
        .get(url)
        .then((response) => {
            if (response.status === 200) {
                const contentList: EmailContentTypeListFrontEndInterface[] =
                    response?.data?.data?.map(
                        (data: EmailContentTypeListBackendInterface) => {
                            return {
                                value: data.stdId,
                                label: data.stdName,
                            }
                        }
                    )
                dispatch(emailContentTypeList(contentList))
            } else {
                ErrorMessage(response.data.operationMsg)
            }
            dispatch(setLoadingStatus({ status: false }))
        })
        .catch((error) => {
            ErrorMessage(error.response.data.operationMsg)
            console.error('Error fetching posts:', error)
            dispatch(setLoadingStatus({ status: false }))
        })
}

export const ActiveInactiveEmailTemplate = (
    dispatch: Dispatch,
    id: string,
    status: string,
    getTableData: CallableFunction
): void => {
    dispatch(setLoadingStatus({ status: true }))
    axios
        .get(`/api/SetRecordActiveStatus/15/${id}/${status}`)
        .then((response) => {
            if (response.status === 200) {
                getTableData()
                SuccessMessage(response.data.operationMsg)
            } else {
                ErrorMessage(response.data.operationMsg)
            }
            dispatch(setLoadingStatus({ status: false }))
        })
        .catch((error) => {
            ErrorMessage(error.response.data.operationMsg)
            console.error('Error fetching posts:', error)
            dispatch(setLoadingStatus({ status: false }))
        })
}

export const AddEmailTemplateDetails = async (
    dispatch: Dispatch,
    data: EmailTemplateFormDataInterface
): Promise<boolean> => {
    dispatch(setLoadingStatus({ status: true }))
    return await axios
        .post(`/api/General/SetEmailTemplate`, data)
        .then((response) => {
            dispatch(setLoadingStatus({ status: false }))
            if (response.status === 200) {
                SuccessMessage(response.data.operationMsg)
                return true
            } else {
                ErrorMessage(response.data.operationMsg)
                return false
            }
        })
        .catch((error) => {
            ErrorMessage(error.response.data.operationMsg)
            console.error('Error fetching posts:', error)
            dispatch(setLoadingStatus({ status: false }))
            return false
        })
}

export const DeleteEmailTemplate = (
    dispatch: Dispatch,
    id: string,
    getTableData: CallableFunction
): void => {
    dispatch(setLoadingStatus({ status: true }))
    axios
        .get(`/api/SetDeleteRecord/24/${id}`)
        .then((response) => {
            if (response.status === 200) {
                getTableData()
                SuccessMessage(response.data.operationMsg)
            } else {
                ErrorMessage(response.data.operationMsg)
            }
            dispatch(setLoadingStatus({ status: false }))
        })
        .catch((error) => {
            ErrorMessage(error.response.data.operationMsg)
            console.error('Error fetching posts:', error)
            dispatch(setLoadingStatus({ status: false }))
        })
}

export const GetTemplateTypeList = (dispatch: Dispatch): void => {
    dispatch(setLoadingStatus({ status: true }))
    axios
        .get('/api/GetStandardListing/36')
        .then((response) => {
            if (response.status === 200) {
                const templateTypeList: ListFrontEndInterface[] =
                    response?.data?.data?.map((data: ListBackendInterface) => {
                        return {
                            value: data.stdId,
                            label: data.stdName,
                        }
                    })

                dispatch(emailTemplateTypeList(templateTypeList))
            } else {
                ErrorMessage(response.data.operationMsg)
            }
            dispatch(setLoadingStatus({ status: false }))
        })
        .catch((error) => {
            ErrorMessage(error.response.data.operationMsg)
            console.error('Error fetching posts:', error)
            dispatch(setLoadingStatus({ status: false }))
        })
}
