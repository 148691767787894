import React, { useEffect, useState, useCallback } from 'react'
import { useNavigate } from 'react-router-dom'
import { connect, useDispatch } from 'react-redux'
import {
    Card,
    Table,
    Space,
    Modal,
    Switch,
    Col,
    Row,
    Tooltip,
    Drawer,
} from 'antd'
import commonStyles from '../../../../Utils/Common.less'
import SearchInput from '../../../../Components/SearchInput'
import type { ColumnsType, TableProps } from 'antd/es/table'
import type { TableParamsInterface } from '../../../../Types/CommonType'
import {
    commonTableParams,
    setCommonTableParamsData,
} from '../../../../Utils/CommontTable'
import FullWidthModal from '../../../../Components/FullWidthModal'
import { ReactComponent as DeleteIcon } from '../../../../Assest/Svg/DeleteIcon.svg'
import type {
    CorporateListInterface,
    CorporateListDataTypeInterface,
    CorporateListStateInterface,
} from '../../../../Types/Client/RegisteredClients/Corporate'
import {
    EyeOutlined,
    MessageOutlined,
    UnorderedListOutlined,
} from '@ant-design/icons'
import {
    GetCorporateList,
    DeleteCorporate,
    ActiveInactiveCorporate,
} from '../../../../Services/Corporate'
import { GetCategoryDropDownList } from '../../../../Services/GroupAndPartners'
import CorporateExpandView from './corporateExpandView'
import AddNewButton from '../../../../Components/AddNewButton'
import calculatePagination from '../../../../Utils/Pagination'
import PortfolioView from '../../../../Components/viewProfolioDetails'
import ActivePremission from '../../../../Utils/premissins'
import { PermissionType } from '../../../../Types/Enum/PermissionType'
import { type DataCHAT } from '../../../../Types/Client/ProspectClients/prospectClient'
import { GetChat } from '../../../../Services/Common'
import StaffCommunication from '../../../../Components/StaffChat'
export const CorporateItemDefaultData = {
    corporateid: '0',
    companyname: '',
    companyregisternumber: '',
    clienttype: '',
    clientcategoryid: 0,
    clientcategory: '',
    clientnumber: '',
    activestatus: '',
    serviceIds: '',
    contactName: '',
    mobilenumber: '',
    emailAddress: '',
    alternativeEmailAddress: '',
    defaultUser: '',
    defaultAssigneeUser: '',
}

const CorporateList = (props: CorporateListInterface): React.ReactElement => {
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const {
        corporateList,
        corporateListCount,
        selectedCategoryId,
        selectedServiceId,
    } = props
    const [tableParams, setTableParams] =
        useState<TableParamsInterface>(commonTableParams)
    const [deleteModalStatus, setDeleteModalStatus] = useState(false)
    const [corporateid, setCorporateId] = useState('')
    const [activeInactiveModalStatus, setActiveInactiveModalStatus] =
        useState(false)
    const [activeInactiveStatusLabel, setActiveInactiveStatusLabel] =
        useState('')
    // const [chatModalOpen, setChatModalOpen] = useState(false)
    const [currentPage, setCurrentPage] = useState(1)
    const [itemsPerPage, setItemsPerPage] = useState(10)
    const [portfolioModel, setportfolioModel] = useState(false)
    const [refernceId, setrefernceId] = useState(0)
    const [ChatDatas, setChatData] = useState<DataCHAT | null>(null)
    const [open, setOpen] = useState(false)
    useEffect(() => {
        if (Number(corporateid) !== 0) {
            void GetChat(
                dispatch,
                7,
                Number(corporateid),
                1,
                (chatdatass: DataCHAT) => {
                    setChatData(chatdatass)
                }
            )
        }
    }, [corporateid])

    const openChatModal = (corporateid: string): void => {
        // setSelectedCorporateId(corporateid)
        // setChatModalOpen(true)
        setCorporateId(corporateid)
        setOpen(true)
    }
    const onClose = (): void => {
        setOpen(false)
    }

    useEffect(() => {
        GetCategoryDropDownList(dispatch)
    }, [dispatch])

    const getTableData = useCallback(() => {
        const { pageSize, pageNo, sortOrderList, searchCriteriaList } =
            setCommonTableParamsData(tableParams)
        GetCorporateList(
            dispatch,
            { pageNo, pageSize, searchCriteriaList, sortOrderList },
            selectedCategoryId,
            selectedServiceId
        )
    }, [
        tableParams?.sorter,
        tableParams.pagination?.current,
        tableParams?.filter,
        selectedCategoryId,
        selectedServiceId,
    ])

    useEffect(() => {
        getTableData()
    }, [getTableData])

    const handleDataCountChange = useCallback(() => {
        setTableParams({
            ...tableParams,
            pagination: {
                ...tableParams.pagination,
                total: corporateListCount,
            },
        })
    }, [corporateListCount])

    useEffect(() => {
        handleDataCountChange()
    }, [handleDataCountChange])

    const handlePageChange = (pageNo: number, pageSize: number): void => {
        setCurrentPage(pageNo)
        setItemsPerPage(pageSize)
    }
    const pagination = calculatePagination(
        currentPage,
        corporateListCount,
        itemsPerPage
    )

    const viewCorporateDetails = (id: string): void => {
        const corporateData = corporateList?.find(
            (item) => item.corporateid === id
        )
        const onbordingId = 0
        navigate(`/clients/registered-clients/corporate-view`, {
            state: { corporateData, onbordingId },
        })
    }

    const createCorporate = (id: string): void => {
        const corporateData = corporateList?.find(
            (item) => item.corporateid === id
        )
        navigate(`/clients/registered-clients/corporate-new`, {
            state: { corporateData },
        })
    }

    const columns: ColumnsType<CorporateListDataTypeInterface> = [
        {
            width: 150,
            title: 'Client No',
            dataIndex: 'clientnumber',
            sorter: true,
            sortDirections: ['descend', 'ascend'],
        },
        {
            width: 150,
            title: 'Company Name',
            dataIndex: 'companyname',
        },
        {
            width: 150,
            title: 'Registration No',
            dataIndex: 'companyregisternumber',
        },
        {
            width: 100,
            title: 'Type',
            dataIndex: 'clientType',
        },
        {
            width: 150,
            title: 'Services',
            dataIndex: 'serviceIds',
        },
        {
            width: 150,
            title: 'Action',
            key: 'action1',
            render: (_, record: CorporateListDataTypeInterface) => {
                return (
                    <Space size="middle">
                        <Tooltip title=" Staff Communication">
                            <MessageOutlined
                                onClick={() => {
                                    openChatModal(record?.corporateid)
                                }}
                            />
                        </Tooltip>
                        <Tooltip title="View Corporate Details">
                            <EyeOutlined
                                className={commonStyles.viewIcon}
                                onClick={() => {
                                    viewCorporateDetails(record?.corporateid)
                                }}
                            />
                        </Tooltip>
                        <Tooltip
                            title={
                                record.activestatus === 'Active'
                                    ? 'Active'
                                    : 'Inactive'
                            }
                        >
                            <Switch
                                checked={record.activestatus === 'Active'}
                                onClick={() => {
                                    activeInactiveModalFunction(
                                        record?.corporateid,
                                        record.activestatus
                                    )
                                }}
                            />
                        </Tooltip>
                        {ActivePremission('3000', PermissionType.DELETE) && (
                            <Tooltip title="Delete Corporate">
                                <DeleteIcon
                                    onClick={() => {
                                        deleteModalFunction(record?.corporateid)
                                    }}
                                />
                            </Tooltip>
                        )}
                        <Tooltip title="View Portfolio Details">
                            <UnorderedListOutlined
                                onClick={() => {
                                    openProtfolio(record?.corporateid)
                                }}
                            />
                        </Tooltip>
                    </Space>
                )
            },
        },
    ]

    const onChangeTableParams: TableProps<CorporateListDataTypeInterface>['onChange'] =
        (pagination, filters, sorter: any, extra) => {
            handlePageChange(pagination.current ?? 1, pagination.pageSize ?? 10)
            setTableParams({
                ...tableParams,
                pagination,
                sorter: {
                    field: sorter?.field ?? '',
                    order: sorter?.order ?? '',
                },
            })
        }

    const onChangeText = (data: React.ChangeEvent<HTMLInputElement>): void => {
        const searchText = data.target.value.trim().toLowerCase()
        onChangeFilter(searchText)
    }

    const onChangeFilter = (data: string): void => {
        setTableParams({
            ...tableParams,
            filter: {
                fieldName: 'multiplesearch',
                searchOperator: 10,
                fieldValue1: data,
                fieldValue2: '',
            },
            pagination: {
                ...tableParams.pagination,
                current: 1,
            },
        })
    }

    const deleteModalFunction = (id: string): void => {
        setDeleteModalStatus(!deleteModalStatus)
        let corporateid = ''
        if (!deleteModalStatus) {
            corporateid = id
        }
        setCorporateId(corporateid)
    }

    const deleteCorporate = (): void => {
        DeleteCorporate(dispatch, corporateid, getTableData)
        deleteModalFunction('')
    }

    const activeInactiveModalFunction = (
        id: string,
        activeStatus: string
    ): void => {
        setActiveInactiveModalStatus(!activeInactiveModalStatus)
        let corporateid = ''
        if (!activeInactiveModalStatus) {
            corporateid = id
        }
        let label = 'Active'
        if (activeStatus === 'Active') {
            label = 'Inactive'
        }
        setActiveInactiveStatusLabel(label)
        setCorporateId(corporateid)
    }

    const updateActiveInactiveCorporate = (): void => {
        let status = 'Active'
        if (activeInactiveStatusLabel === 'Inactive') status = 'Inactive'
        ActiveInactiveCorporate(dispatch, corporateid, status, getTableData)
        activeInactiveModalFunction('', '')
    }

    const openProtfolio = (corporateId: string): void => {
        const refernceid = parseInt(corporateId)
        setrefernceId(refernceid)
        setportfolioModel(!portfolioModel)
    }
    const expandableData = (
        record: CorporateListDataTypeInterface
    ): JSX.Element => {
        return <CorporateExpandView corporateData={record} />
    }

    return (
        <Card className={commonStyles.card}>
            <Row gutter={16} align="middle">
                <div className={commonStyles.corpList}>
                    <Col span={16}>
                        <SearchInput
                            placeHolder={'Search CompanyName by keywords'}
                            onSearch={onChangeFilter}
                            onChange={onChangeText}
                            width="478"
                        />
                    </Col>
                    <Col span={4}>
                        <AddNewButton
                            name="Add New"
                            clickAction={() => {
                                createCorporate('')
                            }}
                        />
                    </Col>
                </div>
            </Row>
            <div className={commonStyles.table}>
                <Table
                    columns={columns}
                    dataSource={corporateList}
                    onChange={onChangeTableParams}
                    pagination={pagination}
                    expandable={{
                        expandedRowRender: expandableData,
                        rowExpandable: () => true,
                    }}
                />
            </div>
            <FullWidthModal
                modalStatus={deleteModalStatus}
                closeModal={deleteModalFunction}
                tittle="You are about to delete this Corporate. Click 'Yes' to continue."
                smallTittle="Warning: This action cannot be undone!"
                yesButtonFunction={deleteCorporate}
            />
            <FullWidthModal
                modalStatus={activeInactiveModalStatus}
                closeModal={() => {
                    activeInactiveModalFunction('', '')
                }}
                tittle={`You are about to ${activeInactiveStatusLabel} this corporate. Click 'Yes' to continue.`}
                smallTittle=""
                yesButtonFunction={updateActiveInactiveCorporate}
            />

            {/* <Modal
                title="Corporate Chat"
                open={chatModalOpen}
                onCancel={() => {
                    setChatModalOpen(false)
                }}
                footer={null}
                width={'50%'}
            >
                {selectedCorporateId != null && (
                    <CorporateChat
                        corporateid={parseInt(selectedCorporateId, 10)}
                    />
                )}
            </Modal> */}
            <Modal
                title="Portfolio Details"
                open={portfolioModel}
                onCancel={() => {
                    setportfolioModel(!portfolioModel)
                }}
                footer={null}
                width={'50%'}
            >
                <PortfolioView
                    businessId={1}
                    referenceId={refernceId}
                    portfolioId={0}
                />
            </Modal>
            <Drawer
                title={ChatDatas?.clientName}
                width={550}
                onClose={onClose}
                open={open}
            >
                <StaffCommunication
                    refernceId={Number(corporateid)}
                    messageTypeId={7}
                    businessId={1}
                />
            </Drawer>
        </Card>
    )
}

const mapStateToProps = (state: any): CorporateListStateInterface => {
    return {
        corporateList: state.client.corporateList,
        corporateListCount: state.client.corporateListCount,
        serviceId: state.initial.serviceList,
    }
}

export default connect(mapStateToProps)(CorporateList)
