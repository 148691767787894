import React, { useEffect, useState } from 'react'
import { Col, Row, Form, Button, Select } from 'antd'
import { connect, useDispatch } from 'react-redux'
import commonStyles from '../../../Utils/Common.less'
import { SaveOutlined } from '@ant-design/icons'
import {
    type AssigneeUserStateInterface,
    type ClientServiceUserSwitchUserInterface,
} from '../../../Types/ServiceSetup/ClientServiceUser'
import { GetClientServiceUserList } from '../../../Services/ServiceSetup'
import { GetServiceDropDownListBusinessType } from '../../../Services/Corporate'
import ActivePremission from '../../../Utils/premissins'
import { PermissionType } from '../../../Types/Enum/PermissionType'

const SwitchWorkingUser = (
    props: ClientServiceUserSwitchUserInterface
): React.ReactElement => {
    const {
        clientServiceDropDownList,
        ClientserviceUserList,
        flegId,
        businessTypeId,
        referenceId,
        onSave,
        serviceName,
    } = props
    const dispatch = useDispatch()
    const [form] = Form.useForm()
    const [serviceId, setServiceId] = useState(0)

    useEffect(() => {
        GetServiceDropDownListBusinessType(
            dispatch,
            businessTypeId,
            referenceId
        )
    }, [dispatch, referenceId, businessTypeId])

    useEffect(() => {
        if (serviceName !== undefined) {
            const data = clientServiceDropDownList.find(
                (item) => item.label === serviceName
            )
            if (data !== undefined) {
                setServiceId(data.value)
                GetClientServiceUserList(dispatch, flegId, data.value)
            }
        }
    }, [serviceName, serviceId])

    const resetForm = (): void => {
        form.resetFields()
    }
    const customizeRequiredMark = (
        label: React.ReactNode,
        { required }: { required: boolean }
    ): React.ReactElement => (
        <>
            {label}
            &nbsp;
            {required && <span className={commonStyles.requireIcon}>*</span>}
        </>
    )

    return (
        <div className={commonStyles.formWrapper}>
            <Form
                name="complex-form"
                labelCol={{ span: 24 }}
                wrapperCol={{ span: 24 }}
                initialValues={{ remember: true }}
                layout={'vertical'}
                autoComplete="off"
                requiredMark={customizeRequiredMark}
                onFinish={(data) => {
                    const newObj = {
                        serviceId,
                        businessTypeId,
                        referenceId,
                        assigneeUserId: flegId === 1 ? data.userId : 0,
                        workingUserId: flegId === 2 ? data.userId : 0,
                        portfolioManagerId: 0,
                        accountManagerId: 0,
                    }
                    onSave(newObj, resetForm)
                }}
                form={form}
            >
                <>
                    <Row gutter={16}>
                        <Col span={24}>
                            <Form.Item
                                label={
                                    flegId === 1
                                        ? 'Assignee User'
                                        : 'Working User'
                                }
                                name="userId"
                                rules={[
                                    {
                                        required: true,
                                        message: `Please select ${
                                            flegId === 1
                                                ? 'Assignee User'
                                                : 'Working User'
                                        }!`,
                                    },
                                ]}
                            >
                                <Select
                                    showSearch
                                    placeholder="Please select"
                                    options={[
                                        {
                                            label: 'Please select',
                                            value: null,
                                        },
                                        ...ClientserviceUserList,
                                    ]}
                                    allowClear
                                    filterOption={(input, option) =>
                                        (option?.label ?? '')
                                            .toLowerCase()
                                            .includes(input.toLowerCase())
                                    }
                                />
                            </Form.Item>
                        </Col>
                    </Row>{' '}
                    {ActivePremission('2001', PermissionType.SAVE) && (
                        <Row gutter={16}>
                            <Col offset={22} span={2}>
                                <Button type="primary" htmlType="submit">
                                    <SaveOutlined />
                                </Button>
                            </Col>
                        </Row>
                    )}
                </>
            </Form>
        </div>
    )
}

const mapStateToProps = (state: any): AssigneeUserStateInterface => {
    return {
        ClientserviceUserList: state.serviceSetup.ClientserviceUserList,
        clientServiceDropDownList: state.initial.clientServiceDropDownList,
    }
}

export default connect(mapStateToProps)(SwitchWorkingUser)
