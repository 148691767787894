import React, { useEffect } from 'react'
import { Col, Row, Input, Form, Button, Tooltip } from 'antd'
import commonStyles from '../../../Utils/Common.less'
import {
    type ServiceProcessIdData,
    type changeProcessModelProps,
} from '../../../Types/ToDoViewAllList'
import {
    GetServiceProcessInduvidual,
    SaveChangePrgress,
} from '../../../Services/ToDoViewAllList'
import { connect, useDispatch } from 'react-redux'
import { SaveOutlined } from '@ant-design/icons'
interface ChecklistProps {
    serviceprocessId: number
    serviceId?: number
    serviceProcessInduvidualData: ServiceProcessIdData
    ServiceProcesName: string
    serviceActionId: number
    changeModelState: () => void
}
const ChangeDataProcessModel = (props: ChecklistProps): React.ReactElement => {
    const {
        serviceprocessId,
        serviceProcessInduvidualData,
        ServiceProcesName,
        serviceActionId,
        changeModelState,
    } = props
    const [form] = Form.useForm()
    const dispatch = useDispatch()

    useEffect(() => {
        GetServiceProcessInduvidual(dispatch, serviceprocessId)
    }, [serviceprocessId])

    const onSave = (): void => {
        void onSavePhone()
    }
    const onSavePhone = async (): Promise<void> => {
        try {
            await form.validateFields()

            const formData = form.getFieldsValue()

            const dataToSave = {
                type: 1,
                referenceId: serviceActionId ?? 0,
                statusId: serviceActionId === 25 ? 7 : 3,
                remarks: formData.description ?? '',
                completeFlag: 1,
                serviceProcessId: serviceprocessId,
                emailTemplateId: 0,
                serviceProcessStatusProperty: [],
            }

            await SaveChangePrgress(dispatch, dataToSave)

            form.setFieldsValue({
                description: '',
            })

            changeModelState()
        } catch (error) {}
    }
    return (
        <>
            <div>
                <h5>
                    {serviceProcessInduvidualData?.companyName}(
                    {serviceProcessInduvidualData?.clientNumber})
                    {serviceProcessInduvidualData?.serviceName}Process for the
                    period of{serviceProcessInduvidualData?.periodStartDate} to
                    {serviceProcessInduvidualData?.periodEndDate}
                </h5>
            </div>
            <div className={commonStyles.formWrapper}>
                <Form
                    name="complex-form"
                    labelCol={{ span: 24 }}
                    wrapperCol={{ span: 24 }}
                    initialValues={{ remember: true }}
                    layout={'vertical'}
                    autoComplete="off"
                    form={form}
                    size="small"
                >
                    <Row gutter={16}>
                        <Col span={24}>
                            <Form.Item
                                label={`${ServiceProcesName}-Remarks`}
                                name="description"
                            >
                                <Input.TextArea rows={4} />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={16}>
                        <Col offset={21} span={2}>
                            <Tooltip title="Update Progress Status">
                                <Button
                                    type="primary"
                                    size="middle"
                                    onClick={onSave}
                                >
                                    <SaveOutlined />
                                </Button>
                            </Tooltip>
                        </Col>
                    </Row>
                </Form>
            </div>
        </>
    )
}

const mapStateToProps = (state: any): changeProcessModelProps => {
    return {
        serviceProcessInduvidualData:
            state.dashboardInfo.serviceProcessInduvidualData,
    }
}
export default connect(mapStateToProps)(ChangeDataProcessModel)
