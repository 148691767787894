import React, { useCallback, useEffect, useState } from 'react'
import {
    Form,
    Button,
    Collapse,
    Col,
    Row,
    Dropdown,
    Select,
    Modal,
    Card,
    Tooltip,
} from 'antd'
import {
    EditOutlined,
    EyeOutlined,
    DownOutlined,
    DeleteOutlined,
} from '@ant-design/icons'
import commonStyles from '../../../../../../Utils/Common.less'
import roleStyle from './ofiicer.less'
import {
    type officerAddShareholderCorporate,
    type CorporateOfficerView,
    type officerAddShareholderPerson,
} from '../../../../../../Types/Client/RegisteredClients/Corporate'
import OfficerEditForm from './OfficerEditDataForm'
import OfficerCorporateEditForm from './OfficerCorporateEditForm'
import CorporateAddShreholderForm from './CorporateAddShreholderForm'
import FullWidthModal from '../../../../../../Components/FullWidthModal'
import OfficerPersonModelForm from './OfficerPersonModelForm'
import CorporateAddShareholderPersonForm from './CorporateAddShareholderPersonForm'
import { connect, useDispatch } from 'react-redux'
import {
    AttachIndividual,
    DeleteShareholder,
    GenerateAccount,
    GetCorporateList,
    SaveOfficerCorporate,
    SaveOfficerPerson,
    getClientCompanyDetails,
} from '../../../../../../Services/Corporate'
import CorporateMemberView from './corporateMemberView'
import PersonMemberView from './personMemberView'
import moment from 'moment'
import ActivePremission from '../../../../../../Utils/premissins'
import { PermissionType } from '../../../../../../Types/Enum/PermissionType'
type EditStates = Record<number, boolean>

export const CorporateOfficerViewDefalutData = {
    countryId: 0,
    referenceId: 0,
    type: '',
    titleOrCompanyName: '',
    firstNameOrBRNo: '',
    middleName: '',
    surName: '',
    dateOfBirth: '',
    postCode: '',
    addressLineOne: '',
    addressLineTwo: '',
    niNumber: '',
    mobileNo: '',
    homeNo: '',
    workNo: '',
    email: '',
    nationality: '',
    occupation: '',
    countryOfResidence: '',
    dateofAppointment: '',
    alternativeEmail: '',
    town: '',
    country: '',
    shareHoldingPercentage: 0,
    noOfShares: 0,
    shareHolderTypeId: 0,
    shareHolderTypeName: '',
    officerRole: '',
    individualClientNumber: '',
    individualId: 0,
    isNewShareHolder: 0,
    companyNumber: '',
    officerRoleInformationList: [],
}
const Officer = (props: {
    corporateTaps: any
    corporateId: number
    companyName: string
    companyNumber: string
    clientTypeId: number
}): React.ReactElement => {
    const {
        corporateTaps,
        corporateId,
        companyName,
        companyNumber,
        clientTypeId,
    } = props
    const [form] = Form.useForm()
    const dispatch = useDispatch()
    const [isEditMode, setIsEditMode] = useState<EditStates>({})
    const [editData, setEditData] = useState<CorporateOfficerView>(
        CorporateOfficerViewDefalutData
    )
    const [memberType, setMemberType] = useState<string | undefined>('PERSON')
    const [generateAccountModalVisible, setGenerateAccountModalVisible] =
        useState(false)
    const [deleteAccountModalVisible, setDeleteAccountModalVisible] =
        useState(false)
    const [tagModalVisible, setTagModalVisible] = useState(false)
    const [selectedMember, setSelectedMember] = useState(null)
    const [iseditNumber, Seteditnumber] = useState(1)
    const [memeberreferenceId, setMemberRefernce] = useState<number>(0)
    const [referenceId, setRefernceid] = useState<number>(0)
    const [officertypeID, setOfficerTypeId] = useState<number>(0)
    const [memberDetails, setMemberDetails] = useState<CorporateOfficerView>(
        CorporateOfficerViewDefalutData
    )
    const [personOptions, setPersonOptions] = useState<React.ReactNode[]>([])
    const [corporateOptions, setCorporateOptions] = useState<React.ReactNode[]>(
        []
    )
    const [tagName, setTagName] = useState('')
    const [removeAccounModel, setRemoveAccountModel] = useState(false)
    const [SelectName, setName] = useState<number>(0)
    const [activeKey, setActiveKey] = useState<string | string[]>([])
    const handleEditClick = (referenceId: number): void => {
        setIsEditMode((prevEditStates) => ({
            ...prevEditStates,
            [referenceId]: !prevEditStates[referenceId],
        }))

        const datas = corporateTaps?.find((data: CorporateOfficerView) => {
            return data?.referenceId === referenceId
        })
        setEditData(datas ?? CorporateOfficerViewDefalutData)
        Seteditnumber(0)
    }

    useEffect(() => {
        const memberDetailss =
            SelectName !== null && SelectName !== 0
                ? Array.isArray(corporateTaps)
                    ? corporateTaps?.find(
                          (member: { referenceId: number }) =>
                              member.referenceId === SelectName
                      )
                    : null
                : null
        setTagName(memberDetailss?.individualClientNumber)
    }, [corporateTaps, SelectName])

    const ReFetchData = (): void => {
        const tabIndex = 2
        const businessTypeId = 1
        void getClientCompanyDetails(
            dispatch,
            businessTypeId,
            corporateId,
            tabIndex
        )
    }

    const getTableData = useCallback(() => {
        ReFetchData()
        GetCorporateList(
            dispatch,
            {
                pageNo: 1,
                pageSize: 10,
                searchCriteriaList: [],
                sortOrderList: [],
            },
            0,
            0
        )
    }, [])
    useEffect(() => {
        if (Array.isArray(corporateTaps)) {
            const person = corporateTaps
                ?.filter((officer) => officer.type === 'PERSON')
                ?.map((person) => (
                    <Select.Option
                        showSearch
                        key={person?.referenceId}
                        value={person.referenceId}
                    >
                        {person?.titleOrCompanyName} {person?.firstNameOrBRNo}{' '}
                        {person?.middleName} {person?.surName}
                    </Select.Option>
                ))
            setPersonOptions(person)
            const corporate = corporateTaps
                ?.filter((officer) => officer.type === 'CORPORATE')
                ?.map((corporate) => (
                    <Select.Option
                        key={corporate?.referenceId}
                        value={corporate?.referenceId}
                    >
                        {corporate?.titleOrCompanyName}{' '}
                        {corporate?.firstNameOrBRNo} {corporate?.middleName}{' '}
                        {corporate?.surName}
                    </Select.Option>
                ))
            setCorporateOptions(corporate)
        }
    }, [corporateTaps])
    const handleMemberTypeChange = (value: string): void => {
        setMemberType(value)
    }

    const openTagModal = (): void => {
        setTagModalVisible(true)
        setGenerateAccountModalVisible(false)
    }

    const opendleteModel = (referenceId: number, officerType: string): void => {
        const id = officerType === 'CORPORATE' ? 2 : 1
        setRefernceid(referenceId)
        setOfficerTypeId(id)
        setDeleteAccountModalVisible(true)
    }

    const handleMemberSelection = (value: any): void => {
        setSelectedMember(value)
    }

    const handleCancel = (): void => {
        setTimeout(() => {
            getTableData()
            setTagModalVisible(false)
        }, 2000)
    }

    const handleSaveCallBack = (data: CorporateOfficerView): void => {
        setIsEditMode((prevEditStates) => ({
            ...prevEditStates,
            [data?.referenceId ?? 0]: false,
        }))
    }

    const items =
        tagName === ''
            ? [
                  {
                      key: '1',
                      label: 'Attach Tag',
                      onClick: openTagModal,
                  },
                  {
                      key: '2',
                      label: 'Generate Account',
                      onClick: () => {
                          setGenerateAccountModalVisible(true)
                      },
                  },
              ]
            : [
                  {
                      key: '3',
                      label: 'Remove Tag',
                      onClick: () => {
                          setRemoveAccountModel(true)
                      },
                  },
              ]

    const hadelClickGenerateAccounts = (referenceId: number): void => {
        setMemberRefernce(referenceId)
    }

    const Account = async (getreferenceid: number): Promise<void> => {
        try {
            await GenerateAccount(dispatch, getreferenceid)
        } catch (error) {}
    }

    const GenerateAccounts = (getreferenceid: number): void => {
        if (getreferenceid !== null) {
            void Account(getreferenceid)
        }
        setGenerateAccountModalVisible(false)
    }

    const companyType = 1
    const RemoveTagAccount = (getreferenceid: number): void => {
        void AttachIndividual(
            dispatch,
            SelectName,
            companyType,
            getreferenceid,
            1
        )
        setTimeout(() => {
            setRemoveAccountModel(false)
            getTableData()
        }, 3000)
    }

    const getRoleBackgroundColor = (role: string): React.ReactNode => {
        if (role !== '' && typeof role === 'string') {
            const roles = role.split(',')?.map((r) => r.trim())

            return (
                <div className={roleStyle.roleBadge}>
                    {roles?.map((r, index) => (
                        <span
                            key={index}
                            className={`${roleStyle.roleSpan} ${
                                roleStyle[r.toLowerCase()] ??
                                roleStyle.defaultColor
                            }`}
                        >
                            {r.substring(0, 3)}
                        </span>
                    ))}
                </div>
            )
        }
    }

    useEffect(() => {
        const memberDetailss =
            selectedMember != null
                ? Array.isArray(corporateTaps)
                    ? corporateTaps?.find(
                          (member: { referenceId: number }) =>
                              member.referenceId === selectedMember
                      )
                    : null
                : null

        setMemberDetails(memberDetailss)
    }, [corporateTaps, selectedMember])

    const isCorporateOfficer: (officer: CorporateOfficerView) => boolean = (
        officer
    ) => {
        return officer?.type === 'CORPORATE'
    }

    const onCorporateNewData = (
        data: CorporateOfficerView,
        callBack: CallableFunction
    ): void => {
        void handleSaveCorporateNew(data, callBack)
    }

    const handleSaveCorporateNew = async (
        data: CorporateOfficerView,
        callBack: CallableFunction
    ): Promise<void> => {
        const memberReferenceId = memberDetails?.referenceId ?? 0
        const MappdataCorporatenew: officerAddShareholderCorporate = {
            membercorporateid: memberReferenceId,
            companyname: data.titleOrCompanyName,
            companynumber: data.companyNumber,
            corpaddress1: memberDetails?.addressLineOne ?? '',
            corpaddress2: memberDetails?.addressLineTwo ?? '',
            corptown: memberDetails?.town ?? '',
            corpcountry: memberDetails?.country ?? '',
            corppostcode: memberDetails?.postCode ?? '',
            dateofappointment: moment(data.dateofAppointment).format(
                'DD/MM/YYYY'
            ),
            shareholdingpercentage: data.shareHoldingPercentage,
            shareholdertypeid: data.shareHolderTypeId ?? 1,
            noofshares: data.noOfShares,
            corporateid: corporateId,
            isNewShareHolder: 1,
        }
        await SaveOfficerCorporate(dispatch, MappdataCorporatenew)
        ReFetchData()
    }

    const onSavePersonDataNew = (
        data: CorporateOfficerView,
        callBack: CallableFunction
    ): void => {
        void handleSavePersonNew(data, callBack)
    }

    const handleSavePersonNew = async (
        data: CorporateOfficerView,
        callBack: CallableFunction
    ): Promise<void> => {
        const memberReferenceId = memberDetails?.referenceId ?? 0

        const Mapdatanew: officerAddShareholderPerson = {
            memberpersonid: memberReferenceId,
            title: data.titleOrCompanyName ?? 'UNKNOWN',
            firstname: data.firstNameOrBRNo,
            middlename: data.middleName ?? '',
            surname: data.surName,
            dateofbirth: moment(data.dateOfBirth).format('DD/MM/YYYY'),
            address1: memberDetails?.addressLineOne ?? '',
            address2: memberDetails?.addressLineTwo ?? '',
            town: memberDetails?.town ?? '',
            country: memberDetails?.country ?? '',
            postcode: memberDetails?.postCode ?? '',
            mobilenumber: memberDetails?.mobileNo ?? '',
            homenumber: memberDetails?.homeNo ?? '',
            worknumber: memberDetails?.workNo ?? '',
            emailaddress: memberDetails?.email ?? '',
            altemailaddress: memberDetails?.alternativeEmail ?? '',
            ninumber: memberDetails?.niNumber ?? '',
            nationality: memberDetails?.nationality ?? '',
            occupation: memberDetails?.occupation ?? '',
            shareholdingpercentage: data.shareHoldingPercentage,
            noofshares: data.noOfShares,
            shareholdertypeid: data.shareHolderTypeId ?? 1,
            dateofappointment: moment(data.dateofAppointment).format(
                'DD/MM/YYYY'
            ),
            corporateid: corporateId,
            isNewShareHolder: 1,
        }

        await SaveOfficerPerson(dispatch, Mapdatanew)
        ReFetchData()
    }

    const handeldeleteShareholder = (referneceId: number): void => {
        void DeleteShareholder(
            dispatch,
            referneceId,
            corporateId,
            officertypeID
        )
        ReFetchData()
        setDeleteAccountModalVisible(false)
    }
    const onsetName = (name: number): void => {
        setName(name)
    }

    return (
        <Card title="Officer Information">
            {Array.isArray(corporateTaps) &&
                corporateTaps?.map((officer, index) => (
                    <Collapse
                        defaultActiveKey={['']}
                        activeKey={activeKey}
                        className={commonStyles.corporateOfficercollaps}
                        key={index + 1}
                        onChange={(key) => {
                            setActiveKey(key)
                            const names = officer.referenceId
                            onsetName(names)
                        }}
                    >
                        <Collapse.Panel
                            header={
                                <div
                                    style={{
                                        display: 'flex',
                                        justifyContent: 'space-between',
                                    }}
                                >
                                    <span>
                                        {`${officer.titleOrCompanyName} ${officer.firstNameOrBRNo} ${officer.middleName} ${officer.surName}`}
                                    </span>
                                    <span>
                                        {getRoleBackgroundColor(
                                            officer.officerRole
                                        )}
                                    </span>
                                </div>
                            }
                            key={index + 1}
                        >
                            <Row gutter={16} justify="end">
                                {isCorporateOfficer(officer) ? null : (
                                    <Col>
                                        <Dropdown
                                            menu={{ items }}
                                            placement="bottom"
                                            arrow={{ pointAtCenter: true }}
                                            trigger={['click']}
                                        >
                                            <Button
                                                type="primary"
                                                onClick={(): void => {
                                                    hadelClickGenerateAccounts(
                                                        officer.referenceId
                                                    )
                                                }}
                                            >
                                                {tagName !== ''
                                                    ? tagName
                                                    : 'Tag'}
                                                <DownOutlined />
                                            </Button>
                                        </Dropdown>
                                    </Col>
                                )}

                                <Col>
                                    {ActivePremission(
                                        '3000',
                                        PermissionType.DELETE
                                    ) && (
                                        <Tooltip title=" Delete Share Holder">
                                            <Button
                                                type="primary"
                                                onClick={(): void => {
                                                    opendleteModel(
                                                        officer.referenceId,
                                                        officer.type
                                                    )
                                                }}
                                            >
                                                <DeleteOutlined />
                                            </Button>
                                        </Tooltip>
                                    )}
                                </Col>

                                <Col>
                                    <Button
                                        type="primary"
                                        onClick={(): void => {
                                            handleEditClick(officer.referenceId)
                                        }}
                                    >
                                        {isEditMode[officer.referenceId] ? (
                                            <Tooltip title=" View Details">
                                                <EyeOutlined />
                                            </Tooltip>
                                        ) : (
                                            ActivePremission(
                                                '3000',
                                                PermissionType.UPDATE
                                            ) &&
                                            ActivePremission(
                                                '3000',
                                                PermissionType.UPDATE
                                            ) && (
                                                <Tooltip title="Edit">
                                                    <EditOutlined />
                                                </Tooltip>
                                            )
                                        )}
                                    </Button>
                                </Col>
                            </Row>
                            {isEditMode[officer.referenceId] ? (
                                isCorporateOfficer(officer) ? (
                                    <OfficerCorporateEditForm
                                        saveCallBack={(
                                            data: CorporateOfficerView
                                        ) => {
                                            handleSaveCallBack(data)
                                        }}
                                        editData={editData}
                                        iseditNumber={iseditNumber}
                                        corporateId={corporateId}
                                        companyName={companyName}
                                        countryList={[]}
                                        ReFetchData={ReFetchData}
                                    />
                                ) : (
                                    <OfficerEditForm
                                        companyNumber={companyNumber}
                                        editData={editData}
                                        corporateId={corporateId}
                                        iseditNumber={iseditNumber}
                                        countryList={[]}
                                        saveCallBack={(
                                            data: CorporateOfficerView
                                        ) => {
                                            handleSaveCallBack(data)
                                        }}
                                        clientTypeId={clientTypeId}
                                        ReFetchData={ReFetchData}
                                    />
                                )
                            ) : isCorporateOfficer(officer) ? (
                                <CorporateMemberView officer={officer} />
                            ) : (
                                <PersonMemberView officer={officer} />
                            )}
                        </Collapse.Panel>
                    </Collapse>
                ))}
            <Row>
                <Col span={24}>
                    <Collapse defaultActiveKey={['1']}>
                        <Collapse.Panel
                            header="Add Shareholder"
                            key="1"
                            className={commonStyles.corporateOfficercollaps}
                        >
                            <div className={commonStyles.formWrapper}>
                                <Form
                                    name="complex-form"
                                    labelCol={{ span: 24 }}
                                    wrapperCol={{ span: 24 }}
                                    initialValues={{ remember: true }}
                                    layout={'vertical'}
                                    autoComplete="off"
                                    form={form}
                                >
                                    <Row
                                        gutter={16}
                                        className={
                                            commonStyles.officerButtonMargin
                                        }
                                    >
                                        <Col span={12}>
                                            <Form.Item<CorporateOfficerView> label="Member Type">
                                                <Select
                                                    showSearch
                                                    allowClear
                                                    placeholder="Please select"
                                                    onChange={
                                                        handleMemberTypeChange
                                                    }
                                                    value={memberType}
                                                >
                                                    <Select.Option
                                                        key="CORPORATE"
                                                        value="CORPORATE"
                                                    >
                                                        Corporate
                                                    </Select.Option>
                                                    <Select.Option
                                                        key="PERSON"
                                                        value="PERSON"
                                                    >
                                                        Person
                                                    </Select.Option>
                                                </Select>
                                            </Form.Item>
                                        </Col>
                                        <Col span={12}>
                                            <Form.Item<CorporateOfficerView>
                                                label={`Member ${
                                                    memberType === 'CORPORATE'
                                                        ? 'Corporate'
                                                        : 'Person'
                                                }`}
                                            >
                                                <Select
                                                    showSearch
                                                    allowClear
                                                    placeholder="Please select"
                                                    onChange={
                                                        handleMemberSelection
                                                    }
                                                    optionFilterProp="children"
                                                >
                                                    <Select.Option
                                                        key="placeholder"
                                                        value=""
                                                    >
                                                        Please select
                                                    </Select.Option>
                                                    {memberType === 'CORPORATE'
                                                        ? corporateOptions
                                                        : personOptions}
                                                </Select>
                                            </Form.Item>
                                        </Col>
                                    </Row>
                                </Form>
                                {memberType === 'PERSON' ? (
                                    <CorporateAddShareholderPersonForm
                                        onSave={onSavePersonDataNew}
                                        member={memberDetails ?? {}}
                                    />
                                ) : memberType === 'CORPORATE' ? (
                                    <CorporateAddShreholderForm
                                        onSave={onCorporateNewData}
                                        member={memberDetails ?? {}}
                                        companyNumber={companyNumber}
                                    />
                                ) : null}
                            </div>
                        </Collapse.Panel>
                    </Collapse>
                </Col>
            </Row>

            <FullWidthModal
                modalStatus={generateAccountModalVisible}
                closeModal={() => {
                    setGenerateAccountModalVisible(false)
                }}
                tittle="You are about to generate the individual account. Click 'Yes' to continue."
                smallTittle="Warning: This action cannot be undone!"
                yesButtonFunction={() => {
                    GenerateAccounts(memeberreferenceId)
                }}
            />
            <FullWidthModal
                modalStatus={deleteAccountModalVisible}
                closeModal={() => {
                    setDeleteAccountModalVisible(false)
                }}
                tittle="You are about to delete this shareholder. Click 'Yes' to continue."
                smallTittle="Warning: This action cannot be undone!"
                yesButtonFunction={() => {
                    handeldeleteShareholder(referenceId)
                }}
            />
            <Modal
                title="Please select the individual Account"
                onCancel={() => {
                    setTagModalVisible(false)
                }}
                visible={tagModalVisible}
                footer={null}
                width={'50%'}
            >
                <OfficerPersonModelForm
                    referencId={memeberreferenceId}
                    onCancel={handleCancel}
                />
            </Modal>

            <FullWidthModal
                modalStatus={removeAccounModel}
                closeModal={() => {
                    setRemoveAccountModel(false)
                }}
                tittle="You are about to remove this business tag. Click 'Yes' to continue."
                smallTittle="Warning: This action cannot be undone!"
                yesButtonFunction={() => {
                    RemoveTagAccount(memeberreferenceId)
                }}
            />
        </Card>
    )
}
const mapStateToProps = (state: any): any => {
    return {
        corporateTaps: state.client.corporateTaps,
    }
}
export default connect(mapStateToProps)(Officer)
