import React from 'react'
import { Col, Row, Form, Button } from 'antd'
import { LoginApi } from '../../Services/Auth'
import styles from './login.less'
import InputField from '../../Components/InputField'
import InputFieldPassword from '../../Components/InputFieldPassword'
import InputFieldCheckBox from '../../Components/InputFieldCheckBox'
import { useDispatch } from 'react-redux'
import type { loginDataInterface } from '../../Types/Auth'
import commonStyles from '../../Utils/Common.less'

export default function Login(): React.ReactElement {
    const dispatch = useDispatch()

    const onSubmit = (data: loginDataInterface): void => {
        const newData = {
            ipAddress: '',
            username: data.username,
            password: data.password,
        }
        LoginApi(dispatch, newData)
    }

    const customizeRequiredMark = (
        label: React.ReactNode,
        { required }: { required: boolean }
    ): React.ReactElement => (
        <>
            {label}
            &nbsp;
            {required && <span className={commonStyles.requireIcon}>*</span>}
        </>
    )

    return (
        <>
            <Row className={styles.main}>
                <Col className={styles.loginPage} span={10}>
                    <div className={styles.backgroundImage} />
                </Col>
                <Col span={14}>
                    <div className={styles.loginContent}>
                        <div className={styles.loginTittleContainter}>
                            <div className={styles.loginTittle}>
                                Hello Again!!
                            </div>
                            <div className={styles.loginTittleDesc}>
                                Enter your credentials to access your account
                            </div>
                        </div>
                        <Form
                            initialValues={{ remember: true }}
                            onFinish={onSubmit}
                            onFinishFailed={() => {}}
                            autoComplete="off"
                            layout="vertical"
                            requiredMark={customizeRequiredMark}
                        >
                            <InputField
                                name="username"
                                placeHolder="ex. email@domain.com"
                                label="Username"
                                reuireMessage="Please input your username!"
                            />
                            <InputFieldPassword
                                name="password"
                                label="Password"
                                reuireMessage="Please input your password!"
                            />
                            <InputFieldCheckBox
                                name="remember"
                                label="Remember Me"
                            >
                                <a className={styles.loginFormForgot} href="">
                                    Forgot password
                                </a>
                            </InputFieldCheckBox>
                            <Form.Item>
                                <Button
                                    type="primary"
                                    htmlType="submit"
                                    className={styles.loginFormButton}
                                >
                                    Sign In
                                </Button>
                            </Form.Item>
                        </Form>
                        <div className={styles.doNotHaveAccountText}>
                            Don’t have an account?
                            <a href="">Sign Up</a>
                        </div>
                    </div>

                    <div className={styles.powerdByText}>
                        © 2023 All Rights Reserved. IYCONNECT®️ is a registered
                        trademark of
                        <a
                            href="https://www.iykons.com/"
                            target="_blank"
                            rel="noreferrer"
                        >
                            {' '}
                            IYKONS LIMITED.
                        </a>
                    </div>
                </Col>
            </Row>
        </>
    )
}
