import React, { useEffect, useState, useCallback } from 'react'
import { connect, useDispatch } from 'react-redux'
import { Card, Table, Space, Modal, Switch, Tooltip } from 'antd'
import commonStyles from '../../../Utils/Common.less'
import AddNewButton from '../../../Components/AddNewButton'
import SearchInput from '../../../Components/SearchInput'
import type { ColumnsType, TableProps } from 'antd/es/table'
import {
    GetHolidayList,
    SaveHoliday,
    ActiveInactiveHoliday,
    DeleteHoliday,
} from '../../../Services/HolidayDetails'
import type { TableParamsInterface } from '../../../Types/CommonType'
import {
    commonTableParams,
    setCommonTableParamsData,
} from '../../../Utils/CommontTable'
import FullWidthModal from '../../../Components/FullWidthModal'
import { ReactComponent as DeleteIcon } from '../../../Assest/Svg/DeleteIcon.svg'
import { ReactComponent as EditIcon } from '../../../Assest/Svg/EditIcon.svg'
import type {
    HolidaysDetailsListDataTypeInterface,
    HolidayDetailsFormDataInterface,
    HolidaysDetailsListStateInterface,
} from '../../../Types/HolidayDetails'
import HolidayForm from './holidaysDetailsForm'
import { GetCountryList } from '../../../Services/Common'
import calculatePagination from '../../../Utils/Pagination'
import ActivePremission from '../../../Utils/premissins'
import { PermissionType } from '../../../Types/Enum/PermissionType'

export const HolidayItemDefaultData = {
    companyHolidayId: '0',
    holidayName: '',
    date: '',
    description: '',
    activeStatus: '',
    countryName: '',
    countryId: 0,
}

const HolidayList = (
    props: HolidaysDetailsListStateInterface
): React.ReactElement => {
    const dispatch = useDispatch()
    const { holidaysDetailsList, holidaysDetailsListCount } = props
    const [tableParams, setTableParams] =
        useState<TableParamsInterface>(commonTableParams)
    const [deleteModalStatus, setDeleteModalStatus] = useState(false)
    const [createEditModalStatus, setCreateEditModalStatus] = useState(false)
    const [companyHolidayId, setCompanyHolidayId] = useState('')
    const [editModalTittle, setEditModalTittle] = useState('Create Holiday')
    const [editData, setEditData] = useState<HolidayDetailsFormDataInterface>(
        HolidayItemDefaultData
    )
    const [activeInactiveModalStatus, setActiveInactiveModalStatus] =
        useState(false)
    const [activeInactiveStatusLabel, setActiveInactiveStatusLabel] =
        useState('')
    const [currentPage, setCurrentPage] = useState(1)
    const [itemsPerPage, setItemsPerPage] = useState(10)

    useEffect(() => {
        GetCountryList(dispatch)
    }, [])

    const getTableData = useCallback(() => {
        const { pageSize, pageNo, sortOrderList, searchCriteriaList } =
            setCommonTableParamsData(tableParams)
        GetHolidayList(dispatch, {
            pageNo,
            pageSize,
            searchCriteriaList,
            sortOrderList,
        })
    }, [
        tableParams?.sorter,
        tableParams.pagination?.current,
        tableParams?.filter,
    ])

    useEffect(() => {
        getTableData()
    }, [getTableData])

    const handleDataCountChange = useCallback(() => {
        setTableParams({
            ...tableParams,
            pagination: {
                ...tableParams.pagination,
                total: holidaysDetailsListCount,
            },
        })
    }, [holidaysDetailsListCount])

    useEffect(() => {
        handleDataCountChange()
    }, [handleDataCountChange])

    const handlePageChange = (pageNo: number, pageSize: number): void => {
        setCurrentPage(pageNo)
        setItemsPerPage(pageSize)
    }

    const pagination = calculatePagination(
        currentPage,
        holidaysDetailsListCount,
        itemsPerPage
    )

    const columns: ColumnsType<HolidaysDetailsListDataTypeInterface> = [
        {
            title: 'Country',
            dataIndex: 'countryName',
        },
        {
            title: 'Holiday',
            dataIndex: 'holidayName',
            sorter: true,
            sortDirections: ['descend', 'ascend'],
        },
        {
            title: 'Date',
            dataIndex: 'date',
        },
        {
            width: 300,
            title: 'Description',
            dataIndex: 'description',
        },
        {
            title: 'Action',
            key: 'action',
            render: (_, record: HolidaysDetailsListDataTypeInterface) => {
                return (
                    <Space size="middle">
                        {ActivePremission('1006', PermissionType.UPDATE) && (
                            <Tooltip title="Edit Holiday">
                                <EditIcon
                                    onClick={() => {
                                        openEditModal(record?.companyHolidayId)
                                    }}
                                    className={commonStyles.clickableIcon}
                                />
                            </Tooltip>
                        )}
                        <Tooltip
                            title={
                                record.activeStatus === 'Active'
                                    ? 'Active'
                                    : 'Inactive'
                            }
                        >
                            <Switch
                                checked={record.activeStatus === 'Active'}
                                onClick={() => {
                                    activeInactiveModalFunction(
                                        record?.companyHolidayId,
                                        record.activeStatus
                                    )
                                }}
                            />
                        </Tooltip>
                        {ActivePremission('1006', PermissionType.DELETE) && (
                            <Tooltip title="Delete">
                                <DeleteIcon
                                    onClick={() => {
                                        deleteModalFunction(
                                            record?.companyHolidayId
                                        )
                                    }}
                                    className={commonStyles.clickableIcon}
                                />
                            </Tooltip>
                        )}
                    </Space>
                )
            },
        },
    ]

    const onChangeTableParams: TableProps<HolidaysDetailsListDataTypeInterface>['onChange'] =
        (pagination, filters, sorter: any, extra) => {
            handlePageChange(pagination.current ?? 1, pagination.pageSize ?? 10)
            setTableParams({
                ...tableParams,
                pagination,
                sorter: {
                    field: sorter?.field ?? '',
                    order: sorter?.order ?? '',
                },
            })
        }

    const onChangeText = (data: React.ChangeEvent<HTMLInputElement>): void => {
        const searchText = data.target.value.trim().toLowerCase()
        onChangeFilter(searchText)
    }
    const onChangeFilter = (data: string): void => {
        setTableParams({
            ...tableParams,
            filter: {
                fieldName: 'holidayName',
                searchOperator: 10,
                fieldValue1: data,
                fieldValue2: '',
            },
        })
    }

    const deleteModalFunction = (id: string): void => {
        setDeleteModalStatus(!deleteModalStatus)
        let companyHolidayId = ''
        if (!deleteModalStatus) {
            companyHolidayId = id
        }
        setCompanyHolidayId(companyHolidayId)
    }

    const deleteHoliday = (): void => {
        DeleteHoliday(dispatch, companyHolidayId, getTableData)
        deleteModalFunction('')
    }

    const openEditModal = (id: string): void => {
        const datas = holidaysDetailsList.find(
            (data: HolidayDetailsFormDataInterface) => {
                return data.companyHolidayId === id
            }
        )
        let title = 'Create Holiday'

        if (id !== null && id !== undefined) {
            title = 'Edit Holiday'
        }

        setEditModalTittle(title)
        setEditData(datas ?? HolidayItemDefaultData)
        changeCreateEditModalStatus()
    }

    const changeCreateEditModalStatus = (): void => {
        setCreateEditModalStatus(!createEditModalStatus)
    }

    const activeInactiveModalFunction = (
        id: string,
        activeStatus: string
    ): void => {
        setActiveInactiveModalStatus(!activeInactiveModalStatus)
        let companyHolidayId = ''
        if (!activeInactiveModalStatus) {
            companyHolidayId = id
        }
        let label = 'Active'
        if (activeStatus === 'Active') {
            label = 'Inactive'
        }
        setActiveInactiveStatusLabel(label)
        setCompanyHolidayId(companyHolidayId)
    }

    const updateActiveInactiveHoliday = (): void => {
        let status = 'Active'
        if (activeInactiveStatusLabel === 'Inactive') status = 'Inactive'
        ActiveInactiveHoliday(dispatch, companyHolidayId, status, getTableData)
        activeInactiveModalFunction('', '')
    }

    const onSaveHoliday = (
        data: HolidayDetailsFormDataInterface,
        callBack: CallableFunction
    ): void => {
        void saveHolidayData(data, callBack)
    }
    const saveHolidayData = async (
        data: HolidayDetailsFormDataInterface,
        callBack: CallableFunction
    ): Promise<void> => {
        await SaveHoliday(dispatch, data)
            .then((result) => {
                if (result !== null && result !== undefined) {
                    changeCreateEditModalStatus()
                    getTableData()
                    callBack()
                }
            })
            .catch(() => {})
    }

    return (
        <>
            <Card
                title="Holiday"
                extra={
                    <AddNewButton
                        name="Add New"
                        clickAction={() => {
                            openEditModal('')
                        }}
                    />
                }
                className={commonStyles.card}
            >
                <SearchInput
                    placeHolder={'Search Holiday by keywords'}
                    onSearch={onChangeFilter}
                    onChange={onChangeText}
                    width="478"
                />
                <div className={commonStyles.table}>
                    <Table
                        columns={columns}
                        dataSource={holidaysDetailsList}
                        onChange={onChangeTableParams}
                        pagination={pagination}
                    />
                </div>
                <FullWidthModal
                    modalStatus={deleteModalStatus}
                    closeModal={deleteModalFunction}
                    tittle="You are about to delete this holiday. Click 'Yes' to continue."
                    smallTittle="Warning: This action cannot be undone!"
                    yesButtonFunction={deleteHoliday}
                />
                <FullWidthModal
                    modalStatus={activeInactiveModalStatus}
                    closeModal={() => {
                        activeInactiveModalFunction('', '')
                    }}
                    tittle={`You are about to ${activeInactiveStatusLabel} this holiday. Click 'Yes' to continue.`}
                    smallTittle=""
                    yesButtonFunction={updateActiveInactiveHoliday}
                />
                <Modal
                    footer={null}
                    onCancel={changeCreateEditModalStatus}
                    width={'40%'}
                    title={editModalTittle}
                    open={createEditModalStatus}
                >
                    <HolidayForm onSave={onSaveHoliday} editData={editData} />
                </Modal>
            </Card>
        </>
    )
}

const mapStateToProps = (state: any): HolidaysDetailsListStateInterface => {
    return {
        holidaysDetailsList: state.initial.holidaysDataList,
        holidaysDetailsListCount: state.initial.holidaysListCount,
    }
}

export default connect(mapStateToProps)(HolidayList)
