import { Col, Form, Switch } from 'antd'
import { type ClientVerificationData } from '../../../../../../Types/Client/RegisteredClients/Corporate'
import complianceStyles from './compilance.less'
import React from 'react'
const DocumentVerification = (props: {
    clientCompanyVerificationViewList: ClientVerificationData[]
    docuswitchStates: Record<number, boolean>
    handleSwitchChangeDocument: (
        verificationId: number,
        checked: boolean
    ) => void
}): React.ReactElement => {
    const {
        clientCompanyVerificationViewList,
        docuswitchStates,
        handleSwitchChangeDocument,
    } = props
    return (
        <>
            {Array.isArray(clientCompanyVerificationViewList) &&
                clientCompanyVerificationViewList?.map(
                    (verificationItem: ClientVerificationData) => {
                        return (
                            <Col
                                span={24}
                                key={
                                    verificationItem.clientCompanyVerificationId
                                }
                            >
                                <div className={complianceStyles.serviceItem}>
                                    <Form.Item
                                        name={`corporateDocstatus-${verificationItem.clientCompanyVerificationId}`}
                                    >
                                        <Switch
                                            checked={
                                                docuswitchStates[
                                                    verificationItem
                                                        .clientVerificationId
                                                ]
                                            }
                                            onChange={(checked) => {
                                                handleSwitchChangeDocument(
                                                    verificationItem.clientVerificationId,
                                                    checked
                                                )
                                            }}
                                        />
                                        {'  '}
                                        <span>
                                            {
                                                verificationItem.clientVerificationName
                                            }
                                        </span>
                                    </Form.Item>
                                </div>
                            </Col>
                        )
                    }
                )}
        </>
    )
}

export default DocumentVerification
