import React, { useEffect, useState, useCallback } from 'react'
import { connect, useDispatch } from 'react-redux'
import { Button, Card, Checkbox, Table, Tooltip } from 'antd'
import commonStyles from '../../../../Utils/Common.less'
import type { ColumnsType, TableProps } from 'antd/es/table'
import type { TableParamsInterface } from '../../../../Types/CommonType'
import {
    commonTableParams,
    setCommonTableParamsData,
} from '../../../../Utils/CommontTable'
import {
    type ProjectManagmentStausListInterface,
    type ProjectManagmentStausListDataTypeInterface,
    type CheckboxState,
} from '../../../../Types/ProjectMangement'
import {
    AddNotificationDetails,
    GetSatusList,
} from '../../../../Services/ProjectManagement'
import { SaveOutlined } from '@ant-design/icons'
import { SuccessMessage } from '../../../../Utils/Notification'
export const ProjectNotificationDefaultData = {
    projectStatusId: 0,
    projectNotificationForIds: 0,
    specificUserId: 0,
}

const ProjectList = (
    props: ProjectManagmentStausListInterface & { projectId: string }
): React.ReactElement => {
    const dispatch = useDispatch()
    const {
        ProjectManagmentStausList,
        ProjectManagmentStausListCount,
        projectId,
    } = props

    const [tableParams, setTableParams] =
        useState<TableParamsInterface>(commonTableParams)

    type CheckboxStates = Record<string, CheckboxState>

    const [checkboxStates, setCheckboxStates] = useState<CheckboxStates>({})

    useEffect(() => {
        const initialStates: CheckboxStates = {}
        ProjectManagmentStausList.forEach((item) => {
            initialStates[item.projectStatusId] = {
                assigneeUser: false,
                reportingUser: false,
                projectLeadUser: false,
                projectAssigneeUser: false,
                currentStatusAssignee: false,
                movingStatusAssignee: false,
                specificUser: false,
            }
        })
        setCheckboxStates(initialStates)
    }, [ProjectManagmentStausList])
    const handleCheckboxChange = (
        statusId: string,
        field: keyof CheckboxState,
        value: boolean
    ): void => {
        setCheckboxStates((prev) => {
            const newState = {
                ...prev,
                [statusId]: {
                    ...prev[statusId],
                    [field]: value,
                },
            }
            return newState
        })
    }

    const getTableData = useCallback(() => {
        const { pageSize, pageNo, sortOrderList, searchCriteriaList } =
            setCommonTableParamsData(tableParams)
        GetSatusList(dispatch, projectId, {
            pageNo,
            pageSize,
            searchCriteriaList,
            sortOrderList,
        })
    }, [
        projectId,
        tableParams?.sorter,
        tableParams.pagination?.current,
        tableParams?.filter,
    ])

    useEffect(() => {
        getTableData()
    }, [getTableData])

    const handleDataCountChange = useCallback(() => {
        setTableParams({
            ...tableParams,
            pagination: {
                ...tableParams.pagination,
                total: ProjectManagmentStausListCount,
            },
        })
    }, [ProjectManagmentStausListCount])
    useEffect(() => {
        handleDataCountChange()
    }, [handleDataCountChange])

    const handleRowSave = async (projectStatusId: string): Promise<void> => {
        const notificationDetails: any[] = []

        // Collect notification details for each checkbox state
        ProjectManagmentStausList.forEach((record) => {
            const checkboxState = checkboxStates[record.projectStatusId]
            const statusNumbers: number[] = []

            if (checkboxState.assigneeUser) statusNumbers.push(1)
            if (checkboxState.reportingUser) statusNumbers.push(2)
            if (checkboxState.projectAssigneeUser) statusNumbers.push(8)
            if (checkboxState.projectLeadUser) statusNumbers.push(16)
            if (checkboxState.currentStatusAssignee) statusNumbers.push(32)
            if (checkboxState.movingStatusAssignee) statusNumbers.push(64)

            if (checkboxState.specificUser) statusNumbers.push(128)

            statusNumbers.forEach((notification) => {
                notificationDetails.push({
                    projectStatusId: record.projectStatusId,
                    projectNotificationForIds: notification,
                    specificUserId: checkboxState.specificUser ? 1 : 0,
                })
            })
        })

        try {
            // Make a single API call to save all notification details
            await Promise.all(
                notificationDetails.map(
                    async (detail) =>
                        await AddNotificationDetails(dispatch, detail)
                )
            )

            // Reset checkbox states after successful save
            const updatedCheckboxStates: CheckboxStates = {}
            Object.keys(checkboxStates).forEach((statusId) => {
                updatedCheckboxStates[statusId] = {
                    assigneeUser: false,
                    reportingUser: false,
                    projectLeadUser: false,
                    projectAssigneeUser: false,
                    currentStatusAssignee: false,
                    movingStatusAssignee: false,
                    specificUser: false,
                }
            })
            setCheckboxStates(updatedCheckboxStates)

            SuccessMessage(
                ' Project Status notification is update Successfully'
            )
        } catch (error) {
            console.error('Failed to save notifications:', error)
        }
    }

    const columns: ColumnsType<ProjectManagmentStausListDataTypeInterface> = [
        {
            title: 'Status Name',
            dataIndex: 'projectStatusName',

            width: '300px',
        },
        {
            title: 'Notification To',
            dataIndex: 'currentUser',
            key: 'currentUser',

            render: (text, record) => (
                <>
                    <label>Assignee User</label>
                    <Checkbox
                        style={{ padding: '5px' }}
                        checked={
                            checkboxStates[record.projectStatusId]?.assigneeUser
                        }
                        onChange={(e) => {
                            handleCheckboxChange(
                                record.projectStatusId,
                                'assigneeUser',
                                e.target.checked
                            )
                        }}
                    />
                    <label style={{ padding: '8px' }}>Reporting User</label>
                    <Checkbox
                        style={{ padding: '3px' }}
                        checked={
                            checkboxStates[record.projectStatusId]
                                ?.reportingUser
                        }
                        onChange={(e) => {
                            handleCheckboxChange(
                                record.projectStatusId,
                                'reportingUser',
                                e.target.checked
                            )
                        }}
                    />
                    <label style={{ padding: '8px' }}>Project Lead User</label>
                    <Checkbox
                        style={{ padding: '3px' }}
                        checked={
                            checkboxStates[record.projectStatusId]
                                ?.projectLeadUser
                        }
                        onChange={(e) => {
                            handleCheckboxChange(
                                record.projectStatusId,
                                'projectLeadUser',
                                e.target.checked
                            )
                        }}
                    />
                    <label style={{ padding: '8px' }}>
                        Project Assignee User
                    </label>
                    <Checkbox
                        style={{ padding: '3px' }}
                        checked={
                            checkboxStates[record.projectStatusId]
                                ?.projectAssigneeUser
                        }
                        onChange={(e) => {
                            handleCheckboxChange(
                                record.projectStatusId,
                                'projectAssigneeUser',
                                e.target.checked
                            )
                        }}
                    />
                    <label style={{ padding: '8px' }}>
                        Current Status Assignee
                    </label>
                    <Checkbox
                        style={{ padding: '3px' }}
                        checked={
                            checkboxStates[record.projectStatusId]
                                ?.currentStatusAssignee
                        }
                        onChange={(e) => {
                            handleCheckboxChange(
                                record.projectStatusId,
                                'currentStatusAssignee',
                                e.target.checked
                            )
                        }}
                    />
                    <label style={{ padding: '8px' }}>
                        Moving Status Assignee
                    </label>
                    <Checkbox
                        style={{ padding: '3px' }}
                        checked={
                            checkboxStates[record.projectStatusId]
                                ?.movingStatusAssignee
                        }
                        onChange={(e) => {
                            handleCheckboxChange(
                                record.projectStatusId,
                                'movingStatusAssignee',
                                e.target.checked
                            )
                        }}
                    />
                    <label style={{ padding: '8px' }}>Specific User</label>
                    <Checkbox
                        style={{ padding: '3px' }}
                        checked={
                            checkboxStates[record.projectStatusId]?.specificUser
                        }
                        onChange={(e) => {
                            handleCheckboxChange(
                                record.projectStatusId,
                                'specificUser',
                                e.target.checked
                            )
                        }}
                    />
                </>
            ),
        },
        {
            title: '',
            key: 'action',

            render: (_, record) => (
                <Button
                    type="primary"
                    onClick={() => {
                        void handleRowSave(record.projectStatusId)
                    }}
                >
                    <Tooltip title="Save Notification">
                        <SaveOutlined />
                    </Tooltip>
                </Button>
            ),
        },
    ]

    const onChangeTableParams: TableProps<ProjectManagmentStausListDataTypeInterface>['onChange'] =
        (pagination, filters, sorter: any) => {
            setTableParams({
                ...tableParams,
                pagination,
                sorter: {
                    field: sorter?.field ?? '',
                    order: sorter?.order ?? '',
                },
            })
        }

    return (
        <>
            <Card title="Project Management" className={commonStyles.card}>
                <div className={commonStyles.table}>
                    <Table
                        columns={columns}
                        dataSource={ProjectManagmentStausList}
                        onChange={onChangeTableParams}
                    />
                </div>
            </Card>
        </>
    )
}

const mapStateToProps = (state: any): ProjectManagmentStausListInterface => {
    return {
        ProjectManagmentStausList: state.initial.ProjectManagmentStausList,
        ProjectManagmentStausListCount:
            state.initial.ProjectManagmentStausListCount,
    }
}

export default connect(mapStateToProps)(ProjectList)
