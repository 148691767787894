import { Card, Col, Row, Button } from 'antd'
import React from 'react'
import hederimage from '../../../../../../../Assest/Svg/pattern-2.svg'
import antimoney from './antiMoney.less'
import { useLocation } from 'react-router-dom'
import {
    PDFDocument,
    type PDFFont,
    type PDFPage,
    type RGB,
    rgb,
    StandardFonts,
} from 'pdf-lib'
import { convertImageToBase64 } from '../../../../../../../Components/base64ImageCovert'
const AntiMoneyLaundering = (): React.ReactElement => {
    const location = useLocation()
    const previewData = location.state?.previewqutation
    const businessDocumentTemplate = previewData?.businessDocumentTemplate
    const quotationHeader = previewData?.quotationHeader
    const quotationCompany = previewData?.quotationCompany
    // const quotationDetails = previewData?.quotationDetails
    const handleDownloadPDFDoc = async (): Promise<void> => {
        // const Url = `https://iykons-s3-storage.s3.eu-west-1.amazonaws.com/Document/BusinessLogo/637490793663999379.png`

        const pdfDoc = await PDFDocument.create()
        const page1 = pdfDoc.addPage([650, 842]) // A4 size in points

        // Load and embed the fonts
        const timesRomanFont = await pdfDoc.embedFont(StandardFonts.TimesRoman)
        // const timesItalicFont = await pdfDoc.embedFont('Times-Italic')
        const timesRomanBoldFont = await pdfDoc.embedFont(
            StandardFonts.TimesRomanBold
        )
        const convertSvgToPng = async (svgUrl: string): Promise<string> => {
            return await new Promise((resolve, reject) => {
                const canvas = document.createElement('canvas')
                const ctx = canvas.getContext('2d')
                const img = new Image()

                img.onload = () => {
                    // Set canvas dimensions to match SVG
                    canvas.width = img.width
                    canvas.height = img.height

                    // Draw SVG on canvas
                    ctx?.drawImage(img, 0, 0)
                    resolve(canvas.toDataURL('image/png'))
                }

                img.onerror = reject
                img.src = svgUrl
            })
        }

        try {
            // Convert the SVG to PNG data URL
            const headerPngDataUrl = await convertSvgToPng(hederimage)
            const headerImageBytes = await fetch(headerPngDataUrl).then(
                async (res) => await res.arrayBuffer()
            )
            const headerImage = await pdfDoc.embedPng(headerImageBytes)

            // Draw the header image
            page1.drawImage(headerImage, {
                x: 25,
                y: 810,
                width: 600,
                height: 10,
            })
        } catch (error) {
            console.error('Error converting SVG to PNG:', error)
        }
        // Set the font size and color
        const fontColor = rgb(0, 0, 0)

        // Draw the header image

        // Draw company name and details
        page1.drawText(businessDocumentTemplate?.companyName ?? '', {
            x: 65,
            y: 780,
            size: 14,
            font: timesRomanBoldFont,
            color: fontColor,
        })

        // Draw additional details
        page1.drawText(
            `${businessDocumentTemplate?.addressOne ?? ''}, ${
                businessDocumentTemplate?.addressTwo ?? ''
            }, ${businessDocumentTemplate?.town ?? ''}, ${
                businessDocumentTemplate?.postCode ?? ''
            }, ${businessDocumentTemplate?.country ?? ''}`,
            {
                x: 65,
                y: 765,
                size: 7,
                font: timesRomanFont,
                color: fontColor,
            }
        )
        page1.drawText(businessDocumentTemplate?.website ?? '', {
            x: 65,
            y: 755,
            size: 7,
            font: timesRomanFont,
            color: fontColor,
        })

        try {
            const imageUrl = `https://api.allorigins.win/raw?url=${businessDocumentTemplate?.companyLogo}`
            const base64String = await convertImageToBase64(imageUrl)

            const imageBytes = await fetch(base64String).then(
                async (res) => await res.arrayBuffer()
            )
            const image = await pdfDoc.embedPng(imageBytes)
            page1.drawImage(image, {
                x: 450,
                y: 750,
                width: 120,
                height: 50,
            })
        } catch (error) {
            console.error('Error embedding image:', error)
        }

        // Draw the Quotation title
        page1.drawText('ANTI-MONEY LAUNDERING', {
            x: 65,
            y: 720,
            size: 24,
            font: timesRomanBoldFont,
            color: fontColor,
        })

        // Draw quotation details

        page1.drawText(
            [
                `${quotationCompany?.companyName}`,
                `${quotationCompany?.addressLine1},`,
                `${quotationCompany?.addressLine2}`,
                `${quotationCompany?.town}`,
                `${quotationCompany?.postCode}`,
                `${quotationCompany?.country}`,
            ].join('\n'),
            {
                x: 65,
                y: 705,
                size: 9,
                font: timesRomanFont,
                color: fontColor,
                lineHeight: 10,
            }
        )
        page1.drawText(`Dear  ${quotationCompany?.attentionName}`, {
            x: 65,
            y: 640,
            size: 8,
            font: timesRomanFont,
            color: fontColor,
        })
        const drawLeftAlignedText = (
            page1: PDFPage,
            text: string,
            x: number,
            y: number,
            width: number,
            fontSize: number,
            font: PDFFont,
            fontColor: RGB
        ): void => {
            const words = text.split(' ')
            let line = ''
            const lines = []
            const spaceWidth = font.widthOfTextAtSize(' ', 12)
            let currentWidth = 0

            // Calculate the lines of text
            words.forEach((word: string) => {
                const wordWidth = font.widthOfTextAtSize(word, fontSize)
                if (currentWidth + wordWidth + spaceWidth <= width) {
                    line += (line?.length > 0 ? ' ' : '') + word
                    currentWidth += wordWidth + spaceWidth
                } else {
                    lines.push(line)
                    line = word
                    currentWidth = wordWidth + spaceWidth
                }
            })
            lines.push(line)

            // Draw each line with left alignment
            lines.forEach((line, index) => {
                page1.drawText(line, {
                    x,
                    y: y - index * (fontSize + 2), // Adjust line height as needed
                    size: fontSize,
                    font,
                    color: fontColor,
                })
            })
        }

        drawLeftAlignedText(
            page1,
            ` In accordance with the recommendations of our professional supervisory body, the ACCA, we must comply with onerous duties imposed by`,
            65, // x position
            620, // y position
            520, // Width for wrapping text
            10,
            timesRomanFont,
            fontColor
        )
        drawLeftAlignedText(
            page1,
            `• The Proceeds of Crime Act 2002 (POCA) as amended. Particular attention is drawn to the Serious Organised Crime and Police Act 2005 (SOCPA);`,
            95, // x position
            580, // y position
            520, // Width for wrapping text
            10,
            timesRomanFont,
            fontColor
        )
        drawLeftAlignedText(
            page1,
            `• The Terrorism Act 2000 (TA 2000) as amended. Particular attention is drawn to the Anti- Terrorism Crime and Security Act 2001 (ATCSA) and the Terrorism Act 2006 (TA 2006)`,
            95, // x position
            550, // y position
            520, // Width for wrapping text
            10,
            timesRomanFont,
            fontColor
        )
        drawLeftAlignedText(
            page1,
            `• The Money Laundering, Terrorist Financing and Transfer of Funds (Information on the Payer) Regulations 2017 (the 2017 Regulations) as amended. Particular attention is drawn to The Money Laundering and Terrorist Financing (Amendment) Regulations 2019 Terrorist Asset-Freezing Act 2010;`,
            95, // x position
            520, // y position
            520, // Width for wrapping text
            10,
            timesRomanFont,
            fontColor
        )
        drawLeftAlignedText(
            page1,
            `• Anti-terrorism, Crime and Security Act 2001;`,
            95, // x position
            480, // y position
            520, // Width for wrapping text
            10,
            timesRomanFont,
            fontColor
        )
        drawLeftAlignedText(
            page1,
            `• Counter-terrorism Act 2008, Schedule 7;`,
            95, // x position
            460, // y position
            520, // Width for wrapping text
            10,
            timesRomanFont,
            fontColor
        )
        drawLeftAlignedText(
            page1,
            `• The Criminal Finances Act 2017`,
            95, // x position
            440, // y position
            520, // Width for wrapping text
            10,
            timesRomanFont,
            fontColor
        )
        drawLeftAlignedText(
            page1,
            `• Bribery Act 2010`,
            95, // x position
            420, // y position
            520, // Width for wrapping text
            10,
            timesRomanFont,
            fontColor
        )
        drawLeftAlignedText(
            page1,
            `• Fraud Act 2006,`,
            95, // x position
            400, // y position
            520, // Width for wrapping text
            10,
            timesRomanFont,
            fontColor
        )
        drawLeftAlignedText(
            page1,
            `Therefore, it is our obligation to conduct client due diligence in accordance with the above regulations for all regulated activities. Failing to comply with regulations could lead to a prosecution resulting in unlimited fines and /or prison terms of many years.`,
            65, // x position
            380, // y position
            520, // Width for wrapping text
            10,
            timesRomanFont,
            fontColor
        )
        drawLeftAlignedText(
            page1,
            `Therefore, as part of client due diligence, we require two forms of identification from you. (NB: In partnership, we require two forms of identification for each partner). Please provide a copy of the documentation (Please refer to the attached leaflet about relevant documentation needed from you) to prove your identity. If you are unable to provide either of these, then please contact us for further advice.`,
            65, // x position
            330, // y position
            520, // Width for wrapping text
            10,
            timesRomanFont,
            fontColor
        )
        drawLeftAlignedText(
            page1,
            ` We may need to obtain ‘satisfactory evidence’ to confirm your identity for ongoing monitoring and enhanced Due diligence before we accept your instructions. In certain circumstances, we may need to obtain evidence confirming third parties’ identities, the source of any funds or other property, the purpose of any instructions, or any other matter. We may also need to obtain such evidence after we have begun to act on your instructions.`,
            65, // x position
            270, // y position
            520, // Width for wrapping text
            10,
            timesRomanFont,
            fontColor
        )
        drawLeftAlignedText(
            page1,
            ` In accordance abovementioned laws and regulations, you agree to waive your right to confidentiality to the extent of any report made, documents provided, or information disclosed to National Crime Agency (NCA) and other government organisations. We are required to report directly to NCA without prior reference to you or your representatives if our  Money Laundering Reporting Officer(MLRO) becomes suspicious of you involved in money laundering.`,
            65, // x position
            210, // y position
            520, // Width for wrapping text
            10,
            timesRomanFont,
            fontColor
        )
        drawLeftAlignedText(
            page1,
            `  Moreover, it is your responsibility to keep us informed of the changes in your personal circumstances. By signing the letter, you confirm that you have thoroughly read and understood the contents of this letter.`,
            65, // x position
            150, // y position
            520, // Width for wrapping text
            10,
            timesRomanFont,
            fontColor
        )
        page1.drawText(`Client Name:`, {
            x: 65,
            y: 100,
            size: 10,
            font: timesRomanFont,
            color: fontColor,
        })
        page1.drawText(`${quotationCompany?.attentionName}`, {
            x: 145,
            y: 100,
            size: 10,
            font: timesRomanFont,
            color: fontColor,
        })
        page1.drawText(`Company Name:`, {
            x: 65,
            y: 80,
            size: 10,
            font: timesRomanFont,
            color: fontColor,
        })
        page1.drawText(`${quotationCompany?.companyName}`, {
            x: 145,
            y: 80,
            size: 10,
            font: timesRomanFont,
            color: fontColor,
        })
        page1.drawLine({
            start: { x: 400, y: 90 },
            end: { x: 550, y: 90 },
            thickness: 1,
            color: rgb(0, 0, 0),
        })

        page1.drawText(`Signature`, {
            x: 455,
            y: 80,
            size: 10,
            font: timesRomanBoldFont,
            color: fontColor,
        })
        page1.drawLine({
            start: { x: 400, y: 40 },
            end: { x: 550, y: 40 },
            thickness: 1,
            color: rgb(0, 0, 0),
        })
        page1.drawText(`Date`, {
            x: 470,
            y: 30,
            size: 10,
            font: timesRomanBoldFont,
            color: fontColor,
        })
        page1.drawLine({
            start: { x: 25, y: 15 },
            end: { x: 600, y: 15 },
            thickness: 1,
            color: rgb(0, 0, 0),
        })
        page1.drawText(
            `Registered in England 06509841 | ACCA Registra on number 2819986`,
            {
                x: 200,
                y: 7,
                size: 8,
                font: timesRomanFont,
                color: fontColor,
            }
        )
        const pdfBytes = await pdfDoc.save()
        const blob = new Blob([pdfBytes], { type: 'application/pdf' })
        const link = document.createElement('a')
        link.href = URL.createObjectURL(blob)
        link.download = `Document_${quotationHeader?.referenceId}-${quotationHeader?.companyType}_AML.pdf`
        link.click()
    }
    const handleDownloadPDF = (): void => {
        void handleDownloadPDFDoc()
    }

    return (
        <>
            <Card>
                <div
                    className={`${antimoney.page} ${antimoney.body} avoid-page-break`}
                    id="pdf-content"
                >
                    <div className={antimoney.container}>
                        <div
                            className={antimoney.div}
                            data-size="A4"
                            id="pdf-content"
                        >
                            <div className={antimoney.col_sm_12}>
                                <img
                                    src={hederimage}
                                    className={antimoney.col_sm}
                                />
                            </div>

                            <div className={antimoney.page}>
                                <div>
                                    <div className={antimoney.company_info}>
                                        <div
                                            className={
                                                antimoney.keep_print_font
                                            }
                                        >
                                            <h4 className={antimoney.h4}>
                                                {
                                                    businessDocumentTemplate?.companyName
                                                }
                                                <small
                                                    className={antimoney.small}
                                                >
                                                    <br />
                                                    {
                                                        businessDocumentTemplate?.addressOne
                                                    }
                                                    ,
                                                    {
                                                        businessDocumentTemplate?.addressTwo
                                                    }
                                                    ,
                                                    {
                                                        businessDocumentTemplate?.town
                                                    }
                                                    ,
                                                    {
                                                        businessDocumentTemplate?.postCode
                                                    }
                                                    ,
                                                    {
                                                        businessDocumentTemplate?.country
                                                    }
                                                    ,
                                                    <br />
                                                    {
                                                        businessDocumentTemplate?.website
                                                    }
                                                </small>
                                            </h4>
                                        </div>
                                        <img
                                            id="barcode"
                                            alt="companyLogo"
                                            src={
                                                businessDocumentTemplate?.companyLogo
                                            }
                                            className={antimoney.barcode}
                                        />
                                    </div>
                                    <h3 className={antimoney.h3_span}>
                                        <span>ANTI-MONEY LAUNDERING</span>
                                    </h3>
                                </div>

                                <div className={antimoney.table}>
                                    <Row>
                                        <Col>
                                            <Row>
                                                {quotationCompany?.companyName}
                                            </Row>
                                            <Row>
                                                {quotationCompany?.addressLine1}
                                                ,
                                            </Row>
                                            <Row>
                                                {quotationCompany?.addressLine2}
                                                ,
                                            </Row>
                                            <Row>{quotationCompany?.town},</Row>
                                            <Row>
                                                {quotationCompany?.postCode},
                                            </Row>
                                            <Row>
                                                {quotationCompany?.country}
                                            </Row>
                                        </Col>
                                    </Row>
                                </div>

                                <br />
                                <div>
                                    <div>
                                        Dear {quotationCompany?.attentionName}
                                    </div>
                                </div>
                                <br />
                                <div>
                                    <p className={antimoney.p}>
                                        In accordance with the recommendations
                                        of our professional supervisory body,
                                        the ACCA, we must comply with onerous
                                        duties imposed by
                                    </p>
                                    <ul className={antimoney.ul}>
                                        <li>
                                            The Proceeds of Crime Act 2002
                                            (POCA) as amended. Particular
                                            attention is drawn to the Serious
                                            Organised Crime and Police Act 2005
                                            (SOCPA);
                                        </li>
                                        <li>
                                            The Terrorism Act 2000 (TA 2000) as
                                            amended. Particular attention is
                                            drawn to the Anti- Terrorism Crime
                                            and Security Act 2001 (ATCSA) and
                                            the Terrorism Act 2006 (TA 2006)
                                        </li>
                                        <li>
                                            The Money Laundering, Terrorist
                                            Financing and Transfer of Funds
                                            (Information on the Payer)
                                            Regulations 2017 (the 2017
                                            Regulations) as amended. Particular
                                            attention is drawn to The Money
                                            Laundering and Terrorist Financing
                                            (Amendment) Regulations 2019
                                            Terrorist Asset-Freezing Act 2010;
                                        </li>
                                        <li>
                                            Anti-terrorism, Crime and Security
                                            Act 2001;
                                        </li>
                                        <li>
                                            Counter-terrorism Act 2008, Schedule
                                            7;
                                        </li>
                                        <li>The Criminal Finances Act 2017.</li>
                                        <li>Bribery Act 2010</li>
                                        <li>Fraud Act 2006,</li>
                                    </ul>
                                    <p className={antimoney.p}>
                                        Therefore, it is our obligation to
                                        conduct client due diligence in
                                        accordance with the above regulations
                                        for all regulated activities. Failing to
                                        comply with regulations could lead to a
                                        prosecution resulting in unlimited fines
                                        and /or prison terms of many years.
                                    </p>
                                    <p className={antimoney.p}>
                                        Therefore, as part of client due
                                        diligence, we require two forms of
                                        identification from you. (NB: In
                                        partnership, we require two forms of
                                        identification for each partner). Please
                                        provide a copy of the documentation
                                        (Please refer to the attached leaflet
                                        about relevant documentation needed from
                                        you) to prove your identity. If you are
                                        unable to provide either of these, then
                                        please contact us for further advice.
                                    </p>
                                    <p className={antimoney.p}>
                                        We may need to obtain ‘satisfactory
                                        evidence’ to confirm your identity for
                                        ongoing monitoring and enhanced Due
                                        diligence before we accept your
                                        instructions. In certain circumstances,
                                        we may need to obtain evidence
                                        confirming third parties’ identities,
                                        the source of any funds or other
                                        property, the purpose of any
                                        instructions, or any other matter. We
                                        may also need to obtain such evidence
                                        after we have begun to act on your
                                        instructions.
                                    </p>
                                    <p
                                        className={`${antimoney.breakhere} ${antimoney.p}`}
                                    >
                                        In accordance abovementioned laws and
                                        regulations, you agree to waive your
                                        right to confidentiality to the extent
                                        of any report made, documents provided,
                                        or information disclosed to National
                                        Crime Agency (NCA) and other government
                                        organisations. We are required to report
                                        directly to NCA without prior reference
                                        to you or your representatives if our
                                        Money Laundering Reporting Officer(MLRO)
                                        becomes suspicious of you involved in
                                        money laundering.
                                    </p>

                                    <p
                                        className={`${antimoney.p} page-break-before}`}
                                    >
                                        We assume that our clients are honest
                                        and law-abiding. However, if at any
                                        time, there appear to be grounds to
                                        suspect (even if we do not actually
                                        suspect) that your instructions relate
                                        to ‘criminal property, for example, If
                                        there are grounds to suspect (even if we
                                        do not actually suspect) that any monies
                                        held in a client account are derived
                                        directly or indirectly from any criminal
                                        activity we are obliged to make a report
                                        to the National Crime Agency (NCA).
                                        Still, we are prohibited from telling
                                        you that we have done so.
                                    </p>
                                    <p className={antimoney.p}>
                                        In such circumstances, we must not act
                                        on your instructions without consent
                                        from NCA. (known as Defence Against
                                        Money Laundering DAML) If NCA does not
                                        refuse consent within seven working
                                        days, we may continue to act. If NCA
                                        issues a refusal within that time, we
                                        must not act for an additional 31 days
                                        from the refusal date.
                                    </p>
                                    <p className={antimoney.p}>
                                        Moreover, it is your responsibility to
                                        keep us informed of the changes in your
                                        personal circumstances. By signing the
                                        letter, you confirm that you have
                                        thoroughly read and understood the
                                        contents of this letter.
                                    </p>
                                </div>

                                <div className={antimoney.company_info}>
                                    <div>
                                        <Row>
                                            <Col>
                                                <Row>Client Name:</Row>
                                                <Row>Company Name:</Row>
                                            </Col>
                                            <Col>
                                                <Row>
                                                    {
                                                        quotationCompany.attentionName
                                                    }
                                                </Row>
                                                <Row>
                                                    {
                                                        quotationCompany.companyName
                                                    }
                                                </Row>
                                            </Col>
                                        </Row>
                                    </div>
                                    <Row className={antimoney.signature}>
                                        <Col>
                                            <Row>
                                                ................................................
                                            </Row>
                                            <Row
                                                className={antimoney.custom_row}
                                            >
                                                Signature
                                            </Row>

                                            <Row>
                                                ...............................................
                                            </Row>
                                            <Row>Date</Row>
                                        </Col>
                                    </Row>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <br />
                <Row gutter={16}>
                    <Col offset={18} span={2}>
                        <Button type="primary" onClick={handleDownloadPDF}>
                            Download
                        </Button>
                    </Col>
                </Row>
            </Card>
        </>
    )
}

export default AntiMoneyLaundering
