import { notification } from 'antd'

export const SuccessMessage = (message: string, title: string = ''): void => {
    notification.success({
        message: title,
        description: message,
    })
}

export const WarningMessage = (message: string, title: string = ''): void => {
    notification.warning({
        message: title,
        description: message,
    })
}

export const ErrorMessage = (message: string, title: string = ''): void => {
    notification.error({
        message: title,
        description: message,
    })
}
