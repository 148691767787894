import { type PayloadAction, createSlice } from '@reduxjs/toolkit'
import {
    type ClientDashboardPayloadIntrface,
    type CleitDashboardServiceProcess,
    type ClientDashBordData,
    type Invoice,
    type ClientDashboardInvoicePayloadIntrface,
    type ClientDashboardTaskPayloadIntrface,
    type TaskData,
    type ClientCommunicationData,
    type ClientDashboardClientComLogPayloadIntrface,
    type ChatMessage,
    type ClientDashboardStaffComLogPayloadIntrface,
} from '../Types/GlobelSearchClient/GlobelSearchClient'

interface InitailStateInterface {
    clientDashbordList: ClientDashBordData | null
    CDashboradServiceProcessList: CleitDashboardServiceProcess[]
    CDashboradServiceProcessListCount: number
    CDInvoiceList: Invoice[]
    CDInvoiceListCount: number
    CDTaskList: TaskData[]
    CDTaskListCount: number
    CDClientCommLogList: ClientCommunicationData[]
    CDClientCommLogListCount: number
    CDStaffCommLogList: ChatMessage[]
}
const GlobelReducer = createSlice({
    name: 'golobelSearch',
    initialState: {
        clientDashbordList: null,
        CDashboradServiceProcessList: [],
        CDashboradServiceProcessListCount: 0,
        CDInvoiceList: [],
        CDInvoiceListCount: 0,
        CDTaskList: [],
        CDTaskListCount: 0,
        CDClientCommLogList: [],
        CDClientCommLogListCount: 0,
        CDStaffCommLogList: [],
    },
    reducers: {
        clientDashbordList(
            state: InitailStateInterface,
            action: PayloadAction<ClientDashBordData>
        ) {
            state.clientDashbordList = action?.payload
        },
        CDashboradServiceProcessList(
            state: InitailStateInterface,
            action: PayloadAction<ClientDashboardPayloadIntrface>
        ) {
            state.CDashboradServiceProcessList = action?.payload?.data
            state.CDashboradServiceProcessListCount =
                action?.payload?.noOfRecords
        },
        CDInvoiceList(
            state: InitailStateInterface,
            action: PayloadAction<ClientDashboardInvoicePayloadIntrface>
        ) {
            state.CDInvoiceList = action?.payload?.data
            state.CDInvoiceListCount = action?.payload?.noOfRecords
        },
        CDTaskList(
            state: InitailStateInterface,
            action: PayloadAction<ClientDashboardTaskPayloadIntrface>
        ) {
            state.CDTaskList = action?.payload?.data
            state.CDTaskListCount = action?.payload?.noOfRecords
        },
        CDClientCommLogList(
            state: InitailStateInterface,
            action: PayloadAction<ClientDashboardClientComLogPayloadIntrface>
        ) {
            state.CDClientCommLogList = action?.payload?.data
            state.CDClientCommLogListCount = action?.payload?.noOfRecords
        },
        CDStaffCommLogList(
            state: InitailStateInterface,
            action: PayloadAction<ClientDashboardStaffComLogPayloadIntrface>
        ) {
            state.CDStaffCommLogList = action?.payload?.data
        },
    },
})

export const {
    clientDashbordList,
    CDashboradServiceProcessList,
    CDInvoiceList,
    CDTaskList,
    CDClientCommLogList,
    CDStaffCommLogList,
} = GlobelReducer.actions
export default GlobelReducer.reducer
