import { type Dispatch } from '@reduxjs/toolkit'
import axios from '../Config/AxiosConfig'
import { ErrorMessage, SuccessMessage } from '../Utils/Notification'
import { setLoadingStatus } from '../Reducer/Common'
import {
    type TaskInterface,
    type ClientRequestApproval,
    type TaskAttachment,
    type ClientRequestDetail,
} from '../Types/MobileRequest'
import { mobileReqestList } from '../Reducer/MoblieRequest'

export const GetMobileRequest = (
    dispatch: Dispatch,
    PageNo: number,
    PageSize: number,
    SearchString: string
): void => {
    dispatch(setLoadingStatus({ status: true }))
    axios
        .get(
            `/api/ClientPortal/GetClientPortalRequestInfoForApproval/${PageNo}/${PageSize}/${SearchString}`
        )
        .then((response) => {
            if (response.status === 200) {
                const newData = response?.data?.data?.map(
                    (value: ClientRequestApproval) => {
                        return {
                            ...value,
                            key: value.clientRequestId,
                        }
                    }
                )
                response.data.data = newData
                dispatch(mobileReqestList({ ...response.data }))
            } else {
                // ErrorMessage(response.data.operationMsg)
            }
            dispatch(setLoadingStatus({ status: false }))
        })
        .catch((error) => {
            // ErrorMessage(error.response.data.operationMsg)
            dispatch(
                mobileReqestList({
                    data: [],
                    noOfRecords: 0,
                })
            )
            console.error('Error fetching posts:', error)
            dispatch(setLoadingStatus({ status: false }))
        })
}

export const GetInduvidualMobileRequest = async (
    dispatch: Dispatch,
    requestId: number
): Promise<any> => {
    dispatch(setLoadingStatus({ status: true }))

    try {
        const response = await axios.get(
            `/api/ClientPortal/GetClientRequestDetail/${requestId}`
        )

        if (response.status === 200) {
            return response?.data?.data
        } else {
            ErrorMessage('Failed to fetch data')
        }
    } catch (error) {
        console.error('Error fetching client company details:', error)
        ErrorMessage('An error occurred while fetching data')
    } finally {
        dispatch(setLoadingStatus({ status: false }))
    }
}

export const ConvertTask = async (
    dispatch: Dispatch,
    data: TaskInterface,
    induvidualMobileData: ClientRequestDetail
): Promise<any> => {
    dispatch(setLoadingStatus({ status: true }))
    return await axios
        .post(`/api/Task/SetTask`, data)
        .then((response) => {
            dispatch(setLoadingStatus({ status: false }))
            if (response.status === 200) {
                SuccessMessage(response.data.operationMsg)
                return response.data.data
            } else {
                ErrorMessage(response.data.operationMsg)
                return false
            }
        })
        .catch((error) => {
            ErrorMessage(error.response.data.operationMsg)
            console.error('Error fetching posts:', error)
            dispatch(setLoadingStatus({ status: false }))
            return false
        })
}

export const setAttachment = async (
    dispatch: Dispatch,
    data: TaskAttachment
): Promise<any> => {
    dispatch(setLoadingStatus({ status: true }))
    return await axios
        .post(`/api/Task/SetTaskAttachment`, data)
        .then((response) => {
            dispatch(setLoadingStatus({ status: false }))
            if (response.status === 200) {
                SuccessMessage(response.data.operationMsg)
                return true
            } else {
                ErrorMessage(response.data.operationMsg)
                return false
            }
        })
        .catch((error) => {
            ErrorMessage(error.response.data.operationMsg)
            console.error('Error fetching posts:', error)
            dispatch(setLoadingStatus({ status: false }))
            return false
        })
}
