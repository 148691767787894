import React, { useEffect, useState, useCallback } from 'react'
import { connect, useDispatch } from 'react-redux'
import { Table } from 'antd'
import commonStyles from '../../Utils/Common.less'
import SearchInput from '../../Components/SearchInput'
import type { ColumnsType, TableProps } from 'antd/es/table'
import type { TableParamsInterface } from '../../Types/CommonType'
import {
    commonTableParams,
    setCommonTableParamsData,
} from '../../Utils/CommontTable'
import calculatePagination from '../../Utils/Pagination'
import {
    type overviewClient,
    type overviemodelprops,
    type OverviewTabeleData,
} from '../../Types/OverViewClient'
import { GetClientOverview } from '../../Services/OverviewClients'

const OverViewClientList = (props: overviemodelprops): React.ReactElement => {
    const dispatch = useDispatch()
    const {
        selectedSequenceNo,
        overviewClientList,
        overviewClientListCount,
        searchtextss,
    } = props
    const [tableParams, setTableParams] =
        useState<TableParamsInterface>(commonTableParams)
    const [currentPage, setCurrentPage] = useState(1)
    const [itemsPerPage, setItemsPerPage] = useState(10)
    const [searchtext, setSerachtext] = useState(searchtextss)
    const user = localStorage.getItem('user')
    const clientCategoryId =
        user !== null ? JSON.parse(user)?.clientCategoryId : ''
    const getTableData = useCallback(() => {
        const { pageSize, pageNo } = setCommonTableParamsData(tableParams)
        GetClientOverview(
            dispatch,
            selectedSequenceNo,
            clientCategoryId,
            pageNo,
            pageSize,
            searchtext
        )
    }, [
        selectedSequenceNo,
        searchtext,
        tableParams?.sorter,
        tableParams.pagination?.current,
        tableParams?.filter,
    ])

    useEffect(() => {
        getTableData()
    }, [getTableData])

    const handleDataCountChange = useCallback(() => {
        setTableParams({
            ...tableParams,
            pagination: {
                ...tableParams.pagination,
                total: overviewClientListCount,
            },
        })
    }, [overviewClientListCount])

    useEffect(() => {
        handleDataCountChange()
    }, [handleDataCountChange])

    const handlePageChange = (pageNo: number, pageSize: number): void => {
        setCurrentPage(pageNo)
        setItemsPerPage(pageSize)
    }

    const pagination = calculatePagination(
        currentPage,
        overviewClientListCount,
        itemsPerPage
    )

    const columns: ColumnsType<OverviewTabeleData> = [
        {
            title: 'Client No',
            dataIndex: 'clientNumber',
        },
        {
            title: 'Client',
            dataIndex: 'clientName',
        },
        {
            title: 'Services',
            dataIndex: 'serviceList',
        },
    ]

    const onChangeTableParams: TableProps<OverviewTabeleData>['onChange'] = (
        pagination,
        filters,
        sorter: any,
        extra
    ) => {
        handlePageChange(pagination.current ?? 1, pagination.pageSize ?? 10)
        setTableParams({
            ...tableParams,
            pagination,
            sorter: {
                field: sorter?.field ?? '',
                order: sorter?.order ?? '',
            },
        })
    }

    const onChangeText = (data: React.ChangeEvent<HTMLInputElement>): void => {
        onChangeFilter(data.target.value)
    }

    const onChangeFilter = (data: string): void => {
        if (data.length !== 0) {
            setSerachtext(data)
        } else {
            setSerachtext('=NA=')
        }
        setTableParams({
            ...tableParams,
            filter: {
                fieldName: 'description',
                searchOperator: 10,
                fieldValue1: data,
                fieldValue2: '',
            },
        })
    }

    const overviewClientListWithKey = overviewClientList?.map(
        (item, index) => ({
            ...item,
            key: index.toString(), // You can use any unique identifier here
        })
    )
    return (
        <>
            <SearchInput
                placeHolder={'Search News Details by keywords'}
                onSearch={onChangeFilter}
                onChange={onChangeText}
                width="478"
            />
            <div className={commonStyles.table}>
                {overviewClientList.length > 0 ? (
                    <Table
                        columns={columns}
                        dataSource={overviewClientListWithKey}
                        onChange={onChangeTableParams}
                        pagination={pagination}
                    />
                ) : (
                    <p>No data available</p>
                )}
            </div>
        </>
    )
}

const mapStateToProps = (state: any): overviewClient => {
    return {
        overviewClientList: state.clientOverviewInfo.overviewClientList,
        overviewClientListCount:
            state.clientOverviewInfo.overviewClientListCount,
    }
}

export default connect(mapStateToProps)(OverViewClientList)
