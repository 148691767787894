import { type PayloadAction, createSlice } from '@reduxjs/toolkit'
import {
    type ProfileHolidayData,
    type ProfileHolidayPayloadInteface,
} from '../Types/Profile'

interface ProfileStateInterface {
    userHolidayList: ProfileHolidayData[]
    userHolidayListCount: number
}

const profileUser = createSlice({
    name: 'profile',
    initialState: {
        userHolidayList: [],
        userHolidayListCount: 0,
    },
    reducers: {
        userHolidayList(
            state: ProfileStateInterface,
            action: PayloadAction<ProfileHolidayPayloadInteface>
        ) {
            state.userHolidayList = action?.payload?.data
            state.userHolidayListCount = action?.payload?.noOfRecords
        },
    },
})

export const { userHolidayList } = profileUser.actions

export default profileUser.reducer
