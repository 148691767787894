import React from 'react'
import { connect, useDispatch } from 'react-redux'
import { Col, Row, Form, Button } from 'antd'
import { PinApi } from '../../Services/Auth'
import styles from './login.less'
import InputField from '../../Components/InputField'
import { setLoginState } from '../../Reducer/AuthReducer'
import type { pinDataInterface, LoginPinInterface } from '../../Types/Auth'
import commonStyles from '../../Utils/Common.less'

const LoginPin = (result: LoginPinInterface): React.ReactElement => {
    const dispatch = useDispatch()

    const onSubmit = (data: pinDataInterface): void => {
        const loginSessionCode = localStorage.getItem('loginSessionCode')
        const newData = {
            pin: data.pin,
            username: result.username,
            ipAddress: '',
            loginSessionCode: loginSessionCode as string,
        }
        PinApi(dispatch, newData)
    }

    const backButtonClick = (): void => {
        dispatch(setLoginState({ status: false }))
    }

    const customizeRequiredMark = (
        label: React.ReactNode,
        { required }: { required: boolean }
    ): React.ReactElement => (
        <>
            {label}
            &nbsp;
            {required && <span className={commonStyles.requireIcon}>*</span>}
        </>
    )
    return (
        <>
            <Row className={styles.main}>
                <Col className={styles.loginPage} span={10}>
                    <div className={styles.backgroundImage} />
                </Col>
                <Col span={14}>
                    <div className={styles.loginContent}>
                        <div className={styles.loginTittleContainter}>
                            <div className={styles.loginTittle}>
                                2FA Secure Sign in
                            </div>
                            <div className={styles.loginTittleDesc}>
                                Enter PIN number to continue to IYKONECT
                                dashboard
                            </div>
                        </div>
                        <Form
                            initialValues={{ remember: true }}
                            onFinish={onSubmit}
                            onFinishFailed={() => {}}
                            autoComplete="off"
                            layout="vertical"
                            requiredMark={customizeRequiredMark}
                        >
                            <InputField
                                name="pin"
                                label="PIN"
                                placeHolder="Enter your four digit PIN"
                                reuireMessage="Please your four digit pin!"
                            />
                            <Form.Item>
                                <Button
                                    type="primary"
                                    htmlType="submit"
                                    className={styles.loginFormButton}
                                >
                                    Verify
                                </Button>
                            </Form.Item>
                            <div>
                                <a className={styles.loginFormForgot} href="">
                                    Forgot PIN?
                                </a>
                            </div>
                        </Form>
                        <Button
                            className={styles.backButton}
                            onClick={backButtonClick}
                        >
                            Back to SignIn
                        </Button>
                    </div>

                    <div className={styles.powerdByText}>
                        © 2023 All Rights Reserved. IYCONNECT®️ is a registered
                        trademark of
                        <a
                            href="https://www.iykons.com/"
                            target="_blank"
                            rel="noreferrer"
                        >
                            IYKONS LIMITED.
                        </a>
                    </div>
                </Col>
            </Row>
        </>
    )
}

const mapStateToProps = (state: any): LoginPinInterface => {
    return {
        username: state.auth.username,
    }
}

export default connect(mapStateToProps)(LoginPin)
