import React, { useEffect, type ReactElement, useState } from 'react'
import { DatePicker, type DatePickerProps, Form, Input, Space } from 'antd'
import { connect } from 'react-redux'
import {
    type RegisterInterface,
    type PersonalDetails,
} from '../../../../../../Types/Client/RegisteredClients/Individual'
import dayjs from 'dayjs'
import moment from 'moment'
import commonStyles from '../../../../../../Utils/Common.less'

const PersonalDetailsForm = (props: PersonalDetails): ReactElement => {
    const { individualTaps } = props
    const [selectedDate, setSelectedDate] = useState<string>('')
    const [selecteddueDate, setSelecteddueDate] = useState<string>('')

    useEffect(() => {
        const initialDate = moment(
            individualTaps?.individualPersonalDetails?.dateOfBirth,
            'M/D/YYYY HH:mm:ss A'
        )
        const formattedDate = initialDate.format('M/D/YYYY h:mm:ss A')
        setSelectedDate(formattedDate)
    }, [individualTaps?.individualPersonalDetails?.dateOfBirth])

    useEffect(() => {
        const initialDate = moment(
            individualTaps?.individualPersonalDetails?.dueDate,
            'M/D/YYYY HH:mm:ss A'
        )
        const formattedDate = initialDate.format('M/D/YYYY h:mm:ss A')
        setSelecteddueDate(formattedDate)
    }, [individualTaps?.individualPersonalDetails?.dueDate])

    const handleDateOfBirthChange = (
        date: DatePickerProps['value'],
        dateString: string | string[]
    ): void => {
        if (date !== null && date !== undefined) {
            const dateto = date.toISOString()
            setSelectedDate(dateto)

            if (props.onDateChange !== undefined) {
                props.onDateChange(dateto)
            }
        }
    }

    const handleDueDateChange = (
        date: DatePickerProps['value'],
        dateString: string | string[]
    ): void => {
        if (date !== null && date !== undefined) {
            const dateto = date.toISOString()
            setSelecteddueDate(dateto)

            if (props.onDueDateChange !== undefined) {
                props.onDueDateChange(dateto)
            }
        }
    }

    return (
        <>
            <Form.Item label="Date Of Birth" name="dateOfBirth">
                <Space direction="vertical" size={12}>
                    <DatePicker
                        value={
                            selectedDate?.length > 0
                                ? dayjs(selectedDate)
                                : null
                        }
                        format="M/D/YYYY"
                        className={commonStyles.dateWidth}
                        onChange={(date, dateString) => {
                            handleDateOfBirthChange(date, dateString)
                        }}
                    />
                </Space>
            </Form.Item>
            <Form.Item label="NI Number" name="niNumber">
                <Input />
            </Form.Item>
            <Form.Item label="Duedate" name="dueDate">
                <Space direction="vertical" size={12}>
                    <DatePicker
                        value={
                            selecteddueDate?.length > 0
                                ? dayjs(selecteddueDate)
                                : null
                        }
                        format="D/M/YYYY"
                        className={commonStyles.dateWidth}
                        onChange={(date, dateString) => {
                            handleDueDateChange(date, dateString)
                        }}
                    />
                </Space>
            </Form.Item>

            <Form.Item
                label="Mobile Number"
                name="mobileNumber"
                rules={[
                    {
                        pattern: /^\(?(\d{2})\)?[- ]?(\d{4})[- ]?(\d{6})$/,
                        message: 'please enter valid mobile number',
                    },
                    {
                        required: true,
                        message: 'Please input your mobile number!',
                    },
                ]}
            >
                <Input />
            </Form.Item>
            <Form.Item
                label="Home Number"
                name="homeNumber"
                rules={[
                    {
                        pattern: /^\(?(\d{2})\)?[- ]?(\d{4})[- ]?(\d{6})$/,
                        message: 'please enter valid mobile number',
                    },
                ]}
            >
                <Input />
            </Form.Item>
            <Form.Item
                label="Work Number"
                name="workNumber"
                rules={[
                    {
                        pattern: /^\(?(\d{2})\)?[- ]?(\d{4})[- ]?(\d{6})$/,
                        message: 'please enter valid mobile number',
                    },
                ]}
            >
                <Input />
            </Form.Item>
            <Form.Item
                label="Email Address"
                name="emailAddress"
                rules={[
                    {
                        type: 'email',
                        required: true,
                        message: 'Please input your email!',
                    },
                ]}
            >
                <Input />
            </Form.Item>
            <Form.Item
                label="Alternative Email Address"
                name="alternativeEmailAddress"
                rules={[
                    {
                        type: 'email',
                        message: 'Please Input Alternative Email!',
                    },
                ]}
            >
                <Input />
            </Form.Item>
        </>
    )
}

const mapStateToProps = (state: any): RegisterInterface => {
    return {
        categoryDropDownList: state.initial.categoryDropDownList,
        countryList: state.common.countryList,
        individualTaps: state.client.individualTaps,
    }
}

export default connect(mapStateToProps)(PersonalDetailsForm)
