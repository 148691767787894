import React from 'react'
import { Table } from 'antd'
import type { TimeSheetExpandViewInterface } from '../../../Types/Timesheet'
import commontStyles from '../../../Utils/Common.less'

const TimesheetExpandView = (
    props: TimeSheetExpandViewInterface
): React.ReactElement => {
    const { TimesheetData } = props

    const columns = [
        {
            title: ' level',
            dataIndex: 'levelName',
        },
        {
            title: 'Approver',
            dataIndex: 'approverName',
        },
        {
            title: ' Approval Date',
            dataIndex: 'approvedDate',
        },
        {
            title: 'Status',
            dataIndex: 'approvalStatus',
        },
        {
            title: 'Remarks',
            dataIndex: 'remarks',
        },
    ]

    const dataSource = TimesheetData?.approvalHistoryList?.map(
        (approvalHistory, index) => ({
            key: index.toString(),
            levelName: approvalHistory.levelName,
            approverName: approvalHistory.approverName,
            approvedDate: approvalHistory.approvedDate,
            approvalStatus: approvalHistory.approvalStatus,
            remarks: approvalHistory.remarks,
        })
    )

    return (
        <div className={commontStyles.expandCard}>
            <Table
                columns={columns}
                dataSource={dataSource}
                pagination={false}
            />
        </div>
    )
}

export default TimesheetExpandView
