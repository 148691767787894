import { Button, Card, Col, Form, Radio, Row, Select } from 'antd'
import React, { useState } from 'react'
import SearchInputText from '../../../Components/SearchInputText'
import companyEligiblity from './companyEligiblity.less'
import { SaveOutlined } from '@ant-design/icons'
import commonStyles from '../../../Utils/Common.less'

const CompanyEligiblity = (): React.ReactElement => {
    const [form] = Form.useForm()
    const [showsuccessCard, setShowsucessCrad] = useState(false)
    const onChangeText = (data: React.ChangeEvent<HTMLInputElement>): void => {
        if (data.target.value.length !== 0) {
            onChangeFilter(data.target.value)
        }
    }

    const onChangeFilter = (data: string): void => {}
    const onclick = (): void => {
        setShowsucessCrad(!showsuccessCard)
    }
    return (
        <>
            <Card>
                <h1 className={companyEligiblity.titleText}>
                    Company name availability checker
                </h1>
                <span className={companyEligiblity.subText}>
                    Enter proposed company name
                </span>
                <Row>
                    <Col span={22}>
                        <SearchInputText
                            placeHolder={'Search'}
                            onSearch={onChangeFilter}
                            onChange={onChangeText}
                            width="478"
                            height="50"
                            onclick={onclick}
                        />
                    </Col>
                </Row>
                {showsuccessCard && (
                    <Card style={{ marginTop: 20 }}>
                        <h4>
                            Congratulations, the name above is available to
                            register!
                        </h4>
                        <div className={commonStyles.formWrapper}>
                            <Form
                                name="complex-form"
                                labelCol={{ span: 24 }}
                                wrapperCol={{ span: 24 }}
                                initialValues={{ remember: true }}
                                layout={'vertical'}
                                autoComplete="off"
                                form={form}
                            >
                                <Row gutter={16}>
                                    <Col span={12}>
                                        <Form.Item
                                            label="Company Type"
                                            name="companyType"
                                        >
                                            <Select
                                                allowClear
                                                placeholder="Please select"
                                                onChange={() => {}}
                                                showSearch
                                                // filterOption={(input, option) =>
                                                //     option?.label
                                                //         ?.toLowerCase()
                                                //         .includes(input.toLowerCase()) ?? false
                                                // }
                                                // options={[
                                                //     { label: 'Please select', value: null },
                                                //     ...priorityDropDownList?.map((service) => ({
                                                //         value: service.value,
                                                //         label: service.label,
                                                //     })),
                                                // ]}
                                            />
                                        </Form.Item>
                                    </Col>
                                    <Col span={12}>
                                        <div>
                                            <span>
                                                Will the company be a &quot;
                                                Community Interest
                                                Company&quot;?
                                            </span>
                                            <div style={{ marginTop: 10 }}>
                                                <Radio value={1}>YES</Radio>
                                                <Radio value={2}>No</Radio>
                                            </div>
                                        </div>
                                    </Col>
                                </Row>
                                <Row gutter={16}>
                                    <Col span={12}>
                                        <Form.Item
                                            label="Ending Name"
                                            name="endingName"
                                        >
                                            <Select
                                                allowClear
                                                placeholder="Please select"
                                                onChange={() => {}}
                                                showSearch
                                                // filterOption={(input, option) =>
                                                //     option?.label
                                                //         ?.toLowerCase()
                                                //         .includes(input.toLowerCase()) ?? false
                                                // }
                                                // options={[
                                                //     { label: 'Please select', value: null },
                                                //     ...priorityDropDownList?.map((service) => ({
                                                //         value: service.value,
                                                //         label: service.label,
                                                //     })),
                                                // ]}
                                            />
                                        </Form.Item>
                                    </Col>
                                </Row>
                                <Row gutter={16}>
                                    <Col span={2}>
                                        <Button
                                            type="primary"
                                            htmlType="submit"
                                        >
                                            <SaveOutlined />
                                        </Button>
                                    </Col>
                                </Row>
                            </Form>
                        </div>
                    </Card>
                )}
            </Card>
        </>
    )
}

export default CompanyEligiblity
