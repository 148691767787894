import { Card, Flex, Modal } from 'antd'
import React, { useState } from 'react'
import style from '../../ThingToDoViewAll/tingtodo.less'
import CusttomBackgroundButton from '../../../Components/CustomBagraoundButton'
import { PlusOutlined } from '@ant-design/icons'
import AddSAILAddress from '../CompanyHouseRelatedThings/AddNewSAILAddress'
interface CompanyDetailsprops {
    companyHousId?: number
}
const SAILAddersss = (props: CompanyDetailsprops): React.ReactElement => {
    const { companyHousId } = props
    const [createEditModalStatus, setCreateEditModalStatus] = useState(false)

    const openEditModal = (): void => {
        setCreateEditModalStatus(!createEditModalStatus)
    }
    const changeCreateEditModalStatus = (): void => {
        setCreateEditModalStatus(!createEditModalStatus)
    }
    return (
        <>
            <div>
                <h3>SAIL Address</h3>
            </div>
            {companyHousId !== 1 && (
                <Card title="Single Alternative Inspection Location (SAIL)">
                    <div>
                        Currently there is no SAIL address registered for this
                        company.
                    </div>
                </Card>
            )}

            {companyHousId === 1 && (
                <Card>
                    <div className={style.misstingInfo}>
                        If you want to do changes required in SAIL address
                        should be filed using the{' '}
                        <b>&quot;Create New Address&quot;</b> page before you
                        file the confirmation statement.
                    </div>
                    <h2>Single Alternative Inspection Location (SAIL)</h2>
                    <Flex justify="flex-start" gap={30}>
                        <div>
                            Currently there is no SAIL address registered for
                            this company.
                        </div>
                        <CusttomBackgroundButton
                            color={'#175ca9'}
                            name={'Create New SAIL Address'}
                            icon={<PlusOutlined />}
                            clickAction={() => {
                                openEditModal()
                            }}
                        />
                    </Flex>
                </Card>
            )}
            <Modal
                footer={null}
                onCancel={changeCreateEditModalStatus}
                width={'50%'}
                title={'Create New SAIL Address'}
                open={createEditModalStatus}
            >
                <AddSAILAddress />
            </Modal>
        </>
    )
}

export default SAILAddersss
