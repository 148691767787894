import { Button, Card, Col, Collapse, Flex, Modal, Row } from 'antd'
import React, { useState } from 'react'
import roleStyle from '../../Clients/RegisteredClients/Corporate/Buttons/OfficerButton/ofiicer.less'
import { DeleteOutlined, PlusOutlined, EditOutlined } from '@ant-design/icons'
import CusttomBackgroundButton from '../../../Components/CustomBagraoundButton'
import style from '../../ThingToDoViewAll/tingtodo.less'
import AddNewOfficerForm from '../CompanyHouseRelatedThings/OfficerAddNewForm'
interface CompanyDetailsprops {
    companyHousId?: number
}
const Officers = (props: CompanyDetailsprops): React.ReactElement => {
    const { companyHousId } = props
    const [createEditModalStatus, setCreateEditModalStatus] = useState(false)
    const [editModalTittle, setEditModalTittle] = useState(
        'Create Officer Details'
    )
    const getRoleBackgroundColor = (role: string): React.ReactNode => {
        if (role !== '' && typeof role === 'string') {
            const roles = role.split(',')?.map((r) => r.trim())

            return (
                <div className={roleStyle.roleBadge}>
                    {roles?.map((r, index) => (
                        <span
                            key={index}
                            className={`${roleStyle.roleSpan} ${
                                roleStyle[r.toLowerCase()] ??
                                roleStyle.defaultColor
                            }`}
                        >
                            {r.substring(0, 3)}
                        </span>
                    ))}
                </div>
            )
        }
    }

    const openEditModal = (id: string): void => {
        // const data = bankingDetailsList.find(
        //     (data: BankDetailsFormDataInterface) => {
        //         return data.paymentOptionId === id
        //     }
        // )

        let tittle = 'Create Officer Details'
        if (id.length > 0) {
            tittle = 'Edit Officer Details'
        }

        setEditModalTittle(tittle)
        // setEditData(data ?? BankDetailsItemDefaultData)
        changeCreateEditModalStatus()
    }

    const changeCreateEditModalStatus = (): void => {
        setCreateEditModalStatus(!createEditModalStatus)
    }
    return (
        <>
            <div>
                <h3>Officer Information</h3>
            </div>
            <Card>
                {companyHousId === 1 && (
                    <>
                        <div className={style.misstingInfo}>
                            If you want to do changes required in officers
                            details, should be filed using respectively the
                            <b>&quot;Edit Officer Address&quot;</b>,{' '}
                            <b>&quot;Add New Officer&quot;</b>,
                            <b>&quot;Terminate Officer&quot;</b> pages before
                            you file the confirmation statement.
                        </div>
                        <div
                            style={{ marginTop: '15px', marginBottom: '15px' }}
                        >
                            <Flex justify="flex-end" gap={5}>
                                <CusttomBackgroundButton
                                    color={'#175ca9'}
                                    name={'Appoint New Officer'}
                                    icon={<PlusOutlined />}
                                    clickAction={() => {
                                        openEditModal('')
                                    }}
                                />
                            </Flex>
                        </div>
                    </>
                )}
                <Collapse defaultActiveKey={['']}>
                    <Collapse.Panel
                        header={
                            <div
                                style={{
                                    display: 'flex',
                                    justifyContent: 'space-between',
                                }}
                            >
                                <span>{`Deveslk`}</span>
                                <span>{getRoleBackgroundColor('Dev')}</span>
                            </div>
                        }
                        key={1}
                    >
                        {companyHousId === 1 && (
                            <div
                                style={{
                                    marginTop: '15px',
                                    marginBottom: '15px',
                                }}
                            >
                                <Flex justify="flex-end" gap={5}>
                                    <Button
                                        type="primary"
                                        size="middle"
                                        danger
                                        icon={<DeleteOutlined />}
                                    >
                                        Terminate Officer
                                    </Button>

                                    <CusttomBackgroundButton
                                        color={'#175ca9'}
                                        name={'Edit Officer Details'}
                                        icon={<EditOutlined />}
                                        clickAction={() => {
                                            openEditModal('1')
                                        }}
                                    />
                                </Flex>
                            </div>
                        )}
                        <Row gutter={[16, 16]}>
                            <Col span={5}>Date of Birth</Col>
                            <Col span={8}>:</Col>
                            <Col span={5}>Date Of Appointment</Col>
                            <Col span={6}>:</Col>
                        </Row>
                        <Row gutter={[16, 16]}>
                            <Col span={5}>Home Address</Col>
                            <Col span={8}>:</Col>
                            <Col span={5}>Corr Address</Col>
                            <Col span={6}>:</Col>
                        </Row>
                        <Row gutter={[16, 16]}>
                            <Col span={5}>Nationality</Col>
                            <Col span={8}>:</Col>
                            <Col span={5}>Residence Country</Col>
                            <Col span={6}>:</Col>
                        </Row>
                        <Row gutter={[16, 16]}>
                            <Col span={5}>Occupation</Col>
                            <Col span={8}>:</Col>
                        </Row>
                    </Collapse.Panel>
                </Collapse>
                <Modal
                    footer={null}
                    onCancel={changeCreateEditModalStatus}
                    width={'50%'}
                    title={editModalTittle}
                    open={createEditModalStatus}
                >
                    <AddNewOfficerForm />
                </Modal>
            </Card>
        </>
    )
}

export default Officers
