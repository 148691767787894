import React, { useCallback, useEffect, useState } from 'react'
import { Card, Table, Space, Modal, Tooltip, Select } from 'antd'
import type { ColumnsType, TableProps } from 'antd/es/table'
import commonStyles from '../../../Utils/Common.less'
import AddNewButton from '../../../Components/AddNewButton'
import SearchInput from '../../../Components/SearchInput'
import { ReactComponent as DeleteIcon } from '../../../Assest/Svg/DeleteIcon.svg'
import { ReactComponent as EditIcon } from '../../../Assest/Svg/EditIcon.svg'
import { UsergroupAddOutlined, EyeOutlined } from '@ant-design/icons'
import PortfolioForm from './profoliomanagerform'
import {
    type ProtfolioTableDataInterface,
    type ProtfolioFormDataInterface,
    type ProtfolioStateInterface,
    type PortfolioManagerDetail,
} from '../../../Types/Client/RegisteredClients/Portfolio'
import { connect, useDispatch } from 'react-redux'
import {
    DeletePortfolioDetails,
    GetPortfolioList,
    SaveProtfolioData,
} from '../../../Services/Portfolio'
import { type TableParamsInterface } from '../../../Types/CommonType'
import {
    commonTableParams,
    setCommonTableParamsData,
} from '../../../Utils/CommontTable'
import calculatePagination from '../../../Utils/Pagination'
import FullWidthModal from '../../../Components/FullWidthModal'
import { GetCategoryDropDownList } from '../../../Services/GroupAndPartners'
import PortfolioClientList from './addORremoveClient'
import { ErrorMessage } from '../../../Utils/Notification'
import PortfolioView from '../../../Components/viewProfolioDetails'

export const ProtfolioDeFaultData = {
    portfolioId: 0,
    number: '',
    name: '',
    managerId: 0,
    remarks: '',
    clientCategoryId: 0,
    manager: '',
}

const PortfolioList = (props: ProtfolioStateInterface): React.ReactElement => {
    const dispatch = useDispatch()
    const { categoryDropDownList, portfolioList, portfolioListCount } = props
    const [tableParams, setTableParams] =
        useState<TableParamsInterface>(commonTableParams)
    const [createEditModalStatus, setCreateEditModalStatus] = useState(false)
    const [createAddStatus, setAddModalStatus] = useState(false)
    const [deleteModalStatus, setDeleteModalStatus] = useState(false)
    const [editModalTittle, setEditModalTittle] = useState('Create Portfolio')
    const [editData, setEditData] =
        useState<PortfolioManagerDetail>(ProtfolioDeFaultData)
    const [selectecategoryId, setSelecteCategoryId] = useState(0)
    const [currentPage, setCurrentPage] = useState(1)
    const [itemsPerPage, setItemsPerPage] = useState(10)
    const [portfolioId, setPortfolioId] = useState('')
    const [Id, setId] = useState(0)
    const handleSelectChange = (value: string | number): void => {
        setSelecteCategoryId(value === 'all' ? 0 : Number(value))
    }
    const [portfolioModel, setportfolioModel] = useState(false)
    useEffect(() => {
        GetCategoryDropDownList(dispatch)
    }, [dispatch])

    const getTableData = useCallback(() => {
        const { pageSize, pageNo, sortOrderList, searchCriteriaList } =
            setCommonTableParamsData(tableParams)
        GetPortfolioList(dispatch, selectecategoryId, {
            pageNo,
            pageSize,
            searchCriteriaList,
            sortOrderList,
        })
    }, [
        tableParams?.sorter,
        tableParams.pagination?.current,
        tableParams?.filter,
        selectecategoryId,
    ])

    useEffect(() => {
        getTableData()
    }, [getTableData])

    const handleDataCountChange = useCallback(() => {
        setTableParams({
            ...tableParams,
            pagination: {
                ...tableParams.pagination,
                total: portfolioListCount,
            },
        })
    }, [portfolioListCount])

    useEffect(() => {
        handleDataCountChange()
    }, [handleDataCountChange])

    const handlePageChange = (pageNo: number, pageSize: number): void => {
        setCurrentPage(pageNo)
        setItemsPerPage(pageSize)
    }

    const pagination = calculatePagination(
        currentPage,
        portfolioListCount,
        itemsPerPage
    )

    const columns: ColumnsType<ProtfolioTableDataInterface> = [
        {
            title: 'Number',
            dataIndex: 'number',
        },
        {
            title: 'Portfolio Name',
            dataIndex: 'name',
        },
        {
            title: 'Portfolio Manager',
            dataIndex: 'manager',
        },
        {
            width: 300,
            title: 'Remarks',
            dataIndex: 'remarks',
        },
        {
            title: 'Action',
            key: 'action',
            render: (_, record: ProtfolioTableDataInterface) => {
                return (
                    <Space size="middle">
                        <Tooltip title="Edit Portfolio">
                            <EditIcon
                                className={commonStyles.clickableIcon}
                                onClick={() => {
                                    openEditModal(record.portfolioId)
                                }}
                            />
                        </Tooltip>

                        <Tooltip title="Delete Portfolio">
                            <DeleteIcon
                                onClick={() => {
                                    deleteModalFunction(
                                        record?.portfolioId.toString()
                                    )
                                }}
                                className={commonStyles.clickableIcon}
                            />
                        </Tooltip>

                        <Tooltip title="Add Or Remove Client">
                            <UsergroupAddOutlined
                                style={{ fontSize: '20px' }}
                                onClick={() => {
                                    openAddOrRemoveModel(record?.portfolioId)
                                }}
                                className={commonStyles.clickableIcon}
                            />
                        </Tooltip>
                        <Tooltip title="View Portfolio Details">
                            <EyeOutlined
                                onClick={() => {
                                    openProtfolio(
                                        record?.portfolioId.toString()
                                    )
                                }}
                            />
                        </Tooltip>
                    </Space>
                )
            },
        },
    ]

    const onChangeTableParams: TableProps<ProtfolioTableDataInterface>['onChange'] =
        (pagination, filters, sorter: any, extra) => {
            handlePageChange(pagination.current ?? 1, pagination.pageSize ?? 10)
            setTableParams({
                ...tableParams,
                pagination,
                sorter: {
                    field: sorter?.field ?? '',
                    order: sorter?.order ?? '',
                },
            })
        }

    const onChangeText = (data: React.ChangeEvent<HTMLInputElement>): void => {
        if (data.target.value?.length === 0) {
            onChangeFilter(data.target.value)
        }
    }

    const onChangeFilter = (data: string): void => {
        setTableParams({
            ...tableParams,
            filter: {
                fieldName: 'multiplesearch',
                searchOperator: 10,
                fieldValue1: data,
                fieldValue2: '',
            },
        })
    }

    const deleteModalFunction = (id: string): void => {
        setDeleteModalStatus(!deleteModalStatus)
        let portfolioId = ''
        if (!deleteModalStatus) {
            portfolioId = id
        }
        setPortfolioId(portfolioId)
    }

    const deletePortfolio = (): void => {
        DeletePortfolioDetails(dispatch, portfolioId, getTableData)
        deleteModalFunction('')
    }

    const openEditModal = (id: number): void => {
        const data = portfolioList?.find((data: ProtfolioFormDataInterface) => {
            return data.portfolioId === id
        })

        let tittle = 'Create Portfolio'
        if (id !== 0) {
            tittle = 'Edit Portfolio'
        }

        setEditModalTittle(tittle)
        setEditData(data ?? ProtfolioDeFaultData)
        changeCreateEditModalStatus()
    }

    const changeCreateEditModalStatus = (): void => {
        setCreateEditModalStatus(!createEditModalStatus)
    }
    const openAddOrRemoveModel = (id: number): void => {
        setId(id)
        setAddModalStatus(!createAddStatus)
    }

    const changeAddModalStatus = (): void => {
        setAddModalStatus(!createAddStatus)
    }
    const onSavePortfolioDetails = (
        data: ProtfolioFormDataInterface,
        callBack: CallableFunction
    ): void => {
        const nameExists = portfolioList.some(
            (item) => item.name === data.name && data.portfolioId === 0
        )

        if (nameExists) {
            ErrorMessage(
                'This Porfolio Name is already taken. Please select another name.'
            )
        } else {
            const dataToSave = {
                portfolioId: data.portfolioId ?? 0,
                number: data.number ?? '',
                name: data.name,
                remarks: data.remarks ?? '',
                managerId: data.managerId,
                clientCategoryId: data.clientCategoryId,
            }
            void saveBanDetailsData(dataToSave, callBack)
        }
    }

    const saveBanDetailsData = async (
        data: ProtfolioFormDataInterface,
        callBack: CallableFunction
    ): Promise<void> => {
        await SaveProtfolioData(dispatch, data)
            .then((result) => {
                if (result !== null && result !== undefined) {
                    changeCreateEditModalStatus()
                    getTableData()
                    callBack()
                }
            })
            .catch(() => {})
    }
    const updateList: ProtfolioTableDataInterface[] = portfolioList?.map(
        (item) => ({
            ...item,
            key: item.portfolioId,
        })
    )

    const openProtfolio = (portFolioId: string): void => {
        setPortfolioId(portFolioId)
        setportfolioModel(!portfolioModel)
    }
    return (
        <>
            <Card
                title="Portfolio Manager"
                extra={
                    <>
                        <AddNewButton
                            name="Add New"
                            clickAction={() => {
                                openEditModal(0)
                            }}
                        />
                        <Select
                            className={commonStyles.topdropdown2}
                            placeholder="Please select"
                            onChange={handleSelectChange}
                            value={
                                selectecategoryId === 0
                                    ? 'all'
                                    : selectecategoryId
                            }
                            options={[
                                { value: 'all', label: 'All' },
                                ...categoryDropDownList,
                            ]}
                        />
                    </>
                }
                className={commonStyles.card}
            >
                <SearchInput
                    placeHolder={'Search Portfolio by keywords'}
                    onSearch={onChangeFilter}
                    onChange={onChangeText}
                    width="478"
                />

                <div className={commonStyles.table}>
                    <Table
                        columns={columns}
                        dataSource={updateList}
                        onChange={onChangeTableParams}
                        pagination={pagination}
                    />
                </div>
                <FullWidthModal
                    modalStatus={deleteModalStatus}
                    closeModal={deleteModalFunction}
                    tittle="You are about to delete this Portfolio Details. Click 'Yes' to continue."
                    smallTittle="Warning: This action cannot be undone!"
                    yesButtonFunction={deletePortfolio}
                />
                <Modal
                    footer={null}
                    onCancel={changeCreateEditModalStatus}
                    width={'40%'}
                    title={editModalTittle}
                    open={createEditModalStatus}
                >
                    <PortfolioForm
                        onSave={onSavePortfolioDetails}
                        editData={editData}
                    />
                </Modal>
                <Modal
                    footer={null}
                    onCancel={changeAddModalStatus}
                    width={'60%'}
                    title={'Add or Remove Client'}
                    open={createAddStatus}
                >
                    <PortfolioClientList
                        portfolioId={Id}
                        clientCategoryId={selectecategoryId}
                    />
                </Modal>
                <Modal
                    title="Portfolio Details"
                    open={portfolioModel}
                    onCancel={() => {
                        setportfolioModel(!portfolioModel)
                    }}
                    footer={null}
                    width={'50%'}
                >
                    <PortfolioView
                        businessId={0}
                        referenceId={0}
                        portfolioId={Number(portfolioId)}
                    />
                </Modal>
            </Card>
        </>
    )
}
const mapStateToProps = (state: any): ProtfolioStateInterface => {
    return {
        categoryDropDownList: state.initial.categoryDropDownList,
        portfolioList: state.portfolio.portfolioList,
        portfolioListCount: state.portfolio.portfolioListCount,
    }
}

export default connect(mapStateToProps)(PortfolioList)
