import React, { useEffect, useState } from 'react'
import { Row } from 'antd'
import styles from './index.less'
// import { ReactComponent as WelcomeLogo } from '../../Assest/Svg/WelcomeLogin.svg'
import { utilDate } from '../../Utils/formatDate'

const Welcome = ({
    lastLoginDate,
}: {
    lastLoginDate: string
}): React.ReactElement => {
    const user = localStorage.getItem('user')
    const name = user !== null ? JSON.parse(user)?.userName : ''
    const [, SetLoginDate] = useState(lastLoginDate)

    useEffect(() => {
        if (lastLoginDate.length > 0) {
            SetLoginDate(utilDate(lastLoginDate))
        }
    }, [user, lastLoginDate])

    return (
        <Row className={styles.welcome}>
            <div className={styles.welcome__div}>
                <p className={styles.welcome__div__largeText}>
                    {' '}
                    {/* Hi&nbsp; */}
                    <span className={styles.welcome__div__largeText_name}>
                        {name}&nbsp;
                    </span>
                    Welcome back Dashboard&nbsp;
                    {/* <span className={styles.welcome__div__smallText}>
                        Let’s check your Dashboard today
                    </span> */}
                </p>
            </div>
            <div className={styles.welcome__div}>
                {/* <WelcomeLogo /> */}
                <p className={styles.welcome__div__smallText}>
                    &nbsp; Your last login:&nbsp; {lastLoginDate}
                </p>
            </div>
        </Row>
    )
}

export default Welcome
