import { type PayloadAction, createSlice } from '@reduxjs/toolkit'
import {
    type UserRightsFormDataInterface,
    type UserRightsPayloadInterface,
} from '../Types/UserRights'
import {
    type ManageUserDetailsFormDataInterface,
    type ManageUserDetailsPayloadInterface,
} from '../Types/ManageUser'
import { type ListFrontEndInterface } from '../Types/CommonType'

interface InitailStateInterface {
    userRightsList: UserRightsFormDataInterface[]
    userRightsListCount: number
    UserInfoList: ManageUserDetailsFormDataInterface[]
    UserInfoListCount: number
    departmentDropDownList: ListFrontEndInterface[]
    designationDropDownList: ListFrontEndInterface[]
}

const UserRightsSlice = createSlice({
    name: 'userrights',
    initialState: {
        userRightsList: [],
        userRightsListCount: 0,
        UserInfoList: [],
        UserInfoListCount: 0,
        departmentDropDownList: [],
        designationDropDownList: [],
    },
    reducers: {
        userRightsList(
            state: InitailStateInterface,
            action: PayloadAction<UserRightsPayloadInterface>
        ) {
            state.userRightsList = action?.payload?.data
            state.userRightsListCount = action?.payload?.noOfRecords
        },
        UserInfoList(
            state: InitailStateInterface,
            action: PayloadAction<ManageUserDetailsPayloadInterface>
        ) {
            state.UserInfoList = action?.payload?.data
            state.UserInfoListCount = action?.payload?.noOfRecords
        },
        departmentDropDownLists(
            state: InitailStateInterface,
            action: PayloadAction<ListFrontEndInterface[]>
        ) {
            state.departmentDropDownList = action?.payload
        },
        DesignationDropDownLists(
            state: InitailStateInterface,
            action: PayloadAction<ListFrontEndInterface[]>
        ) {
            state.designationDropDownList = action?.payload
        },
    },
})
export const {
    userRightsList,
    UserInfoList,
    departmentDropDownLists,
    DesignationDropDownLists,
} = UserRightsSlice.actions

export default UserRightsSlice.reducer
