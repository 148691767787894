import React, { useEffect, useState } from 'react'
import {
    type setTasks,
    type TaskFormDataStateInterface,
} from '../../../Types/HelpDesk/Cretetask'
import { connect, useDispatch } from 'react-redux'
import {
    GetTaskData,
    GetsatusList,
    getAllDepartment,
    getProjectlist,
    getusersdroplist,
} from '../../../Services/Helpdesk/CreteTask'
import { Card, Col, Flex, Modal, Row, Select, Spin, Tabs } from 'antd'
import SearchInput from '../../../Components/SearchInput'
import AddNewButton from '../../../Components/AddNewButton'
import { GetUserList } from '../../../Services/User'
import CreateTaskForm from './CreateTaskForm'
import ClientCreationPage from './CreateClient'
import TabPane from 'antd/es/tabs/TabPane'
import ToDoTasks, { TaskdefaultData } from './toDoTasks'
import InprogressTasks from './inprogressTasks'
import CompletedTask from './completedTask'

const LandingView = (props: TaskFormDataStateInterface): React.ReactElement => {
    const dispatch = useDispatch()
    const { userList, projectDropDownList, allDepartmentList } = props

    const [activeTab, setActiveTab] = useState('1')
    const [searchString, setSearchString] = useState('=NA=')
    const [createEditModalStatus, setCreateEditModalStatus] = useState(false)
    const [editModalTittle, setEditModalTittle] = useState('Edit Task')
    const [editData, setEditData] = useState<setTasks>(TaskdefaultData)
    const [businesstypeid, setSelectedServiceId] = useState<number>(0)
    const [miisedfieldid, setmiisefieldid] = useState<number>(0)
    const [taskId] = useState('')
    const [modalVisible, setModalVisible] = useState(false)
    const [selectedDepartment, setSelectedDepartment] = useState<number>(0)
    const [selectedProjectID, setSelectedProjectID] = useState<number>(0)
    const [isLoading] = useState<boolean>(false)
    const [selectedTaskId, setSelectedTaskIds] = useState<string>('')
    const [selectedTaskId2, setSelectedTaskIds2] = useState<string>('')

    const [activeItDepartment, setActiveItDepartment] = useState(false)
    const [activeItDepartmentView, setActiveItDepartmentView] = useState(false)
    const [createTaskUpdater, setCreateTaskUpdater] = useState(false)

    const user = localStorage.getItem('user')
    const userId = user !== null ? JSON.parse(user)?.userId : ''

    useEffect(() => {
        if (userId !== 1 && userId !== 3) {
            setSelectedDepartment(userId)
            if (selectedDepartment === 5) {
                setActiveItDepartmentView(true)
            } else {
                setActiveItDepartmentView(false)
            }
        }
    }, [userId])

    useEffect(() => {
        if (selectedDepartment !== 0) {
            getProjectlist(dispatch, selectedDepartment)
        }
    }, [selectedDepartment, dispatch])

    useEffect(() => {
        GetsatusList(dispatch)
    }, [])

    useEffect(() => {
        getAllDepartment(dispatch)
    }, [createTaskUpdater, dispatch])

    useEffect(() => {
        GetUserList(dispatch)
    }, [])

    useEffect(() => {
        void getusersdroplist(dispatch)
    }, [])

    const handleTabChange = (key: string): void => {
        setActiveTab(key)
        if (key === '3') {
            setActiveItDepartment(true)
        }
        if (key === '1') {
            setActiveItDepartment(false)
        }
        setActiveItDepartment(false)
    }

    const onChangeText = (event: React.ChangeEvent<HTMLInputElement>): void => {
        const searchText = event.target.value
        onChangeFilter(searchText)
    }
    const onChangeFilter = (data: string): void => {
        if (data === '') {
            setSearchString('=NA=')
        } else {
            setSearchString(data)
        }
    }

    const handleUserSelect = (value: string | number): void => {
        setSelectedServiceId(value === 'all' ? 0 : Number(value))
    }

    const handleUserSelect1 = (value: string | number): void => {
        setmiisefieldid(value === 'all' ? 0 : Number(value))
    }

    const handleOpenModal = (): void => {
        setModalVisible(true)
    }
    const changeCreateEditModalStatus = (): void => {
        setCreateEditModalStatus(!createEditModalStatus)
    }

    const openEditModal = (id: string): void => {
        void openEditData(id)
    }
    const openEditData = async (id: string): Promise<void> => {
        // const data = taskDetailsList.find((data: TaskData) => {
        //     return data.taskId === id
        // })
        try {
            if (id !== '') {
                const data = await GetTaskData(dispatch, id)
                let title = 'Create Task Details'
                if (id !== null && id !== undefined && id !== '') {
                    title = 'Edit Task Details'
                }

                setEditModalTittle(title)
                setEditData(data ?? TaskdefaultData)
                changeCreateEditModalStatus()
            } else {
                setEditModalTittle('Edit Task Details')
                setEditData(TaskdefaultData)
                changeCreateEditModalStatus()
            }
        } catch (err) {}
    }

    const handleCloseModal = (): void => {
        setModalVisible(false)
    }

    const onSaveTask = (callBack: CallableFunction): void => {
        changeCreateEditModalStatus()
        callBack()
        setCreateTaskUpdater(true)
        setTimeout(() => {
            setCreateTaskUpdater(false)
        }, 4000)
    }

    const handleDepartmentChange = (value: number): void => {
        if (value !== null) {
            setSelectedDepartment(value)

            if (value === 5) {
                setActiveItDepartmentView(true)
            } else {
                setActiveItDepartmentView(false)
            }
        }
    }

    const handleProjectChange = (value: number): void => {
        if (value !== null) {
            setSelectedProjectID(value)
        }
    }

    const handleSetSelectedTaskIdsTod = (id: string): void => {
        setSelectedTaskIds(id)
    }

    const handleSetSelectedTaskIdsInpro = (id: string): void => {
        setSelectedTaskIds2(id)
    }

    return (
        <>
            <Card>
                <Row gutter={[16, 16]} align="middle">
                    <Col>
                        <SearchInput
                            placeHolder={'Search by keywords'}
                            onChange={onChangeText}
                            onSearch={onChangeFilter}
                            width="228"
                        />
                    </Col>
                    <Col>
                        <Select
                            style={{ width: '120px' }}
                            showSearch
                            placeholder="Assign By"
                            onChange={handleUserSelect}
                            value={
                                businesstypeid === 0 ? 'all' : businesstypeid
                            }
                            filterOption={(input, option) =>
                                !(option == null) &&
                                option.label
                                    .toLowerCase()
                                    .includes(input.toLowerCase())
                            }
                            options={[
                                { value: 'all', label: 'Assign By' },
                                ...userList,
                            ]}
                        />
                    </Col>
                    <Col>
                        <Select
                            style={{ width: '120px' }}
                            showSearch
                            placeholder="Please select"
                            onChange={handleUserSelect1}
                            value={miisedfieldid === 0 ? 'all' : miisedfieldid}
                            filterOption={(input, option) =>
                                !(option == null) &&
                                option.label
                                    .toLowerCase()
                                    .includes(input.toLowerCase())
                            }
                            options={[
                                { value: 'all', label: 'Assign To' },
                                ...userList,
                            ]}
                        />
                    </Col>
                    <Col>
                        <Select
                            showSearch
                            placeholder={
                                <span style={{ fontWeight: 'bold' }}>
                                    Department
                                </span>
                            }
                            onChange={handleDepartmentChange}
                            filterOption={(input, option) =>
                                !(option == null) &&
                                option.label
                                    .toLowerCase()
                                    .includes(input.toLowerCase())
                            }
                            options={[
                                { label: 'Please select', value: null },
                                ...allDepartmentList.map((item) => ({
                                    label: item.label,
                                    value: item.value,
                                })),
                            ]}
                            style={{ width: '200px' }}
                        />
                    </Col>
                    <Col>
                        <Select
                            showSearch
                            placeholder={
                                <span style={{ fontWeight: 'bold' }}>
                                    Project
                                </span>
                            }
                            onChange={handleProjectChange}
                            filterOption={(input, option) =>
                                !(option == null) &&
                                option.label
                                    .toLowerCase()
                                    .includes(input.toLowerCase())
                            }
                            options={[
                                { label: 'Please select', value: null },
                                ...projectDropDownList.map((item) => ({
                                    label: item.label,
                                    value: item.value,
                                })),
                            ]}
                            style={{ width: '200px' }}
                        />
                    </Col>
                    <Col flex="auto">
                        <Row justify="end" gutter={[8, 0]}>
                            <Col>
                                <AddNewButton
                                    name="Create Client"
                                    clickAction={handleOpenModal}
                                />
                            </Col>
                            <Col>
                                <AddNewButton
                                    name="Create Task"
                                    clickAction={() => {
                                        openEditModal('')
                                    }}
                                />
                            </Col>
                        </Row>
                    </Col>
                </Row>
                <Tabs
                    activeKey={activeTab}
                    onChange={handleTabChange}
                    defaultActiveKey="1"
                >
                    <TabPane tab="My Task" key="1">
                        <Row>
                            <Col span={12}>
                                <ToDoTasks
                                    setSelectedTaskIds={
                                        handleSetSelectedTaskIdsTod
                                    }
                                    selectedProjectID={selectedProjectID}
                                    AssignBY={businesstypeid}
                                    AssignTo={miisedfieldid}
                                    activeItDepartment={activeItDepartment}
                                    activeStates={selectedTaskId2}
                                    activeTab={activeTab}
                                    searchString={searchString}
                                />
                            </Col>
                            <Col span={12}>
                                <InprogressTasks
                                    setSelectedTaskIds={
                                        handleSetSelectedTaskIdsInpro
                                    }
                                    selectedProjectID={selectedProjectID}
                                    AssignBY={businesstypeid}
                                    AssignTo={miisedfieldid}
                                    activeItDepartment={activeItDepartment}
                                    activeStates={selectedTaskId}
                                    activeTab={activeTab}
                                    searchString={searchString}
                                />
                            </Col>
                        </Row>
                    </TabPane>
                    {activeItDepartmentView && (
                        <TabPane tab="QA" key="3">
                            <Row>
                                <Col span={12}>
                                    <ToDoTasks
                                        setSelectedTaskIds={
                                            handleSetSelectedTaskIdsTod
                                        }
                                        selectedProjectID={selectedProjectID}
                                        AssignBY={businesstypeid}
                                        AssignTo={miisedfieldid}
                                        activeItDepartment={activeItDepartment}
                                        activeStates={selectedTaskId2}
                                        activeTab={activeTab}
                                        searchString={searchString}
                                    />
                                </Col>
                                <Col span={12}>
                                    <InprogressTasks
                                        setSelectedTaskIds={
                                            handleSetSelectedTaskIdsInpro
                                        }
                                        selectedProjectID={selectedProjectID}
                                        AssignBY={businesstypeid}
                                        AssignTo={miisedfieldid}
                                        activeItDepartment={activeItDepartment}
                                        activeStates={selectedTaskId}
                                        activeTab={activeTab}
                                        searchString={searchString}
                                    />
                                </Col>
                            </Row>
                        </TabPane>
                    )}

                    <TabPane tab="Completed" key="2">
                        {isLoading && activeTab === '2' ? (
                            <Flex
                                align="center"
                                justify="center"
                                style={{ padding: '20px' }}
                            >
                                <Spin />
                            </Flex>
                        ) : (
                            <CompletedTask
                                setSelectedTaskIds={handleSetSelectedTaskIdsTod}
                                selectedProjectID={selectedProjectID}
                                AssignBY={businesstypeid}
                                AssignTo={miisedfieldid}
                                activeItDepartment={activeItDepartment}
                                activeStates={selectedTaskId2}
                                activeTab={activeTab}
                                searchString={searchString}
                            />
                        )}
                    </TabPane>
                </Tabs>
            </Card>

            <Modal
                title="Create Client"
                visible={modalVisible}
                onCancel={handleCloseModal}
                footer={null}
                width={'40%'}
            >
                <ClientCreationPage />
            </Modal>
            <Modal
                footer={null}
                onCancel={changeCreateEditModalStatus}
                width={'50%'}
                title={editModalTittle}
                open={createEditModalStatus}
                destroyOnClose={true}
            >
                <CreateTaskForm
                    onSave={onSaveTask}
                    editData={editData}
                    taskId={taskId}
                />
            </Modal>
        </>
    )
}

const mapStateToProps = (state: any): TaskFormDataStateInterface => {
    return {
        userList: state.user.userList,
        taskDetailsList: state.helpdesk.taskDetailsList,
        projectDropDownList: state.helpdesk.projectDropDownList,
        allDepartmentList: state.helpdesk.allDepartmentList,
        taskDetailsListCount: state.helpdesk.taskDetailsListCount,
        userimageList: state.helpdesk.userimageList,
        userimageListCount: state.helpdesk.userimageListCount,
        satusList: state.helpdesk.satusList,
        ProjectManagmentStausList: state.initial.ProjectManagmentStausList,
    }
}

export default connect(mapStateToProps)(LandingView)
