import { type PayloadAction, createSlice } from '@reduxjs/toolkit'

interface InitialStateInterface {
    flag: number

    servicedataId: ServicedataIdInterface
}

interface ServicedataIdInterface {
    serviceProcesssId: number
    serviceId: number
}

const DashboardFlagReducer = createSlice({
    name: 'dashboardFlag',
    initialState: {
        flag: 1,

        servicedataId: {
            serviceProcesssId: 0,
            serviceId: 0,
        },
    },
    reducers: {
        flag(state: InitialStateInterface, action: PayloadAction<number>) {
            state.flag = action.payload
        },

        servicedataId(state, action: PayloadAction<ServicedataIdInterface>) {
            state.servicedataId = action.payload
        },
    },
})

export const { flag, servicedataId } = DashboardFlagReducer.actions
export default DashboardFlagReducer.reducer
