import React from 'react'
import { Col, Row, Input, Form, Button } from 'antd'
import commonStyles from '../../../Utils/Common.less'
import { SaveOutlined } from '@ant-design/icons'
import { useDispatch } from 'react-redux'
import { SaveHoldthisThisProcess } from '../../../Services/ToDoViewAllList'

interface holdthisMolde {
    changeModelState: () => void
    serviceProcessId: number
    flagIds: string
}
const HoldThisProcess = (props: holdthisMolde): React.ReactElement => {
    const { changeModelState, serviceProcessId, flagIds } = props
    const [form] = Form.useForm()
    const dispatch = useDispatch()
    const onSave = (): void => {
        void onSavePhone()
    }
    const onSavePhone = async (): Promise<void> => {
        try {
            await form.validateFields()

            const formData = form.getFieldsValue()

            const comments = formData.description

            await SaveHoldthisThisProcess(
                dispatch,
                serviceProcessId,
                flagIds,
                comments
            )

            form.setFieldsValue({
                description: '',
            })

            changeModelState()
        } catch (error) {}
    }

    const customizeRequiredMark = (
        label: React.ReactNode,
        { required }: { required: boolean }
    ): React.ReactElement => (
        <>
            {label}
            &nbsp;
            {required && <span className={commonStyles.requireIcon}>*</span>}
        </>
    )
    return (
        <div className={commonStyles.formWrapper}>
            <Form
                name="complex-form"
                labelCol={{ span: 24 }}
                wrapperCol={{ span: 24 }}
                initialValues={{ remember: true }}
                layout={'vertical'}
                autoComplete="off"
                form={form}
                size="small"
                requiredMark={customizeRequiredMark}
            >
                <Row gutter={16}>
                    <Col span={24}>
                        <Form.Item
                            label="Comments"
                            name="description"
                            rules={[
                                {
                                    required: true,
                                    message: 'Please input your Comments!',
                                },
                            ]}
                        >
                            <Input.TextArea rows={4} />
                        </Form.Item>
                    </Col>
                </Row>

                <Row gutter={16}>
                    <Col offset={21} span={2}>
                        <Button type="primary" size="middle" onClick={onSave}>
                            <SaveOutlined />
                        </Button>
                    </Col>
                </Row>
            </Form>
        </div>
    )
}
export default HoldThisProcess
