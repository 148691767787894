import React from 'react'
import { Row } from 'antd'
import commontStyles from '../../../../Utils/Common.less'
import { type PartneshipExpandViewData } from '../../../../Types/ServiceSetup/ClientServiceUser'

const ParneshipExpandView = (
    props: PartneshipExpandViewData
): React.ReactElement => {
    const { partneshipData } = props

    return (
        <div className={commontStyles.expandCard}>
            <Row className={commontStyles.expandRow}>
                Partnership UTR :-{' '}
                <div className={commontStyles.expandRowValue}>
                    {partneshipData.partnershipUTR}
                </div>
            </Row>
        </div>
    )
}

export default ParneshipExpandView
