import { Button, Card, Flex, Modal, Space, Switch, Table, Tooltip } from 'antd'
import React, { useCallback, useEffect, useState } from 'react'
import commonStyles from '../../../Utils/Common.less'
import type { ColumnsType, TableProps } from 'antd/es/table'
import { connect, useDispatch } from 'react-redux'
import SearchInput from '../../../Components/SearchInput'
import { type TableParamsInterface } from '../../../Types/CommonType'
import {
    commonTableParams,
    setCommonTableParamsData,
} from '../../../Utils/CommontTable'
import calculatePagination from '../../../Utils/Pagination'
import { ReactComponent as DeleteIcon } from '../../../Assest/Svg/DeleteIcon.svg'
import { ReactComponent as PlusIcon } from '../../../Assest/Svg/Plus.svg'
import { ReactComponent as EditIcon } from '../../../Assest/Svg/EditIcon.svg'
import FullWidthModal from '../../../Components/FullWidthModal'
import {
    type ArticalGroupListTableData,
    type ListofArticalGroupStateInterface,
    type ArticleGroupData,
} from '../../../Types/wikiArticalGroup'
import ArticalGroupDetailsForm from './wikiArticalForm'
import {
    ActiveInactiveArticalGroup,
    DeleteArticalGroup,
    GetArticalGroupList,
    SaveArticalGroup,
} from '../../../Services/wikiArticalGroup'
export const ArticleGroupDefaultData = {
    articleGroupId: 0,
    groupName: '',
    description: '',
    icon: '',
    iconType: 0,
    isPublic: 0,
    isSharedInSocialMedia: 0,
    isActive: '',
}
const WikiArticalGroupList = (
    props: ListofArticalGroupStateInterface
): React.ReactElement => {
    const { articalListGroupData, articalListGroupDataCount } = props
    const [currentPage, setCurrentPage] = useState(1)
    const [itemsPerPage, setItemsPerPage] = useState(10)
    const dispatch = useDispatch()
    const [tableParams, setTableParams] =
        useState<TableParamsInterface>(commonTableParams)
    const [editData, setEditData] = useState<ArticleGroupData>(
        ArticleGroupDefaultData
    )
    const [articalGroupId, setArticalGroupId] = useState(0)
    const [editModalTittle, setEditModalTittle] = useState(
        'Create Article Group'
    )
    const [deleteModalStatus, setDeleteModalStatus] = useState(false)
    const [createEditModalStatus, setCreateEditModalStatus] = useState(false)
    const [activeInactiveModalStatus, setActiveInactiveModalStatus] =
        useState(false)
    const [activeInactiveStatusLabel, setActiveInactiveStatusLabel] =
        useState('')
    const getTableData = useCallback(() => {
        const { pageSize, pageNo, sortOrderList, searchCriteriaList } =
            setCommonTableParamsData(tableParams)
        GetArticalGroupList(dispatch, {
            pageNo,
            pageSize,
            searchCriteriaList,
            sortOrderList,
        })
    }, [
        tableParams?.sorter,
        tableParams.pagination?.current,
        tableParams?.filter,
    ])

    useEffect(() => {
        getTableData()
    }, [getTableData])

    const handleDataCountChange = useCallback(() => {
        setTableParams({
            ...tableParams,
            pagination: {
                ...tableParams.pagination,
                total: articalListGroupDataCount,
            },
        })
    }, [articalListGroupDataCount])

    useEffect(() => {
        handleDataCountChange()
    }, [handleDataCountChange])

    const handlePageChange = (pageNo: number, pageSize: number): void => {
        setCurrentPage(pageNo)
        setItemsPerPage(pageSize)
    }

    const pagination = calculatePagination(
        currentPage,
        articalListGroupDataCount,
        itemsPerPage
    )

    const columns: ColumnsType<ArticalGroupListTableData> = [
        {
            title: 'Article Group',
            key: 'articleName',
            render: (record: ArticalGroupListTableData) => {
                return (
                    <div>
                        <h3>{record.groupName}</h3>
                        <div>
                            <div
                                dangerouslySetInnerHTML={{
                                    __html: record.description,
                                }}
                                style={{ height: 'auto' }}
                            />
                        </div>
                    </div>
                )
            },
        },
        {
            title: '',
            key: 'action1',
            render: (record: ArticalGroupListTableData) => {
                return (
                    <Space size="middle">
                        <Tooltip title="Edit Article Group">
                            <EditIcon
                                onClick={() => {
                                    openEditModal(record?.articleGroupId)
                                }}
                                className={commonStyles.clickableIcon}
                            />
                        </Tooltip>
                        <Tooltip
                            title={`Click ${
                                record.isActive === 'Active'
                                    ? 'Active'
                                    : 'Inactive'
                            }
                                    `}
                        >
                            <Switch
                                checked={record.isActive === 'Active'}
                                onClick={() => {
                                    activeInactiveModalFunction(
                                        record?.articleGroupId,
                                        record.isActive
                                    )
                                }}
                            />
                        </Tooltip>

                        <Tooltip title="Delete Article Group">
                            <DeleteIcon
                                onClick={() => {
                                    deleteModalFunction(record?.articleGroupId)
                                }}
                                className={commonStyles.clickableIcon}
                            />
                        </Tooltip>
                    </Space>
                )
            },
        },
    ]

    const onChangeTableParams: TableProps<ArticalGroupListTableData>['onChange'] =
        (pagination, filters, sorter: any, extra) => {
            handlePageChange(pagination.current ?? 1, pagination.pageSize ?? 10)
            setTableParams({
                ...tableParams,
                pagination,
                sorter: {
                    field: sorter?.field ?? '',
                    order: sorter?.order ?? '',
                },
            })
        }

    const onChangeText = (data: React.ChangeEvent<HTMLInputElement>): void => {
        if (data.target.value?.length === 0) {
            onChangeFilter(data.target.value)
        }
    }

    const onChangeFilter = (data: string): void => {
        setTableParams({
            ...tableParams,
            filter: {
                fieldName: 'multiplesearch',
                searchOperator: 10,
                fieldValue1: data,
                fieldValue2: '',
            },
            pagination: {
                ...tableParams.pagination,
                current: 1,
            },
        })
    }

    const deleteModalFunction = (id: number): void => {
        setDeleteModalStatus(!deleteModalStatus)
        let articalgroupid = 0
        if (!deleteModalStatus) {
            articalgroupid = id
        }
        setArticalGroupId(articalgroupid)
    }

    const deleteArticalDetails = (): void => {
        DeleteArticalGroup(dispatch, articalGroupId, getTableData)
        deleteModalFunction(0)
    }

    const openEditModal = (id: number): void => {
        const data = articalLists.find((data: ArticleGroupData) => {
            return data.articleGroupId === id
        })

        let tittle = 'Create Article Group'
        if (id > 0) {
            tittle = 'Edit Article Group'
        }

        setEditModalTittle(tittle)
        setEditData(data ?? ArticleGroupDefaultData)
        changeCreateEditModalStatus()
    }
    const changeCreateEditModalStatus = (): void => {
        setCreateEditModalStatus(!createEditModalStatus)
    }

    const activeInactiveModalFunction = (
        id: number,
        activeStatus: string
    ): void => {
        setActiveInactiveModalStatus(!activeInactiveModalStatus)
        let articalgroupid = 0
        if (!activeInactiveModalStatus) {
            articalgroupid = id
        }
        let label = 'Active'
        if (activeStatus === 'Active') {
            label = 'Inactive'
        }
        setActiveInactiveStatusLabel(label)
        setArticalGroupId(articalgroupid)
    }

    const updateActiveInactiveCorporate = (): void => {
        let status = 'Active'
        if (activeInactiveStatusLabel === 'Inactive') status = 'Inactive'
        ActiveInactiveArticalGroup(
            dispatch,
            articalGroupId,
            status,
            getTableData
        )
        activeInactiveModalFunction(0, '')
    }

    const onSaveArticalDetails = (
        data: ArticleGroupData,
        callBack: CallableFunction
    ): void => {
        void saveArticalDetailsData(data, callBack)
    }

    const saveArticalDetailsData = async (
        data: ArticleGroupData,
        callBack: CallableFunction
    ): Promise<void> => {
        await SaveArticalGroup(dispatch, data)
            .then((result) => {
                if (result !== null && result !== undefined) {
                    changeCreateEditModalStatus()
                    getTableData()
                    callBack()
                }
            })
            .catch(() => {})
    }

    const articalLists = articalListGroupData?.map((item) => ({
        ...item,
        key: item.articleGroupId,
    }))

    return (
        <>
            <div>
                <h2>Article Group</h2>
            </div>
            <Card title={'Article Group'}>
                <Flex justify="space-between">
                    <SearchInput
                        placeHolder={'Search by keywords & press enter'}
                        onSearch={onChangeFilter}
                        onChange={onChangeText}
                        width="478"
                    />
                    <Tooltip title="Create Article Group">
                        <Button
                            type="primary"
                            onClick={() => {
                                openEditModal(0)
                            }}
                        >
                            <PlusIcon />
                        </Button>
                    </Tooltip>
                </Flex>
                <div className={commonStyles.table}>
                    <Table
                        columns={columns}
                        dataSource={articalLists}
                        onChange={onChangeTableParams}
                        pagination={pagination}
                    />
                </div>
                <FullWidthModal
                    modalStatus={deleteModalStatus}
                    closeModal={() => {
                        deleteModalFunction(0)
                    }}
                    tittle="You are about to delete this ArticleGroup. Click 'Yes' to continue."
                    smallTittle="Warning: This action cannot be undone!"
                    yesButtonFunction={deleteArticalDetails}
                />
                <Modal
                    footer={null}
                    onCancel={changeCreateEditModalStatus}
                    width={'50%'}
                    title={editModalTittle}
                    open={createEditModalStatus}
                >
                    <ArticalGroupDetailsForm
                        onSave={onSaveArticalDetails}
                        editData={editData}
                    />
                </Modal>
                <FullWidthModal
                    modalStatus={activeInactiveModalStatus}
                    closeModal={() => {
                        activeInactiveModalFunction(0, '')
                    }}
                    tittle={`You are about to ${activeInactiveStatusLabel} this ArticleGroup. Click 'Yes' to continue.`}
                    smallTittle=""
                    yesButtonFunction={updateActiveInactiveCorporate}
                />
            </Card>
        </>
    )
}

const mapStateToProps = (state: any): ListofArticalGroupStateInterface => {
    return {
        articalListGroupData: state.wiki.articalListGroupData,
        articalListGroupDataCount: state.wiki.articalListGroupDataCount,
    }
}

export default connect(mapStateToProps)(WikiArticalGroupList)
