import React, { useEffect, useState } from 'react'
import {
    Col,
    Row,
    Input,
    Form,
    Select,
    Switch,
    Flex,
    Space,
    DatePicker,
    type DatePickerProps,
} from 'antd'
import { connect, useDispatch } from 'react-redux'
import commonStyles from '../../../Utils/Common.less'
import { CheckOutlined, CloseSquareOutlined } from '@ant-design/icons'
import { GetCountryList } from '../../../Services/Common'
import type { ListFrontEndInterface } from '../../../Types/CommonType'
import FullWidthModal from '../../../Components/FullWidthModal'
import dayjs from 'dayjs'
import CusttomBackgroundButton from '../../../Components/CustomBagraoundButton'
interface AddressProps {
    countryList: ListFrontEndInterface[]
}
const NameChangePage = (props: AddressProps): React.ReactElement => {
    const [form] = Form.useForm()
    const dispatch = useDispatch()
    const [swithModalStatus, setSwithModelStatus] = useState(false)
    const [selectedDateAp, setSelectedDateAp] = useState<string>('')
    useEffect(() => {
        form.setFieldValue('dateofAppointment', selectedDateAp)
    }, [selectedDateAp])

    const onChangeDateAppoint = (
        dateofAppointment: DatePickerProps['value'],
        dateString: string | string[]
    ): void => {
        if (dateofAppointment != null) {
            const isoDate = dateofAppointment.toISOString()
            setSelectedDateAp(isoDate)
        }
    }
    useEffect(() => {
        GetCountryList(dispatch)
    }, [])

    const ChangeModalFunction = (): void => {
        setSwithModelStatus(!swithModalStatus)
    }

    const changeCompanyHouse = (): void => {
        ChangeModalFunction()
    }

    const customizeRequiredMark = (
        label: React.ReactNode,
        { required }: { required: boolean }
    ): React.ReactElement => (
        <>
            {label}
            &nbsp;
            {required && <span className={commonStyles.requireIcon}>*</span>}
        </>
    )

    return (
        <>
            <div className={commonStyles.formWrapper}>
                <Form
                    name="complex-form"
                    labelCol={{ span: 24 }}
                    wrapperCol={{ span: 24 }}
                    initialValues={{ remember: true }}
                    layout={'vertical'}
                    autoComplete="off"
                    form={form}
                    requiredMark={customizeRequiredMark}
                >
                    <Form.Item name="clientCategoryId" hidden={true}>
                        <Input />
                    </Form.Item>
                    <Row gutter={16}>
                        <Col span={12}>
                            <Form.Item label="Company & Reg Number" name="">
                                <Input disabled />
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item
                                label="Company Name"
                                name=""
                                rules={[
                                    {
                                        required: true,
                                        message: 'Please input Company Name!',
                                    },
                                ]}
                            >
                                <Input />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={16}>
                        <Col span={12}>
                            <Form.Item label="Ending Name" name="endingName">
                                <Select
                                    allowClear
                                    showSearch
                                    placeholder="Please select"
                                    onChange={() => {}}
                                    optionFilterProp="children"
                                    // filterOption={(input, option) =>
                                    //     (option?.label ?? '')
                                    //         .toLowerCase()
                                    //         .includes(input.toLowerCase())
                                    // }
                                    // options={countryList}
                                />
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item
                                label="Date of Resolution"
                                name="daate"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Please input your date!',
                                    },
                                ]}
                            >
                                <Space direction="vertical" size={12}>
                                    <DatePicker
                                        className={commonStyles.dateWidth}
                                        onChange={(date, dateString) => {
                                            onChangeDateAppoint(
                                                date,
                                                dateString
                                            )
                                        }}
                                        value={
                                            selectedDateAp.length > 0
                                                ? dayjs(selectedDateAp)
                                                : null
                                        }
                                        format="M/D/YYYY"
                                    />
                                </Space>
                            </Form.Item>
                        </Col>
                    </Row>

                    <Row gutter={16}>
                        <Col span={24}>
                            <Row>
                                <Col span={2}>
                                    <Switch checked disabled />
                                </Col>
                                <Col span={20}>
                                    <span style={{ marginLeft: 5 }}>
                                        Tick to confirm that the notice is given
                                        that this company has resolved to change
                                        the company name to the proposed new
                                        name displayed above
                                    </span>
                                </Col>
                            </Row>{' '}
                        </Col>
                    </Row>
                    <Row gutter={16} style={{ marginTop: 20 }}>
                        <Col span={24}>
                            <Switch />{' '}
                            <span style={{ marginLeft: 5 }}>
                                Select this option, If you are need to apply
                                this changes to companies house.
                            </span>
                        </Col>
                    </Row>
                    <div style={{ marginTop: '20px' }}>
                        <Flex justify="flex-end" gap={5}>
                            <CusttomBackgroundButton
                                color={'#868e96'}
                                name={'Cancel'}
                                icon={<CloseSquareOutlined />}
                            />
                            <CusttomBackgroundButton
                                color={'#1dc9b7'}
                                name={'Submit Changes'}
                                icon={<CheckOutlined />}
                            />
                        </Flex>
                    </div>
                </Form>
            </div>
            <FullWidthModal
                modalStatus={swithModalStatus}
                closeModal={ChangeModalFunction}
                tittle="You are about to submit this changes to Company House. Click 'Yes' to continue."
                smallTittle="Warning: This action cannot be undone!"
                yesButtonFunction={changeCompanyHouse}
            />
        </>
    )
}

const mapStateToProps = (state: any): AddressProps => {
    return {
        countryList: state.common.countryList,
    }
}

export default connect(mapStateToProps)(NameChangePage)
