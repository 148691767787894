import {
    Col,
    Row,
    Input,
    Form,
    Button,
    Select,
    DatePicker,
    Switch,
    Radio,
    InputNumber,
    Tooltip,
    type DatePickerProps,
    Space,
} from 'antd'
import { connect, useDispatch } from 'react-redux'

import commonStyles from '../../../Utils/Common.less'
import { SaveOutlined } from '@ant-design/icons'
import React, { useEffect, useState } from 'react'
import { GetUserList } from '../../../Services/User'
import TextArea from 'antd/es/input/TextArea'
import { SaveEvent } from '../../../Services/Helpdesk/ManageDiary'
import moment from 'moment'
import {
    type ManageDiaryFormProps,
    type userlistEvent,
} from '../../../Types/HelpDesk/ManageDiary'
import dayjs from 'dayjs'
import { ErrorMessage } from '../../../Utils/Notification'

type DaysOfWeek =
    | 'IsRepeatOnMon'
    | 'IsRepeatOnTue'
    | 'IsRepeatOnWed'
    | 'IsRepeatOnThu'
    | 'IsRepeatOnFri'
    | 'IsRepeatOnSat'
    | 'IsRepeatOnSun'

const RecurringFrequencyTypeOption = [
    { label: 'Day', value: 1 },
    { label: 'Week', value: 2 },
    { label: 'Month', value: 3 },
    { label: 'Year', value: 4 },
]

const taskTypeOptions = [
    { label: 'Onetime', value: 0 },
    { label: 'Recurring', value: 1 },
]

const reqrutingFrequencyOptions = [
    { label: 'Daily', value: 1 },
    { label: 'Custom', value: 5 },
]

const ManageDiaryForm = (props: ManageDiaryFormProps): React.ReactElement => {
    const { date, userList, initialFormData, oncloseEventModal } = props
    const initialSwitchStates: Record<DaysOfWeek, number> = {
        IsRepeatOnMon: 0,
        IsRepeatOnTue: 0,
        IsRepeatOnWed: 0,
        IsRepeatOnThu: 0,
        IsRepeatOnFri: 0,
        IsRepeatOnSat: 0,
        IsRepeatOnSun: 0,
    }

    const [endsValue, setEndsValue] = useState(0)
    const dispatch = useDispatch()
    const [form] = Form.useForm()

    const [showInput, setShowInput] = useState(false)
    const [selectedStartDate, setSelectedStartDate] = useState<string>('')
    const [selectedFromDate, setSelectedFromDate] = useState<string>('')

    const user = localStorage.getItem('user')
    const userId = user !== null ? JSON.parse(user)?.userId : ''

    useEffect(() => {
        // const nextDay = date.add(1, 'day')
        setSelectedStartDate(date.format('YYYY-MM-DD'))
        setSelectedFromDate(date.format('YYYY-MM-DD'))
    }, [date])

    const handleSelectChange = (value: number): void => {
        if (value === 1) {
            setShowInput(true)
        } else {
            setShowInput(false)
        }
    }
    const [showCustomOptions, setShowCustomOptions] = useState(false)
    const [switchStates, setSwitchStates] = useState(initialSwitchStates)
    const handleRecurringPlanChange = (value: number): void => {
        if (value === 5) {
            setShowCustomOptions(true)
        } else {
            setShowCustomOptions(false)
        }
    }
    const [showToggles, setShowToggles] = useState(false)
    const [isEmailSent, setIsEmailSent] = useState(1)

    const handleSwitchChange1 = (checked: boolean): void => {
        setIsEmailSent(checked ? 1 : 0)
    }
    const handleDropdownChange = (value: number): void => {
        if (value === 2) {
            setShowToggles(true)
        } else {
            setShowToggles(false)
        }
    }
    useEffect(() => {
        GetUserList(dispatch)
    }, [dispatch])

    useEffect(() => {
        form.setFieldValue('startFrom', selectedStartDate ?? '')
        form.setFieldValue('endTo', selectedFromDate ?? '')
    }, [selectedStartDate, selectedFromDate])

    const onChange = (
        date: DatePickerProps['value'],
        dateString: string | string[]
    ): void => {
        if (date !== null && date !== undefined) {
            const isoDate = dayjs(date).format('YYYY-MM-DD')
            setSelectedStartDate(isoDate)
        }
    }

    const onChange2 = (
        date: DatePickerProps['value'],
        dateString: string | string[]
    ): void => {
        if (date !== null && date !== undefined) {
            const isoDate = dayjs(date).format('YYYY-MM-DD')
            setSelectedFromDate(isoDate)
        }
    }

    // useEffect(() => {
    //     setSelectedFromDate(initialFormData?.endTo ?? '')
    //     setSelectedStartDate(initialFormData?.startFrom ?? '')
    // }, [initialFormData])

    useEffect(() => {
        const userIds = initialFormData?.generalEventFollowerList?.map(
            (user: { userId: any }) => user.userId
        )
        form.setFieldsValue({
            eventTitle: initialFormData?.eventTitle,
            description: initialFormData?.description,
            startFrom: initialFormData?.startFrom,
            endTo: initialFormData?.endTo,
            isEmailSent: initialFormData?.isEmailSent,
            userid: userIds,
            eventId: initialFormData?.eventId,
            RecurringFrequencyPeriodId:
                initialFormData?.RecurringFrequencyPeriodId,
            RecurringFrequency: initialFormData?.RecurringFrequency,
            RecurringFrequencyType: initialFormData?.RecurringFrequencyType,
            IsRepeatOnMon: initialFormData?.IsRepeatOnMon === 1,
            IsRepeatOnTue: initialFormData?.IsRepeatOnTue === 1,
            IsRepeatOnWed: initialFormData?.IsRepeatOnWed === 1,
            IsRepeatOnThu: initialFormData?.IsRepeatOnThu === 1,
            IsRepeatOnFri: initialFormData?.IsRepeatOnFri === 1,
            IsRepeatOnSat: initialFormData?.IsRepeatOnSat === 1,
            IsRepeatOnSun: initialFormData?.IsRepeatOnSun === 1,
            RecurringEnds: initialFormData?.RecurringEnds,
            RecurringEndsOn: moment(initialFormData?.RecurringEndsOn),
            RecurringOccurrence: initialFormData?.RecurringOccurrence,
            eventType: initialFormData?.eventType ?? 0,
        })
    }, [initialFormData])

    const handleFinish = (): void => {
        void handleSave()
    }

    const handleSave = async (): Promise<void> => {
        try {
            await form.validateFields()
            const formData = form.getFieldsValue()
            const startFrom = moment(formData.startFrom)
            const endTo = moment(formData.endTo)

            if (endTo.isBefore(startFrom, 'day')) {
                ErrorMessage(
                    'End date must be greater than or equal to start date'
                )
            } else {
                const dataToSave = {
                    eventType: formData.eventType ?? 0,
                    eventId: formData.eventId ?? 0,
                    eventTitle: formData.eventTitle,
                    description: formData.description,
                    endTo: moment(selectedFromDate).format('DD/MM/YYYY'),
                    startFrom: moment(selectedStartDate).format('DD/MM/YYYY'),
                    isEmailSent,
                    generalEventFollowerList: [
                        {
                            eventFollowerId: 0,
                            eventId: 0,
                            userId: 0,
                            userName: '',
                            imageUrl: '',
                            emailAddress: '',
                        },
                    ],
                    userIds: formData.userid !== null ? formData.userid : [],
                    createdBy: userId.toString() ?? '',
                    recurringFrequency: formData.RecurringFrequency ?? 0,
                    recurringFrequencyType:
                        formData.RecurringFrequencyType ?? 0,
                    recurringFrequencyPeriodId:
                        formData.RecurringFrequencyPeriodId ?? 0,
                    recurringEnds: endsValue,
                    recurringOccurrence: formData.RecurringOccurrence ?? 0,
                    recurringEndsOn: '',
                    isRepeatOnSun: switchStates.IsRepeatOnSun ?? 0,
                    isRepeatOnMon: switchStates.IsRepeatOnMon ?? 0,
                    isRepeatOnTue: switchStates.IsRepeatOnTue ?? 0,
                    isRepeatOnWed: switchStates.IsRepeatOnWed ?? 0,
                    isRepeatOnThu: switchStates.IsRepeatOnThu ?? 0,
                    isRepeatOnFri: switchStates.IsRepeatOnFri ?? 0,
                    isRepeatOnSat: switchStates.IsRepeatOnSat ?? 0,
                }

                if (endsValue === 2) {
                    dataToSave.recurringEndsOn =
                        formData.RecurringEndsOn !== undefined
                            ? formData.RecurringEndsOn.format('D/M/YYYY')
                            : 'default-value-if-undefined'
                } else {
                    dataToSave.recurringEndsOn = ''
                }
                void SaveEvent(dispatch, dataToSave)
                form.resetFields()
                setEndsValue(0)
                setShowInput(false)
                setShowCustomOptions(false)
                setShowToggles(false)
                setSwitchStates(initialSwitchStates)
                oncloseEventModal()
            }
        } catch (error) {}
    }

    const handleSwitchChange = (day: DaysOfWeek, checked: boolean): void => {
        setSwitchStates({ ...switchStates, [day]: checked ? 1 : 0 })
    }
    const customizeRequiredMark = (
        label: React.ReactNode,
        { required }: { required: boolean }
    ): React.ReactElement => (
        <>
            {label}
            &nbsp;
            {required && <span className={commonStyles.requireIcon}>*</span>}
        </>
    )
    return (
        <div className={commonStyles.formWrapper}>
            <Form
                name="complex-form"
                labelCol={{ span: 24 }}
                wrapperCol={{ span: 24 }}
                initialValues={{ remember: true }}
                autoComplete="off"
                form={form}
                requiredMark={customizeRequiredMark}
            >
                <Form.Item name="eventId" hidden={true}>
                    <Input />
                </Form.Item>
                <Row gutter={24}>
                    <Col span={24}>
                        <Form.Item
                            label="Subject"
                            name="eventTitle"
                            rules={[
                                {
                                    required: true,
                                    message: 'Please Enter Subject',
                                },
                            ]}
                        >
                            <Input />
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={16}>
                    <Col span={12}>
                        <Form.Item
                            label="Start Date"
                            name="startFrom"
                            rules={[
                                {
                                    required: true,
                                    message: 'Please Enter Start Date!',
                                },
                            ]}
                        >
                            <Space direction="vertical" size={12}>
                                <DatePicker
                                    onChange={(date, dateString) => {
                                        onChange(date, dateString)
                                    }}
                                    value={
                                        selectedStartDate?.length > 0
                                            ? dayjs(selectedStartDate)
                                            : null
                                    }
                                    style={{ width: '300px' }}
                                />
                            </Space>
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item
                            label="End Date"
                            name="endTo"
                            rules={[
                                {
                                    required: true,
                                    message: 'Please Enter End Date!',
                                },
                            ]}
                        >
                            <Space direction="vertical" size={12}>
                                <div style={{ width: '100%' }}>
                                    <DatePicker
                                        onChange={(date, dateString) => {
                                            onChange2(date, dateString)
                                        }}
                                        value={
                                            selectedFromDate?.length > 0
                                                ? dayjs(selectedFromDate)
                                                : null
                                        }
                                        style={{ width: '200%' }}
                                    />
                                </div>
                            </Space>
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={24}>
                    <Col span={24}>
                        <Form.Item
                            label="Participant"
                            name="userid"
                            rules={[
                                {
                                    required: true,
                                    message: 'Please Select Participant!',
                                },
                            ]}
                        >
                            <Select
                                mode="multiple"
                                showSearch
                                allowClear
                                placeholder="Please select"
                                onChange={() => {}}
                                filterOption={(input, option) =>
                                    option?.label
                                        ?.toLowerCase()
                                        .includes(input.toLowerCase()) ?? false
                                }
                                options={[
                                    { label: 'Please select', value: null },
                                    ...userList,
                                ]}
                            />
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={24}>
                    <Col span={24}>
                        <Form.Item valuePropName="isEmailSent">
                            <Switch
                                checked={isEmailSent === 1}
                                onChange={handleSwitchChange1}
                            />
                            <span style={{ marginLeft: 10 }}>
                                Send Email Notification
                            </span>
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={24}>
                    <Col span={24}>
                        <Form.Item
                            label="Description"
                            name="description"
                            rules={[
                                {
                                    required: true,
                                    message: 'Please Enter Description!',
                                },
                            ]}
                        >
                            <TextArea />
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={24}>
                    <Col span={12}>
                        <Form.Item label="Task Type" name="eventType">
                            <Select
                                onChange={handleSelectChange}
                                showSearch
                                filterOption={(input, option) =>
                                    option?.label
                                        ?.toLowerCase()
                                        .includes(input.toLowerCase()) ?? false
                                }
                                options={taskTypeOptions}
                            />
                        </Form.Item>
                    </Col>
                    {showInput && (
                        <Col span={12}>
                            <Form.Item
                                label="Recurring Plan"
                                name="RecurringFrequencyPeriodId"
                            >
                                <Select
                                    onChange={handleRecurringPlanChange}
                                    showSearch
                                    filterOption={(input, option) =>
                                        option?.label
                                            ?.toLowerCase()
                                            .includes(input.toLowerCase()) ??
                                        false
                                    }
                                    options={reqrutingFrequencyOptions}
                                />
                            </Form.Item>
                        </Col>
                    )}
                    {showCustomOptions && (
                        <>
                            {' '}
                            <Col span={7}>
                                <Form.Item
                                    label="Repeat every"
                                    name="RecurringFrequency"
                                >
                                    <InputNumber
                                        min={0}
                                        max={10}
                                        step={1}
                                        defaultValue={0}
                                        className={commonStyles.repeatWidth}
                                    />
                                </Form.Item>
                            </Col>
                            <Col span={17}>
                                <Form.Item
                                    name="RecurringFrequencyType"
                                    label=" "
                                >
                                    <Select
                                        onChange={handleDropdownChange}
                                        showSearch
                                        filterOption={(input, option) =>
                                            option?.label
                                                ?.toLowerCase()
                                                .includes(
                                                    input.toLowerCase()
                                                ) ?? false
                                        }
                                        options={RecurringFrequencyTypeOption}
                                    />
                                </Form.Item>
                            </Col>
                            {showToggles && (
                                <Row gutter={24}>
                                    <Col span={3}>
                                        <Form.Item
                                            label="Mon"
                                            name="IsRepeatOnMon"
                                        >
                                            <Switch
                                                checked={
                                                    switchStates.IsRepeatOnMon ===
                                                    1
                                                }
                                                onChange={(checked) => {
                                                    handleSwitchChange(
                                                        'IsRepeatOnMon',
                                                        checked
                                                    )
                                                }}
                                            />
                                        </Form.Item>
                                    </Col>
                                    <Col span={3}>
                                        <Form.Item
                                            label="Tue"
                                            name="IsRepeatOnTue"
                                        >
                                            <Switch
                                                checked={
                                                    switchStates.IsRepeatOnTue ===
                                                    1
                                                }
                                                onChange={(checked) => {
                                                    handleSwitchChange(
                                                        'IsRepeatOnTue',
                                                        checked
                                                    )
                                                }}
                                            />
                                        </Form.Item>
                                    </Col>
                                    <Col span={3}>
                                        <Form.Item
                                            label="Wed"
                                            name="IsRepeatOnWed"
                                        >
                                            <Switch
                                                checked={
                                                    switchStates.IsRepeatOnWed ===
                                                    1
                                                }
                                                onChange={(checked) => {
                                                    handleSwitchChange(
                                                        'IsRepeatOnWed',
                                                        checked
                                                    )
                                                }}
                                            />
                                        </Form.Item>
                                    </Col>
                                    <Col span={3}>
                                        <Form.Item
                                            label="Thu"
                                            name="IsRepeatOnThu"
                                        >
                                            <Switch
                                                checked={
                                                    switchStates.IsRepeatOnThu ===
                                                    1
                                                }
                                                onChange={(checked) => {
                                                    handleSwitchChange(
                                                        'IsRepeatOnThu',
                                                        checked
                                                    )
                                                }}
                                            />
                                        </Form.Item>
                                    </Col>
                                    <Col span={3}>
                                        <Form.Item
                                            label="Fri"
                                            name="IsRepeatOnFri"
                                        >
                                            <Switch
                                                checked={
                                                    switchStates.IsRepeatOnFri ===
                                                    1
                                                }
                                                onChange={(checked) => {
                                                    handleSwitchChange(
                                                        'IsRepeatOnFri',
                                                        checked
                                                    )
                                                }}
                                            />
                                        </Form.Item>
                                    </Col>
                                    <Col span={3}>
                                        <Form.Item
                                            label="Sat"
                                            name="IsRepeatOnSat"
                                        >
                                            <Switch
                                                checked={
                                                    switchStates.IsRepeatOnSat ===
                                                    1
                                                }
                                                onChange={(checked) => {
                                                    handleSwitchChange(
                                                        'IsRepeatOnSat',
                                                        checked
                                                    )
                                                }}
                                            />
                                        </Form.Item>
                                    </Col>
                                    <Col span={3}>
                                        <Form.Item
                                            label="Sun"
                                            name="IsRepeatOnSun"
                                        >
                                            <Switch
                                                checked={
                                                    switchStates.IsRepeatOnSun ===
                                                    1
                                                }
                                                onChange={(checked) => {
                                                    handleSwitchChange(
                                                        'IsRepeatOnSun',
                                                        checked
                                                    )
                                                }}
                                            />
                                        </Form.Item>
                                    </Col>
                                </Row>
                            )}
                            <Row gutter={24}>
                                <Col span={3}>
                                    <Form.Item label=" " name="RecurringEnds">
                                        <Radio
                                            value="1"
                                            checked={endsValue === 1}
                                            onChange={() => {
                                                setEndsValue(1)
                                            }}
                                        >
                                            never
                                        </Radio>
                                    </Form.Item>
                                </Col>
                                <Col span={3}>
                                    <Form.Item label=" " name="RecurringEnds">
                                        <Radio
                                            value="2"
                                            checked={endsValue === 2}
                                            onChange={() => {
                                                setEndsValue(2)
                                            }}
                                        >
                                            on
                                        </Radio>
                                    </Form.Item>
                                </Col>
                                <Col span={7}>
                                    <Form.Item label=" " name="RecurringEndsOn">
                                        <DatePicker
                                            disabled={endsValue !== 2}
                                        />
                                    </Form.Item>
                                </Col>
                                <Col span={3}>
                                    <Form.Item label=" " name="RecurringEnds">
                                        <Radio
                                            value="3"
                                            checked={endsValue === 3}
                                            onChange={() => {
                                                setEndsValue(3)
                                            }}
                                        >
                                            After
                                        </Radio>
                                    </Form.Item>
                                </Col>
                                <Col span={8}>
                                    <Row align="middle">
                                        <Col span={12}>
                                            <Form.Item
                                                name="RecurringOccurrence"
                                                label=" "
                                            >
                                                <InputNumber
                                                    min={0}
                                                    max={10}
                                                    step={1}
                                                    defaultValue={0}
                                                    className={
                                                        commonStyles.recureWidth
                                                    }
                                                />
                                            </Form.Item>
                                        </Col>
                                        <Col span={10}>
                                            <span>Occurrences</span>
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                        </>
                    )}
                </Row>
                <Row gutter={16}>
                    <Col offset={22} span={2}>
                        <Tooltip title="Save Event">
                            <Button type="primary" onClick={handleFinish}>
                                <SaveOutlined />
                            </Button>
                        </Tooltip>
                    </Col>
                </Row>
            </Form>
        </div>
    )
}

const mapStateToProps = (state: any): userlistEvent => {
    return { userList: state.user.userList }
}

export default connect(mapStateToProps)(ManageDiaryForm)
