import { type PayloadAction, createSlice } from '@reduxjs/toolkit'
import { type ListFrontEndInterface } from '../Types/CommonType'
import {
    type PermissionListType,
    type LeaveListType,
    type CalendarEventType,
    type LeavePayloadInteface,
    type PermissionPayloadInteface,
} from '../Types/Hr/LeavePermission'
import {
    type TimeSheetFormDataInterface,
    type TimesheetPayloadInterface,
} from '../Types/Timesheet'

interface HrStateInterface {
    workflowDropDownList: ListFrontEndInterface[]
    LeaveModeDropDownList: ListFrontEndInterface[]
    TeamCalenderList: CalendarEventType[]
    TeamCalenderListCount: number
    PersonalCalenderList: CalendarEventType[]
    PersonalCalenderListCount: number
    LeaveList: LeaveListType[]
    LeaveListCount: number
    PermissionList: PermissionListType[]
    PermissionListCount: number
    TimeSheetList: TimeSheetFormDataInterface[]
    TimeSheetListCount: number
}

const HrSlice = createSlice({
    name: 'hr',
    initialState: {
        workflowDropDownList: [],
        LeaveModeDropDownList: [],
        TeamCalenderList: [],
        TeamCalenderListCount: 0,
        PersonalCalenderList: [],
        PersonalCalenderListCount: 0,
        LeaveList: [],
        LeaveListCount: 0,
        PermissionList: [],
        PermissionListCount: 0,
        TimeSheetList: [],
        TimeSheetListCount: 0,
    },
    reducers: {
        workflowDropDownList(
            state: HrStateInterface,
            action: PayloadAction<ListFrontEndInterface[]>
        ) {
            state.workflowDropDownList = action?.payload
        },
        LeaveModeDropDownList(
            state: HrStateInterface,
            action: PayloadAction<ListFrontEndInterface[]>
        ) {
            state.LeaveModeDropDownList = action?.payload
        },
        TeamCalenderList(state: HrStateInterface, action: PayloadAction<any>) {
            state.TeamCalenderList = action?.payload?.data
            state.TeamCalenderListCount = action?.payload?.noOfRecords
        },
        PersonalCalenderList(
            state: HrStateInterface,
            action: PayloadAction<any>
        ) {
            state.PersonalCalenderList = action?.payload?.data
            state.PersonalCalenderListCount = action?.payload?.noOfRecords
        },
        LeaveList(
            state: HrStateInterface,
            action: PayloadAction<LeavePayloadInteface>
        ) {
            state.LeaveList = action?.payload?.data
            state.LeaveListCount = action?.payload?.noOfRecords
        },
        PermissionList(
            state: HrStateInterface,
            action: PayloadAction<PermissionPayloadInteface>
        ) {
            state.PermissionList = action?.payload?.data
            state.PermissionListCount = action?.payload?.noOfRecords
        },
        TimeSheetList(
            state: HrStateInterface,
            action: PayloadAction<TimesheetPayloadInterface>
        ) {
            state.TimeSheetList = action?.payload?.data
            state.TimeSheetListCount = action?.payload?.noOfRecords
        },
    },
})

export const {
    workflowDropDownList,
    LeaveModeDropDownList,
    TeamCalenderList,
    PersonalCalenderList,
    LeaveList,
    PermissionList,
    TimeSheetList,
} = HrSlice.actions

export default HrSlice.reducer
