import {
    Col,
    Row,
    Input,
    Form,
    Card,
    Collapse,
    Select,
    Button,
    Switch,
} from 'antd'
import commonStyles from '../../../../Utils/Common.less'
import partneshipTraderNewStyles from '../../../../Less/Clients/RegisteredClients/SoleTrader/SoleTraderNew.less'
import { connect, useDispatch } from 'react-redux'
import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { GetCategoryDropDownList } from '../../../../Services/GroupAndPartners'
import { GetCountryList } from '../../../../Services/Common'
import { SaveOutlined } from '@ant-design/icons'
import {
    type PartneshipHomeApi,
    type PartnershipFormInterface,
} from '../../../../Types/Client/RegisteredClients/Partnership'
import { SavePartnershipData } from '../../../../Services/Parnership'

const PartnershipNew = (
    props: PartnershipFormInterface
): React.ReactElement => {
    const { categoryDropDownList, countryList } = props

    const [form] = Form.useForm()
    const [collapseOne, SetCollapseOne] = useState<string[] | string>(['1'])
    const [collapseTwo, SetCollapseTwo] = useState<string[] | string>([])
    const navigate = useNavigate()

    const dispatch = useDispatch()

    useEffect(() => {
        GetCategoryDropDownList(dispatch)
        GetCountryList(dispatch)
    }, [dispatch, Form])

    const customizeRequiredMark = (
        label: React.ReactNode,
        { required }: { required: boolean }
    ): React.ReactElement => (
        <>
            {label}
            &nbsp;
            {required && <span className={commonStyles.requireIcon}>*</span>}
        </>
    )

    const saveCallBack = (data: PartneshipHomeApi): void => {
        navigate('/clients/registered-clients/partnership-view', {
            state: { partnershipData: data },
        })
    }

    const onSavePartner = (data: PartneshipHomeApi): void => {
        if (collapseOne.length === 1) {
            SetCollapseOne(['1'])
            SetCollapseTwo(['2', '3'])
        } else {
            void SavePartnershipData(dispatch, data, saveCallBack)
        }
    }

    const collapseOneOnChange = (data: string | string[]): void => {
        SetCollapseOne(data)
        if (collapseTwo.length > 0) {
            SetCollapseTwo([])
        }
    }

    const collapseTwoOnChange = (data: string | string[]): void => {
        SetCollapseTwo(data)
        if (collapseOne.length > 0) {
            SetCollapseOne([])
        }
    }

    const initialDetails = (): React.ReactElement => {
        return (
            <div>
                <Form.Item<PartneshipHomeApi>
                    name="partnershipId"
                    hidden={true}
                >
                    <Input />
                </Form.Item>

                <Form.Item<PartneshipHomeApi>
                    label="Client Category"
                    name="clientCategoryId"
                    rules={[
                        {
                            required: true,
                            message: 'Please Enter clientCategoryId',
                        },
                    ]}
                >
                    <Select
                        placeholder="Please select"
                        options={[...categoryDropDownList]}
                    />
                </Form.Item>
                <Form.Item<PartneshipHomeApi>
                    label="Trading Name"
                    name="tradingName"
                    rules={[
                        {
                            required: true,
                            message: 'Please Enter Trading Name',
                        },
                    ]}
                >
                    <Input />
                </Form.Item>
                <Form.Item<PartneshipHomeApi>
                    label="Partnership UTR"
                    name="partnershipUTR"
                >
                    <Input />
                </Form.Item>
            </div>
        )
    }

    const businessAddress = (): React.ReactElement => {
        return (
            <div>
                <Form.Item<PartneshipHomeApi>
                    label="Address 1"
                    name="busAddressOne"
                    rules={[
                        {
                            required: true,
                            message: 'Please Enter Address1',
                        },
                    ]}
                >
                    <Input />
                </Form.Item>
                <Form.Item<PartneshipHomeApi>
                    label="Address 2"
                    name="busAddressTwo"
                >
                    <Input />
                </Form.Item>
                <Form.Item<PartneshipHomeApi>
                    label="Town"
                    name="busTown"
                    rules={[
                        {
                            required: true,
                            message: 'Please Enter Town',
                        },
                    ]}
                >
                    <Input />
                </Form.Item>
                <Form.Item<PartneshipHomeApi> label="Country" name="busCountry">
                    <Select
                        allowClear
                        showSearch
                        placeholder="Please select"
                        onChange={() => {}}
                        optionFilterProp="children"
                        options={countryList?.map((data: { label: string }) => {
                            return {
                                id: data.label,
                                value: data.label,
                                label: data.label,
                            }
                        })}
                    />
                </Form.Item>
                <Form.Item<PartneshipHomeApi>
                    label="Post Code"
                    name="busPostCode"
                    rules={[
                        {
                            required: true,
                            message: 'Please Enter Post Code',
                        },
                    ]}
                >
                    <Input />
                </Form.Item>
            </div>
        )
    }

    const onChangeCABusiness = (checked: boolean): void => {
        let corrAddress1 = ''
        let corrAddress2 = ''
        let corrTown = ''
        let corrCountry = ''
        let corrPostCode = ''
        if (checked) {
            corrAddress1 = form.getFieldValue('busAddress1')
            corrAddress2 = form.getFieldValue('busAddress2')
            corrTown = form.getFieldValue('busTown')
            corrCountry = form.getFieldValue('busCountry')
            corrPostCode = form.getFieldValue('busPostCode')
        }
        form.setFieldValue('corrAddress1', corrAddress1)
        form.setFieldValue('corrAddress2', corrAddress2)
        form.setFieldValue('corrTown', corrTown)
        form.setFieldValue('corrCountry', corrCountry)
        form.setFieldValue('corrPostCode', corrPostCode)
    }

    const correspondAddress = (): React.ReactElement => {
        return (
            <div>
                <div className={partneshipTraderNewStyles.cpAddressMain}>
                    <div>
                        <Switch size="small" onChange={onChangeCABusiness} />
                        &nbsp;
                        <label className={commonStyles.colorGray}>
                            Same as Business
                        </label>
                    </div>
                </div>
                <Form.Item<PartneshipHomeApi>
                    label="Address 1"
                    name="corrAddressOne"
                    rules={[
                        {
                            required: true,
                            message: 'Please Enter Address1',
                        },
                    ]}
                >
                    <Input />
                </Form.Item>
                <Form.Item<PartneshipHomeApi>
                    label="Address 2"
                    name="corrAddressTwo"
                >
                    <Input />
                </Form.Item>
                <Form.Item
                    label="Town"
                    name="corrTown"
                    rules={[
                        {
                            required: true,
                            message: 'Please Enter Town',
                        },
                    ]}
                >
                    <Input />
                </Form.Item>
                <Form.Item<PartneshipHomeApi>
                    label="Country"
                    name="corrCountry"
                >
                    <Select
                        allowClear
                        showSearch
                        placeholder="Please select"
                        onChange={() => {}}
                        optionFilterProp="children"
                        options={countryList?.map((data: { label: string }) => {
                            return {
                                id: data.label,
                                value: data.label,
                                label: data.label,
                            }
                        })}
                    />
                </Form.Item>
                <Form.Item<PartneshipHomeApi>
                    label="Post Code"
                    name="corrPostCode"
                    rules={[
                        {
                            required: true,
                            message: 'Please Enter Post Code',
                        },
                    ]}
                >
                    <Input />
                </Form.Item>
            </div>
        )
    }

    return (
        <Card className={commonStyles.card}>
            <Form
                name="complex-form"
                layout={'vertical'}
                autoComplete="off"
                form={form}
                requiredMark={customizeRequiredMark}
                onFinish={onSavePartner}
            >
                <Row gutter={16}>
                    <Col span={12}>
                        <Form.Item label="Client Type">
                            <Input disabled value={'Partnership'} />
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item label="Client No" name="clientNumber">
                            <Input disabled />
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={16}>
                    <Col span={12}>
                        <Form.Item label="Client Name">
                            <Input disabled />
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item label="Trading Name" name="tradingName">
                            <Input disabled />
                        </Form.Item>
                    </Col>
                </Row>
                <Row>
                    <h3>Basic Information</h3>
                </Row>
                <div className={commonStyles.flexDirectionRow}>
                    <div className={partneshipTraderNewStyles.collapseFirst}>
                        <Collapse
                            activeKey={collapseOne}
                            onChange={collapseOneOnChange}
                            bordered={true}
                            items={[
                                {
                                    key: '1',
                                    label: 'Initial Details',
                                    children: initialDetails(),
                                },
                            ]}
                        />
                    </div>
                    <div className={partneshipTraderNewStyles.collapseSecond}>
                        <Collapse
                            activeKey={collapseTwo}
                            onChange={collapseTwoOnChange}
                            bordered={true}
                            items={[
                                {
                                    key: '2',
                                    label: 'Business Address',
                                    children: businessAddress(),
                                },
                                {
                                    key: '3',
                                    label: 'Correspondance Address',
                                    children: correspondAddress(),
                                },
                            ]}
                        />
                    </div>
                </div>
                &nbsp;
                <Row gutter={16}>
                    <Col offset={23} span={1}>
                        <Button type="primary" htmlType="submit">
                            <SaveOutlined />
                        </Button>
                    </Col>
                </Row>
            </Form>
        </Card>
    )
}

const mapStateToProps = (state: any): PartnershipFormInterface => {
    return {
        categoryDropDownList: state.initial.categoryDropDownList,
        countryList: state.common.countryList,
        clientDropDownList: state.client.clientDropDownList,
    }
}

export default connect(mapStateToProps)(PartnershipNew)
