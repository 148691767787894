import React, { useEffect, useState } from 'react'
import { Col, Row, Input, Form, Button, Select } from 'antd'
import commonStyles from '../../../Utils/Common.less'
import { SaveOutlined } from '@ant-design/icons'
import { connect, useDispatch } from 'react-redux'
import {
    type PortfolioFormStateInterface,
    type PortfolioDetailsFormInterface,
} from '../../../Types/Client/RegisteredClients/Portfolio'
import { GetCategoryDropDownList } from '../../../Services/GroupAndPartners'
import { GetUserList } from '../../../Services/User'
import { type ListFrontEndInterface } from '../../../Types/CommonType'

const { TextArea } = Input
const PorfolioForm = (
    props: PortfolioDetailsFormInterface
): React.ReactElement => {
    const { categoryDropDownList, userList, onSave, editData } = props
    const dispatch = useDispatch()
    const [form] = Form.useForm()
    const [selectedId, setSelectedId] = useState(0)

    useEffect(() => {
        const userData = userList?.find(
            (data: ListFrontEndInterface) => data.value === editData.managerId
        )
        const newData = {
            ...editData,
            managerId: userData?.value ?? '',
        }
        form.setFieldsValue(newData)
    }, [editData, userList])

    useEffect(() => {
        GetCategoryDropDownList(dispatch)
    }, [dispatch])

    useEffect(() => {
        GetUserList(dispatch)
    }, [])

    const handleSelectChange = (value: string | number): void => {
        setSelectedId(value === 'all' ? 0 : Number(value))
    }

    const resetForm = (): void => {
        form.resetFields()
    }

    const customizeRequiredMark = (
        label: React.ReactNode,
        { required }: { required: boolean }
    ): React.ReactElement => (
        <>
            {label}
            &nbsp;
            {required && <span className={commonStyles.requireIcon}>*</span>}
        </>
    )

    return (
        <div className={commonStyles.formWrapper}>
            <Form
                name="complex-form"
                labelCol={{ span: 24 }}
                wrapperCol={{ span: 24 }}
                initialValues={{ remember: true }}
                layout={'vertical'}
                autoComplete="off"
                onFinish={(data) => {
                    onSave(data, resetForm)
                }}
                form={form}
                requiredMark={customizeRequiredMark}
            >
                <Form.Item name="portfolioId" hidden={true}>
                    <Input />
                </Form.Item>
                <Row gutter={16}>
                    <Col span={24}>
                        <Form.Item
                            label="Client Category"
                            name="clientCategoryId"
                            rules={[
                                {
                                    required: true,
                                    message: 'Please select Client Category!',
                                },
                            ]}
                        >
                            <Select
                                placeholder="Please select"
                                onChange={handleSelectChange}
                                value={selectedId === 0 ? 'all' : selectedId}
                                options={[
                                    { value: 0, label: 'All' },
                                    ...categoryDropDownList,
                                ]}
                                filterOption={(input, option) =>
                                    option?.label
                                        ?.toLowerCase()
                                        .includes(input.toLowerCase()) ?? false
                                }
                                allowClear
                                showSearch
                            />
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={16}>
                    <Col span={24}>
                        <Form.Item label="Porfolio Number" name="number">
                            <Input
                                disabled
                                placeholder="This is Auto Generated"
                            />
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={16}>
                    <Col span={24}>
                        <Form.Item
                            label="Porfolio Name"
                            name="name"
                            rules={[
                                {
                                    required: true,
                                    message: 'Please input your Porfolio Name!',
                                },
                            ]}
                        >
                            <Input />
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={16}>
                    <Col span={24}>
                        <Form.Item
                            label="Manager Name"
                            name="managerId"
                            rules={[
                                {
                                    required: true,
                                    message: 'Please select Manager Name!',
                                },
                            ]}
                        >
                            <Select
                                showSearch
                                placeholder="Please select"
                                options={[
                                    { label: 'Please select', value: null },
                                    ...userList,
                                ]}
                                allowClear
                                onChange={() => {}}
                                filterOption={(input, option) =>
                                    (option?.label ?? '')
                                        .toLowerCase()
                                        .includes(input.toLowerCase())
                                }
                            />
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={16}>
                    <Col span={24}>
                        <Form.Item label="Remarks" name="remarks">
                            <TextArea />
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={16}>
                    <Col offset={22} span={2}>
                        <Button type="primary" htmlType="submit">
                            <SaveOutlined />
                        </Button>
                    </Col>
                </Row>
            </Form>
        </div>
    )
}
const mapStateToProps = (state: any): PortfolioFormStateInterface => {
    return {
        categoryDropDownList: state.initial.categoryDropDownList,
        userList: state.user.userList,
    }
}
export default connect(mapStateToProps)(PorfolioForm)
