import React from 'react'
import { Row } from 'antd'
import commontStyles from '../../../../Utils/Common.less'
import { type CorporateExpandViewData } from '../../../../Types/ServiceSetup/ClientServiceUser'

const CorporateExpandView = (
    props: CorporateExpandViewData
): React.ReactElement => {
    const { CorporateData } = props

    return (
        <div className={commontStyles.expandCard}>
            <Row className={commontStyles.expandRow}>
                Registration No :-{' '}
                <div className={commontStyles.expandRowValue}>
                    {CorporateData.companyregisternumber}
                </div>
            </Row>
            <Row className={commontStyles.expandRow}>
                Type :-{' '}
                <div className={commontStyles.expandRowValue}>
                    {CorporateData.clienttype}
                </div>
            </Row>
        </div>
    )
}

export default CorporateExpandView
