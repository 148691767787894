import { type Dispatch } from '@reduxjs/toolkit'
import axios from '../Config/AxiosConfig'
import { ErrorMessage, SuccessMessage } from '../Utils/Notification'
import type { OtherPersonFormDataInterface } from '../Types/OtherPerson'
import { otherPersonList } from '../Reducer/InitialReducer'
import { setLoadingStatus } from '../Reducer/Common'
import type { TableListInterface } from '../Types/CommonType'

export const GetOtherPersonList = (
    dispatch: Dispatch,
    data: TableListInterface
): void => {
    dispatch(setLoadingStatus({ status: true }))
    const { pageNo, pageSize, searchCriteriaList, sortOrderList } = data
    const bodyData = {
        searchCriteriaList,
        sortOrderList,
    }

    axios
        .post(`/api/General/GetOtherPersonList/${pageNo}/${pageSize}`, bodyData)
        .then((response) => {
            if (response.status === 200) {
                const newData = response?.data?.data?.map(
                    (value: OtherPersonFormDataInterface) => {
                        return {
                            ...value,
                            key: value.otherPersonId,
                        }
                    }
                )
                response.data.data = newData
                dispatch(otherPersonList({ ...response.data }))
            } else {
                ErrorMessage(response.data.operationMsg)
            }
            dispatch(setLoadingStatus({ status: false }))
        })
        .catch((error) => {
            ErrorMessage(error.response.data.operationMsg)
            console.error('Error fetching posts:', error)
            dispatch(setLoadingStatus({ status: false }))
        })
}

export const SaveOtherPerson = async (
    dispatch: Dispatch,
    data: OtherPersonFormDataInterface
): Promise<any> => {
    dispatch(setLoadingStatus({ status: true }))
    return await axios
        .post(`/api/General/SetOtherPerson`, data)
        .then((response) => {
            dispatch(setLoadingStatus({ status: false }))
            if (response.status === 200) {
                SuccessMessage(response.data.operationMsg)
                return true
            } else {
                ErrorMessage(response.data.operationMsg)
                return false
            }
        })
        .catch((error) => {
            ErrorMessage(error.response.data.operationMsg)
            console.error('Error fetching posts:', error)
            dispatch(setLoadingStatus({ status: false }))
            return false
        })
}
