import React from 'react'

interface HTMLcodeToReactProps {
    html: string
}

const ConverCodeToReact = (props: HTMLcodeToReactProps): React.ReactElement => {
    const { html } = props

    return <div dangerouslySetInnerHTML={{ __html: html }} />
}

export default ConverCodeToReact
