import React, { useEffect, useState } from 'react'
import { Col, Row, Input, Form, Button, Card } from 'antd'
import commonStyles from '../../../../Utils/Common.less'
import { SendOutlined } from '@ant-design/icons'
import { type ProspectClientFormDataInterface } from '../../../../Types/Client/ProspectClients/prospectClient'
import sendemail from '../taps.less'
import ReactQuillComponet from '../../../../Components/ReactQillComponet'
import { useDispatch } from 'react-redux'
import { ErrorMessage } from '../../../../Utils/Notification'
import { SaveEmail } from '../../../../Services/ProspectClient'
import { useLocation, useNavigate } from 'react-router-dom'
import { GetContacPersonDetalils } from '../../../../Services/GlobelSearch'
import { type ContactInfo } from '../../../../Types/GlobelSearchClient/GlobelSearchClient'
import S3UploadMultiple from '../../../../Components/S3UploadMultiple'

const ClientSendEmail = (): React.ReactElement => {
    const location = useLocation()
    const { businessTypeId, referenceId } = location.state
    const [form] = Form.useForm()
    const [emailText, setEmailText] = useState('')
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const [ConatctInfo, setConatctInfo] = useState<ContactInfo | null>(null)
    const [uploadFileList, setUploadFileList] = useState<string[]>([])
    const handleSetFormData = (name: string, locations: string[]): void => {
        setUploadFileList(locations)
    }
    useEffect(() => {
        const fetchData = (): void => {
            void GetContacPersonDetalils(
                dispatch,
                businessTypeId,
                referenceId,
                (details: ContactInfo) => {
                    setConatctInfo(details)
                }
            )
        }
        fetchData()
    }, [businessTypeId, referenceId])
    useEffect(() => {
        if (ConatctInfo !== null && typeof ConatctInfo === 'object') {
            const newFieldValues = {
                emailAddress: ConatctInfo?.emailAddress,
            }

            form.setFieldsValue(newFieldValues)
        }
    }, [ConatctInfo, form])
    const customizeRequiredMark = (
        label: React.ReactNode,
        { required }: { required: boolean }
    ): React.ReactElement => (
        <>
            {label}
            &nbsp;
            {required && <span className={commonStyles.requireIcon}>*</span>}
        </>
    )
    const onDescriptinChange = (value: string): void => {
        setEmailText(value)
    }

    const onSave = (): void => {
        void onSaveEmail()
    }
    const onSaveEmail = async (): Promise<void> => {
        try {
            await form.validateFields()

            const formData = form.getFieldsValue()
            const toAddress: string = formData.emailAddress
            const ccAddress: string = formData.emailCC
            const bccAddress: string = formData.emailBcc

            if (toAddress.includes('.com.')) {
                ErrorMessage('Plese Input Correct To Email')
            } else {
                const dataTosave = {
                    clientEmailId: 0,
                    templateId: 0,
                    serviceProcessId: 0,
                    companyTypeId: businessTypeId,
                    referenceId,
                    serviceActionId: 0,
                    serviceActionStepId: 0,
                    fromAddress: 'info@iykons.com',
                    toAddress,
                    ccAddress,
                    bccAddress,
                    subject: formData.subject,
                    description: emailText,
                    emailStatus: 'Sucsss',
                    serviceId: 0,
                    contactName: '',
                    invoiceId: 0,
                }

                await SaveEmail(dispatch, dataTosave, uploadFileList)

                form.setFieldsValue({
                    description: '',
                    emailCC: '',
                    emailBcc: '',
                    subject: '',
                })
                const value = `${businessTypeId}-${referenceId}`
                navigate(`/overview/client-dashboard`, {
                    state: { value },
                })
            }
        } catch (error) {
            console.error('Error:', error)
        }
    }
    return (
        <Card title={'Send Email'}>
            <div className={commonStyles.formWrapper}>
                <Form
                    name="complex-form"
                    labelCol={{ span: 24 }}
                    wrapperCol={{ span: 24 }}
                    initialValues={{ remember: true }}
                    layout={'vertical'}
                    autoComplete="off"
                    form={form}
                    size="small"
                    requiredMark={customizeRequiredMark}
                >
                    <Row gutter={16}>
                        <Col span={24}>
                            <Form.Item<ProspectClientFormDataInterface>
                                label="Email To"
                                name="emailAddress"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Please input your email!',
                                    },
                                ]}
                            >
                                <Input />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={16}>
                        <Col span={24}>
                            <Form.Item
                                label="Email CC"
                                name="emailCC"
                                rules={
                                    [
                                        // {
                                        //     type: 'email',
                                        //     message: 'Please input Valid email!',
                                        // },
                                    ]
                                }
                            >
                                <Input />
                                <span>
                                    <i>
                                        Note:-This format only accepted
                                        (example@gmail.com,example1@gmail.com,example2@gmail.com)
                                    </i>
                                </span>
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={16}>
                        <Col span={24}>
                            <Form.Item
                                label="Email BCC "
                                name="emailBcc"
                                rules={[
                                    {
                                        type: 'email',

                                        message: 'Please input Valid email!',
                                    },
                                ]}
                            >
                                <Input />
                                <span>
                                    <i>
                                        Note:-This format only accepted
                                        (example@gmail.com,example1@gmail.com,example2@gmail.com)
                                    </i>
                                </span>
                            </Form.Item>
                        </Col>
                    </Row>

                    <Row gutter={16}>
                        <Col span={24}>
                            <Form.Item
                                label="Subject"
                                name="subject"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Please input your Subject',
                                    },
                                ]}
                            >
                                <Input />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={16}>
                        <Col span={24}>
                            <Form.Item label="Description" name="description">
                                <ReactQuillComponet
                                    value={emailText}
                                    onChange={(value: string) => {
                                        onDescriptinChange(value)
                                    }}
                                    className={sendemail.reactQuill}
                                />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={24}>
                        <Col span={24}>
                            <Form.Item label="Attachment" name="imageUpload">
                                <S3UploadMultiple
                                    name={'imageUpload'}
                                    value={uploadFileList}
                                    setFormData={handleSetFormData}
                                    corporate={''}
                                    corporateid={0}
                                    documenttype={''}
                                    documentbaseId={0}
                                    memberpersonId={0}
                                />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={16}>
                        <Col offset={22} span={2}>
                            <Button
                                type="primary"
                                size="middle"
                                onClick={onSave}
                            >
                                <SendOutlined />
                            </Button>
                        </Col>
                    </Row>
                </Form>
            </div>
        </Card>
    )
}

export default ClientSendEmail
