import React, { useEffect } from 'react'
import { Col, Row, Input, Form, Button, Select, Tooltip } from 'antd'
import commontStyles from '../../../Utils/Common.less'
import { SaveOutlined } from '@ant-design/icons'
import { connect, useDispatch } from 'react-redux'
import { GetCountryList } from '../../../Services/Common'
import S3Upload from '../../../Components/S3Upload'
import {
    type clientssFormStateInterface,
    type ceteteclient,
    type clientFormStateInterface,
} from '../../../Types/HelpDesk/Cretetask'
import { SaveClient } from '../../../Services/Helpdesk/CreteTask'
import { type ListFrontEndInterface } from '../../../Types/CommonType'
export const clientDefaultData: ceteteclient = {
    otherCompanyId: '',
    title: '',
    firstName: '',
    middleName: '',
    surName: '',
    companyName: '',
    referredBy: '',
    mobileNumber: '',
    emailAddress: '',
    address1: '',
    address2: '',
    town: '',
    postCode: '',
    country: '',
    attachment: '',
    isActive: '',
}

const ClientForm = (props: clientssFormStateInterface): React.ReactElement => {
    const { countryList, onSave, editData } = props
    const [form] = Form.useForm()
    const dispatch = useDispatch()

    useEffect(() => {
        GetCountryList(dispatch)
    }, [])

    const customizeRequiredMark = (
        label: React.ReactNode,
        { required }: { required: boolean }
    ): React.ReactElement => (
        <>
            {label}
            &nbsp;
            {required && <span className={commontStyles.requireIcon}>*</span>}
        </>
    )
    const setFormData = (name: string, location: string): void => {
        form.setFieldValue(name, location)
    }
    const resetForm = (): void => {
        form.setFieldsValue({
            clientDefaultData,
        })
    }
    useEffect(() => {
        form.setFieldsValue(editData)
    }, [editData])
    const saveuserInformationData = (): void => {
        void handleFinish()
    }

    const handleFinish = async (): Promise<void> => {
        try {
            await form.validateFields()
            const formData = form.getFieldsValue()

            const datatosave = {
                otherCompanyId: formData.otherCompanyId ?? 0,
                title: formData.title,
                firstName: formData.firstName,
                middleName: formData.middleName ?? '',
                surName: formData.surName,
                companyName: formData.companyName,
                referredBy: formData.referredBy ?? '',
                mobileNumber: formData.mobileNumber,
                emailAddress: formData.emailAddress,
                address1: formData.address1,
                address2: formData.address2 ?? '',
                town: formData.town ?? '',
                postCode: formData.postCode ?? '',
                country: formData.country,
                attachment: formData.attachment ?? '',
                isActive: 'Active',
            }

            await SaveClient(dispatch, datatosave)

            if (onSave !== undefined) {
                onSave(resetForm)
            }

            form.resetFields()
        } catch (error) {
            console.error('Validation failed:', error)
        }
    }

    return (
        <div className={commontStyles.formWrapper}>
            <Form
                name="complex-form"
                labelCol={{ span: 24 }}
                wrapperCol={{ span: 24 }}
                initialValues={{ remember: true }}
                layout={'vertical'}
                autoComplete="off"
                requiredMark={customizeRequiredMark}
                form={form}
            >
                <Form.Item<ceteteclient> name="otherCompanyId" hidden={true}>
                    <Input />
                </Form.Item>
                <Row gutter={16}>
                    <Col span={12}>
                        <Form.Item<ceteteclient>
                            label="Title"
                            name="title"
                            rules={[
                                {
                                    required: true,
                                    message: 'Please input your title!',
                                },
                            ]}
                        >
                            <Select
                                showSearch
                                allowClear
                                placeholder="Please select"
                                onChange={() => {}}
                            >
                                <Select.Option value="UNKNOWN">
                                    Unknown
                                </Select.Option>
                                <Select.Option value="MR.">MR.</Select.Option>
                                <Select.Option value="MISS.">
                                    MISS.
                                </Select.Option>
                                <Select.Option value="MRS.">MRS.</Select.Option>
                                <Select.Option value="MS.">MS.</Select.Option>
                                <Select.Option value="DR.">DR.</Select.Option>
                            </Select>
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item<ceteteclient>
                            label="First Name"
                            name="firstName"
                            rules={[
                                {
                                    required: true,
                                    message: 'Please input your First Name!',
                                },
                            ]}
                        >
                            <Input />
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={16}>
                    <Col span={12}>
                        <Form.Item<ceteteclient>
                            label="Middle Name"
                            name="middleName"
                        >
                            <Input />
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item<ceteteclient>
                            label="Surname"
                            name="surName"
                            rules={[
                                {
                                    required: true,
                                    message: 'Please input your Surname!',
                                },
                            ]}
                        >
                            <Input />
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={16}>
                    <Col span={12}>
                        <Form.Item<ceteteclient>
                            label="Email Address"
                            name="emailAddress"
                            rules={[
                                {
                                    type: 'email',
                                    required: true,
                                    message: 'Please input your email!',
                                },
                            ]}
                        >
                            <Input />
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item<ceteteclient>
                            label="Mobile Number "
                            name="mobileNumber"
                            rules={[
                                {
                                    pattern: /^\d{10,15}$/,
                                    message: 'please enter valid mobile number',
                                },
                                {
                                    required: true,
                                    message: 'Please input your mobile number!',
                                },
                            ]}
                        >
                            <Input />
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={16}>
                    <Col span={12}>
                        <Form.Item<ceteteclient>
                            label="Company Name"
                            name="companyName"
                            rules={[
                                {
                                    required: true,
                                    message: 'Please input your companyName!',
                                },
                            ]}
                        >
                            <Input />
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item<ceteteclient>
                            label="Referred By"
                            name="referredBy"
                        >
                            <Input />
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={16}>
                    <Col span={12}>
                        <Form.Item<ceteteclient>
                            label="Address One"
                            name="address1"
                            rules={[
                                {
                                    required: true,
                                    message: 'Please input your address1!',
                                },
                            ]}
                        >
                            <Input />
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item<ceteteclient>
                            label="Address Two"
                            name="address2"
                        >
                            <Input />
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={16}>
                    <Col span={12}>
                        <Form.Item<ceteteclient> label="Town" name="town">
                            <Input />
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item<ceteteclient>
                            label="Country"
                            name="country"
                            rules={[
                                {
                                    required: true,
                                    message: 'Please input your Country!',
                                },
                            ]}
                        >
                            <Select
                                allowClear
                                showSearch
                                placeholder="Please select"
                                onChange={() => {}}
                                optionFilterProp="children"
                                filterOption={(input, option) =>
                                    (option?.label ?? '')
                                        .toLowerCase()
                                        .includes(input.toLowerCase())
                                }
                                options={countryList?.map(
                                    (country: ListFrontEndInterface) => ({
                                        value: country.label,
                                        label: country.label,
                                    })
                                )}
                            />
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={16}>
                    <Col span={12}>
                        <Form.Item<ceteteclient>
                            label="Post Code"
                            name="postCode"
                        >
                            <Input />
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item label="Document" name="attachment">
                            <S3Upload
                                name={'attachment'}
                                value={form.getFieldValue('attachment')}
                                setFormData={setFormData}
                                corporate={''}
                                corporateid={0}
                                documenttype={''}
                                documentbaseId={0}
                                memberpersonId={0}
                            />
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={16}>
                    <Col offset={22} span={2}>
                        <Tooltip title="Save Client">
                            <Button
                                type="primary"
                                htmlType="submit"
                                onClick={saveuserInformationData}
                            >
                                <SaveOutlined />
                            </Button>
                        </Tooltip>
                    </Col>
                </Row>
            </Form>
        </div>
    )
}

const mapStateToProps = (state: any): clientFormStateInterface => {
    return {
        countryList: state.common.countryList,
        createadocclientList: state.helpdesk.createadocclientList,
        createadocclientListCount: state.helpdesk.createadocclientListCount,
    }
}

export default connect(mapStateToProps)(ClientForm)
