import React, { useEffect, useState } from 'react'
import {
    Button,
    Flex,
    Form,
    Input,
    InputNumber,
    Select,
    Space,
    Switch,
    Tooltip,
} from 'antd'
import style from './Invoice.less'
import { connect, useDispatch } from 'react-redux'
import {
    type InvoiceTabledata,
    type EdittableInterface,
    type EdittableStateInterface,
} from '../../../Types/Invoice/Invoicing'
import { GetServiceDropDownList } from '../../../Services/Corporate'
import { DeleteOutlined, EditOutlined, PlusOutlined } from '@ant-design/icons'
import ActivePremission from '../../../Utils/premissins'
import { PermissionType } from '../../../Types/Enum/PermissionType'
const formItemLayoutWithOutLabel = {
    wrapperCol: {
        xs: { span: 24, offset: 0 },
        sm: { span: 20, offset: 4 },
    },
}

const EditabeRowTable = (props: EdittableInterface): React.ReactElement => {
    const {
        invoiceBodyList,
        serviceDropDownList,
        setTaxTotal,
        setSubTotal,
        setDiscount,
        setNetTotal,
        setTabledata,
    } = props
    const dispatch = useDispatch()
    const [form] = Form.useForm()
    const [discountType, setDiscountType] = useState(1)
    const [indexnumber, setIndexNumber] = useState(0)
    const [taxvalue, setTax] = useState(0)
    const [grossTotal, setGrossTotal] = useState(0)
    const [discountTotal, setDiscountTotal] = useState(0)
    const [netTotal, setnetTotal] = useState(0)
    const [fieldIndex, setFieldIndex] = useState(-1)

    useEffect(() => {
        setTabledata(form.getFieldValue('list'))
    }, [invoiceBodyList, fieldIndex])

    useEffect(() => {
        GetServiceDropDownList(dispatch)
    }, [])

    useEffect(() => {
        if (invoiceBodyList !== undefined && invoiceBodyList?.length > 0) {
            form.setFieldValue('list', invoiceBodyList)
        }
    }, [invoiceBodyList])

    const handleServiceChange = (value: number, index: number): void => {
        const selectedService = serviceDropDownList?.find(
            (service) => service.value === value
        )
        form.setFields([
            {
                name: ['list', index, 'lineDescription'],
                value: selectedService?.label,
            },
            {
                name: ['list', index, 'serviceName'],
                value: selectedService?.label,
            },
        ])
    }

    const handleEdit = (index: number): void => {
        setFieldIndex(index)
    }

    const handleTaxChange = (index: number): void => {
        const row = form.getFieldValue(['list', index])

        // Update corresponding fields in the form
        const obj = calculateNetAmount(index)
        form.setFields([
            {
                name: ['list', index, 'tax'],
                value: row.taxStatus === true ? obj.tax : 0, // Adjust tax value based on switch state
            },
            {
                name: ['list', index, 'netAmount'],
                value: obj.netAmount, // Recalculate net amount based on tax and other values
            },
        ])
    }

    const handleTotalChange = (index: number): void => {
        // Update corresponding fields in the form
        const obj = calculateNetAmount(index)
        form.setFields([
            {
                name: ['list', index, 'netAmount'],
                value: obj.netAmount, // Recalculate net amount based on tax and other values
            },
        ])
    }

    const handleTotalChangeDiscount = (index: number): void => {
        // Update corresponding fields in the form
        const obj = calculateNetAmount(index)
        form.setFields([
            {
                name: ['list', index, 'netAmount'],
                value: obj.netAmount, // Recalculate net amount based on tax and other values
            },
        ])
    }
    const handleDiscountTypeChange = (value: number, index: number): void => {
        setDiscountType(value)
        const obj = calculateNetAmount(index)
        form.setFields([
            {
                name: ['list', index, 'netAmount'],
                value: obj.netAmount, // Recalculate net amount based on tax and other values
            },
        ])
    }

    const calculateNetAmount = (
        index: number
    ): {
        tax: number
        netAmount: number
    } => {
        const row = form.getFieldValue(['list', index])
        const grossTotal = row?.grossTotal ?? 0
        const discountValue = row?.discountValue ?? 0
        let afterDiscountTotal = 0
        if (discountType === 1) {
            afterDiscountTotal = grossTotal - discountValue
        } else if (discountType === 2) {
            afterDiscountTotal = grossTotal - (grossTotal * discountValue) / 100
        }
        let taxv = 0
        if (discountValue !== 0 && discountValue !== undefined) {
            taxv = afterDiscountTotal * 0.2
        } else {
            taxv = grossTotal * 0.2
        }
        const tax = row?.taxStatus === true ? taxv : 0

        setTax(tax)
        setGrossTotal(grossTotal)
        setDiscountTotal(grossTotal - afterDiscountTotal)
        setnetTotal(afterDiscountTotal)

        let netAmount = 0

        if (row.taxStatus === true) {
            if (afterDiscountTotal !== 0) {
                netAmount = afterDiscountTotal + tax
            } else {
                netAmount = grossTotal + tax
            }
        } else {
            if (afterDiscountTotal !== 0) {
                netAmount = afterDiscountTotal
            } else {
                netAmount = grossTotal
            }
        }
        return { tax, netAmount }
    }

    useEffect(() => {
        handleTaxChange(indexnumber)
        calculateNetAmount(indexnumber)
    }, [
        indexnumber,
        form.getFieldValue(['list', indexnumber, 'grossTotal']),
        form.getFieldValue(['list', indexnumber, 'discountValue']),
        discountType,
    ])

    useEffect(() => {
        const rowss = form.getFieldValue('list') as InvoiceTabledata[]
        const grossTotal = rowss?.reduce((acc, row) => {
            if (row?.grossTotal !== undefined) {
                acc += row?.grossTotal
            }
            return acc
        }, 0)
        setGrossTotal(grossTotal)

        const dis = rowss?.reduce((acc, row) => {
            if (row?.discountValue !== undefined) {
                acc += row?.discountValue
            }
            return acc
        }, 0)
        setDiscountTotal(dis)
        const net = rowss?.reduce((acc, row) => {
            if (row?.netAmount !== undefined) {
                acc += row?.netAmount
            }
            return acc
        }, 0)
        setnetTotal(net)
        const taxs = rowss?.reduce((acc, row) => {
            if (row?.tax !== undefined) {
                acc += row?.tax
            }
            return acc
        }, 0)
        setTax(taxs)
    }, [form.getFieldValue('list')])

    return (
        <Form
            name="dynamic_form_item"
            {...formItemLayoutWithOutLabel}
            form={form}
            initialValues={{
                list: [
                    {
                        serviceName: undefined,
                        grossTotal: undefined,
                        discountValue: undefined,
                        tax: undefined,
                        netAmount: undefined,
                        lineDescription: undefined,
                        discountType: undefined,
                    },
                ],
            }}
        >
            <table className={style.tables}>
                <thead>
                    <tr className={style.thstyle}>
                        <th>Service</th>
                        <th>Description</th>
                        <th>Total</th>
                        <th>Discount</th>
                        <th>Tax</th>
                        <th>NetTotal</th>
                        <th>Action</th>
                    </tr>
                </thead>
                <tbody>
                    <Form.List name="list">
                        {(fields, { add, remove }) => (
                            <>
                                {fields?.map(
                                    ({ key, name, ...restField }, index) => (
                                        <tr key={key}>
                                            <td className={style.tdstyle}>
                                                {fieldIndex === index ? (
                                                    <Form.Item
                                                        {...restField}
                                                        name={[
                                                            name,
                                                            'serviceName',
                                                        ]}
                                                    >
                                                        <Select
                                                            style={{
                                                                width: '125px',
                                                            }}
                                                            onChange={(
                                                                value
                                                            ) => {
                                                                handleServiceChange(
                                                                    value,
                                                                    key
                                                                )
                                                            }}
                                                            allowClear
                                                            showSearch
                                                            placeholder="Please select"
                                                            optionFilterProp="children"
                                                            filterOption={(
                                                                input,
                                                                option
                                                            ) =>
                                                                (
                                                                    option?.label ??
                                                                    ''
                                                                )
                                                                    .toLowerCase()
                                                                    .includes(
                                                                        input.toLowerCase()
                                                                    )
                                                            }
                                                            options={
                                                                serviceDropDownList
                                                            }
                                                        />
                                                    </Form.Item>
                                                ) : (
                                                    form?.getFieldValue('list')[
                                                        key
                                                    ]?.serviceName
                                                )}
                                            </td>
                                            <td className={style.tdstyle}>
                                                {fieldIndex === index ? (
                                                    <Form.Item
                                                        {...restField}
                                                        name={[
                                                            name,
                                                            'lineDescription',
                                                        ]}
                                                    >
                                                        <Input
                                                            style={{
                                                                width: '100%',
                                                            }}
                                                            defaultValue={
                                                                form?.getFieldValue(
                                                                    'list'
                                                                )[key]
                                                                    ?.serviceName
                                                            }
                                                        />
                                                    </Form.Item>
                                                ) : (
                                                    form?.getFieldValue('list')[
                                                        key
                                                    ]?.lineDescription
                                                )}
                                            </td>
                                            <td className={style.tdstyle}>
                                                {fieldIndex === index ? (
                                                    <div
                                                        onClick={() => {
                                                            setIndexNumber(
                                                                index
                                                            )
                                                        }}
                                                    >
                                                        <Form.Item
                                                            {...restField}
                                                            name={[
                                                                name,
                                                                'grossTotal',
                                                            ]}
                                                        >
                                                            <InputNumber
                                                                style={{
                                                                    width: '100%',
                                                                }}
                                                                onChange={(
                                                                    e
                                                                ) => {
                                                                    handleTotalChange(
                                                                        index
                                                                    )
                                                                }}
                                                            />
                                                        </Form.Item>
                                                    </div>
                                                ) : (
                                                    form.getFieldValue('list')[
                                                        key
                                                    ].grossTotal ?? 0
                                                )}
                                            </td>
                                            <td className={style.tdstyle}>
                                                {fieldIndex === index ? (
                                                    <>
                                                        <Flex>
                                                            <Form.Item
                                                                {...restField}
                                                                name={[
                                                                    name,
                                                                    'discountType',
                                                                ]}
                                                            >
                                                                <Select
                                                                    style={{
                                                                        width: '50px',
                                                                    }}
                                                                    onChange={(
                                                                        value
                                                                    ) => {
                                                                        if (
                                                                            typeof value ===
                                                                            'number'
                                                                        ) {
                                                                            handleDiscountTypeChange(
                                                                                value,
                                                                                index
                                                                            )
                                                                        }
                                                                    }}
                                                                >
                                                                    <Select.Option
                                                                        value={
                                                                            1
                                                                        }
                                                                    >
                                                                        £
                                                                    </Select.Option>
                                                                    <Select.Option
                                                                        value={
                                                                            2
                                                                        }
                                                                    >
                                                                        %
                                                                    </Select.Option>
                                                                </Select>
                                                            </Form.Item>
                                                            <Form.Item
                                                                {...restField}
                                                                name={[
                                                                    name,
                                                                    'discountValue',
                                                                ]}
                                                            >
                                                                <InputNumber
                                                                    style={{
                                                                        width: '100%',
                                                                    }}
                                                                    onChange={(
                                                                        e
                                                                    ) => {
                                                                        handleTotalChangeDiscount(
                                                                            index
                                                                        )
                                                                    }}
                                                                />
                                                            </Form.Item>
                                                        </Flex>
                                                    </>
                                                ) : (
                                                    <>
                                                        <div
                                                            className={
                                                                style.disTdStyle
                                                            }
                                                        >
                                                            <Flex gap={5}>
                                                                {form?.getFieldValue(
                                                                    'list'
                                                                )[key]
                                                                    ?.discountValue ??
                                                                    0}
                                                                <div>
                                                                    {form?.getFieldValue(
                                                                        'list'
                                                                    )[key]
                                                                        ?.discountType ===
                                                                    1
                                                                        ? '(£)'
                                                                        : '(%)'}
                                                                </div>
                                                            </Flex>
                                                        </div>
                                                    </>
                                                )}
                                            </td>
                                            <td className={style.tdstyle}>
                                                {fieldIndex === index ? (
                                                    <>
                                                        <Flex
                                                            gap={5}
                                                            style={{
                                                                width: '100%',
                                                            }}
                                                        >
                                                            <Form.Item
                                                                {...restField}
                                                                name={[
                                                                    name,
                                                                    'taxStatus',
                                                                ]}
                                                            >
                                                                <Switch
                                                                    checked={
                                                                        form?.getFieldValue(
                                                                            'list'
                                                                        )[key]
                                                                            ?.taxStatus
                                                                    }
                                                                    onChange={(
                                                                        checked
                                                                    ) => {
                                                                        handleTaxChange(
                                                                            key
                                                                        )
                                                                    }}
                                                                />
                                                            </Form.Item>
                                                            <Form.Item
                                                                {...restField}
                                                                name={[
                                                                    name,
                                                                    'tax',
                                                                ]}
                                                            >
                                                                <InputNumber
                                                                    disabled
                                                                    style={{
                                                                        width: '100%',
                                                                    }}
                                                                />
                                                            </Form.Item>
                                                        </Flex>
                                                    </>
                                                ) : (
                                                    form.getFieldValue('list')[
                                                        key
                                                    ].tax ?? 0
                                                )}
                                            </td>
                                            <td className={style.tdstyle}>
                                                {fieldIndex === index ? (
                                                    <Form.Item
                                                        {...restField}
                                                        name={[
                                                            name,
                                                            'netAmount',
                                                        ]}
                                                    >
                                                        <InputNumber
                                                            disabled
                                                            style={{
                                                                width: '100%',
                                                            }}
                                                        />
                                                    </Form.Item>
                                                ) : (
                                                    form.getFieldValue('list')[
                                                        key
                                                    ].netAmount ?? 0
                                                )}
                                            </td>
                                            <td className={style.tdstyle}>
                                                <Space size="small">
                                                    <Form.Item>
                                                        <Tooltip title="Add Item">
                                                            <Button
                                                                type="primary"
                                                                onClick={() => {
                                                                    add()
                                                                    setFieldIndex(
                                                                        index +
                                                                            1
                                                                    )
                                                                    setTaxTotal(
                                                                        taxvalue ??
                                                                            0
                                                                    )
                                                                    setSubTotal(
                                                                        grossTotal ??
                                                                            0
                                                                    )
                                                                    setDiscount(
                                                                        discountTotal ??
                                                                            0
                                                                    )
                                                                    setNetTotal(
                                                                        netTotal ??
                                                                            0
                                                                    )
                                                                }}
                                                                icon={
                                                                    <PlusOutlined />
                                                                }
                                                            />
                                                        </Tooltip>
                                                    </Form.Item>

                                                    <Form.Item>
                                                        {ActivePremission(
                                                            '6000',
                                                            PermissionType.DELETE
                                                        ) && (
                                                            <Tooltip title="Delete Record">
                                                                <Button
                                                                    type="primary"
                                                                    danger
                                                                    icon={
                                                                        <DeleteOutlined />
                                                                    }
                                                                    onClick={() => {
                                                                        remove(
                                                                            name
                                                                        )
                                                                        if (
                                                                            fieldIndex ===
                                                                            index
                                                                        ) {
                                                                            setFieldIndex(
                                                                                index -
                                                                                    1
                                                                            )
                                                                        }
                                                                    }}
                                                                />
                                                            </Tooltip>
                                                        )}
                                                    </Form.Item>
                                                    <Form.Item>
                                                        {ActivePremission(
                                                            '6000',
                                                            PermissionType.UPDATE
                                                        ) && (
                                                            <Tooltip title="Edit Record">
                                                                <Button
                                                                    onClick={() => {
                                                                        handleEdit(
                                                                            key
                                                                        )
                                                                    }}
                                                                    icon={
                                                                        <EditOutlined />
                                                                    }
                                                                />
                                                            </Tooltip>
                                                        )}
                                                    </Form.Item>
                                                </Space>
                                            </td>
                                        </tr>
                                    )
                                )}
                            </>
                        )}
                    </Form.List>
                </tbody>
            </table>
        </Form>
    )
}

const mapStateToProps = (state: any): EdittableStateInterface => {
    return {
        serviceDropDownList: state.initial.serviceDropDownList,
    }
}

export default connect(mapStateToProps)(EditabeRowTable)
