import axios from '../../Config/AxiosConfig'
import { type Dispatch } from '@reduxjs/toolkit'
import { setLoadingStatus } from '../../Reducer/Common'
import {
    type ListFrontEndInterface,
    type ListBackendInterface,
    type TableListInterface,
} from '../../Types/CommonType'
import { ErrorMessage, SuccessMessage } from '../../Utils/Notification'
import {
    workflowDropDownList,
    LeaveModeDropDownList,
    TeamCalenderList,
    PersonalCalenderList,
    LeaveList,
    PermissionList,
} from '../../Reducer/Hr'
import {
    type PermissionListType,
    type LeaveListType,
    type CalendarEventType,
} from '../../Types/Hr/LeavePermission'

export const GetWorkFlow = (dispatch: Dispatch): void => {
    dispatch(setLoadingStatus({ status: true }))
    axios
        .get('/api/GetStandardListing/33')
        .then((response) => {
            if (response.status === 200) {
                const newWorkFlow: ListFrontEndInterface[] =
                    response?.data?.data.map((data: ListBackendInterface) => {
                        return {
                            value: data.stdId,
                            label: data.stdName,
                        }
                    })
                dispatch(workflowDropDownList(newWorkFlow))
            } else {
                ErrorMessage(response.data.operationMsg)
            }
            dispatch(setLoadingStatus({ status: false }))
        })
        .catch((error) => {
            ErrorMessage(error.response.data.operationMsg)
            console.error('Error fetching posts:', error)
            dispatch(setLoadingStatus({ status: false }))
        })
}

export const GetLeaveMode = (dispatch: Dispatch): void => {
    dispatch(setLoadingStatus({ status: true }))
    axios
        .get('/api/GetStandardListing/32')
        .then((response) => {
            if (response.status === 200) {
                const newWorkFlow: ListFrontEndInterface[] =
                    response?.data?.data.map((data: ListBackendInterface) => {
                        return {
                            value: data.stdId,
                            label: data.stdName,
                        }
                    })
                dispatch(LeaveModeDropDownList(newWorkFlow))
            } else {
                ErrorMessage(response.data.operationMsg)
            }
            dispatch(setLoadingStatus({ status: false }))
        })
        .catch((error) => {
            ErrorMessage(error.response.data.operationMsg)
            console.error('Error fetching posts:', error)
            dispatch(setLoadingStatus({ status: false }))
        })
}

export const SaveLeave = async (
    dispatch: Dispatch,
    data: LeaveListType
): Promise<boolean> => {
    dispatch(setLoadingStatus({ status: true }))
    return await axios
        .post(`/api/Employee/SetLeave`, data)
        .then((response) => {
            dispatch(setLoadingStatus({ status: false }))
            if (response.status === 200) {
                SuccessMessage(response.data.operationMsg)
                return true
            } else {
                ErrorMessage(response.data.operationMsg)
                return false
            }
        })
        .catch((error) => {
            ErrorMessage(error.response.data.operationMsg)
            console.error('Error fetching posts:', error)
            dispatch(setLoadingStatus({ status: false }))
            return false
        })
}

export const GetTeamCalender = (dispatch: Dispatch): void => {
    dispatch(setLoadingStatus({ status: true }))
    axios
        .get(`/api/Employee/GetLeaveDetailsByUserId/1/1/=NA=`)
        .then((response) => {
            if (response.status === 200) {
                const newData = response?.data?.data?.map(
                    (value: CalendarEventType) => {
                        return {
                            ...value,
                        }
                    }
                )
                response.data.data = newData
                dispatch(TeamCalenderList({ ...response.data }))
            } else {
                ErrorMessage(response.data.operationMsg)
            }
            dispatch(setLoadingStatus({ status: false }))
        })
        .catch((error) => {
            ErrorMessage(error.response.data.operationMsg)
            console.error('Error fetching posts:', error)
            dispatch(setLoadingStatus({ status: false }))
        })
}

export const GetPersonalCalender = (dispatch: Dispatch): void => {
    dispatch(setLoadingStatus({ status: true }))
    axios
        .get(`/api/Employee/GetLeaveDetailsByUserId/2/1/=NA=`)
        .then((response) => {
            if (response.status === 200) {
                const newData = response?.data?.data?.map(
                    (value: CalendarEventType) => {
                        return {
                            ...value,
                            key: value.key,
                        }
                    }
                )
                response.data.data = newData
                dispatch(PersonalCalenderList({ ...response.data }))
            } else {
                ErrorMessage(response.data.operationMsg)
            }
            dispatch(setLoadingStatus({ status: false }))
        })
        .catch((error) => {
            ErrorMessage(error.response.data.operationMsg)
            console.error('Error fetching posts:', error)
            dispatch(setLoadingStatus({ status: false }))
        })
}

export const GetLeaveList = (
    dispatch: Dispatch,
    data: TableListInterface,
    leaveType: number
): void => {
    dispatch(setLoadingStatus({ status: true }))
    const { pageNo, pageSize, searchCriteriaList, sortOrderList } = data
    const bodyData = {
        searchCriteriaList,
        sortOrderList,
    }
    axios
        .post(
            `/api/Employee/GetLeaveListByLeaveType/${leaveType}/${pageNo}/${pageSize}`,
            bodyData
        )
        .then((response) => {
            if (response.status === 200) {
                const newData = response?.data?.data?.map(
                    (value: LeaveListType) => {
                        return {
                            ...value,
                            key: value.leaveId,
                        }
                    }
                )
                response.data.data = newData
                dispatch(LeaveList({ ...response.data }))
            } else {
                ErrorMessage(response.data.operationMsg)
            }
            dispatch(setLoadingStatus({ status: false }))
        })
        .catch((error) => {
            ErrorMessage(error.response.data.operationMsg)
            console.error('Error fetching posts:', error)
            dispatch(setLoadingStatus({ status: false }))
        })
}

export const GetLeaveListById = (
    dispatch: Dispatch,
    LeaveId: number,
    getRespone: CallableFunction
): void => {
    dispatch(setLoadingStatus({ status: true }))
    axios
        .get(`/api/Employee/GetLeaveDetail/${LeaveId}`)
        .then((response) => {
            if (response.status === 200) {
                getRespone(response)
                SuccessMessage(response.data.operationMsg)
            } else {
                ErrorMessage(response.data.operationMsg)
            }
            dispatch(setLoadingStatus({ status: false }))
        })
        .catch((error) => {
            ErrorMessage(error.response.data.operationMsg)
            console.error('Error fetching posts:', error)
            dispatch(setLoadingStatus({ status: false }))
        })
}

export const SetApproval = (
    dispatch: Dispatch,
    LeaveId: number,
    description: string,
    Status: string
): void => {
    dispatch(setLoadingStatus({ status: true }))
    axios
        .get(
            `/api/Employee/SetApprovalStatusRecord/1/${LeaveId}/${Status}/${description}`
        )
        .then((response) => {
            if (response.status === 200) {
                const newData = response?.data?.data
                response.data.data = newData
                SuccessMessage(response.data.operationMsg)
            } else {
                ErrorMessage(response.data.operationMsg)
            }
            dispatch(setLoadingStatus({ status: false }))
        })
        .catch((error) => {
            ErrorMessage(error.response.data.operationMsg)
            console.error('Error fetching posts:', error)
            dispatch(setLoadingStatus({ status: false }))
        })
}

export const GetPermissionList = (
    dispatch: Dispatch,
    data: TableListInterface
): void => {
    dispatch(setLoadingStatus({ status: true }))
    const { pageNo, pageSize, searchCriteriaList, sortOrderList } = data
    const bodyData = {
        searchCriteriaList,
        sortOrderList,
    }
    axios
        .post(`/api/Employee/GetPermissionList/${pageNo}/${pageSize}`, bodyData)
        .then((response) => {
            if (response.status === 200) {
                const newData = response?.data?.data?.map(
                    (value: PermissionListType) => {
                        return {
                            ...value,
                            key: value.permissionId,
                        }
                    }
                )
                response.data.data = newData
                dispatch(PermissionList({ ...response.data }))
            } else {
                ErrorMessage(response.data.operationMsg)
            }
            dispatch(setLoadingStatus({ status: false }))
        })
        .catch((error) => {
            ErrorMessage(error.response.data.operationMsg)
            console.error('Error fetching posts:', error)
            dispatch(setLoadingStatus({ status: false }))
        })
}

export const SavePermission = async (
    dispatch: Dispatch,
    data: PermissionListType
): Promise<boolean> => {
    dispatch(setLoadingStatus({ status: true }))
    return await axios
        .post(`/api/Employee/SetPermission`, data)
        .then((response) => {
            dispatch(setLoadingStatus({ status: false }))
            if (response.status === 200) {
                SuccessMessage(response.data.operationMsg)
                return true
            } else {
                ErrorMessage(response.data.operationMsg)
                return false
            }
        })
        .catch((error) => {
            ErrorMessage(error.response.data.operationMsg)
            console.error('Error fetching posts:', error)
            dispatch(setLoadingStatus({ status: false }))
            return false
        })
}

export const DeletePermission = (
    dispatch: Dispatch,
    id: number,
    getTableData: CallableFunction
): void => {
    dispatch(setLoadingStatus({ status: true }))
    axios
        .get(`/api/SetDeleteRecord/20/${id}`)
        .then((response) => {
            if (response.status === 200) {
                getTableData()
                SuccessMessage(response.data.operationMsg)
            } else {
                ErrorMessage(response.data.operationMsg)
            }
            dispatch(setLoadingStatus({ status: false }))
        })
        .catch((error) => {
            ErrorMessage(error.response.data.operationMsg)
            console.error('Error fetching get:', error)
            dispatch(setLoadingStatus({ status: false }))
        })
}

export const DeleteLeave = (
    dispatch: Dispatch,
    id: number,
    getTableData: CallableFunction
): void => {
    dispatch(setLoadingStatus({ status: true }))
    axios
        .get(`/api/SetDeleteRecord/19/${id}`)
        .then((response) => {
            if (response.status === 200) {
                getTableData()
                SuccessMessage(response.data.operationMsg)
            } else {
                ErrorMessage(response.data.operationMsg)
            }
            dispatch(setLoadingStatus({ status: false }))
        })
        .catch((error) => {
            ErrorMessage(error.response.data.operationMsg)
            console.error('Error fetching get:', error)
            dispatch(setLoadingStatus({ status: false }))
        })
}

export const SetPermissionApproval = (
    dispatch: Dispatch,
    LeaveId: number,
    description: string,
    Status: string
): void => {
    dispatch(setLoadingStatus({ status: true }))
    axios
        .get(
            `/api/Employee/SetApprovalStatusRecord/2/${LeaveId}/${Status}/${description}`
        )
        .then((response) => {
            if (response.status === 200) {
                const newData = response?.data?.data?.map(
                    (value: PermissionListType) => {
                        return {
                            ...value,
                        }
                    }
                )
                response.data.data = newData
                SuccessMessage(response.data.operationMsg)
            } else {
                ErrorMessage(response.data.operationMsg)
            }
            dispatch(setLoadingStatus({ status: false }))
        })
        .catch((error) => {
            ErrorMessage(error.response.data.operationMsg)
            console.error('Error fetching posts:', error)
            dispatch(setLoadingStatus({ status: false }))
        })
}
