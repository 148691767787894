// axiosConfig.js

import axios from 'axios'

const instance = axios.create({
    baseURL: process.env.REACT_APP_BASE_URL,
    // baseURL: 'https://demoapi.iykons.com', // Replace with your API base URL
})

// Set authentication headers for requests
instance.interceptors.request.use((config) => {
    const token = localStorage.getItem('jwtToken') // Replace with how you store your JWT token
    if (token !== null && token !== undefined && token !== '') {
        config.headers.Authorization = `Bearer ${token}`
    }
    return config
})

export default instance
