import React, { useEffect, useState } from 'react'
import {
    Col,
    Row,
    Input,
    Form,
    Button,
    Select,
    DatePicker,
    Tooltip,
} from 'antd'
import { connect, useDispatch } from 'react-redux'
import commonStyles from '../../../Utils/Common.less'
import { SaveOutlined } from '@ant-design/icons'
import type { ListFrontEndInterface } from '../../../Types/CommonType'
import { ProjectItemDefaultData } from './ProjectManagementList'
import { GetCountryList } from '../../../Services/Common'
import { GetDepartmentDropDownList } from '../../../Services/ManageUser'
import { GetUserList } from '../../../Services/User'
import dayjs from 'dayjs'
import { GetCategoryDropDownList } from '../../../Services/GroupAndPartners'
import {
    type ProjectManagmentFormDataInterface,
    type ProjectManagmentStateInterface,
    type ProjectManagmentFormInterface,
    type SaveProject,
} from '../../../Types/ProjectMangement'
import {
    CheckProjectNameExist,
    saveProjectManagenent,
} from '../../../Services/ProjectManagement'

const { TextArea } = Input

const ProjectForm = (
    props: ProjectManagmentFormInterface
): React.ReactElement => {
    const {
        onSave,
        editData,

        userList,
        categoryDropDownList,
        departmentDropDownList,
    } = props
    const dispatch = useDispatch()
    const [form] = Form.useForm()
    const [clientCategoryId, setClientCategoryId] = useState<number | null>(
        null
    )
    let timeout: any
    const [, setSelectedLabelcategory] = useState<string>('')
    const [departmentId, setDepartmentId] = useState<number | null>(null)
    const [, setSelectedLabeldepartment] = useState<string>('')
    // const [selectedDate, setSelectedDate] = useState<string>('')
    // const [selectedDate1, setSelectedDate1] = useState<string>('')

    useEffect(() => {
        GetCountryList(dispatch)
        GetUserList(dispatch)
        GetCategoryDropDownList(dispatch)
    }, [])

    useEffect(() => {
        if (clientCategoryId !== null) {
            GetDepartmentDropDownList(dispatch, clientCategoryId)
        }
    }, [clientCategoryId, editData])

    useEffect(() => {
        console.log('editDate', editData)
        resetForm()
        form.resetFields()
    }, [])

    useEffect(() => {
        if (editData.projectId != null) {
            const clientCategoryData = categoryDropDownList.find(
                (data: ListFrontEndInterface) =>
                    data.label === editData.clientCategoryName
            )

            setClientCategoryId(clientCategoryData?.value ?? null)
        }
    }, [editData.projectId, categoryDropDownList])

    useEffect(() => {
        if (editData.projectId != null && departmentDropDownList?.length > 0) {
            const departmentData = departmentDropDownList.find(
                (data: ListFrontEndInterface) =>
                    data.label === editData.department
            )

            setDepartmentId(departmentData?.value ?? null)
        }
    }, [editData.projectId, departmentDropDownList])

    useEffect(() => {
        if (editData.projectId != null && departmentDropDownList?.length > 0) {
            const newData = {
                ...editData,
                clientCategoryId: clientCategoryId ?? null,
                departmentId: departmentId ?? null,
            }
            form.setFieldsValue(newData)
        }
    }, [editData.projectId])

    useEffect(() => {
        if (editData.projectId != null) {
            const newData = {
                ...editData,
            }
            form.setFieldsValue(newData)
        }
        // form.setFieldValue('projectLeadId', editData?.projectLeadName)
    }, [editData.projectId])

    // const startDate = Form.useWatch('projectStartDate', form)
    // const projectEndDate = Form.useWatch('projectEndDate', form)
    // useEffect(() => {
    //     console.log('form start date ', startDate, projectEndDate, editData)
    // }, [startDate, projectEndDate])

    const resetForm = (): void => {
        form.setFieldsValue({
            ProjectItemDefaultData,
            clientCategoryId: null,
            departmentName: undefined,
            projectStartDate: undefined,
            projectEndDate: undefined,
        })
    }

    useEffect(() => {
        const startDate = editData?.projectStartDate?.toString()
        const endDate = editData?.projectEndDate?.toString()
        if (
            startDate !== undefined &&
            startDate?.length > 0 &&
            startDate !== null &&
            endDate !== undefined &&
            endDate?.length > 0
        ) {
            const splitStartDate = startDate?.split('-')
            const _startDate =
                splitStartDate[2] +
                '-' +
                splitStartDate[1] +
                '-' +
                splitStartDate[0] +
                'T00:00:00.000Z'

            const splitEndDate = endDate?.split('-')
            const _endDate =
                splitEndDate[2] +
                '-' +
                splitEndDate[1] +
                '-' +
                splitEndDate[0] +
                'T00:00:00.000Z'

            form.setFieldsValue({
                projectStartDate: dayjs(_startDate),
                projectEndDate: dayjs(_endDate),
            })
        }
    }, [editData?.projectStartDate, editData?.projectEndDate])

    // const onChange = (date: string, dateString: string | string[]): void => {
    //     console.log('start date', date, dateString)
    //     if (date !== null && date !== undefined) {
    //         const isoDate = date
    //         setSelectedDate(isoDate)
    //     }
    // }
    // const onChange1 = (date1: string, dateString: string | string[]): void => {
    //     console.log('End date', date1, dateString)
    //     if (date1 !== null && date1 !== undefined) {
    //         const isoDate1 = date1
    //         setSelectedDate1(isoDate1)
    //     }
    // }

    const handleCategoryChange = (option: ListFrontEndInterface): void => {
        setClientCategoryId(form.getFieldValue('clientCategoryId'))
        setSelectedLabelcategory(option.label)
    }

    const handleDepartmentChange = (option: ListFrontEndInterface): void => {
        setDepartmentId(form.getFieldValue('departmentId'))
        setSelectedLabeldepartment(option.label)
    }

    const saveuserInformationData = (
        formData: ProjectManagmentFormDataInterface
    ): void => {
        void saveuserInformation(formData)
    }
    const saveuserInformation = async (
        formData: ProjectManagmentFormDataInterface
    ): Promise<void> => {
        try {
            console.log('formdata', formData)
            await form.validateFields()

            const dataToSave = {
                projectId: Number(formData.projectId) ?? 0,
                projectPrefix: formData.projectPrefix ?? '',
                projectName: formData.projectName ?? '',
                departmentId: formData.departmentId ?? 0,
                projectDescription: formData.projectDescription ?? '',
                projectStartDate: dayjs(formData.projectStartDate).isValid()
                    ? dayjs(formData.projectStartDate).format('DD/MM/YYYY')
                    : dayjs().format('DD/MM/YYYY'),
                projectEndDate: dayjs(formData.projectEndDate).isValid()
                    ? dayjs(formData.projectEndDate).format('DD/MM/YYYY')
                    : dayjs().format('DD/MM/YYYY'),
                clientCategoryId: formData?.clientCategoryId ?? 0,
                assignToUserId: formData.assignUserId ?? 0,
                projectLeadUserId: formData.projectLeadId ?? 0,
            }
            void saveUserInfoData(dataToSave)
        } catch (error) {}
    }
    const saveUserInfoData = async (data: SaveProject): Promise<void> => {
        await saveProjectManagenent(dispatch, data)
            .then((result) => {
                if (result !== null && result !== undefined) {
                    onSave(resetForm)
                }
            })
            .catch(() => {})
    }

    const customizeRequiredMark = (
        label: React.ReactNode,
        { required }: { required: boolean }
    ): React.ReactElement => (
        <>
            {label}
            &nbsp;
            {required && <span className={commonStyles.requireIcon}>*</span>}
        </>
    )
    const onNamechange = (): void => {
        const projectName = form.getFieldValue('projectName') ?? null

        clearTimeout(timeout)
        timeout = setTimeout(() => {
            CheckProjectNameExist(dispatch, editData?.projectId, projectName)
        }, 1000)
    }

    return (
        <div className={commonStyles.formWrapper}>
            <Form
                name="complex-form"
                labelCol={{ span: 24 }}
                wrapperCol={{ span: 24 }}
                initialValues={{ remember: true }}
                layout={'vertical'}
                autoComplete="off"
                form={form}
                requiredMark={customizeRequiredMark}
                onFinish={saveuserInformationData}
            >
                <Form.Item<ProjectManagmentFormDataInterface>
                    name="projectId"
                    hidden={true}
                >
                    <Input />
                </Form.Item>
                <Row gutter={24}>
                    <Col span={12}>
                        <Form.Item<ProjectManagmentFormDataInterface>
                            label="Group&Partner"
                            name="clientCategoryId"
                            rules={[
                                {
                                    required: true,
                                    message: 'Please input your Group&Partner!',
                                },
                            ]}
                        >
                            <Select
                                showSearch
                                allowClear
                                placeholder="Please select"
                                onChange={handleCategoryChange}
                                filterOption={(input, option) =>
                                    !(option == null) &&
                                    option.label
                                        .toLowerCase()
                                        .includes(input.toLowerCase())
                                }
                                options={[
                                    { label: 'Please select', value: null },
                                    ...categoryDropDownList,
                                ]}
                            />
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item<ProjectManagmentFormDataInterface>
                            label="Department"
                            name="departmentId"
                            rules={[
                                {
                                    required: true,
                                    message: 'Please input your Department!',
                                },
                            ]}
                        >
                            <Select
                                allowClear
                                placeholder="Please select"
                                onChange={handleDepartmentChange}
                                options={[
                                    { label: 'Please select', value: null },
                                    ...departmentDropDownList,
                                ]}
                                showSearch
                                filterOption={(input, option) =>
                                    !(option == null) &&
                                    option.label
                                        .toLowerCase()
                                        .includes(input.toLowerCase())
                                }
                            />
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={24}>
                    <Col span={12}>
                        <Form.Item<ProjectManagmentFormDataInterface>
                            label=" ProjectName"
                            name="projectName"
                            rules={[
                                {
                                    required: true,
                                    message: 'Please input your ProjectName!',
                                },
                            ]}
                        >
                            <Input onChange={onNamechange} />
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item<ProjectManagmentFormDataInterface>
                            label="Project Prefix"
                            name="projectPrefix"
                        >
                            <Input />
                        </Form.Item>
                    </Col>
                </Row>

                <Row gutter={24}>
                    <Col span={12}>
                        <Form.Item<ProjectManagmentFormDataInterface>
                            label="Start Date"
                            name="projectStartDate"
                        >
                            <DatePicker
                                format={'DD/MM/YYYY'}
                                // onChange={(date, dateString) => {
                                //     onChange(date, dateString)
                                // }}
                                // value={
                                //     selectedDate !== undefined &&
                                //     dayjs(selectedDate).isValid()
                                //         ? dayjs(selectedDate).format(
                                //               'DD/MM/YYYY'
                                //           )
                                //         : undefined
                                // }
                                className={commonStyles.dateWidth}
                            />
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item<ProjectManagmentFormDataInterface>
                            label="End Date"
                            name="projectEndDate"
                        >
                            <DatePicker
                                format={'DD/MM/YYYY'}
                                // onChange={(date, dateString) => {
                                //     onChange1(date, dateString)
                                // }}
                                // value={
                                //     dayjs(selectedDate1).isValid()
                                //         ? dayjs(selectedDate1).format(
                                //               'DD/MM/YYYY'
                                //           )
                                //         : null
                                // }
                                className={commonStyles.dateWidth}
                            />
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={24}>
                    <Col span={12}>
                        <Form.Item<ProjectManagmentFormDataInterface>
                            label="Assign To"
                            name="assignUserId"
                            rules={[
                                {
                                    required: true,
                                    message: 'Please input your Assign To!',
                                },
                            ]}
                        >
                            <Select
                                allowClear
                                placeholder="Please select"
                                options={[
                                    { label: 'Please select', value: 0 },
                                    ...userList,
                                ]}
                                size="large"
                                showSearch
                                filterOption={(input, option) =>
                                    (option?.label ?? '')
                                        .toLowerCase()
                                        .includes(input.toLowerCase())
                                }
                                onChange={() => {}}
                            />
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item<ProjectManagmentFormDataInterface>
                            label="Project Lead"
                            name="projectLeadId"
                            rules={[
                                {
                                    required: true,
                                    message: 'Please input your Project Lead!',
                                },
                            ]}
                        >
                            <Select
                                allowClear
                                placeholder="Please select"
                                options={[
                                    { label: 'Please select', value: null },
                                    ...userList,
                                ]}
                                size="large"
                                showSearch
                                filterOption={(input, option) =>
                                    (option?.label ?? '')
                                        .toLowerCase()
                                        .includes(input.toLowerCase())
                                }
                                onChange={() => {}}
                            />
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={16}>
                    <Col span={24}>
                        <Form.Item<ProjectManagmentFormDataInterface>
                            label="Description"
                            name="projectDescription"
                        >
                            <TextArea rows={4} />
                        </Form.Item>
                    </Col>
                </Row>

                <Row gutter={16}>
                    <Col offset={22} span={2}>
                        <Tooltip title="Save Project">
                            <Button type="primary" onClick={form.submit}>
                                <SaveOutlined />
                            </Button>
                        </Tooltip>
                    </Col>
                </Row>
            </Form>
        </div>
    )
}

const mapStateToProps = (state: any): ProjectManagmentStateInterface => {
    return {
        categoryDropDownList: state.initial.categoryDropDownList,
        departmentDropDownList: state.userrights.departmentDropDownList,
        userList: state.user.userList,
    }
}

export default connect(mapStateToProps)(ProjectForm)
